import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../../../store';
import { API_BASE_URL } from '../../../../utils/AppConstants';
import { ISignatureFlowSettings, IMessage, IAuthenticationQuestion } from '../../../models/SignatureFlowSettings';
import { handleResponse } from '../../Library';
import { NotificationAction, StatusType } from '../../../store/common/NotificationStore';
import * as Constants from '../../../components/helper/Constants';
import { actionTypes } from '../../../store/ActionTypes';
import { ReceiveSignatureAuthenticationQuestions, ReceiveSignatureAuthenticationQuestion,DeleteSignatureFlowAuthenticationQuestion, AuthenticationQuestionsActionTypes } from './AuthenticationQuestionsActionTypes';
import { validateError } from '../../../components/helper/Validations';
import * as SignatureFlowSettingsStore from '../../SignatureFlowSettingsStore';
import { logger } from 'src/routes/LoggedIn';
import { ErrorSignatureFlowSettingsAction, RequestSignatureFlowSettingsAction } from '../../SignatureFlowSettingsStore';

export interface IAuthenticationQuestionsData{
    authenticationQuestions:IAuthenticationQuestionDictionary;
    isLoading: boolean;
}
export interface IAuthenticationQuestionDictionary {
    [index: number]: AuthenticationQuestionState;
}

export interface AuthenticationQuestionState {
    authenticationQuestion: IAuthenticationQuestion;
}

type DispatchAction = ReceiveSignatureAuthenticationQuestions|
                      ReceiveSignatureAuthenticationQuestion|
                      DeleteSignatureFlowAuthenticationQuestion|
                      NotificationAction|
                      ErrorSignatureFlowSettingsAction|
                      RequestSignatureFlowSettingsAction;

export const actionCreators = {
    requestAuthenticationQuestions: (): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/AuthenticationQuestions/GetAuthenticationQuestionsAsync', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(json => json as Promise<IAuthenticationQuestion[]>)
            .then(data => {
                dispatch({
                    type: AuthenticationQuestionsActionTypes.RECEIVE_SIGNATURE_FLOW_AUTHENTICATION_QUESTIONS, authenticationQuestions: data
                });
            })
            .catch(error => {
                const message = validateError(error);

                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanySettingsError,
                    statusType: StatusType.Error, statusCode:error?.status
                })
                dispatch({
                    type: actionTypes.ERROR_SIGNATURE_FLOW_SETTINGS,
                    reason: message,
                });

                logger.trackError(`requestAuthenticationQuestions failed with error ${message}`)
            });
        addTask(fetchTask);

        dispatch({
            type: actionTypes.REQUEST_SIGNATURE_FLOW_SETTINGS,
            message: ''
        });
    },

    saveAuthenticationQuestion: (question: IAuthenticationQuestion, isSetAsDefault: boolean): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/AuthenticationQuestions', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(question),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((newId) => {

                question.id = newId;
                if (isSetAsDefault) {
                    let settings = getState().signatureFlowSettingsData.signatureFlowSettings;
                    settings.securitySettings.questionSettings.defaultQuestion = newId;

                    let action: any = SignatureFlowSettingsStore.actionCreators.updateCompanySettings(settings);
                    dispatch(action);
                }
                dispatch({
                    type: AuthenticationQuestionsActionTypes.RECEIVE_SIGNATURE_FLOW_AUTHENTICATION_QUESTION,
                    question: question,
                    questionId: newId
                });
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.QuestionConstants.SaveQuestionSuccess,
                    statusType: StatusType.Success
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SecuritySettingConstants.SaveQuestionError,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`saveAuthenticationQuestion failed for the request having parameters ${JSON.stringify(question)} with error ${validateError(error)}`)
            });
        addTask(fetchTask);
    },

    updateAuthenticationQuestion: (question: IAuthenticationQuestion): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/AuthenticationQuestions', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(question),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: AuthenticationQuestionsActionTypes.RECEIVE_SIGNATURE_FLOW_AUTHENTICATION_QUESTION, question: question, questionId: question.id
                });
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.QuestionConstants.UpdateQuestionSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SecuritySettingConstants.UpdateQuestionError,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`updateAuthenticationQuestion failed for the request having question ${JSON.stringify(question)} with error ${validateError(error)}`)
            });
        addTask(fetchTask);
    },

    deleteAuthenticationQuestion: (question: IAuthenticationQuestion): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/AuthenticationQuestions/', {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(question),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: AuthenticationQuestionsActionTypes.DELETE_SIGNATURE_FLOW_AUTHENTICATION_QUESTION, question: question
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.QuestionConstants.DeleteQuestionSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SecuritySettingConstants.DeleteQuestionError,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`deleteAuthenticationQuestion failed for the request having question ${JSON.stringify(question)} with error ${validateError(error)}`)
            });
        addTask(fetchTask);
    },

};
const authenticationQuestionDic: IAuthenticationQuestionDictionary = [];

const unloadedState: IAuthenticationQuestionsData = {
    authenticationQuestions: { ...authenticationQuestionDic },
    isLoading: false,
} as IAuthenticationQuestionsData;

export const reducer : Reducer<IAuthenticationQuestionsData> = (state=unloadedState,incomingAction)=>{
    const action = incomingAction as DispatchAction;
    switch(action.type){
        case AuthenticationQuestionsActionTypes.RECEIVE_SIGNATURE_FLOW_AUTHENTICATION_QUESTION:
            var received = { ...state };
            if (!received.authenticationQuestions || !Array.isArray(received.authenticationQuestions)) {
                received.authenticationQuestions = [];
            }
            received.authenticationQuestions[action.questionId] = { authenticationQuestion: action.question };
            received.isLoading = false;
            return received;
        case AuthenticationQuestionsActionTypes.RECEIVE_SIGNATURE_FLOW_AUTHENTICATION_QUESTIONS:
            var received = { ...state };
            if (!received.authenticationQuestions || !Array.isArray(received.authenticationQuestions)) {
                received.authenticationQuestions = [];
            }
            action.authenticationQuestions.forEach((item, i) => {
                received.authenticationQuestions[item.id] = { authenticationQuestion: item };
            });
            received.isLoading = false;
            return received;
        case AuthenticationQuestionsActionTypes.DELETE_SIGNATURE_FLOW_AUTHENTICATION_QUESTION:
            var received = { ...state }
            if (received.authenticationQuestions[action.question.id]) {
                delete received.authenticationQuestions[action.question.id];
                received.isLoading = false;
            }
            return received;
    }
    return state;
};