import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { DropdownComponent } from '../../../common/DropdownComponent';
import { ISignatureDefaultSettings, DefaultDownloadInfoType } from '../../../../models/user/UserSettingsModels';
import { IUserModel, DownloadOptions} from '../../../../../Core/ViewModels/User/UserViewModel';


export interface IMyDefaultProps {
    users: IUserModel[];
    onChangeNotifyUser: (event: any) => void;
    onChangeDefaultDownload: (event: any) => void;
    defaultSettings: ISignatureDefaultSettings;
}

export const MyDefaults: React.FC<IMyDefaultProps> = props => {

    const [selectedDownloadOption, setSelectedDownloadOption] = React.useState<DefaultDownloadInfoType>(DefaultDownloadInfoType.PromptMeEveryTime);

    React.useEffect(() => {
        setSelectedDownloadOption(
            props.defaultSettings.defaultDownloadInfo.rememberDefaultDownload ? props.defaultSettings.defaultDownloadInfo.downloadType : DefaultDownloadInfoType.PromptMeEveryTime
        );
    }, [])

    const userList = (): { value: string, label: string }[] => {
        let columnValues: { value: string, label: string }[] = [];
        props.users.map((person: IUserModel) => {
            columnValues.push({
                value: person.userId.toString(),
                label: `${person.firstName} ${person.lastName}`,
            })
        });
        return columnValues;
    }

    const handleDefaultDownloadChange = (selectedOption: any) => {
        props.onChangeDefaultDownload(selectedOption);
        setSelectedDownloadOption(selectedOption);
    }

    return (
        <Accordion defaultActiveKey="0">
            <Card id="collapsible-panel-defaults" className="marB20">
                <Accordion.Toggle eventKey="0">
                    <Card.Header style={{ paddingTop: '8px', paddingBottom: '5px' }}>
                        <Card.Title> <i className='fa fa-star' /> My Defaults   </Card.Title>    
                    </Card.Header>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" >
                    <Card.Body >
                        <Row className="marB03 padT07">
                            <Col sm={6} md={5} xl={5}>
                                <div className="marB10 padT07"> Default Notify About Signing Events:</div>
                            </Col>
                            <Col sm={6} md={7} xl={6} className="my-def-help-tooltip">
                                <div className="padR30 pos-relative">
                                    <DropdownComponent
                                        id="ddlDefaultSigningEvents"
                                        options={userList()}
                                        onChange={props.onChangeNotifyUser}
                                        searchable={true}
                                        menuStyle={{ maxHeight: '120px' }}
                                        selectedValue={props.defaultSettings ? props.defaultSettings.notifyUser : 0}
                                        data-test-auto="A97096D1-35C5-4428-AB53-BCBEA46B12DA"
                                    /> 
                                </div>
                            </Col>
                        </Row>
                        <Row className="marB03 padT07">
                            <Col sm={6} md={5} xl={5}>
                                <div className="marB10 padT07"> Default Download Option:</div>
                            </Col>
                            <Col sm={6} md={7} xl={6} className="my-def-help-tooltip">
                                <div className="padR30 pos-relative">
                                    <DropdownComponent
                                        id="defaultDownloadOptions"
                                        options={DownloadOptions}
                                        onChange={handleDefaultDownloadChange}
                                        searchable={true}
                                        menuStyle={{ maxHeight: '120px' }}
                                        selectedValue={selectedDownloadOption}
                                        data-test-auto="CC1D0849-C531-4A43-8A88-CFB97C57D971"
                                        className='DefaultDownloadOptions'
                                    /> 
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
        );
}