import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/types';
import * as Component from './SignatureDraftReport';
import * as SignatureFlowSettingsStore from '../../../store/SignatureFlowSettingsStore';
import * as UserStore from '../../../store/SFUserStore';
import * as DraftReportStore from '../../../store/ReportsStore/DraftReport/DraftReportStore';
import * as SignatureFlowReportCustomColumnStore from '../../../store/ReportsCommon/SignatureFlowReportCustomColumnStore';
import * as SignatureFlowReportFilterStore from '../../../store/ReportsCommon/SignatureFlowReportFilterStore';
import * as SignatureFlowReportPopupModelStore from '../../../store/ReportsCommon/SignatureFlowReportPopupModelStore';
import * as UserProfileStore from '../../../store/userManagement/UserProfileStore';


export default connect(
    (state: ApplicationState) => ({
        DraftReportDocuments: state.DraftReportDocuments,
        userStore: state.sfUserList,
        signatureFlowReportDetails: state.signatureFlowReport,
        signatureFlowReportFilter: state.signatureFlowReportFilter,
        profile: state.userProfile.user,
        companyData: state.companyData,
        signatureFlowSettingsData: state.signatureFlowSettingsData,
        userOfficeLocationState: state.userOfficeLocation,
        pageTitle: "Draft"
    }),
    {
        ...DraftReportStore.actionCreators,
        ...SignatureFlowReportCustomColumnStore.actionCreators,
        ...UserStore.actionCreators,
        ...SignatureFlowReportFilterStore.actionCreators,
        ...SignatureFlowReportPopupModelStore.actionCreators,
        ...SignatureFlowSettingsStore.actionCreators,
        ...UserProfileStore.actionCreators,
    }
)(Component.SignatureDraftReport as any)