export interface ICountryState {
    id: number;
    name: string;
    image: string;
}

export interface ICompanyInfo {
    companyName: string;
    adminUser: number;
    isSsrLogo: boolean;
    id: number;
};



export interface IPrimaryAdmin {
    userId: number;
    firstName: string;
    phoneNumber: string;
    extension: string;
    email: string;
    title: string;
    mobileNumber: string;
    countryCode: string;
};

export interface ICompany {
    companyInfo: ICompanyInfo;
    countryStates: ICountryState[];
}

export interface IAdmin {
    adminId: number;
    adminUsers: IPrimaryAdmin[];
    currentAdmin: IPrimaryAdmin
}

export interface ICompanyLogoSetting {
    isSsrLogo: boolean;
    logoPath: string;
    updatedLogo: Blob;
    isLogoUpdated: boolean;
    isLogoChangedToMyLogo: boolean;
    isCompanyNameChanged: boolean
}

export interface IState {
    company: ICompany;
    companyLogoSetting: ICompanyLogoSetting; 
    showEditCompanyPopUp: boolean;
    showEditPrimaryAdminPopup: boolean;
    showEditCompanyLogoPopup: boolean;
    alert: string;
    message: string;
    showAlert: boolean;
};


export const initialCompanyModel: ICompany = {
    companyInfo: {
        companyName: "",
        adminUser: 0,
        isSsrLogo: false,
        id: 0
    },
    countryStates: [{
        id: 0,
        name: "",
        image: ""
    }]
}

export const initialCompanyLogoSettings: ICompanyLogoSetting = {
    isSsrLogo: false,
    logoPath: "",
    updatedLogo: new Blob([]),
    isLogoUpdated: false,
    isLogoChangedToMyLogo: false,
    isCompanyNameChanged: false
}