
export interface ISignatureFlowReportCustomColumn {
    customColumn: CustomColumnType;
    columnName: string;
    columnOrder: number;
    isActive: number;
}

export enum CustomColumnType {
    Title = 1,
    SentBy = 2,
    DocumentName = 3,
    DocumentType = 4,
    DeliveredDate = 5,
    DocumentNote = 6,
    Recipients = 7,
    SignatureStatus = 8,
    DocumentExpiryDate = 9,
    DownloadStatus = 10,
    LoggedInCPA = 11,
    ClientName = 12,
    TaxYear = 13,
    ClientId = 14,
    ArchivedDate = 15,
    LastReminder = 16,
    AssignedTo = 17,
    DocumentStatusChangedOn = 18,
    officeLocation = 19,
}

export enum ReportTypes {
    None = 0,
    Signature = 1,
    Archived = 2,
    Draft = 3,
    Locked = 4
}
