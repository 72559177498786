import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Route, RouteProps } from "react-router";
import { FeatureDisabled } from "../components/navigation/FeatureDisabled";
import Unauthorized  from "../components/navigation/Unauthorized";
import { ApplicationState } from "../store/types";
import {getPageRoute, isAllRouteDisabled} from "../components/helper/HelperFunctions";
import { IsAlsoSignatureUser } from '../../oidcClient/userRoles';
import LoadingView from "../components/LoadingView";
import history from "../../routes/history";
import {AccessDeniedConstants} from "../components/helper/Constants"
import { AccessDeniedPageSvg, AccessDeniedProductSvg } from "../components/svg/CustomSvgIcons";


export const RBACRoute: FC<RouteProps> = ({ component, path, ...rest }) => {

    const isCompanySfSubscriptionEnabled = useSelector((state: ApplicationState) => state.postAuthDataStore.isCompanySfSubscriptionEnabled);
    const isCompanySignaturesProductEnabled = useSelector((state: ApplicationState) => state.postAuthDataStore.isCompanySignaturesProductEnabled);

    const user = useSelector((state: ApplicationState) => state.auth.user);
    const isAlsoSignatureUser = IsAlsoSignatureUser(user);

    const sSSuiteData = useSelector((state: ApplicationState) => state.SSSuiteStore);
    const allRouteDisabled = sSSuiteData.isdataFetched && isAllRouteDisabled(sSSuiteData.menuList)
    const pageRoute =  isCompanySfSubscriptionEnabled && isCompanySignaturesProductEnabled && isAlsoSignatureUser &&
                            sSSuiteData.isdataFetched && getPageRoute(sSSuiteData.menuList, path,history.push);

    return isCompanySfSubscriptionEnabled && isCompanySignaturesProductEnabled ?
        (
           (isAlsoSignatureUser) ? // Need to confirm if this check is necessary, since access is entirely based on individual permissions
                (
                    (!sSSuiteData.isdataFetched ?
                        <LoadingView/>
                        :
                        (allRouteDisabled ? 
                            <Route component={(props) =><Unauthorized message={AccessDeniedConstants.ACCESSDENIED_MESSAGE_USERPERMISSION}
                                    isUserLogoutEnable={true} icon={<AccessDeniedProductSvg/>} />} {...rest} /> 
                            :
                            !pageRoute ? <></> :
                                pageRoute !== "Unauthorized"
                                ? <Route component={component} path={(pageRoute as string | string[])} {...rest} /> 
                                : 
                                <Route component={(props) =><Unauthorized message={AccessDeniedConstants.ACCESSDENIED_MESSAGE_SCREENPERMISSION}
                                isUserLogoutEnable={false} icon={<AccessDeniedPageSvg/>}  />} {...rest} />
                        )
                    )
                )
                : <Route component={(props) =><Unauthorized message={AccessDeniedConstants.ACCESSDENIED_MESSAGE_USERPERMISSION}
                    isUserLogoutEnable={true} icon={<AccessDeniedProductSvg/>}  />} {...rest} />
        )
        : <Route component={FeatureDisabled} {...rest} />;
}

export default RBACRoute;