import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { IModalProps, IUserProfile, IOTPState, IPasswordUpdateModel } from './ProfileObjects';
import { VenusNotifier } from '../../helper/VenusNotifier';
import * as Validation from '../../helper/Validations';
import * as Loaders from '../../helper/Loader';
import { IUserMetaData } from '../../../../Core/ViewModels/User/UserViewModel';
import { myAccountFetch, GetCountryCode } from '../../helper/HelperFunctions';
import ChangePassword from './ChangePassword';
import { ICompanySettings, IPasswordPolicySettings , ISuiteMFASetting} from '../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { ValidationContants, MyAccountConstants } from '../../helper/Constants';
import { PhoneNumberComponent } from '../../common/PhoneNumberComponent';
import Select from 'react-select';
import { phoneNumberDisplay, countryCodeDisplay } from '../../helper/HelperFunctions';
import { Link } from 'react-router-dom';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { userAutoLogout } from 'src/SignatureFlow/store/auth/actions';
import { LogoutCause } from 'src/SignatureFlow/models/user/UserSettingsModels';
import store from 'src/SignatureFlow/store';

let initialState = {} as IMyAccountState;

interface IMyAccountProps extends IModalProps {
	profile: IUserProfile;
	saveUserProfile: (userInfo: IUserProfile, existingEmailAddress: string, oldPassword: string, notification: boolean, onResponse: (response: any, error: any) => void) => void;
	updatePassword: (passwordUpdateModel: IPasswordUpdateModel, onResponse: (response: any, error: any) => void) => void;
	companySettings: ICompanySettings;
	passwordPolicySettings: IPasswordPolicySettings;
	saveOneTimePassword: (mobileNumber: string, countryCode: string) => void;
	otpData: IOTPState;
	validateOTP: (otp: string, mobileNumber: string, callback: () => void) => void;
	verifyLoading: boolean;
	mfaSettings: ISuiteMFASetting;
}
interface IMyAccountState extends IUserProfile {
	existingEmailAddress: string;
	showChangePassword: boolean;
	showOtpVerification: boolean;
	saving: boolean;
	otpValue: string;
	disableVerifyLink: boolean;
}
class MyAccount extends React.Component<IMyAccountProps, IMyAccountState> {
	constructor(props: IMyAccountProps) {
		super(props);
		this.state = {
			...props.profile,
			existingEmailAddress: '',
			showChangePassword: false,
			showOtpVerification: false,
			saving: false,
			otpValue: '',
			disableVerifyLink: false,
		};


		//Bindings
		this.submitMyAccount = this.submitMyAccount.bind(this);
		this.onChangeFirstName = this.onChangeFirstName.bind(this);
		this.onChangeLastName = this.onChangeLastName.bind(this);
		this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this);
		this.onChangeExtension = this.onChangeExtension.bind(this);
		this.onChangeFaxNumber = this.onChangeFaxNumber.bind(this);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onClickChangePassword = this.onClickChangePassword.bind(this);
		this.onHide = this.onHide.bind(this);
		this.onComplete = this.onComplete.bind(this);
		this.onChangePasswordHide = this.onChangePasswordHide.bind(this);
		this.onChangePasswordComplete = this.onChangePasswordComplete.bind(this);
		this.saveMyAccount = this.saveMyAccount.bind(this);
	}


	UNSAFE_componentWillMount() {
		if (this.props.profile) {
			this.setState({ ...this.props.profile, showChangePassword: this.state.showChangePassword });
		}
		myAccountFetch().then((data) => {
			this.setState({ ...data, existingEmailAddress: data.emailAddress, showChangePassword: this.state.showChangePassword });
			initialState = { ...this.state };
		});
	}
	UNSAFE_componentWillReceiveProps(nextProps: IMyAccountProps) {
		if (nextProps.profile) {
			this.setState({
				...nextProps.profile, showChangePassword: this.state.showChangePassword,
				mobileNumber: this.state.mobileNumber, countryCode: this.state.countryCode,
				isMobileVerify: this.state.isMobileVerify,
				showOtpVerification: this.state.showOtpVerification,
				disableVerifyLink: this.state.disableVerifyLink,
				otpValue: this.state.otpValue
			});
		}
	}

	submitMyAccount() {
		var retSaveMyAccount = true;
		if (this.state.firstName.trim() == '') {
			VenusNotifier.Warning(ValidationContants.FirstNameWarning, "");
			return retSaveMyAccount = false;
		}
		else if (this.state.lastName.trim() == '') {
			VenusNotifier.Warning(ValidationContants.LastNameWarning, "");
			return retSaveMyAccount = false;
		}
		else if (this.state.emailAddress.trim() == "") {
			VenusNotifier.Warning(ValidationContants.EmailAdrressWarning, "");
			return retSaveMyAccount = false;
		}
		else if (!Validation.isValidEmailAddress(this.state.emailAddress.trim()) || this.state.emailAddress.trim() == "") {
			VenusNotifier.Warning(ValidationContants.ValidEmailAddressWarning, "");
			return retSaveMyAccount = false;
        }
		else if (this.props.mfaSettings.isMfaEnabled && !Validation.NullandEmptyCheck(this.state.mobileNumber)) {
            VenusNotifier.Warning(ValidationContants.MobileNumberWarning, "");
            return false;
        } else if (
            this.props.mfaSettings.isMfaEnabled &&
            Validation.NullandEmptyCheck(this.state.mobileNumber) &&
            !Validation.validatePhoneLength(this.state.mobileNumber)
        ) {
            VenusNotifier.Warning(ValidationContants.MobileNumberLengthWarning, "");
            return false;
        } else if (
            this.props.mfaSettings.isMfaEnabled &&
            (this.state.countryCode === "" || this.state.countryCode === undefined)
        ) {
			VenusNotifier.Warning(ValidationContants.CountryCodeWarning, "");
            return false;
		}
        else {
            var phoneNumber = this.state.phone == undefined ? '' : this.state.phone;
            if (phoneNumber.trim() != "") {
                if (Validation.validatePhone(this.state.phone) == false) {
                    return retSaveMyAccount = false;
                }
            }
            var faxNumber = this.state.fax == undefined ? "" : this.state.fax;
            if (faxNumber.trim() != "") {
                if (Validation.validateFax(this.state.fax) == false) {
                    return retSaveMyAccount = false;
                }
            }
            var extension = this.state.extension == undefined ? "" : this.state.extension;
            if (extension.trim() != "") {
                if (Validation.validateExtension(this.state.extension) == false) {
                    return retSaveMyAccount = false;
                }
                else {
                    if (this.state.phone.trim() == "") {
                        VenusNotifier.Warning(ValidationContants.PhoneNumberWarning, "");
                        return retSaveMyAccount = false;
                    }
                }
            }
        }	
		if (retSaveMyAccount) {
			this.saveMyAccount();
		}
	}

	saveMyAccount() {
		const state = this;
		Loaders.ShowLoader();//remove after the impl of content loader
		state.setState({ saving: true, showOtpVerification: false, otpValue: '', disableVerifyLink: false }, () => {
			this.props.saveUserProfile(this.state, this.state.existingEmailAddress, this.state.oldPassword, true, (response: any, error: any) => {
				state.setState({ saving: false });
				Loaders.HideLoader();//remove after the impl of content loader
				if(response){
					initialState = state.state;
					state.setState({ password: "", confirmPassword: "", oldPassword: "" });
					if(this.state.existingEmailAddress !== this.state.emailAddress){
						store.dispatch(userAutoLogout(LogoutCause.EmailUpdated)); 
					}
					state.onComplete();
				}
			});
		});
	}


	onChangeFirstName(event: any) {       
        if (Validation.validateInputTextCharacterMaxLength(event.target.value)) {
            this.setState({ firstName: event.target.value })
        }
    }
    onChangeLastName(event: any) {
        if (Validation.validateInputTextCharacterMaxLength(event.target.value)) {
            this.setState({ lastName: event.target.value })
        }
    }
    onChangePhoneNumber(value: string) {
        this.setState({ phone: value })
     }
	onChangeExtension(event: any) {
		this.setState({ extension: event.target.value })
	}
    onChangeFaxNumber(event: any) {
        if (Validation.ValidateTenDigitNumber(event)) {
            this.setState({ fax: event.target.value })
        }
    }

	onChangeEmail(event: any) {
		this.setState({ emailAddress: event.target.value })
	}
	onClickChangePassword(event: any) {
		this.setState({ showChangePassword: true })
	}

	onChangeMobileNumber = (value: string) => {
		this.setState({
			mobileNumber: value,
			disableVerifyLink: false,
			isMobileVerify: false
		})
	}

	onChangeCountryCode = (event: any) => {
		if(event !== null){
			this.setState({
				countryCode: event.value,
				disableVerifyLink: false,
				isMobileVerify: false
			})
		}
	}

	onHide() {
		this.setState({
			...initialState,
			mobileNumber: this.props.profile.mobileNumber,
			countryCode: this.props.profile.countryCode,
			isMobileVerify: this.props.profile.isMobileVerify,
			showOtpVerification: false
		}, () => {
			this.props.onHide();
		});
	}

	onComplete() {
		this.props.onHide();
	}

	onChangePasswordHide() {
		this.setState({ showChangePassword: false })
	}
	onChangePasswordComplete() {
		this.setState({ showChangePassword: false })
		this.onHide();
	}
	private isControlDisabled = (property: string): boolean => {
		const user = this.props.profile;
		if (user && user.authenticationProviders && user.authenticationProviders.length > 0) {
			let metaData = JSON.parse(user.metadata) as IUserMetaData;
			if (metaData) {
				for (var i = 0; i < user.authenticationProviders.length; i++) {
					let provider = user.authenticationProviders[i];
					let providerData = metaData.readonlyFields.providerData.find(x => x.provider === provider);
					if (providerData) {
						for (var j = 0; j < providerData.fields.length; j++) {
							if (providerData.fields[j] === property)
								return true;
						}
					}
				}
			}
		}
		return false;
	}

	onChangeOtpValue = (event: any) => {
		if (Validation.ValidateTenDigitNumber(event)) {
			let value = event.target.value;
			this.setState({ otpValue: value }, () => {
				if (this.props.otpData.mfaOTPLength === value.length) {
					let mobileNumber = this.state.countryCode + this.state.mobileNumber;
					this.props.validateOTP(value, mobileNumber, this.hideVerifyLink);
				}
			});
		}
	}

	hideVerifyLink = () => {
		if (this.props.otpData.validOTP) {
			this.setState({
				isMobileVerify: true,
				showOtpVerification: false
			})
		}
	}

	onClickVerifyOTP = (event: any) => {
		if (this.validateMobileNumber()) {
			this.disableVerifyLink(true);
			setTimeout(() => this.disableVerifyLink(false), 108000)
			let mobileNumber = this.state.countryCode + this.state.mobileNumber;
			let countryCode = this.state.countryCode;
			this.setState({
				showOtpVerification: true,
				otpValue: ''
			}, () => { this.props.saveOneTimePassword(mobileNumber, countryCode); })
		}
	}

	disableVerifyLink = (show: boolean) => {
		this.setState({
			disableVerifyLink: show
		})
	}

	validateMobileNumber = () => {
		if (!Validation.NullandEmptyCheck(this.state.mobileNumber)) {
			VenusNotifier.Warning(ValidationContants.MobileNumberWarning, "");
			return false;
		}
		else if (Validation.NullandEmptyCheck(this.state.mobileNumber) && !Validation.validatePhoneLength(this.state.mobileNumber)) {
			VenusNotifier.Warning(ValidationContants.MobileNumberLengthWarning, "");
			return false;
		}
		else if (this.state.countryCode === "" || this.state.countryCode === undefined) {
			VenusNotifier.Warning(ValidationContants.CountryCodeWarning, "");
			return false;
		}
		else {
			return true;
		}
	}


	public render() {
		return <Modal show={this.props.showState} onHide={this.onHide}>
			<Modal.Header closeButton data-test-auto="7A232988-989E-4DEB-9B10-6EF9BDFBAA37">
				<Modal.Title>
					<span className='fas fa-user ddl-icon my-account-icon'></span>
                    My Account
                    </Modal.Title>
			</Modal.Header>
			{
				this.props.profile && this.props.profile.authenticationProviders
					&& this.props.profile.authenticationProviders.length > 0 ? null : (
						<ChangePassword
							show={this.state.showChangePassword}
							profile={this.props.profile}
							onCancel={this.onChangePasswordHide}
							onComplete={this.onChangePasswordComplete}
							updatePassword={this.props.updatePassword}
							companySettings={this.props.companySettings}
							passwordPolicySettings={this.props.passwordPolicySettings}
						/>)
			}

			<Modal.Body>
				<LoadingOverlay>
					<div className="row">
						<div className="col-sm-6 col-md-6 col-xl-6">
							<div className="marB10 pos-relative">
								<input type="text" className="form-control"
									placeholder="First Name"
									value={this.state.firstName}
									onChange={this.onChangeFirstName}
									data-test-auto="55A2B5B9-54F1-4E19-ABE7-A8C992027401"
									disabled={this.isControlDisabled("FirstName")}
									data-lpignore="true"
								></input>
								<span className="fas fa-asterisk compulsory-star1"></span>
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-xl-6">
							<div className="marB10 pos-relative">
								<input type="text"
									className="form-control"
									placeholder="Last Name"
									ref="lasname"
									value={this.state.lastName}
									onChange={this.onChangeLastName}
									data-test-auto="9962560F-A954-4EED-848B-2E50FC36B739"
									disabled={this.isControlDisabled("LastName")}
									data-lpignore="true"
								></input>
								<span className="fas fa-asterisk compulsory-star1"></span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12 col-md-12 col-xl-12">
							<div className="marB10 pos-relative">
								<input type="text"
									className="form-control"
									placeholder="Email"
									value={this.state.emailAddress}
									onChange={this.onChangeEmail}
									data-test-auto="3F21CD09-C6AF-46F0-81A4-F42934F36903"
									disabled={this.isControlDisabled("EmailAddress")}
									data-lpignore="true"
								/>
								<span className="fas fa-asterisk compulsory-star1" />
							</div>
							<div className="marB10">
								<input type="text"
									className="form-control"
									placeholder="Fax Number"
									value={this.state.fax}
									onChange={this.onChangeFaxNumber}
									data-test-auto="C0402DCD-B191-47C1-BACD-28AE23AF3AE9"
									disabled={this.isControlDisabled("Fax")}
									data-lpignore="true" />
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-sm-3 col-md-3 col-xl-3">
							<div className="marB10 pos-relative">
								<Select
									name="form-field-country-code"
									value={this.state.countryCode}
									onChange={this.onChangeCountryCode}
									options={GetCountryCode()}
									data-test-auto="36B0F044-D3EA-4EB1-AC67-BCC81343A99C"
									clearable={false}
								/>
								{this.props.mfaSettings.isMfaEnabled ? (
                                        <span className="fas fa-asterisk compulsory-star1"></span>
                                ) : null}										
							</div>
						</div>
						<div className="col-sm-5 col-md-5 col-xl-5">
							<div className="marB10 pos-relative">
								<PhoneNumberComponent
									phoneNumber={this.state.mobileNumber}
									handleChangePhoneNumber={this.onChangeMobileNumber}
									disabled={false}>
								</PhoneNumberComponent>
								{this.props.mfaSettings.isMfaEnabled ? (
                                        <span className="fas fa-asterisk compulsory-star1"></span>
                                ) : null}								
							</div>
						</div>
						{this.state.isMobileVerify ? null :
							<div className="col-sm-4 col-md-4 col-xl-4">
								<a className={this.state.disableVerifyLink ? 'disableAnchor' : 'enableAnchor'} href="javascript:void(0)" onClick={this.onClickVerifyOTP} ><u>
									Verify</u></a>
								<Link to={'#'}
									className="help-icon marL05"
									data-placement="right"
									data-toggle="tooltip"
									title="You can skip the verification step. On next login, you will be prompted to verify the mobile number">
									<i className="fas fa-question-circle"></i>
								</Link>
							</div>
						}
					</div>
					{this.state.showOtpVerification ?
						<div className="row padB10">
							<div className="col-sm-8 col-md-8 col-xl-8">
								Enter the access code sent to {countryCodeDisplay(this.state.countryCode)}
								{phoneNumberDisplay(this.state.mobileNumber)}
							</div>
							<div className="col-sm-3 col-md-3 col-xl-3">
								<input type="text"
									className="form-control"
									placeholder="Access code"
									value={this.state.otpValue}
									onChange={this.onChangeOtpValue}
									data-test-auto="5E794F51-B0E5-4780-80C5-817296F78AB0"
									maxLength={this.props.otpData.mfaOTPLength}

								/>
							</div>
						</div> : null
					}
					<div className="row">
						<div className="col-sm-9 col-md-9 col-xl-9">
							<div className="marB10">
								<PhoneNumberComponent
									phoneNumber={this.state.phone}
									handleChangePhoneNumber={this.onChangePhoneNumber}
									disabled={this.isControlDisabled("Phone")}>
								</PhoneNumberComponent>
							</div>
						</div>
						<div className="col-sm-3 col-md-3 col-xl-3">
							<div className="marB10">
								<input type="text"
									className="form-control"
									name="Extension"
									placeholder="Ext"
									value={this.state.extension}
									onChange={this.onChangeExtension}
									data-test-auto="FD934570-D098-4381-9D25-C98C0F4D7556"
									disabled={this.isControlDisabled("Extension")}
									data-lpignore={"true"} />
							</div>
						</div>
					</div>
					{
						this.props.profile && this.props.profile.authenticationProviders
							&& this.props.profile.authenticationProviders.length > 0 ? null : (
								<div className="row">
									<div className="col-sm-4 col-md-4 col-xl-4">
										<i className='fas fa-user-shield padR05'></i>
										<a href="javascript:void(0)" onClick={this.onClickChangePassword} ><u>Change Password</u></a>
									</div>
								</div>
							)}
					<Loader loading={this.props.verifyLoading} />
				</LoadingOverlay>
			</Modal.Body>
			<Modal.Footer>
				<span className="modal-footer-left float-left padT07">
					<span className="fas fa-asterisk compulsory-star-only padR05"></span>
					<span>Required</span>
				</span>
				<Button
					variant='default'
					onClick={this.onHide}
					className="btn-white"
					data-test-auto="4A0C3490-369E-49E9-A36A-3139973D5A81">
						Cancel
                </Button>
				<Button
					variant='info'
					onClick={this.submitMyAccount}
					data-test-auto="3CDE45EA-64A3-49F4-B6F4-A122088BA80B">
						Save
                 </Button>
			</Modal.Footer>
		</Modal>
	}
}
export default MyAccount;
