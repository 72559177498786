//To prevent from overriding clientInfo details created by other products during editClientInfo,
//All the clientInfo properties in IClientInfoViewModel is required.

export interface IClientInfoMismatchModal {
    showModal: boolean;
    isLoading: boolean;
    clientBasicInfoId: number;
    canShowModal?:boolean;
    clientInfoCallbackEvent?:ClientInfoCallbackEvent;
    clientInfoMismatchCallback?: any;
}

export interface IClientInfoViewModel {
    clientBasicInfoId: number;
    clientId: string;
    name: string;
    emailAddress?: string;
    countryCode?: string;
    mobileNumber?: string;
    type?: CMClientType;
    uid?: string;
    ero?: string;
    dob?: Date | null;
    isDeceased?: boolean;
    defaultFirstSigner?: boolean;
    spouseName?: string;
    spouseSsn?: string;
    spouseEmailAddress?: string;
    spouseDob?: Date | null;
    spouseCountryCode?: string;
    spouseMobileNumber?: string;
    spouseIsDeceased?: boolean;
    mfj?: boolean;
    totalCount?: number;
    eroFirstName?: string;
    eroLastName?: string;
    locationId:number;
    locationName?:string;
}

export interface IClientInfoMismatchViewModel {
    clientId: IComparisonMismatchViewModel;
    name: IComparisonMismatchViewModel;
    uid?: IComparisonMismatchViewModel;
    emailAddress?: IComparisonMismatchViewModel;
    countryCode?: IComparisonMismatchViewModel;
    mobileNumber?: IComparisonMismatchViewModel;
    ero?: IComparisonMismatchViewModel;
    isDeceased?: IComparisonMismatchViewModel;
    dob?: IComparisonMismatchViewModel;
    spouseSSN?: IComparisonMismatchViewModel;
    spouseName?: IComparisonMismatchViewModel;
    spouseEmailAddress?: IComparisonMismatchViewModel;
    spouseCountryCode?: IComparisonMismatchViewModel;
    spouseMobileNumber?: IComparisonMismatchViewModel;
    spouseDOB?: IComparisonMismatchViewModel;
    spouseIsDeceased?: IComparisonMismatchViewModel;
    locationName: IComparisonMismatchViewModel;
}

export interface IClientInfoComparisonResultViewModel {
    comparisonStatus: ClientInfoComparisionCode;
    clientInfoResultData: IClientInfoMismatchViewModel; 
}


export interface IComparisonMismatchViewModel {
    hadDifference: boolean;
    taxReturnValue?: string;
    clientManagementValue?: string;
}

export enum ClientInfoComparisionCode {
    NoDataMismatch,
    DataMismatch,
    SSNMismatch,
    ClientIdMismatch,
    NewClient
}

export enum CMClientType {
    None = 0,
    SSN = 1,
    EIN = 2,
}

export enum ClientInfoCallbackEvent {
    None = 0,
    Next = 1,
    Save = 2,
    Deliver = 3
}

export const columnNames = {
    clientInformation: "Client Information",
    taxReturn: "Signatures",
    clientManagement: "Client Management"
};

export const clientRows = {
    clientId: "Client ID",
    name: "Name",
    locationName:"Office",
};

export const defaultOrder = [
    "clientId",
    "name",
    "locationName",
];

export const initialClientInfoViewModel: IClientInfoViewModel = {
    clientBasicInfoId: 0,
    clientId: '',
    name: '',
    locationName: '',
    locationId: 0
}

export const initialClientInfoComparisonResultViewModel: IClientInfoComparisonResultViewModel = {
    comparisonStatus: ClientInfoComparisionCode.NewClient,
    clientInfoResultData: {
        clientId: {
            hadDifference: false,
            taxReturnValue: '',
            clientManagementValue: ''
        },
        name: {
            hadDifference: false,
            taxReturnValue: '',
            clientManagementValue: ''
        },
        locationName: {
            hadDifference: false,
            taxReturnValue: '',
            clientManagementValue: ''
        }
    }
}

export const initialClientInfoMismatchModalState: IClientInfoMismatchModal = {
    showModal: false,
    isLoading: false,
    clientBasicInfoId: 0,
    canShowModal:true,
    clientInfoCallbackEvent:ClientInfoCallbackEvent.None
}

export const setNewClientInfoComparisonResult = (clientInfo: IClientInfoViewModel) => {
    const data: IClientInfoComparisonResultViewModel = {
        comparisonStatus: ClientInfoComparisionCode.NewClient,
        clientInfoResultData: {
            clientId: {
                hadDifference: !!clientInfo.clientId,
                taxReturnValue: clientInfo.clientId
            },
            name: {
                hadDifference: !!clientInfo.name,
                taxReturnValue: clientInfo.name
            },
            locationName: {
                hadDifference: !!clientInfo.locationName,
                taxReturnValue: clientInfo.locationName
            }
        }
    };
    return data;
};
