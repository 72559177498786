import { connect } from 'react-redux';
import { ApplicationState } from '../store/types';
import { SendForSignature } from './SendForSignature';
import * as DocumentStore from '../store/DocumentStore';
import * as SignatureFlowSettingsStore from '../store/SignatureFlowSettingsStore';
import * as SFStore from '../store/SFStore';
import * as UserProfileStore from '../store/userManagement/UserProfileStore';
import * as AllUsersStore from '../store/SFUserStore';
import * as ContactsStore from '../store/ContactsStore';
import * as SignatureStore from '../store/SignatureStore';
import * as SignatureFlowReportPopupModelStore from '../store/ReportsCommon/SignatureFlowReportPopupModelStore';
import * as SignatureFlowSavedMessageStore from '../store/settings/SavedMessage/SavedMessageStore';
import * as SignatureFlowAuthenticationQuestionsStore from '../store/settings/AuthenticationQuestions/AuthenticationQuestionsStore';

export default connect(
    (state: ApplicationState) => ({
        sfUploadStore: state.sfUploadStore,
        signatureFlowSettingsData: state.signatureFlowSettingsData,
        profile: state.userProfile,
        userList: state.sfUserList,
        contactsStore: state.contactsStore,
        settings: state.userSettings,
        companyData: state.companyData,
        sendForSignatureData: state.sfStore,
        recognizedSignatureFlowDocumentsInfo: state.recognizedSignatureFlowDocumentsInfo,
        signingInfoDictionary:state.signatureStore,
        officeLocations: state.officeLocation,
        userOfficeLocation: state.userOfficeLocation,
        SavedMessageData: state.SavedMessageData,
        AuthenticationQuestionsData: state.AuthenticationQuestionsData,
    }),
    {
        ...DocumentStore.actionCreators,
        ...SignatureFlowSettingsStore.actionCreators,
        ...SFStore.actionCreators,
        ...UserProfileStore.actionCreators,
        ...AllUsersStore.actionCreators,
        ...ContactsStore.actionCreators,
        ...SignatureStore.actionCreators,
        ...SignatureFlowReportPopupModelStore.actionCreators,
        ...SignatureFlowSavedMessageStore.actionCreators,
        ...SignatureFlowAuthenticationQuestionsStore.actionCreators
    }
)(SendForSignature as any);