import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/types';
import * as SignatureFlowSettingsStore from '../../../store/SignatureFlowSettingsStore';
import * as SignatureFlowClientInstructionsStore from '../../../store/settings/ClientInstructions/ClientInstructionsStore';
import { ClientInstructions } from './ClientInstructions';


export default connect(
    (state: ApplicationState) => ({
        signatureFlowSettingsData: state.signatureFlowSettingsData,
        ClientInstructionsData : state.ClientInstructionsData
    }),
    {
        ...SignatureFlowSettingsStore.actionCreators,
        ...SignatureFlowClientInstructionsStore.actionCreators
    }
)(ClientInstructions as any);