import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ISignatureFlowSettings, initialQuestion, IAuthenticationQuestion } from '../../../models/SignatureFlowSettings';
import * as SignatureFlowSettingsStore from '../../../store/SignatureFlowSettingsStore';
import * as SignatureFlowAuthenticationQuestionsStore from '../../../store/settings/AuthenticationQuestions/AuthenticationQuestionsStore';
import * as bootbox from 'bootbox';
import { AddQuestionPopup } from '../AddQuestionPopup';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { SecuritySettingConstants } from '../../helper/Constants';
import { PencilIcon, PlusLg, TrashIcon } from '../../svg/CustomSvgIcons';
import * as Constants from '../../helper/Constants';
const isEqual = require("react-fast-compare");

   
export interface ISecuritySettingsModalProps {
    resourceId: string;
    signatureFlowSettings: ISignatureFlowSettings;
    authenticationQuestions: SignatureFlowAuthenticationQuestionsStore.IAuthenticationQuestionDictionary;
    updateCompanySettings: (signatureFlowSettings: ISignatureFlowSettings, settingsType?: Constants.typeOfSettings) => void;
    saveAuthenticationQuestion: (question: IAuthenticationQuestion, isSetAsDefault: boolean) => void;
    updateAuthenticationQuestion: (question: IAuthenticationQuestion) => void;
    deleteAuthenticationQuestion: (question: IAuthenticationQuestion) => void;
}

export interface ISecuritySettingsModalState {
    showAuthenticationQuestionPopUp: boolean;
    isUpdateQuestion: boolean;
    buttonText: string;
    title: string;
    selectedQuestion: number;
}

export class SecuritySettingsModal extends React.Component<ISecuritySettingsModalProps, ISecuritySettingsModalState> {
    state = {
        enableSignerAuthentication: false,
        enableSignerDelegation: false,
        allowUsersToAdd: false,
        showAuthenticationQuestionPopUp: false,
        isUpdateQuestion: false,
        buttonText: '',
        title: '',
        selectedQuestion: 0
    };

    componentDidMount() {
        if (this.props.signatureFlowSettings) {
            this.setState({
                selectedQuestion: this.props.signatureFlowSettings.securitySettings.questionSettings.defaultQuestion
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: ISecuritySettingsModalProps) {
        if (!isEqual(this.props, nextProps)) {
            this.setState({
                selectedQuestion: nextProps.signatureFlowSettings ?
                    nextProps.signatureFlowSettings.securitySettings.questionSettings.defaultQuestion : 0
            });
        }
    }

    handleToggleSwitchChange = (event: any) => {
        let companySettings = this.props.signatureFlowSettings;
        companySettings.securitySettings.enableSignerAuthentication = event.target.checked;
        this.props.updateCompanySettings(companySettings, Constants.typeOfSettings.AuthenticationQuestions);
    }
    
    
    handleAllowUsersToAddChange = (event: any) => {
        let companySettings = this.props.signatureFlowSettings;
        companySettings.securitySettings.questionSettings.allowUsersToAdd = event.target.checked;
        this.props.updateCompanySettings(companySettings, Constants.typeOfSettings.AuthenticationQuestions);
    }

    onAddButtonClick = () => {
        this.setState({
            showAuthenticationQuestionPopUp: true,
            isUpdateQuestion: false,
            buttonText: 'Add',
            title: 'Add Security Question'
        });
    }

    onListItemClick = (id: number) => {
        this.setState({
            selectedQuestion: id
        });
    }

    onCancelButtonClick = () => {
        this.setState({
            showAuthenticationQuestionPopUp: false,
            isUpdateQuestion: false
        });
    }

    onEditButtonClick = () => {
        this.setState({
            showAuthenticationQuestionPopUp: true,
            isUpdateQuestion: true,
            buttonText: 'Save',
            title: 'Edit Security Question'
        });
    }

    onDeleteButtonClick = () => {
        if (this.state.selectedQuestion == this.props.signatureFlowSettings.securitySettings.questionSettings.defaultQuestion) {
            bootbox.alert({
                title: 'Invalid Action',
                message: 'The default question cannot be deleted.',
                size: 'small',
                buttons: {
                    ok: {
                        label: 'OK',
                        className: 'btn-info modal-footer-button-save'
                    }
                }
            }).find(".modal-dialog")
            .addClass("modal-dialog-centered");
        }
        else {
            let _currentObject = this
            bootbox.confirm({
                title: 'Delete Question',
                message: 'You are about to delete the selected question. This cannot be undone, do you want to proceed?',
                buttons: {
                    cancel: {
                        label: 'Cancel',
                        className: 'btn-white modal-footer-button-cancel'
                    },
                    confirm: {
                        label: 'Delete',
                        className: 'btn-info modal-footer-button-save'
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        _currentObject.props.deleteAuthenticationQuestion(_currentObject.props.authenticationQuestions[_currentObject.state.selectedQuestion].authenticationQuestion);
                        _currentObject.setState({ selectedQuestion: _currentObject.props.signatureFlowSettings.securitySettings.questionSettings.defaultQuestion });
                    }
                }
            }).find(".modal-dialog")
            .addClass("modal-dialog-centered");
        }
    }

    editQuestion = (question: IAuthenticationQuestion, isSetDefault: boolean) => {
        let signatureFlowSettings = this.props.signatureFlowSettings;
        if (signatureFlowSettings) {
            if (question.id == signatureFlowSettings.securitySettings.questionSettings.defaultQuestion && !isSetDefault) {
                //Default question should not be turned off
                VenusNotifier.Warning(SecuritySettingConstants.SelectDefaultQuestionWarning, null);
                return false;
            }
            else if (isSetDefault && question.id != signatureFlowSettings.securitySettings.questionSettings.defaultQuestion) {
                signatureFlowSettings.securitySettings.questionSettings.defaultQuestion = question.id;
                this.props.updateCompanySettings(signatureFlowSettings);
            }

            this.props.updateAuthenticationQuestion(question);
            this.setState({
                showAuthenticationQuestionPopUp: false,
                isUpdateQuestion: false
            })
        }
        else {
            VenusNotifier.Error(SecuritySettingConstants.UpdateQuestionError, null);
        }
    }

    addQuestion = (question: IAuthenticationQuestion, isSetDefault: boolean) => {
        let signatureFlowSettings = this.props.signatureFlowSettings;
        if (signatureFlowSettings) {
            this.props.saveAuthenticationQuestion(question, isSetDefault);
            this.setState({
                showAuthenticationQuestionPopUp: false,
                isUpdateQuestion: false
            })
        } else {
            VenusNotifier.Error(SecuritySettingConstants.SaveQuestionError, null);
        }
    }

    public render() {
        const questionList = this.props.authenticationQuestions ? this.props.authenticationQuestions : [];
        const currentQuestion = this.props.authenticationQuestions &&
            this.props.authenticationQuestions[this.state.selectedQuestion] ?
            this.props.authenticationQuestions[this.state.selectedQuestion].authenticationQuestion :
            initialQuestion;
        const defaultQuestion = this.props.signatureFlowSettings ?
            this.props.signatureFlowSettings.securitySettings.questionSettings.defaultQuestion : 0;
        const authenticationQuestions = this.props.authenticationQuestions;
        const listHeightStyle = Object.keys(questionList).length > 4 ? 'calc(37px * 4 - 2px)' : 'calc(37px * 4 - 1px)';

        return <div className="new-main-content font14 security-settings-container" data-resource-id={this.props.resourceId}>

            <div className="row" style={{ position: "relative", marginLeft:'0px' }}>
                <div style={{ marginLeft: 15, paddingBottom:'5px',paddingTop:'px' }}>
                    <div className="row">
                        <div className="col-sm-10 padL00 float-left">
                            <h4 className='sigflow-settings-heading padB15'>Authentication Options</h4>
                        </div>
                    </div>

                    <div className="row" style={{ alignItems: "center" }}>
                        <div style={{width:'367px', marginLeft:'15px'}}>
                            <Row>
                                <div style={{width:'49px'}}>
                                    <label className="toggleSwitch">
                                        <input type="checkbox"
                                            checked={this.props.signatureFlowSettings.securitySettings.enableSignerAuthentication}
                                            onChange={this.handleToggleSwitchChange}></input>
                                        <span className="columnFilterSlider round"></span>
                                    </label>
                                </div>
                                <div className="text-left" style={{paddingTop: '8px',paddingBottom:'42px'}}>
                                    <div className="font16"> Signer Authentication </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <span style={{fontWeight:'700',height:'24px',width:'77px'}}>Questions</span>
                <div className={"custom-curve-check-box"} style={{paddingTop:'20px'}}>
                            <div className="form-check" style={{paddingLeft:'0px'}}>
                                <input
                                    id="AllowUsersToAdd"
                                    className="form-check-input magic-checkbox" 
                                    type="checkbox" 
                                    value="" 
                                    checked={this.props.signatureFlowSettings.securitySettings.questionSettings.allowUsersToAdd}
                                    data-test-auto="308ED508-244D-4C56-9FBB-F08C2FA80E47"
                                    onChange={this.handleAllowUsersToAddChange}>
                                </input>
                                <label htmlFor={'AllowUsersToAdd'} 
                                        className={"form-check-label "}>
                                    <span className='fontSize14'>Allow user to add new questions while processing.</span>
                                </label>
                            </div>
                </div>
            </div>

            <div className="row" style={{ position: "relative", marginLeft:'0px',paddingTop:'20px' }}>
                <div className='padR70'>
                    <div className="dispFR settings-list-container custom-scroll-bar" style={{ marginTop: 31.5, overflowY: 'auto', height: listHeightStyle,  width: 367}}>
                        <div>
                            <ul className="list-group">
                                {
                                    Object.keys(questionList).map((key, index) => {
                                        const liClassName = " padR20 padT07-i " + (authenticationQuestions[parseInt(key)].authenticationQuestion.id ===
                                                            this.state.selectedQuestion ? "custom-list-group-item-singleline custom-active " :
                                                            "custom-list-group-item-singleline ") +
                                                            (index === 0 ? 'bord-t-0-i ' : '') + 
                                                            ((Object.keys(questionList).length < 4 && index === Object.keys(questionList).length - 1) ? "bord-b-1 " : "");
                                                            
                                        return (<li
                                            key={authenticationQuestions[parseInt(key)].authenticationQuestion.id}
                                            className={liClassName}
                                            title={questionList[parseInt(key)].authenticationQuestion.title}
                                            onClick={() => this.onListItemClick(parseInt(key))}
                                        >
                                            <div className="width100 row " style={{ margin: 0 }}>
                                                <div
                                                    className={"overflowHidden ellipsis font16 " + (this.props.authenticationQuestions[parseInt(key)].authenticationQuestion.id == defaultQuestion ?
                                                        "col-md-10 nopadding " : "")}>
                                                    {questionList[parseInt(key)].authenticationQuestion.title}
                                                </div>

                                                {authenticationQuestions[parseInt(key)].authenticationQuestion.id == defaultQuestion ?
                                                    <div className="col-md-2 pad00 ">
                                                        <span className={"default-indicator-batch"} style={{ marginBottom: 3, float: 'initial' }}>Default</span>
                                                    </div>
                                                    : <span></span>
                                                }
                                            </div>
                                        </li>)
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <Button
                        data-test-auto="02E8EEC4-5E5F-4E02-8D32-5AE4072203F7"
                        variant='default'
                        className='btn-white settings-add-btn'
                        onClick={this.onAddButtonClick}
                        title="Add Security Question">
                        <PlusLg  marginBottom={'4px'}/><span style={{paddingLeft:'5px'}}>Add</span>
                    </Button>
                    
                </div>
                <div>
                    <div className="row text-message dispFR" style={{marginLeft:'0px', marginTop: '10px', padding:'0px', border:'unset',paddingTop:'20px'}}>
                        <div className='custom-scroll-bar settings-list-container' style={{ height: "100%", overflowY: "scroll" }}>
                            <div  style={{padding:'14px 33px 15px 11px'}}>
                                <div className="form-group">
                                    <label>
                                        Title
                                    </label>
                                    <div className="form-control ellipsis" style={{ backgroundColor: '#f1f1f1' }} title={currentQuestion.title}>{currentQuestion.title}</div>
                                </div>
                                <div className="form-group">
                                    <label >Question Text</label>
                                    <div>
                                        <textarea
                                            className="form-control custom-scroll-bar"
                                            value={currentQuestion.text}
                                            style={{ overflowY: 'auto', height: '145px', backgroundColor: '#f1f1f1' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button
                        variant='default'
                        className='btn-white settings-add-btn'
                        data-test-auto="CD1E6997-E3F0-43BC-AFDB-1307FA7D76BF"
                        title="Edit Question"
                        onClick={this.onEditButtonClick}
                    >
                        <PencilIcon  marginBottom={'4px'}/><span style={{paddingLeft:'5px', paddingTop:'1px'}}>Edit</span>
                    </Button>
                    <Button
                        variant='default'
                        className='btn-white settings-add-btn'
                        data-test-auto="49A5F19B-F4CE-422A-966D-BC421BBD86C3"
                        title="Delete Question"
                        style={{ marginLeft: 11}}
                        onClick={this.onDeleteButtonClick}
                    >
                        <TrashIcon marginBottom={'4px'}/><span style={{paddingLeft:'5px', paddingTop:'1px'}}>Delete</span>
                    </Button>
            </div>
            </div>
            <AddQuestionPopup
                showState={this.state.showAuthenticationQuestionPopUp}
                onHide={this.onCancelButtonClick}
                submitButtonClick={this.state.isUpdateQuestion ? this.editQuestion : this.addQuestion}
                buttonText={this.state.buttonText}
                selectedQuestion={this.state.isUpdateQuestion ? currentQuestion : initialQuestion}
                title={this.state.title}
                defaultQuestionId={defaultQuestion}
                isUpdate={this.state.isUpdateQuestion}
                questions={this.props.authenticationQuestions}
            />
        </div>;
    }
}

export default SecuritySettingsModal;
