import * as React from 'react';
import * as Bs from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { GetFormatedDateTime } from '../../../components/helper/HelperFunctions';
import { OverlayLoader } from '../../../components/helper/OverlayLoader';
import { ISignatureFlowReportDocument, SignatureFlowDocumentEvent } from '../../../models/SignatureFlowReportState';
import { MailEvent } from '../../../components/common/MailEvent';
import { SignatureReportClientTrackingEvents } from '../../helper/Constants';
import Pagination from '../../common/Pagination';

interface IClientTrackingProps {
    show: boolean;
    onCancel: () => void;
    selectedDocument: ISignatureFlowReportDocument;
}
export interface IClientTrackingState {
    pageNo: number
}


export class SignatureFlowReportClientTrackingModal extends React.Component<IClientTrackingProps, IClientTrackingState> {
    constructor(props: IClientTrackingProps) {
        super(props);
        this.state = {
            pageNo:1
        }
        this.onPageChange = this.onPageChange.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    private  onPageChange(page: number, pageSize: number) {
        this.setState({pageNo: page})
    }

    private onHide() {
        this.setState({pageNo: 1}, this.props.onCancel)
    }

    private onCloseModal(){
        this.setState({pageNo: 1}, this.props.onCancel)
    }
    public render() {       
        const columns = [
            {
                header: 'Event',
                key: 'eventId',
                isKey: true,
                dataFormat: this.eventIdType,
                columnClassName: 'overflowTextClientTracking',
                className:'table-column-header',
                dataSort: true,
                width:'340px',
                toolTip: true,
                hidden: false
            },
            {
                header: 'By',
                key: 'actedBy',
                isKey: false,
                dataFormat: this.actedByType,
                columnClassName: 'overflowTextClientTracking',
                className:'table-column-header',
                dataSort: true,
                width:'auto',
                toolTip: true,
                hidden: false
            },
            {
                header: 'Date',
                key: 'actedOn',
                isKey: false,
                dataFormat: this.actedOnType,
                columnClassName: 'overflowTextClientTracking',
                className:'table-column-header',
                dataSort: true,
                toolTip: true,
                width:'auto',
                hidden: false
            },
            {
                header: 'Data',
                key: 'eventData',
                isKey: false,
                hidden: true,
                dataFormat: this.defaultType,
                columnClassName: '',
                className:'',
                dataSort: false,
                width:'auto',
                toolTip: false
            }
        ];     

        const clientTrackingdata = this.props.selectedDocument && this.props.selectedDocument.clientTracking ?
            this.props.selectedDocument.clientTracking.filter(x => x.eventId != SignatureFlowDocumentEvent.DownloadEmail) : [];

        const downloadMailData = this.props.selectedDocument && this.props.selectedDocument.clientTracking ?
            this.props.selectedDocument.clientTracking.filter(x => x.eventId == SignatureFlowDocumentEvent.DownloadEmail
                && x.eventData.mailEventId == MailEvent.None)
            : [];

        if (downloadMailData.length > 0) {
            downloadMailData.map(x => { clientTrackingdata.push(x) });
        }

        const data = clientTrackingdata.length > 0 ? clientTrackingdata.sort((a: any, b: any) => {
            if (a.actedOn > b.actedOn) return 1;
            else return -1;
        }).map((model, index) => {
            return {
                eventId: model.eventId,
                actedBy: (model.actedBy && model.actedBy.emailAddress),
                actedOn: model.actedOn,
                eventData: model.eventData
            }
        }) : [];

        const sortedData = data.slice(10 * (this.state.pageNo - 1),(data.length/10) >= this.state.pageNo ? (this.state.pageNo * 10) : ((this.state.pageNo * 10) - (10 -(data.length % 10))) +1 )        

        let modalBody = this.props.selectedDocument && this.props.selectedDocument.clientTracking ?
            <BootstrapTable
                data={sortedData}
                striped                
                containerClass='report-table-container'
                tableHeaderClass='report-header-border'
                bordered={false}
                pagination={false}>
                {columns.map((value, index) => {
                    return <TableHeaderColumn key={index}  
                                              hidden={value.hidden} 
                                              isKey={value.isKey} 
                                              dataField={value.key} 
                                              width={value.width}
                                              dataFormat={value.dataFormat}
                                              columnClassName={value.columnClassName} 
                                              className={value.className ? value.className : ''} 
                                              dataSort={true} columnTitle={value.toolTip}>
                                                {value.header}
                            </TableHeaderColumn>;
                })}
            </BootstrapTable> : (<OverlayLoader
                show={this.props.show}
                text={"Loading, please wait..."} />);

        return <Bs.Modal className="client-tracking-modal" show={this.props.show} centered={true} backdrop="static" onHide={() => { this.onHide() }}>
            <Bs.Modal.Header closeButton>
                <Bs.Modal.Title>
                    Client Tracking History
                </Bs.Modal.Title>
            </Bs.Modal.Header>
            <Bs.Modal.Body>
                <div>
                    {modalBody}
                </div>
                <div style={{height:'35px', marginTop: '20px'}}>
                            <Pagination
                            totalCount={this.props.selectedDocument?.clientTracking ? data.length : 0}
                            pageIndex={this.state.pageNo}
                            pageSize={10}
                            defaultPageSize={10}
                            goToPage = {this.onPageChange}
                            showpageCount={true}
                            ShowListAndGoto={false}
                            />
                    </div>
            </Bs.Modal.Body>
            <Bs.Modal.Footer>
                <Bs.Button
                    variant="default"
                    className='btn-white modal-footer-button-cancel'
                    onClick={this.onCloseModal}>
                    Close
                </Bs.Button>
            </Bs.Modal.Footer>
        </Bs.Modal>
    }


    defaultType(cell: any, row: any) {
        return cell;
    }

    actedOnType(cell: any, row: any) {
        return GetFormatedDateTime(cell);
    }

    eventResponseType(cell: any, row: any) {
        return MailEvent[cell.mailEventId];
    }

    actedByType(cell: any, row: any) {
        return cell.replace(/null/g, "")
    }

    eventIdType(cell: any, row: any) {
        let eventName = '';
        let mailEvent = row.eventData.mailEventId;
        const recipientEmail = row.eventData && row.eventData.recipientInfo && row.eventData.recipientInfo.emailAddress ? row.eventData.recipientInfo.emailAddress : " ";
        
     
        switch (cell) {
            case SignatureFlowDocumentEvent.DocumentDownloadedByCPA:
            case SignatureFlowDocumentEvent.AllDocumentDownloadedByCPA:
            case SignatureFlowDocumentEvent.SignedDocumentDownloadedByCPA:
            case SignatureFlowDocumentEvent.AllSignedDocumentDownloadedByCPA:
            case SignatureFlowDocumentEvent.SignedDocumentDownloaded:
            case SignatureFlowDocumentEvent.AllSignedDocumentDownloaded:
            case SignatureFlowDocumentEvent.PartialSignedDocumentDownloaded:
            case SignatureFlowDocumentEvent.SignedAndDownloaded:
                eventName = SignatureReportClientTrackingEvents[cell] + " (" + row.eventData.fileName + ")"
                break;

            case SignatureFlowDocumentEvent.CancelledDocument:
            case SignatureFlowDocumentEvent.DocumentSigned:
            case SignatureFlowDocumentEvent.DownloadEmail:
            case SignatureFlowDocumentEvent.Filed:
            case SignatureFlowDocumentEvent.AccessLinkEmailed:
            case SignatureFlowDocumentEvent.DelegationUpdated:
            case SignatureFlowDocumentEvent.DelegationCancelled:
            case SignatureFlowDocumentEvent.Archived:
            case SignatureFlowDocumentEvent.RestoreFromRecycle:
            case SignatureFlowDocumentEvent.RestoreFromArchive:
            case SignatureFlowDocumentEvent.DeliveryFailed:
            case SignatureFlowDocumentEvent.BulkDownloadsDownloadedByCPA:
                eventName = SignatureReportClientTrackingEvents[cell];
                break;

            case SignatureFlowDocumentEvent.RestoreFromRecycle:
                eventName = SignatureReportClientTrackingEvents[cell];
                break;
            case SignatureFlowDocumentEvent.DeclinedEmail:
                eventName = SignatureReportClientTrackingEvents[cell] + ((mailEvent == 1 || mailEvent == 2 || mailEvent == 3 || mailEvent == 4 || mailEvent == 5 || mailEvent == 6)
                    ? " - " + MailEvent[row.eventData.mailEventId] : "") + " - Mailed To" + " " + row.eventData.recipientInfo.emailAddress;
                break;
            case SignatureFlowDocumentEvent.SigningCompletionMail:
                eventName = SignatureReportClientTrackingEvents[cell] + " - To" + " " + row.eventData.recipientInfo.emailAddress;
                break;
            case SignatureFlowDocumentEvent.EmailedToDelegatee:
                eventName = SignatureReportClientTrackingEvents[cell] + " " + row.eventData.recipientInfo.emailAddress;
                break;
            case SignatureFlowDocumentEvent.AccessLinkEmailedToDelegatee:
                eventName = SignatureReportClientTrackingEvents[cell] + ((mailEvent == 1 || mailEvent == 2 || mailEvent == 3 || mailEvent == 4 || mailEvent == 5 || mailEvent == 6)
                    ? " " + MailEvent[row.eventData.mailEventId] : "");
                break;
            case SignatureFlowDocumentEvent.ClientAuthenticationModified:
                eventName = SignatureReportClientTrackingEvents[cell] + " " + "for " + row.eventData.recipientInfo.emailAddress;
                break;
            case SignatureFlowDocumentEvent.ReminderSendForSigning:
                eventName = mailEvent == 5 || mailEvent == 6 ? "Manual Reminder email - " + MailEvent[row.eventData.mailEventId] :
                    SignatureReportClientTrackingEvents[cell] + " to " + recipientEmail;
                break;
            case SignatureFlowDocumentEvent.AutomatedSigningReminderMailStatus:
                eventName = mailEvent == 5 || mailEvent == 6 ? "Automated Reminder email - " + MailEvent[row.eventData.mailEventId] :
                    SignatureReportClientTrackingEvents[cell] + " to " + recipientEmail;
                break;
            default: eventName = SignatureReportClientTrackingEvents[cell] + ((mailEvent == 1 || mailEvent == 2 || mailEvent == 3 || mailEvent == 4 || mailEvent == 5 || mailEvent == 6)
                ? " - " + MailEvent[row.eventData.mailEventId] : "");
                break;
        }
        return eventName;
    }
}