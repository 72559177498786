import React, { useEffect, useState } from "react";
import { IUserModel } from "src/Core/ViewModels/User/UserViewModel";
import { ISignatureFlowReportDocument } from "src/SignatureFlow/models/SignatureFlowReportState";
import { Alert, Button, Modal } from "react-bootstrap";
import { CheckCircleFill, DropdownArrow } from "../../svg/CustomSvgIcons";
import DropdownComponent from "../../common/DropdownComponent";

export interface ISignatureAssignToModalProps {
    showAssignTo:Boolean;
    onCancel: () => void;
    selectedReports: ISignatureFlowReportDocument[];
    count: number;
    users: IUserModel[];
    onSubmit: (userId: number) => void;
    assignedUser: (selectedReports: ISignatureFlowReportDocument[]) => number;
}

export const SignatureAssignToModal :React.FC <ISignatureAssignToModalProps> = (props) => {
    const [assignToId, setAssignToId] = useState<number>(0);

    useEffect(()=>{
        setAssignToId(props.assignedUser(props.selectedReports))
    },[props])

    const userList = (): { value: string, label: string }[] => {
        let columnValues: { value: string, label: string, title: string }[] = [];
        props.users.map((person: IUserModel) => {
            columnValues.push({
                value: person.userId.toString(),
                label: `${person.firstName} ${person.lastName}`,
                title: `${person.firstName} ${person.lastName}`
            })
        });
        return columnValues;
    }

    return (<Modal className="draft-assign-to-modal" centered={true} show={props.showAssignTo} onHide={props.onCancel} backdrop="static">
                <Modal.Header closeButton onClick={props.onCancel} >
                    <Modal.Title>
                        Assign Document(s)
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="info" className='modal-alert' style={{height:'38px'}}>
                        <div style={{paddingTop: '2px'}}>
                            <span>
                                <CheckCircleFill marginBottom={'3.5px'}/>
                            </span>
                            {props.count > 1 ? " " + props.count + " Document(s) selected." : " 1 Document(s) selected."}
                        </div>
                    </Alert>
                    <div style={{marginBottom:'16px'}}>
                        <span>Please choose the user you want to assign the document to</span>
                    </div>
                    <div className="select-component-container">
                        <div id="assign-to-user-list">
                            <DropdownComponent
                                id="assign-to-user-list"
                                options={userList()}
                                onChange={setAssignToId}
                                customPlaceHolder="Select user"
                                searchable={true}
                                selectedValue={assignToId}
                                arrowRenderer={() => <DropdownArrow/>}
                                clearRenderer={() => {}}
                                optionClassName="option ellipsis"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='default'
                        data-test-auto='1137347C-947C-4D8A-ABA7-0DE9D70DB526'
                        onClick={props.onCancel}
                        className='btn-white modal-footer-button-cancel'>
                        Cancel
                    </Button>
                    <Button
                        data-test-auto='E0F1216B-0B82-456E-8EEA-57302D25A818'
                        className=' modal-footer-button-save'
                        onClick={() => props.onSubmit(assignToId > 0 ? assignToId : 0)}
                        variant="info">
                        Assign
                    </Button>
                </Modal.Footer>
            </Modal>)
}
