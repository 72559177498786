import React from 'react';
import {ReactComponent as SSSLogo} from './../../images/SignatureLogo.svg';
import {ReactComponent as SSSLogooWithTitle} from './../../images/logo.svg';

const LoadingView: React.FC = () => (
    <div className='login-container flex-column-center-all'>
        <SSSLogooWithTitle width={350} />
        <div className='loading-text' data-title="dot-spin">
            <div className="dot-spin"></div>
        </div>
    </div>
)

LoadingView.displayName = 'LoadingView'

export default LoadingView
