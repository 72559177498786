import * as React from 'react';
import * as Bootstrap from 'react-bootstrap';
import { ResourceIdConstants } from '../helper/RBACConstants';
import { SetAccessHeaderIcon, SendReminderHeaderIcon, DownloadHeaderIcon, ChangeOfficeLocationSvg, RestoreHeaderIcon, ArchiveHeaderIcon, DeleteHeaderIcon } from '../svg/CustomSvgIcons';

interface HeaderProps {
    screenId: string;
    pageTitle: string;
    onSetAccessOpen: (rowIndex: number, headerTextShow: boolean) => void;
    onSendReminder: (rowIndex: number) => void,
    onDownloadDocuments: (rowIndex: number) => void,
    onArchiveDocuments?: (rowIndex: number) => void,
    onRetoreDocumentsOpen: (rowIndex: number) => void,
    onChangeOfficeLocationOpen: (rowIndex: number) => void,
    onDeleteDocuments: (rowIndex: number) => void,
    selectedDocumentCount: number;
    isBulkSelectionEnabled: boolean;
}

const NO_INDEX = -1;

export class SignatureFlowReportsHeader extends React.Component<HeaderProps, {}>{
   
    public render() {

        const { screenId} = this.props;
        const isArchiveReport = this.props.pageTitle == 'Archive';
        const isRecycledReport = this.props.pageTitle == 'Recycle Bin';
        return  <div className='report-header-width'>
                <div className="row" style={{ marginBottom: 23 }}>
                    <div className="col-sm-8">
                        <h3><strong>{this.props.pageTitle}</strong></h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                    <Bootstrap.Button
                            data-resource-id={`${screenId}${ResourceIdConstants.SetAccess}`}
                            variant='default'
                            className='btn btn-white marR10 header-action'
                            disabled={this.props.selectedDocumentCount === 0 || this.props.isBulkSelectionEnabled}
                            onClick={() => this.props.onSetAccessOpen(NO_INDEX, true)}>
                            <SetAccessHeaderIcon /><span>Set Access</span>
                        </Bootstrap.Button>

                    <Bootstrap.Button
                        data-resource-id={`${screenId}${ResourceIdConstants.SendReminder}`}
                            variant='default'
                            className='btn btn-white marR10 header-action'
                            data-test-auto="04CE0584-4E83-4706-AF86-2D90BC9599AB"
                            disabled={this.props.isBulkSelectionEnabled ? false : this.props.selectedDocumentCount === 0}
                            onClick={() => this.props.onSendReminder(NO_INDEX)} >
                            <SendReminderHeaderIcon /><span>Send Reminder</span>
                        </Bootstrap.Button>

                    <Bootstrap.Button
                        data-resource-id={`${screenId}${ResourceIdConstants.Download}`}
                            variant='default'
                            className='btn btn-white marR10 header-action'
                            data-test-auto="03F15B8A-E226-4A43-BC66-6B2FF78F211E"
                            disabled={this.props.isBulkSelectionEnabled ? false : this.props.selectedDocumentCount === 0}
                            onClick={() => this.props.onDownloadDocuments(NO_INDEX)} >
                            <DownloadHeaderIcon /><span>Download</span>
                        </Bootstrap.Button>

                        {(isArchiveReport || isRecycledReport) ?
                        <Bootstrap.Button
                            data-resource-id={`${screenId}${ResourceIdConstants.Restore}`}
                                variant='default'
                                className='btn btn-white marR10 header-action'
                                data-test-auto="E89DC7DA-0DFD-463D-BC1F-86A779A55303"
                                disabled={this.props.isBulkSelectionEnabled ? false : this.props.selectedDocumentCount === 0}
                                onClick={() => this.props.onRetoreDocumentsOpen(NO_INDEX)}>
                                <RestoreHeaderIcon /><span>Restore</span>
                            </Bootstrap.Button>
                            :
                        <Bootstrap.Button
                            data-resource-id={`${screenId}${ResourceIdConstants.Archive}`}
                                variant='default'
                                className='btn btn-white marR10 header-action'
                                data-test-auto="12B962E8-FD73-4B20-9756-E7B85C8A2218"
                                disabled={this.props.isBulkSelectionEnabled ? false : this.props.selectedDocumentCount === 0}
                                onClick={() => this.props.onArchiveDocuments(NO_INDEX)} >
                                <ArchiveHeaderIcon /><span>Archive</span>
                            </Bootstrap.Button>
                        }
                    <Bootstrap.Button
                        data-resource-id={`${screenId}${ResourceIdConstants.Delete}`}
                            variant='default'
                            className="btn btn-white marR10 header-action"
                            data-test-auto="CA785E4E-F548-4BED-98A6-17C08105967C"
                            disabled={this.props.isBulkSelectionEnabled ? false : this.props.selectedDocumentCount === 0}
                            onClick={() => this.props.onDeleteDocuments(NO_INDEX)} >
                            <DeleteHeaderIcon /><span>Delete</span>
                        </Bootstrap.Button>

                    {!isRecycledReport &&
                        <Bootstrap.Button
                        data-resource-id={`${screenId}${ResourceIdConstants.ChangeOfficeLocation}`}
                        variant='default'
                        className="btn btn-white header-action"
                        data-test-auto="970f2ed8-1470-4db5-ac73-81e5f7b913c9"
                        disabled={this.props.isBulkSelectionEnabled ? false : this.props.selectedDocumentCount === 0}
                        onClick={() => this.props.onChangeOfficeLocationOpen(NO_INDEX)} >
                                <ChangeOfficeLocationSvg /><span>Change Office Location</span>
                            </Bootstrap.Button>    
                    }
                    </div>
                </div>
        </div> 
    }
}

export default SignatureFlowReportsHeader;