import  * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { OverlayLoader } from '../../helper/OverlayLoader';
import { MyDefaults } from './mySettings/MyDefaults';
import { IModalProps } from './ProfileObjects';
import { ISignatureUserSettings, DefaultDownloadInfoType } from '../../../models/user/UserSettingsModels';
import { IUserModel } from '../../../../Core/ViewModels/User/UserViewModel';
import { UserSettings } from '../../../store/userManagement/UserSettingStore';


export interface IMySettingProps extends IModalProps {
    users: IUserModel[];
    mySettings: UserSettings;
    updateSignatureSettings: (settings: ISignatureUserSettings) => void;
    updateSignatureSettingsState: (settings: ISignatureUserSettings) => void;
}

export const MySettings: React.FC<IMySettingProps> = props => {

    const [saving, setSaving] = React.useState(false);

    React.useEffect(() => {
        if (!props.mySettings.isLoading && saving) {
            setSaving(false);
            props.onHide();
        }
    }, [props.mySettings.isLoading])

    const oncloseModal = () => {
        setSaving(false);
        props.onHide();
    }

    const onSave = () => {
        if (!saving) {
            setSaving(true);
            props.updateSignatureSettings(props.mySettings.signatureSettings);
        }
    }

    const onChangeNotifyUser = (notifyUserId: number) => {
        let settings = { ...props.mySettings.signatureSettings };
        settings.defaultSettings.notifyUser = notifyUserId;
        props.updateSignatureSettingsState(settings);
    }

    const onChangeDefaultDownload = (event: any) => {
        let DownloadInfoType = event as DefaultDownloadInfoType;
        let settings = { ...props.mySettings.signatureSettings };
        settings.defaultSettings.defaultDownloadInfo.downloadType = DownloadInfoType;
        props.updateSignatureSettingsState(settings);
    }

    const disableButton = props.showState && props.mySettings.settings && props.users ? false : true;


    return (

        <Modal show={props.showState} onHide={oncloseModal} className="my-settings">
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className='fa fa-cog' style={{ color: 'grey', marginRight: '5px', marginTop: '4px' }}></span>
                    My Settings
                </Modal.Title>
            </Modal.Header>
            {
                saving ? 
                    (<OverlayLoader show={saving} text={"Please wait..."} />) :
                    <Modal.Body style={{ maxHeight: '74vh' }}>
                        <MyDefaults
                            users={props.users}
                            onChangeNotifyUser={onChangeNotifyUser}
                            onChangeDefaultDownload={onChangeDefaultDownload}
                            defaultSettings={props.mySettings.signatureSettings.defaultSettings}
                        />
                    </Modal.Body>
            }
            <Modal.Footer>
                <Button
                    variant='default'
                    className="btn-white"
                    disabled={disableButton}
                    data-test-auto="6A473DC7-675C-40F0-9A65-710B47FE0CBC"
                    onClick={oncloseModal}>
                    Cancel
                </Button>

                <Button
                    variant='info'
                    disabled={disableButton}
                    onClick={onSave}
                    data-test-auto="92152B53-A40D-46B7-A39E-0DCDA1F9C1EA">
                    Save & Close
                </Button>
            </Modal.Footer>
        </Modal>
        );
};