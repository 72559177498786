import * as React from 'react';
import { Col, Card, Row, FormControl, Accordion } from 'react-bootstrap';
import { SignatureControlBox } from './SignatureControlBox';
import { DropdownComponent } from '../components/common/DropdownComponent';
import { ISignatureControl, SignatureControlRole, SignatureControlType, SignatureGroupType } from '../models/SignatureData';
import { DropdownArrow } from './svg/CustomSvgIcons';

export interface ISigner {
    value: any;
    label: any;
    signatureRole?: SignatureControlRole;
    disabled?: boolean;
}
export interface ISignatureControlBoxItems {
    caption: string,    
    controlType: SignatureControlType,
    helpText: string
}

export enum InputType {
    TextBox = 0,
    Dropdown = 1
}
export interface IESignDocument {
    documentGuid: string,
    documentTypeId?: number,
    documentId?: number,
}

export interface PdfPageSignatureControls {
    signatureControls: ISignatureControl[],
    signer: ISigner,
    focusedGroup: SignatureGroupType
}
export interface PdfPageSignatureControls {
    signatureControls: ISignatureControl[],
    signer: ISigner,
    focusedGroup: SignatureGroupType
}
export interface SFPdfPageSignatureControls extends PdfPageSignatureControls {
    sender: ISigner
}
export interface ISignatureControlProps {
    title?: string;
    selectedSigner: ISigner;
    signers?: ISigner[];
    onSelectBoxChange?: (value: any) => void;
    onTextBoxChange?: (value: any) => void;
    controlBoxItems: ISignatureControlBoxItems[];
    onSelectBoxControlFocus?: () => void;
    dragStart?: (event: any, controlType: SignatureControlType, signatureRole: SignatureControlRole, signer: string) => void;
    inputType?: InputType;
    dragEnd?: (event: any) => void;
}

export class SignatureControlPanel extends React.Component<ISignatureControlProps, any>{
    public renderSigners() {
        switch (this.props.inputType) {
            case InputType.TextBox:
                return <FormControl
                    value={this.props.selectedSigner.label + " (You)"}
                    onChange={this.props.onTextBoxChange}
                    data-test-auto="35C1B3FB-08B7-47EB-BCBD-AEA180C0EC38"
                    disabled={this.props.selectedSigner.disabled}
                />;
            case InputType.Dropdown:
                return <DropdownComponent
                    id="ddlSignerList"
                    options={this.props.signers}
                    searchable={true}
                    onChange={this.props.onSelectBoxChange}
                    selectedValue={this.props.selectedSigner}
                    arrowRenderer={() => <span><DropdownArrow/></span>}
                    data-test-auto="{9E0BBD1A-43E4-43A3-9797-22D810934504}"
                    clearable={false}
                    customStyle={{background: 'linear-gradient(90deg,#542D87 6px,#FFFFFF 4px)'}}
                />;
            default:
                break;
        }
    }

    public renderSignatureControlBox() {
        return <SignatureControlBox controlBoxItems={this.props.controlBoxItems}
            dragStart={(event, controlType) => {
                this.props.dragStart &&
                    this.props.dragStart(event, controlType,
                        (this.props.selectedSigner.signatureRole ? this.props.selectedSigner.signatureRole : SignatureControlRole.None),
                        this.props.selectedSigner.value)
            }}
            dragEnd={this.props.dragEnd}
        />;
    }

    public render() {
        return <Card className='signature-control-panel'>
                <Card.Title className='fontSize15 fontWeight700 marB0' style={{ padding: "10px 10px 0 22px" }}>
                        {this.props.title}
                </Card.Title>
                <Card.Body>
                    <div className="form-horizontal">
                        <Row className="marB15">
                            <Col sm={12}>
                                {this.renderSigners()}
                            </Col>
                        </Row>
                    </div>
                    <div className='fontSize15 fontWeight700'>Signature Fields</div>
                    <div className='fontSize14 fontWeight400 marB15'>Drag & drop to place signature.</div>
                    <div>
                        {this.renderSignatureControlBox()}
                    </div>
                </Card.Body>
            </Card>
    }
}