import store from "../../../store";
import history from '../../../../routes/history';
import  { ProductType }  from "@sssuite-component-ui/ss-suite-layout";
import { IsAlsoSignatureUser } from "../../../../oidcClient/userRoles";
import { IMenuSection, ISideMenuProps } from "@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu";
import { IHeaderProps } from "@sssuite-component-ui/ss-suite-layout/dist/Header/Header";
import { HeaderConstants } from "../../helper/Constants";
import { ISectionData } from "@sssuite-component-ui/ss-suite-layout/dist/Header/NavigationWidget/NavigationWidget";
import { IHeaderModal } from "./SSSuiteLayoutWrapper";
import { ChildComponents } from "./ChildComponents";

export const initialHeaderConfig: IHeaderProps = {
    productName: ProductType.SignaturesOneOff,
    companyName: "",
    children: null,
    onClickProductLogo: () => null,
    profileDropDown: {
        dropdownHeader: "",
        dropdownHeaderTitle:"",
        onClickLogout: () => null,
        profileDropDownItems: [],
    },
    widgetData: {
        onBackClick: () => { return; },
        hideBackIcon: true,
        dropDownData: [
            {
                caption: HeaderConstants.SS_SUITE,
                items: [],
            },
            {
                caption: HeaderConstants.TOOLS,
                items: [],
            },
        ],
    },
};

const rolebasedMenu = (arr :IMenuSection[]) => {
    const appStore = store.getState();

    const isAlsoSignatureUser = IsAlsoSignatureUser(appStore.auth.user)
    const isCompanySfSubscriptionEnabled = appStore.postAuthDataStore.isCompanySfSubscriptionEnabled
    const isCompanySignaturesProductEnabled = appStore.postAuthDataStore.isCompanySignaturesProductEnabled

    if(!isCompanySfSubscriptionEnabled || !isCompanySignaturesProductEnabled || !isAlsoSignatureUser){
        return [];
    }

    const isSettingsDisabled = arr.filter(x => x.items[0].caption == "Settings")[0].items[0].disabled
    return isSettingsDisabled ?  arr.filter(x => x.items[0].caption != "Settings") : arr;
}

export const sideMenuConfig = (arr: IMenuSection[]): ISideMenuProps | undefined => {
    let roleBasedMenu = rolebasedMenu(arr);
    let topMenuData: IMenuSection[] = [];
    let bottomMenuData: IMenuSection[] = [];

    roleBasedMenu.forEach((x: IMenuSection) => {
        if (x.items?.length && x.items[0]?.isAtBottomOfLeftMenu) {
            bottomMenuData.push(x);
        } 
        else {
            topMenuData.push(x);
        }
    })

    const buttonData = topMenuData.shift().items[0];
    return roleBasedMenu.length == 0 ? undefined : ({
        hasButton: true,
        buttonText: buttonData.caption,
        buttonIcon: buttonData.icon,
        buttonTitle:buttonData.title,
        disableButton: buttonData.disabled,
        topMenuData: topMenuData,
        bottomMenuData: bottomMenuData,
        onButtonClick: () => history.push('/SendForSignature'),
        onNavigate: (route: string) => history.push(route)
    })
}

export const initialSideMenuData: ISideMenuProps = {
    hasButton: true,
    buttonText: "",
    buttonIcon: "",
    buttonTitle: "",
    disableButton: true,
    topMenuData: [
        {
            section: 1,
            items: [
                {
                    caption: "",
                    route: "/",
                    disabled: false,
                    menuOrder: 1,
                    active: false
                }
            ]
        }
    ],
    bottomMenuData: [
        {
            section: 1,
            items: [
                {
                    caption: "",
                    route: "#",
                    disabled: false,
                    menuOrder: 1,
                    active: false
                }
            ]
        }
    ],
    onButtonClick: () => null,
    onNavigate: () => null,
    isMenuToggled: false
}

export const headerConfig = (showModal: IHeaderModal, setShowModal: React.Dispatch<React.SetStateAction<IHeaderModal>>, widgetMenus: ISectionData[], suiteUrl: string, navigate: (route: string) => void,onForethoughtModel: () =>void): IHeaderProps => {
    
    const state = store.getState();
    const {firstName} = state.userProfile.user;
    const {company_name} =  state.auth.user.profile;
    let {isSsrLogo,logoPath} = state.companyData.companyLogoSetting

    let headerData= {
        productName: ProductType.SignaturesOneOff,
        onClickProductLogo: () => navigate("/SendForSignature"),
        children: ChildComponents({openForethoughtModel: onForethoughtModel}),
        profileDropDown: {
            dropdownHeader: `Hi, ${firstName}!`,
            dropdownHeaderTitle: firstName,
            onClickLogout: () => setShowModal({ ...showModal, logOut: true }),
            profileDropDownItems: [
                {
                    caption: HeaderConstants.profile,
                    onClick: () => setShowModal({ ...showModal, profile: true }),
                },
                {
                    caption: HeaderConstants.mySettings,
                    onClick: () => setShowModal({ ...showModal, mySettings: true })
                },
                {
                    caption : HeaderConstants.myDownloads,
                    onClick: () => setShowModal({ ...showModal, myDownloads: true })
                },
                {
                    caption: HeaderConstants.loginHistory,
                    onClick: () => setShowModal({ ...showModal, loginHistory: true })
                }
            ]
        },
        widgetData: {
            onBackClick: () => {
                window.open(suiteUrl, '_blank');
            },
            dropDownData: widgetMenus
        },
    };

    if(!isSsrLogo && logoPath){
        return {...headerData, companyLogo:logoPath}
    }
    else{
        return {...headerData, companyName:company_name}
    }
}

