import { IReminderSettings } from "./SignatureFlowSettings";


export interface ISigningInfoSettings {
    signingInfoId: number;
    reminderSettings: IReminderSettings;
}

export const initialReminderSettings: IReminderSettings = {
    signingReminder: { enabled: false, reminderDays: 7 }
}

export const initialSigningInfoSettings: ISigningInfoSettings = {
    signingInfoId: 0,
    reminderSettings : initialReminderSettings
}

