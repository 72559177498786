import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ISignatureFlowReportCustomColumn, CustomColumnType} from '../../models/CustomColumn';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

interface ICustomColumnFilterProps {
    showCustomColumn: boolean;
    onCancel(): void;
    reportCustomColumns: ISignatureFlowReportCustomColumn[];
    saveReportCustomColumns: (customColumns: ISignatureFlowReportCustomColumn[], callback: () => void) => void;
}

interface ICustomColumnFilterState {
    customColumnOptions: ISignatureFlowReportCustomColumn[];
    tempCustomColumns: ISignatureFlowReportCustomColumn[];
    showLoader: boolean;
}

export class CustomColumnFilter extends React.Component<ICustomColumnFilterProps, ICustomColumnFilterState>{
    state: ICustomColumnFilterState = {
        customColumnOptions: [],
        tempCustomColumns: [],
        showLoader: false
    }

    UNSAFE_componentWillReceiveProps(nextProps: ICustomColumnFilterProps) {
        if (nextProps.reportCustomColumns.length > 0) {
            this.setState({
                customColumnOptions: JSON.parse(JSON.stringify(nextProps.reportCustomColumns)),
                tempCustomColumns: JSON.parse(JSON.stringify(nextProps.reportCustomColumns)),
            });
        }
    }

    private onDragStart = (event: any, listIndex: any) => {
        let index = listIndex;

        event.dataTransfer.setData("item", index);

    }

    private onDropped = (event: any, listIndex: any) => {
        let copy: ISignatureFlowReportCustomColumn[] = [...this.state.customColumnOptions];
        let temp = JSON.parse(JSON.stringify(copy));
        let oldIndex: number = parseInt(event.dataTransfer.getData("item"));
        if(oldIndex != listIndex){
            temp[oldIndex].columnOrder=temp[listIndex].columnOrder;
            if(listIndex<oldIndex){
                for(let j=listIndex;j<oldIndex;j++){
                    temp[j].columnOrder=temp[j].columnOrder+1;
                }
            }
            else{
                for(let j=oldIndex+1;j<=listIndex;j++){
                    temp[j].columnOrder=temp[j].columnOrder-1;
                }
            }
            temp.sort((a:any,b:any)=>a.columnOrder-b.columnOrder)
            this.setState({ customColumnOptions: temp });
        }
    }

    private activateOrDeactivateCustomColumn = (event: any, name: string) => {
        let temp: ISignatureFlowReportCustomColumn[] = [...this.state.customColumnOptions];
        temp.map((items, index) => {
            if (items.columnName == name) {
                if (event.target.checked) {
                    items.isActive = 1;
                } else {
                    items.isActive = 0;
                }
            }
        });
        this.setState({ customColumnOptions: temp });
    }

    private OnSaveCustomColumns = () => {
        this.setState({ showLoader: true });
        this.props.saveReportCustomColumns(this.state.customColumnOptions, this.callback);
    }

    private onReset = () => {
        this.setState({ customColumnOptions: this.state.tempCustomColumns });
        this.props.onCancel();
    }

    private callback = () => {
        this.setState({ showLoader: false });
        this.props.onCancel();
    }

    private onDragOver = (event: any) => {
        event.preventDefault();

        const container = event.currentTarget;
        const rect = container.getBoundingClientRect();
        const mouseY = event.clientY;
        const scrollSpeed = 20; // Adjust the scrolling speed as needed

        // Scroll up if the mouse is close to the top edge
        if (mouseY < rect.top + scrollSpeed) {
            container.scrollTop -= scrollSpeed;
        }
        // Scroll down if the mouse is close to the bottom edge
        else if (mouseY > rect.bottom - scrollSpeed) {
            container.scrollTop += scrollSpeed;
        }
    }

    private getListItem = () => {
        let items = this.state.customColumnOptions.map((value, index) => {
            let isColumnActive: boolean = value.isActive == 1 ? true : false;
            return <li key={value.columnName + value.columnOrder} className={value.columnName} onDrop={(e) => this.onDropped(e, index)} draggable={true} onDragStart={(e) => this.onDragStart(e, index)}
                onDragOver={(e) => e && e.preventDefault()} onDragEnd={(e) => e && e.preventDefault()} style={{ listStyleType: "none", marginBottom: "17px", width: "100%", display: "flex" }}>
                <div style={{ width: "100%" }}>
                    <span style={{ float: "left" }}>
                        <div className={"custom-curve-check-box"} >
                        <div className="form-check">
                            <input
                                id={'custom-column-filter-columns-'+ index}
                                className="form-check-input magic-checkbox" 
                                type="checkbox" 
                                value="" 
                                checked={isColumnActive}
                                disabled={(value.customColumn == CustomColumnType.Recipients || 
                                    value.customColumn == CustomColumnType.ClientId) ? true : false}
                                onChange={(event) => this.activateOrDeactivateCustomColumn(event, value.columnName)}>
                                </input>
                                <label htmlFor={'custom-column-filter-columns-'+ index} 
                                        className={"form-check-label "+ ( isColumnActive ? "checked" : "")}>
                                </label>
                        </div>
                        </div>
                    </span>
                    <label className={"form-check-label "+ (!(value.customColumn == CustomColumnType.Recipients || 
                                    value.customColumn == CustomColumnType.ClientId)? "column-disabled" : "")}
                                    style={{cursor:'move'}}>
                                {value.columnName}
                    </label>
                </div>
            </li>
        });
        return items;
    }

    public render() {
        return (

            <Modal centered={true} show={this.props.showCustomColumn} onHide={this.onReset} backdrop="static" className="custom-column-filter-modal">
                <Modal.Header closeButton >
                    <Modal.Title>
                        {"Customize Columns"}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className='custom-scroll-bar' onDragOver={(e) => this.onDragOver(e)} style={{ maxHeight: 'calc(100vh - 400px)', overflowY: 'auto' }}>
                    <LoadingOverlay style={{ minHeight: 100 }}>
                        <ul id="item-list" className="moveable">
                            {this.getListItem()}
                        </ul>
                        <Loader loading={this.state.showLoader} text="Saving your changes. Please wait..." />
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='default'
                        onClick={this.onReset}
                        className='btn-white modal-footer-button-cancel'
                        data-test-auto="BC3C6FF2-75C2-45AD-8552-8915E4C8EDF0">
                        Cancel
                    </Button>
                    <Button onClick={this.OnSaveCustomColumns}
                        variant="info"
                        className=' modal-footer-button-save'
                        data-test-auto="BC7DCC23-8799-4DD0-BDC6-30597C7804F1">
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>)
    }
}