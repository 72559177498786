import * as React from 'react';
import { Modal, Col, Button, FormControl, Row } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { VenusNotifier } from '../helper/VenusNotifier';
import { ReportProblemConstants, UploadtaxReturnConstants, ClientInfoValidation, ValidationContants } from '../helper/Constants';
import { formatPhoneNumber } from '../helper/HelperFunctions';
import { IReportProblemDetails, ReportProblemProperty, ReportProblemTypes,
    initialState, ProductTypes, ReportedStep,SFSignatureStatus} from './ReportProblemModel';
import { isValidEmailAddress } from '../helper/Validations';
//import { IDocumentType } from '../../signatureflow/models/SignatureFlowSettings';

import { IUserProfile } from './../navigation/profile/ProfileObjects';
//import { ICompanySettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import { PhoneNumberComponent } from '../common/PhoneNumberComponent';
import { ISignatureFlowReportDocument } from '../../models/SignatureFlowReportState';
import { CloseXButton } from '../svg/CustomSvgIcons';

interface IReportProbemProps {
    show: boolean;
    onCancel: () => void;
    sfModel?: ISignatureFlowReportDocument;
    onReportProblemSave: (id: number, problemDetails: IReportProblemDetails, callback?: () => void) => void;
    loggedInCPA: IUserProfile;
    companyName: string;
    reportProblemType: ReportProblemTypes;
    problemStep?: ReportedStep;
    //companySettings?: ICompanySettings;
    //documentTypes?: IDocumentType[];
    //requestSignatureUsers?: (reload?: boolean) => void;
    //sfUsers?: IUserModel[];
    //requestDocumentTypes?: () => void;
}

interface state {
    saving: boolean;
    showLoader: boolean;
    problemState: IReportProblemDetails;
}

export class ReportProblemModal extends React.Component<IReportProbemProps, state> {
    constructor(props: IReportProbemProps) {
        super(props);

        this.state = {
            saving: false,
            showLoader: false,
            problemState: initialState
        }

        this.onSubmit = this.onSubmit.bind(this);
    }


    //componentDidMount() {
    //    if (this.props.requestDocumentTypes) {
    //        this.props.requestDocumentTypes();
    //    }
    //    if (this.props.requestSignatureUsers) {
    //        this.props.requestSignatureUsers(true);
    //    }

    //}

    UNSAFE_componentWillReceiveProps(nextProps: IReportProbemProps) {
        let reportProblem = { ...this.state.problemState };
        reportProblem.cpaPhoneNumber = !this.props.loggedInCPA || !this.props.loggedInCPA.mobileNumber ? "" : this.props.loggedInCPA.mobileNumber;
        reportProblem.cpaEmail = !this.props.loggedInCPA ? "" : this.props.loggedInCPA.emailAddress;
        reportProblem.productType = ProductTypes[1];

        this.setState({
            saving: false,
            showLoader: !nextProps.sfModel ? true : false,
            problemState: reportProblem
        });

        if (nextProps.sfModel && nextProps.reportProblemType == ReportProblemTypes.SignatureSpecific) {
            reportProblem.productType = ProductTypes[3];
            reportProblem.signingId = nextProps.sfModel.signingId;
            reportProblem.taxYear = nextProps.sfModel.taxYear;
            reportProblem.status = SFSignatureStatus[nextProps.sfModel.signatureStatus];
            reportProblem.sentBy = nextProps.sfModel.senderName;
            reportProblem.clientName = nextProps.sfModel.recipientName;
            reportProblem.documentName = nextProps.sfModel.documentName;
            reportProblem.documentType = nextProps.sfModel.documentType;
            reportProblem.clientId = nextProps.sfModel.clientId;
            this.setState({
                problemState: reportProblem
            });
        }
    }


    //setContactDropdown = (userList: IUserModel[] | undefined) => {
    //    var _contactPerson = []
    //    if (userList) {
    //        for (let i = 0; i < userList.length; i++) {
    //            let userId = userList[i].userId ? userList[i].userId : 0;
    //            let firstName: string = userList[i].firstName ? userList[i].firstName : "";
    //            let lastName: string = userList[i].lastName ? userList[i].lastName : "";

    //            _contactPerson.push({
    //                value: firstName + " " + lastName,
    //                label: firstName + " " + lastName
    //            });
    //        }
    //    }
    //    if (this.props.sfModel) {
    //        _contactPerson.push({
    //            value: this.props.sfModel.senderName,
    //            label: this.props.sfModel.senderName
    //        });
    //    }

    //    return _contactPerson;

    //}


    //// Create document type dropdown list 
    //setDocumentTypes = (documentTypes: IDocumentType[] | undefined) => {

    //    var documentTypesArray: any = [];
    //    if (documentTypes) {
    //        var filterTypes: IDocumentType[] = documentTypes;
    //        if (documentTypes.length > 0) {
    //            filterTypes = documentTypes.filter(x => x.isActive);
    //        }
    //        filterTypes && filterTypes.map((documentType: IDocumentType) =>
    //            documentTypesArray.push({
    //                value: documentType.description,
    //                label: documentType.description
    //            }));
    //    }
    //    return documentTypesArray;
    //}


    handleFieldChange = (item: ReportProblemProperty, value: any) => {
        let reportProblem = { ...this.state.problemState };
        switch (item) {
            case ReportProblemProperty.productType:
                reportProblem.productType = value;

                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.clientId:
                reportProblem.clientId = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
           
            case ReportProblemProperty.taxYear:
                reportProblem.taxYear = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
          
            case ReportProblemProperty.cpaEmail:

                reportProblem.cpaEmail = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.cpaPhoneNumber:
                reportProblem.cpaPhoneNumber = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.status:
                reportProblem.status = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.description:
                reportProblem.description = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.clientName:
                reportProblem.clientName = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.documentType:
                reportProblem.documentType = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.documentName:
                reportProblem.documentName = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.sentBy:
                reportProblem.sentBy = value;
                this.setState({
                    problemState: reportProblem
                });
                break;

        }
    }

    handleClose = () => {
        this.setState({
            problemState: initialState
        }, () => { this.props.onCancel() });
    }

    public render() {
        const isDataLoaded = (this.props.reportProblemType == ReportProblemTypes.SignatureSpecific && (this.props.sfModel ? true : false));
        const {problemState} = this.state;
        let loadingMessage: string = !isDataLoaded ? UploadtaxReturnConstants.OverlayMessage.ApplicationLoading : "";

        //let engType = [];
        //for (let type in EngagementType) {
        //    if (isNaN(Number(type))) {
        //        if ((type !== EngagementType[EngagementType.None]))
        //            engType.push({ value: type.toString(), label: type.slice(1) });
        //    }
        //}

        //const docStatus: SelectOptions[] = DocumentStatusType;
        //const sigStatus: SelectOptions[] = SignatureStatusOptionsList;
        //const sfSignStatus: SelectOptions[] = SFSignatureStatusOptionsList


        return <div>
            <Modal centered={true} className="report-problem-modal" show={this.props.show} onHide={this.handleClose} backdrop="static">
                <LoadingOverlay>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Report a Problem
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={6}>
                                <span className="section-header">Your Information</span>
                                <Row style={{paddingTop:'7px'}} className='pad-b'>
                                    <Col sm={5}>
                                    <span className='section-field'>Product Type</span>
                                    </Col>
                                    <Col sm={7}>
                                    <div className='section-value ellipsis' title={problemState.productType} data-test-auto="aa849eb1-f194-4aba-84f7-dffb3d0cf0fc">
                                        {problemState.productType}
                                    </div>
                                    </Col>
                                </Row>
                                <Row className='pad-b'>
                                    <Col sm={5}>
                                        <span className='section-field'>CPA Firm</span>
                                    </Col>
                                    <Col sm={7}>
                                        <div className='section-value ellipsis' title={this.props.companyName} data-test-auto="b9853ae9-5551-42a1-acd7-8873c06bbf76">
                                            {this.props.companyName}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='pad-b'>
                                    <Col sm={5}>
                                        <span className='section-field'>Logged-In User</span>
                                    </Col>
                                    <Col sm={7}>
                                        <div className='section-value ellipsis' 
                                            title= {this.props.loggedInCPA != undefined ?
                                                this.props.loggedInCPA.firstName + " " + this.props.loggedInCPA.lastName : ""}
                                            data-test-auto="070ab2c8-8913-4cbc-ab8f-104d89bb00e5">
                                            {this.props.loggedInCPA != undefined ?
                                            this.props.loggedInCPA.firstName + " " + this.props.loggedInCPA.lastName : ""}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='pad-b'>
                                    <Col sm={4} style={{paddingTop:'7px'}}>
                                        <span className='section-field'>Phone</span>
                                    </Col>
                                    <Col sm={8} className='section-value editable-section-value'>
                                        <Row >
                                            <Col style={{paddingLeft: '18px', fontSize:'16px', maxWidth:'87%'}}>
                                                <PhoneNumberComponent
                                                    phoneNumber={problemState.cpaPhoneNumber}
                                                    handleChangePhoneNumber={(value: string) => { this.handleFieldChange(ReportProblemProperty.cpaPhoneNumber, value) }}
                                                    disabled={false}
                                                    autoFocus={true}
                                                    datatestAuto="280e7282-48d5-4ff5-a4c7-7601fef18751"
                                            />
                                            <span onClick={() => { this.handleFieldChange(ReportProblemProperty.cpaPhoneNumber, "") }} > 
                                                  <CloseXButton fill='#05386B'/>
                                            </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='pad-b'>
                                    <Col sm={4} style={{paddingTop:'7px'}}>
                                        <span className='section-field'>Email</span>
                                    </Col>
                                    <Col sm={8} className='section-value editable-section-value'>
                                        <Row >
                                            <Col style={{paddingLeft: '18px', fontSize:'16px', maxWidth:'87%'}}>
                                                <FormControl
                                                 type="text"
                                                 placeholder="Enter Logged-In CPA Email"
                                                 value={problemState.cpaEmail}
                                                 title={problemState.cpaEmail}
                                                 onChange={(event: any) => { this.handleFieldChange(ReportProblemProperty.cpaEmail, event.target.value) }}
                                                 autoFocus={true}
                                                 data-test-auto="b749096c-96bf-4b66-b833-40b56117475f"
                                                />
                                                <span onClick={() => { this.handleFieldChange(ReportProblemProperty.cpaEmail, "") }} > 
                                                  <CloseXButton fill='#05386B'/>
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='pad-b'>
                                    <Col sm={5}>
                                    <span className='section-field'>Tax Year</span>
                                    </Col>
                                    <Col sm={7}>
                                        <div className='section-value ellipsis' title={problemState.taxYear.toString()} data-test-auto="e6679547-95b9-4cc7-8d95-b1787fdc1692">
                                            {problemState.taxYear}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='pad-b'>
                                    <Col sm={5}>
                                        <span className='section-field'>Document Type</span>
                                    </Col>
                                    <Col sm={7}>
                                        <div className='section-value ellipsis' title={problemState.documentType} data-test-auto="65b25081-b447-4e7d-aa7c-291b3ac9801c">
                                            {problemState.documentType}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='pad-b'>
                                    <Col sm={5}>
                                        <span className='section-field'>Client Name</span>
                                    </Col>
                                    <Col sm={7}>
                                        <div className='section-value ellipsis' title={problemState.clientName} data-test-auto="76157598-e61f-4514-bc43-4b7e917764cb">
                                            {problemState.clientName}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='pad-b'>
                                    <Col sm={5}>
                                        <span className='section-field'>Status</span>
                                    </Col>
                                    <Col sm={7}>
                                        <div className='section-value ellipsis' title={problemState.status} data-test-auto="911e3fcc-81c3-4e87-9a05-6dcbeb9e898f">
                                            {problemState.status}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='pad-b'>
                                    <Col sm={5}>
                                        <span className='section-field'>Sent By</span>
                                    </Col>
                                    <Col sm={7}>
                                        <div className={'section-value ellipsis ' + (!this.props.sfModel?.isValidSender ? 'invalid-sender'  : '')} 
                                            title= {problemState.sentBy}
                                            data-test-auto="893b554f-9ef2-42a7-8b9e-a2043ca388b1">
                                            {problemState.sentBy}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='pad-b'>
                                    <Col sm={5}>
                                        <span className='section-field'>Document Name</span>
                                    </Col>
                                    <Col sm={7}>
                                        <div className='section-value ellipsis' title={problemState.documentName} data-test-auto="7a21732c-a406-42eb-bf15-d7e5f51951f0">
                                            {problemState.documentName}
                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                            <Col sm={6}>
                                <span className="section-header">Description</span>
                               
                                    <FormControl
                                        value={problemState.description}
                                        id="report-problem-text"
                                        style={{ height: '91%', marginTop:'7px' }}
                                        as="textarea"
                                        placeholder='Please describe your problem…'
                                        onChange={(event: any) => { this.handleFieldChange(ReportProblemProperty.description, event.target.value) }} 
                                        data-test-auto="2a433b5c-0464-4460-8c09-a051f0eace98"/>
                                    </Col>                          
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='default'
                            data-test-auto='1137347C-947C-4D8A-ABA7-0DE9D70DB526'
                            onClick={this.handleClose}
                            className='btn-white modal-footer-button-cancel'>
                            Cancel
                        </Button>
                        <Button
                            data-test-auto='E0F1216B-0B82-456E-8EEA-57302D25A818'
                            className=' modal-footer-button-save'
                            onClick={this.onSubmit}
                            variant="info">
                            Submit
                        </Button>
                    </Modal.Footer>
                    <Loader loading={this.state.showLoader
                        || (this.props.reportProblemType == ReportProblemTypes.ReturnSpecific && !isDataLoaded)
                        || (this.props.reportProblemType == ReportProblemTypes.SignatureSpecific && !isDataLoaded)}
                        text={!isDataLoaded ?
                            loadingMessage : ReportProblemConstants.OverlayMessage.SubmittingIssue} />
                </LoadingOverlay>
            </Modal>
        </div>
    }

    private onSubmit() {
        if (this.state.problemState.description.trim() === '') {
            VenusNotifier.Warning("Please enter problem description to report.", "Report a Problem");
            return;
        }
        else if (!isValidEmailAddress(this.state.problemState.cpaEmail)) {
            VenusNotifier.Warning(ClientInfoValidation.inCorrectEmail, "");
            return;
        }
        else if (this.state.problemState.cpaPhoneNumber.trim() != "") {
            if (this.state.problemState.cpaPhoneNumber.length < 10 || /^[0-9]{1,10}$/.test(this.state.problemState.cpaPhoneNumber) == false) {
                VenusNotifier.Warning(ValidationContants.PhoneNumberLengthWarning, "");
                return;
            }
        }

        if (!this.state.saving) {
            this.setState({ saving: true, showLoader: true },
                () => {
                    this.props.onReportProblemSave(this.state.problemState.signingId, this.state.problemState,
                        () => { this.setState({ problemState: initialState }); })
                });
        }

    }
};