import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/types';
import * as Component from './SignatureLockedReport';
import * as LockedReportStore from '../../../store/ReportsStore/LockedReport/LockedReportStore';
import * as SignatureFlowReportFilterStore from '../../../store/ReportsCommon/SignatureFlowReportFilterStore';
import * as SignatureFlowReportPopupModelStore from '../../../store/ReportsCommon/SignatureFlowReportPopupModelStore';

export default connect(
    (state: ApplicationState) => ({
        LockedReportDocuments: state.LockedReportDocuments, 
        signatureFlowReportDetails: state.signatureFlowReport,
        signatureFlowReportFilter : state.signatureFlowReportFilter,
        pageTitle: "Locked"
    }),
    {
        ...LockedReportStore.actionCreators,
        ...SignatureFlowReportFilterStore.actionCreators,
        ...SignatureFlowReportPopupModelStore.actionCreators
    }
)(Component.SignatureLockedReport as any)