import React, { useImperativeHandle, useRef, useState } from 'react';
import * as bootbox from 'bootbox';
import { BootstrapTable, CustomFilter, CustomSelectProps, TableHeaderColumn, TextFilter } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import { IFilters } from '../../../components/common/Filters';
import CustomSingleSelect from '../../common/SingleSelectComponent';
import { CustomMultiSelect } from '../../common/MultipleSelectComponent';
import * as Helper from '../../../components/helper/HelperFunctions';
import { DaysRangeOptionsList, SearchDelayTimeConstant, SignatureFlowReportFilterConstants } from '../../helper/Constants';
import { SignatureFlowReportFilter } from '../SignatureFlowReportFilter';
import { CheckBoxComponent, CheckBoxSize } from '../../common/CheckBoxComponent';
import { ISignatureFlowReportTableModel } from 'src/SignatureFlow/models/SignatureFlowReportTableState';
import moment from 'moment';
import SignatureFlowDraftReportAction from '../SignatureFlowDraftReportAction';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { InfoIconFill } from '../../svg/CustomSvgIcons';
import { VenusNotifier } from '../../helper/VenusNotifier';

export interface TableProps {
    screenId: string;
    isLoading: boolean,
    onSortChange: any,
    onSearchChange: any,
    onFilterChange: any,
    pageNo: number,
    totalRows: number;
    pageSize: number,
    saveFilterShow: boolean,
    onRowSelect: (row: any, isSelected: any, e: any) => void,
    onSelectAll: (isSelected: any, rows: any, e: any) => void,
    
    onFilterSave(onApplyFilter: (filter: IFilters) => void): void,
    onPageReload: () => void,
    onFilterNameChange(event: any): void,
    onSetDefaultFilter: (name: string) => void,
    onRemoveDefaultFilter: (name: string) => void,
    defaultFilter: string | undefined,
    onSaveFilterShow(): void,
    onSaveFilterHide(): void,
    filterList: IFilters[],
    currentFilter: IFilters,
    loadGrid(): void,
    onFilterUpdate: (filterName: string) => void,
    onFilterDelete: (filterName: string) => any,

    selectedRows: number[],
    signatureReportsData: ISignatureFlowReportTableModel,
    onDocumentDetailsOpen: (rowIndex: number) => void;
    onDeleteDocument: (rowIndex: number) => void;
    onSetAccessOpen: (rowIndex: number, headerTextShow: boolean) => void;
    onReportProblemOpen: (rowIndex: number) => void;
    showBulkSelectionMessage: boolean;
    onBulkSelectionChange: (isEnabled: boolean) => void;
    onResume: (rowIndex: number) => void;
    userOfficeLocation: any[];
}

const SignatureDraftReportTable = React.forwardRef((props:TableProps, ref: any) => {
    const signatureReportTableRef:any = useRef(null);

    //Table column Refs
    const index:any = useRef(null);
    const clientId:any = useRef(null);
    const recipientName:any = useRef(null);
    const documentType:any = useRef(null);
    const assignedTo:any = useRef(null);
    const documentStatusChangedOn:any = useRef(null);
    const taxYear:any = useRef(null);
    const officeLocation:any = useRef(null);
    const button:any = useRef(null);
    const filtersRef:any = useRef(null);

    var filterChanged: boolean = false;
    var isAppiedFilter: boolean = false;

    const taxYearList = Helper.getTaxYearsForSignReportsFilter()
    const [customDraftDateSingleSelectRef, setCustomDraftDateSingleSelectRef] = useState<any>(null);
    const [customTaxYearMultiSelectRef, setCustomTaxYearMultiSelectRef] = useState<any>(null);
    const [customOfficeLocationMultiSelectRef, setcustomOfficeLocationMultiSelectRef] = useState<any>(null);

    const onFilterChange = (dataField: any) => {
        if (!filterChanged && !isAppiedFilter) {
            filterChanged = true;
            props.onFilterChange(dataField);
            filterChanged = false;
        }
    }

    const setNoContent = () => {
        if (props.isLoading) {
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={props.isLoading} />
            </LoadingOverlay>)
        } else {
            return "No reports found"
        }
    }

    const defaultType = (cell: any, row: any) => {
        return <div title={cell} className="ellipsis">{cell}</div>
    }

    const defaultClientIdType = (cell: any, row: any) => {
        return <div title={cell} className="ellipsis" style={{fontWeight:'700'}}>{cell}</div>
    }

    const GetSortedColumns = (columns: any) => {
        let columnHeader: any = columns;

        const indexOfColumnIndex = columnHeader.findIndex((item:any) => item.dataField === 'index');
        columnHeader = Helper.moveItemAtEnd(indexOfColumnIndex, columnHeader);

        return columnHeader;
    }


    const CreateDocumentTypeDataFormatType = (cell: any, row: any) => {
        if (row.documentCount > 1) {
            return (<Link to={'#'} 
                        className="ellipsis" 
                        onClick={() => props.onDocumentDetailsOpen(row.rowIndex)}>
                            {"Multiple Documents"}
                        </Link>)
        }
        return <span className='ellipsis'>{`${row.documentType}`}</span>
    }

    const CreateDocumentTypeToolTip = (cell: any, row: any) => {
        if (row.documentCount == 1) {
            return `${row.documentType}`
        }
    }
    
    const getDraftDateSingleSelectDropDown = (filterHandler: any, customFilterParameters: any)  => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomSingleSelect onRef={setCustomDraftDateSingleSelectRef} 
                                filterHandler={filterHandler} 
                                options={options} 
                                placeholder={placeholder} />
        );
    }

    const getYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={setCustomTaxYearMultiSelectRef}
                                filterHandler={filterHandler} 
                                options={options} 
                                enableAllFilter={enableAllFilter} 
                                placeholder={placeholder} 
                                isSignatureReports="True" />
        );
    }

    const getMultiOfficeLocationDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect
                onRef={setcustomOfficeLocationMultiSelectRef}
                filterHandler={filterHandler}
                options={options}
                placeholder={placeholder}
            />
        );
    }

    const createCustomCheckbox = (props: CustomSelectProps): any => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"signatureflow-reports-checkbox-" + props.rowIndex}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            className={"custom-curve-check-box SignatureRecyclecheckbox"}
            text={""} />);
    }

    const SignatureFlowReportAction = (cell: any, row: any) => {
        return <SignatureFlowDraftReportAction
            screenId={props.screenId}
            isArchive={false}
            rowIndex={row.rowIndex}
            title={cell} 
            signatureReportsData={props.signatureReportsData.documents}
            onDeleteDocument={props.onDeleteDocument} 
            onSetAccessOpen={props.onSetAccessOpen}
            onReportProblemOpen={props.onReportProblemOpen}
            onResume={props.onResume}/>;
    }

    const onLoadSelectedFilter = (filter?: IFilters) => {
        isAppiedFilter = true;
        onClearFilter(true);
        if (filter) {
            filterChanged = true;
            for (let field of Object.keys(filter.fields)) {
                switch (field) {
                    case 'documentType':
                        (documentType.current as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'taxYear':
                       customTaxYearMultiSelectRef?.applyFilter(filter.fields[field]);
                       break;
                    case 'recipientName':
                        (recipientName.current as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'clientId':
                        (clientId.current as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'assignedTo':
                        (assignedTo.current as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'documentStatusChangedOn':
                       customDraftDateSingleSelectRef?.applyFilter(filter.fields[field]);
                       break;
                    case 'officeLocation':
                        customOfficeLocationMultiSelectRef?.applyFilter(filter.fields[field]);
                        break;
                }
                filterChanged = false;
            }

            isAppiedFilter = false;
            onFilterChange(filter.fields);
        }
    }


    const onFilterSaveClick = (filterName: string) => {

        if (!Helper.validateFilter(props.currentFilter)) {
            VenusNotifier.Warning(SignatureFlowReportFilterConstants.OtherMessage.FilterFieldsAreEmpty, null);
            return;
        }

        if (typeof filterName == 'undefined' || filterName.length == 0) {
            props.onSaveFilterShow();
        }
        else {
            bootbox.dialog({
                title: SignatureFlowReportFilterConstants.FilterConfirmationModalTitle.FilterSaveUpdate,
                message: SignatureFlowReportFilterConstants.OtherMessage.CreateOrUpdateFilter,
                buttons: {
                    cancel: {
                        label: SignatureFlowReportFilterConstants.ControlLabel.NoUpdateTheCurrent,
                        className: 'btn-white modal-footer-button-cancel',
                        callback: function () { props.onFilterUpdate(filterName) }
                    },
                    confirm: {
                        label: SignatureFlowReportFilterConstants.ControlLabel.YesCreateNew,
                        className: 'btn-info modal-footer-button-save',
                        callback: function () { props.onSaveFilterShow(); }
                    }
                }
            }).find(".modal-dialog")
            .addClass("modal-dialog-centered");
        }
    }

    const onClearFilter = (clearAll?: boolean) => {
        filterChanged = true;
        assignedTo.current !== null ? (assignedTo.current as TableHeaderColumn).cleanFiltered() : "";
        documentType.current !== null ? (documentType.current as TableHeaderColumn).cleanFiltered() : "";
        recipientName.current !== null ? (recipientName.current as TableHeaderColumn).cleanFiltered() : "";
        clientId.current !== null ? (clientId.current as TableHeaderColumn).cleanFiltered() : "";

        customTaxYearMultiSelectRef?.cleanFiltered(clearAll);
        customDraftDateSingleSelectRef?.cleanFilteredValue();
        customOfficeLocationMultiSelectRef?.cleanFiltered(clearAll);
        filterChanged = false;
    }
    
    const createCustomToolBar = () => { 
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 marR15 header-filter" style={{ height: 33, position: "unset" }}>
                {
                    showBulkAlertMessage()
                }
                {
                    <SignatureFlowReportFilter ref={filtersRef}
                        screenId={props.screenId}
                        onFilterNameChange={props.onFilterNameChange}
                        onFilterSave={props.onFilterSave}
                        onClearFilter={onClearFilter}
                        showExportButton={false}
                        filterList={props.filterList}
                        onSetDefaultFilter={props.onSetDefaultFilter}
                        onRemoveDefaultFilter={props.onRemoveDefaultFilter}
                        onFilterDelete={props.onFilterDelete}
                        show={props.saveFilterShow}
                        onFilterSaveClick={onFilterSaveClick}
                        onSaveFilterHide={props.onSaveFilterHide}
                        onLoadSelectedFilter={onLoadSelectedFilter}
                        defaultFilter={props.defaultFilter}
                        loadGrid={props.loadGrid}
                        showCustomColumnsButton={false}
                        onPageReload={props.onPageReload}
                    />
                }
            </div >);
    }

    const showBulkAlertMessage = () => {
        if (props.totalRows > props.pageSize && props.showBulkSelectionMessage) {
            let documentsInPage = props.signatureReportsData.documents.length;
            return (<div className="col-sm-9 col-lg-9 font16" style={{ position: "relative", display: "inline-block", zIndex: 10, paddingLeft: '0px', marginTop: '61px'}} >
                        <div className='custom-alert-warning'>         
                            <InfoIconFill marginLeft='5px' marginBottom='2px'/>  You have selected all  {documentsInPage} records displayed. To select the additional {props.totalRows - documentsInPage} records, please
                            <Link to={"#"} onClick={() => props.onBulkSelectionChange(true)}> click here</Link>
                        </div>
            </div>);
        }
    }

    let selected: number[] = [];
    if (props.selectedRows.length > 0) {
        for (var i in props.selectedRows) {
            let rowIndex = props.selectedRows[i];
            selected.push(((props.pageNo - 1) * props.pageSize) + rowIndex);
        }
    }

    let data: any = [];
    if (props.signatureReportsData.documents) {
        data = props.signatureReportsData.documents.map((model, index) => {
            return {
                signingId: model.signingId,
                documentType: model.documentType == null ? " " : model.documentType,
                documentStatusChangedOn: model.documentStatusChangedOn == null ? "" : moment.utc(model.documentStatusChangedOn).local().format('MM/DD/YYYY'),
                button: 'Actions',
                index: ((props.pageNo - 1) * props.pageSize) + index,
                rowIndex: index,
                taxYear: (model.taxYear == null || model.taxYear == 0) ? "" : model.taxYear,
                documentCount: model.documentCount,
                recipientName: model.recipientName == null ? "" : model.recipientName,
                clientId: model.clientId == null ? "" : model.clientId,
                assignedTo: model.assignedTo == null ? "" : model.assignedTo,
                officeLocation: model.officeLocation == null ? "" : model.officeLocation
            }
        });
    }

    const options = {
        onSortChange: props.onSortChange,
        sizePerPage: props.pageSize,
        onSearchChange: props.onSearchChange,
        page: props.pageNo,
        onFilterChange: onFilterChange,
        noDataText: setNoContent(),
        toolBar: createCustomToolBar,
        clearSearch: true,
    };

    let selectRowProp: any = {
        mode: 'checkbox',
        onSelect: props.onRowSelect,
        onSelectAll: props.onSelectAll,
        selected: selected,
        customComponent: createCustomCheckbox,
        className: 'row-selected'
    };

    const Columns = [
        {
            header: '',
            key: index,
            dataField: 'index',
            isKey: true,
            dataFormat: defaultType,
            className: 'text-field-header header-text',
            dataSort: true,
            toolTip: false,
            isHidden: true,
            width: 'auto',
            filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter
        },
        {
            header: 'Client ID',
            key: clientId,
            dataField: 'clientId',
            isKey: false,
            dataFormat: defaultClientIdType,
            dataSort: true,
            isHidden: false,
            toolTip: false,
            className: 'text-field-header header-text',
            width: 'auto',
            filter: {
                type: 'TextFilter', placeholder: 'Client ID', style: { font: 'bold' }, delay : SearchDelayTimeConstant
            } as TextFilter
        },
        {
            header: 'Client Name',
            key: recipientName,
            dataField: 'recipientName',
            isKey: false,
            dataFormat: defaultType,
            dataSort: true,
            isHidden: false,
            toolTip: false,
            className: 'text-field-header header-text',
            width: 'auto',
            filter: {
                type: 'TextFilter', placeholder: 'Client Name', style: { font: 'bold' }, delay : SearchDelayTimeConstant
            } as TextFilter
        },
        {
            header: 'Office Location',
            key: officeLocation,
            dataField: 'officeLocation',
            isKey: false,
            dataFormat: defaultType,
            className: 'text-field-header header-text',
            dataSort: true,
            toolTip: false,
            isHidden: false,
            width: 'auto',
            filter: { type: 'CustomFilter', getElement: getMultiOfficeLocationDropDown, 
                    customFilterParameters: { options: props.userOfficeLocation, enableAllFilter: true, placeholder: 'Office Location' } as any } as CustomFilter
        },
        {
            header: 'Document Type',
            key: documentType,
            dataField: 'documentType',
            isKey: false,
            dataFormat: CreateDocumentTypeDataFormatType,
            dataSort: true,
            isHidden: false,
            toolTip: CreateDocumentTypeToolTip,
            className: 'text-field-header header-text',
            width: 'auto',
            filter: {
                type: 'TextFilter', placeholder: 'Document Type', style: { font: 'bold' }, delay : SearchDelayTimeConstant
            } as TextFilter
        },
        {
            header: 'Assigned to',
            key: assignedTo,
            dataField: 'assignedTo',
            isKey: false,
            dataFormat: defaultType,
            dataSort: true,
            isHidden: false,
            toolTip: false,
            className: 'text-field-header header-text',
            width: 'auto',
            filter: {
                type: 'TextFilter', placeholder: 'Assigned to', style: { font: 'bold' }, delay : SearchDelayTimeConstant
            } as TextFilter
        },
        {
            header: 'Tax Year',
            key: taxYear,
            dataField: 'taxYear',
            isKey: false,
            dataFormat: defaultType,
            className: 'text-field-header header-text',
            dataSort: true,
            toolTip: false,
            isHidden: false,
            width: 'auto',
            filter: { type: 'CustomFilter', getElement: getYearMultiSelectDropDown, 
                    customFilterParameters: { options: taxYearList, enableAllFilter: true, placeholder: 'Tax Year' } as any } as CustomFilter
        },
        {
            header: 'Date',
            key: documentStatusChangedOn,
            dataField: 'documentStatusChangedOn',
            isKey: false,
            dataFormat: defaultType,
            dataSort: true,
            isHidden: false,
            toolTip: false,
            className: 'text-field-header header-text',
            width: 'auto',
            filter: { type: 'CustomFilter', getElement: getDraftDateSingleSelectDropDown, 
                    customFilterParameters: { options: DaysRangeOptionsList, placeholder: 'Date' } as any } as CustomFilter
        },
        {
            header: 'Actions',
            key: button, // String-based value accessors!
            dataField: 'button',
            isKey: false,
            dataFormat: SignatureFlowReportAction,
            columnClassName: 'button-cell',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: '175px',
            className: 'text-field-header header-text',
            filter: { type: 'TextFilter', placeholder: '', style: { display: 'none' } } as TextFilter
        },
    ]

    const SortedColumns: any[] = GetSortedColumns(Columns);

    useImperativeHandle(ref, () => ({
        isAppiedFilter,
        filtersRef
      }));

    return <div className="pos-relative">
                <div>
                    {SortedColumns.length > 0 &&
                        <BootstrapTable
                        ref={signatureReportTableRef}
                        data={data}
                        remote={true}
                        fetchInfo={{ dataTotalSize: props.totalRows }}
                        options={options}
                        containerClass='report-table-container'
                        tableHeaderClass='report-header-border table-header-font'
                        trStyle={{borderBottom:'1px solid #A6A9AC', height: '32px'}}
                        striped
                        hover={true}
                        bordered={false}
                        pagination={false}
                        selectRow={selectRowProp}>
                        {SortedColumns.map((value, index) => {
                            return <TableHeaderColumn
                                key={index}
                                ref={value.key}
                                isKey={value.isKey}
                                dataField={value.dataField}
                                hidden={value.isHidden}
                                width={value.width}
                                dataFormat={value.dataFormat}
                                className={value.className ? value.className : ''}
                                columnTitle={value.toolTip}
                                filter={value.filter}
                                dataSort={value.dataSort}
                                caretRender={Helper.sortRenderer}>
                                    <span title={value.header} className="table-text-sort">{value.header}</span>
                            </TableHeaderColumn>;
                        })}
                        </BootstrapTable>
                    }
                </div>
        </div>
});

export default SignatureDraftReportTable;
