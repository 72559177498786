import { VenusNotifier } from './VenusNotifier';
import {
    ValidationContants,FileIsEncrypted, FileConstants, FileSizeExceedsLimit, SFDocument, MailMergeDocument, SFStep2Constants
} from './Constants'
import { IUserModel } from '../../../Core/ViewModels/User/UserViewModel';
import { ICompanySettings, IPasswordSettings, PasswordOptions } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { IValidationResult } from './ValidationResult'; 
import {FileType, IDocumentFileData} from '../../models/DocumentInfo';
import { AuthenticationType, IClient } from '../../models/SigningInfo';
import { constant } from 'lodash';

export function isValidEmailAddress(emailAddress: any) {
    if (emailAddress) {
        var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
        return pattern.test(emailAddress);
    }
    return false;
};
export function isValidIdentityServerEmailAddress(emailAddress: any) {
    var pattern = new RegExp(/^((([a-z]|\d|[\'\-_{\|}]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[\'\-_{\|}]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
    return pattern.test(emailAddress);
};
export function validateZip(ZipNoId: any) {
    var ZipNo = ZipNoId.trim();
    if (ZipNo.trim() == "") {
        VenusNotifier.Warning(ValidationContants.ZipWarning, "");
        return false;
    }
    else if (ZipNo.trim().length > 5 || ZipNo.trim().length < 5 || /^[0-9]{5}$/.test(ZipNo) == false) {
        VenusNotifier.Warning(ValidationContants.ZipLengthWarning, "");
        return false;
    }
    else
        return true;
}
export function validateExtension(ExtensionId: any) {
    var Extension = ExtensionId.trim();
    if (Extension.trim().length > 10 || /^[0-9]{1,10}$/.test(Extension) == false) {
        VenusNotifier.Warning(ValidationContants.ExtensionWarning, "");
        return false;
    }
    else
        return true;
}
export function validateFax(FaxId: any) {
    var Fax = FaxId.trim();
    if (Fax.trim().length < 10 || /^[0-9]{10}$/.test(Fax) == false) {
        VenusNotifier.Warning(ValidationContants.FaxWarning, "");
        return false;
    }
    else
        return true;
}
export function validateState(stateId: number) {
    if (stateId == 0) {
        VenusNotifier.Warning(ValidationContants.StateWarning, "");
        return false;
    }
}
export function validateCity(CityId: any) {
    var City = CityId.trim();
    if (City == "") {
        VenusNotifier.Warning(ValidationContants.CityWarning, "");
        return false;
    }
    else if (/^(?:[a-zA-Z]+(?:[.'\-,])?\s?)+$/.test(City) == false) {
        VenusNotifier.Warning(ValidationContants.ValidCityWarning, "");
        return false;
    }
    else
        return true;
}
export function validatePhone(phoneNoId: any) {
    var phoneNo = phoneNoId.trim();
    if (phoneNo.trim() == "") {
        VenusNotifier.Warning(ValidationContants.PhoneNumberWarning, "");
        return false;
    }
    else if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
        VenusNotifier.Warning(ValidationContants.PhoneNumberLengthWarning, "");
        return false;
    }
    else
        return true;
}
export function validatePassword(passwordId: any) {
    var password = passwordId.trim();
    if (password.trim().length < 8) {
        VenusNotifier.Warning(ValidationContants.PasswordLengthWarning, "");
        return false;
    }
    else if (password.trim().indexOf(' ') >= 0) {
        VenusNotifier.Warning(ValidationContants.PasswordWithoutSpaceWarning, "");
        return false;
    }
}

export function validatePasswordBasedOnPolicy(password: any, passwordSettings: IPasswordSettings): IValidationResult {
    var password = password.trim();
    const passwordOptions = passwordSettings.passwordOptions;
    if (password.trim().length < passwordSettings.length) {
        return {
            isError: true,
            errorDescription: 'Password must be minimum ' + passwordSettings.length + ' characters.'
        };
    }
    else if (password.trim().indexOf(' ') >= 0) {
        return {
            isError: true,
            errorDescription: ValidationContants.PasswordWithoutSpaceWarning
        };
    }
    else if ((passwordOptions & PasswordOptions.UpperCase) === PasswordOptions.UpperCase && !hasUpperCase(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.UpperCaseValidationError
        };
    }
    else if ((passwordOptions & PasswordOptions.LowerCase) === PasswordOptions.LowerCase && !hasLowerCase(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.LowerCaseValidationError
        };
    }
    else if ((passwordOptions & PasswordOptions.Numbers) === PasswordOptions.Numbers && !hasNumber(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.NumbersValidationError
        };
    }
    else if ((passwordOptions & PasswordOptions.SpecialCharacters) === PasswordOptions.SpecialCharacters && !hasSpecialCharacter(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.SpecialCharactersValidationError
        };
    }

    return {
        isError: false,
        errorDescription: "Success"
    };
}

function hasLowerCase(data: string) {
    var pattern = new RegExp('.*[a-z].*');
    return pattern.test(data);
}

function hasUpperCase(data: string) {
    var pattern = new RegExp('.*[A-Z].*');
    return pattern.test(data);
}

function hasNumber(data: string) {
    var pattern = new RegExp(/[\d]/i);
    return pattern.test(data);
}

function hasSpecialCharacter(data: string) {
    var pattern = new RegExp(/[~!@#$%^&*_]/i);
    return pattern.test(data);
}

export function bootboxAlert(message: string, callback: any) {
    bootbox.alert(message, callback);
}

export function validateUrl(url: string) {
    return /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i.test(url);
}

export function getFileExtension(fileName: string) {
    return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
}

export function getFileType(extension:string){
    switch(extension){
        case "pdf":
            return FileType.pdf;
        case "doc":
        case "docx":
            return FileType.word;
        case "csv":
            return FileType.csv;
        default:
            return FileType.undefined;
    }
}

export function getContentType(file: File): string {
    let contentType: string = file.type.slice(0, file.type.indexOf("/"));
    if (contentType == "application") {
        return contentType + "/" + getFileExtension(file.name);
    }
    return file.type;
}

export function validateEncryption (file:IDocumentFileData):boolean{
    if (file.encrypted) {
        VenusNotifier.Error(FileIsEncrypted(file.name),null)
        return true;
    }
    return false;
}

export function validateFileType(fileExtension: string): boolean {
    if (fileExtension.toLowerCase() !== "pdf") {
        VenusNotifier.Warning(ValidationContants.PdfFileValidation, "")
        return false;
    }
    return true;
}

export function validateFileSize(file: any): boolean {
    if (file.size > FileConstants.MaximumFileSize) {
        VenusNotifier.Error(FileSizeExceedsLimit(file.name), null);
        return false;
    }
    return true;
}
export function GetSafeFilename(fileName: string) 
{
    var pattern = new RegExp(/[^a-z A-Z 0-9. _\-]+/g);
    return fileName.replace(pattern, '_');
}
export function isValidSSN(ssn: string) {
    if (ssn.replace(/-/g, '').length == 9) { return true; }
};

export function isValidEIN(ein: string) {
    if (ein.replace(/-/g, '').length === 9) { return true; }
}

export function isValidDate(date: any) {
    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    return date_regex.test(date);
}

export function isDigit(zip: any) {
    var date_regex = /^[0-9]{1,10}$/;
    return date_regex.test(zip);
}

export function NullandEmptyCheck(text: string) {
    if (text == undefined || text == null || text.trim() == "") {
        return false;
    }
    return true;
}

export function isValidatePTIN(ptin: string) {
    var ptin_regex = /^[Pp][0-9]{8}$/;
    return ptin_regex.test(ptin);
}

export function validateUser(userDetails: IUserModel, enterPassword: string, confirmPassword: string, passwordValidation: boolean = true): boolean {
    if (!NullandEmptyCheck(userDetails.firstName)) {
        VenusNotifier.Warning(ValidationContants.FirstNameWarning, "");
        return false;
    }
    else if (!NullandEmptyCheck(userDetails.lastName)) {
        VenusNotifier.Warning(ValidationContants.LastNameWarning, "");
        return false;
    }
    else if (!NullandEmptyCheck(userDetails.email)) {
        VenusNotifier.Warning(ValidationContants.EmailAdrressWarning, "");
        return false;
    }
    else if (!isValidEmailAddress(userDetails.email.trim()) || userDetails.email.trim() == "") {
        VenusNotifier.Warning(ValidationContants.ValidEmailAddressWarning, "");
        return false;
    }
    else {
        if (NullandEmptyCheck(userDetails.phoneNumber)) {
            if (validatePhone(userDetails.phoneNumber) == false) {
                return false;
            }
        }
        if (NullandEmptyCheck(userDetails.faxNumber)) {
            if (validateFax(userDetails.faxNumber) == false) {
                return false;
            }
        }

        if (NullandEmptyCheck(userDetails.extension)) {
            if (validateExtension(userDetails.extension) == false) {
                return false;
            }
        }
        if (NullandEmptyCheck(userDetails.ptin)) {
            if (!isValidatePTIN(userDetails.ptin)) {
                VenusNotifier.Warning(ValidationContants.PTINWarning, "");
                return false;
            }
        }
        if (passwordValidation) {
            if (!NullandEmptyCheck(enterPassword) || !NullandEmptyCheck(confirmPassword)) {
                if (!NullandEmptyCheck(enterPassword)) {
                    VenusNotifier.Warning(ValidationContants.EnterPasswordWarning, "");
                    return false;
                }
            }
            if (NullandEmptyCheck(enterPassword)) {
                if (NullandEmptyCheck(confirmPassword)) {
                    if (validatePassword(confirmPassword) == false) {
                        return false;
                    }

                    if (enterPassword.trim() == confirmPassword.trim()) {
                        return true;
                    }
                    else {
                        VenusNotifier.Warning(ValidationContants.PasswordNotMatchedWarning, "");
                        return false;
                    }
                }
                else {
                    VenusNotifier.Warning(ValidationContants.EnterPasswordWarning, "");
                    return false;
                }
            }
        }
    }
    return true;
}

export function isValidFirmKey(firmKey: string) {
    var guid_regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return guid_regex.test(firmKey);
}

export function isValidGuid(guid: string) {
    var guid_regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return guid_regex.test(guid);
}

export function validatePhoneLength(phoneNoId: any) {
    var phoneNo = phoneNoId.trim();
    if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
        return false;
    }
    else
        return true;
}

export function validateFaxLength(FaxId: any) {
    var Fax = FaxId.trim();
    if (Fax.trim().length < 10 || /^[0-9]{10}$/.test(Fax) == false) {
        return false;
    }
    else
        return true;
}

export function validateValidCity(CityId: any) {
    var City = CityId.trim();
    if (/^(?:[a-zA-Z]+(?:[.'\-,])?\s?)+$/.test(City) == false) {
        return false;
    }
    else
        return true;
}
export function validateZipLength(ZipNoId: any) {
    var ZipNo = ZipNoId.trim();
    if (ZipNo.trim().length > 5 || ZipNo.trim().length < 5 || /^[0-9]{5}$/.test(ZipNo) == false) {
        return false;
    }
    else
        return true;
}
export function ValidateWebsite(website: string) {
    if (/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z A-Z 0-9]+([\-\.]{1}[a-z A-Z 0-9]+)*\.[a-z A-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(website) == false) {
        return false;
    }
    else {
        return true;
    }
}

export function validateExtensionLength(ExtensionId: any) {
    var Extension = ExtensionId.trim();
    if (Extension.trim().length > 6 || /^[0-9]{1,10}$/.test(Extension) == false) {
        return false;
    }
    else
        return true;

}
export function validatePasswordLength(passwordId: any) {
    var password = passwordId.trim();
    if (password.length < 8) {
        return false;
    }
    return true;
}
export function validatePasswordSpace(passwordId: any) {
    var password = passwordId.trim();
    if (password.indexOf(' ') >= 0) {
        return false;
    }
    return true;
}

export function validateCompanySettings(companySetting: ICompanySettings) {
    if (companySetting && companySetting.isDefault) {
        return false;
    }
    return true;
}

export function validateFileTypes(fileExtension: string): boolean {
    if (fileExtension.toLowerCase() !== "pdf" && fileExtension.toLowerCase() !== "docx" && fileExtension.toLowerCase() !== "doc" && fileExtension.toLowerCase() !== "png" &&
        fileExtension.toLowerCase() !== "jpeg" && fileExtension.toLowerCase() !== "xlsx"
        && fileExtension.toLowerCase() !== "jpg" && fileExtension.toLowerCase() !== "xls") {
        VenusNotifier.Warning(ValidationContants.FileFormatNotSupported, "")
        return false;
    }
    return true
}

export function validatePdfFileContent(signature: any) {
    if (signature === '25504446') { // 'application/pdf'
        return true;
    }
    return false;
}
export function validatePdfFile(fileExtension: string, signature: any) {
    if (fileExtension.toLowerCase() === "pdf" && validatePdfFileContent(signature)) {
        return true;
    }
    VenusNotifier.Warning(ValidationContants.PdfFileValidation, "")
    return false;
}

export function validateSFUploadFileType(fileExtension: string): boolean {
    if (fileExtension.toLowerCase() !== "pdf" && fileExtension.toLowerCase() !== "doc" && fileExtension.toLowerCase() !== "docx") {
        VenusNotifier.Warning(SFDocument.sfUploadFileWarning, "")
        return false;
    }
    return true;
}

export function validateMailMergeTemplateUploadFileType(fileExtension: string): boolean {
    if (fileExtension.toLowerCase() !== "csv") {
        VenusNotifier.Warning(MailMergeDocument.CsvUploadFileWarning, "")
        return false;
    }
    return true;
}

export function validateWordAndPDFFileContent(signature: any) {
    if (signature === '25504446') { // 'application/pdf'
        return true;
    }
    else if (signature === 'D0CF11E0') {
        return true;
    }
    else if (signature === '504B34') {
        return true;
    }
    return false;
}

export function isWordFile(signature: any) {
    return signature === 'D0CF11E0' || signature === '504B34';
}

export function ValidateCSVFileContent(signature: any): boolean {
    return signature === 'EFBBBF22' || signature === '5369676E' || signature === '53656E64' ? true : false;
}

export function fileContentValidation(signature: any) {
    if (signature === '25504446' ||             //.pdf
        signature === 'D0CF11E0' ||             //.doc
        signature === '504B34' ||             //.docx
        signature === '89504E47' ||             //.png
        signature === 'FFD8FFE1' ||             //.jpg
        signature === '504B0304' ||             //.xlsx
        signature === 'D0CF11E0' ||             //.xls
        signature === 'FFD8FFE0' ||             //.jpeg
        signature === 'FFD8FFE2'                //.jpeg

    ) {
        return true;
    }
    VenusNotifier.Warning(ValidationContants.FileFormatNotSupported, "")
    return false;
}

export function ConvertDateToUtc(date: Date): Date {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
}

export function ValidateTenDigitNumber(event: any) {
    var pattern = new RegExp(/^[0-9]{0,10}$/);
    return pattern.test(event.target.value);
}

export function ValidateTenDigitphoneNumber(value: string) {
    var pattern = new RegExp(/^[0-9\-\s)\(]{0,14}$/);
    return pattern.test(value);
}

export function ValidateTrustedDeviceExpiryDays(value: number) {
    if (value > 0 && value < 61) {
        return true;
    }
}

export function ValidateIPAdress(ipAddress: string): boolean {
    var ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    return ipRegex.test(ipAddress);
}


export function ValidateFontSizeValue(value: number) {
    if (value > 0 && value < 73) {
        return true;
    }
}

export function isAuthenticationValid(recipient: IClient): boolean {
    switch (recipient.authenticationType.toString()) {
        case AuthenticationType.AccessCodeSMS.toString():
            if (recipient.countryCode === null || recipient.countryCode === undefined || recipient.countryCode === "") {
                VenusNotifier.Warning(SFStep2Constants.CountryCodeWarning.replace('%RECIPIENT%', recipient.emailAddress), null);
                return false;
            }
            if (recipient.mobileNumber === null || recipient.mobileNumber === undefined || recipient.mobileNumber === "") {
                VenusNotifier.Warning(SFStep2Constants.MobileNumberWarning.replace('%RECIPIENT%', recipient.emailAddress), null);
                return false;
            }
            else if (recipient.mobileNumber.trim().length < 10 || /^[0-9]{1,10}$/.test(recipient.mobileNumber) == false) {
                VenusNotifier.Warning(SFStep2Constants.ValidMobile.replace('%RECIPIENT%', recipient.emailAddress), "");
                return false;
            }
            break;
        case AuthenticationType.First4SSN.toString():
            if (recipient.first4SSN === null || recipient.first4SSN === undefined || recipient.first4SSN === "") {
                VenusNotifier.Warning(SFStep2Constants.First4SSNWarning.replace('%RECIPIENT%', recipient.emailAddress), null);
                return false;
            }
            if (recipient.first4SSN && /^[0-9]{4}$/.test(recipient.first4SSN) == false) {
                VenusNotifier.Warning(SFStep2Constants.SSNFormatWarning.replace('%RECIPIENT%', recipient.emailAddress), null);
                return false;
            }
            break;
        case AuthenticationType.Last4SSN.toString():
            if (recipient.last4SSN === null || recipient.last4SSN === undefined || recipient.last4SSN === "") {
                VenusNotifier.Warning(SFStep2Constants.Last4SSNWarning.replace('%RECIPIENT%', recipient.emailAddress), null);
                return false;
            }
            if (recipient.last4SSN && /^[0-9]{4}$/.test(recipient.last4SSN) == false) {
                VenusNotifier.Warning(SFStep2Constants.SSNFormatWarning.replace('%RECIPIENT%', recipient.emailAddress), null);
                return false;
            }
            break;
        case AuthenticationType.QuestionAnswer.toString():
            if (recipient.question === null || recipient.question === undefined || recipient.question === "") {
                VenusNotifier.Warning(SFStep2Constants.QuestionWarning.replace('%RECIPIENT%', recipient.emailAddress), null);
                return false;
            }
            if (recipient.answer === null || recipient.answer === undefined || recipient.answer === "") {
                VenusNotifier.Warning(SFStep2Constants.AnswerWarning.replace('%RECIPIENT%', recipient.emailAddress), null);
                return false;
            }
            break;
        case AuthenticationType.AccessCodeEmail.toString():
        case AuthenticationType.None.toString():
            return true;
    }
    return true;
}

export function validateError(error: any) {
    if (typeof error === 'string') return error;
    if (typeof error === 'undefined') return '';
    if (error.message !== undefined) return error.message;
    if (error.statusText !== undefined) return error.statusText;
    return '';
}

export function validateInputTextCharacterMaxLength(value: string) {
    const pattern = new RegExp(/^(.{0,100})$/);
    return pattern.test(value);
}
