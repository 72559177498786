import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/types';
import * as SignatureFlowSettingsStore from '../../../store/SignatureFlowSettingsStore';
import * as SignatureFlowAuthenticationQuestionsStore from '../../../store/settings/AuthenticationQuestions/AuthenticationQuestionsStore';
import { SecuritySettings } from './SecuritySettings';

export default connect(
    (state: ApplicationState) => ({
        signatureFlowSettingsData: state.signatureFlowSettingsData,
        authenticationQuestionsData: state.AuthenticationQuestionsData,
    }),
    {
        ...SignatureFlowSettingsStore.actionCreators,
        ...SignatureFlowAuthenticationQuestionsStore.actionCreators
    }
)(SecuritySettings as any);