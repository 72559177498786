export interface IUserDefaultSettingsStates {
	defaultSettings: IDefaultSettings;
	signatureSettings: ISignatureSettings;
	enableSignerDeligation: boolean;
	useSignatureStamp: boolean;
	signatureDelegation: ISignatureDelegation;
	userId: number;
	mfaSettings: IMFASettings
}

export interface DefaultSenderInfo {
	senderType: DefaultSenderInfoType;
	senderId: number;
}

export enum DefaultSenderInfoType {
	None = "None",
	Company = "Company",
	User = "User"
}

export interface DefaultDownloadInfo {
    downloadType: DefaultDownloadInfoType;
    rememberDefaultDownload: boolean;
}

export enum DefaultDownloadInfoType {
    DownloadNow = "DownloadNow",
    DownloadToMyDownloads = "DownloadToMyDownloads",
    PromptMeEveryTime = "PromptMeEveryTime"
}

export interface IDefaultSettings {
	manualAddressId: number;
	eroUser: number;
	contactPerson: number;
	sender: DefaultSenderInfo;
	notifyUser: number;
	reportFilter: string;
	enableLastLogin: boolean;
}


export interface INotificationSettings {
	lastPersonNotified: number;
	isReturnReady: boolean;
	isReturnAssigned: boolean;
	isApprovedForDelivery: boolean;
}

export interface ISignatureSettings {
	isAutoPlaceSignature: boolean;
	isEnableSignatureDelegation: boolean;
	signaturePath: string;
}

export interface IMFASettings {
	isMfaEnabled: boolean;
}

export interface DefaultMailingAddress {
	id: number;
	title: string;
}

export interface ISignatureDelegation {
	userId: number;
	delegate: number[];
}

export interface ISignatureDefaultSettings {
	notifyUser: number;
	showDefaultWarning: boolean;
	defaultDownloadInfo: DefaultDownloadInfo;
}

export interface ISignatureUserSettings {
	userId: number;
	defaultSettings: ISignatureDefaultSettings;
}

export enum LogoutCause {
	None = 0,
	UserPermissionChange = 1,
	UserDelete = 2,
	ProductTierChange = 3,
	CompanyDisable = 4,
	EmailUpdated = 5,
}