import { Reducer } from 'redux';
import { actionTypes } from '../ActionTypes';
import {
    RequestSignatureFlowReportDocumentPagesAction, ReceiveSignatureFlowReportDocumentPagesAction, ResetSignatureFlowReportDocumentPagesAction
} from './KnownTypes';
import { ISignatureFlowReportDictionary, ISignatureFlowReportState } from '../../models/SignatureFlowReportTableState';

type dispatchAction =
    RequestSignatureFlowReportDocumentPagesAction |
    ReceiveSignatureFlowReportDocumentPagesAction |
    ResetSignatureFlowReportDocumentPagesAction;


const unloadedState: ISignatureFlowReportState = {
    query: '?',
    signatureFlowReportTableModel: {},
    totalRowCount: 0,
    isLoading: false,
} as ISignatureFlowReportState

const initialState = {
    '?': unloadedState
}

export const reducer: Reducer<ISignatureFlowReportDictionary> = (state = initialState, incomingAction) => {
    const action = incomingAction as dispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES:
            if (!state[action.query]) {

                let added = { ...unloadedState } as ISignatureFlowReportState;
                added.isLoading = true;
                added.query = action.query;
                let item = {} as ISignatureFlowReportDictionary;
                item[action.query] = added;

                return {
                    ...state,
                    ...item
                };
            }
            break;

        case actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES:
            if (state[action.query]) {
                let changed = state[action.query] as ISignatureFlowReportState;
                changed.isLoading = false;
                changed.signatureFlowReportTableModel = action.table;
                changed.totalRowCount = action.totalRowCount;
                let item = {} as ISignatureFlowReportDictionary;
                item[action.query] = changed;
                return {
                    ...state,
                    ...item
                };
            }
            break;
        case actionTypes.RESET_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES:
            return {} as ISignatureFlowReportDictionary;
    }
    return state;
}


