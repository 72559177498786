import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { API_BASE_URL } from '../../../utils/AppConstants';
import { AppThunkAction } from '../';
import { handleResponse } from '../Library';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import * as Helper from '../../components/helper/Constants';
import { ISignatureUserSettings, IUserDefaultSettingsStates, DefaultDownloadInfoType } from '../../models/user/UserSettingsModels';

import { logger } from '../../../routes/LoggedIn';

export interface UserSettings {
    settings: IUserDefaultSettingsStates;
    signatureSettings: ISignatureUserSettings;
    error: boolean;
    isLoading: boolean;
    message: string;
}

interface RequestUserSettingAction {
    type: actionTypes.REQUEST_USER_SETTINGS;
}

interface ReceiveUserSettingAction {
    type: actionTypes.RECEIVE_USER_SETTINGS;
    settings: IUserDefaultSettingsStates;
}

interface ReceiveSignatureUserSettingAction {
    type: actionTypes.RECEIVE_SIGNATURE_USER_SETTINGS;
    settings: ISignatureUserSettings;
}

interface ErrorUserSettingAction {
    type: actionTypes.ERROR_USER_SETTINGS;
    message: string;
}

type KnownAction =
    RequestUserSettingAction |
    ReceiveUserSettingAction |
    ErrorUserSettingAction |
    ReceiveSignatureUserSettingAction |
    NotificationAction;

export const actionCreators = {
    requestUserSettings: (reload: boolean = false, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (reload || !getState().userSettings.settings) {
            let fetchTask = fetch(API_BASE_URL +'api/UserSettings/UserSetting', {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json as Promise<IUserDefaultSettingsStates>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_USER_SETTINGS, settings: data });
                    if (callback) {
                        callback();
                    }
                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_USER_SETTINGS, message: Helper.UserSettings.fetchUserSettings });
                });
            addTask(fetchTask);
            //dispatch({ type: actionTypes.REQUEST_USER_SETTINGS });
        }
    },
    requestSignatureUserSettings: (reload: boolean = false, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (reload || !getState().userSettings.signatureSettings) {
            let fetchTask = fetch(API_BASE_URL +'api/UserSettings/GetSignatureUserSetting', {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json as Promise<ISignatureUserSettings>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_SIGNATURE_USER_SETTINGS, settings: data });
                    if (callback) {
                        callback();
                    }
                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_USER_SETTINGS, message: Helper.UserSettings.fetchUserSettings });
                    logger.trackError(`requestSignatureUserSettings failed with error ${error.message}`);

                });
            addTask(fetchTask);
        }
    },

    updateSignatureSettingsState: (settings: ISignatureUserSettings): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RECEIVE_SIGNATURE_USER_SETTINGS, settings: settings });
    },

    saveUserSettings: (settings: IUserDefaultSettingsStates, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let fetchTask = fetch(API_BASE_URL +'api/UserSettings', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(settings),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(json => json as Promise<IUserDefaultSettingsStates>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_USER_SETTINGS, settings: settings });
                if (callback) {
                    callback();
                }

                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsSuccess, statusType: StatusType.Success });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_USER_SETTINGS, message: Helper.UserSettings.saveUserSettingsError });
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsError, statusType: StatusType.Error, statusCode:error?.status});

                logger.trackError(`saveUserSettings failed for the request having parameters ${JSON.stringify(settings)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    updateUserSettings: (settings: ISignatureUserSettings, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (settings.defaultSettings.defaultDownloadInfo.downloadType == DefaultDownloadInfoType.PromptMeEveryTime || settings.defaultSettings.defaultDownloadInfo.downloadType == null) {
            settings.defaultSettings.defaultDownloadInfo.rememberDefaultDownload = false;
            settings.defaultSettings.defaultDownloadInfo.downloadType = DefaultDownloadInfoType.PromptMeEveryTime;
        } else {
            settings.defaultSettings.defaultDownloadInfo.rememberDefaultDownload = true;
        }
        
        let fetchTask = fetch(API_BASE_URL +'api/UserSettings/UpdateUserSettings', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(settings),
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
        }
    })
        .then(handleResponse)
        .then(json => json as Promise<ISignatureUserSettings>)
        .then(data => {
            dispatch({ type: actionTypes.RECEIVE_SIGNATURE_USER_SETTINGS, settings: settings });
            if (callback) {
                callback();
            }

            dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsSuccess, statusType: StatusType.Success });
        })
        .catch(error => {
            dispatch({ type: actionTypes.ERROR_USER_SETTINGS, message: Helper.UserSettings.saveUserSettingsError });
            dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsError, statusType: StatusType.Error, statusCode:error?.status });

            logger.trackError(`updateUserSettings failed for the request having parameters ${JSON.stringify(settings)} with error ${error.message}`)
        });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_USER_SETTINGS });
    }

};

const unloadedState: UserSettings = {
    settings: {},
    signatureSettings: {},
    isLoading: false
} as UserSettings;

export const reducer: Reducer<UserSettings> = (state: UserSettings = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_USER_SETTINGS:
            let cleanSettings: UserSettings = { ...state };
            cleanSettings.error = false;
            cleanSettings.isLoading = true;
            cleanSettings.message = '';
            return cleanSettings;
        case actionTypes.RECEIVE_USER_SETTINGS:
            let updated: UserSettings = { ...state };
            updated.error = false;
            updated.settings = action.settings;
            updated.isLoading = false;
            updated.message = Date();
            return updated;
        case actionTypes.RECEIVE_SIGNATURE_USER_SETTINGS:
            return {
                ...state,
                error : false,
                signatureSettings : action.settings,
                isLoading : false,
                message : ''
            }
        case actionTypes.ERROR_USER_SETTINGS:
            return {
                ...state,
                isLoading: false,
                error: true,
                message: action.message
            } as UserSettings;
    }

    return state || unloadedState;
};
