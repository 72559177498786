import React from "react";
import { Button, Modal } from "react-bootstrap";
import { CloseXButton } from "../svg/CustomSvgIcons";

export interface ILocationMismatchActionModalProps{
    show:boolean;
    onCancel:()=>void;
    onContinue:()=>void;
    locationName: string;
}

const LocationMismatchModal: React.FC<ILocationMismatchActionModalProps> = (props) => {
    return (
        <Modal show={props.show} onHide={props.onCancel} className="location-mismatch-modal" backdrop="static" >
            <Modal.Header>
                <div>
                    <Modal.Title>Attention</Modal.Title>
                </div>
                <div data-test-auto="3AE0C223-EB6A-4F04-8BBC-72FD908CB985" className="closeIcon" onClick={props.onCancel}>
                    <CloseXButton width={'16px'} height={'17px'} marginBottom={'1px'} color="black"/>
                </div>
            </Modal.Header>
           
            <Modal.Body className="show-grid">
                <div className="warning-alter">
                    <p>
                        By changing Office Location to {props.locationName} you will no longer have access to it as you are not
                        assigned to that office.<br />Press Continue to change office location or Cancel to keep the current office
                        location.
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                data-test-auto="AF335F34-D26C-456E-A6DC-8F1665834793" 
                variant="secondary" 
                onClick={props.onCancel}>
                    Cancel
                </Button>
                <Button 
                onClick={props.onContinue} 
                data-test-auto="62ECF9B0-E98D-4A3A-8961-3F8D0DDA84D3" 
                variant="primary">
                    Continue
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LocationMismatchModal;