import * as React from 'react';
import { Alert, Modal, Col, FormGroup, FormLabel, Button, FormControl, Row } from 'react-bootstrap';
import { LoadingOverlay } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { SigFlowCompanySettingsConstants } from '../helper/Constants';
import { DocumentTypeExpirationList } from '../../components/helper/UserHelperFunctions';
import { IDocumentType } from '../../models/SignatureFlowSettings';
import { OverlayLoader } from '../../components/helper/OverlayLoader';
import { ArrowRendererProps } from 'react-select';
import { InfoIconFill, SelectDropdownCaret } from '../svg/CustomSvgIcons';
import Select from 'react-select';
import { VenusNotifier } from '../helper/VenusNotifier';

interface IDocumentTypeProps {
    documentType: IDocumentType;
    show: boolean;
    documentTypes: IDocumentType[];
    onCancel: () => void;
    buttonText: string;
    title: string;
    onSaveDocumentType: (id: number, value: string, expiration: number, isActive: boolean) => void;
}

interface ChangeState {
    description: string;
    fileExpiration: number;
    isActive: boolean;
    message: string;
    alert: string;
    showAlert: boolean;
    showLoader: boolean;
    loaderText: string;
}

const msg = {
    saving: 'Saving Document Value...',
    loading: 'Loading the Document Value',
    warning: '',
    errorValue: 'Please Enter Document Type Value!',
    errorFileExpiration: 'Please select File Expiration!',
    errorDuplicateName: 'The name already exists. Please enter different name.'
}
export class DocumentTypePopup extends React.Component<IDocumentTypeProps, ChangeState>{
    constructor(props: IDocumentTypeProps) {
        super(props);
        this.state = {
            description: '',
            isActive: false,
            fileExpiration: 1,
            message: msg.warning,
            alert: '',
            showAlert: false,
            showLoader: false,
            loaderText: 'Saving...'
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.onFileExpirationChange = this.onFileExpirationChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: IDocumentTypeProps) {
        let state: ChangeState = {
            message: msg.loading,
            fileExpiration: 0,
            description: '',
            isActive: false,
            alert: '',
            showAlert: false,
            showLoader: false,
            loaderText: 'Saving...'
        };
        if (nextProps.show &&
            nextProps.documentType) {
            state.description = nextProps.documentType.description ? nextProps.documentType.description : this.state.description;
            state.fileExpiration = nextProps.documentType.expiration ? nextProps.documentType.expiration : this.state.fileExpiration;
            state.isActive = nextProps.documentType.isActive ? nextProps.documentType.isActive : this.state.isActive;
            state.message = msg.warning;
        }
        this.setState(state);
    }

    
    selectDropdownCaretRenderer = (props: ArrowRendererProps) => {
        return <SelectDropdownCaret className="selectDropdownCaretRenderer" style={{fill:'#212529'}}/>
    }

    public render() {

        return (<Modal
            className="document-type-modal sigflow-settings-popup" 
            centered={true} 
            backdrop="static"
            show={this.props.show}
            onHide={this.props.onCancel}
        >
            <OverlayLoader show={this.state.showLoader} text={this.state.loaderText} />
            <Modal.Header closeButton>
                <Modal.Title>
                    {this.props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='fontSize14-all'>
                <Alert variant={this.state.alert} hidden={!this.state.showAlert}><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px', color: '#666' }}>
                </i>{this.state.message}</Alert>
                <div className="col-sm-9 col-lg-9 font16 custom-alert-warning-container">
                    <div className='custom-alert-warning'> 
                        <span style={{fontSize:'16px'}}>
                            <span style={{paddingLeft:'7px', paddingRight:'8px'}}>
                                <InfoIconFill marginLeft='5px' marginBottom='2px' height='16px' width='16px' />
                            </span>
                            {SigFlowCompanySettingsConstants.DocumentType.DocumentTypeEditAlert}
                        </span>       
                    </div>
                </div>
                <LoadingOverlay style={{ height: '100%' }}>
                    {this.props.documentType &&
                        <div className="row marB03">
                            <Col sm={8}>
                                <FormGroup className="dispFR">
                                    <FormLabel>Value</FormLabel>
                                    <FormControl type="text"
                                        value={this.state.description}
                                        onChange={this.onValueChange}
                                        data-test-auto="E8222FA8-F9CA-43D9-8497-BFE686E17117" />
                                </FormGroup>
                                <FormGroup className="dispFR">
                                    <FormLabel>File Expiration</FormLabel>
                                    <div className='d-flex'>
                                        <div className='w-100'>
                                        <Select
                                            id={"ddlUserChange"}
                                            arrowRenderer={this.selectDropdownCaretRenderer}
                                            searchable={false}
                                            options={DocumentTypeExpirationList()}
                                            onChange={(event: any) => this.onFileExpirationChange(event.value)}
                                            value={this.state.fileExpiration}
                                            clearable={false}
                                            data-test-auto="220AB560-0CD9-4BDC-92B8-904688B7AAA5"
                                        />
                                           
                                        </div>
                                        <div className={"custom-curve-check-box"} >
                                           <div className="form-check padL00">
                                               <input
                                                   id={"DocumentTypeActivated"}
                                                   className="form-check-input magic-checkbox" 
                                                   type="checkbox" 
                                                   value="" 
                                                   checked={this.state.isActive}
                                                   onChange={this.onActiveChange}
                                                   data-test-auto={"3BDB23FB-2854-4FFC-BA0A-338AD6932396"}>
                                               </input>
                                               <label htmlFor={'DocumentTypeActivated'} 
                                                       className={"form-check-label "+ ( this.state.isActive ? "" : "")}>
                                                   <span className='fontSize14'>Activated</span>
                                               </label>
                                           </div>
                                       </div>
                                    </div>
                                </FormGroup>
                                <FormGroup className="dispFR marT15">
                                    
                                </FormGroup>
                            </Col>
                        </div>}
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => { this.props.onCancel() }}
                    variant='default'
                    className='btn-white modal-footer-button-cancel'>
                        Cancel
                    </Button>
                <Button
                    variant='info'
                    className='modal-footer-button-save'
                    onClick={this.onSubmit} >
                        {this.props.buttonText}
                </Button>
            </Modal.Footer>
        </Modal>)
    }

    private onValueChange = (value: any) => {
        this.setState({ description: value.target.value });
    }

    private onFileExpirationChange = (value: any) => {
        this.setState({ fileExpiration: value });
    }

    private onActiveChange = (value: any) => {
        this.setState({ isActive: value.target.checked });
    }

    private onSubmit() {
        if (!this.state.description.trim()) {
            VenusNotifier.Error(msg.errorValue,"");
            return;
        }

        let index = this.props.documentTypes.findIndex((value: IDocumentType) => value.description === this.state.description
            && this.props.documentType.id !== value.id);
        if (index !== -1) {
            VenusNotifier.Error(msg.errorDuplicateName,"");
            return;
        }

        if (!this.state.fileExpiration) {
            VenusNotifier.Error(msg.errorFileExpiration ,"");
            return;
        }

        if (!this.state.showLoader) {
            this.setState({ message: msg.saving, showAlert: false }, () => {
                this.props.onSaveDocumentType(this.props.documentType.id, this.state.description, this.state.fileExpiration, this.state.isActive);
                this.setState({ showLoader: true });
            });
        }
    }
}