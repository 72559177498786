import { IMessage } from '../../../models/SignatureFlowSettings';

export enum SavedMessageActionTypes {
    REQUEST_SIGNATURE_FLOW_SAVED_MESSAGES = 'REQUEST_SIGNATURE_FLOW_SAVED_MESSAGES',
    RECEIVE_SIGNATURE_FLOW_SAVED_MESSAGE = 'RECEIVE_SIGNATURE_FLOW_SAVED_MESSAGE',
    RECEIVE_SIGNATURE_FLOW_SAVED_MESSAGES = 'RECEIVE_SIGNATURE_FLOW_SAVED_MESSAGES',
    DELETE_SIGNATURE_FLOW_SAVED_MESSAGE = 'DELETE_SIGNATURE_FLOW_SAVED_MESSAGE',
    RESET_SIGNATURE_FLOW_SAVED_MESSAGE_DATA = 'RESET_SIGNATURE_FLOW_SAVED_MESSAGE_DATA'
}

export interface RequestSignatureFlowSavedMessages {
    type: SavedMessageActionTypes.REQUEST_SIGNATURE_FLOW_SAVED_MESSAGES;
}

export interface ReceiveSignatureFlowSavedMessage {
    type: SavedMessageActionTypes.RECEIVE_SIGNATURE_FLOW_SAVED_MESSAGE;
    savedMessage: IMessage;
    messageId: number;
}

export interface ReceiveSignatureFlowSavedMessages {
    type: SavedMessageActionTypes.RECEIVE_SIGNATURE_FLOW_SAVED_MESSAGES;
    savedMessages: IMessage[];
}

export interface DeleteSignatureFlowSavedMessage {
    type: SavedMessageActionTypes.DELETE_SIGNATURE_FLOW_SAVED_MESSAGE;
    message: IMessage;
}

export interface ResetSignatureFlowSavedMessageData {
    type: SavedMessageActionTypes.RESET_SIGNATURE_FLOW_SAVED_MESSAGE_DATA;
}