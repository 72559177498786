import * as React from 'react';
import { Modal } from 'react-bootstrap';
import 'isomorphic-fetch';
import { TYPES } from './../../../../Startup/types';
import { container } from './../../../../Startup/inversify.config';
import { ISessionLocalStore } from './../../../../Core/Utilities/SessionStore';
const SessionStore = container.get<ISessionLocalStore>(TYPES.ISessionStore);
import { IModalProps } from './ProfileObjects'
import { clearAllTemporaryFilter } from '../../common/Filters';
import { WithLogout, WithLogoutProps } from 'src/oidcClient/withLogout';



class Logout extends React.Component<IModalProps & WithLogoutProps, {}> {
    constructor(props: IModalProps & WithLogoutProps) {
        super(props);

        this.onLogout = this.onLogout.bind(this);
    }
    private onLogout(e: React.SyntheticEvent<EventTarget>) {
        e && e.preventDefault();
        clearAllTemporaryFilter();
        SessionStore.remove("isVisited");
        this.props.onLogout();
    }

    public render() {
        return <Modal
            show={this.props.showState}
            contentClassName="border-radius-5"
            dialogClassName="common-modal">

            <Modal.Header className="common-modal-header">
                <span className='common-modal-header-text'>
                    Log Out
                </span>
            </Modal.Header>
            <Modal.Body className="common-modal-content">
                Are you sure you want to log out? You will be logged out of all instances of the SafeSend Suite.
            </Modal.Body>
            <Modal.Footer className="common-modal-footer">
                <button
                    className="modal-secondary-button secondary-btn-bg"
                    onClick={this.props.onHide}
                    data-test-auto="31DF48DE-9F14-4065-B44A-08D265BA2CF5"
                >No, Discard</button>
                <button
                    className="modal-primary-button red-btn-bg"
                    onClick={this.onLogout}
                    data-test-auto="32FE58D3-FB91-4F86-98BA-29D93FBB5762"
                >Yes, Log Out</button>
            </Modal.Footer>
        </Modal>
    }
}
export default WithLogout(Logout);