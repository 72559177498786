import { IUserModel, initialUserModel } from '../../Core/ViewModels/User/UserViewModel';
import { IDocumentInfo } from './DocumentInfo';
import { Guid } from '../../Core/Utilities/Guid';
import { getCurrentTaxYear } from '../components/helper/HelperFunctions';
import { ISignatureFlowSettings } from './SignatureFlowSettings';
import { ErrorMessage } from './Common';

export enum ClientType {
    Undefined = 0,
    Signer = 1,
    CC = 2,
    Sender = 3,
    Delegatee = 4
}

export interface IClient {
    clientGuid: Guid;
    emailAddress: string;
    clientType: ClientType;
    authenticationType: AuthenticationType;
    countryCode?: string;
    mobileNumber?: string;
    question?: string;
    answer?: string;
    first4SSN?: string;
    last4SSN?: string;
    delegateeId?: number;
    signingOrder?: number;
    id?: number;
}

export enum AuthenticationType {
    None = 0,
    First4SSN = 1,
    Last4SSN = 2,
    AccessCodeEmail = 3,
    AccessCodeSMS = 4,
    QuestionAnswer = 5,
    KBA = 6
}

export class Client implements IClient {
    clientType: ClientType;
    clientGuid: Guid;
    emailAddress: string;
    authenticationType: AuthenticationType;
    signingOrder?: number;


    constructor(emailAddress: string, clientGuid: Guid, clientType: ClientType, authenticationType: AuthenticationType, signingOrder: number) {
        this.emailAddress = emailAddress;
        this.clientGuid = clientGuid;
        this.clientType = clientType;
        this.authenticationType = authenticationType;
        this.signingOrder = signingOrder;
    }
}

export function createClient(emailAddress: string, clientGuid: Guid, clientType: ClientType, authenticationType: AuthenticationType, signingOrder: number): IClient {
    return new Client(emailAddress, clientGuid, clientType, authenticationType, signingOrder);
}

export interface ISendForSignatureModel{
    signingInfo:ISigningInfo;
    signatureFlowSettings:ISignatureFlowSettings;
}

export interface ISigningInfo {
    id?:number;
    sender: IUserModel;
    delegate?: IUserModel;
    message?: string;
    messageId?: number;
    recipients: IClient[];
    CC: string[];
    senderSignOption: SenderSignOption;
    documentAccess: IDocumentAccess;
    documentInfo: IDocumentInfo[];
    mailType: SignatureFlowMailType;
    title: string;
    clientSender: IClient;
    isAccessCodeAuthenticationRequired: boolean;
    recipientName: string;
    taxYear: number;
    clientId: string;
    signerAuthentication?: boolean;
    draftState: DraftState;
    documentStatusChangedOn:Date;
    assignedTo:number;
    locationId: number;
    contactPersonId: number;
    signingType: SigningType;
}

export interface IOfficeLocation {
    locationId?: number,
    locationName?: string;
}

export interface ILocationMismatchModalState {
    isModalOpen: boolean;
    shouldShowModal: boolean;
    locationMissmatchCallback: () => void;
}

 export enum DraftState {        
    None = 0,
    AddSigner = 1,
    AddSignatureControls = 2,
    ReviewSend = 3
}

export enum SenderSignOption {
    'NO_SIGN', 'SIGNS_FIRST', 'SIGNS_LAST'
}

export interface IDocumentAccess {
    SigningId: number;
    userIds: number[];
}

export enum SignatureFlowMailType {
    None = 0,
    Signatures,
    SignMerge,
}

export interface IContact {
    emailAddress: string;
    name?: string;
}

export interface ChangeOfficeLoctionErrorList {
    officeLocation: ErrorMessage;
    contactPerson: ErrorMessage;
}

export const initialDocumentAccess: IDocumentAccess =
{
    SigningId: 0,
    userIds: []
};

export enum SigningType
{
    None = 0,
    Sequential = 1,
    Custom = 2,
    Parallel = 3
}

export const initialClient: IClient = createClient('', new Guid(Guid.empty), ClientType.Undefined, AuthenticationType.None, 1);

export const initialSigningInfo: ISigningInfo = {
    sender: initialUserModel,
    CC: [],
    recipients: [],
    senderSignOption: SenderSignOption.NO_SIGN,
    documentAccess: initialDocumentAccess,
    documentInfo: [],
    mailType: SignatureFlowMailType.None,
    title: '',
    clientSender: initialClient,
    isAccessCodeAuthenticationRequired: false,
    taxYear: getCurrentTaxYear(),
    recipientName: '',
    clientId: '',
    draftState: DraftState.None,
    documentStatusChangedOn: undefined,
    assignedTo: 0,
    locationId: 0,
    contactPersonId: -1,
    signingType: SigningType.Parallel
}

export const initialLocationMismatchModalState: ILocationMismatchModalState = {
    isModalOpen: false,
    shouldShowModal: false,
    locationMissmatchCallback: () => { },
}

export const initialChangeOfficeLoctionErrorList: ChangeOfficeLoctionErrorList = {
    officeLocation: { error: false, message: "" },
    contactPerson: { error: false, message: "" }
}
