import { connect } from 'react-redux';
import { ApplicationState } from "../../store/types";
import { SignalRWebSocket } from './SignalRWebSocket';
import * as UserProfileStore from '../../store/userManagement/UserProfileStore';
import * as CompanyStore from '../../store/Company/CompanyStore';
import * as DeliveredReportStore from '../../store/ReportsStore/DeliveredReport/DeliveredReportsStore';
import * as DocumentStore from '../../store/DocumentStore';
import * as MyDownloads from '../../store/MyDownloadsStore';
import * as RecycleReportStore from '../../store/ReportsStore/RecycleReport/RecycleReportStore';

export default connect(
    (state: ApplicationState) => ({
        UserProfile: state.auth,
        DeliveredReportDocuments: state.DeliveredReportDocuments,

    }),
    {
        ...UserProfileStore.actionCreators,
        ...CompanyStore.actionCreators,
        ...DeliveredReportStore.actionCreators,
        ...RecycleReportStore.actionCreators,
        ...DocumentStore.actionCreators,
        ...MyDownloads.actionCreators
    }
)(SignalRWebSocket as any);