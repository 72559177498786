import * as React from 'react'
import { Modal, Button, Col, Row } from 'react-bootstrap';

interface SecurityModalProps {
    openModal: boolean;
}

export default class SecurityModal extends React.Component<SecurityModalProps, any>{
    
    private onCloseModal = (e: any): void => {
        e && e.preventDefault();
        location.reload();
    }

    render() {
        const { openModal } = this.props;
        return (
            <div>
                <Modal show={openModal} onHide={this.onCloseModal} className="session-timeout-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <span className='modalIcon text-secondary fas fa-info-circle'>
                            </span>Security
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row>
                                <Col lg={3}>
                                </Col>
                                <Col lg={9}>
                                    <h2 style={{ color: '#0973BA', margin: '0' }}>Session Expired</h2>
                                    <Row className="margin-top-10">
                                       Your session has expired. Please login again.
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn-white"
                            variant='default'
                            onClick={this.onCloseModal}
                        >
                            Login
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

