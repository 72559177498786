import { actionTypes } from "../ActionTypes";
import { NotificationAction, StatusType } from '../../store/common/NotificationStore';
import { handleResponse } from "../Library";
import { API_BASE_URL } from "src/utils/AppConstants";
import { AppThunkAction } from "..";
import { addTask } from "domain-task";
import { Reducer } from "redux";
import { IOfficeLocation } from "src/SignatureFlow/models/SigningInfo";
import { logger } from "src/routes/LoggedIn";
import * as Constants from '../../components/helper/Constants';
import { IDropdown } from "src/Core/ViewModels/User/UserViewModel";
import { validateError } from "src/SignatureFlow/components/helper/Validations";

export interface IOfficeLocationState {
    locationDropdown: IDropdown[];
    loading: boolean;
}

export interface IUserOfficeLocationState {
    userLocation: IOfficeLocation[];
}

export interface RequestUserOfficeLocationDropdownAction {
    type: actionTypes.REQUEST_USER_OFFICE_LOCATION;
}

export interface ReceiveUserOfficeLocationDropdownAction {
    type: actionTypes.RECEIVE_USER_OFFICE_LOCATION;
    userLocation: IOfficeLocation[];
}

export interface RequestOfficeLocationDropdownAction {
    type: actionTypes.REQUEST_OFFICE_LOCATION_DROPDOWN;
}

export interface ReceiveOfficeLocationDropdownAction {
    type: actionTypes.RECEIVE_OFFICE_LOCATION_DROPDOWN;
    locationDropdown: IDropdown[];
}

type KnownAction =
    RequestOfficeLocationDropdownAction |
    ReceiveOfficeLocationDropdownAction |
    RequestUserOfficeLocationDropdownAction|
    ReceiveUserOfficeLocationDropdownAction|
    NotificationAction;

const officeLocationUnloadedState: IOfficeLocationState = {
    locationDropdown: [],
    loading: false
} as IOfficeLocationState;

const userOfficeLocationUnloadedState: IUserOfficeLocationState = {
    userLocation: []
} as IUserOfficeLocationState;

export const actionCreators = {
    getOfficeLocationDropdown: (reload: boolean = false, callback?: ()=> void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        if (reload || state.officeLocation?.locationDropdown.length == 0) {
            const fetchTask = fetch(API_BASE_URL + 'api/office-location/dropdown', { credentials: 'include' })
                .then(handleResponse)
                .then(response => response as Promise<IDropdown[]>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_OFFICE_LOCATION_DROPDOWN, locationDropdown: data
                    });
                    callback && callback();
                }).catch(function (error) {
                    const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof (statusMessage) === "string") {
                        dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                    }
                    logger.trackError(error);
                });
            addTask(fetchTask);

            dispatch({ type: actionTypes.REQUEST_OFFICE_LOCATION_DROPDOWN });
        }
    },

    fetchUserOfficeLocation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + "api/UserOfficeLocation/GetAsync", {
            method: "GET",
            credentials: "include",
            headers: {
                Accept: "application/json, */*",
                "Content-Type": "application/json",
            }
        })
            .then(handleResponse)
            .then((response: any) => {
                dispatch({
                    type: actionTypes.RECEIVE_USER_OFFICE_LOCATION, userLocation: response
                });
            })
            .catch((error: any) => {
                logger.trackError(error);
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof (statusMessage) === "string")
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
            });
            
        addTask(fetchTask);
    },

    changeOfficeLocation: (ids: number[], locationId:number, contactPersonId:number, urlPath: string, callback: (status:string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(urlPath, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, *',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify({
                documents: ids,
                locationId: locationId,
                contactPersonId: contactPersonId
            })
        })
            .then(handleResponse)
            .then((response: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ChangeOfficeLocationConstants.ChangeOfficeLocationSuccess,
                    statusType: StatusType.Success
                })
               callback && callback("success");
            })
            .catch((error: any) => {
                callback && callback("error");
                const message = validateError(error);
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ChangeOfficeLocationConstants.ChangeOfficeLocationError,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                logger.trackError(`changeOfficeLocation failed for the request having parameters ${JSON.stringify({documents:ids,location:locationId})} with error ${message}`)
            });
            
        addTask(fetchTask);
    },
}

export const officeLocationReducer: Reducer<IOfficeLocationState> = (state = officeLocationUnloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case actionTypes.REQUEST_OFFICE_LOCATION_DROPDOWN:
            return {
                locationDropdown: [...state.locationDropdown == undefined ? [] : state.locationDropdown],
                loading: true
            } as IOfficeLocationState;
        case actionTypes.RECEIVE_OFFICE_LOCATION_DROPDOWN:
            return {
                locationDropdown: action.locationDropdown,
                loading: false
            } as IOfficeLocationState;
    }
    return state;
};

export const requestLocationUsers = (locationId: number, callback?:(isError: boolean, data?: IDropdown[]) => void) => {
    const fetchTask = fetch(`${API_BASE_URL}/api/UserManagement/get-location-users/${locationId}`, { 
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json, */*',
            'Content-Type': 'application/json',
        }
     })
    .then(handleResponse)
    .then((data: IDropdown[]) => {
        if(callback) callback(false, data);
    })
    .catch((error: any) => {
        if(callback) callback(true);
        logger.trackError(`requestLocationUsers failed with error ${validateError(error)}`);
     })
    addTask(fetchTask);
}

export const userOfficeLocationReducer: Reducer<IUserOfficeLocationState> = (state = userOfficeLocationUnloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case actionTypes.REQUEST_USER_OFFICE_LOCATION:
            return {
                userLocation: [...state.userLocation == undefined ? [] : state.userLocation],
            } as IUserOfficeLocationState;
        case actionTypes.RECEIVE_USER_OFFICE_LOCATION:
            return {
                userLocation: action.userLocation,
            } as IUserOfficeLocationState;
    }
    return state;
};