import * as React from 'react';
import { ToastContainer,toast } from 'react-toastify';
import { CheckCircle, CloseXButton, WarningIcon } from '../svg/CustomSvgIcons';

export enum NotifierTypes {
    Error,
    Success,
    Warning,
    Info
}

export class VenusNotifier extends React.Component<any, any> {
    
    static Error = (message: string, title: any) =>
                        message && toast.error(<VenusNotifierMessage 
                                                message={message} 
                                                notificationType={NotifierTypes.Error} /> , { 
                            position: toast.POSITION.TOP_RIGHT, 
                            className:'toastify-body toastify-error', 
                            closeButton:<span className='toastify-icon toastify-close'><CloseXButton fill='#7D7676' /></span>,
                        });
    
    static Warning = (message: string, title: any) =>
                        message && toast.error(<VenusNotifierMessage 
                                            message={message} 
                                            notificationType={NotifierTypes.Warning} />, { 
                        position: toast.POSITION.TOP_RIGHT, 
                        className:'toastify-body toastify-error', 
                        closeButton:<span className='toastify-icon toastify-close'><CloseXButton fill='#7D7676' /></span>,
                    });
    

    static Success = (message: string, title: any) =>
                        message && toast.success(<VenusNotifierMessage 
                                                    message={message} 
                                                    notificationType={NotifierTypes.Success} />, { 
                            position: toast.POSITION.TOP_RIGHT, 
                            className:'toastify-body toastify-success', 
                            closeButton:<span className='toastify-icon toastify-close'><CloseXButton fill='#7D7676' /></span>
                        });
    

    static Info = (message: string, title: any) =>
                    message && toast.info(<VenusNotifierMessage 
                                            message={message} 
                                            notificationType={NotifierTypes.Info} />, { 
                        position: toast.POSITION.TOP_RIGHT, 
                        className:'toastify-body toastify-info', 
                        closeButton:<span className='toastify-icon toastify-close'><CloseXButton fill='#7D7676' /></span>
                    });
    
}

export class Toaster extends React.Component<any, any> {
    
    render() {
        return (
            <div className="font14">
                <ToastContainer
                    className='toastify-container'
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnHover
                />
            </div>
        );
    }
}

export interface IVenusNotifierMessageProps {
    notificationType:NotifierTypes;
    message: string;
}

export const VenusNotifierMessage = (props:IVenusNotifierMessageProps) => {

    const getNotificationIcon = (notificationType: NotifierTypes) =>{
        switch(notificationType){
            case NotifierTypes.Error:
            case NotifierTypes.Warning:    //As part of US:128736 Making Warning Toast Similar to Error, If required will be reverting the changes to original. 
                return <WarningIcon fill='#B8433C' />;
            case NotifierTypes.Success:
                return <CheckCircle fill='#1F8747' />;
            case NotifierTypes.Info:
                return <CheckCircle fill='#0973BA' />;
            default:
                return <WarningIcon fill='#B8433C' />;
        }
    }


    return (<div style={{display:'inline-flex'}}>
                <div className='toastify-icon toastify-icon-pad'>{getNotificationIcon(props.notificationType)}</div>
                <div className='toastify-text'>{props.message}</div>
            </div>)
}