import * as React from 'react';
export interface SvgImageProps {
    height: number;
    width: number;
    style?: React.CSSProperties;
    className?: string;
};

export const LoginHistory: React.FC<SvgImageProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} style={props.style} className={props.className} viewBox="0 0 300 300">
        <path fill="#FFFFFF" d="M132.5,160c44.111,0,80-35.888,80-80s-35.889-80-80-80c-44.112,0-80,35.888-80,80S88.388,160,132.5,160z" />
        <path fill="#4D4D4D" d="M132.5,150c38.664,0,70-31.336,70-70s-31.336-70-70-70s-70,31.336-70,70S93.836,150,132.5,150z" />
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="480.5654" y1="-263.7676" x2="481.3447" y2="-263.7676" gradientTransform="matrix(1 0 0 -1 -256 -46)">
            <stop offset="0" style={{ stopColor: "#F7C001" }} />
            <stop offset="1" style={{ stopColor: "#E77A17" }} />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M224.955,217.378c-0.215,0-0.391,0.175-0.391,0.39s0.176,0.39,0.391,0.39s0.391-0.175,0.391-0.39 S225.17,217.378,224.955,217.378L224.955,217.378z" />
        <path fill="#FFFFFF" d="M36.251,300c-14.598,0-27.707-8.689-33.397-22.139C0.96,273.385,0,268.637,0,263.749V241 c0-46.042,37.458-83.5,83.5-83.5h11.271l2.247,1.006c11.319,5.139,23.256,7.744,35.482,7.744c12.226,0,24.163-2.605,35.479-7.743 l2.207-0.999l2.18-0.008h9.134c46.042,0,83.5,37.458,83.5,83.5v22.749c0,4.89-0.96,9.638-2.854,14.113 C256.456,291.311,243.348,300,228.75,300H36.251z" />
        <path fill="#808080" d="M181.5,167.5h-9.133h-0.001c-0.084,0.038-0.169,0.074-0.253,0.112c-12.129,5.507-25.493,8.638-39.613,8.638 s-27.485-3.131-39.613-8.638c-0.084-0.038-0.169-0.074-0.253-0.112h-0.001H83.5C42.922,167.5,10,200.422,10,241v22.749 c0,3.623,0.735,7.075,2.063,10.216C16.05,283.387,25.381,290,36.25,290h0.001h192.498h0.001c10.869,0,20.2-6.613,24.187-16.035 c1.329-3.141,2.063-6.593,2.063-10.216V241C255,200.422,222.078,167.5,181.5,167.5z" />
        <path fill="#FFFFFF" d="M232.183,284.391c-15.447,0-30.563-5.334-42.565-15.019c-3.432-2.771-5.534-6.886-5.769-11.292 c-0.234-4.414,1.424-8.738,4.549-11.864l2.626-2.626c2.96-2.958,6.891-4.586,11.069-4.586c3.494,0,6.923,1.193,9.653,3.361 c5.858,4.648,12.925,7.105,20.437,7.105c4.496,0,8.785-0.907,12.692-2.547c-0.485,0.045-0.977,0.067-1.472,0.067l0,0 c-3.448,0-6.847-1.167-9.571-3.285l-19.109-14.863v-15.598c-0.889,0.18-1.804,0.272-2.736,0.272h-33.891 c-7.571,0-13.73-6.159-13.73-13.73v-33.891c0-7.575,6.172-13.738,13.757-13.738c3.645,0,7.083,1.43,9.681,4.027l5.096,5.096 c11.41-8.131,25.052-12.525,39.284-12.525c37.326,0,67.749,30.365,67.817,67.689c0.033,18.081-7.013,35.121-19.841,47.982 c-12.827,12.859-29.848,19.949-47.927,19.963H232.183z M255.271,216.142c3.287,2.556,5.384,6.241,5.9,10.375 c0.285,2.285,0.07,4.555-0.611,6.684c2.871-4.882,4.519-10.565,4.519-16.627c0-11.94-6.395-22.416-15.939-28.182 c0.328,1.258,0.503,2.577,0.503,3.936v19.437L255.271,216.142z" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="174.3652" y1="216.5732" x2="290" y2="216.5732">
            <stop offset="0" style={{ stopColor: "#F7C001" }} />
            <stop offset="1" style={{ stopColor: "#E77A17" }} />
        </linearGradient>
        <path fill="url(#SVGID_2_)" stroke="#FBB03B" strokeWidth="3" d="M290,216.464c0.059,31.855-25.92,57.903-57.775,57.927 c-13.759,0.01-26.396-4.786-36.327-12.801c-2.583-2.085-2.776-5.955-0.429-8.303l2.626-2.626c2.008-2.007,5.212-2.228,7.436-0.463 c7.316,5.806,16.576,9.272,26.652,9.272c23.711,0,42.896-19.189,42.896-42.897c0-23.711-19.189-42.897-42.896-42.897 c-11.381,0-21.717,4.424-29.392,11.641l11.833,11.833c2.35,2.351,0.687,6.368-2.638,6.368h-33.891c-2.062,0-3.73-1.669-3.73-3.73 v-33.891c0-3.324,4.018-4.988,6.368-2.639l11.51,11.511c10.38-9.92,24.447-16.013,39.939-16.013 C264.078,158.756,289.941,184.582,290,216.464z M247.823,234.831l2.29-2.944c1.897-2.439,1.458-5.955-0.981-7.852l-9.489-7.381 v-24.327c0-3.091-2.505-5.595-5.595-5.595h-3.73c-3.09,0-5.595,2.504-5.595,5.595v31.625l15.249,11.86 C242.41,237.709,245.926,237.271,247.823,234.831z" />
    </svg>;
}