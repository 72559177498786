import * as React from 'react';
import { WithLogout, WithLogoutProps } from 'src/oidcClient/withLogout';
import { Button } from 'react-bootstrap';

interface IUnauthorizedProps {
    message: string;
    icon: JSX.Element;
    isUserLogoutEnable ?:boolean
}

const Unauthorized :React.FC <IUnauthorizedProps & WithLogoutProps>=(props) => {

    const onLogout=(e: React.SyntheticEvent<EventTarget>)=> {
        e && e.preventDefault();
        props.onLogout();
    }

        return <div className='featureDisabled-body'>
                <div>
                    {props.icon}
                </div>
                <div>
                    <h2>Access Denied!</h2>
                    <div className='featureDisabled-body-text' >{props.message}</div>
                    <br />
                    {props.isUserLogoutEnable ? <div className='featureDisabled-btn'><Button onClick={onLogout} >Sign in as Different User</Button></div> : null}
                </div>
        </div>
}

export default WithLogout(Unauthorized);