export enum ClientInstructionType {
    None = 0,
    ReminderInstruction = 1,
    DownloadInstruction = 2
}

export interface IClientInstrucionsDictionary {
    [index: number]: ClientInstructionState;
}

export interface ClientInstructionState {
    InstructionDetails: IInstruction;
}

export interface IInstruction {
    id: number;
    name: string;
    body: string;
    instructionType: ClientInstructionType;
}

export const initialInstruction = {
    id: 0,
    name: '',
    body: '',
    instructionType: ClientInstructionType.None
}

export enum ClientInstructionsActionTypes {
    REQUEST_SIGNATURE_FLOW_CLIENT_INSTRUCTIONS = 'REQUEST_SIGNATURE_FLOW_CLIENT_INSTRUCTIONS',
    RECEIVE_SIGNATURE_FLOW_CLIENT_INSTRUCTIONS = 'RECEIVE_SIGNATURE_FLOW_CLIENT_INSTRUCTIONS',
    RECEIVE_SIGNATURE_FLOW_CLIENT_INSTRUCTION = 'RECEIVE_SIGNATURE_FLOW_CLIENT_INSTRUCTION',
    DELETE_SIGNATURE_FLOW_CLIENT_INSTRUCTION = 'DELETE_SIGNATURE_FLOW_CLIENT_INSTRUCTION',
    RESET_SIGNATURE_FLOW_CLIENT_INSTRUCTIONS_DATA = 'RESET_SIGNATURE_FLOW_CLIENT_INSTRUCTIONS_DATA'
}

export interface RequestSignatureFlowClientInstructions {
    type: ClientInstructionsActionTypes.REQUEST_SIGNATURE_FLOW_CLIENT_INSTRUCTIONS;
}

export interface ReceiveSignatureClientInstructions {
    type: ClientInstructionsActionTypes.RECEIVE_SIGNATURE_FLOW_CLIENT_INSTRUCTIONS;
    clientInstructions: IInstruction[];
}

export interface ReceiveSignatureFlowClientInstruction {
    type: ClientInstructionsActionTypes.RECEIVE_SIGNATURE_FLOW_CLIENT_INSTRUCTION;
    instruction: IInstruction;
    instructionId: number;
}

export interface DeleteSignatureFlowClientInstruction {
    type: ClientInstructionsActionTypes.DELETE_SIGNATURE_FLOW_CLIENT_INSTRUCTION;
    instruction: IInstruction;
}

export interface ResetSignatureFlowClientInstructionsData {
    type: ClientInstructionsActionTypes.RESET_SIGNATURE_FLOW_CLIENT_INSTRUCTIONS_DATA;
}

