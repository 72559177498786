import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { PencilIcon, PlusLg, TrashIcon } from '../../svg/CustomSvgIcons';
import * as Constants from '../../helper/Constants';
import {IInstruction, IClientInstrucionsDictionary, initialInstruction, ClientInstructionType} from "../../../store/settings/ClientInstructions/ClientInstructionsActionTypes";
import * as SignatureFlowSettingsStore from "../../../store/SignatureFlowSettingsStore";
import { ClientInstructionsPopUp } from './ClientInstructionsPopup';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { ISignatureFlowSettings } from '../../../models/SignatureFlowSettings';
import * as bootbox from 'bootbox';

interface IDownloadEmailInstructionsProps {
    deleteInstructionDetail: (instruction : IInstruction) => void;
    updateInstructionDetail: (instruction : IInstruction) => void; 
    saveInstructionDetail: (instruction : IInstruction, signatureFlowSettings : ISignatureFlowSettings, isSetDefault : boolean) => void;
    defaultInstructionId : number;
    instructionsList: IClientInstrucionsDictionary;
    signatureFlowSettingsData: SignatureFlowSettingsStore.ISignatureFlowSettingsData,
    updateCompanySettings: (signatureFlowSettings: ISignatureFlowSettings) => void;
}

export const DownloadEmailInstructions:React.FC <IDownloadEmailInstructionsProps> = (props) =>{
    const [title,setTitle] = useState('');
    const [instructionsList,setInstructionsList] = useState<IClientInstrucionsDictionary>([]);
    const [buttonText,setButtonText] = useState('');
    const [selectedInstructionId,setSelectedInstructionId] = useState(0);
    const [isUpdateInstruction,setIsUpdateInstruction] = useState(false);
    const [showClientInstructionPopUp,setShowClientInstructionPopUp] = useState(false);

    const currentInstruction = instructionsList && instructionsList[selectedInstructionId]
    ? instructionsList[selectedInstructionId].InstructionDetails
    : initialInstruction;

    useEffect(() => {
        setInstructionsList(props.instructionsList);
        setSelectedInstructionId(props.defaultInstructionId)
    }, [props.instructionsList, props.defaultInstructionId])

    const onEditButtonClick = () => {
        setShowClientInstructionPopUp(true);
        setIsUpdateInstruction(true);
        setButtonText('Save');
        setTitle('Edit Download Instruction');
    }

    const onListItemClick = (id: number) => {
        setSelectedInstructionId(id);
    }

    const onAddButtonClick = () => {
        setShowClientInstructionPopUp(true);
        setIsUpdateInstruction(false);
        setButtonText('Add');
        setTitle('Add Download Instruction');
    }

    const addDownloadInstruction = (instruction: IInstruction, isSetDefault: boolean) => {
        let signatureFlowSettings = props.signatureFlowSettingsData.signatureFlowSettings;
        if (signatureFlowSettings) {
            props.saveInstructionDetail(instruction, signatureFlowSettings, isSetDefault);
            setShowClientInstructionPopUp(false);
            setIsUpdateInstruction(false);
        } else {
            VenusNotifier.Error(Constants.ClientInstructionsConstants.SaveInstructionError, null);
        }
    }

    const onDeleteButtonClick = () => {
        if (selectedInstructionId == props.defaultInstructionId) {
            bootbox.alert({
                title: 'Invalid Action',
                message: Constants.ClientInstructionsConstants.DeleteInstructionWarning,
                size: 'small',
                buttons: {
                    ok: {
                        label: 'OK',
                        className: 'btn-info modal-footer-button-save'
                    }
                }
            }).find(".modal-dialog")
            .addClass("modal-dialog-centered");
        }
        else {
            bootbox.confirm({
                title: 'Delete Instruction',
                message: 'Are you sure you want to delete this Instruction?',
                buttons: {
                    cancel: {
                        label: 'Cancel',
                        className: 'btn-white modal-footer-button-cancel'
                    },
                    confirm: {
                        label: 'Delete',
                        className: 'btn-info modal-footer-button-save'
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        props.deleteInstructionDetail(instructionsList[selectedInstructionId].InstructionDetails);
                        setSelectedInstructionId(props.defaultInstructionId);
                    }
                }
            }).find(".modal-dialog")
            .addClass("modal-dialog-centered");
        }
    }

    const editDownloadInstruction = (instruction: IInstruction, isSetDefault: boolean) => {
        let signatureFlowSettings = props.signatureFlowSettingsData.signatureFlowSettings;
        const Keyvalue= Object.keys(ClientInstructionType).find(key => ClientInstructionType[key] === instruction.instructionType) ;
        if (signatureFlowSettings) {
            if (instruction.id == signatureFlowSettings.clientInstructionSettings['default'+ Keyvalue] && !isSetDefault) {
                VenusNotifier.Warning(Constants.ClientInstructionsConstants.SelectDefaultInstructioneWarning, null);
                return false;
            }
            else if (isSetDefault && instruction.id != signatureFlowSettings.clientInstructionSettings['default'+ Keyvalue]) {                
                signatureFlowSettings.clientInstructionSettings['default'+ Keyvalue] = instruction.id;
                props.updateCompanySettings(signatureFlowSettings);
            }

            props.updateInstructionDetail(instruction);
            setShowClientInstructionPopUp(false);            
            setIsUpdateInstruction(false);
        }
        else {
            VenusNotifier.Error(Constants.ClientInstructionsConstants.UpdateInstructionError, null);
        }
    }

    const onCancelButtonClick = () => {
        setShowClientInstructionPopUp(false);
        setIsUpdateInstruction(false);
    }

    const listHeightStyle = Object.keys(instructionsList).length > 4 ? 'calc(37px * 4 - 2px)' : 'calc(37px * 4 - 1px)';

    return <div>                
                <div className="row" style={{ marginLeft: 0, marginBottom:'3px' }}>
                    <div className="col-sm-10 padL00 float-left">
                        <h4 className='sigflow-settings-heading'>Download E-Signed Forms</h4>
                    </div>
                </div>
                <div className="row" style={{ marginLeft: 0, marginBottom:'20px' }}>
                    <div className="col-sm-10 padL00 float-left">
                        <span>Once all parties have e-signed all documents</span>
                    </div>
                </div>
                        <div className="row" style={{ position: "relative", marginLeft:'0px' }}>
                        
                            <div className='padR70'>
                                    <div className="dispFR settings-list-container custom-scroll-bar" 
                                        style={{ marginTop: 10, height: listHeightStyle, width: 367, marginLeft:'0px', overflowY: 'auto', }}>
                                        <div>
                                            <ul className="list-group">
                                                {
                                                    Object.keys(instructionsList).map((key, index) => {
                                                        const liClassName = "custom-list-group-item-singleline padR20 padT07-i "+ 
                                                        (instructionsList[parseInt(key)].InstructionDetails.id == selectedInstructionId ? "custom-active " : "") +
                                                        (index === 0 ? 'bord-t-0-i ' : '') +
                                                        ((Object.keys(instructionsList).length < 4 && index === Object.keys(instructionsList).length - 1) ? "bord-b-1 " : "");
                                                        return (<li
                                                            key={instructionsList[parseInt(key)].InstructionDetails.id}
                                                            className={liClassName}
                                                            title={instructionsList[parseInt(key)].InstructionDetails.name}
                                                            onClick={() => onListItemClick(parseInt(key))}
                                                            >
                                                            <div className="width100 row " style={{ margin: 0 }}>
                                                                <div className={"overflowHidden font16 ellipsis " + (instructionsList[parseInt(key)].InstructionDetails.id == props.defaultInstructionId ? "col-md-10 nopadding " : "")}>
                                                                    {instructionsList[parseInt(key)].InstructionDetails.name}
                                                                </div>
                                                                {
                                                                    instructionsList[parseInt(key)].InstructionDetails.id == props.defaultInstructionId ?
                                                                    <div className="col-md-2 pad00" style={{marginTop:'2px'}}>
                                                                            <span className={"default-indicator-batch"} style={{  marginBottom: 3, float: 'initial' }}>Default</span>
                                                                        </div>
                                                                        : <span></span>
                                                                    }
                                                            </div>
                                                        </li>)
                                                })
                                            }
                                            </ul>
                                        </div>
                                    </div>                
                                    <Button
                                        data-test-auto="f2c96cf4-614b-49b5-9387-08e7acdb4467"
                                        variant='default'
                                        className='btn-white settings-add-btn marL0-i'
                                        onClick={onAddButtonClick}
                                        title="Add Instruction">
                                    <PlusLg  marginBottom={'4px'}/><span style={{paddingLeft:'5px'}}>Add</span>
                                    </Button>
                                </div>
                                <div>
                                    <div className="text-message dispFR " style={{ marginTop: '9px', padding:'0px', border:'unset'}}>
                                        <div className='custom-scroll-bar settings-list-container' style={{ height: "100%", overflowY: "scroll" }}>
                                            <div style={{padding:'14px 33px 15px 11px'}}>
                                                <div className="form-group">
                                                    <label>
                                                        Subject
                                                    </label>
                                                    <div className="form-control ellipsis" title="Subject" style={{ backgroundColor: '#f1f1f1' }}>Important: Your Succesfully Signed Documents</div>
                                                </div>
                                                <div className="form-group">
                                                    <label>
                                                        Name
                                                    </label>
                                                    <div className="form-control ellipsis" title={currentInstruction.name} style={{ backgroundColor: '#f1f1f1' }}>{currentInstruction.name}</div>
                                                </div>                                    
                                                <div className="form-group">
                                                    <label >Body</label>
                                                    <div>
                                                        <textarea
                                                            className="form-control custom-scroll-bar" 
                                                            value={currentInstruction.body}
                                                            style={{ overflowY: 'auto', height: 140, backgroundColor: '#f1f1f1' }}
                                                            />
                                                    </div>
                                                </div>                                    
                                            </div>
                                        </div>
                                    </div>
                                        <Button
                                            data-test-auto="5e31060c-fbca-4d89-b97a-24242426b3e6"
                                            variant='default'
                                            className='btn-white settings-add-btn'
                                            title="Edit Instruction"
                                            onClick={onEditButtonClick}>
                                            <PencilIcon  marginBottom={'4px'}/><span style={{paddingLeft:'5px', paddingTop:'1px'}}>Edit</span>
                                        </Button>
                                        <Button
                                            data-test-auto="d322d2f8-9820-4746-ad8d-5fe9047ec4a5"
                                            variant='default'
                                            className='btn-white settings-add-btn'
                                            title="Delete Instruction"
                                            style={{ marginLeft: 11 }}
                                            onClick={onDeleteButtonClick}
                                            >
                                            <TrashIcon marginBottom={'4px'}/><span style={{paddingLeft:'5px', paddingTop:'1px'}}>Delete</span>
                                        </Button>
                                    
                                </div>

                                <ClientInstructionsPopUp
                                    showState={showClientInstructionPopUp}
                                    onHide={onCancelButtonClick}
                                    submitButtonClick={isUpdateInstruction ? editDownloadInstruction : addDownloadInstruction}
                                    buttonText={buttonText}
                                    selectedInstruction={isUpdateInstruction ? currentInstruction : initialInstruction}
                                    title={title}
                                    defaultInstructionId={props.defaultInstructionId}
                                    clientInstructions={instructionsList}
                                    instructionType={ClientInstructionType.DownloadInstruction}
                                    />
                    </div>
        </div> 
}