import * as React from 'react';
import { RouteComponentProps } from 'react-router';

interface IRadioButtonComponentProps {
    checked?: boolean;
    onChange?: (event: any) => void;
    dataTestAuto?: string;
    value?: string | string[] | number;
    disabled?: boolean;
    text: string;
    name?: string;
    onClick?: (event: any) => void;
    id: string;
    style?: React.CSSProperties;
    className?: string;
}

export class RadioButtonComponent extends React.Component<IRadioButtonComponentProps, {}>{
    public render() {
        return (
            <div className={'display-checkBox ' + this.props.className || ""} style={this.props.style}>
                <input
                    className="magic-radio"
                    type="radio"
                    id={this.props.id}
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    disabled={this.props.disabled}
                    name={this.props.name}
                    data-test-auto={this.props.dataTestAuto}
                    onClick={this.props.onClick}
                ></input>
                <label htmlFor={this.props.id} className={this.props.checked ? "checked" : ""}>
                    {this.props.text}
                </label>
                {this.props.children}
            </div>
        )
    }
}