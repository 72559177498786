import { ISignatureFlowReportDocument, ISignatureFlowReportFilter } from '../models/SignatureFlowReportState';
import { IRestorePopupState } from './SignatureFlowReportTableState';

export interface ISignatureFlowReportStatusList {
    value: number;
    name: string;
}

export interface ISignatureFlowReportState {
    signatureFlowReportTableModel: ISignatureFlowReportTableModel;
    query: string;
    totalRowCount: number;
    isLoading: boolean;
}

export interface ISignatureFlowReportTableModel {
    count: number,
    documents: ISignatureFlowReportDocument[],
}
export interface ISignatureFlowRecycleReportPageState {
    page: number;
    pageSize: number;
    selectedRows: number[];
    filter: ISignatureFlowReportFilter;
    searchString: string;
    sortName: string;
    sortOrder: string;
    saveFilterShow: boolean,
    filterSenderName: string;
    filterDocumentName: string,
    filterDocumentType: string;
    filterSigners: string;
    filterRecycledOn: Date | undefined;
    filterSignatureStatus: string;
    filterDownloadStatus: string;
    filterTitle: string;
    filterLoggedUser: string;
    filterTaxYear: any;
    filterRecipientName: string;
    filterClientId: string;
    filterOfficeLocation: any,
    showLoader: boolean;
    isBulkSelectionEnabled: boolean;
    deSelectedRows: number[];
    showBulkSelectionMessage: boolean;

    //pop-ups
    signerDetailsState: IPopupStateSingle;
    documentDetailsState: IPopupStateSingle;
    recipientDetailsState: IPopupStateSingle;
    cancelDocumentState: IPopupStateSingle;
    restoreDocumentState: IRestorePopupState;
    totalRecordsCount: number;
}

export interface ISignatureFlowReportDictionary {
    [index: string]: ISignatureFlowReportState;
}

export interface IPopupStateSingle extends IPopupState {
    model: ISignatureFlowReportDocument | undefined,
    saveCheckId: number | undefined
}
export interface IPopupStateMultiple extends IPopupState {
    selected: any[] | undefined,
    saveCheckIds: number[] | undefined
    remove: boolean
}

export interface IPopupState {
    show: boolean;
}


export interface ISignatureStatusList {
    value: number;
    name: string;
}

export const initialRestoreDocumentState: IRestorePopupState = {
    selected: undefined,
    show: false,
    saveCheckIds: undefined,
    remove: false,
    showReminderEnabledWarning: false
}

