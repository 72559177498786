import React from 'react';
import * as Bootstrap from 'react-bootstrap';
import { DeleteHeaderIcon, PersonCheck, SetAccessHeaderIcon } from '../../svg/CustomSvgIcons';
import { NO_INDEX } from '../../helper/HelperFunctions';
import { ResourceIdConstants } from '../../helper/RBACConstants';

interface HeaderProps {
    screenId: string;
    pageTitle: string;
    onDeleteDocuments: (rowIndex: number) => void,
    onSetAccessOpen: (rowIndex: number, headerTextShow: boolean) => void;
    onAssignToOpen: (rowIndex: number) => void,
    selectedDocumentCount: number;
}

export const SignatureDraftReportsHeader :React.FC <HeaderProps> = (props) => {

    const { screenId } =  props;

    return (<div className='report-header-width'>
                 <div className="row" style={{ marginBottom: 23 }}>
                    <div className="col-sm-8">
                        <h3><strong>{props.pageTitle}</strong></h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Bootstrap.Button
                            data-resource-id={`${screenId}${ResourceIdConstants.SetAccess}`}
                            variant='default'
                            className='btn btn-white header-action'
                            data-test-auto="183113bb-78a7-4381-a8b8-62030f15084f"
                            disabled={props.selectedDocumentCount === 0}
                            onClick={() => props.onSetAccessOpen(NO_INDEX, true)}>
                                <SetAccessHeaderIcon /><span>Set Access</span>
                        </Bootstrap.Button>

                        <Bootstrap.Button
                            data-resource-id={`${screenId}${ResourceIdConstants.AssignTo}`}
                            variant='default'
                            className="btn btn-white marL10 header-action"
                            data-test-auto="316ba52e-efd9-40b9-891d-1beb33365b67"
                            disabled={props.selectedDocumentCount === 0}
                            onClick={() => props.onAssignToOpen(NO_INDEX)}>
                                <PersonCheck color='#05386B' marginBottom='4px'/><span>Assign to</span>
                        </Bootstrap.Button>

                        <Bootstrap.Button
                            data-resource-id={`${screenId}${ResourceIdConstants.Delete}`}
                            variant='default'
                            className="btn btn-white marL10 header-action"
                            data-test-auto="CA785E4E-F548-4BED-98A6-17C08105967C"
                            disabled={props.selectedDocumentCount === 0}
                            onClick={() => props.onDeleteDocuments(NO_INDEX)}>
                                <DeleteHeaderIcon /><span>Delete</span>
                        </Bootstrap.Button>
                    </div>
                </div>
            </div>)

}