import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import TinyMCEComponent from '../../common/TinyMCE';
import { ISignatureFlowReportDocument } from '../../../models/SignatureFlowReportState';

interface DocumentNoteProps {
    showState: boolean;
    onHide: (event: any) => void;
    changeStateTinymceBody: (event: any) => void;
    signatureReport: ISignatureFlowReportDocument;
    updateButtonClick: () => void;
    deleteDocumentNote: () => void;
    buttonText?: string;
}

export class SignatureFlowReportDocumentNoteModal extends React.Component<DocumentNoteProps, {}>{
    public render() {
        let messageBody = (this.props.signatureReport && this.props.signatureReport.documentNote) == undefined ? "" : this.props.signatureReport.documentNote
        return (
            <Modal centered={true} show={this.props.showState} onHide={this.props.onHide} className='report-document-note-modal' backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Document Note
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="padLR-2">
                        <TinyMCEComponent
                            changeStateTinymceBody={this.props.changeStateTinymceBody}
                            messageBody={messageBody}
                            readOnly={0}
                        ></TinyMCEComponent>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className='btn-white modal-footer-button-cancel'
                        variant='default'
                        onClick={this.props.onHide} >
                        Cancel
                    </Button>
                    {messageBody &&
                            <Button
                                variant='danger'
                                onClick={this.props.deleteDocumentNote} >
                                Delete
                        </Button>
                        }
                    {this.props.buttonText == "Save" ?
                        <Button
                            variant='info'
                            className=' modal-footer-button-save'
                            onClick={this.props.updateButtonClick}
                        >
                            {this.props.buttonText}
                        </Button>
                        :
                        <Button
                            variant='info'
                            className=' modal-footer-button-save'
                            onClick={this.props.updateButtonClick}
                        >
                            {this.props.buttonText}
                        </Button>
                    }
                   
                  
                </Modal.Footer>
            </Modal>
        )
    }
}