import * as React from 'react';
import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Button } from 'react-bootstrap';
import { IMessage, initialMessage } from '../../../models/SignatureFlowSettings';
import * as SignatureFlowSettingsStore from '../../../store/SignatureFlowSettingsStore';
import * as SignatureFlowSavedMessageStore from '../../../store/settings/SavedMessage/SavedMessageStore';
import { SavedMessagePopUp } from './SavedMessagePopUp';
import { VenusNotifier } from '../../helper/VenusNotifier';
import * as Constants from '../../helper/Constants';
import * as bootbox from 'bootbox';
import { GreaterThanSvg, PencilIcon, PlusLg, TrashIcon } from '../../svg/CustomSvgIcons';
import { ResourceIdConstants, ScreenIds } from '../../helper/RBACConstants';

type ISavedMessageProps =
    {
        signatureFlowSettingsData: SignatureFlowSettingsStore.ISignatureFlowSettingsData,
        SavedMessageData: SignatureFlowSavedMessageStore.ISavedMessageData,
    }
    & typeof SignatureFlowSettingsStore.actionCreators
    & typeof SignatureFlowSavedMessageStore.actionCreators
    & RouteComponentProps<{}>;

export const SavedMessage:React.FC <ISavedMessageProps> = (props) => {
    const [title,setTitle] = useState('');
    const [messages,setmessages] = useState<SignatureFlowSettingsStore.ISavedMessageDictionary>([]);
    const [buttonText,setButtonText] = useState('');
    const [selectedMessage,setSelectedMessage] = useState(0);
    const [isUpdateMessage,setIsUpdateMessage] = useState(false);
    const [showSavedMessagePopUp,setShowSavedMessagePopUp] = useState(false);


    const messageList = messages ? messages : [];
    const currentMessage = messages && messages[selectedMessage]
        ? messages[selectedMessage].messageDetails
        : initialMessage;
    const defaultMessage = props.signatureFlowSettingsData.signatureFlowSettings ? props.signatureFlowSettingsData.signatureFlowSettings.messageSettings.welcomeMessage : 0;
    const listHeightStyle = Object.keys(messageList).length > 4 ? 'calc(37px * 4 - 2px)' : 'calc(37px * 4 - 1px)';


    React.useEffect(() => {
        props.requestCompanySignatureSettings();
        props.requestSavedMessages(`${ScreenIds.Settings}${ResourceIdConstants.GeneralSettings}`);
        
        if (props.signatureFlowSettingsData.signatureFlowSettings) {
            setSelectedMessage(props.signatureFlowSettingsData.signatureFlowSettings.messageSettings.welcomeMessage);           
        }
    },[])

    useEffect(() => {
        setmessages(props.SavedMessageData.savedMessages ? props.SavedMessageData.savedMessages : []);
        setSelectedMessage(props.signatureFlowSettingsData.signatureFlowSettings ? props.signatureFlowSettingsData.signatureFlowSettings.messageSettings.welcomeMessage : 0)
    },[props.signatureFlowSettingsData,props.SavedMessageData])

    const onAddButtonClick = () => {
        setShowSavedMessagePopUp(true);
        setIsUpdateMessage(false);
        setButtonText('Add');
        setTitle('Add Saved Message');
    }

    const onEditButtonClick = () => {
        setShowSavedMessagePopUp(true);
        setIsUpdateMessage(true);
        setButtonText('Save');
        setTitle('Edit Saved Message');
    }

     const onDeleteButtonClick = () => {
        if (selectedMessage == props.signatureFlowSettingsData.signatureFlowSettings.messageSettings.welcomeMessage) {
            bootbox.alert({
                title: 'Invalid Action',
                message: Constants.SavedMessageConstants.DeleteMessageWarning,
                size: 'small',
                buttons: {
                    ok: {
                        label: 'OK',
                        className: 'btn-info modal-footer-button-save'
                    }
                }
            }).find(".modal-dialog")
            .addClass("modal-dialog-centered");
        }
        else {
            bootbox.confirm({
                title: 'Delete Message',
                message: 'Are you sure you want to delete this message?',
                buttons: {
                    cancel: {
                        label: 'Cancel',
                        className: 'btn-white modal-footer-button-cancel'
                    },
                    confirm: {
                        label: 'Delete',
                        className: 'btn-info modal-footer-button-save'
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        props.deleteMessageDetail(messages[selectedMessage].messageDetails);
                        setSelectedMessage(props.signatureFlowSettingsData.signatureFlowSettings.messageSettings.welcomeMessage);
                    }
                }
            }).find(".modal-dialog")
            .addClass("modal-dialog-centered");
        }
    }

    const onListItemClick = (id: number) => {
        setSelectedMessage(id);
    }

    const onCancelButtonClick = () => {
        setShowSavedMessagePopUp(false);
        setIsUpdateMessage(false);
    }

    const addWelcomeMessage = (message: IMessage, isSetDefault: boolean) => {
        let signatureFlowSettings = props.signatureFlowSettingsData.signatureFlowSettings;
        if (signatureFlowSettings) {
            props.saveMessageDetail(message, signatureFlowSettings, isSetDefault);
            setShowSavedMessagePopUp(false);
            setIsUpdateMessage(false);
        } else {
            VenusNotifier.Error(Constants.SavedMessageConstants.SaveMessageError, null);
        }
    }

    const editWelcomeMessage = (message: IMessage, isSetDefault: boolean) => {
        let signatureFlowSettings = props.signatureFlowSettingsData.signatureFlowSettings;
        if (signatureFlowSettings) {
            if (message.id == signatureFlowSettings.messageSettings.welcomeMessage && !isSetDefault) {//Default message shouldnot be turned off
                VenusNotifier.Warning(Constants.SavedMessageConstants.SelectDefaultMessageWarning, null);
                return false;
            }
            else if (isSetDefault && message.id != signatureFlowSettings.messageSettings.welcomeMessage) {
                signatureFlowSettings.messageSettings.welcomeMessage = message.id;
                props.updateCompanySettings(signatureFlowSettings);
            }

            props.updateMessageDetail(message);
            setShowSavedMessagePopUp(false);
            setIsUpdateMessage(false);
        }
        else {
            VenusNotifier.Error(Constants.SavedMessageConstants.UpdateMessageError, null);
        }
    }


    return <div className="new-main-content font14 saved-message-container" data-resource-id={`${ScreenIds.Settings}${ResourceIdConstants.GeneralSettings}`}>
                <div className="row" style={{ marginLeft: 0, marginBottom:'20px' }}>
                    <span className='fontWeight700 text-primary'>Settings</span> 
                    <span className='padL08'><GreaterThanSvg/></span>
                    <span className='padL08'>Saved Messages</span>
                </div>
                <div className="row" style={{ marginLeft: 0, marginBottom:'25px' }}>
                    <div className="col-sm-10 padL00 float-left">
                        <h2 style={{fontSize: '20px'}}  className='fontWeight700'>Saved Messages</h2>
                    </div>
                </div>
                <div className="row" style={{ marginLeft: 0, marginBottom:'5px' }}>
                    <div className="col-sm-10 padL00 float-left">
                        <h4 className='sigflow-settings-heading'>Saved Messages</h4>
                    </div>
                </div>
                <div className="row" style={{ position: "relative", marginLeft:'0px' }}>
                    <div className='padR70'>
                        <div className="dispFR settings-list-container custom-scroll-bar" 
                            style={{ marginTop: 31.5, overflowY: 'auto', height: listHeightStyle, width: 367, marginLeft:'0px' }}>
                            <div>
                                <ul className="list-group">
                                    {
                                        Object.keys(messageList).map((key, index) => {
                                            const liClassName = "custom-list-group-item-singleline padR20 padT07-i "+ 
                                                                (messages[parseInt(key)].messageDetails.id == selectedMessage ? "custom-active " : "") +
                                                                (index === 0 ? 'bord-t-0-i ' : '') +
                                                                ((Object.keys(messageList).length < 4 && index === Object.keys(messageList).length - 1) ? "bord-b-1 " : "");
                                            return (<li
                                                key={messages[parseInt(key)].messageDetails.id}
                                                className={liClassName}
                                                title={messages[parseInt(key)].messageDetails.name}
                                                onClick={() => onListItemClick(parseInt(key))}
                                            >
                                                <div className="width100 row " style={{ margin: 0 }}>
                                                    <div className={"overflowHidden font16 ellipsis " + (messages[parseInt(key)].messageDetails.id == defaultMessage ? "col-md-10 nopadding " : "")}>
                                                        {messages[parseInt(key)].messageDetails.name}
                                                    </div>
                                                    {
                                                        messages[parseInt(key)].messageDetails.id == defaultMessage ?
                                                            <div className="col-md-2 pad00" style={{marginTop:'2px'}}>
                                                                <span className={"default-indicator-batch"} style={{  marginBottom: 3, float: 'initial' }}>Default</span>
                                                            </div>
                                                            : <span></span>
                                                    }
                                                </div>
                                            </li>)
                                    })
                                    }
                                </ul>
                            </div>
                        </div>                
                        <Button
                            data-test-auto="01354C39 - 6B1B-4BBD-93BF-AA0F060D441E"
                            variant='default'
                            className='btn-white settings-add-btn marL0-i'
                            onClick={onAddButtonClick}
                            title="Add Message">
                        <PlusLg  marginBottom={'4px'}/><span style={{paddingLeft:'5px'}}>Add</span>
                        </Button>
                    </div>
                    <div>
                        <span style={{fontWeight:'700'}}>Preview</span>
                        <div className="text-message dispFR " style={{ marginTop: '9px', padding:'0px', border:'unset'}}>
                            <div className='custom-scroll-bar settings-list-container' style={{ height: "100%", overflowY: "scroll" }}>
                                <div style={{padding:'14px 33px 15px 11px'}}>
                                    <div className="form-group">
                                        <label>
                                            Name
                                        </label>
                                        <div className="form-control ellipsis" title={currentMessage.name} style={{ backgroundColor: '#f1f1f1' }}>{currentMessage.name}</div>
                                    </div>
                                    <div className="form-group">
                                        <label>Subject</label>
                                        <div className="form-control ellipsis" title={currentMessage.subject} style={{ backgroundColor: '#f1f1f1' }}>{currentMessage.subject}</div>
                                    </div>
                                    <div className="form-group">
                                        <label >Body</label>
                                        <div>
                                            <textarea
                                                className="form-control custom-scroll-bar" 
                                                value={currentMessage.body}
                                                style={{ overflowY: 'auto', height: 73, backgroundColor: '#f1f1f1' }}
                                            />
                                        </div>
                                    </div>
                                    {currentMessage.isEditable ? <div className="form-group custom-control" style={{marginBottom:'0px'}}>
                                        <i className="fas fa-check" style={{ color: '#0973BA' }}></i>
                                        <label className="custom-control-label" style={{ marginLeft: 5 }} >Allow users to edit before sending</label>
                                    </div> : ""}
                                </div>
                            </div>
                        </div>
                            <Button
                                data-test-auto="C7D673C4-5E79-4185-8CEF-72E05313FD82"
                                variant='default'
                                className='btn-white settings-add-btn'
                                title="Edit Message"
                                onClick={onEditButtonClick}>
                                <PencilIcon  marginBottom={'4px'}/><span style={{paddingLeft:'5px', paddingTop:'1px'}}>Edit</span>
                            </Button>
                            <Button
                                data-test-auto="73194033-00F4-43F4-8875-14B9D36F5235"
                                variant='default'
                                className='btn-white settings-add-btn'
                                title="Delete Message"
                                style={{ marginLeft: 11 }}
                                onClick={onDeleteButtonClick}
                            >
                                <TrashIcon marginBottom={'4px'}/><span style={{paddingLeft:'5px', paddingTop:'1px'}}>Delete</span>
                            </Button>
                        
                    </div>
                </div>

                <SavedMessagePopUp
                    showState={showSavedMessagePopUp}
                    onHide={onCancelButtonClick}
                    submitButtonClick={isUpdateMessage ? editWelcomeMessage : addWelcomeMessage}
                    buttonText={buttonText}
                    selectedMessage={isUpdateMessage ? currentMessage : initialMessage}
                    title={title}
                    defaultMessageId={defaultMessage}
                    isUpdate={isUpdateMessage}
                    messages={messages}
                />
        </div>;
};