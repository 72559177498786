import { SignatureControlType } from './../../models/SignatureData';

export function getControlText(controlType: SignatureControlType): string {
    switch (controlType) {
        case SignatureControlType.Date:
            return "Date";
        case SignatureControlType.Signature:
            return "Signature";
        case SignatureControlType.Company:
            return "Company";
        case SignatureControlType.Initial:
            return "Initials";
        case SignatureControlType.Text:
            return "Text";
        case SignatureControlType.Name:
            return "Name";
        case SignatureControlType.Title:
            return "Title";
        case SignatureControlType.PrintName:
            return "Print Name"
        case SignatureControlType.Radio:
            return "Radio Button"
        default:
            return "";
    }
}

export function getControlIcon(controlType: SignatureControlType): string {
    switch (controlType) {
        case SignatureControlType.Date:
            return "fas fa-calendar-alt";
        case SignatureControlType.Signature:
            return "fas fa-signature";
        case SignatureControlType.Company:
            return "fas fa-building";
        case SignatureControlType.Initial:
            return "fas fa-font";
        case SignatureControlType.Text:
            return "fas fa-text-width";
        case SignatureControlType.Name:
            return "fas fa-user-circle";
        case SignatureControlType.Title:
            return "fas fa-address-card";
        case SignatureControlType.PrintName:
            return "fas fa-address-card";
        case SignatureControlType.CheckBox:
            return "fas fa-check-square";
        case SignatureControlType.Radio:
            return "fas fa-dot-circle";
            
        default:
            return "";
    }
}