import * as React from 'react';
import * as Bs from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, ExpandBy } from 'react-bootstrap-table';
import { OverlayLoader } from '../../../components/helper/OverlayLoader';
import { ISignatureFlowReportDocument, ClientTypes, ISignerModel, SignatureFlowReportSignatureStatus } from '../../../models/SignatureFlowReportState';
import { duplicateRecipient } from '../../helper/HelperFunctions';

let moment = require('moment');

interface ISignatureRecycledReportRecipientModalProps {
    show: boolean;
    onCancel: () => void;
    signatureReport: ISignatureFlowReportDocument;
}

export class SignatureRecycledReportRecipientModal extends React.Component<ISignatureRecycledReportRecipientModalProps, {}>{
    constructor(props: ISignatureRecycledReportRecipientModalProps) {
        super(props);
        this.state = {
        }
        this.defaultType = this.defaultType.bind(this);
    }

    defaultType(cell: any, row: any) {
        return <div title={cell} className="ellipsis">{cell}</div>;
    }

    recipientType(cell: any, row: any) {
        let signerType: any = `${ClientTypes[row.signerType]}`;;
        return <div title={signerType} >{signerType}</div>;;
    }

    public render() {
        const signedDetailsColumn = [
            {
                header: 'Email',
                key: 'signerEmail',
                isKey: false,
                dataFormat: this.defaultType,
                width: '70%',
                toolTip: true,
                className: 'table-column-header',
            },
            {
                header: 'Type',
                key: 'signerType',
                isKey: true,
                dataFormat: this.recipientType,
                width: '',
                toolTip: true,
                className: 'table-column-header',
            }
        ];



        let signers: ISignerModel[] = [];


        let signatureStatus: SignatureFlowReportSignatureStatus = SignatureFlowReportSignatureStatus.None;

        if (this.props.signatureReport && this.props.signatureReport.signerInfo) {
            signatureStatus = this.props.signatureReport.signatureStatus;

            let signerRecipients = this.props.signatureReport.signerInfo
                .filter(x => x.signerType == ClientTypes.Signer)
                .sort((a: any, b: any) => {
                    if (a.clientId > b.clientId) return 1;
                    else return -1;
                });

            let ccRecipients = this.props.signatureReport.signerInfo.filter(x => x.signerType == ClientTypes.CC);

            signerRecipients.map((value, index) => {
                signers.push(value);
            });

            if (ccRecipients.length > 0) {
                ccRecipients.map((value, index) => {
                    signers.push(value);
                });
            }
        }


        let modalBody = this.props.signatureReport ?
            (
                <BootstrapTable data={signers}
                containerClass='report-table-container modal-scrollable'
                tableHeaderClass='report-header-border'
                bordered={false}
                striped
                trStyle={(row:ISignerModel) => duplicateRecipient(row,signers) ? {backgroundColor: '#d1ecf1'} : {}}>
                    {signedDetailsColumn.map((value, index) => {
                        return <TableHeaderColumn key={index}
                            isKey={value.isKey}
                            dataField={value.key}
                            dataFormat={value.dataFormat}
                            width={value.width}
                            className={value.className}
                            columnTitle={value.toolTip}>
                            {value.header}
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            ) :
            (<OverlayLoader
                show={this.props.show}
                text={"Loading, please wait..."} />);

        return <Bs.Modal centered={true} className="recycle-signed-Details-modal" show={this.props.show} onHide={this.props.onCancel} backdrop="static">
            <Bs.Modal.Header closeButton>
                <Bs.Modal.Title>
                    Recipient Details
                   </Bs.Modal.Title>
            </Bs.Modal.Header>
            <Bs.Form>
                <Bs.Modal.Body>
                    {modalBody}
                </Bs.Modal.Body>
            </Bs.Form>
            <Bs.Modal.Footer>
                <Bs.Button
                    variant="default"
                    className='btn-white modal-footer-button-cancel'
                    onClick={this.props.onCancel}>
                   Cancel
                </Bs.Button>
            </Bs.Modal.Footer>
        </Bs.Modal>
    }
}

export default SignatureRecycledReportRecipientModal;