import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, FormGroup, FormLabel, FormControl, Col, Alert } from 'react-bootstrap';
import { IUserBaseModel, IUserModel } from '../../../../Core/ViewModels/User/UserViewModel';
import * as Helper from '../../../components/helper/HelperFunctions';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { ISignatureFlowReportDocument } from '../../../models/SignatureFlowReportState';
import { CheckCircleFill, GripVertical } from '../../svg/CustomSvgIcons';
import { event } from 'jquery';

interface ISetAccessModalProps {
    showEditAccess: boolean;
    selectedReports: ISignatureFlowReportDocument[];
    count: number;
    users: IUserModel[];
    onApplyAccess(accessingUsers: number[]): void;
    onCancel: () => void;
    setAccessHeaderTextDisplay: boolean;
}

interface ISetAccessModalStates {
    allUsers: IUserModel[];
    docUsers: IUserModel[];
    leftSelect: IUserModel[];
    lastLeft: number;
    rightSelect: IUserModel[];
    lastRight: number;
    saving: boolean;
    message: string;
    alertMsg: string;
    alertIcon: string;
}

const everyOne = { userId: -1, firstName: 'Everyone' } as IUserModel;
const msg = {
    loading: 'Loading,Please Wait...',
    saving: 'Submitting the access changes...',

}
export class SignatureFlowReportSetAccessModal extends React.Component<ISetAccessModalProps, ISetAccessModalStates> {

    constructor(props: ISetAccessModalProps) {
        super(props);

        this.state = {
            allUsers: [],
            docUsers: [],
            leftSelect: [],
            lastLeft: 0,
            rightSelect: [],
            lastRight: 0,
            saving: false,
            message: '',
            alertMsg: '',
            alertIcon: 'fa-info-circle',
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.moveRight = this.moveRight.bind(this);
        this.moveLeft = this.moveLeft.bind(this);
        this.onSelectFromLeft = this.onSelectFromLeft.bind(this);
        this.onSelectFromRight = this.onSelectFromRight.bind(this);
        this.filterLeft = this.filterLeft.bind(this);
        this.onClickAvailableUsers = this.onClickAvailableUsers.bind(this);
        this.onClickUsersWithAccessRights = this.onClickUsersWithAccessRights.bind(this);
        this.DragToRight = this.DragToRight.bind(this);
        this.DragToLeft = this.DragToLeft.bind(this);
    }

    //These are Ids of current user and delegated user list component
    private currentUsersId : string  =  "current-users";
    private selectedUsersId : string  =  "selected-users";

    UNSAFE_componentWillReceiveProps(nextProps: ISetAccessModalProps) {
        this.ModifySetAccessModalData(nextProps);
    }
    
    DragToRight(){
        let left = [...this.state.allUsers];
        let right = [...this.state.docUsers];
        right = [...right,...this.state.leftSelect];
        left = left.filter(u => !right.includes(u));
        right = handleEveryOne(right);
        this.setState({
            allUsers: left,
            docUsers: right,
            leftSelect: [],
            rightSelect: [],
            lastLeft:0,
            lastRight:0
        });
    }

    DragToLeft(){
        let left = [...this.state.allUsers];
        let right = [...this.state.docUsers];
        left = [...left,...this.state.rightSelect];
        Helper.removeItem(everyOne, left);
        right = right.filter(u => !left.includes(u));
        right = handleEveryOne(right);
        this.setState({
            allUsers: left,
            docUsers: right,
            leftSelect: [],
            rightSelect: [],
            lastLeft:0,
            lastRight:0
        });
    }

    ModifySetAccessModalData = (setAccessProps: ISetAccessModalProps) => {
        let allUsers: IUserModel[] = [...setAccessProps.users];
        let docUsers: IUserModel[] = [];
        let message = msg.loading;

        if (setAccessProps.selectedReports != null && setAccessProps.selectedReports.length != 0) {
            if (setAccessProps.users && setAccessProps.users.length > 0 &&
                setAccessProps.selectedReports) {
                let arrays = setAccessProps.selectedReports.map((reports, j) => {
                    if (reports.documentAccess && reports.documentAccess.userIds && reports.documentAccess.userIds.length > 0) {
                        return reports.documentAccess.userIds;
                    }
                }).filter(a => a !== undefined);
                if (arrays && arrays.length > 0 && arrays[0] !== undefined) {
                    const common = Helper.intersection(arrays);

                    docUsers = common.map((id, i) => {
                        if (setAccessProps.users.findIndex((u) => u.userId === id) != -1) {
                            return setAccessProps.users.find((u) => u.userId === id)
                        }
                    }).filter(u => u !== undefined) as IUserModel[];

                }
                if (docUsers.length == 0) {
                    docUsers.push(everyOne);
                }
                else {
                    allUsers = this.filterLeft(docUsers);
                }
                message = msg.saving;
            }
            else {
                docUsers.push(everyOne);
            }
        }
        else {
            docUsers.push(everyOne);
        }

        if (setAccessProps.showEditAccess) {
            this.setState({
                allUsers: allUsers,
                docUsers: docUsers,
                message: message,
                leftSelect: [],
                rightSelect: []
            });
        }
        else {
            this.setState({
                saving: false
            });
        }

    }

    public render() {
        return <Modal
            centered={true}
            className="set-access-modal"
            show={this.props.showEditAccess}
            onHide={this.props.onCancel}
            backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    Set Access
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{marginLeft:'14px', marginRight:'14px'}}>Move users to apply signature document access.</p>
                <LoadingOverlay style={{ height: '100%', paddingTop:'17px'}}>
                    {this.props.setAccessHeaderTextDisplay ?
                        <Alert variant="info" className='modal-alert'>
                            <div style={{paddingTop: '6px'}}>
                                <span>
                                    <CheckCircleFill marginBottom={'3.5px'}/>
                                </span>
                                {this.props.count > 1 ? " " + this.props.count + " Document(s) selected." : " 1 Document(s) selected."}
                            </div>
                        </Alert> : ""}
                    
                    {this.state.alertMsg ?
                        <Alert
                            closeLabel={'remove'}
                            variant="warning"
                            dismissible
                            onClose={() => { this.setState({ alertMsg: '' }) }}>
                            <span
                                className={'fa ' + this.state.alertIcon}
                                style={{ marginRight: '5px' }}>
                            </span>{this.state.alertMsg}
                        </Alert>
                        : false}
                    <SetAccessBody
                        docUsers={this.state.docUsers}
                        allUsers={this.state.allUsers}
                        onDoubleClickLeft={this.onClickAvailableUsers}
                        onDoubleClickRight={this.onClickUsersWithAccessRights}
                        onSelectFromLeft={this.onSelectFromLeft}
                        onSelectFromRight={this.onSelectFromRight}
                        moveLeft={this.moveLeft}
                        moveRight={this.moveRight}
                        rightSelect={this.state.rightSelect}
                        leftSelect={this.state.leftSelect}
                        dragToRight={this.DragToRight}
                        dragToLeft={this.DragToLeft}/>
                    <Loader loading={this.state.saving} text={this.state.message} />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer>
                <span style={{marginRight:'21px'}}
                onClick={()=>this.ModifySetAccessModalData(this.props)}>
                    <a style={{textDecorationLine: 'underline',color: '#0973BA', cursor:'pointer'}}>
                        Reset
                        </a>
                </span>
                <Button
                    variant='default'
                    className="btn-white modal-footer-button-cancel"
                    disabled={this.state.saving}
                    onClick={this.props.onCancel}
                >Cancel</Button>
                <Button
                    variant="info"
                    className=' modal-footer-button-save'
                    disabled={this.state.saving}
                    onClick={this.onSubmit}
                >Save</Button>
               
            </Modal.Footer>
        </Modal>
    }

    private onSubmit(e: any) {
        if (!this.state.saving) {
            this.setState({ saving: true, message: msg.saving }, () => {
                let users = this.state.docUsers.map((user, id) => {
                    return user.userId;
                });
                if (users.some(x => x === -1)) {
                    users = [];
                }
                this.props.onApplyAccess(users);
            });
        }
    }
    private onClickAvailableUsers(selected: IUserModel, callback: any) {
        let leftSelect = Helper.handleSelect(selected, event, this.state.leftSelect, this.state.allUsers, this.state.lastLeft);
        this.setState({ leftSelect: leftSelect, lastLeft: selected.userId });

        if (callback)
            callback();
    }

    private onClickUsersWithAccessRights(selected: IUserModel, callback: any) {
        let rightSelect = Helper.handleSelect(selected, event, this.state.rightSelect, this.state.docUsers, this.state.lastRight);
        this.setState({ rightSelect: rightSelect, lastRight: selected.userId });

        if (callback)
            callback();
    }
    private onSelectFromLeft(selected: IUserModel, event: any) {
        if(Helper.isWithinElement(event, this.currentUsersId)) {
           let leftSelect = Helper.handleSelect(selected, event, this.state.leftSelect, this.state.allUsers, this.state.lastLeft);
           this.setState({ leftSelect: leftSelect, lastLeft: selected.userId });
        }
    }

    private onSelectFromRight(selected: IUserModel, event: any) {
        if(Helper.isWithinElement(event, this.selectedUsersId)) {
           let rightSelect = Helper.handleSelect(selected, event, this.state.rightSelect, this.state.docUsers, this.state.lastRight);
           this.setState({ rightSelect: rightSelect, lastRight: selected.userId });
        }
    }

    private moveRight(event: any) {
        if (this.state.leftSelect.length > 0) {
            let right = [...this.state.docUsers];
            this.state.leftSelect.map((u, i) => {
                right.push(u);
            });
            right = handleEveryOne(right);
            this.setState({
                allUsers: this.filterLeft(right),
                docUsers: right,
                leftSelect: [],
                rightSelect: [],
                lastLeft: 0,
                lastRight: 0
            });
        } else {
            this.setState({ alertMsg: 'Please select users to add!', alertIcon: 'fa-exclamation-triangle' });
        }
        event && event.preventDefault();
    }

    private moveLeft(event: any) {
        if (this.state.rightSelect.length > 0) {

            let right = [...this.state.docUsers];
            this.state.rightSelect.map((u, i) => {
                Helper.removeItem(u, right);
            });
            right = handleEveryOne(right);
            this.setState({
                allUsers: this.filterLeft(right),
                docUsers: right,
                leftSelect: [],
                rightSelect: [],
                lastLeft: 0,
                lastRight: 0
            });
        } else {
            this.setState({ alertMsg: 'Please select users to remove!', alertIcon: 'fa-exclamation-triangle' });
        }
        event && event.preventDefault();
    }

    private filterLeft(right: IUserModel[]) {
        let left = [...this.props.users];
        right.map((u, i) => {
            Helper.removeItem(u, left);
        });
        return left;
    }
};

export function handleEveryOne(right: IUserModel[]) {
    if (right.length > 1) {
        Helper.removeItem(everyOne, right);
    }
    if (right.length === 0) {
        right.push(everyOne);
    }
    return right;
}

interface IBodyProps {
    docUsers: IUserModel[];
    allUsers: IUserModel[];
    onDoubleClickLeft: (selected: IUserModel, callback: any) => void;
    onDoubleClickRight: (selected: IUserModel, callback: any) => void;
    onSelectFromLeft: (selected: IUserModel, event: any) => void;
    onSelectFromRight: (selected: IUserModel, event: any) => void;
    moveRight: (event: any) => void;
    moveLeft: (event: any) => void;
    rightSelect: IUserBaseModel[];
    leftSelect: IUserBaseModel[];
    dragToRight: () => void;
    dragToLeft: () => void;
}

export class SetAccessBody extends React.Component<IBodyProps, {}> {

    public render() {
        return <div>
            <div className="row">
                <Col sm={12} style={{ display: "flex" }}>
                    <Col sm={6}>
                        <FormGroup style={{ display: "block" }}  onDrop={()=>{ this.props.dragToLeft()}}>
                            <FormLabel>AVAILABLE USERS</FormLabel>
                                <FormControl id="current-users" style={{ height: '332px', overflowY: 'auto', padding:'0px'}} className="group_user custom-scroll-bar" as="div" onDragOver={(event:any)=>event.preventDefault()}>

                                    <ul style={{marginBottom:'0px'}}>
                                        {this.props.allUsers != undefined ? this.props.allUsers.map((user, index) => {
                                            const fullName = Helper.fullName(user);
                                            const oddEven = (index % 2 == 0) ? 'even ' : 'odd ';
                                            const isActiveSelected = this.props.leftSelect.some((j: IUserBaseModel) => j.userId == user.userId) ? "activeSelected" : "";
                                            return (
                                                <div draggable={true} onDragStart={(event:any)=>{this.props.onSelectFromLeft(user,event)}}>
                                                        <li
                                                            className={'set-access-users '+ oddEven + isActiveSelected}
                                                            key={index}
                                                            value={user.userId}
                                                            onDoubleClick={() => { this.props.onDoubleClickLeft(user, this.props.moveRight); }}
                                                            onClick={this.props.onSelectFromLeft.bind(this, user)}
                                                        > 
                                                            <div 
                                                                title={fullName}
                                                                className='ellipsis'
                                                                onClick={()=>this.props.onSelectFromLeft} 
                                                                style={{paddingTop:'4px',paddingBottom:'4px',cursor:'grab'}} >
                                                                <span style={{paddingLeft:'9px'}}><GripVertical /></span>
                                                                <span style={{paddingLeft:'15px'}}>{fullName}</span>
                                                            </div>
                                                        </li>
                                                </div>
                                            );
                                        }) : ""}
                                    </ul>

                                </FormControl>
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup style={{ display: "block" }}  onDrop={()=>{ this.props.dragToRight()}}>
                            <FormLabel>USER(S) WITH ACCESS RIGHTS</FormLabel>
                                <FormControl id="selected-users" style={{ height: '332px', overflowY: 'auto', padding:'0px' }} className="group_user custom-scroll-bar" as="div" onDragOver={(event:any)=>event.preventDefault()}>
                                        <ul style={{marginBottom:'0px'}}>
                                            {this.props.docUsers != undefined ? this.props.docUsers.map((user, index) => {
                                                const fullName = Helper.fullName(user);
                                                const oddEven = (index % 2 == 0) ? 'even ' : 'odd ';
                                                const isActiveSelected = this.props.rightSelect.some((j: IUserBaseModel) => j.userId == user.userId) ? "activeSelected" : "";
                                                return (
                                                    <div draggable={true} onDragStart={(event:any)=>{this.props.onSelectFromRight(user,event)}}>
                                                            <li
                                                                className={'set-access-users '+ oddEven + isActiveSelected}
                                                                key={index}
                                                                value={user.userId}
                                                                onDoubleClick={() => { this.props.onDoubleClickRight(user, this.props.moveLeft); }}
                                                                onClick={this.props.onSelectFromRight.bind(this, user)}
                                                                >
                                                                <div 
                                                                    title={fullName}
                                                                    className='ellipsis'
                                                                    onClick={()=>this.props.onSelectFromRight} 
                                                                    style={{paddingTop:'4px',paddingBottom:'4px',cursor:'grab'}} >
                                                                    <span style={{paddingLeft:'9px'}}><GripVertical /></span>
                                                                    <span style={{paddingLeft:'15px'}}>{fullName}</span>
                                                                </div>
                                                            </li>
                                                    </div>);
                                            }) : ""}
                                        </ul>
                                </FormControl>
                        </FormGroup>
                    </Col>
                </Col>
            </div>
        </div>
    }
}
