import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ISignatureFlowReportDocument } from '../../../models/SignatureFlowReportState';
import { RestoreSignatureReturnsConstants } from '../../helper/Constants';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { IReminderSettings } from '../../../models/SignatureFlowSettings';
import { filterUnSignedDocs, isReminderAllowedFilter } from '../../helper/HelperFunctions';
import { CheckCircle } from '../../svg/CustomSvgIcons';


export interface IRestoreDocumentsModalProps {
    show: boolean;
    onCancel: () => void;
    isLoading: boolean;
    loadingText: string;
    showReminderEnabledWarning: boolean;
    selectedReports: ISignatureFlowReportDocument[];
    filterSignatureStatus: string;
    restoreDocuments: (ids: number[]) => void;
    selectedDocReminderSettings: IReminderSettings;
    isBulkSelectionEnabled: boolean;
}

export const RestoreDocumentsModal: FC<IRestoreDocumentsModalProps> = props => {


    const getWarningMessage = () => {
        if (props.isBulkSelectionEnabled) {
            return RestoreSignatureReturnsConstants.BulkRestoreWarning;
        }
        else if (props.selectedReports && props.selectedReports.length === 1) {
            return RestoreSignatureReturnsConstants.SingleRestoreWarning;
        }
        else if (props.selectedReports && props.selectedReports.length > 1) {
            return RestoreSignatureReturnsConstants.MultiRestoreWarning;
        }
    }

    const restoreDocuments = () => {
        props.restoreDocuments(props.selectedReports.map((s: ISignatureFlowReportDocument) => { return s.signingId; }));
    }
    
    const showWarningMessage = () => {
        if (props.isBulkSelectionEnabled) {
            return isReminderAllowedFilter(props.filterSignatureStatus);
        }
        if (props.showReminderEnabledWarning || props.selectedDocReminderSettings.signingReminder.enabled) {
            if (props.selectedReports && filterUnSignedDocs(props.selectedReports).length >= 1) {
                return true
            }
        }
        return false
    }

    return (<div>
        <Modal centered={true} show={props.show} onHide={props. onCancel} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title> Restore Document(s) </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <LoadingOverlay style={{ height: '50%' }}>
                    <Loader loading={props.isLoading} text={props.loadingText ? props.loadingText : "Loading..."} />
                    <p> {RestoreSignatureReturnsConstants.documentRestoreConfirmation} </p>
                    {showWarningMessage() ?
                        <div className='custom-alert-warning div-flex'> 
                            <span style={{margin: '9px 7px 10px 11px'}}><CheckCircle fill='#0973BA' /></span>
                            <span style={{marginLeft:'5px'}}>{getWarningMessage()}</span>
                        </div> : null
                    }
                </LoadingOverlay>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant='default'
                    onClick={props.onCancel}
                    disabled={props.isLoading}
                    className='btn-white modal-footer-button-cancel'
                    data-test-auto="6DAD384C-3DD8-416B-9624-C8A8ACBA188B">
                        Cancel
                </Button>
                <Button
                    variant='info'
                    disabled={props.isLoading}
                    className=' modal-footer-button-save'
                    onClick={restoreDocuments}
                    data-test-auto="371DD78C-4067-4EDB-903C-D4DFFD4E3DED">
                        Yes
                </Button>
            </Modal.Footer>
        </Modal>
    </div>)
}