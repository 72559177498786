import * as React from "react";
import { LoadingOverlay, Loader } from 'react-overlay-loader';

export interface IAccordianProps {
    uniqueId: any;
    header: any;
    onHeaderClick?: () => void;
    onContentClick?: (pageNo: number) => void;
    reset: (documentGuid: string) => void;
    content: any;
    loading?: boolean;
}

export interface IAccordianStates {
    toggle: boolean;
}

export class Accordian extends React.PureComponent<IAccordianProps, IAccordianStates> {
    constructor(props: IAccordianProps) {
        super(props);
        this.state = { toggle: false }
    }
    private renderHeading = (key: any) => {
        return <div key={key} id={key} className="card-header bookmark-row bookmark-item"  style={{ background: "transparent" }}>
            <h4 className="card-title col-md-12" style={{ display: "flex", width: "100%", padding: "0px" }}>
                <div className="col-md-8" style={{ padding: "0px", lineHeight: 'initial' }}>
                    <span className="bookmark-name wordbreak ellipsis" onClick={() => { this.onHeaderClick() }} title={this.props.header} style={{ display: "block", cursor: "pointer" }}
                        data-toggle="collapse" data-parent="#accordion">
                        {this.props.header}
                    </span>
            
                </div>
                <div className="col-md-1"><a onClick={() => { this.props.reset(this.props.uniqueId) }} title="Reset"><i className="fas fa-undo-alt" style={{ cursor: "pointer", color: "#1d9bff" }} ></i></a></div>
                <div className="col-md-2" style={{ padding: "0px", lineHeight: 'initial' }}>
                    <i className={"accordion-toggle bookmark-right " + (this.state.toggle === true ? "" : "collapsed")} style={{ cursor: "pointer" }} onClick={() => { this.onHeaderClick() }} data-toggle="collapse"
                        data-parent="#accordion"></i>
                </div>
            </h4>
        </div>;
    }

    private onHeaderClick = () => {
        this.setState({ toggle: !this.state.toggle });
        this.props.onHeaderClick && this.props.onHeaderClick();
    }

    private renderContent = (key: any) => {
        return <div id={key} key={key} className={"card-collapse " + (this.state.toggle === true ? "" : "collapse")} >
            <div className="card-body">
                <LoadingOverlay style={{ height: '100%' }}>
                    <ul key={key}>
                        {
                            this.props.content
                        }
                    </ul>
                    <Loader loading={this.props.loading} />
                </LoadingOverlay>
            </div>
        </div>
    }

    public render() {
        let _self = this;
        return (<div className="accordian-panel card-group" id="accordion" key={_self.props.uniqueId} style={{ margin: "0px" }}>
            <div className="card">
                {
                    this.renderHeading(_self.props.uniqueId)
                }
                {
                    this.renderContent(_self.props.uniqueId)
                }
            </div>
        </div>);
    }
}