import { DefaultSenderInfoType, IUserDefaultSettingsStates, DefaultDownloadInfoType } from '../../../SignatureFlow/models/user/UserSettingsModels';
import { AuthenticationProvider } from '../Company/CompanySettingsViewModel';
let moment = require('moment');
const defaultDate = moment.utc("0001-01-01");

export interface IUserBaseModel {
    userId: number,
    firstName: string,
    lastName: string,
    email: string
}

export interface IUserModel {
    userId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    phoneNumber: string;
    extension: string;
    ptin: string;
    email: string;
    confirmEmail: string;
    faxNumber: string;
    title: string;
    customUserGroup: IUserGroups[];
    userGroups: IUserGroups[];
    createdOn?: any;
    password?: string;
    authenticationProviders: AuthenticationProvider[];
    metadata: string;
    isDeceased: boolean;
    mobileNumber: string;
    countryCode: string;
    isMobileVerify: boolean;
    userSettings: IUserDefaultSettingsStates;
    lastLogin: Date;
    userReviewStatus: UserReviewStatus,
    revokeStatus: SSORevokeStatus,
}

export enum UserReviewStatus {
    YetToReview = 0,
    Reviewed = 1,
    NotApplicable = 100
}

export enum SSORevokeStatus {
    None = 0,
    PermanentlyRevoked = 1,
    TemporarilyRevoked = 2,
}


export interface IUserFilterOptions {
    filterName: string,
    filterEmailId: string,
    filterUserGroup: string,
    sortBy?: string,
    sortOrder?: string,
    offset: number
}

export interface IUserGroups {
    id: number;
    // groupCategoryId?: IGroupCategory;
    //customUserGroup?: number,
    name: string;
    userId?: number;
    userGroups?: number;

}

export enum IGroupCategory {
    None,
    Departments = 1,
    Locations,
    Groups
}

export enum IUserGroup {
    None,
    SystemAdmin = 1,
    Staff,
    Partner,
    Signatures
}

export interface ICustomUserGroup {
    id: number;
    groupCategoryId: number;
    name: string;
}

export enum UserManagementProperty {
    firstName = 0,
    middleName,
    lastName,
    phoneNumber,
    extension,
    ptin,
    email,
    faxNumber,
    title,
    mobileNumber,
    countryCode,
    isMfaEnabled
}

export enum IChangePassword {
    No = 0,
    Yes = 1
}
export enum IPasswordChangeBy {
    None,
    IWillCreate = 1,
    UserWillCreate
}

export const initialUserModel: IUserModel = {
    userId: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    extension: "",
    ptin: "",
    email: "",
    confirmEmail: "",
    faxNumber: "",
    title: "",
    customUserGroup: [],
    userGroups: [],
    createdOn: "",
    authenticationProviders: [],
    metadata: "",
    isDeceased: false,
    mobileNumber: "",
    countryCode: "",
    isMobileVerify: false,
    userSettings: {
        defaultSettings: {
            manualAddressId: 0,
            contactPerson: 0,
            eroUser: 0,
            notifyUser: 0,
            reportFilter: "",
            sender: {
                senderId: 0,
                senderType: DefaultSenderInfoType.None
            },
            enableLastLogin: false
        },
        signatureSettings: {
            isAutoPlaceSignature: false,
            isEnableSignatureDelegation: false,
            signaturePath: ""
        },
        enableSignerDeligation: false,
        useSignatureStamp: false,
        userId: 0,
        signatureDelegation: {
            delegate: [],
            userId: 0
        },
        mfaSettings: {
            isMfaEnabled: false
        }
    },
    lastLogin: defaultDate,
    userReviewStatus: UserReviewStatus.NotApplicable,
    revokeStatus: SSORevokeStatus.None,
}

export const initialCustomUserGroup: ICustomUserGroup = {
    id: 0,
    groupCategoryId: 0,
    name: ""
}

export const initialUserBaseModel: IUserBaseModel = {
    userId: 0,
    firstName: '',
    lastName: '',
    email: ''
}

export interface IUserMetaData {
    readonlyFields: IUserReadonlyFields
}

export interface IUserReadonlyFields {
    providerData: IExternalAuthenticationProviderFields[]
}

export interface IExternalAuthenticationProviderFields {
    provider: AuthenticationProvider,
    fields: string[],
}

export enum Product {
    None = 0,
    Returns = 1,
    SignaturesOneOff = 2,
    SignaturesMailMerge = 3,
    Organizers = 4,
    Extensions = 5,
    Exchange = 6,
    SSSuitePortal = 1001,
}

export const DownloadOptions = [
    {
        value: DefaultDownloadInfoType.DownloadNow,
        label: "Download Now"
    },
    {
        value: DefaultDownloadInfoType.DownloadToMyDownloads,
        label: "Download to \"My Downloads\""
    },
    {
        value: DefaultDownloadInfoType.PromptMeEveryTime,
        label: "Prompt me Every Time"
    }
]
export interface IDropdown {
    value: number,
    name: string,
}