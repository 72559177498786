import { injectable } from "inversify";
import { PDFDocumentProxy } from 'pdfjs-dist';
const PDFJS: any = require('pdfjs-dist');

export interface IFileClient {
    download(url: string): Promise<any>;
}

@injectable()
export class FileClient implements IFileClient {

    download(url: string): Promise<any> {

        return new Promise(function (resolve, reject) {

            PDFJS.getDocument({ url: url })
                .then((pdf: PDFDocumentProxy) => {

                    resolve(pdf);

                }, (error: string) => {

                    reject(error);

                });
        });
    }
}


@injectable()
export class IEFileClient implements IFileClient {

    download(url: string): Promise<any> {

        return new Promise(function (resolve, reject) {

            fetch(url).then(function (response) {

                if (response.ok) {
                    return response.blob();
                }
                throw new Error('Error in network response/slow network !!');

            }).then(function (responseData) {

                let objectURL: string = URL.createObjectURL(responseData);

                PDFJS.getDocument(objectURL)
                    .then((pdf: PDFDocumentProxy) => {

                        resolve(pdf);

                    }, (error: string) => {
                        reject(error);
                    });

            }).catch(function (error) {
                reject(error);
            });

        });
    }
}