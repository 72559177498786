import * as React from "react";
import * as Bootstrap from 'react-bootstrap';
import { ResourceIdConstants } from "../helper/RBACConstants";
import { DeleteHeaderIcon, RefreshFilterIcon, RestoreHeaderIcon } from "../svg/CustomSvgIcons";


interface HeaderProps {
    screenId: string;
    pageTitle: string;
    onRetoreDocumentsOpen: (rowIndex: number) => void,
    onDeleteDocuments: (rowIndex: number) => void,
    selectedDocumentCount: number,
    onPageReload: () => void,
    isBulkSelectionEnabled: boolean;
}

const NO_INDEX = -1;

export class SignatureRecycledReportsHeader extends React.Component<HeaderProps, {}>{

    public render() {

        const { screenId } = this.props;

        return <div>
            <div className="col-xs-8" style={{ display: "inline-flex", margin: "5px 0 20px 0" }}>
                <h3><strong>{this.props.pageTitle}</strong></h3>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <Bootstrap.Button
                        variant='default'
                        className='btn btn-white header-action'
                        data-resource-id={`${screenId}${ResourceIdConstants.Delete}`}
                        data-test-auto="6B5F4E00-2943-4E11-A2C9-5BB7142BF009"
                        disabled={this.props.isBulkSelectionEnabled ? false : this.props.selectedDocumentCount === 0}
                        onClick={() => this.props.onDeleteDocuments(NO_INDEX)} >
                        <DeleteHeaderIcon /><span>Delete</span>
                    </Bootstrap.Button>


                    <Bootstrap.Button
                        variant='default'
                        className='btn btn-white marL10 header-action'
                        data-resource-id={`${screenId}${ResourceIdConstants.Restore}`}
                        data-test-auto="A38A7A8A - 7079 - 4FD7-BE6A-5358623AFF82"
                        disabled={this.props.isBulkSelectionEnabled ? false : this.props.selectedDocumentCount === 0}
                        onClick={() => this.props.onRetoreDocumentsOpen(NO_INDEX)}>
                        <RestoreHeaderIcon /><span>Restore</span>
                    </Bootstrap.Button>


                    <Bootstrap.Button
                        variant="default"
                        onClick={this.props.onPageReload}
                        title="Refresh"
                        className="btn-white btn-only marR10 header-filter-icon header-filter-button-size"
                        style={{ paddingLeft: '12px', marginLeft: '8px', float:"right" }}
                        data-test-auto="80D665FF-B3C8-436C-831D-FCC8A0B62662">
                        <RefreshFilterIcon marginLeft={'-2px'}/>
                    </Bootstrap.Button>
                </div>

            </div>
        </div>
    }
}

export default SignatureRecycledReportsHeader;