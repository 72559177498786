import * as React from 'react';
import * as bootbox from 'bootbox';
import { RouteComponentProps } from 'react-router';
import { SortDirections, ReportFilterType } from '../../components/common/Filters';
import { ISignatureFlowReportFilter, ISignerModel, ISignatureFlowReportDocument, ClientTypes } from '../../models/SignatureFlowReportState';
import { SignatureRecycleReportTable } from './RecycledReports/SignatureRecycleReportTable';
import * as UserStore from '../../store/SFUserStore';
import * as RecycleReportStore from '../../store/ReportsStore/RecycleReport/RecycleReportStore';
import * as UserProfileStore from '../../store/userManagement/UserProfileStore';
import { IUserProfile } from '../../components/navigation/profile/ProfileObjects';
import * as SignatureRecycleFlowReportState from '../../models/SignatureFlowRecycleReportTableState';
import * as SignatureFlowReportCustomColumnStore from '../../store/ReportsCommon/SignatureFlowReportCustomColumnStore';
import * as SignatureFlowReportFilterStore from '../../store/ReportsCommon/SignatureFlowReportFilterStore';
import * as SignatureFlowReportPopupModelStore from '../../store/ReportsCommon/SignatureFlowReportPopupModelStore';
import * as SignatureFlowSettingsStore from '../../store/SignatureFlowSettingsStore';
import * as SigningInfoSettingsStore from '../../store/SigningInfoSettingsStore';
import { VenusNotifier } from '../../components/helper/VenusNotifier';
import * as Helper from '../../components/helper/HelperFunctions';
import { SignatureRecycledReportsHeader } from './SignatureRecycledReportsHeader';
import { SignatureFlowESignDetailsModal } from './ActionButtonModals/SignatureFlowESignDetailsModal';
import { SignatureFlowReportDocumentModal } from './ActionButtonModals/SignatureFlowReportDocumentModal';
import { SignatureFlowReportCancelDocument } from './ActionButtonModals/SignatureFlowReportCancelDocumentModal';
import { SignatureRecycledReportRecipientModal } from './RecycledReports/SignatureRecycledReportRecipientModal';
import {
    DeleteSignatureConstants, RestoreSignatureReturnsConstants, SizePerPageList
} from '../helper/Constants';
import { logger } from '../../../routes/LoggedIn';
import { SFBulkRecycledOperationQuery } from '../../models/SFBulkOperationQuery';
import * as SFBulkOperationsStore from '../../store/SFBulkOperationsStore';
import { HideLoader } from '../../components/helper/Loader';
import { filterDocumentsForReminders, getAdjustedReportsPageNumber, getCurrentTaxYear, NO_INDEX } from '../../components/helper/HelperFunctions';
import { ISigningInfoSettingDictionary } from '../../store/SigningInfoSettingsStore';
import { RestoreDocumentsModal } from './RecycledReports/RestoreDocumentsModal';
import { initialRestoreDocumentState } from '../../models/SignatureFlowRecycleReportTableState';
import { IReminderSettings } from '../../models/SignatureFlowSettings';
import { initialReminderSettings } from '../../models/SigningInfoSettings';
import { ResourceIdConstants, ScreenIds } from '../helper/RBACConstants';
import { CommonPaths } from '../helper/UrlPaths';
import Pagination from '../common/Pagination';
import * as OfficeLocationStore from '../../store/MultiOffice/OfficeLocationStore';

// At runtime, Redux will merge together...
type SignatureRecycleReportProps =
    {
        RecycleReportDocuments: SignatureRecycleFlowReportState.ISignatureFlowReportState,     // ... state we've requested from the Redux store
        userStore: UserStore.ISFUserData,
        signatureFlowReportDetails: SignatureFlowReportPopupModelStore.ISignatureFlowReportPopupDictionary,
        signatureFlowReportFilter: SignatureFlowReportFilterStore.IFilterState,
        reportCustomColumns: SignatureFlowReportCustomColumnStore.ISignatureFlowReportCustomColumnState,
        profile: IUserProfile,
        signatureFlowSettingsData: SignatureFlowSettingsStore.ISignatureFlowSettingsData,
        signingInfoSettingsData: ISigningInfoSettingDictionary,
        userOfficeLocationState: OfficeLocationStore.IUserOfficeLocationState,
        pageTitle: string,
    }
    & typeof RecycleReportStore.actionCreators   // ... plus action creators we've requested
    & typeof SignatureFlowReportCustomColumnStore.actionCreators
    & typeof UserStore.actionCreators
    & typeof SignatureFlowReportFilterStore.actionCreators
    & typeof SignatureFlowReportPopupModelStore.actionCreators
    & typeof UserProfileStore.actionCreators
    & typeof SignatureFlowSettingsStore.actionCreators
    & typeof SFBulkOperationsStore.actionCreators
    & typeof SigningInfoSettingsStore.actionCreators
    & RouteComponentProps<{ page: string }>;        // ... plus incoming routing parameters

const pageSize: number = 10;

export class SignatureRecycleReports extends React.Component<SignatureRecycleReportProps, SignatureRecycleFlowReportState.ISignatureFlowRecycleReportPageState> {
    restoreDocModalRef: React.RefObject<unknown>;
    constructor(props: SignatureRecycleReportProps) {
        super(props);
        this.restoreDocModalRef = React.createRef();
        this.state = {
            page: 1,
            pageSize: pageSize,
            filter: ({
                name: '',
                searchText: '',
                sort: {
                    column: "",
                    direction: SortDirections.None
                },
                fields: {},
                isDefault: 0,
                filterType: ReportFilterType.None,
                isDefaultFilter: false,
                isMasterFilter: false,
            }),
            selectedRows: [],
            searchString: "",
            sortName: "",
            sortOrder: "desc",
            saveFilterShow: false,
            filterSenderName: "",
            filterDocumentName: "",
            filterDocumentType: "",
            filterRecycledOn: undefined,
            filterSigners: "",
            filterSignatureStatus: "",
            filterTitle: "",
            filterDownloadStatus: "",
            filterLoggedUser: "",
            filterTaxYear: getCurrentTaxYear(),
            filterRecipientName: "",
            filterClientId: "",
            filterOfficeLocation: "",
            showLoader: false,
            //Signer Details State
            signerDetailsState:
            {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Document Details State
            documentDetailsState:
            {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Recipient Details State
            recipientDetailsState:
            {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Cancel Document State
            cancelDocumentState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

             //Restore Document State
            restoreDocumentState: initialRestoreDocumentState,
            //
            isBulkSelectionEnabled: false,
            deSelectedRows: [],
            showBulkSelectionMessage: false,
            totalRecordsCount: 0,
        };

        this.onPageReload = this.onPageReload.bind(this);

        //Grid Operation Handlers
        this.onRowSelect = this.onRowSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);

        //filter operation handlers
        this.onFilterChange = this.onFilterChange.bind(this);
        this.applyDefaultFilter = this.applyDefaultFilter.bind(this);

        this.onSignersDetailOpen = this.onSignersDetailOpen.bind(this);
        this.onSignersDetailCancel = this.onSignersDetailCancel.bind(this);

        this.onDocumentDetailsOpen = this.onDocumentDetailsOpen.bind(this);
        this.onDocumentDetailsCancel = this.onDocumentDetailsCancel.bind(this);

        this.onDeleteDocumentsOpen = this.onDeleteDocumentsOpen.bind(this);
        this.onRestoreDocumentsOpen = this.onRestoreDocumentsOpen.bind(this);

        this.onDeleteSelectedReturns = this.onDeleteSelectedReturns.bind(this);
        this.onConfirmRestoreSelectedReturns = this.onConfirmRestoreSelectedReturns.bind(this);


        //Support Methods
        this.selectedDocumentIds = this.selectedDocumentIds.bind(this);
        this.selectedDocuments = this.selectedDocuments.bind(this);
        this.getStoreItem = this.getStoreItem.bind(this);
        this.buildQuery = this.buildQuery.bind(this);

        this.hasAllSignersSigned = this.hasAllSignersSigned.bind(this);
    }

    UNSAFE_componentWillMount() {
        logger.trackPageView("Signature Recycle Report Page");
        this.props.requestSignatureRecycleDocuments(this.buildQuery(this.state.page, this.state.pageSize), true);
        this.props.requestCompanySignatureSettings();
        this.props.requestSignatureFlowReportFilter();
        this.props.requestAllSFUsers(true);

    }

    UNSAFE_componentWillReceiveProps(nextProps: SignatureRecycleReportProps) {
        if (this.state.isBulkSelectionEnabled) {
            let selectedRows: number[] = [];
            nextProps.RecycleReportDocuments.signatureFlowReportTableModel.documents.forEach((value, index) => {
                this.state.deSelectedRows.find(m => m === value.signingId) === undefined && selectedRows.push(index);
            });
            this.setState({ selectedRows: selectedRows });
        }
        if(nextProps.RecycleReportDocuments.totalRowCount !== this.state.totalRecordsCount){
            if(!nextProps.RecycleReportDocuments.isLoading){
                this.setState({
                    totalRecordsCount: nextProps.RecycleReportDocuments.totalRowCount,
                });
            }      
        }
    }

    buildQuery(page: number, pageSize: number) {
        return '?pageNo=' + page
            + '&pageSize=' + this.state.pageSize
            + '&sortBy=' + this.state.sortName
            + '&sortOrder=' + this.state.sortOrder
            + '&searchString=' + this.state.searchString
            + '&filterSenderName=' + encodeURIComponent(this.state.filterSenderName)
            + '&filterDocumentName=' + encodeURIComponent(this.state.filterDocumentName)
            + '&filterDocumentType=' + this.state.filterDocumentType
            + '&filterDownloadStatus=' + this.state.filterDownloadStatus
            + '&filterSignatureStatus=' + this.state.filterSignatureStatus
            + '&filterRecycledOn=' + (this.state.filterRecycledOn || "")
            + '&filterSigners=' + this.state.filterSigners
            + '&filterTitle=' + encodeURIComponent(this.state.filterTitle)
            + '&filterLoggedUser=' + this.state.filterLoggedUser
            + '&filterTaxYear=' + this.state.filterTaxYear
            + '&filterRecipientName=' + encodeURIComponent(this.state.filterRecipientName)
            + '&filterClientId=' + encodeURIComponent(this.state.filterClientId)
            + '&filterOfficeLocation=' + this.state.filterOfficeLocation
    }

    loadSignatureFlowReports() {
        let queryString = this.buildQuery(this.state.page, this.state.pageSize);
        this.props.requestSignatureRecycleDocuments(queryString, false);
    }

    fetchSignatureFlowReports(page: number, sizePerPage: number) {
        let queryString = this.buildQuery(page, sizePerPage);
        this.setState({ page: page }, () => { this.props.requestSignatureRecycleDocuments(queryString), false });
    }

    //======================Grid Operation Starts here===============================

    private onRowSelect(row: any, isSelected: any, e: any) {
        let newList = [...this.state.selectedRows];
        let deSelectedRows = this.state.deSelectedRows;
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A' && e.target.tagName !== 'DIV') {
            if (isSelected) {
                newList.push(row.rowIndex);
                if (this.state.isBulkSelectionEnabled) {
                    index = deSelectedRows.indexOf(row.signingId);
                    deSelectedRows.splice(index, 1);
                }
            } else {
                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
                if (this.state.isBulkSelectionEnabled) {
                    deSelectedRows.push(row.signingId);

                    if (deSelectedRows.length >= this.props.RecycleReportDocuments.totalRowCount) {
                        deSelectedRows = [];
                        this.setState({deSelectedRows: [], isBulkSelectionEnabled: false});
                    }
                }
            }
            this.state.isBulkSelectionEnabled ?
                this.setState({ deSelectedRows: deSelectedRows, selectedRows: newList }) :
                this.setState({ selectedRows: newList });
        }
        this.forceUpdate();
    }

    private onSelectAll(isSelected: boolean, rows: any[], e: any) {
        let selectedRows: number[] = [];
        let showBulkSelectionMessage = false;

        if (isSelected) {
            rows.forEach((value: any, index: number) => {
                selectedRows.push(value.rowIndex);
            });
            showBulkSelectionMessage = true;
        }
        this.setState({
            selectedRows: selectedRows,
            deSelectedRows: [],
            showBulkSelectionMessage: showBulkSelectionMessage,
            isBulkSelectionEnabled: false
        });
    }

    private onPageChange(page: number,pageSize: number) {
        if (this.state.isBulkSelectionEnabled) {
            this.setState({
                page: page,
                pageSize:pageSize
            }, this.loadSignatureFlowReports);
        }
        else {
            this.setState({
                page: page,
                pageSize:pageSize,
                selectedRows: [],
                showBulkSelectionMessage: false,
            }, this.loadSignatureFlowReports);
        }
    }

    private onBulkSelectionChange = (isEnabled: boolean) => {
        let showBulkSelectionMessage = false;
        this.setState({
            isBulkSelectionEnabled: isEnabled,
            showBulkSelectionMessage: showBulkSelectionMessage
        });
    }

    private onSortChange(sortName: string, sortOrder: string, column: number) {
        this.clearSelection();
        let temp: ISignatureFlowReportFilter = { ...this.state.filter };
        temp.sort.column = sortName;
        temp.sort.direction = sortOrder == "asc" ? SortDirections.Ascending : SortDirections.Descending;
        this.setState({
            filter: temp,
            sortName: sortName,
            sortOrder: sortOrder == "asc" ? "asc" : "desc"
        }, () => this.fetchSignatureFlowReports(1, this.state.pageSize));
    }

    private onSearchChange(searchString: string) {
        let temp: ISignatureFlowReportFilter = { ...this.state.filter };
        temp.searchText = searchString;
        this.setState({
            searchString: searchString,
            filter: temp

        }, () => this.fetchSignatureFlowReports(1, this.state.pageSize));
    }

    private onPageReload() {
        let query = this.buildQuery(this.state.page, this.state.pageSize);
        this.props.requestSignatureRecycleDocuments(query, true);
        this.clearSelection();
    }

    //======================Grid Operation Ends here=================================
    private selectedDocumentIds() {
        let ids: number[] = [];
        this.state.selectedRows.map((row, i) => {
            let selectedDocument = this.props.RecycleReportDocuments.signatureFlowReportTableModel.documents[row];
            if (selectedDocument) {
                ids.push(selectedDocument.signingId);
            }
        });
        return ids;
    }

    private selectedDocuments() {
        let docs: any[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.RecycleReportDocuments.signatureFlowReportTableModel.documents[row];
            if (selectedDocument) {
                docs.push(selectedDocument);
            }
        }
        return docs;
    }
    private onPopupOpen(rowIndex: number) {
        let ids = [rowIndex];
        if (rowIndex !== NO_INDEX) {
            return [this.props.RecycleReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId];
        }
        if (this.state.selectedRows.length == 0) {

            return [];
        }
        return this.selectedDocumentIds();
    }

    private getStoreItem(index: number) {
        let id = this.props.RecycleReportDocuments.signatureFlowReportTableModel.documents[index].signingId;
        return this.props.signatureFlowReportDetails[id].signatureReport;

    }

    private hasAllSignersSigned(selectedReportSigners: ISignerModel[]) {
        let isSigned: boolean = selectedReportSigners.filter(signer => signer.order >= 0 && signer.signerType != ClientTypes.CC).every(signer => signer.isSigned == 1);
        return isSigned;
    }

    //======================Filter Operation Starts here=================================

    private applyDefaultFilter(filter: ISignatureFlowReportFilter) {
        this.setState({ filter: filter },
            () => this.fetchSignatureFlowReports(1, this.state.pageSize))
    }

    private onFilterChange(dataField: any) {
        let newState: SignatureRecycleFlowReportState.ISignatureFlowRecycleReportPageState = {
            filterSenderName: "",
            filterRecycledOn: undefined,
            filterSigners: "",
            filterDownloadStatus: "",
            filterSignatureStatus: "",
            filterDocumentName: "",
            filterDocumentType: "",
            filterTitle: "",
            filterLoggedUser: "",
            filterRecipientName: "",
            filterClientId: "",
            filterOfficeLocation: "",
            isBulkSelectionEnabled: false
        } as SignatureRecycleFlowReportState.ISignatureFlowRecycleReportPageState;
        let filterStatus: any;
        let filterTaxYear: any;
        var dict: { [columnName: string]: string; } = {};
        for (let field of Object.keys(dataField)) {
            dict[field.valueOf().toString()] = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            switch (field) {
                case 'deliveredBy':
                    newState.filterSenderName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'signatureStatus':
                    filterStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    filterStatus = (filterStatus == "-1") ? "" : filterStatus;
                    this.setState({ filterSignatureStatus: filterStatus });
                    newState.filterSignatureStatus = ((dataField[field].value) && (dataField[field].value !== "-1")) ? dataField[field].value : "";
                    break;
                case 'officeLocation':
                    let filterOfficeLocation = dataField[field].value ? dataField[field].value : dataField[field];
                    filterOfficeLocation = (filterOfficeLocation == "-1") ? "" : filterOfficeLocation;
                    this.setState({ filterOfficeLocation: filterOfficeLocation });
                    newState.filterOfficeLocation = ((dataField[field].value) && (dataField[field].value !== "-1")) ? dataField[field].value : "";
                    break;
                case 'documentStatusChangedOn':
                    newState.filterRecycledOn = Helper.GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
                    break;
                case 'signers':
                    newState.filterSigners = dataField[field].value ? dataField[field].value : dataField[field];
                    break;


                case 'downloadStatus':
                    newState.filterDownloadStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'documentName':
                    newState.filterDocumentName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'documentType':
                    newState.filterDocumentType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'title':
                    newState.filterTitle = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'loggedUser':
                    newState.filterLoggedUser = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'taxYear':
                    filterTaxYear = ((dataField[field].value) && (dataField[field].value !== "-1")) ? dataField[field].value : "";
                    filterTaxYear = (filterTaxYear == "-1") ? "" : filterTaxYear;
                    this.setState({ filterTaxYear: filterTaxYear });
                    newState.filterTaxYear = filterTaxYear;
                    break;
                case 'recipientName':
                    newState.filterRecipientName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'clientId':
                    newState.filterClientId = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
            }
        }
        let tempfilter: ISignatureFlowReportFilter = { ...this.state.filter };
        tempfilter.fields = dict;
        if (this.isFilterChanged(newState, filterStatus, filterTaxYear)) {
            this.clearSelection();
            this.setState({ ...newState, filter: tempfilter, page: 1 },
                () => {
                    let signatureRecycleReportTable: any = this.refs.SignatureRecycleReportTable;
                    if (!signatureRecycleReportTable.isAppliedFilter && !this.props.RecycleReportDocuments.isLoading)
                        this.props.requestSignatureRecycleDocuments(this.buildQuery(1, this.state.pageSize), true)
                })
        }
    }
    private clearSelection = () => {
        this.setState({
            selectedRows: [],
            deSelectedRows: [],
            isBulkSelectionEnabled: false,
            showBulkSelectionMessage: false
        });
    }
    private isFilterChanged(newState: SignatureRecycleFlowReportState.ISignatureFlowRecycleReportPageState, filterStatus: any, filterTaxYear: any): boolean {
        return (
            newState.filterSenderName !== this.state.filterSenderName ||
            newState.filterSignatureStatus !== this.state.filterSignatureStatus ||
            newState.filterDownloadStatus !== this.state.filterDownloadStatus ||
            newState.filterRecycledOn !== this.state.filterRecycledOn ||
            newState.filterDocumentName !== this.state.filterDocumentName ||
            newState.filterDocumentType !== this.state.filterDocumentType ||
            newState.filterSigners !== this.state.filterSigners ||
            newState.filterTitle != this.state.filterTitle ||
            newState.filterLoggedUser != this.state.filterLoggedUser ||
            filterTaxYear != this.state.filterTaxYear ||
            newState.filterOfficeLocation != this.state.filterOfficeLocation ||
            newState.filterRecipientName != this.state.filterRecipientName ||
            newState.filterClientId != this.state.filterClientId);
    }

    clearFilters = () => {
        let signatureRecycleReportTable: any = this.refs.SignatureRecycleReportTable;
        signatureRecycleReportTable.refs.Filters.setState({ activeFilter: '', appliedFilters: [] });
    }

    //======================Filter Operation Ends here=================================


    //======================Signer Details State=======================================

    private onSignersDetailOpen(rowIndex: number = NO_INDEX) {
        this.setState({
            signerDetailsState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });

    }

    private onSignersDetailCancel() {
        this.setState({
            signerDetailsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        })
    }
    private onRecipientDetailsCancel = () => {
        this.setState({
            recipientDetailsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }
    //======================Signer Details Ends Here======================================= 


    //======================Document Details State=======================================

    private onDocumentDetailsOpen(rowIndex: number = NO_INDEX) {
        this.setState({
            documentDetailsState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });

    }

    private onDocumentDetailsCancel() {
        this.setState({
            documentDetailsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        })
    }

    getBulkOperationQuery = (): SFBulkRecycledOperationQuery => {
        let query: SFBulkRecycledOperationQuery = {
            clientName: this.state.filterRecipientName,
            clientID: this.state.filterClientId,
            title: this.state.filterTitle,
            documentType: this.state.filterDocumentType,
            taxYear: this.state.filterTaxYear,
            signer: this.state.filterSigners,
            signatureStatus: this.state.filterSignatureStatus,
            deletedBy: this.state.filterSenderName,
            deletedOn: this.state.filterRecycledOn,
            unSelectedIds: this.state.deSelectedRows,
            totalRecords: this.props.RecycleReportDocuments.totalRowCount,
            officeLocation: this.state.filterOfficeLocation
        }
        return query;
    }


    //====================Restore Returns Starts Here===============================

    private onRestoreDocumentsOpen(rowIndex: number = NO_INDEX) {
        if (this.hasExpiredDocuments()) {
            VenusNotifier.Warning(RestoreSignatureReturnsConstants.DocumentExpired, null);
            return;
        }
        let ids = this.onPopupOpen(rowIndex);
        let selectedReports = ids.map((id, i) => {
            return this.props.RecycleReportDocuments.signatureFlowReportTableModel.documents.filter(x => x.signingId == id)[0];
        });
        
        if (this.state.isBulkSelectionEnabled) {
            this.restoreDocuments([], true);
        }
        else if (ids.length === 0  && !this.state.isBulkSelectionEnabled) {
            VenusNotifier.Warning(RestoreSignatureReturnsConstants.PleaseSelectDocumentToRestore, null);
            return;
        }
        else if (ids.length === 1) {
            this.props.getReminderSettings(ids[0],
                `${ScreenIds.RecycleBin}${ResourceIdConstants.Restore}`,
                () => { this.restoreDocuments(ids) });
        }
        else {
            let filteredIds = filterDocumentsForReminders(ids, this.props.RecycleReportDocuments.signatureFlowReportTableModel.documents)
            if (filteredIds.length > 0) {
                this.props.isSigningReminderEnabled(filteredIds, this.restoreDocuments);
            }
        }
        this.setState({
            restoreDocumentState: {
                selected: selectedReports,
                show: true,
                saveCheckIds: undefined,
                remove: false,
                showReminderEnabledWarning: false
            }
        });
    }

    restoreDocuments = (ids: number[], showReminderEnabledWarning: boolean = false) => {
        if (showReminderEnabledWarning) {
            this.setState({ restoreDocumentState: { ...this.state.restoreDocumentState, showReminderEnabledWarning: true } });
        }
    }

    private hasExpiredDocuments = (): boolean => {
        const selectedDocuments = this.selectedDocuments();
        if (selectedDocuments && selectedDocuments.includes((x: any) => x.isExpired)){
            return true;
        }
        return false;
    }
  

    private onConfirmRestoreSelectedReturns(ids: number[]) {
        this.state.isBulkSelectionEnabled ? this.onBulkRestoreReturns() :
            this.props.restoreSignatureRecycledDocument(ids, () => {
                this.setState({
                    restoreDocumentState: {...initialRestoreDocumentState },
                    page: getAdjustedReportsPageNumber(this.props.RecycleReportDocuments.signatureFlowReportTableModel.count, ids.length, this.state.page, this.state.pageSize)
                });
                this.onPageReload();
            });
    }

    private onBulkRestoreReturns = () => {
        let query = this.getBulkOperationQuery();
        this.props.restoreBulkDocuments(query, () => {
            this.setState({
                restoreDocumentState: { ...initialRestoreDocumentState },
                page: 1
            });
            HideLoader();
            this.clearSelection();
        });
    }

    onCancelRestore = () => {
        this.setState({
            restoreDocumentState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false,
                showReminderEnabledWarning: false
            }
        });
    }

    getReminderSettings = (): IReminderSettings => {
        if (this.state.restoreDocumentState.selected) {
            if (this.state.restoreDocumentState.selected.length === 1 &&
                this.props.signingInfoSettingsData &&
                this.props.signingInfoSettingsData[this.state.restoreDocumentState.selected[0].signingId]) {
                return this.props.signingInfoSettingsData[this.state.restoreDocumentState.selected[0].signingId].signingInfoSettings.reminderSettings;
            }
        }
        return initialReminderSettings;
    }

    isRestoreDocModalLoading = () => {
        if (this.state.isBulkSelectionEnabled) {
            return false;
        }
        if (this.state.selectedRows.length > 1) {
            return this.props.signingInfoSettingsData.isLoading;
        }
        if (this.props.signingInfoSettingsData && this.state.restoreDocumentState.selected &&
            this.state.restoreDocumentState.selected.length > 0 &&
            this.props.signingInfoSettingsData[this.state.restoreDocumentState.selected[0].signingId]) {
            return this.props.signingInfoSettingsData[this.state.restoreDocumentState.selected[0].signingId].isLoading ||
                this.props.signingInfoSettingsData.isLoading;
        }
        return false;
    }

    //====================Restore Returns End Here===============================

    //=====================Show Canceled/Decliend Document=======================================================================
    private onCancelDocumentOpen = (rowIndex: number) => {
        let id = this.props.RecycleReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId;
        let signatureStatus = this.props.RecycleReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signatureStatus;

        this.setState({
            showLoader: true,
            cancelDocumentState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });
        this.props.requestCancelledDescription(id, signatureStatus, CommonPaths.DeclinedDescription,
            () => {
            this.setState({ showLoader: false });
        });
        logger.trackTrace("onCancelDocument with id" + JSON.stringify(id));
    }

    private onCancelDocumentCancel = () => {
        this.setState({
            cancelDocumentState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }


    //======================Cancel Document Ends Here============================================================


   
    private onDeleteDocumentsOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0 && !this.state.isBulkSelectionEnabled) {
            VenusNotifier.Warning("Please select document(s) to delete!", null);
            return;
        }

        bootbox.confirm({
            title: 'Permanent Delete',
            message: DeleteSignatureConstants.ConfirmRecycleDeletion,
            buttons: {
                cancel: {
                    label: 'Cancel',
                    className:'btn-white modal-footer-button-cancel'
                },
                confirm: {
                    label: 'Confirm',
                    className:'btn-info modal-footer-button-save'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.onDeleteSelectedReturns(ids)
                }
            }
        }).find(".modal-dialog")
        .addClass("modal-dialog-centered permanentDeleteModel ");
    }

    private onDeleteSelectedReturns(ids: number[]) {
        this.state.isBulkSelectionEnabled ? this.onBulkRecycledDeleteReturns() :
        this.props.deleteSignatureRecycledDocument(ids, () => {
            this.setState({
                page: getAdjustedReportsPageNumber(this.props.RecycleReportDocuments.signatureFlowReportTableModel.count, ids.length, this.state.page, this.state.pageSize)
            })
            this.onPageReload();
        });
    }

    private onBulkRecycledDeleteReturns() {
        let query = this.getBulkOperationQuery();
        this.props.deleteRecycledBulkDocuments(query, () => {
            this.setState({
                page: 1
            })
            HideLoader();
            this.clearSelection();
        });
    }
    //================== Delete Document End ==================================================


    //====================Recipient Details Starts Here===============================

    private onRecipientDetailsOpen = (rowIndex: number) => {
        this.setState({
            recipientDetailsState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });
    }
    //====================Recipient Details Ends Here===============================


    public render() {
        return <div className='user-assignment-content signatureflow-report'>
            {<div>
                <SignatureRecycledReportsHeader
                    screenId={ScreenIds.RecycleBin}
                    pageTitle={this.props.pageTitle}
                    onRetoreDocumentsOpen={this.onRestoreDocumentsOpen}
                    onDeleteDocuments={this.onDeleteDocumentsOpen}
                    selectedDocumentCount={this.selectedDocumentIds().length}
                    onPageReload={this.onPageReload}
                    isBulkSelectionEnabled={this.state.isBulkSelectionEnabled}
                />
                <br />

                <SignatureRecycleReportTable
                    ref='SignatureRecycleReportTable'
                    onSortChange={this.onSortChange}
                    onSearchChange={this.onSearchChange}
                    pageNo={this.state.page}
                    totalRows={this.props.RecycleReportDocuments.totalRowCount}
                    isLoading={this.props.RecycleReportDocuments.isLoading}
                    pageSize={this.state.pageSize}
                    onRowSelect={this.onRowSelect}
                    onSelectAll={this.onSelectAll}
                    selectedRows={this.state.selectedRows}
                    onSignersDetailOpen={this.onSignersDetailOpen}
                    onDocumentDetailsOpen={this.onDocumentDetailsOpen}
                    onDeleteDocumentsOpen={this.onDeleteDocumentsOpen}
                    onRetoreDocumentsOpen={this.onRestoreDocumentsOpen}
                    onCancelDocumentOpen={this.onCancelDocumentOpen}
                    signatureReportsData={this.props.RecycleReportDocuments.signatureFlowReportTableModel}
                    onRecipientDetailsOpen={this.onRecipientDetailsOpen}
                    onPageReload={this.onPageReload}
                    onFilterChange={this.onFilterChange}
                    showBulkSelectionMessage={this.state.showBulkSelectionMessage}
                    onBulkSelectionChange={this.onBulkSelectionChange}
                    officeLocation={Helper.setUserOfficeLocation(this.props.userOfficeLocationState.userLocation)}
                />

                <br />

                <div style={{height:'35px'}}>
                    <Pagination
                    totalCount={this.state.totalRecordsCount}
                    pageIndex={this.state.page}
                    pageSize={this.state.pageSize}
                    defaultPageSize={pageSize}
                    goToPage = {this.onPageChange}
                    sizePerPageList={SizePerPageList}
                    showSizePerPageList={true}
                    showGoToPages={true}
                    showpageCount={false}
                    ShowListAndGoto= {true} 
                    />
                </div>
            </div>}
            
            <SignatureFlowESignDetailsModal
                show={this.state.signerDetailsState.show}
                signatureReport={this.state.signerDetailsState.model as ISignatureFlowReportDocument}
                onCancel={this.onSignersDetailCancel}
                pageTitle={this.props.pageTitle}
            />
            <SignatureRecycledReportRecipientModal
                show={this.state.recipientDetailsState.show}
                signatureReport={this.state.recipientDetailsState.model as ISignatureFlowReportDocument}
                onCancel={this.onRecipientDetailsCancel}
            />

            <SignatureFlowReportDocumentModal
                show={this.state.documentDetailsState.show}
                signatureReport={this.state.documentDetailsState.model as ISignatureFlowReportDocument}
                onCancel={this.onDocumentDetailsCancel}
            />
            <SignatureFlowReportCancelDocument
                show={this.state.cancelDocumentState.show}
                showLoader={this.state.showLoader}
                selectedDocument={this.state.cancelDocumentState.model as ISignatureFlowReportDocument}
                onCancel={this.onCancelDocumentCancel}
            />

            <RestoreDocumentsModal
                isLoading={this.isRestoreDocModalLoading()}
                show={this.state.restoreDocumentState.show}
                selectedReports={this.state.restoreDocumentState.selected as ISignatureFlowReportDocument[]}
                filterSignatureStatus={this.state.filterSignatureStatus}
                onCancel={this.onCancelRestore}
                loadingText={this.props.signingInfoSettingsData.message}
                restoreDocuments={this.onConfirmRestoreSelectedReturns}
                showReminderEnabledWarning={this.state.restoreDocumentState.showReminderEnabledWarning}
                selectedDocReminderSettings={this.getReminderSettings()}
                isBulkSelectionEnabled={this.state.isBulkSelectionEnabled}
                />
            </div>
    }


}
