import { Action, ActionCreator, Reducer } from "redux";
import { IPasswordPolicySettings, initialPasswordPolicySettings } from "src/Core/ViewModels/Company/CompanySettingsViewModel";
import { SSSUITE_API_BASE_URL } from "src/utils/AppConstants";
import { actionTypes } from "../ActionTypes";
import { AppThunkAction } from "..";
import { addTask } from "domain-task";
import { logger } from "src/routes/LoggedIn";
import { validateError } from "src/SignatureFlow/components/helper/Validations";

export interface ISSSuiteCompanySettingsState {
    passwordPolicySettings: IPasswordPolicySettings;
}

export interface ReceivePasswordPolicySettings {
    type: actionTypes.RECEIVE_SUITE_PASSWORD_POLICY_SETTINGS,
    passwordPolicySettings : IPasswordPolicySettings;
}

type KnownAction =  ReceivePasswordPolicySettings;

export const actionCreators = {
    getPasswordPolicySettings: (callback?: () => void): AppThunkAction<KnownAction> => (dispatch) => {
        const fetchTask = fetch(`${SSSUITE_API_BASE_URL}/api/settings/password-policy`, { credentials: 'include' })
            .then(response => response.json() as Promise<IPasswordPolicySettings>)
            .then((data: IPasswordPolicySettings) => {
                dispatch({ type: actionTypes.RECEIVE_SUITE_PASSWORD_POLICY_SETTINGS, passwordPolicySettings: data });
                if(callback){
                    callback();
                }
            })
            .catch((error) =>{
                logger.trackError(`getPasswordPolicySettings failed with error ${validateError(error)}`);
            })
        addTask(fetchTask);
    },
}

const unloadedState: ISSSuiteCompanySettingsState = {
   passwordPolicySettings: initialPasswordPolicySettings
}

export const reducer: Reducer<ISSSuiteCompanySettingsState> = (state: ISSSuiteCompanySettingsState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case actionTypes.RECEIVE_SUITE_PASSWORD_POLICY_SETTINGS:
            const newState = { ...state };
            newState.passwordPolicySettings = action.passwordPolicySettings;
            return newState;
    }

    return state || unloadedState;
}