import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { API_BASE_URL } from '../../../utils/AppConstants';
import { AppThunkAction } from '..';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { handleResponse } from '../Library';
import { RequestSignatureFlowReportCustomColumns, ReceiveSignatureFlowReportCustomColumns } from './KnownTypes';
import { ISignatureFlowReportCustomColumn, ReportTypes } from '../../models/CustomColumn';
import { SignatureFlowReportCustomColumnConstants } from '../../components/helper/Constants';

import { logger } from '../../../routes/LoggedIn';

type knownAction = RequestSignatureFlowReportCustomColumns | ReceiveSignatureFlowReportCustomColumns | NotificationAction;

type dispatchAction = RequestSignatureFlowReportCustomColumns|ReceiveSignatureFlowReportCustomColumns;

export interface ISignatureFlowReportCustomColumnState {
    signatureCustomColumn: ISignatureFlowReportCustomColumn[];
    ArchivedCustomColumn: ISignatureFlowReportCustomColumn[];
    isLoading: boolean;
}

export const actionCreators = {

    requestSignatureFlowReportCustomColumn: (): AppThunkAction<knownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/ReportCustomColumn/', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_CUSTOM_COLUMN, customColumns: data, reportType:ReportTypes.Signature
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportCustomColumnConstants.StatusMessage.ErrorFetchingCustomColumns, statusType: StatusType.Error, statusCode:error?.status
                });
            })
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_SIGNATURE_FLOW_REPORT_CUSTOM_COLUMN });
    },
    requestSignatureArchiveReportCustomColumn: (): AppThunkAction<knownAction> => (dispatch, getState) => {
       
        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/ReportCustomColumn/GetArchiveCustomColumns', { credentials: 'include' })
            .then(handleResponse)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_CUSTOM_COLUMN, customColumns: data, reportType: ReportTypes.Archived
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportCustomColumnConstants.StatusMessage.ErrorFetchingCustomColumns, statusType: StatusType.Error, statusCode:error?.status
                });
            })
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_SIGNATURE_FLOW_REPORT_CUSTOM_COLUMN });
    },

    saveSignatureFlowReportCustomColumn: (customColumn: ISignatureFlowReportCustomColumn[], callback: () => void): AppThunkAction<knownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/ReportCustomColumn/SaveSignatureFlowReportCustomColumn', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(customColumn)
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_CUSTOM_COLUMN, customColumns: customColumn, reportType:ReportTypes.Signature });
                if (callback) {
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportCustomColumnConstants.StatusMessage.SavingCustomColumnsSuccess,
                    statusType: StatusType.Success
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportCustomColumnConstants.StatusMessage.ErrorSavingCustomColumns, statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`saveSignatureFlowReportCustomColumn failed for the request having parameters ${JSON.stringify(customColumn)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },
    saveSignatureArchiveReportCustomColumn: (customColumn: ISignatureFlowReportCustomColumn[], callback: () => void): AppThunkAction<knownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/ReportCustomColumn/SaveSignatureArchiveReportCustomColumn', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(customColumn)
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_CUSTOM_COLUMN, customColumns: customColumn, reportType: ReportTypes.Archived });
                if (callback) {
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportCustomColumnConstants.StatusMessage.SavingCustomColumnsSuccess,
                    statusType: StatusType.Success
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportCustomColumnConstants.StatusMessage.ErrorSavingCustomColumns, statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`saveSignatureArchiveReportCustomColumn failed for the request having parameters ${JSON.stringify(customColumn)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },
}

const unloadedState: ISignatureFlowReportCustomColumnState = {
    signatureCustomColumn: [],
    ArchivedCustomColumn: [],
    isLoading: true
} as ISignatureFlowReportCustomColumnState;

export const reducer: Reducer<ISignatureFlowReportCustomColumnState> = (state, incomingAction) => {
    const action = incomingAction as dispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_SIGNATURE_FLOW_REPORT_CUSTOM_COLUMN:
            return {
                ...unloadedState
            } as ISignatureFlowReportCustomColumnState;

        case actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_CUSTOM_COLUMN:
            const newState = { ...state };
            switch(action.reportType) {
                case ReportTypes.Signature:
                    newState.signatureCustomColumn = action.customColumns;
                    break;
                case ReportTypes.Archived:
                    newState.ArchivedCustomColumn = action.customColumns;
                    break;
            }
            return {...newState, isLoading: false};
    }

    return state || unloadedState;
};