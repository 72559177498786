import { API_BASE_URL } from 'src/utils/AppConstants'
import { handleResponse } from '../SignatureFlow/store/Library'

export function IsAdminUser(user: any) {
    return user?.profile?.role?.split(',')?.includes('Admin') || false
}

export function IsOnlySignatureUser(user: any) {
    return user?.profile?.role === 'Signatures'
}

export function IsAlsoSignatureUser(user: any) {
    return user?.profile?.role?.split(',')?.includes('Signatures') || false
}


export const getIsSFProductEnabled = async () => {
    const response = await fetch(API_BASE_URL + 'api/Company/GetSFProductStatusAsync', { credentials: 'include' })
    return handleResponse(response)
}
