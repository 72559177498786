import { addTask } from 'domain-task';
import { API_BASE_URL } from '../../utils/AppConstants';
import { AppThunkAction } from '../store';
import { actionTypes } from '../store/ActionTypes';
import { handleResponse } from './Library';
import { NotificationAction, StatusType } from '../store/common/NotificationStore';
import { SFBulkOperationQuery, SFBulkRecycledOperationQuery } from '../models/SFBulkOperationQuery';
import { BulkOperationMessage, SignatureFlowReportConstants } from '../components/helper/Constants';
import * as SignatureReportStore from './ReportsStore/DeliveredReport/DeliveredReportsStore';
import { IReminderSettings } from '../models/SignatureFlowSettings';
import { IDownloadableDocuments } from '../models/SignatureFlowReportState';
import { logger } from 'src/routes/LoggedIn';

type AllKnownAction = NotificationAction;

export const actionCreators = {
    deleteBulkDocuments: (query: SFBulkOperationQuery, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/BulkOperations/DeleteBulkSFReturnsAsync', {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: BulkOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                let resetReportsAction: any = SignatureReportStore.actionCreators.resetSignatureFlowReportsDocumentPagesState();
                dispatch(resetReportsAction);
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: BulkOperationMessage.DeleteError,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },
    deleteRecycledBulkDocuments: (query: SFBulkRecycledOperationQuery, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/BulkOperations/DeleteRecycledBulkSFReturnsAsync', {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: BulkOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                let resetReportsAction: any = SignatureReportStore.actionCreators.resetSignatureFlowReportsDocumentPagesState();
                dispatch(resetReportsAction);
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: BulkOperationMessage.DeleteError,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },
    archiveBulkDocuments: (query: SFBulkOperationQuery, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/BulkOperations/ArchiveBulkSFReturnsAsync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: BulkOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                let resetReportsAction: any = SignatureReportStore.actionCreators.resetSignatureFlowReportsDocumentPagesState();
                dispatch(resetReportsAction);
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: BulkOperationMessage.ArchiveError,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },
    restoreBulkDocuments: (query: SFBulkRecycledOperationQuery, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/BulkOperations/RestoreBulkSFReturnsAsync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: BulkOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                let resetReportsAction: any = SignatureReportStore.actionCreators.resetSignatureFlowReportsDocumentPagesState();
                dispatch(resetReportsAction);
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                if (error.statusText != null && error.statusText.trim() == "One or more return(s) expired") {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: BulkOperationMessage.ReturnExpiredOnRestore,
                        statusType: StatusType.Warning
                    });
                    if (callback) {
                        callback();
                    }
                }
                else {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: BulkOperationMessage.RestoreError,
                        statusType: StatusType.Error, statusCode:error?.status
                    });
                    if (callback) {
                        callback();
                    }
                }
            });
        addTask(fetchTask);
    },
    sendBulkReminders: (query: SFBulkOperationQuery, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/BulkOperations/SendBulkSignatureRemindersAsync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: BulkOperationMessage.SendReminderWaitProcessing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: BulkOperationMessage.SendReminderError,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },
    updateBulkReminderSettings: (newSettings: IReminderSettings, query: SFBulkOperationQuery, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/BulkOperations/UpdateBulkReminderSettingsAsync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({ data: query, reminderSettings: newSettings}),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: BulkOperationMessage.UpdateReminderWaitProcessing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: BulkOperationMessage.UpdateReminderSettingError,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },
    downloadLater: (downloadableDocuments: IDownloadableDocuments[], fileDownloadName: string, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/BulkOperations/Download?fileName='+fileDownloadName, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify({
                downloadableDocuments: downloadableDocuments,
                fileName: fileDownloadName
            })
            }).then((response) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.DownloadDocument.DownloadLaterSuccess,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.DownloadDocument.Error,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                if (callback) {
                    callback();
                }
                logger.trackError(`downloadLater failed for the request having parameters ${JSON.stringify(downloadableDocuments)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },
    downloadLaterBulk: (query: SFBulkOperationQuery, fileDownloadName: string, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/BulkOperations/DownloadWithFilters?fileName='+fileDownloadName, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(query)
            }).then((response) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.DownloadDocument.DownloadLaterSuccess,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.DownloadDocument.Error,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                if (callback) {
                    callback();
                }
                logger.trackError(`downloadLaterBulk failed for the request having parameters ${JSON.stringify(query)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },
    changeBulkOfficeLocation: (query: SFBulkOperationQuery,locationId: number, contactPersonId: number, callback: (status:string) => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/SignatureFlow/BulkOperations/ChangeBulkOfficeLocationAsync?locationId=${locationId}&contactPersonId=${contactPersonId}`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: BulkOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                let resetReportsAction: any = SignatureReportStore.actionCreators.resetSignatureFlowReportsDocumentPagesState();
                dispatch(resetReportsAction);
                if (callback) {
                    callback("success");
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: BulkOperationMessage.ChangeOfficeLocationError,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                if (callback) {
                    callback("error");
                }
            });
        addTask(fetchTask);
    },
}