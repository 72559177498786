import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SignatureControlType } from './../models/SignatureData';
import { GripVertical } from './svg/CustomSvgIcons';

export interface ISignatureControlItemProps {
    caption: string,
    controlType: SignatureControlType,
    helpText: string,
    dragStart?: (event: any, controlType: SignatureControlType) => void;
    dragEnd?: (event: any) => void;
}

export class SignatureControlItems extends React.Component<ISignatureControlItemProps, any>{
    public render() {
        return <Row className="marB15">
            <Col sm={12}>
                {
                    <div
                        className="icon-div col-xs-12 fontSize16"
                        data-test-auto="dd8fe022-8c43-45b3-a7c1-efd55bc97814"
                        draggable={true}
                        onDragStart={(event) => { this.props.dragStart && this.props.dragStart(event, this.props.controlType) }}
                        onDragEnd={(event) => { this.props.dragEnd && this.props.dragEnd(event) }}
                    >
                        <span style={{paddingRight:'10px'}}><GripVertical /></span>
                        {this.props.caption}
                    </div>
                }
            </Col>            
        </Row>
    }
}
