import React, { useState } from 'react';
import { ClientInstructionMessageVariables } from '../../helper/Constants';
import { copyToClipboard } from "../../helper/HelperFunctions";
import { Card, Accordion } from 'react-bootstrap';

export interface IVariableListData {
    variable: string;
    helpText: string;
}

const VariableList: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);

    const getVariableList = (): IVariableListData[] => {
        return [
            { variable: "CLIENTID", helpText: ClientInstructionMessageVariables.ClientId },
            { variable: "CLIENTNAME", helpText: ClientInstructionMessageVariables.ClientName },
            { variable: "COMPANYNAME", helpText: ClientInstructionMessageVariables.CompanyName },
            { variable: "CONTACTPERSON", helpText: ClientInstructionMessageVariables.ContactPerson }
        ];
    };

    const icon = open ? "fas fa-minus" : "fas fa-plus";
    const text = open ? "Variables List" : "Show Variables List";

    return (
        <Accordion>
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0" style={{ padding: "10px" }} onClick={() => setOpen(!open)}>
                    <Card.Title as="h3" style={{ marginBottom: '0px' }}>
                        <i className={icon}></i> <a>{text}</a>
                    </Card.Title>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='0'>
                    <Card.Body style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                        <ul>
                            {getVariableList().map((item, index) => {
                                const value = `<${item.variable}>`;
                                return (
                                    <div className="row" key={index}>
                                        <div className="col-md-4">
                                            <li className="copy">
                                                {value}
                                                <span
                                                    onClick={() => copyToClipboard(item.variable)}
                                                    title="Copy"
                                                    className="far fa-copy copyIcon"
                                                    style={{ fontSize: "16px", paddingLeft: "14px", cursor: "pointer" }}>
                                                </span>
                                            </li>
                                        </div>
                                        <div className="col-md-8">{item.helpText}</div>
                                    </div>
                                );
                            })}
                        </ul>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

export default VariableList;
