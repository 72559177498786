import * as React from 'react';
import Select from 'react-select';

export interface CustomSingleSelectProps {
  options:{label: string;    value: string;}[],
  filterHandler: (value: string) => void,
  placeholder: string,
  onRef?: (ref: any) => any
}

export class CustomSingleSelect extends React.Component<CustomSingleSelectProps, any>{
    
	constructor(props: any) {
		super(props);
		this.state = {
			selectedOption: "-1",
			options: []
		}

		this.applyFilter = this.applyFilter.bind(this);
		this.cleanFilteredValue = this.cleanFilteredValue.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.dropDownDataCreation = this.dropDownDataCreation.bind(this);
	}
	
	
	componentDidMount(){
        this.dropDownDataCreation();
	}

    // method prevents the value of Tax year dropdown from getting over ridden during update --- change if problem arises
	componentDidUpdate(prevProps: any) {
		if (this.props.options !== prevProps.options) {
			this.dropDownDataCreation();
		}
	}


	public cleanFilteredValue(){
		this.setState({
			selectedOption: "-1"
		},()=> {this.props.filterHandler(undefined)})
	}

	private handleSelectChange= (value: any) => {	
       if(!value){
		this.setState({
			selectedOption: "-1"
		},()=> {this.props.filterHandler(undefined)});
          return;
       };

       this.setState({
		selectedOption: value
	},()=> {this.props.filterHandler(value.value.toString())})
	}

    public  applyFilter = (value: any)=> {
		this.setState({
			selectedOption: value
		},()=> {this.props.filterHandler(value)})
	}

    private dropDownDataCreation = () =>{
		this.props.onRef(this);
		var dropDownData: any = [];

		this.props.options.map((data: any) => {
			dropDownData.push(data);
		});
		this.setState({ options: dropDownData });
    }

    private createCustomOption = (props: any) => {
		return (
			<div 
				className={this.state.selectedOption.label === props.children ? "selected" : ""}
				style={{ paddingLeft: '5px', verticalAlign: 'middle' , width: "auto", padding: "7px 0 1px 0" }} onMouseDown={(e) => {
				e.preventDefault();
				e.stopPropagation();
				props.onSelect(props.option, e);
			}}
				onMouseEnter={(e) => { props.onFocus(props.option, e); }}
				onMouseMove={(e) => {
					if (props.isFocused) return;
					props.onFocus(props.option, e);
				}} >
				<span className="custom-singleselect-status">{props.children}</span>
			</div>
		)
	}
	render() {
		return (
			<Select
				options={this.state.options}
                className={"filter select-filter custom-single-select-container"}
				onChange={(e: any) => { this.handleSelectChange(e); }}
				arrowRenderer={()=>this.state.selectedOption =="-1" ? <span className="Select-arrow" data-test-auto="1BAA181A-4995-44D1-A3DB-EA18A5266F8D"></span> : null}
                value={this.state.selectedOption}
                clearRenderer={() =>this.state.selectedOption =="-1" ? null :<span className="minusClass"> <strong style={{fontSize:"14px"}}>x</strong> </span>}
				placeholder={<span className="filter select-filter placeholder-selected" style={{lineHeight:'2'}}>{this.props.placeholder}</span>}
                clearValueText=''
                clearable={true}
				searchable={false}
                scrollMenuIntoView={false}
                optionComponent={this.createCustomOption}
			/>
		)}
};

export default CustomSingleSelect;