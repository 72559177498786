import * as React from 'react';
import * as SignatureFlowSettingsStore from '../../../store/SignatureFlowSettingsStore';
import * as SignatureFlowAuthenticationQuestionsStore from '../../../store/settings/AuthenticationQuestions/AuthenticationQuestionsStore';
import { RouteComponentProps } from 'react-router';
import { ResourceIdConstants, ScreenIds } from '../../helper/RBACConstants';
import { useEffect, useState } from 'react';
import { GreaterThanSvg } from '../../svg/CustomSvgIcons';
import { DefaultAccessOptionsModal } from './DefaultAccessOptionsModal';
import SignerDelegationSection from './SignerDelegationSection';
import SecuritySettingsModal from './SecuritySettingsModal';


type ISecuritySettingsProps = {
    signatureFlowSettingsData: SignatureFlowSettingsStore.ISignatureFlowSettingsData,
    authenticationQuestionsData: SignatureFlowAuthenticationQuestionsStore.IAuthenticationQuestionsData,
}
& typeof SignatureFlowSettingsStore.actionCreators
& typeof SignatureFlowAuthenticationQuestionsStore.actionCreators
& RouteComponentProps<{}>;

export const SecuritySettings:React.FC <ISecuritySettingsProps> = (props) => {
    useEffect(() => {
        props.requestCompanySignatureSettings();
        props.requestSignatureFlowSignerDelegation();
        props.requestAuthenticationQuestions();

    }, []);
   

    return  <div className='font14 security-settings'>
                <div className="row" style={{ marginLeft: 0, marginBottom:'20px' }}>
                    <span className='fontWeight700 text-primary'>Settings</span> 
                    <span className='padL08'><GreaterThanSvg /></span>
                    <span className='padL08'>Security</span>
                </div>
                <div className="row" style={{ marginLeft: 0, marginBottom:'25px' }}>
                    <div className="col-sm-10 padL00 float-left">
                        <h2 style={{fontSize: '20px'}}  className='fontWeight700'>Security</h2>
                    </div>
                </div>

                <DefaultAccessOptionsModal 
                    resourceId={`${ScreenIds.Settings}${ResourceIdConstants.GeneralSettings}`}
                    signatureFlowSettings={props.signatureFlowSettingsData.signatureFlowSettings}
                    updateCompanySettings={props.updateCompanySettings} />
                
                <hr/>
            <div>
                    <SecuritySettingsModal
                        resourceId={`${ScreenIds.Settings}${ResourceIdConstants.GeneralSettings}`}
                        signatureFlowSettings={props.signatureFlowSettingsData.signatureFlowSettings}
                        updateCompanySettings={props.updateCompanySettings}
                        authenticationQuestions={props.authenticationQuestionsData.authenticationQuestions}
                        deleteAuthenticationQuestion={props.deleteAuthenticationQuestion}
                        saveAuthenticationQuestion={props.saveAuthenticationQuestion}
                        updateAuthenticationQuestion={props.updateAuthenticationQuestion}
                    />
            </div>
            <hr/>
            <SignerDelegationSection
                    resourceId={`${ScreenIds.Settings}${ResourceIdConstants.GeneralSettings}`} />
            </div>
}