import * as React from 'react';
import { Popover, OverlayTrigger, Modal, Button, ListGroup, Row, Col, Table } from 'react-bootstrap';
import 'isomorphic-fetch';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { Guid } from 'src/Core/Utilities/Guid';
import * as MyDownloadsStore from '../../../store/MyDownloadsStore';
import { DeleteSvg, DownloadHeaderIcon, InfoIcon, TextFileSvg } from '../../svg/CustomSvgIcons';

let moment = require('moment');
export interface IDownloadedZipFilesModel {
    count: number;
    bulkDownloadId: number;
    fileName: string;
    createdOn: any;
    fileSize: any;
    filePath: string;
    fileGuid: Guid;
    requestedDocumentsCount: number;
    downloadedDocumentsCount: number;
    userId: number;
    status: ZipDownloadStatus;
    isDeleted: boolean;
}


export enum ZipDownloadStatus {
    None = 0,
    InProgress = 1,
    Ready = 2,
    Error = 3,
    Downloaded = 4,
    Expired = 5
}

interface MyDownloadsProps{
    showState: boolean;
    showLoader: boolean;
    onHide: ()=> void;
    fetchMyDownloads: (pageNo: number) => void;
    downloadMyDownloads: (bulkDownloadsId: number, documentGuid: string, filePath: string, fileName: string) => void;
    downloadAllMyDownloads: (bulkdownloads: IDownloadedZipFilesModel[]) => void;
    deleteMyDownloads: (bulkDownloadsId: number, documentGuid: string, filePath: string) => void;
    deleteAllMyDownloads: () => void;
    myDownloads: MyDownloadsStore.IMyDownloadsState
}

export class MyDownloads extends React.Component<MyDownloadsProps>  {
    constructor(props: MyDownloadsProps) {
        super(props); 
        this.DownloadAllMyDownloads = this.DownloadAllMyDownloads.bind(this);
    }
    apiCalled: boolean = false;

    componentWillReceiveProps(nextProps: Readonly<MyDownloadsProps>, nextContext: any): void {
        if(!this.props.showState){
            this.apiCalled = false;
        }
        if(nextProps.showState && !nextProps.myDownloads.isBulkDownloadsLoading && !this.apiCalled){
            this.apiCalled = true;
            this.props.fetchMyDownloads(1);
        }
    }
    private getDateString(dateString:string){
        return moment.utc(dateString)
                    .local()
                    .format("DD/MM/YYYY hh:mm a")
                    .toString()
                    .toUpperCase();
    }

    private getFileSizeString(bytes: number){
        let sizeString = '';
        if(bytes < 1024){
            sizeString = bytes +' B';
        }else if((bytes=(bytes/1024))<1024){
            sizeString = parseFloat(bytes.toString()).toFixed(2)+' KB';
        }else if ((bytes=(bytes/1024)) < 1024){
            sizeString = parseFloat(bytes.toString()).toFixed(2) + ' MB';
        }else {
            sizeString = parseFloat((bytes/1024).toString()).toFixed(2) + ' GB';
        }
        return sizeString;
    }


    private DownloadZip(bulkDownloadId: number, fileGuid: Guid, fileName: string, filePath: string){
        this.props.downloadMyDownloads(bulkDownloadId, fileGuid.toString(), filePath, fileName);
    }

    private DownloadAllMyDownloads(){
        let activeMyDownloads = this.props.myDownloads.bulkDownloads.filter(ele=>(ele.status != ZipDownloadStatus.InProgress));
        if(activeMyDownloads.length>0){
            this.props.downloadAllMyDownloads(activeMyDownloads);
        }
    }
    

    public render() {

        let modalBody =
            <div className="padR30" style={{textAlign:'center', paddingTop:'130px'}}>
                <span>No Data</span>
            </div>;
        let regularFooter =
            <Modal.Footer>
                <Button variant='default'
                        onClick={this.props.deleteAllMyDownloads}
                        className='btn-white modal-footer-button-cancel'>
                            Clear All
                </Button>
                <Button 
                        onClick={this.DownloadAllMyDownloads}
                        className='btn btn-info modal-footer-button-save'
                        variant="info">
                            Download All
                </Button>
            </Modal.Footer>;
        let emptyFooter = 
            <Modal.Footer>
                <Button variant='default'
                        onClick={this.props.onHide}
                        className='btn-white modal-footer-button-cancel'>
                            Close
                </Button>
            </Modal.Footer>;

        return (
            <Modal className="download-options-modal" show={this.props.showState} onHide={this.props.onHide}>
                <Modal.Header closeButton >
                    <Modal.Title className='custom-modal-header'>
                    My Downloads
                    </Modal.Title>
                </Modal.Header>
                    <Modal.Body style={{maxHeight: 'calc(100vh - 250px)', overflowY: 'auto'}}>
                        <LoadingOverlay style={{ height: '100%', minHeight:'300px' }} >
                            
                            <div>
                                {!this.props.showLoader && this.props.myDownloads.bulkDownloadsCount==0 && modalBody}
                                {!this.props.showLoader && this.props.myDownloads.bulkDownloadsCount>0 && this.props.myDownloads.bulkDownloads.map((element,i) => {

                                    let filesCount = (element.status == ZipDownloadStatus.InProgress)?element.requestedDocumentsCount:element.downloadedDocumentsCount;
                                    let popover = (<Popover id="mydownloads-popover">
                                                    <div style={{padding:"16px", fontSize:"14px", fontWeight:"400"}}>
                                                        <span>
                                                        {filesCount} files
                                                        </span>
                                                    </div>                                                    
                                                </Popover>);
                                    let rowData = (<Row>
                                            <Col lg={1} md={1} sm={1}>
                                                <TextFileSvg/>
                                            </Col>
                                            <Col lg={8} md={8} sm={8}>
                                                <Row style={{fontWeight:'600'}}>
                                                    <span>
                                                        E-Signed
                                                    </span>
                                                    
                                                    <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
                                                        <a className="my-downloads-info-icon marL07">
                                                            <InfoIcon 
                                                                className="cursor-pointer" 
                                                                style={{height:'16px', width:'16px', color: '#898D91', marginBottom:'4px'}}/>
                                                        </a>
                                                    </OverlayTrigger>
                                                </Row>
                                                <Row style={{color:'gray', fontSize:'14px'}}>
                                                    <span>
                                                        {this.getDateString(element.createdOn)} - {this.getFileSizeString(element.fileSize)}
                                                    </span>
                                                </Row>
                                            </Col>
                                            <Col lg={3} md={3} sm={3}>
                                                <span
                                                    onClick={()=> (element.status != ZipDownloadStatus.InProgress) 
                                                                    && this.DownloadZip(element.bulkDownloadId, element.fileGuid, element.fileName, element.filePath)}
                                                    className={element.status == ZipDownloadStatus.InProgress?'btn disabled':'btn'}
                                                    data-bulk-download-id = {element.bulkDownloadId}
                                                    title="Download"
                                                    style={{marginRight:'20px'}}>
                                                    <DownloadHeaderIcon />
                                                </span>
                                                <span
                                                    onClick={()=> (element.status != ZipDownloadStatus.InProgress) 
                                                                    && this.props.deleteMyDownloads(element.bulkDownloadId, element.fileGuid.toString(), element.filePath)}
                                                    className={element.status == ZipDownloadStatus.InProgress?'btn disabled':'btn'}
                                                    title="Delete"
                                                    style={{display:'contents'}}>
                                                    <DeleteSvg />
                                                </span>


                                            </Col>
                                        </Row>)

                                        if(i>0){
                                            return (
                                            <li style={{listStyle:'none', paddingBottom:'10px', paddingTop:'10px', borderTop:'1px solid lightgray' }}>
                                                {rowData}
                                            </li>)
                                        }else{
                                            return (
                                                <li style={{listStyle:'none', paddingBottom:'10px'}}>
                                                    {rowData}
                                                </li>)
                                        }
                                })}

                            </div>
                        
                            <Loader loading={this.props.showLoader} text={"Please wait..."} />
                        </LoadingOverlay>
                    </Modal.Body>
                    {this.props.showLoader || this.props.myDownloads.bulkDownloadsCount==0?emptyFooter:regularFooter}
                    

                
            </Modal>
        )
    }
}
