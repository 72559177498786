import {  addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../..';
import { actionTypes } from '../../ActionTypes';
import { API_BASE_URL } from '../../../../utils/AppConstants';
import { StatusType, NotificationAction } from '../../common/NotificationStore';
import { handleResponse } from '../../Library';
import {
    RequestLockedReportDocuments, ReceiveLockedReportDocuments,ResetSignatureFlowReportDocumentPagesAction,RequestSignatureFlowReportDocumentPagesAction,
    ReceiveSignatureFlowReportDocumentPagesAction,ReceiveSignatureFlowReportDocumentPopupModal,ResetLockedReportDocuments
} from '../../ReportsCommon/KnownTypes';
import { ISignatureFlowReportTableModel, ISignatureFlowReportState } from '../../../models/SignatureFlowReportTableState';
import { ResetSigningInfoSettingsAction } from '../../SigningInfoSettingsStore';
import { validateError } from '../../../components/helper/Validations';

import { logger } from '../../../../routes/LoggedIn';

type KnownAction = 
    RequestLockedReportDocuments | 
    ReceiveLockedReportDocuments |
    NotificationAction |
    ResetSigningInfoSettingsAction |
    ResetSignatureFlowReportDocumentPagesAction |
    RequestSignatureFlowReportDocumentPagesAction |
    ReceiveSignatureFlowReportDocumentPagesAction |
    ReceiveSignatureFlowReportDocumentPopupModal;

type dispatchAction =
    RequestLockedReportDocuments |
    ReceiveLockedReportDocuments |
    ResetLockedReportDocuments;

export const actionCreators = {
    requestSignatureLockedDocuments: (query: string, reload: boolean = false, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        if (reload || query !== state.LockedReportDocuments.query) {
            let page = state.signatureFlowReportsDocumentPages[query];
            if (!reload && page) {
                dispatch({ type: actionTypes.REQUEST_LOCKED_REPORT_DOCUMENTS, query: query });
                dispatch({ type: actionTypes.RECEIVE_LOCKED_REPORT_DOCUMENTS, query: query, table: page.signatureFlowReportTableModel });
                return;
            }
            const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReports/GetSignatureLockedReports' + query, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<ISignatureFlowReportTableModel>)
                .then(data => {
                    if (callback) {
                        callback();
                    }
                    let updatedState = getState();
                    if(updatedState.LockedReportDocuments.query == query){ //This condition is added whatever the latest query (call) is, it should be updated in the store.
                        dispatch({ type: actionTypes.RECEIVE_LOCKED_REPORT_DOCUMENTS, query: query, table: data });
                        dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES, query: query, table: data, totalRowCount: data.count });
                        dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_POPUP_MODAL, signatureFlowReportDetails: data.documents });                       
                        }
                    })
                .catch((error) => {
                    const message = validateError(error);
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: message, statusType: StatusType.Error, statusCode:error?.status });

                    logger.trackError(`requestSignatureLockedDocuments failed for the request having query ${query} with error ${message}`)
                });
            
            addTask(fetchTask);

            dispatch({ type: actionTypes.REQUEST_LOCKED_REPORT_DOCUMENTS, query: query });
            dispatch({ type: actionTypes.REQUEST_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES, query: query });
        }
    }
}

const unloadedState: ISignatureFlowReportState = {
    query: '',
    signatureFlowReportTableModel: {
        documents: [],
        count: 0
    } as ISignatureFlowReportTableModel,
    totalRowCount: 0,
    isLoading: false
} as ISignatureFlowReportState

export const reducer: Reducer<ISignatureFlowReportState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as dispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_LOCKED_REPORT_DOCUMENTS:
            return {
                ...unloadedState,
                query: action.query,
                isLoading: true
            } as ISignatureFlowReportState;

        case actionTypes.RECEIVE_LOCKED_REPORT_DOCUMENTS:
            return {
                query: action.query,
                signatureFlowReportTableModel: action.table,
                totalRowCount: action.table.count,
                isLoading: false
            } as ISignatureFlowReportState;

        case actionTypes.RESET_LOCKED_REPORT_DOCUMENTS:
            return clearSignatureLockedReports(state);

        default: const exhaustiveCheck: never = action;
    }
    return state;
};

function clearSignatureLockedReports(state: ISignatureFlowReportState): ISignatureFlowReportState {
    return {
        ...unloadedState,
        query: state.query,
        isLoading: false
    } as ISignatureFlowReportState;
}


