import * as React from 'react';
import { ISignatureControlBoxItems } from './SignatureControlPanel';
import { SignatureControlType } from './../models/SignatureData';
import { SignatureControlItems } from './SignatureControlItems';

export interface ISignatureControlBoxProps {
    controlBoxItems: ISignatureControlBoxItems[];
    dragStart?: (event: any, controlType: SignatureControlType) => void;
    dragEnd?: (event: any) => void;
}
export class SignatureControlBox extends React.Component<ISignatureControlBoxProps, any>{
    public render() {
        return (<div className='signature-controls'>
            {
                this.props.controlBoxItems.map((controlBoxItem, Index) => {
                    return <SignatureControlItems
                        key={Index}
                        caption={controlBoxItem.caption}
                        controlType={controlBoxItem.controlType}
                        helpText={controlBoxItem.helpText}
                        dragStart={(event, controlType) => {
                            this.props.dragStart && this.props.dragStart(event, controlType)
                        }}
                        dragEnd={this.props.dragEnd }
                    />
                })
            }
        </div>);
    }
}