import { IPdfDocumentFacade } from '../../Core/Utilities/PdfDocumentFacade';
import { UploadStatus } from '../../Core/ViewModels/Common/UploadDocumentViewModel';
import { ISignatureData, ISignatureControl, SignatureControlType } from './SignatureData';
import { IClient } from './SigningInfo';

export interface DocumentUploadData {
    number?: number,
    name?: string,
    progressBar?: number,
    size?: string,
    status?: UploadStatus,
    clientId?: string,
    uuid?: string
    sasGuid?: string,
    gridRowDisable: boolean,
    rowIndex?: number,
    file?: any,
    documentType: number,
    documentTypeName?: string;
    expireon?: Date,
    inProcess?: boolean
    sourceFileGuid: string | null;
}
export interface IDocumentInfo {
    id: number;
    documentGuid: string;
    signingId: number;
    documentTypeId: number;
    name: string;
    expiresOn: Date;
    sourceFileGuid: string | null;
    signatureData: ISignatureData[];
    size:string;
    sasUrl?:string;
}

export interface IDocumentData {
    documentGuid: string;
    fileName: string;
    pdf: IPdfDocumentFacade;
    sasUrl: string;
    isPDFloaded: boolean;
    sourceFileGuid: string | null;
    fileType: FileType;
}

export interface IDocumentFileData{
    upload: FileUploadData        
    status: string,
    accepted: boolean,
    encrypted: boolean,
    lastModified: number,
    name: string
    size: number
    type: string
}

export interface FileUploadData{    
    uuid: string,
    progress: number,
    total: number,
    bytesSent: number,
    filename: string,
    chunked: boolean,
    totalChunkCount: number
}

export enum FileType {
    undefined = 0,
    pdf = 1,
    word = 2,
    csv = 3
}

export function getSignersWithoutSignatureControl(documentInfo: IDocumentInfo[], allSigners: IClient[]): IClient[] {
    let signersWithoutSignControls: IClient[] = [...allSigners];
    documentInfo.forEach((docInfo: IDocumentInfo, index1: number) => {
        docInfo.signatureData ? docInfo.signatureData.forEach((signData: ISignatureData, index2: number) => {
            signData.signatureControls.forEach((value: ISignatureControl, index3: number) => {
                if (value.type === SignatureControlType.Signature) {
                    let index: number = signersWithoutSignControls
                        .findIndex((signer: IClient, index: number) => signer.clientGuid.toString() === value.signer);
                    if (index !== -1) {
                        signersWithoutSignControls.splice(index, 1);
                    }
                }
            });
        }) : null;
    });

    return signersWithoutSignControls;
}