import * as React from 'react';
import { Col, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { IUserModel } from './../../../Core/ViewModels/User/UserViewModel'
import * as Helper from '../../components/helper/HelperFunctions';
import { VenusNotifier } from '../../components/helper/VenusNotifier';
import { GripVertical } from '../svg/CustomSvgIcons';

const isEqual = require("react-fast-compare");

interface ISignatureFlowDelegationListProps {
    usersAllowedToDelegate: number[];
    allUserList: IUserModel[];
    usersToIgnore?: number[];
    modifyDelegateUsers: (usersAllowedToDelegate: number[]) => void;
}

interface ISignatureFlowDelegationListState {
    delegates: IUserModel[];
    allUsers: IUserModel[];
    leftSelect: IUserModel[];
    lastLeft: number;
    rightSelect: IUserModel[];
    lastRight: number;
}

export class SignatureFlowDelegationList extends React.Component<ISignatureFlowDelegationListProps, ISignatureFlowDelegationListState>{
    state: ISignatureFlowDelegationListState = {
        delegates: [],
        allUsers: [],
        leftSelect: [],
        lastLeft: 0,
        rightSelect: [],
        lastRight: 0
    };
    
    //These are Ids of current user and delegated user list component
    private currentUsersId : string  =  "signature-flow-current-users";   
    private delegatedUsersId : string =  "sender-delegation-selected-users";  

 componentDidMount() {
        this.setDelegatedUsers(this.props.usersAllowedToDelegate);
    }

    private setDelegatedUsers = (usersAllowedToDelegate: number[]) => {
        const delegatedUsers = Helper.selectUsers(this.props.allUserList, usersAllowedToDelegate);

        let allUsers = this.filterLeft(this.props.allUserList, delegatedUsers);
        if (this.props.usersToIgnore) {
            const usersToIgnore = Helper.selectUsers(this.props.allUserList, this.props.usersToIgnore);
            allUsers = this.filterLeft(allUsers, usersToIgnore);
        }

        this.setState({
            delegates: delegatedUsers,
            allUsers: allUsers
        });
    }
   
    private onSelectFromLeft = (selected: IUserModel, event: any) => {
        if(Helper.isWithinElement(event, this.currentUsersId)) {
           let leftSelect = Helper.handleSelect(selected, event, this.state.leftSelect, this.state.allUsers, this.state.lastLeft);
           this.setState({ leftSelect: leftSelect, lastLeft: selected.userId });
        }
    }

    private onSelectFromRight = (selected: IUserModel, event: any) => {
        if(Helper.isWithinElement(event,this.delegatedUsersId)) {
           let rightSelect = Helper.handleSelect(selected, event, this.state.rightSelect, this.state.delegates, this.state.lastRight);
           this.setState({ rightSelect: rightSelect, lastRight: selected.userId });
        }
    }

    private onDoubleClickLeft = (selected: IUserModel, callback: any) => {
        let leftSelect = Helper.handleSelect(selected, event, this.state.leftSelect, this.state.allUsers, this.state.lastLeft);
        this.setState({ leftSelect: leftSelect, lastLeft: selected.userId });

        if (callback)
            callback();
    }

    private onDoubleClickRight = (selected: IUserModel, callback: any) => {
        let rightSelect = Helper.handleSelect(selected, event, this.state.rightSelect, this.state.delegates, this.state.lastRight);
        this.setState({ rightSelect: rightSelect, lastRight: selected.userId });

        if (callback)
            callback();
    }

    private moveRight = (event: any) => {
        if (this.state.leftSelect.length > 0) {
            let right = [...this.state.delegates];
            this.state.leftSelect.map((u, i) => {
                right.push(u);
            });
            this.setState({
                allUsers: this.filterLeft(this.props.allUserList, right),
                delegates: right,
                leftSelect: [],
                rightSelect: [],
                lastLeft: 0,
                lastRight: 0
            });

            this.props.modifyDelegateUsers(right.map(user => user.userId));

        } else {
            VenusNotifier.Warning("Please select a users to add!", "Select Users");
        }
        event && event.preventDefault();
    }

    private moveLeft = (event: any) => {
        if (this.state.rightSelect.length > 0) {

            let right = [...this.state.delegates];
            this.state.rightSelect.map((u, i) => {
                Helper.removeItem(u, right);
            });
            this.setState({
                allUsers: this.filterLeft(this.props.allUserList, right),
                delegates: right,
                leftSelect: [],
                rightSelect: [],
                lastLeft: 0,
                lastRight: 0
            });

            this.props.modifyDelegateUsers(right.map(user => user.userId));

        } else {
            VenusNotifier.Warning("Please select a users to remove!", "Unselect Users");
        }
        event && event.preventDefault();
    }

    private DragToRight(){
        let left = [...this.state.allUsers];
        let right = [...this.state.delegates];
        right = [...right,...this.state.leftSelect];
       left = left.filter(u => !right.includes(u));
           this.setState({
            allUsers: left,
            delegates: right,
            leftSelect: [],
            rightSelect: [],
            lastLeft:0,
            lastRight:0
        });
        this.props.modifyDelegateUsers(right.map(user => user.userId));
    }

    private DragToLeft(){
        let left = [...this.state.allUsers];
        let right = [...this.state.delegates];
        left = [...left,...this.state.rightSelect];
        right = right.filter(u => !left.includes(u));
        this.setState({
            allUsers: left,
            delegates: right,
            leftSelect: [],
            rightSelect: [],
            lastRight:0,
            lastLeft:0
        });
        this.props.modifyDelegateUsers(right.map(user => user.userId));
    }

    private filterLeft = (allUsers: IUserModel[], usersToRemove: IUserModel[]) => {
        let left = [...allUsers];
        usersToRemove.map((u, i) => {
            Helper.removeItem(u, left);
        });
        return left;
    }

    public render() {
        return <div className="row">
            <Col sm={12} style={{ display: "flex" }}>
                <Col sm={6}>
                    <FormGroup style={{ display: "block" }} onDrop={()=>{ this.DragToLeft()}}>
                        <FormLabel>AVAILABLE USERS</FormLabel>
                            <FormControl id="signature-flow-current-users" style={{ height: '332px', overflowY: 'auto', padding:'0px'}} className="group_user custom-scroll-bar" as="div"  onDragOver={(event:any)=>event.preventDefault()}>
                                <ul style={{marginBottom:'0px'}}>
                                    {this.state.allUsers.map((user, index) => {
                                        const fullName = Helper.fullName(user);
                                        const oddEven = (index % 2 == 0) ? 'even ' : 'odd ';
                                        const isActiveSelected = this.state.leftSelect.some((j: IUserModel) => j.userId == user.userId) ? "activeSelected" : "";
                                            return(
                                                <div draggable={true} onDragStart={(event:any)=>{this.onSelectFromLeft(user,event)}}>
                                                        <li
                                                            className={'sender-delegation-users '+ oddEven + isActiveSelected}
                                                            key={index}
                                                            value={user.userId}
                                                            onDoubleClick={() => { this.onDoubleClickLeft(user, this.moveRight); }}
                                                            onClick={this.onSelectFromLeft.bind(this, user)}
                                                        > 
                                                            <div 
                                                                title={fullName}
                                                                className='ellipsis'
                                                                onClick={()=>this.onSelectFromLeft}
                                                                style={{paddingTop:'4px',paddingBottom:'4px',cursor:'grab'}} >
                                                                <span style={{paddingLeft:'9px'}}><GripVertical /></span>
                                                                <span style={{paddingLeft:'15px'}}>{fullName}</span>
                                                            </div>
                                                        </li>

                                                </div>
                                            )
                                        })}
                                </ul>
                            </FormControl>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup style={{ display: "block" }} onDrop={()=>{ this.DragToRight()}}>
                        <FormLabel>DELEGATION</FormLabel>
                            <FormControl id="sender-delegation-selected-users" style={{ height: '332px', overflowY: 'auto', padding:'0px' }} className="group_user custom-scroll-bar" as="div"  onDragOver={(event:any)=>event.preventDefault()}>
                            <ul style={{marginBottom:'0px'}}>
                                {this.state.delegates.map((user, index) => {
                                    const fullName = Helper.fullName(user);
                                    const oddEven = (index % 2 == 0) ? 'even ' : 'odd ';
                                    const isActiveSelected = this.state.rightSelect.some((j: IUserModel) => j.userId == user.userId) ? "activeSelected" : "";
                                    return (
                                        <div draggable={true} onDragStart={(event:any)=>{this.onSelectFromRight(user,event)}}>
                                                <li
                                                    className={'sender-delegation-users '+ oddEven + isActiveSelected}
                                                    key={index}
                                                    value={user.userId}
                                                    onDoubleClick={() => { this.onDoubleClickRight(user, this.moveLeft); }}         
                                                    onClick={this.onSelectFromRight.bind(this, user)}
                                                    >
                                                    <div 
                                                        title={fullName}
                                                        className='ellipsis'                                 
                                                        onClick={() => this.onSelectFromRight}                                                        
                                                        style={{paddingTop:'4px',paddingBottom:'4px',cursor:'grab'}} >
                                                       <span style={{paddingLeft:'9px'}}><GripVertical /></span>
                                                        <span style={{paddingLeft:'15px'}}>{fullName}</span>
                                                    </div>
                                                </li>
                                        </div>);
                                })}
                            </ul>
                            </FormControl>
                    </FormGroup>
                </Col>
            </Col>
        </div>;
    }
}