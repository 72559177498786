import { connect } from 'react-redux';
import * as UserSettingStore from '../../store/userManagement/UserSettingStore';
import { ApplicationState } from '../../store/types';
import { UserChangedWarning } from './UserChangedWarning';



export default connect(
    (state: ApplicationState) => ({
        userSettings: state.userSettings,
    }),
    {
        ...UserSettingStore.actionCreators,
    }
)(UserChangedWarning as any);