import * as React from 'react';
import { Modal, Alert, Button } from 'react-bootstrap';
import { IUserModel } from './../../../Core/ViewModels/User/UserViewModel'
import { SignatureFlowDelegationList } from './SignatureFlowDelegationList';

const isEqual = require("react-fast-compare");

interface ISignatureFlowDelegationPopUpProps {
    usersAllowedToDelegate: number[];
    userList: IUserModel[];
    showState: boolean;
    updateDelgatedUsers: (userToDelegate: number[]) => void;
    onHide: () => void;
}

interface ISignatureFlowDelegationPopUpState {
    usersAllowedToDelegate: number[];
}

export class SignatureFlowDelegationPopUp extends React.Component<ISignatureFlowDelegationPopUpProps, ISignatureFlowDelegationPopUpState>{
   
    private _delegationListRef: any;

    state: ISignatureFlowDelegationPopUpState = {
        usersAllowedToDelegate: []
    }

    UNSAFE_componentWillReceiveProps(nextProps: ISignatureFlowDelegationPopUpProps) {
        if (!isEqual(this.props, nextProps)) {
            this.setState({
                usersAllowedToDelegate: nextProps.usersAllowedToDelegate
            });
        }
    }

    private onCancelButtonClick = () => {
        this.props.onHide();
    }

    private onSaveButtonClick = () => {
        this.props.updateDelgatedUsers(this.state.usersAllowedToDelegate);
        this.props.onHide();
    }

    private onResetButtonClick = () => {
        this.setState({
            usersAllowedToDelegate: this.props.usersAllowedToDelegate
        });
        this._delegationListRef.setDelegatedUsers(this.props.usersAllowedToDelegate);
    }

    private modifyDelegateUsers = (usersAllowedToDelegate: number[]) => {
        this.setState({
            usersAllowedToDelegate: usersAllowedToDelegate
        });
    }

    public render() {
        return (<Modal className="sender-delegation-modal" centered={true}show={this.props.showState} onHide={this.onCancelButtonClick} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Sender Delegation
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{marginLeft:'14px', marginRight:'14px'}}>Move users that can apply your signature stamp to the Delegation side.</p>
                        <SignatureFlowDelegationList
                            ref={(ref: any) => this._delegationListRef = ref}
                            usersAllowedToDelegate={this.state.usersAllowedToDelegate}
                            allUserList={this.props.userList}
                            modifyDelegateUsers={this.modifyDelegateUsers}
                        />
                    </Modal.Body>
                    <Modal.Footer>         
                        <span data-test-auto="6595999f-38f5-47f9-9275-10e3a286f264" style={{marginRight:'21px'}} onClick={this.onResetButtonClick}>
                            <a style={{textDecorationLine: 'underline',color: '#0973BA', cursor:'pointer'}}>
                                Reset
                            </a>
                        </span>
                        <Button
                            data-test-auto="651EF924-C2F9-41E7-9421-C891E64019FB"
                            variant='default'
                            className='btn-white modal-footer-button-cancel'
                            onClick={this.onCancelButtonClick} >
                                Cancel
                        </Button>
                        <Button
                            variant='info'
                            className=' modal-footer-button-save'
                            onClick={this.onSaveButtonClick}
                            data-test-auto="7539FCBB-EEEC-4A6F-ABBC-2FB42159304C">
                                Save
                        </Button>
                    </Modal.Footer>
                </Modal>
        )
    }
}