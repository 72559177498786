import React from 'react';
import * as Bootstrap from 'react-bootstrap';
import {UnLock } from '../../svg/CustomSvgIcons';
import { ResourceIdConstants } from '../../helper/RBACConstants';

interface HeaderProps {
    screenId: string;
    pageTitle: string;
    selectedDocumentCount: number;
    onUnlockDocuments: () => void;
}

export const SignatureLockedReportsHeader :React.FC <HeaderProps> = (props) => {
    const {screenId} = props;
    return (<div className='report-header-width'>
                 <div className="row" style={{ marginBottom: 23 }}>
                    <div className="col-sm-8">
                        <h3><strong>{props.pageTitle}</strong></h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Bootstrap.Button
                            data-resource-id={`${screenId}${ResourceIdConstants.Unlock}`}
                            variant='default'
                            className='btn btn-white header-action'
                            data-test-auto="da34c0f8-29af-4600-aca7-d13c5afcf108"
                            disabled={props.selectedDocumentCount === 0}
                            onClick={() => props.onUnlockDocuments()}>
                                <UnLock marginLeft='5px' marginBottom='5px'/><span style={{marginRight: '8px', paddingTop: '2px'}}> Unlock </span>
                        </Bootstrap.Button>
                    </div>
                </div>
            </div>);
}