import { useSelector,useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {actionCreators} from '../../../store/SignatureFlowSettingsStore';
import { ApplicationState } from '../../../store/types';

export interface SignerDelegationSectionProps{
    resourceId: string;
}
export const SignerDelegationSection:React.FC<SignerDelegationSectionProps> = props => {
    const dispatch = useDispatch();
    const isAllowedToDelegate = useSelector((state:ApplicationState)=>state.signatureFlowSettingsData.signatureFlowSettings.delegationSettings.signerDelegation);
    const [toggle, setToggle] = useState(isAllowedToDelegate);
    useEffect(()=>{
    setToggle(isAllowedToDelegate);
    },[isAllowedToDelegate]);
   return (
    <div className="new-main-content signer-delegation-section" data-resource-id={props.resourceId}>
        <div className="row" style={{ position: "relative" }}>
            <div className="col-sm-8 col-lg-11" style={{ marginLeft: 17 }}>
                <div className="row">
                    <div className="col-sm-10 padL00 float-left" style={{paddingBottom:'26px',paddingTop:'18px'}}>
                        <h4 className='sigflow-settings-heading'>Signer Delegation</h4>
                    </div>
                </div>
                <div className="row" style={{ alignItems: "center",paddingBottom:'20px' }}>
                <div className='automated-reminder-row' >
                <label className="signerDelegation-toggleSwitch" style={{marginRight:'18px'}}>
                    <input type="checkbox"
                       checked={toggle}
                       onChange={()=>{
                           dispatch(actionCreators.updateSignatureFlowSignerDelegation(!toggle))
                           setToggle(!toggle);
                       
                       }}></input>
                    <span className="columnFilterSlider round" style={{margin:0}}></span>
                </label>
            </div>
            <div className="text-left">
                <div className="font16"> Signer Delegation </div>
            </div>
    </div>
</div>
</div>
</div>
   )
 }
 
 export default SignerDelegationSection