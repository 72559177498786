import { LogoutCause } from 'src/SignatureFlow/models/user/UserSettingsModels';
import {
    SILENT_RENEW_ERROR,
    USER_FOUND,
    LOAD_USER_ERROR,
    USER_EXPIRED,
    USER_SIGNED_OUT,
    LOADING_USER,
    USER_AUTO_LOGOUT,
} from './action-types'

export interface IAuthState {
    user: any
    isLoadingUser: boolean;
    userPrivilegesChanged:boolean;
    userAutoLogoutCause:LogoutCause
}

const initialState = {
    user: null,
    isLoadingUser: false,
    userPrivilegesChanged:false,
    userAutoLogoutCause:LogoutCause.None
}

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case USER_FOUND:
            return {
                ...state,
                isLoadingUser: false,
                user: action.payload,
            }
        case LOADING_USER:
            return {
                ...state,
                isLoadingUser: true,
            }
        case USER_EXPIRED:
        case LOAD_USER_ERROR:
        case SILENT_RENEW_ERROR:
        case USER_SIGNED_OUT:
            return {
                ...state,
                user: null,
                isLoadingUser: false,
            }
        case USER_AUTO_LOGOUT:
            return {
                ...state,
                userPrivilegesChanged:true,
                userAutoLogoutCause:action.userAutoLogoutCause
            }
        default:
            return state
    }
}

export default reducer
