import * as React from 'react';
import Modal  from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as UserSettingStore from '../../store/userManagement/UserSettingStore';

type UserChangedProps  = {
    userSettings: UserSettingStore.UserSettings
}
    & typeof UserSettingStore.actionCreators

export const UserChangedWarning: React.FC<UserChangedProps> = props => {

    const [showModal, setShowModal] = React.useState(false);

    React.useEffect(() => {
        let signatureSettings = props.userSettings.signatureSettings;
        if (signatureSettings.defaultSettings !== undefined &&
            showModal !== signatureSettings.defaultSettings.showDefaultWarning) {
            setShowModal(signatureSettings.defaultSettings.showDefaultWarning);
        }
    }, [props.userSettings.signatureSettings])

    const onCloseModal = (e: any): void => {
        e && e.preventDefault();
        let settings = { ...props.userSettings.signatureSettings };

        setShowModal(false);
        settings.defaultSettings.notifyUser = 0;
        settings.defaultSettings.showDefaultWarning = false;
        props.updateUserSettings(settings);
    }

    return (
        <Modal show={showModal} onHide={onCloseModal} size="sm" className="user-warning-modal" >
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className='modalIcon text-secondary fas fa-info-circle'>
                    </span>Warning: Default User has Been Modified or Deleted.
                    </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>The user you had selected as a default in your settings has been modified or deleted. To add new default selections, please go to "My Settings". </h4>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="btn-white"
                    variant='default'
                    onClick={onCloseModal}
                >
                    Close
                    </Button>
            </Modal.Footer>
            </Modal>
    );
}
