import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../';
import { IUserProfile, initialUserProfile, IPasswordUpdateModel } from '../../components/navigation/profile/ProfileObjects';
import { actionTypes } from '../ActionTypes';
import { handleResponse } from '../Library';
import { API_BASE_URL, SSSUITE_API_BASE_URL } from '../../../utils/AppConstants';
import { UserManagementConstants } from '../../components/helper/Constants';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { container } from '../../../Startup/inversify.config';
import { ILocalStore } from '../../../Core/Utilities/LocalStore';
import { TYPES } from '../../../Startup/types';
import { validateError } from '../../components/helper/Validations';
import { logger } from '../../../routes/LoggedIn';
import { ProductType } from '@sssuite-component-ui/ss-suite-layout';
import { ResourceValidator } from '@sssuite-component-ui/resource-id-validator';
import { RBACInfo } from '../../components/helper/RBACConstants';


export let validator: ResourceValidator;

const startValidator = (resources: string[]) => {
    validator = new ResourceValidator(resources, 'disable', 'click', RBACInfo.NoPermissionTitle);
    validator.start();
}


export interface IUserProfileData {
    user: IUserProfile;
    resourceInfo: IUserResourceInfo;
    isLoading: boolean;
}

export interface IUserResourceInfo {
    availableResources: string[];
    isLoaded: boolean;
}

export interface UserProfileState {
    user: IUserProfile;
}

interface RequestUserProfileAction {
    type: actionTypes.REQUEST_USER_PROFILE;
}

export interface ReceiveUserProfileAction {
    type: actionTypes.RECEIVE_USER_PROFILE;
    user: IUserProfile;
}
interface UpdatePasswordAction {
    type: actionTypes.UPDATE_USER_PASSWORD;
}

interface UpdateUserProfileAction {
    type: actionTypes.UPDATE_USER_PROFILE;
    userDetails: IUserProfile;
}

export interface ReceiveUserResourcesAction {
    type: actionTypes.RECEIVE_USER_RESOURCES;
    resources: string[];
}

type KnownAction = RequestUserProfileAction | ReceiveUserProfileAction | UpdateUserProfileAction | NotificationAction | ReceiveUserResourcesAction|UpdatePasswordAction;

type DispatchActions = RequestUserProfileAction | ReceiveUserProfileAction | UpdateUserProfileAction | ReceiveUserResourcesAction |UpdatePasswordAction;

const localStorage = container.get<ILocalStore>(TYPES.ILocalStore);

export const actionCreators = {
    requestUserProfile: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (reload || !getState().userProfile.user.emailAddress) {
            const fetchTask = fetch(SSSUITE_API_BASE_URL +'api/user-management/user-details', {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<IUserProfile>)
                .then(data => {                 
                    dispatch({ type: actionTypes.RECEIVE_USER_PROFILE, user: data });
                })
                .catch(function (error) {
                });
            addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
            dispatch({ type: actionTypes.REQUEST_USER_PROFILE });
        }
    },
    updatePassword: (passwordUpdateModel: IPasswordUpdateModel, onResponse: (response: any, error: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let options: any = {
            method: 'PUT',
            credentials: "include",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(passwordUpdateModel)
        };

        const fetchTask = fetch(SSSUITE_API_BASE_URL + 'api/user-management/user-password', options)
            .then(response => response.ok ? response.json() : Promise.reject(response.text()))
            .then(response => {
                dispatch({
                    type: actionTypes.UPDATE_USER_PASSWORD
                });
                if (onResponse) {
                    onResponse(response, null);
                }
            }).catch(error => {
                error.then((err: any) => {
                    if (onResponse) {
                        onResponse(null, err);
                    }
                })
            });
        addTask(fetchTask);
    },

    saveMyAccount: (userInfo: IUserProfile, existingEmailAddress: string, oldPassword: string, notification: boolean,
        onResponse: (response: any, error: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
         
        let options: any = {
            method: 'PUT',
            credentials: "include",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(userInfo) 
        };

        const fetchTask = fetch(SSSUITE_API_BASE_URL +'api/user-management/user-details', options)
            .then(response => response.json())
            .then(response => {
                dispatch({
                    type: actionTypes.UPDATE_USER_PROFILE, userDetails: userInfo
                });
                if (onResponse) {
                    onResponse(response,null);
                }
                if (notification) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: UserManagementConstants.UserUpdationSuccess,
                        statusType: StatusType.Success
                    });
                }
            }).catch(error => {
                if (onResponse) {
                    onResponse(null, error);
                }
                if (notification) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: UserManagementConstants.UserUpdationError,
                        statusType: StatusType.Error,
                        statusCode:error?.status
                    });
                }

                logger.trackError(`saveMyAccount failed for the request having parameters ${JSON.stringify({
                    userInfo: userInfo,
                    existingEmailAddress: existingEmailAddress,
                    oldPassword: oldPassword
                })} with error ${error.message}`)
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_USER_PROFILE });
        },

        
    getResources: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (reload) {
            const fetchTask = fetch(`${SSSUITE_API_BASE_URL}/api/resource/${ProductType.SignaturesOneOff}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(response => response.json() as Promise<string[]>)
                .then(data => {
                    startValidator(data);
                    dispatch({ type: actionTypes.RECEIVE_USER_RESOURCES, resources: data });
                })
                .catch(function (error) {
                    logger.trackError(`getResources failed with error ${validateError(error)}`);
                });
            addTask(fetchTask);
        }
    }
};

const unloadedState: IUserProfileData = {
    resourceInfo: { availableResources: [], isLoaded: false }, user: initialUserProfile, isLoading: false
};

export const reducer: Reducer<IUserProfileData> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.REQUEST_USER_PROFILE:
            return ({
                ...state,
                user: unloadedState.user
            }) as IUserProfileData;
        case actionTypes.RECEIVE_USER_PROFILE:
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.user.userId === state.user.userId || state.user.firstName === '') {
                return {
                    ...state,
                    user : action.user,
                    fullName() { return action.user.firstName + ' ' + action.user.lastName; }
                } as IUserProfileData;
            }
            else {
                return {
                    ...state,
                    fullName() { return this.user.firstName + ' ' + this.user.lastName; }
                } as IUserProfileData;
            }
        case actionTypes.UPDATE_USER_PASSWORD:
                return ({
                    ...unloadedState
                }) as IUserProfileData;
        case actionTypes.UPDATE_USER_PROFILE:
            let received: IUserProfileData = { ...state };
            received.user = action.userDetails
            return received;

        case actionTypes.RECEIVE_USER_RESOURCES:
            let resourceInfo: IUserResourceInfo = { availableResources: action.resources, isLoaded: true };
            return { ...state, resourceInfo: resourceInfo };

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state;
};
