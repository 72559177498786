import { useState, useEffect, useRef } from 'react';
import * as bootbox from 'bootbox';
import { RouteComponentProps, useHistory } from 'react-router';
import { SortDirections } from '../../../components/common/Filters';
import { IDocumentAccess, ISignatureFlowReportDocument, ISignatureFlowReportFilter } from '../../../models/SignatureFlowReportState';
import * as UserStore from '../../../store/SFUserStore';
import * as DraftReportStore from '../../../store/ReportsStore/DraftReport/DraftReportStore';
import * as UserProfileStore from '../../../store/userManagement/UserProfileStore';
import { IUserProfile } from '../../../components/navigation/profile/ProfileObjects';
import * as SignatureFlowReportFilterStore from '../../../store/ReportsCommon/SignatureFlowReportFilterStore';
import * as SignatureFlowReportPopupModelStore from '../../../store/ReportsCommon/SignatureFlowReportPopupModelStore';
import * as SignatureFlowSettingsStore from '../../../store/SignatureFlowSettingsStore';
import * as Helper from '../../../components/helper/HelperFunctions';
import {SignatureFlowReportFilterConstants, AssignToConstant, Common, SizePerPageList, DeleteDraftConstants, SignatureFlowConstants} from '../../helper/Constants';
import * as SFBulkOperationsStore from '../../../store/SFBulkOperationsStore';
import Pagination from '../../common/Pagination';
import React from 'react';
import { GetInitialDraftReportPageState, ISignatureFlowDraftReportPageState } from 'src/SignatureFlow/models/SignatureFlowDraftReportTableState';
import { initialSFFilter } from '../../../models/SignatureFlowSettings';
import SignatureDraftReportTable from './SignatureDraftReportTable';
import { SignatureDraftReportsHeader } from './SignatureDraftReportHeader';
import { ScreenIds } from '../../helper/RBACConstants';
import { HideLoader, ShowLoader } from '../../helper/Loader';
import * as CompanyStore from '../../../store/Company/CompanyStore';
import { logger } from 'src/routes/LoggedIn';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { DraftReportPaths } from '../../helper/UrlPaths';
import { ReportProblemModal }  from '../../common/ReportProblemModal_FC';
import { ReportProblemTypes, IReportProblemDetails } from '../../common/ReportProblemModel';
import { getAdjustedReportsPageNumber } from '../../../components/helper/HelperFunctions';
import { SignatureFlowReportSetAccessModal } from '../ActionButtonModals/SignatureFlowReportSetAccessModal';
import SignatureFlowReportDocumentModal from '../ActionButtonModals/SignatureFlowReportDocumentModal';
import { SignatureAssignToModal } from '../ActionButtonModals/SignatureAssignToModal';
import store from 'src/SignatureFlow/store';
import * as OfficeLocationStore from '../../../store/MultiOffice/OfficeLocationStore';

// At runtime, Redux will merge together...
type SignatureDraftReportProps =
    {
        DraftReportDocuments: DraftReportStore.ISignatureDraftReportState,     // ... state we've requested from the Redux store
        userStore: UserStore.ISFUserData,
        signatureFlowReportDetails: SignatureFlowReportPopupModelStore.ISignatureFlowReportPopupDictionary,
        signatureFlowReportFilter: SignatureFlowReportFilterStore.IFilterState,
        profile: IUserProfile,
        companyData: CompanyStore.ICompanyData,
        userOfficeLocationState: OfficeLocationStore.IUserOfficeLocationState,
        pageTitle: string,
    }
    & typeof DraftReportStore.actionCreators   // ... plus action creators we've requested
    & typeof UserStore.actionCreators
    & typeof SignatureFlowReportFilterStore.actionCreators
    & typeof SignatureFlowReportPopupModelStore.actionCreators
    & typeof UserProfileStore.actionCreators
    & typeof SignatureFlowSettingsStore.actionCreators
    & typeof SFBulkOperationsStore.actionCreators
    & RouteComponentProps<{ page: string }>;        // ... plus incoming routing parameters

const pageSize: number = 10;
const NO_INDEX = -1;

export const SignatureDraftReport :React.FC <SignatureDraftReportProps> = (props) => {
    const SignatureDraftReportTableRef: any = useRef();
    const [draftReportPageState, setDraftReportPageState] = useState<ISignatureFlowDraftReportPageState>(GetInitialDraftReportPageState(pageSize));
    const [defaultFilter, setDefaultFilter] = useState<ISignatureFlowReportFilter>(initialSFFilter);

    const buildQuery = (page: number, pageSize: number, updateFilterState?:ISignatureFlowDraftReportPageState) => {
        return '?pageNo=' + page
            + '&pageSize=' + pageSize
            + '&sortBy=' + (updateFilterState ? updateFilterState.sortName : draftReportPageState.sortName)
            + '&sortOrder=' + (updateFilterState ? updateFilterState.sortOrder : draftReportPageState.sortOrder)
            + '&searchString=' + (updateFilterState ? updateFilterState.searchString : draftReportPageState.searchString)
            + '&filterDocumentType=' + (updateFilterState ? updateFilterState.filterDocumentType : draftReportPageState.filterDocumentType)
            + '&filterTaxYear=' + (updateFilterState ? updateFilterState.filterTaxYear : draftReportPageState.filterTaxYear)
            + '&filterRecipientName=' + encodeURIComponent(updateFilterState ? updateFilterState.filterRecipientName : draftReportPageState.filterRecipientName)
            + '&filterClientId=' + encodeURIComponent(updateFilterState ? updateFilterState.filterClientId : draftReportPageState.filterClientId)
            + '&filterDraftOn=' + (updateFilterState ? (updateFilterState.filterDraftOn || "") : (draftReportPageState.filterDraftOn || ""))
            + '&filterAssignedTo=' + (updateFilterState ? updateFilterState.filterAssignedTo : draftReportPageState.filterAssignedTo)
            + '&filterOfficeLocation='+(updateFilterState ? updateFilterState.filterOfficeLocation : draftReportPageState.filterOfficeLocation)
    }

    useEffect(() => {
        props.requestSignatureDraftDocuments(buildQuery(draftReportPageState.page, draftReportPageState.pageSize), true);
        props.requestSignatureDraftReportFilter();
        props.requestAllSFUsers(true);
    },[])

    useEffect(()=>{
        if(draftReportPageState.stateCallback !== null){
            draftReportPageState.stateCallback();
            setDraftReportPageState({...draftReportPageState,
                stateCallback: null
            })
        }

    },[draftReportPageState])

    useEffect(() => {
        setDefaultFilter(props.signatureFlowReportFilter.draftReportFilter.find(x => x.isDefault == 1));

        if(props.DraftReportDocuments.totalRowCount !== draftReportPageState.totalRecordsCount) {
            if(!props.DraftReportDocuments.isLoading) {
                setDraftReportPageState({...draftReportPageState,
                    totalRecordsCount: props.DraftReportDocuments.totalRowCount
                });
            }
        }
    },[props])

    const onPageReload = (page?:number) => {
        const query = buildQuery(page ? page : draftReportPageState.page, draftReportPageState.pageSize);
        props.requestSignatureDraftDocuments(query, true);
        setDraftReportPageState({...draftReportPageState,
                                selectedRows: []});
    }

    const history = useHistory(); 

    const onBulkSelectionChange = (isEnabled: boolean) => {
        let showBulkSelectionMessage = false;

        setDraftReportPageState({...draftReportPageState,
            isBulkSelectionEnabled: isEnabled,
            showBulkSelectionMessage: showBulkSelectionMessage
        });
    }

    const getStoreItem = (index: number) => {
        let id = props.DraftReportDocuments.signatureFlowReportTableModel.documents[index].signingId;
        return props.signatureFlowReportDetails[id].signatureReport;

    }

    const onRowSelect = (row: any, isSelected: any, e: any) => {
        let newList = [...draftReportPageState.selectedRows];
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A' && e.target.tagName !== 'DIV') {
            if (isSelected) {
                newList.push(row.rowIndex);
            } else {
                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
            }
            setDraftReportPageState({...draftReportPageState,selectedRows: newList });
        }
    }

    const onSelectAll = (isSelected: boolean, rows: any[], e: any) => {
        let selectedRows: number[] = [];
        if (isSelected) {
            rows.forEach((value: any, index: number) => {
                selectedRows.push(value.rowIndex);
            });
        }
        setDraftReportPageState({...draftReportPageState, selectedRows: selectedRows });
    }

    const onSortChange = (sortName: string, sortOrder: string, column: number) => {
        clearSelection();
        let newState = {...draftReportPageState};
        newState.filter.sort.column = sortName;
        newState.filter.sort.direction = sortOrder == "asc" ? SortDirections.Ascending : SortDirections.Descending;
        newState.sortName = sortName;
        newState.sortOrder = sortOrder == "asc" ? "asc" : "desc";
        newState.page = 1;
        newState.selectedRows = [];
        newState.deSelectedRows = [];
        
        let queryString = buildQuery(1, draftReportPageState.pageSize, newState);
        setDraftReportPageState({...newState,
            stateCallback: () => props.requestSignatureDraftDocuments(queryString, false)
        })
    }

    const onSearchChange = (searchString: string) => {
        let temp: ISignatureFlowReportFilter = { ...draftReportPageState.filter };
        temp.searchText = searchString;
        let queryString = buildQuery(1, draftReportPageState.pageSize);
        setDraftReportPageState({...draftReportPageState,
            searchString: searchString,
            filter: temp,
            selectedRows:[],
            deSelectedRows:[],
            stateCallback: () => props.requestSignatureDraftDocuments(queryString, false)
        });
    }

    const loadSignatureDraftReports = () => {
        const queryString = buildQuery(draftReportPageState.page, draftReportPageState.pageSize);
        props.requestSignatureDraftDocuments(queryString, false);
    }

    const onPageChange = (page: number, pageSize: number) => {
        let queryString = buildQuery(page, pageSize);
        setDraftReportPageState({...draftReportPageState,
            page: page,
            pageSize:pageSize,
            selectedRows: [],
            stateCallback:() => props.requestSignatureDraftDocuments(queryString, false)
        });
    }

    const selectedDocumentIds = () => {
        let ids: number[] = [];
        draftReportPageState.selectedRows.map((row, i) => {
            let selectedDocument = props.DraftReportDocuments.signatureFlowReportTableModel.documents[row];
            if (selectedDocument) {
                ids.push(selectedDocument.signingId);
            }
        });
        return ids;
    }
    
    const onPopupOpen = (rowIndex: number) => {
        let ids = [rowIndex];
        if (rowIndex !== NO_INDEX) {
            return [props.DraftReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId];
        }
        if (draftReportPageState.selectedRows.length == 0) {

            return [];
        }
        return selectedDocumentIds();
    }

    const onDeleteDocumentsOpen = async (rowIndex: number = NO_INDEX) => {
        let ids = onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning("Please select document(s) to delete!", null);
            return;
        }
        ShowLoader(Common.OverlayMessages.Loading);
        if(await hasDraftDocumentsLocked(ids)) {
            HideLoader();
            VenusNotifier.Warning(SignatureFlowConstants.DocumentsInUse, null);
            return;
        }
        HideLoader();
        bootbox.confirm({
            title:'Delete Document(s)',
            message: DeleteDraftConstants.ConfirmDeletion,
            buttons: {
                cancel: {
                    label: 'Cancel',
                    className:'btn-white modal-footer-button-cancel'
                },
                confirm: {
                    label: 'Confirm',
                    className:'btn-info modal-footer-button-save'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    ShowLoader(Common.OverlayMessages.Deleting);
                    onDeleteSelectedReturns(ids);
                    logger.trackTrace("onDeleteSelectedDrafts with ids" + JSON.stringify(ids));
                }

            }
        }).find(".modal-dialog")
        .addClass("modal-dialog-centered");
    }

    const onDeleteSelectedReturns = (ids: number[]) => {
        props.deleteSignatureDraftDocument(ids, (isSuccess: boolean) => {
            if(isSuccess){
                const adjustedPageNumber = getAdjustedReportsPageNumber(props.DraftReportDocuments.signatureFlowReportTableModel.count, ids.length, draftReportPageState.page, draftReportPageState.pageSize)
                setDraftReportPageState({
                    ...draftReportPageState,
                    page: adjustedPageNumber
                })
                props.requestSignatureDraftDocuments(buildQuery(adjustedPageNumber, draftReportPageState.pageSize), true,() => {
                    clearFilters(adjustedPageNumber);
                })
            }
            HideLoader();
        });
        setDraftReportPageState({
            ...draftReportPageState,
            deleteReturnsState: {
                show: false,
                model: undefined,
                saveCheckId: ids[0]
            }
        });
    }

    const onResume = async (rowIndex: number = NO_INDEX) => {
        const id = [props.DraftReportDocuments?.signatureFlowReportTableModel?.documents[rowIndex]?.signingId];
        ShowLoader(Common.OverlayMessages.Loading);
        if(await hasDraftDocumentsLocked(id)) {
            HideLoader();
            VenusNotifier.Warning(SignatureFlowConstants.DocumentsInUse, null);
            return;
        }
        HideLoader();
        navigateToSendForSignature(rowIndex);
    }

    const navigateToSendForSignature = (rowIndex: number = NO_INDEX) =>{ 
        history.push({
            pathname:"/SendForSignature",
            state:{
                signingId: props.DraftReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId,
                redirectedFrom: history.location
             }
           });
      }

    //======================Filter Operation Starts here=================================

    const onFilterSave = (onApplyFilter: (filter: ISignatureFlowReportFilter) => void) => {
        if (draftReportPageState.filter.name.trim() != '') {
            let filter = props.signatureFlowReportFilter.draftReportFilter.find(x => x.name.toLocaleLowerCase() == draftReportPageState.filter.name.toLocaleLowerCase());
            if (filter) {
                VenusNotifier.Warning(SignatureFlowReportFilterConstants.OtherMessage.FilterNameAlreadyExists, null);
            }
            else {
                props.saveSignatureDraftReportFilter(draftReportPageState.filter.name, draftReportPageState.filter, () => {
                    onApplyFilter(draftReportPageState.filter);
                    onSaveFilterHide();
                });
            }
        }
        else {
            VenusNotifier.Warning(SignatureFlowReportFilterConstants.OtherMessage.PleaseEnterFilterName, null);
        }
    }

    const isFilterChanged = (newState:ISignatureFlowDraftReportPageState, filterStatus: any, filterTaxYear: any): boolean => {
        return (
            newState.filterDraftOn !== draftReportPageState.filterDraftOn||
            newState.filterAssignedTo != draftReportPageState.filterAssignedTo ||
            newState.filterDocumentType != draftReportPageState.filterDocumentType ||
            filterTaxYear != draftReportPageState.filterTaxYear ||
            newState.filterRecipientName != draftReportPageState.filterRecipientName ||
            newState.filterClientId != draftReportPageState.filterClientId||
            newState.filterOfficeLocation != draftReportPageState.filterOfficeLocation);
    }

    const clearSelection = () => {
        setDraftReportPageState({...draftReportPageState,
            selectedRows: [],
            deSelectedRows: [],
            isBulkSelectionEnabled: false,
            showBulkSelectionMessage: false
        });
    }

    const  onFilterChange = (dataField: any) => {
        let newState: ISignatureFlowDraftReportPageState = {
            filterDraftOn: undefined,
            filterAssignedTo: "",
            filterDocumentType: "",
            filterRecipientName: "",
            filterClientId: "",
            filterOfficeLocation: "",
        } as ISignatureFlowDraftReportPageState;
        let filterStatus: any;
        let filterTaxYear: any;
        var dict: { [columnName: string]: string; } = {};
        for (let field of Object.keys(dataField)) {
            dict[field.valueOf().toString()] = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            switch (field) {
                case 'documentStatusChangedOn':
                    newState.filterDraftOn = Helper.GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
                    break;
                case 'assignedTo':
                    newState.filterAssignedTo = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'officeLocation':
                    let filterOfficeLocation = dataField[field].value ? dataField[field].value : dataField[field];
                    filterOfficeLocation = filterOfficeLocation == "-1" ? "" : filterOfficeLocation;
                    newState.filterOfficeLocation = filterOfficeLocation;
                    break;
                case 'taxYear':
                    let tempYear = dataField[field].value ? dataField[field].value : dataField[field];
                    tempYear = ( tempYear && (tempYear !== "-1")) ? tempYear : "";
                    newState.filterTaxYear = tempYear;
                    break;
                case 'documentType':
                    newState.filterDocumentType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'recipientName':
                    newState.filterRecipientName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'clientId':
                    newState.filterClientId = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
            }
        }
        
        let tempfilter: ISignatureFlowReportFilter = { ...draftReportPageState.filter };
        tempfilter.fields = dict;
        let stateCallBack : any = null;
        if (SignatureDraftReportTableRef && SignatureDraftReportTableRef.current && !SignatureDraftReportTableRef.current.isAppliedFilter  && !props.DraftReportDocuments.isLoading) {
            let query = buildQuery(1, draftReportPageState.pageSize, {...draftReportPageState, ...newState, ...tempfilter});
            stateCallBack = () => {
                props.requestSignatureDraftDocuments(query, true);
            }
        }
        if (isFilterChanged(newState, filterStatus, filterTaxYear)) {
            setDraftReportPageState({...draftReportPageState,
                ...newState,
                filter: tempfilter,
                page:1,
                selectedRows:[],
                deSelectedRows:[],
                isBulkSelectionEnabled: false,
                showBulkSelectionMessage: false,
                stateCallback: stateCallBack
            })
        }
    }

    const onFilterNameChange = (event: any) => {
        let temp: ISignatureFlowReportFilter = { ...draftReportPageState.filter };
        temp.name = event.target.value;
        setDraftReportPageState({...draftReportPageState,
            filter: temp
        });
    }

    const onSetDefaultFilter = (name: string) => {
        let oldDefaultFilter = props.signatureFlowReportFilter.draftReportFilter.find(x => x.isDefault == 1); 
        let filter: ISignatureFlowReportFilter[] = [];
        if (oldDefaultFilter != null && oldDefaultFilter != undefined && oldDefaultFilter.name != name) {
            oldDefaultFilter.isDefault = 0;
            props.updateSignatureDraftReportFilter(oldDefaultFilter.name, oldDefaultFilter, undefined, false);
        }

        filter = props.signatureFlowReportFilter.draftReportFilter.filter(x => x.name == name);
        if (filter) {
            filter[0].isDefault = 1;
            setDefaultFilter(filter[0]);
            props.updateSignatureDraftReportFilter(name, filter[0], undefined, true);

        }
    }

    const onFilterDelete = (filterName: string) => {
        props.removeSignatureDraftReportFilter(filterName);
    }

    const onSaveFilterShow = () => {
        setDraftReportPageState({...draftReportPageState,
            saveFilterShow: true
        });
    }

    const onSaveFilterHide = () => {
        setDraftReportPageState({...draftReportPageState,
            saveFilterShow: false
        });
    }

    const onFilterUpdate = (name: string) => {
        let filter = props.signatureFlowReportFilter.draftReportFilter.find(x => x.name == name);
        if (!filter) {
            VenusNotifier.Warning(SignatureFlowReportFilterConstants.OtherMessage.FilterDoesNotExists, null);
        }
        else {
            filter.fields = draftReportPageState.filter.fields;
            filter.name = name;
            filter.searchText = draftReportPageState.filter.searchText;
            filter.sort = draftReportPageState.filter.sort;
            filter.isDefault = draftReportPageState.filter.isDefault;
            props.updateSignatureDraftReportFilter(name, filter, undefined, true);
            onSaveFilterHide();
        }
    }

    const onRemoveDefaultFilter = (name: string) => {
        let filter = props.signatureFlowReportFilter.draftReportFilter.find(x => x.name == name);
        if (filter) {
            filter.isDefault = 0;
            props.updateSignatureDraftReportFilter(name, filter, undefined, true);
        }

        onPageReload();
    }

    const clearFilters = (page: number) => {
        setDraftReportPageState({ 
            ...draftReportPageState,
            page: page,
            selectedRows: [],
            stateCallback: () => onPageReload(page)
        });
        SignatureDraftReportTableRef.current && 
        SignatureDraftReportTableRef.current.filtersRef.current && 
        SignatureDraftReportTableRef.current.filtersRef.current.setState({ activeFilter: '', appliedFilters: [] });
    }

    //======================Filter Operation Ends here=================================

    //======================Report problem Starts here=================================

    const onReportProblemOpen = (rowIndex: number = NO_INDEX) => {
        setDraftReportPageState({...draftReportPageState,
            reportProblem: {
                show: true,
                model: getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });

    }

    const onReportProblemCancel = () => {
        setDraftReportPageState({...draftReportPageState,
            reportProblem: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    const onReportProblemSave = (id: number, problemDetails: IReportProblemDetails, callback?: () => void) => {
        props.reportTaxDocumentProblem(problemDetails, DraftReportPaths.ReportedProblem, callback);
        setDraftReportPageState({...draftReportPageState,
            reportProblem: {
                show: false,
                model: undefined,
                saveCheckId: id
            }
        });

        logger.trackTrace("onReportProblemSave with params" + JSON.stringify({id: id, problemDetails: problemDetails}));

    }

    //======================Report problem Ends here=================================
    const onSetAccessSave = (userIds: number[]) => {
        let ids = selectedDocumentIds();
        if (ids.length == 0 && draftReportPageState.setAccessState.selected) {
            draftReportPageState.setAccessState.selected.map((reports, i) => {
                ids.push(reports.signingId)
            })
        }
        let accessMaps: IDocumentAccess[] = [];
        ids.forEach((id, i) => {
            accessMaps.push({
                signingId: id,
                userIds: userIds
            } as IDocumentAccess);
        });
        props.setAccessToSignatureReports(accessMaps, DraftReportPaths.SetAccess, () => {
            setDraftReportPageState({...draftReportPageState,
                setAccessState: {
                    show: false,
                    selected: undefined,
                    saveCheckIds: ids,
                    remove: false
                },
                selectedRows: [],
                stateCallback:() => onPageReload()
            });
        });
    }

    const onSetAccessCancel = () => {
        setDraftReportPageState({...draftReportPageState,
            setAccessState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false
            }
        });
    }
    
    const hasDraftDocumentsLocked = async (ids: number[]) => {
        await props.hasAnyDraftDocumentsLocked(ids);
        return store.getState().DraftReportDocuments.isDocumentsLocked;
    }

    const onSetAccessOpen = async (rowIndex: number = Helper.NO_INDEX, headerTextShow: boolean) => {
        let ids = onPopupOpen(rowIndex);
        if (ids.length == 0) {
            VenusNotifier.Warning("Please select document(s) to set access for other users!", null);
            return;
        }
        ShowLoader(Common.OverlayMessages.Loading);
        if(await hasDraftDocumentsLocked(ids)) {
            HideLoader();
            VenusNotifier.Warning(SignatureFlowConstants.DocumentsInUse, null);
            return;
        }
        HideLoader();
        props.requestAllSFUsers(false);
        props.requestSignatureReportAccess(ids, DraftReportPaths.SetAccess);

        let selectedReports = ids.map((id, i) => {
            return props.DraftReportDocuments.signatureFlowReportTableModel.documents.filter(x => x.signingId == id)[0];
        });

        setDraftReportPageState({...draftReportPageState,
            setAccessState: {
                show: true,
                selected: selectedReports,
                saveCheckIds: undefined,
                remove: false
            },
            setAccessHeaderTextDisplay: headerTextShow
        });

        logger.trackTrace("onSetAccessOpen with ids" + JSON.stringify(ids));
    }

    const onDocumentDetailsOpen = (rowIndex: number = Helper.NO_INDEX) => {
        setDraftReportPageState({...draftReportPageState,
            documentDetailsState: {
                show: true,
                model: getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });

    }

    const onDocumentDetailsCancel = () => {
        setDraftReportPageState({...draftReportPageState,
            documentDetailsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    const assignedUser = (selectedReports: ISignatureFlowReportDocument[]) => {
        if(selectedReports && selectedReports.length === 1 && 
            selectedReports[0].documentAssignedTo){
            return selectedReports[0].documentAssignedTo.userId;
        }
        return 0;
    }

    const onAssignToOpen = async (rowIndex: number = Helper.NO_INDEX) => {

        let ids = onPopupOpen(rowIndex);

        if (ids.length === 0) {
            VenusNotifier.Warning("Please select document(s) to Assign Document(s) for other users!", null);
            return;
        }
        ShowLoader(Common.OverlayMessages.Loading);
        if(await hasDraftDocumentsLocked(ids)) {
            HideLoader();
            VenusNotifier.Warning(SignatureFlowConstants.DocumentsInUse, null);
            return;
        }
        HideLoader();
        props.requestAllSFUsers(false);
        props.requestDraftReportAssignedTo(ids, DraftReportPaths.AssignedTo);

        let selectedReports = ids.map((id, i) => {
            return props.DraftReportDocuments.signatureFlowReportTableModel.documents.filter(x => x.signingId == id)[0];
        });

        setDraftReportPageState({...draftReportPageState,
            assignToState:{
                show: true,
                selected: selectedReports,
                saveCheckIds: undefined,
                remove: false
            }});
    }

    const onAssignedToSave = (userId: number) => {

        //Functionality related to DOCUMENT IN USE will be implemented after Resume/Locked functionality is implemented.

        let ids = selectedDocumentIds();
        if(userId == 0){
            VenusNotifier.Warning(AssignToConstant.NoUserSelected, null);
            return
        }
        if (ids.length == 0 && draftReportPageState.assignToState.selected) {
            draftReportPageState.assignToState.selected.map((reports, i) => {
                ids.push(reports.signingId)
            })
        }

        props.setAssignToSignatureReports(ids, userId, DraftReportPaths.AssignedTo, () => {
            setDraftReportPageState({...draftReportPageState,
                assignToState: {
                    show: false,
                    selected: undefined,
                    saveCheckIds: ids,
                    remove: false
                },
                selectedRows: [],
                stateCallback:() => onPageReload()
            });
        });
    }

    const onAssignToCancel = () => {
        setDraftReportPageState({...draftReportPageState,
                assignToState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            }
        });
    }

    const getDefaultFilter = () => {
        let defaultFilterToApply = !props.DraftReportDocuments.isLoading && 
                                    !props.signatureFlowReportFilter.isLoading && defaultFilter ? defaultFilter.name : undefined;
        return defaultFilterToApply;
    }

    return (<div className='user-assignment-content signatureflow-report'>
                <div>

                    <SignatureDraftReportsHeader
                        screenId={ScreenIds.SignatureDraft}
                        pageTitle={props.pageTitle}
                        onDeleteDocuments={onDeleteDocumentsOpen}
                        onSetAccessOpen={onSetAccessOpen}
                        selectedDocumentCount={selectedDocumentIds().length}
                        onAssignToOpen={onAssignToOpen}
                    />
                    <br />
                    <SignatureDraftReportTable
                        ref={SignatureDraftReportTableRef}
                        screenId={ScreenIds.SignatureDraft}
                        isLoading={props.DraftReportDocuments.isLoading}
                        onSortChange={onSortChange}
                        onSearchChange={onSearchChange}
                        pageNo={draftReportPageState.page}
                        onFilterChange={onFilterChange}
                        onFilterSave={onFilterSave}
                        onFilterNameChange={onFilterNameChange}
                        onSetDefaultFilter={onSetDefaultFilter}
                        onFilterDelete={onFilterDelete}
                        onSaveFilterHide={onSaveFilterHide}
                        onSaveFilterShow={onSaveFilterShow}
                        onRemoveDefaultFilter={onRemoveDefaultFilter}
                        onFilterUpdate={onFilterUpdate}
                        saveFilterShow={draftReportPageState.saveFilterShow}
                        filterList={props.signatureFlowReportFilter.draftReportFilter}
                        currentFilter={draftReportPageState.filter}
                        defaultFilter={getDefaultFilter()}
                        loadGrid={loadSignatureDraftReports}
                        totalRows={props.DraftReportDocuments.totalRowCount}
                        pageSize={draftReportPageState.pageSize} 
                        onRowSelect={onRowSelect} 
                        onSelectAll={onSelectAll} 
                        selectedRows={draftReportPageState.selectedRows} 
                        signatureReportsData={props.DraftReportDocuments.signatureFlowReportTableModel}
                        onDeleteDocument={onDeleteDocumentsOpen}
                        onSetAccessOpen={onSetAccessOpen}
                        onPageReload={onPageReload}
                        showBulkSelectionMessage={draftReportPageState.showBulkSelectionMessage}
                        onBulkSelectionChange={onBulkSelectionChange}
                        onDocumentDetailsOpen={onDocumentDetailsOpen}  //need to implement popup for document type
                        onReportProblemOpen={onReportProblemOpen}
                        onResume={onResume}
                        userOfficeLocation={Helper.setUserOfficeLocation(props.userOfficeLocationState.userLocation)}
                    />

                    <br />
                    <div style={{height:'35px'}}>
                        <Pagination
                            totalCount={draftReportPageState.totalRecordsCount}
                            pageIndex={draftReportPageState.page}
                            pageSize={draftReportPageState.pageSize}
                            defaultPageSize={pageSize}
                            goToPage = {onPageChange}
                            sizePerPageList={SizePerPageList}
                            showSizePerPageList={true}
                            showGoToPages={true}
                            showpageCount={false}
                            ShowListAndGoto={true}
                        />
                    </div>
                </div>
                <SignatureFlowReportSetAccessModal
                    showEditAccess={draftReportPageState.setAccessState.show}
                    onCancel={onSetAccessCancel}
                    selectedReports={draftReportPageState.setAccessState.selected as ISignatureFlowReportDocument[]}
                    count={draftReportPageState.selectedRows.length}
                    users={props.userStore.users}
                    onApplyAccess={onSetAccessSave}
                    setAccessHeaderTextDisplay={draftReportPageState.setAccessHeaderTextDisplay}
                />

                <SignatureFlowReportDocumentModal
                    show={draftReportPageState.documentDetailsState.show}
                    signatureReport={draftReportPageState.documentDetailsState.model as ISignatureFlowReportDocument}
                    onCancel={onDocumentDetailsCancel}
                />

                <SignatureAssignToModal 
                    showAssignTo={draftReportPageState.assignToState.show}
                    onCancel={onAssignToCancel}
                    selectedReports={draftReportPageState.assignToState.selected as ISignatureFlowReportDocument[]}
                    count={draftReportPageState.selectedRows.length}
                    users={props.userStore.users}
                    onSubmit={onAssignedToSave}
                    assignedUser={assignedUser}
                />

            <ReportProblemModal
                onCancel={onReportProblemCancel}
                sfModel={draftReportPageState.reportProblem.model}
                onReportProblemSave={onReportProblemSave}
                show={draftReportPageState.reportProblem.show}
                loggedInCPA={props.profile}
                companyName={props.companyData.companyProfile.companyInfo.companyName}
                reportProblemType={ReportProblemTypes.SignatureSpecific}
                hideStatus={true}
                hideSentBy={true}
            />
            </div>)
}
