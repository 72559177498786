import {  addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../../index';
import { actionTypes } from '../../ActionTypes';
import { API_BASE_URL } from '../../../../utils/AppConstants';
import { StatusType, NotificationAction } from '../../common/NotificationStore';
import { handleBlob, handleResponse } from '../../Library';
import {
    ResetArchiveReportDocuments,RequestArchiveReportDocuments,
    RequestSignatureFlowReportDocumentPagesAction, ReceiveSignatureFlowReportDocumentPagesAction,ReceiveArchiveReportDocuments,
    ReceiveSignatureFlowReportDocumentPopupModal, ReceiveArchiveReportDocument, SignatureArchiveReportSendReminder,
} from '../../ReportsCommon/KnownTypes';
import { ISignatureFlowReportTableModel, ISignatureFlowReportState } from '../../../models/SignatureFlowReportTableState';
import { ISignatureFlowReportDocument, ISignerModel } from '../../../models/SignatureFlowReportState';
import { HeaderResourceId, SignatureFlowReportConstants } from '../../../components/helper/Constants';
import { IClient } from '../../../models/SigningInfo';
import { validateError } from '../../../components/helper/Validations';

import { logger } from '../../../../routes/LoggedIn';

type KnownAction =
    RequestArchiveReportDocuments |
    ReceiveArchiveReportDocuments |
    ResetArchiveReportDocuments |
    RequestSignatureFlowReportDocumentPagesAction |
    ReceiveArchiveReportDocument |
    ReceiveSignatureFlowReportDocumentPagesAction |
    ReceiveSignatureFlowReportDocumentPopupModal |
    NotificationAction |
    SignatureArchiveReportSendReminder;

type dispatchAction =
    RequestArchiveReportDocuments |
    ReceiveArchiveReportDocuments |
    ReceiveArchiveReportDocument |
    ResetArchiveReportDocuments |
    SignatureArchiveReportSendReminder ;

export const actionCreators = {
    requestSignatureArchiveDocuments: (query: string, reload: boolean = false, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        if (reload || query !== state.ArchivedReportDocuments.query) {
            let page = state.signatureFlowReportsDocumentPages[query];
            if (!reload && page) {
                dispatch({ type: actionTypes.REQUEST_ARCHIVE_REPORT_DOCUMENTS, query: query });
                dispatch({ type: actionTypes.RECEIVE_ARCHIVE_REPORT_DOCUMENTS, query: query, table: page.signatureFlowReportTableModel });
                return;
            }
            const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReports/GetSignatureArchiveReports' + query, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<ISignatureFlowReportTableModel>)
                .then(data => {
                    if (callback) {
                        callback();
                    }
                    let updatedState = getState();
                    if(updatedState.ArchivedReportDocuments.query == query){ //This condition is added whatever the latest query (call) is, it should be updated in the store.
                        dispatch({ type: actionTypes.RECEIVE_ARCHIVE_REPORT_DOCUMENTS, query: query, table: data });
                        dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES, query: query, table: data, totalRowCount: data.count });
                        dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_POPUP_MODAL, signatureFlowReportDetails: data.documents });
                    }    
                })
                .catch((error) => {
                    const message = validateError(error);
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: message, statusType: StatusType.Error, statusCode:error?.status });

                    logger.trackError(`requestSignatureArchiveDocuments failed for the request having query ${JSON.stringify(query)} with error ${message}`)
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_ARCHIVE_REPORT_DOCUMENTS, query: query });
            dispatch({ type: actionTypes.REQUEST_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES, query: query });
        }
    },
    exportSignatureArchiveReportsToExcel: (query: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReports/ExportExcelSignatureArchiveReports' + query, { credentials: 'include' })
            .then(handleBlob)
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.setAttribute('download', 'SignatureArchivedReports.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode && link.parentNode.removeChild(link);
                if (callback) {
                    callback();
                }
            }).catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: SignatureFlowReportConstants.ExportExcel.Error, statusType: StatusType.Error, statusCode:error?.status });
                if (callback) {
                    callback();
                }
                logger.trackError(`exportSigantureArchiveReportsToExcel failed for the request having query ${query} with error ${validateError(error)}`)
            });
        addTask(fetchTask);
    },
    updateSignatureArchiveReportDocuments: (signingReport: ISignatureFlowReportDocument, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RECEIVE_ARCHIVE_REPORT_DOCUMENT, id: signingReport.signingId, signatureFlowReportDetails: signingReport });
        if (callback) {
            callback();
        }
    },
    updateArchiveDocumentNote: (signingReport: ISignatureFlowReportDocument, urlPath: string, isDelete?: boolean, isSave?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const fetchTask = fetch(urlPath, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                },
                body: JSON.stringify({
                    id: signingReport.signingId,
                    message: signingReport.documentNote
                })
            }).then(handleResponse)
                .then(() => {
                    actionCreators.updateSignatureArchiveReportDocuments(signingReport);
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: isDelete ? SignatureFlowReportConstants.DocumentNote.DeleteSuccess :
                            isSave ? SignatureFlowReportConstants.DocumentNote.SaveSuccess :
                                SignatureFlowReportConstants.DocumentNote.UpdateSuccess,
                        statusType: StatusType.Success
                    });
                })
                .catch((error) => {
                    const message = validateError(error);
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: SignatureFlowReportConstants.DocumentNote.Error,
                        statusType: StatusType.Error, statusCode:error?.status
                    });

                    logger.trackError(`updateArchiveDocumentNote failed for the request having parameters ${JSON.stringify(signingReport)} with error ${message}`)
                });
            addTask(fetchTask);
    },
    updateArchiveSignerEmail: (signingReport: ISignatureFlowReportDocument, signers: ISignerModel[], resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReports/UpdateSignersEmail', {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                    [HeaderResourceId]: resourceId
                },
                body: JSON.stringify({
                    signingId: signingReport.signingId,
                    signers: signers
                })
            }).then(handleResponse)
                .then((data: Array<IClient>) => {

                    signingReport?.signerInfo?.forEach((si) => {
                        data?.filter(res => res?.emailAddress === si?.signerEmail)
                            .map(m => si.clientGuid = m.clientGuid.toString());
                    });

                    actionCreators.updateSignatureArchiveReportDocuments(signingReport);
                    let state = getState().ArchivedReportDocuments;
                    let table = state.signatureFlowReportTableModel;
                    let id: number = table.documents.findIndex(x => x.signingId == signingReport.signingId);
                    table.documents[id] = signingReport;
                    dispatch({ type: actionTypes.RECEIVE_ARCHIVE_REPORT_DOCUMENTS, query: state.query, table: table });
                    dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES, query: state.query, table: table, totalRowCount: table.count });
                    dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_POPUP_MODAL, signatureFlowReportDetails: table.documents });
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: SignatureFlowReportConstants.UpdateSignatureSigners.Success,
                        statusType: StatusType.Success
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: SignatureFlowReportConstants.UpdateSignatureSigners.Error,
                        statusType: StatusType.Error,
                        statusCode:error?.status
                    });

                    logger.trackError(`updateArchiveSignerEmail failed for the request having parameters ${JSON.stringify(signingReport)} and ${JSON.stringify(signers)} with error ${validateError(error)}`)
                });
            addTask(fetchTask);
    }
}

const unloadedState: ISignatureFlowReportState = {
    query: '',
    signatureFlowReportTableModel: {
        documents: [],
        count: 0
    } as ISignatureFlowReportTableModel,
    totalRowCount: 0,
    isLoading: false
} as ISignatureFlowReportState

export const reducer: Reducer<ISignatureFlowReportState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as dispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_ARCHIVE_REPORT_DOCUMENTS:
            return {
                ...unloadedState,
                query: action.query,
                isLoading: true
            } as ISignatureFlowReportState;

        case actionTypes.RECEIVE_ARCHIVE_REPORT_DOCUMENTS:
            return {
                query: action.query,
                signatureFlowReportTableModel: action.table,
                totalRowCount: action.table.count,
                isLoading: false
            } as ISignatureFlowReportState;

        case actionTypes.RECEIVE_ARCHIVE_REPORT_DOCUMENT:
            let newState: ISignatureFlowReportState = { ...state }
            let id: number = newState.signatureFlowReportTableModel.documents.findIndex(x => x.signingId == action.id);
            newState.signatureFlowReportTableModel.documents[id] = action.signatureFlowReportDetails;
            return newState;

        case actionTypes.RESET_ARCHIVE_REPORT_DOCUMENTS:
            return clearSignatureArchivedReports(state);
        
        case actionTypes.SIGNATURE_ARCHIVE_REPORT_SEND_REMINDER:
            let signatureFlowReportState: ISignatureFlowReportState = { ...state }
            action.sendReminder.forEach((item, index) => {
                const rowIndex: number = signatureFlowReportState.signatureFlowReportTableModel.documents.findIndex(i => i.signingId == item.documents[0].signingId);
                signatureFlowReportState.signatureFlowReportTableModel.documents[rowIndex].lastReminder = new Date();
            });
            return signatureFlowReportState;

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }
    return state;
};

function clearSignatureArchivedReports(state: ISignatureFlowReportState): ISignatureFlowReportState {
    return {
        ...unloadedState,
        query: state.query,
        isLoading: true
    } as ISignatureFlowReportState;
}


