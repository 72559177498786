import { ReportFilterType, SortDirections } from "../components/common/Filters";
import { getCurrentTaxYear } from "../components/helper/HelperFunctions";
import { initialRestoreDocumentState } from "./SignatureFlowRecycleReportTableState";
import { ISignatureFlowReportFilter } from "./SignatureFlowReportState";
import { IPopupStateMultiple, IPopupStateSingle, IRestorePopupState } from "./SignatureFlowReportTableState";


export interface ISignatureFlowDraftReportPageState {
    page: number;
    pageSize: number;
    selectedRows: number[];
    filter: ISignatureFlowReportFilter;
    searchString: string;
    sortName: string;
    sortOrder: string;
    saveFilterShow: boolean,
    filterDocumentType: string;
    filterDraftOn: Date | undefined;
    filterAssignedTo: string;
    filterTaxYear: any;
    filterRecipientName: string;
    filterClientId: string;
    filterOfficeLocation: string;
    showLoader: boolean;
    showMainLoader: boolean;
    isBulkSelectionEnabled: boolean;
    deSelectedRows: number[];
    showBulkSelectionMessage: boolean;
    setAccessHeaderTextDisplay: boolean;

    //pop-ups
    setAccessState: IPopupStateMultiple;
    assignToState: IPopupStateMultiple
    signerDetailsState: IPopupStateSingle;
    documentDetailsState: IPopupStateSingle;
    recipientDetailsState: IPopupStateSingle;
    cancelDocumentState: IPopupStateSingle;
    deleteReturnsState: IPopupStateSingle;
    reportProblem: IPopupStateSingle;
    restoreDocumentState: IRestorePopupState;
    totalRecordsCount: number;
    stateCallback: () => void | undefined
}

export const GetInitialDraftReportPageState = (pageSize: number):ISignatureFlowDraftReportPageState  => {
    return {
        page: 1,
        pageSize: pageSize,
        filter: ({
            name: '',
            searchText: '',
            sort: {
                column: "",
                direction: SortDirections.None
            },
            fields: {},
            isDefault: 0,
            filterType: ReportFilterType.None,
            isDefaultFilter: false,
            isMasterFilter: false,
        }),
        selectedRows: [],
        searchString: "",
        sortName: "",
        sortOrder: "desc",
        saveFilterShow: false,
        filterDocumentType: "",
        filterTaxYear: getCurrentTaxYear(),
        filterOfficeLocation: "",
        filterRecipientName: "",
        filterAssignedTo: "",
        filterDraftOn: undefined,
        filterClientId: "",
        showMainLoader: false,
        showLoader: false,
        setAccessHeaderTextDisplay: false,

        //Signer Details State
        signerDetailsState:
        {
            model: undefined,
            show: false,
            saveCheckId: undefined
        },

        //Document Details State
        documentDetailsState:
        {
            model: undefined,
            show: false,
            saveCheckId: undefined
        },

        //Set Access Detail State
        setAccessState: {
            selected: undefined,
            show: false,
            saveCheckIds: undefined,
            remove: false
        },

        //Assign To Detail State
        assignToState: {
            selected: undefined,
            show: false,
            saveCheckIds: undefined,
            remove: false
        },

        //Recipient Details State
        recipientDetailsState:
        {
            model: undefined,
            show: false,
            saveCheckId: undefined
        },

        //Cancel Document State
        cancelDocumentState: {
            model: undefined,
            show: false,
            saveCheckId: undefined
        },
        //Delete Document State
        deleteReturnsState: {
            model: undefined,
            show: false,
            saveCheckId: undefined
        },

         //Restore Document State
        restoreDocumentState: initialRestoreDocumentState,
        //
        isBulkSelectionEnabled: false,
        deSelectedRows: [],
        showBulkSelectionMessage: false,
        totalRecordsCount: 0,

        //Report Problem State
        reportProblem:
        {
            model: undefined,
            show: false,
            saveCheckId: undefined
        },
        stateCallback: null,
    };
}