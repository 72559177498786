import * as React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import * as SignatureFlowSettingsStore from '../../../store/SignatureFlowSettingsStore';
import { GreaterThanSvg } from '../../svg/CustomSvgIcons';
import { ResourceIdConstants, ScreenIds } from '../../helper/RBACConstants';
import { ReminderEmailInstructions } from './ReminderEmailInstructions';
import { DownloadEmailInstructions } from './DownloadEmailInstructions';
import { ClientInstructionType, IInstruction, IClientInstrucionsDictionary } from "../../../store/settings/ClientInstructions/ClientInstructionsActionTypes";
import * as SignatureFlowClientInstructionsStore from '../../../store/settings/ClientInstructions/ClientInstructionsStore';
import { ISignatureFlowSettings } from '../../../models/SignatureFlowSettings';

type IClientInstructionsProps =
    {
        signatureFlowSettingsData: SignatureFlowSettingsStore.ISignatureFlowSettingsData,
        ClientInstructionsData: SignatureFlowClientInstructionsStore.IClientInstrutionsData
    }
    & typeof SignatureFlowSettingsStore.actionCreators
    & typeof SignatureFlowClientInstructionsStore.actionCreators
    & RouteComponentProps<{}>;

export const ClientInstructions:React.FC <IClientInstructionsProps> = (props) => {

    useEffect(() => {
        props.requestCLientInstructions(`${ScreenIds.Settings}${ResourceIdConstants.GeneralSettings}`);
        props.requestCompanySignatureSettings();
    },[]);

    const getInstructionList  = (instructionType : ClientInstructionType ) =>{
        const instructionList = {};
        for (let value in props.ClientInstructionsData.clientInstructions) {
            if(props.ClientInstructionsData.clientInstructions[value]?.InstructionDetails?.instructionType === instructionType){
                instructionList[value] = props.ClientInstructionsData.clientInstructions[value];
            }
        }
        return instructionList;
    }

    const getDefaultInstructionId = (type : string ) =>{
        const InstructionType :string = 'default' + type;
        const ClientInstructionSettings = props.signatureFlowSettingsData && props.signatureFlowSettingsData.signatureFlowSettings
                                              && props.signatureFlowSettingsData.signatureFlowSettings.clientInstructionSettings ? 
                                                   props.signatureFlowSettingsData.signatureFlowSettings.clientInstructionSettings  : {}
        return ClientInstructionSettings[InstructionType] || 0;
    }

    const deleteInstructionDetail = (instructionDetails : IInstruction ) => {
        props.deleteInstructionDetail(instructionDetails);
    }

    const updateInstructionDetail = (instructionDetails : IInstruction ) => {
        props.updateInstructionDetail(instructionDetails);
    }

    const saveInstructionDetail = (instruction : IInstruction, signatureFlowSettings : ISignatureFlowSettings, isSetDefault : boolean) => {
        props.saveInstructionDetail(instruction, signatureFlowSettings, isSetDefault);
    }

    return <div className="new-main-content font14 client-instructions-conatiner" data-resource-id={`${ScreenIds.Settings}${ResourceIdConstants.GeneralSettings}`}>
                <div className="row" style={{ marginLeft: 0, marginBottom:'20px' }}>
                    <span className='fontWeight700 text-primary'>Settings</span> 
                    <span className='padL08'><GreaterThanSvg/></span>
                    <span className='padL08'>Client Instructions</span>
                </div>
                <div className="row" style={{ marginLeft: 0, marginBottom:'25px' }}>
                    <div className="col-sm-10 padL00 float-left">
                        <h2 style={{fontSize: '20px'}}  className='fontWeight700'>Client Instructions</h2>
                    </div>
                </div>

                <ReminderEmailInstructions
                  instructionsList = {getInstructionList(ClientInstructionType.ReminderInstruction)}
                  defaultInstructionId = {getDefaultInstructionId("ReminderInstruction")}
                  deleteInstructionDetail = {deleteInstructionDetail}
                  signatureFlowSettingsData = {props.signatureFlowSettingsData}
                  updateCompanySettings = {props.updateCompanySettings}
                  updateInstructionDetail = {updateInstructionDetail} 
                  saveInstructionDetail = {saveInstructionDetail}
                />

                <hr/>

                <DownloadEmailInstructions
                  instructionsList = {getInstructionList(ClientInstructionType.DownloadInstruction)}
                  defaultInstructionId = {getDefaultInstructionId("DownloadInstruction")}
                  deleteInstructionDetail = {deleteInstructionDetail}
                  signatureFlowSettingsData = {props.signatureFlowSettingsData}
                  updateCompanySettings = {props.updateCompanySettings}
                  updateInstructionDetail = {updateInstructionDetail} 
                  saveInstructionDetail = {saveInstructionDetail}
                />
                
        </div>;
};