import React from "react";
import { ReactComponent as SupportIcon } from "../../../../images/support.svg";

interface IChildComponentsProps {
    openForethoughtModel: () => void;
}

export const ChildComponents: React.FC<IChildComponentsProps> = ({openForethoughtModel}) => {
    return (
        <>
         <SupportIcon
                className="header-icon"
                onClick={openForethoughtModel}
                title="Support"
            ></SupportIcon>
        </>);
};