export interface SasContainer {
    sasGuid: string
}

export enum UploadStatus {
    Wait = 0,
    Initiating = 1,
    Uploading = 2,
    Uploaded = 3,
    Error = 4,
    Converting = 5
}