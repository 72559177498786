import * as React from 'react';
import { addTask } from 'domain-task';
import { RouteComponentProps } from 'react-router';
import * as signalR from '@aspnet/signalr';
import * as CompanyStore from '../../store/Company/CompanyStore';
import { VenusNotifier } from '../../components/helper/VenusNotifier';
import { handleResponse } from '../../store/Library';
import * as DeliveredReportStore from '../../store/ReportsStore/DeliveredReport/DeliveredReportsStore';
import * as SignatureFlowReportState from '../../models/SignatureFlowReportTableState';
import * as DocumentStore from '../../store/DocumentStore';
import * as MyDownloads from '../../store/MyDownloadsStore';
import { API_BASE_URL } from '../../../utils/AppConstants';
import store from "../../store"
import { LogoutCause } from 'src/SignatureFlow/models/user/UserSettingsModels';
import { userAutoLogout } from 'src/SignatureFlow/store/auth/actions';
import * as RecycleReportStore from '../../store/ReportsStore/RecycleReport/RecycleReportStore';
import { IAuthState } from 'src/SignatureFlow/store/auth/reducer';
const isEqual = require("react-fast-compare");
//const localApiBaseUrl: any = 'http://localhost:7071';
//const apiBaseUrl: any = 'https://signalrhubapptest.azurewebsites.net/';
const bearerPrefix: string = 'Bearer ';

export interface ISignalRState {
    apiBaseUrl: any
};
type SignalRProps =
    {
        UserProfile : IAuthState,
        DeliveredReportDocuments: SignatureFlowReportState.ISignatureFlowReportState,
    }
    & typeof CompanyStore.actionCreators
    & typeof RecycleReportStore.actionCreators 
    & typeof DeliveredReportStore.actionCreators
    & typeof DocumentStore.actionCreators
    & typeof MyDownloads.actionCreators
    & RouteComponentProps<{}>

export interface INotificationMessage {
    companyId: number;
    notifictionType: NotifictionType;
    data: any;
    logoutCause:LogoutCause;
    users:number[]
}

export enum NotifictionType {
    None = "None",
    BulkDeleteSuccess = "BulkDeleteSuccess",
    BulkDeleteError = "BulkDeleteError",
    BulkArchiveSuccess = "BulkArchiveSuccess",
    BulkArchiveError = "BulkArchiveError",
    BulkRestoreSuccess = "BulkRestoreSuccess",
    BulkRestoreError = "BulkRestoreError",
    ExtractSignatureDocumentControlsSuccess = "ExtractSignatureDocumentControlsSuccess",
    ExtractSignatureDocumentControlsError = "ExtractSignatureDocumentControlsError",
    BulkRecycledDeleteSuccess = "BulkRecycledDeleteSuccess",
    BulkRecycledDeleteError = "BulkRecycledDeleteError",
    UserPrivilegesChangeSuccess = "UserPrivilegesChangeSuccess",
    BulkDownloadsZipReady = "BulkDownloadsZipReady",
    BulkChangeOfficeLocationSuccess = "BulkChangeOfficeLocationSuccess",
    BulkChangeOfficeLocationError = "BulkChangeOfficeLocationError",
}

export class SignalRWebSocket extends React.Component<SignalRProps, ISignalRState> {

    private user: string = '';
    private group: string = '';
    private token: any = '';
    state = {
        apiBaseUrl: ''
    };

    componentDidMount() {
        this.getToken();
    }

    private getToken() {
        const user = store.getState().auth.user;
        this.token = user.access_token;
        this.getWebSocketUrl();
    }

    private getWebSocketGroup = (companyId: number) => {
        var group = "00000000" + companyId;
        return 'ssr' + group.substr(group.length - 8);
    }

    shouldComponentUpdate(nextProps: SignalRProps, nextState: ISignalRState) {
        return (((nextProps.UserProfile.user?.profile.user_id !== this.props.UserProfile.user?.profile.user_id)
            && (nextProps.UserProfile.user.profile.user_id !== 0)
            && nextProps.UserProfile.user.profile.company_id !== 0)
            || !isEqual(this.state, nextState));
    }

    private signalRConnectionInit = () => {
        let fetchTask = fetch(`${this.state.apiBaseUrl}/signalr/negotiate`, {
            method: 'POST',
            headers: {
                'x-ms-signalr-userid': this.user,
                'Authorization': bearerPrefix + this.token,
                'ClientType': "SIG-CPA"
            }
        }).then(handleResponse)
            .then((info: any) => {
                info.accessToken = info.accessToken || info.accessKey;
                info.url = info.url || info.endpoint;

                const options = {
                    accessTokenFactory: () => info.accessToken
                };

                const connection = new signalR.HubConnectionBuilder()
                    .withUrl(info.url, options)
                    .configureLogging(signalR.LogLevel.Information)
                    .build();
                connection.on('SignatureBulkOperationUpdated', this.onSignatureNotificationUpdated);
                connection.on('SignatureDocumentControlsExtracted', this.onSignatureNotificationUpdated);
                connection.on('UserPrivilegesChanged', this.onUserPrivilegesChanged);
                connection.onclose(() => {
                    console.log('disconnected');
                    this.startConnection(connection);
                });
                this.startConnection(connection);

            }).catch((reason) => {
                console.log(reason);
            })
        addTask(fetchTask);
    }

    private startConnection = (connection: any) => {
        const _self: any = this;
        console.log('connecting...');
        connection.start()
            .then(function () {
                console.log('connected!');
                _self.addGroup();
                connection.invoke('getConnectionId')
                    .then(function (connectionId: any) {
                        // Send the connectionId to controller
                    });
            })
            .catch(function (err: any) {
                console.error(err);
                setTimeout(_self.startConnection, 5000, connection);
            });
    }

    private getCookieValue = (key: string) => {
        const b: any = document.cookie.match('(^|[^;]+)\\s*' + key + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }

    private addGroup = () => {
        const fetchTask = fetch(`${this.state.apiBaseUrl}/signalr/AddToGroup`, {
            method: 'POST',
            headers: {
                'x-ms-signalr-userid': this.user,
                'Authorization': bearerPrefix + this.token,
                'ClientType': "SIG-CPA"
            },
            body: JSON.stringify({
                recipient: this.user,
                groupname: this.group
            })
        }).then((resp: any) => {
            if (resp.status == 200) {
                console.log("User added to the group successfully")
            }
        });
        addTask(fetchTask);
    }

    private removeGroup = () => {
        const fetchTask = fetch(`${this.state.apiBaseUrl}/signalr/RemoveFromGroup`, {
            method: 'POST',
            headers: {
                'x-ms-signalr-userid': this.user,
                'Authorization': bearerPrefix + this.token,
                'ClientType': "SIG-CPA"
            },
            body: JSON.stringify({
                recipient: this.user,
                groupname: this.group
            })
        }).then((resp: any) => {
            if (resp.status == 200) {
                console.log("User removed from group successfully")
            }
        });
        addTask(fetchTask);
    }

    private getWebSocketUrl = () => {
        const fetchTask = fetch(API_BASE_URL + 'api/WebSocket/GetWebSocketConnectionUrl', {
            method: 'GET',
            credentials: 'include',

        }).then(handleResponse)
            .then((resp: any) => {
                this.setState({ apiBaseUrl: resp });
            });
        addTask(fetchTask);
    }
    private onSignatureNotificationUpdated = (notificationMessage: INotificationMessage) => {
        let errorMessage = "";
        switch (notificationMessage.notifictionType) {
            case NotifictionType.BulkDeleteSuccess:
            case NotifictionType.BulkArchiveSuccess:
            case NotifictionType.BulkChangeOfficeLocationSuccess:    
                this.props.updateSignatureDeliveredReports();
                break;
            case NotifictionType.BulkRestoreSuccess:
            case NotifictionType.BulkRecycledDeleteSuccess:
                this.props.updateRecycledSignatureReports();
                break;
            case NotifictionType.BulkDeleteError:
            case NotifictionType.BulkArchiveError:
            case NotifictionType.BulkChangeOfficeLocationError:    
                this.props.updateSignatureDeliveredReports();
                errorMessage = this.getNotificationMessage(notificationMessage.notifictionType);
                VenusNotifier.Error(errorMessage, null);
                break;
            case NotifictionType.BulkRestoreError:
            case NotifictionType.BulkRecycledDeleteError:
                this.props.updateRecycledSignatureReports();
                errorMessage = this.getNotificationMessage(notificationMessage.notifictionType);
                VenusNotifier.Error(errorMessage, null);
                break;
            case NotifictionType.ExtractSignatureDocumentControlsSuccess:
                this.props.GetRecognizedSignatureDocumentInfoFromBlob(notificationMessage.data);
                break;
            case NotifictionType.ExtractSignatureDocumentControlsError:
                errorMessage = this.getNotificationMessage(notificationMessage.notifictionType);
                VenusNotifier.Error(errorMessage, null);
                break;
            case NotifictionType.BulkDownloadsZipReady:
                this.props.fetchMyDownloads(1, 20, ()=>{});
                break;  
        }
    }
    private onUserPrivilegesChanged = (notificationMessage: INotificationMessage) => {
        const authUser = store.getState().auth;
        if (notificationMessage.users && notificationMessage.users.includes(this.props.UserProfile.user.profile.user_id) && !authUser?.userPrivilegesChanged) {
            store.dispatch(userAutoLogout(notificationMessage.logoutCause as LogoutCause));
        }
    }

    getNotificationMessage = (notificationType: NotifictionType): string => {
        switch (notificationType) {
            case NotifictionType.BulkArchiveError: return "Failed to archive the selected document(s)!";
            case NotifictionType.BulkDeleteError: return "Failed to delete the selected document(s)!";
            case NotifictionType.BulkRestoreError: return "Failed to restore the selected document(s)!";
            case NotifictionType.BulkRecycledDeleteError: return "Failed to delete the selected document(s)!";
            case NotifictionType.ExtractSignatureDocumentControlsError: return "Failed to extract the signture document controls!";
            case NotifictionType.BulkChangeOfficeLocationError: return "Failed to change the office location of the selected document(s)!";
            default:
                return "";
        }
    }

    public render() {
        if (this.state.apiBaseUrl != ""
            && this.props.UserProfile.user != null
            && this.props.UserProfile.user.profile
            && this.props.UserProfile.user.profile.user_id != 0
            && this.props.UserProfile.user.profile.company_id != 0) {
            this.user = this.props.UserProfile.user.profile.company_id + '_' + this.props.UserProfile.user.profile.user_id;            
            this.group = this.getWebSocketGroup(this.props.UserProfile.user.profile.company_id);
            this.signalRConnectionInit();
        }
        return (<div />);
    }
}
export default SignalRWebSocket;
