import * as React from 'react';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { DefaultDownloadInfoType } from 'src/SignatureFlow/models/user/UserSettingsModels';
import { RadioButtonComponent } from '../../common/RadioButtonComponent';
interface DownloadTypeOptionProps {
    show: boolean,
    onCancel: () => void;
    selectedDownloadType: DefaultDownloadInfoType
    onDownloadOptionChange(selectedValue: any): void;
    onDownloadOptionSave(selectedValue: any): void;
    showLoader: boolean;
}


interface DownloadTypeOptionState {
    saving: boolean;
}

export class DownloadTypeOption extends React.Component<DownloadTypeOptionProps, DownloadTypeOptionState>
{
    state = {
        saving: false
    }

    public render() {

        let isLoaded: boolean = true;
        let modalBody =
            <div className="padR30 bulk-downloads-options">
                <div style={{ position: 'relative', padding: '15px' }}>
                    <div className=" padR30" style={{ marginLeft: '15px' }}>
                        <RadioButtonComponent
                            id={"DownloadNow"}
                            checked={this.props.selectedDownloadType == DefaultDownloadInfoType.DownloadNow}
                            onChange={this.props.onDownloadOptionChange}
                            value={"DownloadNow"}
                            name="defaultDownload"
                            text={"Now"}
                        />
                    </div>
                    <div className=" padR30" style={{ marginLeft: '15px' }}>
                        <RadioButtonComponent
                            id={"DownloadToMyDownloads"}
                            checked={this.props.selectedDownloadType == DefaultDownloadInfoType.DownloadToMyDownloads}
                            onChange={this.props.onDownloadOptionChange}
                            value={"DownloadToMyDownloads"}
                            name="defaultDownload"
                            text={"Later (My Downloads)"}
                        />
                    </div>
                </div >
            </div>

        return (
            <Modal className="download-options-modal" show={this.props.show} onHide={this.props.onCancel}>
                <Modal.Header closeButton >
                    <Modal.Title className='custom-modal-header'>
                    Download
                    </Modal.Title>
                </Modal.Header>
                <LoadingOverlay>
                    <Modal.Body>
                        {modalBody}
                    </Modal.Body>
                    <Loader loading={this.props.showLoader} text={"Downloading please wait..."} />
                </LoadingOverlay>

                
                <Modal.Footer>
                    <Button variant='default'
                        onClick={this.props.onCancel}
                        className='btn-white modal-footer-button-cancel'>
                        Cancel
                    </Button>
                    <Button 
                        onClick={this.props.onDownloadOptionSave}
                        className={(this.props.selectedDownloadType === undefined || 
                            this.props.selectedDownloadType==DefaultDownloadInfoType.PromptMeEveryTime)?
                            'btn btn-info modal-footer-button-save disabled':'btn btn-info modal-footer-button-save'}
                        variant="info">
                        Download
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}