import * as React from 'react';
import { Col, Row, Popover, OverlayTrigger  } from 'react-bootstrap';
import { PdfPageViewer } from './PdfPageViewer';
import { IPdfDocumentFacade } from '../../../Core/Utilities/PdfDocumentFacade';
import { IPageItem } from '../helper/PdfHelper';		
import { PageTitle } from './PageTitle';
import * as Constants from '../helper/Constants';

export interface IPageThumbViewProps {
    pages: IPageItem[];
    pdfDocument: IPdfDocumentFacade;
    onPageSelect?: (pageNo: number) => void;
    onPageDelete?: (pageNo: number) => void;
    getTitle?: (pageTitle: string, id: number) => void;
    tabType?: number;
}
export interface IPagesState {
    pageNo: number;
}

export class PageThumbView extends React.Component<IPageThumbViewProps, IPagesState> {
    constructor(props: IPageThumbViewProps) {
        super(props);
        this.state = {
            pageNo: -1
        }

        this.onPageDelete = this.onPageDelete.bind(this);
    }
    onPageDelete() {
        this.props.onPageDelete ? this.props.onPageDelete(this.state.pageNo) : undefined
        document.body.click();
    }
    public closeDeleteConfirmPopHover() {
        document.body.click();
    }
    public render() {
        const popover = (<Popover id="popover-refund-delete-confirm" title={Constants.ClientInfoTab.AddedRefund.DeleteConfirm} style={{ width:'500%'}}>
            <button
                className="btn btn-only btn-success"
                onClick={this.onPageDelete}
                title="Yes"
                data-test-auto="644432DD-2D8A-4597-B7DE-E01EAF0C67B1"
                style={{ marginLeft: "5px" }}>
                Yes
                </button>
            <button
                onClick={this.closeDeleteConfirmPopHover}
                title="No"
                data-test-auto="9138EE23-BDCF-4D94-8264-6E707800C214"
                className="btn btn-only btn-danger"
                style={{ marginLeft: "5px" }}>
                No
                </button>
        </Popover>
        )

        return (<div className="pageThumbView" >
            {
                this.props.pages.length == 0 ? <div className="textalign-center">No Content</div> :
                    (this.props.pages.map((form, i) => {
                        let rowId = "deletePage" + form.pageNo;
                        return <div
                            key={i + "" + form.pageNo}
                            className="marB05 cursor-pointer"
                            style={{ border: '1px solid gray' }}>
                            <Thumbnail
                                form={form}
                                pdfDocument={this.props.pdfDocument}
                                onPageSelect={this.props.onPageSelect}
                                getTitle={this.props.getTitle}
                            >
                            </Thumbnail>
                           
                                <Row className="marL0" style={{ marginRight: '-15px', padding: '2px 0px' }}>
                                    <Col sm={2} className="padL08 padR00">
                                        <i className={form.icon}></i>
                                    </Col>
                                    <Col sm={8} className="padLR-0 cursor-pointer">
                                        <PageTitle key={form.pageNo} form={form} />
                                    </Col>
                                    {
                                    form.isDeleteEnabled &&
                                    (<Col sm={2} className="padLR-0" id={rowId}>
                                        <OverlayTrigger rootClose trigger="click" placement="right" overlay={popover} container={document.getElementById(rowId)}>
                                            <i className={"fas fa-times cursor-pointer text-danger "}
                                                onClick={() => { this.setState({ pageNo: form.pageNo }) }}
                                                title={"Move Page " + form.pageNo + " to bin"}></i>
                                        </OverlayTrigger>
                                    </Col>)
                                    }
                                   
                                </Row>
                           
                        </div>
                    }))
            }
        </div>)
    }
};

interface IThumbnailProps {
    form: IPageItem;
    pdfDocument: IPdfDocumentFacade;
    onPageSelect?: (page: number) => void;
    getTitle?: (pageTitle: string, id: number) => void;
}
class Thumbnail extends React.Component<IThumbnailProps, {}> {
    public render() {
        return <div
            className="card"
            onClick={(e) => {
                this.props.onPageSelect && this.props.onPageSelect(this.props.form.pageNo);
                this.props.getTitle ? this.props.getTitle(this.props.form.pageTitle, this.props.form.id) : undefined;
            }}>     
            {
                <PdfPageViewer document={this.props.pdfDocument}
                    pageNo={this.props.form.pageNo} />

            }
            
        </div>
    }
};
