import * as React from 'react';
import { IPageItem } from '../helper/PdfHelper';

interface IPageTitleProps {
    form: IPageItem;
}
export class PageTitle extends React.Component<IPageTitleProps, {}> {
    public render() {
        const { form } = this.props;
        const parts = form.pageTitle ? form.pageTitle.split("###") : [];
        if (parts.length === 2) {
            return <div>
                <span title={parts[0]}
                    className={"pageTitle wordbreak ellipsis " + (form.className || "")}
                >{parts[0]}</span>
                <span title={parts[1]}
                    className={"float-right textalign-right "}>
                    {parts[1]}
                </span>
            </div>
        }
        return <div className={"pageTitle wordbreak ellipsis " + (form.className || "")} title={form.pageTitle}>{form.pageTitle}</div>
    }
};