import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '..';
import { actionTypes } from '../ActionTypes';
import { API_BASE_URL } from '../../../utils/AppConstants';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { handleResponse, handleBlob } from '../Library';
import * as SignatureReportStore from '../ReportsStore/DeliveredReport/DeliveredReportsStore';
import * as SigntureArchivereportStore from "../ReportsStore/ArchivedReport/ArchivedReportStore"
import {
    ReceiveSignatureFlowReportDocumentPopupModal, ReceiveSignatureFlowReportDocumentAccess,
    SignatureDeliveredReportSendReminder, ReceiveSignatureFlowReportCancelledDescription,SignatureArchiveReportSendReminder,
    SignatureFlowReportCancelDocument, ReceiveSignatureFlowReportAccessCode,
    GenerateSignatureFlowReportAccessCode, ReceiveSignatureFlowReportClientTracking,
    ReceiveSignedDocumentDownloadHistory, ReceiveUpdatedSignerModel,
    ReceiveSignedDocumentDownload, ReceiveSignedDocumentDownloadAll, ReceivereDraftReportAssignedTo
} from './KnownTypes';
import {
    ISignatureFlowReportDocument, IDocumentAccess, IMailData, ISignerModel,
    IAccessCode, IDocumentTransaction, IDownloadableDocuments,
    SignatureFlowReportSignatureStatus,
    IDocumentAssignedTo
} from '../../models/SignatureFlowReportState';
import { ArchiveSignatureConstants, DeleteSignatureConstants, HeaderResourceId, RestoreSignatureReturnsConstants, SignatureFlowReportConstants } from '../../components/helper/Constants';
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';
import { VenusNotifier } from '../../components/helper/VenusNotifier';
import { LoadingSigningInfoSettingsAction, ReceiveSigningInfoSettingsAction } from '../SigningInfoSettingsStore';

import { logger } from '../../../routes/LoggedIn';
import { ArchiveReportPaths, SignatureReportPaths } from '../../components/helper/UrlPaths';

type KnownAction = ReceiveSignatureFlowReportDocumentAccess |
    ReceivereDraftReportAssignedTo |
    SignatureDeliveredReportSendReminder |
    SignatureArchiveReportSendReminder |
    ReceiveSignatureFlowReportCancelledDescription |
    SignatureFlowReportCancelDocument |
    ReceiveSignatureFlowReportAccessCode |
    GenerateSignatureFlowReportAccessCode |
    ReceiveSignatureFlowReportClientTracking |
    ReceiveSignedDocumentDownloadHistory |
    ReceiveUpdatedSignerModel |
    ReceiveSignedDocumentDownload |
    ReceiveSignedDocumentDownloadAll |
    NotificationAction |
    LoadingSigningInfoSettingsAction |
    ReceiveSigningInfoSettingsAction |
    LoadingSigningInfoSettingsAction;

type dispatchAction =
    ReceiveSignatureFlowReportDocumentPopupModal |
    ReceiveSignatureFlowReportDocumentAccess |
    ReceivereDraftReportAssignedTo |
    ReceiveSignatureFlowReportCancelledDescription |
    ReceiveSignatureFlowReportAccessCode |
    ReceiveSignatureFlowReportClientTracking |
    SignatureFlowReportCancelDocument |
    ReceiveSignedDocumentDownloadHistory |
    ReceiveUpdatedSignerModel |
    ReceiveSignedDocumentDownload |
    ReceiveSignedDocumentDownloadAll;



export interface ISignatureFlowReportPopupDictionary {
    [index: string]: {
        signatureReport: ISignatureFlowReportDocument,
        isLoading: boolean,
        error: boolean,
        isSignersUpdated: boolean,
    }
}

export const actionCreators = {

    requestSignatureReportAccess: (ids: number[], urlPath: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const queryString = ids.map(id => `documentIds=${id}`).join('&');
        const url = `${urlPath}?${queryString}`;
        const fetchTask = fetch(url, {
            method: 'GET',
            credentials: 'include'
        }).
            then(handleResponse)
            .then(json => json as Promise<IDocumentAccess[]>)
            .then((data) => {
                dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_ACCESS, accessIds: data })
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                logger.trackError(`requestSignatureReportAccess failed for the request having ids ${JSON.stringify(ids)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    requestDraftReportAssignedTo: (ids: number[], urlPath: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const queryString = ids.map(id => `documentIds=${id}`).join('&');
        const url = `${urlPath}?${queryString}`;
        const fetchTask = fetch(url, {
            method: 'GET',
            credentials: 'include'
        }).
            then(handleResponse)
            .then(json => json as Promise<IDocumentAssignedTo[]>)
            .then((data) => {
                dispatch({ type: actionTypes.RECEIVE_DRAFT_REPORT_ASSIGN_TO, accessIds: data })
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                logger.trackError(`requestDraftReportAssignedTo failed for the request having ids ${JSON.stringify(ids)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    sendReminder: (sendReminderData: IMailData[], urlPath: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(urlPath, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(sendReminderData),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((response) => {
                let actionType = urlPath.includes('Archive') ? actionTypes.SIGNATURE_ARCHIVE_REPORT_SEND_REMINDER : actionTypes.SIGNATURE_DELIVERED_REPORT_SEND_REMINDER;
                dispatch({ type: actionType, sendReminder: sendReminderData });
                if (callback) {
                    callback();
                }
                dispatch({ type: actionTypes.RECEIVE_SIGNING_INFO_SETTINGS });

            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.SendReminder.Error,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                dispatch({ type: actionTypes.RECEIVE_SIGNING_INFO_SETTINGS });
                if (callback) {
                    callback();
                }
                logger.trackError(`sendReminder failed for the request having parameters ${JSON.stringify(sendReminderData)} with error ${error.message}`)
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.LOADING_SIGNING_INFO_SETTINGS, isLoading: true, message: '' });

    },

    requestCancelledDescription: (id: number, status: SignatureFlowReportSignatureStatus, urlPath: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${urlPath}/${id}?signatureStatus=${status}`, {
            method: 'GET',
            credentials: 'include'
        }).then(handleResponse)
            .then((data) => {
                dispatch({
                    type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_CANCELLED_DESCRIPTION, id: id, description: data
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                logger.trackError(`requestCancelledDescription failed for the request having parameters ${JSON.stringify({
                    id: id,
                    status: status
                })} with error ${error.message}`)
            });
        addTask(fetchTask);
    },
    cancelDocument: (signingReport: ISignatureFlowReportDocument, urlPath: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(urlPath, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify({
                signingInfoId: signingReport.signingId,
                signatureStatus: signingReport.signatureStatus,
                reason: signingReport.cancelledDocumentDescription
            })
        }).
            then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.SIGNATURE_FLOW_REPORT_CANCEL_DOCUMENT, signingReport: signingReport });
                let action: any =urlPath.includes('Archive') ? SigntureArchivereportStore.actionCreators.updateSignatureArchiveReportDocuments(signingReport) :
                                   SignatureReportStore.actionCreators.updateSignatureDeliveredReportDocuments(signingReport);
                dispatch(action);
                if (callback) {
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.CancelDocument.UpdateSuccess,
                    statusType: StatusType.Success
                })
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.CancelDocument.Error,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`cancelDocument failed for the request having parameters ${JSON.stringify(signingReport)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    setAccessToSignatureReports: (accessMaps: IDocumentAccess[], urlPath: string, callBack?:() =>void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(accessMaps)
        };
        const fetchTask = fetch(urlPath, options)
            .then(handleResponse)
            .then((response) => {
                dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_ACCESS, accessIds: accessMaps });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.SetAccess.Success,
                    statusType: StatusType.Success
                });
                if(callBack){
                    callBack();
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.SetAccess.Error,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`setAccessToSignatureReports failed for the request having parameters ${JSON.stringify(accessMaps)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    setAssignToSignatureReports: (assignMaps: number[], userId: number, urlPath: string, callBack?:() => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        let documentIds = assignMaps; 
        documentIds.forEach(id => {
            formData.append('documentIds', id.toString());
        });
        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: formData
        };
        const fetchTask = fetch(`${urlPath}/${userId.toString()}`, options)
            .then(handleResponse)
            .then((response) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.AssignTo.Success,
                    statusType: StatusType.Success
                });
                if(callBack){
                    callBack();
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.AssignTo.Error,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`setAssignToSignatureReports failed for the request having parameters ${JSON.stringify(assignMaps),userId.toString()} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    resendAccessLink: (resendAccessLinkData: IMailData, urlPath: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(urlPath, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(resendAccessLinkData),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((response) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.ResendAccessLink.Success,
                    statusType: StatusType.Success
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.ResendAccessLink.Error,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                logger.trackError(`resendAccessLink failed for the request having parameters ${JSON.stringify(resendAccessLinkData)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    requestSignatureReportAccessCode: (id: number, urlPath: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${urlPath}/${id}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<IAccessCode[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_ACCESS_CODE, id: id, accessCode: data });

            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`requestSignatureReportAccessCode failed for the request having id ${id.toString()} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    generateOTP: (signingId: number, clientType: number, clientGUID: string, urlPath: string, callBack?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(urlPath, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify({ signingId: signingId, clientType: clientType, clientGuid: clientGUID})
        })
            .then(handleResponse)
            .then((response) => {

                let action: any = actionCreators.requestSignatureReportAccessCode(signingId,
                    urlPath.includes('Archive') ? ArchiveReportPaths.GetOtp : SignatureReportPaths.GetOtp);
                dispatch(action);
                dispatch({ type: actionTypes.GENERATE_SIGNATURE_FLOW_REPORT_ACCESS_CODE, id: signingId });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.AccessCode.Success,
                    statusType: StatusType.Success
                });
                if (callBack) {
                    callBack();
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.AccessCode.Error,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`generateOTP failed for the request having parameters ${JSON.stringify({
                    signingId: signingId,
                    clientType: clientType,
                    clientGUID: clientGUID
                })} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    requestSignatureReportClientTracking: (signingId: number, urlPath: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${urlPath}/${signingId}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<IDocumentTransaction[]>)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_CLIENT_TRACKING,
                    signingId: signingId,
                    clientTracking: data
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`requestSignatureReportClientTracking failed for the request having signingId ${signingId.toString()} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    downloadIndividualFile: (documentGuid: string, fileName: string, signingGuid: string, signingId: number, isReturnSigned: boolean, fileDownloadName: string, urlPath: string,
        callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
            fetch(urlPath, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                },
                body: JSON.stringify({
                    documentGuid: documentGuid,
                    fileName: fileName,
                    signingGuid: signingGuid,
                    signingId: signingId,
                    IsSigningCompleted: isReturnSigned,
                    fileDownloadName: fileDownloadName
                })
            }).then(response => {
                    if(response.ok){
                        const contentDisposition = response.headers.get("content-disposition");
                        const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                        if (fileNameMatch && fileNameMatch.length > 1) {
                            fileName = fileNameMatch[1];
                        }
                        return response.blob();
                    }
                    else{
                        return response.json().catch((error: any) => {
                            // the status was not ok and there was not custom error provided
                            return Promise.reject({
                                status: response.status,
                                statusText: response.statusText,
                            });
                        }).then((json: any) => {
                            // the status was not ok but custom error provided
                            return Promise.reject({
                                status: response.status,
                                statusText: json,
                            });
                        });
                    }
                    
                })
                .then((data) => {
                    let displayDownloadFile = new DisplayDownloadFile();
                    displayDownloadFile.showFile(data, fileName);

                    if (isReturnSigned) {
                        dispatch({
                            type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOWNLOAD,
                            id: signingId
                        });
                    }
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: SignatureFlowReportConstants.DownloadDocument.Success,
                        statusType: StatusType.Success
                    });
                    if (callback) {
                        callback();
                    }
                }).catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: SignatureFlowReportConstants.DownloadDocument.Error,
                        statusType: StatusType.Error, statusCode:error?.status
                    });
                    
                    if (callback) {
                        callback();
                    }
                    logger.trackError(`downloadIndividualFile failed for the request having parameters ${JSON.stringify({
                        documentGuid: documentGuid,
                        fileName: fileName,
                        signingGuid: signingGuid,
                        signingId: signingId,
                        isReturnSigned: isReturnSigned,
                        fileDownloadName: fileDownloadName
                    })} with error ${error.message}`)
                });
        },

    downloadAllDocuments: (downloadableDocuments: IDownloadableDocuments[], fileDownloadName: string, urlPath: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(urlPath, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify({
                downloadableDocuments: downloadableDocuments,
                fileName: fileDownloadName
            })
        }).then(handleBlob)
            .then((data) => {
                let displayDownloadFile = new DisplayDownloadFile();

                displayDownloadFile.showFile(data, fileDownloadName);

                dispatch({
                    type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOWNLOADALL,
                    downloadableDocuments: downloadableDocuments
                })
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.DownloadDocument.Success,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.DownloadDocument.Error,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                if (callback) {
                    callback();
                }
                logger.trackError(`downloadAllDocuments failed for the request having parameters ${JSON.stringify(downloadableDocuments)} with error ${error.message}`)
            });
    },

    sendcanceledDocumentNotification: (canceledDocumentMailData: IMailData, remarks: string, urlPath: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(urlPath, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                mailData: canceledDocumentMailData,
                reason: remarks
            }),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((response) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.CancelDocumentMail.Success,
                    statusType: StatusType.Success
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.CancelDocumentMail.Error,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`sendcanceledDocumentNotification failed for the request having parameters ${JSON.stringify(canceledDocumentMailData)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    deleteDocument: (signingId: number, urlPath: string, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${urlPath}/${signingId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((response) => {
                callback();
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.DeleteDocument.Success,
                    statusType: StatusType.Success
                });
                callback();
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.DeleteDocument.Error,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`deleteDocument failed for the request having signingId ${signingId.toString()} with error ${error.message}`)
            })
        addTask(fetchTask);
    },

    requestDownloadHistory: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReportPopup/GetSignedDocumentDownloadHistoryAsync/' + id, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<IDocumentTransaction[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOWNLOAD_HISTORY, id: id, downloadHistory: data });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.DownloadHistory.Error,
                    statusType: StatusType.Error, statusCode:error?.status, 
                });

                logger.trackError(`requestDownloadHistory failed for the request having id ${id.toString()} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    requestSignatureReportSigners: (signingId: number, resourceId: string, callback?: (error?: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReportPopup/GetSignatureSignersByIdAsync/' + signingId, {
            method: 'GET',
            credentials: 'include',
            headers: {
                [HeaderResourceId]: resourceId
            }
        })
            .then(handleResponse)
            .then(response => response as Promise<ISignerModel[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_SIGNERS, id: signingId, signers: data });
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.SendReminder.Error,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                if (callback) {
                    callback(true);
                }
                logger.trackError(`requestSignatureReportSigners failed for the request having signingId ${signingId.toString()} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    archiveSignatureDocument: (DocumentIds: number[], callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReportPopup/ArchiveSelectedDocuments', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(DocumentIds)
        })
            .then(handleResponse)
            .then(() => {
                VenusNotifier.Success(ArchiveSignatureConstants.SuccessMessage, null);
                let resetReportsAction: any = SignatureReportStore.actionCreators.resetSignatureFlowReportsDocumentPagesState();
                dispatch(resetReportsAction);
                callback();
            })
            .catch(error => {
                logger.trackError(`archiveSignatureDocument failed for the request having Document Ids ${JSON.stringify(DocumentIds)} with error ${error.message}`)
            });
        addTask(fetchTask);

    },

    deleteSignatureDraftDocument: (DocumentIds: number[], callback: (boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReportPopup/Draft/Delete', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(DocumentIds)
        })
            .then(handleResponse)
            .then(() => {
                VenusNotifier.Success(DeleteSignatureConstants.SuccessMessage, null);
                let resetReportsAction: any = SignatureReportStore.actionCreators.resetSignatureFlowReportsDocumentPagesState();
                dispatch(resetReportsAction);
                callback(true);
            })
            .catch(error => {
                VenusNotifier.Error(DeleteSignatureConstants.ErrorMessage, null);
                callback(false);
                logger.trackError(`deleteSignatureDocument failed for the request having Document Ids ${JSON.stringify(DocumentIds)} with error ${error.message}`)
            });
        addTask(fetchTask);

    },
    unlockSigningDocuments: (signingDocumentIds:number[], reload: boolean = false, callback: (boolean) => void): AppThunkAction<KnownAction> => (dispatch, getstate) => {
        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReportPopup/UnlockSigningDocuments', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(signingDocumentIds)
        })
        .then(handleResponse)
        .then(() => {
            if(reload){
                VenusNotifier.Success(SignatureFlowReportConstants.UnlockDocuments.Success, null);
                let resetReportsAction: any = SignatureReportStore.actionCreators.resetSignatureFlowReportsDocumentPagesState();
                dispatch(resetReportsAction);
                callback(true);
            }
        })
        .catch(error => {
            dispatch({
                type: actionTypes.NOTIFICATION,
                statusMessage: SignatureFlowReportConstants.UnlockDocuments.Error,
                statusType: StatusType.Error
            });            
            logger.trackError(`unlockSigningDocuments failed for the request having Signing Ids ${JSON.stringify(signingDocumentIds)} with error ${error.message}`)
        });
        addTask(fetchTask);
    },
    deleteSignatureDocument: (DocumentIds: number[], urlPath: string, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(urlPath, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(DocumentIds)
        })
            .then(handleResponse)
            .then(() => {
                VenusNotifier.Success(DeleteSignatureConstants.SuccessMessage, null);
                let resetReportsAction: any = SignatureReportStore.actionCreators.resetSignatureFlowReportsDocumentPagesState();
                dispatch(resetReportsAction);
                callback();
            })
            .catch(error => {
                logger.trackError(`deleteSignatureDocument failed for the request having Document Ids ${JSON.stringify(DocumentIds)} with error ${error.message}`)
            });
        addTask(fetchTask);

    },
    deleteSignatureRecycledDocument:  (DocumentIds: number[], callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReportPopup/DeleteRecycledDocuments', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(DocumentIds)
        })
            .then(handleResponse)
            .then(() => {
                VenusNotifier.Success(DeleteSignatureConstants.SuccessMessage, null);
                let resetReportsAction: any = SignatureReportStore.actionCreators.resetSignatureFlowReportsDocumentPagesState();
                dispatch(resetReportsAction);
                callback();
            })
            .catch(error => {
                logger.trackError(`deleteSignatureRecycledDocument failed for the request having Document Ids ${JSON.stringify(DocumentIds)} with error ${error.message}`)
            });
        addTask(fetchTask);

    },
    RestoreSignatureDocument: (DocumentIds: number[], callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReportPopup/RestoreSelectedDocuments', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(DocumentIds)
        })
            .then(handleResponse)
            .then(() => {
                VenusNotifier.Success(RestoreSignatureReturnsConstants.SuccessMessage, null);
                let resetReportsAction: any = SignatureReportStore.actionCreators.resetSignatureFlowReportsDocumentPagesState();
                dispatch(resetReportsAction);
                callback();
            })
            .catch(error => {
                logger.trackError(`RestoreSignatureDocument failed for the request having Document Ids ${JSON.stringify(DocumentIds)} with error ${error.message}`)
            });
        addTask(fetchTask);

    },
    restoreSignatureRecycledDocument: (documentIds: number[], callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReportPopup/RestoreRecycledDocuments', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(documentIds)
        })
            .then(handleResponse)
            .then(() => {
                VenusNotifier.Success(RestoreSignatureReturnsConstants.SuccessMessage, null);
                let resetReportsAction: any = SignatureReportStore.actionCreators.resetSignatureFlowReportsDocumentPagesState();
                dispatch(resetReportsAction);
                callback();
                dispatch({ type: actionTypes.LOADING_SIGNING_INFO_SETTINGS, isLoading: false, message: '' });
            })
            .catch(error => {
                dispatch({ type: actionTypes.LOADING_SIGNING_INFO_SETTINGS, isLoading: false, message: '' });
                logger.trackError(`RestoreSignatureRecycledDocument failed for the request having Document Ids ${JSON.stringify(documentIds)} with error ${error.message}`)
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.LOADING_SIGNING_INFO_SETTINGS, isLoading: true, message:'Restoring...' });
    },
    unlockDocument: (signingId:number): AppThunkAction<KnownAction> => (dispatch) => {
        const fetchTask = fetch(API_BASE_URL + `api/SignatureFlow/SendForSignature/Signature/UnlockDocument?signingId=${signingId}`, {
            method: 'GET',
            credentials: 'include',
        })
        .then(handleResponse).then()
        .catch(error => {
            dispatch({
                type: actionTypes.NOTIFICATION,
                statusMessage: SignatureFlowReportConstants.UnlockDocuments.Error,
                statusType: StatusType.Error
            });            
            logger.trackError(`Unlock failed for the request having Signing Ids ${JSON.stringify(signingId)} with error ${error.message}`)
        });
        addTask(fetchTask);
    }
}

export const reducer: Reducer<ISignatureFlowReportPopupDictionary> = (state = {}, incomingActions) => {
    const action = incomingActions as dispatchAction;
    switch (action.type) {
        case actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_POPUP_MODAL:
            let received: ISignatureFlowReportPopupDictionary = { ...state };
            action.signatureFlowReportDetails.forEach((model, i) => {
                received[model.signingId] = {
                    signatureReport: model,
                    isLoading: false,
                    error: false,
                    isSignersUpdated: false
                };
            });
            return received;

        case actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_ACCESS:
            let accessState: ISignatureFlowReportPopupDictionary = { ...state };
            if(action.accessIds && action.accessIds.length > 0){
                action.accessIds.map((item, i) => {
                    let model = state[item.signingId].signatureReport;
                    model.documentAccess = item;
                    accessState[item.signingId] = {
                        signatureReport: model,
                        isLoading: false,
                        error: false,
                        isSignersUpdated: false
                    };
                });
            }
            return accessState;
        
        case actionTypes.RECEIVE_DRAFT_REPORT_ASSIGN_TO:
            let assignToState: ISignatureFlowReportPopupDictionary = { ...state };
            if(action.accessIds && action.accessIds.length > 0){
                action.accessIds.map((item, i) => {
                    let model = state[item.signingId].signatureReport;
                    model.documentAssignedTo = item;
                    assignToState[item.signingId] = {
                        signatureReport: model,
                        isLoading: false,
                        error: false,
                        isSignersUpdated: false
                    }
                })
            }
            return assignToState;

        case actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_CANCELLED_DESCRIPTION:
            let receiveCancelDocumentState: ISignatureFlowReportPopupDictionary = { ...state };
            receiveCancelDocumentState[action.id].signatureReport.cancelledDocumentDescription = action.description
            return receiveCancelDocumentState;

        case actionTypes.SIGNATURE_FLOW_REPORT_CANCEL_DOCUMENT:
            let cancelledDocumentState: ISignatureFlowReportPopupDictionary = { ...state };
            cancelledDocumentState[action.signingReport.signingId] = {
                signatureReport: action.signingReport,
                isLoading: false,
                error: false,
                isSignersUpdated: false
            }
            return cancelledDocumentState;

        case actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_ACCESS_CODE:
            let accessCodeState: ISignatureFlowReportPopupDictionary = { ...state };
            accessCodeState[action.id].signatureReport.accessCode = action.accessCode;
            return accessCodeState;

        case actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_CLIENT_TRACKING:
            let clientTrackingState: ISignatureFlowReportPopupDictionary = { ...state };
            clientTrackingState[action.signingId].signatureReport.clientTracking = action.clientTracking;
            return clientTrackingState;

        case actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOWNLOAD_HISTORY:
            let downloadHistoryState: ISignatureFlowReportPopupDictionary = { ...state };
            downloadHistoryState[action.id].signatureReport.downloadHistory = action.downloadHistory;
            return downloadHistoryState;

        case actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_SIGNERS:
            let signersState: ISignatureFlowReportPopupDictionary = { ...state };
            signersState[action.id].signatureReport.signerInfo = action.signers;
            signersState[action.id].isSignersUpdated = true;
            return signersState;

        case actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOWNLOAD:
            let downloadState: ISignatureFlowReportPopupDictionary = { ...state };
            downloadState[action.id].signatureReport.downloadCount += 1;
            return downloadState;

        case actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOWNLOADALL:
            let downloadAllState: ISignatureFlowReportPopupDictionary = { ...state };
            action.downloadableDocuments.map((item, i) => {
                if (item.isSigningCompleted) {
                    downloadAllState[item.signingId].signatureReport.downloadCount += 1;
                }
            });
            return downloadAllState;

        default: const exhaustiveCheck: never = action;
    }
    return state;
}
