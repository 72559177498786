import React, { FC, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { DropdownArrow, XLg } from '../../svg/CustomSvgIcons';
import DropdownComponent from '../../common/DropdownComponent';
import { ChangeOfficeLocationConstants } from '../../helper/Constants';
import { ChangeOfficeLoctionErrorList, initialChangeOfficeLoctionErrorList } from 'src/SignatureFlow/models/SigningInfo';
import { IDropdown } from 'src/Core/ViewModels/User/UserViewModel';
import { prepareDropDownData } from '../../helper/HelperFunctions';
import { requestLocationUsers } from 'src/SignatureFlow/store/MultiOffice/OfficeLocationStore';

interface IChangeOfficeLocationModalProps {
    show: boolean;
    onCancel: () => void;
    officeLocationList: any;
    onChangeOfficeLocationContinue: (officeLocationId: number, contactPersonId: number, callback: () => void) => void;
}

export const ChangeOfficeLocationModal: FC<IChangeOfficeLocationModalProps> = props => {
    const [selectedOfficeLocation, setselectedOfficeLocation] = useState(0);
    const [selectedContactPerson, setSelectedContactPerson] = useState(0);
    const [disableContinueButton, setDisableContinueButton] = useState(false);
    const [locationUserList, setLocationUserList] = useState<IDropdown[]>([]);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [errorList, setErrorList] = useState<ChangeOfficeLoctionErrorList>(initialChangeOfficeLoctionErrorList);

    const resetAllStates = () => {
        setselectedOfficeLocation(0);
        setSelectedContactPerson(0);
        setDisableContinueButton(false);
        setErrorList({
            officeLocation: { error: false, message: "" },
            contactPerson: { error: false, message: "" }
        });
    };

    const onCancel = () => {
        resetAllStates();
        props.onCancel();
    };

    const ChangeOfficeLocation = (e: any) => {
        if(selectedOfficeLocation == 0 || selectedOfficeLocation == null || errorList.officeLocation.error){
            setErrorList({ ...errorList, officeLocation: { error: false, message: "" } });
        }
        setIsModalLoading(true);
        setselectedOfficeLocation(e);
        requestLocationUsers(e, (isError: boolean, data?: IDropdown[]) => {
            if(!isError && data){
                setLocationUserList(data);
            }
            setIsModalLoading(false);
        })
    };

    const ChangeContactPerson = (e: any) => {
        if(selectedContactPerson == 0 || selectedContactPerson == null || errorList.contactPerson.error) {
            setErrorList({ ...errorList, contactPerson: { error: false, message: "" } });
        }
        setSelectedContactPerson(e);
    };

    const onChangeOfficeLocation = () => {
        let isValid = true;

        let newErrorList = { ...errorList };

        if (selectedOfficeLocation == 0 || selectedOfficeLocation == null) {
            isValid = false;
            newErrorList = {
                ...newErrorList,
                officeLocation: { error: true, message: "This field is required" }
            };
        };

        if (selectedContactPerson == 0 || selectedContactPerson == null) {
            isValid = false;
            newErrorList = {
                ...newErrorList,
                contactPerson: { error: true, message: "This field is required" }
            };
        };

        if (isValid) {
            setDisableContinueButton(true);
            props.onChangeOfficeLocationContinue(selectedOfficeLocation, selectedContactPerson, () => {
                setselectedOfficeLocation(0);
                setSelectedContactPerson(0);
                setDisableContinueButton(false);
                setIsModalLoading(false);
                setLocationUserList([]);
            });
        } else {
            setErrorList({ ...newErrorList });
        }
    };

    return (<div>
        <Modal centered={true} show={props.show} className="change-office-location-modal" onHide={onCancel} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    Select New Office Location
                </Modal.Title>
            </Modal.Header>

            <Modal.Body >
                <LoadingOverlay style={{ height: '50%' }}>
                    <Loader loading={isModalLoading} />
                    <div className='office-location-modal-body'>
                        <div className='fontSize14 '>
                                {ChangeOfficeLocationConstants.ChangeOfficeLocationMessage}
                            </div>
                        <div className='fontSize16 fontWeight600 marB10 marT10'>
                            Office Location
                        </div>
                        <div>
                        <DropdownComponent
                            id="changeOfficeLocationModalDropdown"
                            options={props.officeLocationList}
                            data-test-auto={"0ba5b3b3-e2e6-4a16-932a-05ba897c9846"}
                            selectedValue={selectedOfficeLocation}
                            onChange={(e) => ChangeOfficeLocation(e)}
                            customPlaceHolder="Select..."
                            searchable={false}
                            clearRenderer={() => <XLg color='#212529'/>}
                            arrowRenderer={() => <DropdownArrow/>}
                            customStyle={errorList.officeLocation.error ? { borderColor: '#CC4A43' } : null}
                        />
                        </div>
                        {errorList.officeLocation.error && <div className='fontSize14 marT2' style={{color : '#CC4A43'}}>{errorList.officeLocation.message}</div>}
                        <div className='fontSize14 marT16'>
                                {ChangeOfficeLocationConstants.ChangeContactPersonMessage}
                            </div>
                        <div className='fontSize16 fontWeight600 marB10 marT10'>
                            Change Contact Person
                        </div>
                        <div>
                            <DropdownComponent
                                id="change-contact-person-modal-dropdown"
                                options={prepareDropDownData(locationUserList)}
                                data-test-auto={"0eae1474-a466-4150-83b2-0e829edc5f82"}
                                selectedValue={selectedContactPerson}
                                onChange={(e) => ChangeContactPerson(e)}
                                customPlaceHolder="Select..."
                                searchable={false}
                                clearRenderer={() => <XLg color='#212529'/>}
                                arrowRenderer={() => <DropdownArrow />}
                                customStyle={errorList.contactPerson.error ? { borderColor: '#CC4A43' } : null}
                            />
                        </div>
                        {errorList.contactPerson.error && <div className='fontSize14 marT2' style={{color : '#CC4A43'}}>{errorList.contactPerson.message}</div>}
                    </div>
                </LoadingOverlay>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant='default'
                    className='btn-white modal-footer-button-cancel'
                    onClick={onCancel}
                    data-test-auto="51450092-b0f7-43eb-9563-c5bdf31566d7">
                    Cancel
                </Button>
                <Button
                    variant='info'
                    className='modal-footer-button-save'
                    disabled={disableContinueButton || isModalLoading}
                    onClick={onChangeOfficeLocation}
                    data-test-auto="b81ab905-e11d-41bb-9292-475470b8dcba">
                    Continue
                </Button>
            </Modal.Footer>
        </Modal>
    </div>);
};
