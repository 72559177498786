import * as React from 'react';
import * as Bs from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { OverlayLoader } from '../../../components/helper/OverlayLoader';
import { ISignatureFlowReportDocument } from '../../../models/SignatureFlowReportState';
import { IUserModel } from '../../../../Core/ViewModels/User/UserViewModel';

let moment = require('moment');

interface ISignedDocumentDownloadHistoryProps {
    show: boolean;
    onCancel: () => void;
    selectedDocument: ISignatureFlowReportDocument;
    users: IUserModel[];
}

interface IColumnValues {
    downloadedBy: string,
    downloadedDate: Date
}
export class SignedDocumentDownloadHistory extends React.Component<ISignedDocumentDownloadHistoryProps, {}>{
    constructor(props: ISignedDocumentDownloadHistoryProps) {
        super(props);
        this.defaultType = this.defaultType.bind(this);
    }

    defaultType(cell: any, row: any) {
        return <div className="ellipsis" title={cell}>{cell}</div>;
    }

    downloadByType = (cell: any, row: any) => {
        const downloadedCPA = this.props.users.filter(x => x.email == row.downloadedBy)[0];
        if (downloadedCPA) {
            const fullName = downloadedCPA.firstName + " " + downloadedCPA.lastName;
            return <div className="ellipsis" title={fullName}>{fullName}</div>;
        }
        return <div className="ellipsis" title={row.downloadedBy}>{row.downloadedBy}</div>;
    }

    public render() {

        const downloadHistoryColumns = [
            {
                header: 'Downloaded By',
                key: 'downloadedBy',
                isKey: false,
                dataFormat: this.downloadByType,
                toolTip: true,
                width: 'auto',
                className:"table-column-header"
            },
            {
                header: 'Downloaded Date',
                key: 'downloadedDate',
                isKey: true,
                dataFormat: this.defaultType,
                toolTip: true,
                width: 'auto',
                className:"table-column-header"
            },
        ];

        let downloadHistoryData: IColumnValues[] = [];

        if (this.props.selectedDocument && this.props.selectedDocument.downloadHistory) {
            this.props.selectedDocument.downloadHistory.map((value, index) => {
                downloadHistoryData.push({
                    downloadedBy: value.actedBy.emailAddress,
                    downloadedDate: moment(value.actedOn).format('MM/DD/YYYY')
                });
            });
        }

        let modalBody = this.props.selectedDocument && this.props.selectedDocument.downloadHistory ?
            (<BootstrapTable data={downloadHistoryData}
                containerClass='report-table-container'
                striped
                tableHeaderClass='report-header-border table-header-font'
                trStyle={{borderBottom:'1px solid #A6A9AC', height: '32px'}}
                bordered={false}>
                {downloadHistoryColumns.map((value, index) => {
                    return <TableHeaderColumn key={index} isKey={value.isKey} dataField={value.key} dataFormat={value.dataFormat} width={value.width}  className={value.className ? value.className : ''} columnTitle={value.toolTip}>{value.header}</TableHeaderColumn>;
                })}
            </BootstrapTable>) : (<OverlayLoader
                show={this.props.show}
                text={"Loading, please wait..."} />);

        return <Bs.Modal centered={true} className="download-history-modal" show={this.props.show} onHide={this.props.onCancel} >
            <Bs.Modal.Header closeButton onClick={this.props.onCancel}>
                <Bs.Modal.Title>Download History</Bs.Modal.Title>
            </Bs.Modal.Header>
            <Bs.Form>
                <Bs.Modal.Body>
                    {modalBody}
                </Bs.Modal.Body>
            </Bs.Form>
        </Bs.Modal >
    }
}