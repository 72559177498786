import { param } from 'jquery';
import { PDFPageProxy, ViewportParameters } from 'pdfjs-dist';
import * as React from 'react';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { IPdfDocumentFacade } from '../../../Core/Utilities/PdfDocumentFacade';

export interface PdfPageViewerProps {
    document?: IPdfDocumentFacade;
    pageNo: number;
};

interface PdfPageViewerState {
    page: PDFPageProxy | undefined;
    status: RenderStatus,
}

export enum RenderStatus {
    None = 0,
    InProgress = 1,
    Completed = 2
}

export class PdfPageViewer extends React.Component<PdfPageViewerProps, PdfPageViewerState> {
    canvas: any;

    constructor(props: PdfPageViewerProps) {
        super(props);

        this.state = {
            status: RenderStatus.None,
            page: undefined,
        };
    }

    shouldComponentUpdate(nextProps: PdfPageViewerProps, nextState: PdfPageViewerState) {
        return this.props.document !== nextProps.document || this.state.status !== nextState.status;
    }

    componentDidUpdate(nextProps: PdfPageViewerProps) {
        nextProps.document && this.update(nextProps.document);
    }

    componentDidMount() {
        this.props.document && this.update(this.props.document);
    }

    setCanvasRef = (canvas: any) => {
        this.canvas = canvas;
    };

    update = (document: IPdfDocumentFacade) => {
        if (document) {
            this.loadPage(document);
        } else {
            this.setState({ status: RenderStatus.InProgress });
        }
    };

    loadPage = (document: IPdfDocumentFacade) => {
        if (this.state.status === RenderStatus.InProgress || this.state.page !== undefined) return;

        document.getPage(this.props.pageNo).then((page) => {
            this.setState({ status: RenderStatus.InProgress });
            this.renderPage(page);
        });
    }

    renderPage = (page: PDFPageProxy) => {
        let viewportparams: ViewportParameters = { scale: 1 };
        let viewport = page.getViewport(viewportparams);
        let canvas = this.canvas;
        let context = canvas.getContext('2d');
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        page.render({
            canvasContext: context,
            viewport
        }).promise.then(() => {
            this.setState({ status: RenderStatus.Completed });
        });
        this.setState({ page });
    }

    render() {
        return (<LoadingOverlay style={{ height: '100%' }}>
            <canvas
                id={'pdfpage' + this.props.pageNo}
                ref={(instance: any) => { this.setCanvasRef(instance); }}
                style={{ width: '100%', minHeight: 189, maxHeight:189 }}>
            </canvas>
            <Loader loading={this.state.status != RenderStatus.Completed} />
        </LoadingOverlay>);
    }
}