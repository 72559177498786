export enum TaxreturnType {
    None = 0,
    IndividualTaxReturn = 1,
    MarriedJointTaxReturn = 2,
    CorporateTaxReturn = 3,
}

export interface IReportProblemDetails {
    productType: string;
    cpaPhoneNumber: string;
    cpaEmail: string;
    signingId: number;
    taxYear: number;
    clientId: string;
    clientName: string;
    documentType: string;
    documentName: string;
    status: string;
    sentBy: string;
    description: string;
}

export const initialState: IReportProblemDetails = {
    productType: "",
    cpaPhoneNumber: "",
    cpaEmail: "",
    signingId: 0,
    taxYear: 0,
    clientId: "",
    clientName: "",
    documentType: "",
    documentName: "",
    status: "",
    sentBy: "",
    description: "",
}


export enum ReportProblemProperty {
    productType,
    clientId,
    returnType,
    taxYear,
    cpaEmail,
    cpaPhoneNumber,
    taxSoftware,
    clientName,
    documentType,
    documentName,
    sentBy,
    status,
    //Taxpayer
    taxpayerName,
    taxpayerEmail,
    //Spouse
    spouseName,
    spouseEmail,
    //Partner
    partnerName,
    partnerEmail,
    description,
}

export enum ReportProblemTypes {
    Generic = 1,
    ReturnSpecific = 2,
    SignatureSpecific = 3
}

export enum ProductTypes {
    "General" = 1,
    "SafeSend Returns" = 2,
    "SafeSend Signatures" = 3,
    "SafeSend Organizers" = 4
}


export enum ReportedStep {
    None = 0,
    Assignments = 1,
    Report = 2
}
export enum SFSignatureStatus {
    "Processing" = 1,
    "Out For Signature" = 2,
    "E-Signed" = 3,
    "Declined" = 4,
    "Canceled" = 5,
    "Partially Signed" = 6,
    "Signature Stamping Failed" = 7,
    "Delivery Failed" =  8
}


export const SFSignatureStatusOptionsList = [
    { label: 'Processing', value: "Processing" },
    { label: 'Out For Signature', value: "OutForSignature" },
    { label: 'E-Signed', value: "ESigned" },
    { label: 'Declined', value: "Declined" },
    { label: 'Canceled', value: "Cancelled" },
    { label: 'Delivery Failed', value: "DeliveryFailed" }
];

export interface SelectOptions {
    value: any,
    label: string
}

