import * as React from 'react';
import { Modal, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import { ISignatureFlowReportDocument } from '../../../models/SignatureFlowReportState';
let moment = require('moment');
import { OverlayLoader } from '../../../components/helper/OverlayLoader';
import { InfoIconFill } from '../../svg/CustomSvgIcons';

interface IAccessCodeProps {
    selectedDocument: ISignatureFlowReportDocument;
    onCancel(): void;
    show: boolean;
    onGenerateOTP(documentId: number, clientType: number, clientGUID: string, callback: () => void): void;
}

interface IColumnValues {
    signersName: string,
    createdOn: Date,
    OTP: string,
    status: string,
    signingId: number,
    clientType: number,
    clientGUID: string,
    date: Date,
}

const msg = {
    saving: 'Generating OTP...',
    loading: 'Loading, please wait...'
}

interface IAccessCodeState {
    message: string;
    isOtpGenerated: boolean;
    alert: string;
    hideAlert: boolean;
}

const successMsg = {
    generatedAccessCode: "Access code generated successfully"
}

export class SignatureFlowReportAccessCodeModal extends React.Component<IAccessCodeProps, IAccessCodeState>{
    constructor(props: IAccessCodeProps) {
        super(props);
        this.state = {
            message: msg.loading,
            isOtpGenerated: false,
            alert: "success",
            hideAlert: true,
        }
        this.defaultType = this.defaultType.bind(this);
        this.generateOTPButton = this.generateOTPButton.bind(this);
        this.onGenerateOTP = this.onGenerateOTP.bind(this);
        this.getDifferenceInDate = this.getDifferenceInDate.bind(this);
    }
    UNSAFE_componentWillReceiveProps(props: IAccessCodeProps) {
        if (!props.show) {
            this.setState({ message: msg.loading });
        }
    }
    componentWillUnmount() {
        this.setState({ isOtpGenerated: false, hideAlert: true })
    }

    private defaultType(cell: any, row: IColumnValues) {
        return  <div title={cell} className="ellipsis">{cell}</div>;
    }

    private defaultSignatureStatusType(cell: any, row: IColumnValues) {
        const SignatureStatusClassName = cell == "Active" ? "signature-status-signed" : "signature-status-not-signed"
        return <span className={SignatureStatusClassName}>{cell}</span>;
    }

    private generateOTPButton(cell: any, row: IColumnValues) {

        return <Link to={"#"} onClick={(event) => { this.onGenerateOTP(row) }} style={{color:'#05386B'}}> {"Generate Access Code"}</Link >;
    }
    _toolTip = (cell: any, row: any) => {
        let accessAction;
        this.props.selectedDocument.accessCode.map((value, index) => {
            accessAction = ((this.getDifferenceInDate(value.createdOn) > 20) ? "Generate Access Code" : "");
        });
        return `${accessAction}`;
    }
    private onGenerateOTP(row: IColumnValues) {
        this.setState({ message: msg.saving, isOtpGenerated: true, hideAlert: true },
            () => {
                this.props.onGenerateOTP(row.signingId, row.clientType, row.clientGUID, this.triggerAlert);
            }
        );
    }

    private getDifferenceInDate(createdDate: Date) {
        let date1: any = new Date();
        let date2: any = moment.utc(createdDate).toDate();
        let minute = (date1.getTime() - date2.getTime()) / 60000;
        return minute
    }
    private onCancel = () => {
        this.setState({ isOtpGenerated: false, hideAlert: true })
        this.props.onCancel()
    }
    private triggerAlert = () => {
        this.setState({ hideAlert: false, alert: "success" })
    }

    public render() {
        let viewAccessCodeData: any[] = [];
        const viewAccessCodeColumn = [
            {
                header: "Email",
                key: 'signersEmail',
                isKey: false,
                dataFormat: this.defaultType,
                width:'250px',
                toolTip: true,
                className: 'word-Visible table-column-header',

            },
            {
                header: 'Date',
                key: 'createdOn',
                isKey: true,
                dataFormat: this.defaultType,
                toolTip: true,
                width:'130px',
                className: 'table-column-header',
            },
            {
                header: 'Access Code',
                key: 'OTP',
                isKey: false,
                dataFormat: this.defaultType,
                toolTip: true,
                width:'130px',
                className: 'table-column-header',
            },
            {
                header: 'Status',
                key: 'status',
                isKey: false,
                dataFormat: this.defaultSignatureStatusType,
                toolTip: true,
                width:'130px',
                className: 'table-column-header',
            },
            {
                header: 'Action',
                key: 'action',
                isKey: false,
                dataFormat: this.generateOTPButton.bind(this),
                toolTip: this._toolTip,
                width:'',
                className: 'table-column-header',
            }
        ];
        if (this.props.selectedDocument && this.props.selectedDocument.accessCode) {
            viewAccessCodeData = this.props.selectedDocument.accessCode.map((value, index) => {
                return {
                    signersEmail: value.signerEmail,
                    createdOn: moment.utc(value.createdOn).local().format('MM/DD/YYYY'),
                    OTP: value.otp,
                    status: this.getDifferenceInDate(value.createdOn) > 20 ? "Expired" : "Active",
                    signingId: value.signingId,
                    clientType: value.clientType,
                    clientGUID: value.clientGuid,
                    date: value.createdOn,
                }
            })
        }
        let modalBody = (this.props.selectedDocument && this.props.selectedDocument.accessCode) ?
            (<BootstrapTable data={viewAccessCodeData}
                containerClass='report-table-container'
                tableHeaderClass='report-header-border'
                options={{noDataText:'No data to display'}}
                bordered={false}
                striped
                >
                {viewAccessCodeColumn.map((value, index) => {
                    return <TableHeaderColumn
                        key={index}
                        isKey={value.isKey}
                        dataField={value.key}
                        dataFormat={value.dataFormat}
                        className={value.className}
                        columnTitle={value.toolTip}
                        width={value.width}>
                        {value.header}
                    </TableHeaderColumn>;
                })}
            </BootstrapTable>) : (<OverlayLoader
                show={this.props.show}
                text={this.state.message}
                width='94%' />);
        return <div>
            <Modal centered={true} className="view-access-code-modal" show={this.state.isOtpGenerated ? true : this.props.show} onHide={this.onCancel} backdrop="static">
                <Modal.Header closeButton onClick={this.onCancel}>
                    <Modal.Title>Access Code</Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <div className='custom-alert-warning div-flex'> 
                            <span style={{marginRight:'7px'}}><InfoIconFill marginLeft='5px' marginBottom='2px'/></span>
                            <span>Please note that generating a new code will not result in the sending of a new email. </span>
                        </div>
                        {modalBody}
                    </Modal.Body>
                </Form>
                <Modal.Footer style={{display: "inline-block",textAlign: "end"}}>
                    <Button
                        variant="default"
                        className='btn-white modal-footer-button-cancel'
                        onClick={this.onCancel}>
                        Close
                        </Button>
                </Modal.Footer>
            </Modal>
        </div>
    }
}