import * as React from 'react';
import { Col } from 'react-bootstrap';
import { ISignatureFlowSettings } from '../../models/SignatureFlowSettings';
import { CheckBoxComponent } from '../common/CheckBoxComponent';
import { daysDropdown } from '../helper/HelperFunctions';
import Select from 'react-select';
import { DropdownArrow } from '../svg/CustomSvgIcons';

export interface IAutomatedReminderSettingsProps {
    resourceId: string;
    signatureFlowSettings: ISignatureFlowSettings;
    updateCompanySettings: (signatureFlowSettings: ISignatureFlowSettings) => void;
}


export const AutomatedReminderSettings: React.FC<IAutomatedReminderSettingsProps> = props => {

    const handleSigningReminderChange = (event: any) => {
        let companySettings = props.signatureFlowSettings;
        companySettings.reminderSettings.signingReminder.enabled = event.target.checked;
        props.updateCompanySettings(companySettings);
    }

    const handleSigningReminderDayChange = (event: any) => {
        let companySettings = props.signatureFlowSettings;
        if (companySettings.reminderSettings.signingReminder.reminderDays !== event.value) {
            companySettings.reminderSettings.signingReminder.reminderDays = event.value;
            props.updateCompanySettings(companySettings);
        }
    }


    return (
        <div className="new-main-content automatedReminderSettings" data-resource-id={props.resourceId}>
            <div className="row" style={{ position: "relative" }}>
                <div className="col-sm-8 col-lg-11" style={{ marginLeft: 17 }}>
                    <div className="row">
                        <div className="col-sm-10 padL00 float-left">
                            <h4 className='sigflow-settings-heading'>Automated Signing Reminders</h4>
                        </div>
                    </div>
                    <div className="row" style={{ alignItems: "center" }}>
                        <Col className='padL00' style={{ marginTop: 20 }}>
                            <CheckBoxComponent
                                id="enableSigningReminders"
                                className='custom-curve-check-box'
                                text="Enable Automatic Signing Reminders"
                                checked={props.signatureFlowSettings.reminderSettings.signingReminder.enabled}
                                onChange={handleSigningReminderChange}
                                datatestAuto="B41F155F-4F5C-4B59-A431-3FA178E88DA5"
                            />
                        </Col>
                    </div>
                    <div className="row" style={{ alignItems: "center", marginTop: "20px",marginBottom:"17px" , fontSize: "16px" }}>
                        <div>Send Signing Reminder every</div>
                        <div className="select-reminder-control" style={{ margin:"0 8px" }}>                                                
                            <Select
                                name="signing-reminder-select"
                                autosize={false}
                                searchable={false}
                                arrowRenderer={()=><span><DropdownArrow/></span>}
                                value={props.signatureFlowSettings.reminderSettings.signingReminder.reminderDays}
                                onChange={handleSigningReminderDayChange}
                                options={daysDropdown()}
                                data-test-auto="52A69016-2B50-4B88-84B4-3E82ED02586A"
                                disabled={!props.signatureFlowSettings.reminderSettings.signingReminder.enabled}
                                clearable={false}
                                menuStyle={{width: '99px', zIndex: 9999, backgroundColor:'#fff',fontSize: '14px' }}
                            />
                        </div>
                        <div>since last reminder/initial email, until e-Signed.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}