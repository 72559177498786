import { Reducer } from 'redux';
import { addTask } from 'domain-task';
import { AppThunkAction } from '../store';
import { API_BASE_URL } from '../../utils/AppConstants';
import { handleResponse } from './Library';
import { actionTypes } from '../store/ActionTypes';
import { AuthenticationType, IClient, initialSigningInfo, ISendForSignatureModel, ISigningInfo } from '../models/SigningInfo';
import { NotificationAction, StatusType } from '../store/common/NotificationStore';
import { ReceiveClientAuthenticationParam } from './SFStore';
import { getAuthenticationParam } from '../components/helper/HelperFunctions';
import { logger } from '../../routes/LoggedIn';
import { ISignatureFlowSettings } from '../models/SignatureFlowSettings';
import * as Constants from '../components/helper/Constants';


export interface ISigningInfoDictionary {
    [index: number]: {
        signingInfo: ISigningInfo,
        isLoading: boolean,
        error: boolean,
        message: string,
        signatureFlowSettings?: ISignatureFlowSettings,
    };
}
export interface ReceiveSigningInfo {
    type: actionTypes.RECEIVE_SIGNING_INFO;
    id: number;
    signingInfo: ISigningInfo;
}

export interface RequestSigningInfo {
    type: actionTypes.REQUEST_SIGNING_INFO;
    id: number;
}

export interface ReceiveSigningInfoWithDocuments {
    type: actionTypes.RECEIVE_SIGNING_INFO_DOCUMENTS;
    id: number;
    signingInfo: ISigningInfo;
    signatureFlowSettings:ISignatureFlowSettings;
}
export interface ErrorSigningInfo {
    type: actionTypes.ERROR_SIGNING_INFO;
    id: number;
}

export interface ReceiveSignatureRecipients {
    type: actionTypes.RECEIVE_SIGNATURE_RECIPIENTS;
    id: number;
    recipients: IClient[];
}

export interface RequestSignatureRecipients {
    type: actionTypes.REQUEST_SIGNATURE_RECIPIENTS;
    id: number;
}

type KnownAction = RequestSignatureRecipients | ReceiveSignatureRecipients | ReceiveClientAuthenticationParam |
    ReceiveSigningInfo | RequestSigningInfo | ErrorSigningInfo | NotificationAction  | ReceiveSigningInfoWithDocuments;


export const actionCreators = {
    requestRecipients: (signingInfoId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_SIGNATURE_RECIPIENTS, id: signingInfoId })
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SendForSignature/GetClients?signingInfoId=' + signingInfoId, {
            method: 'GET',
            credentials: 'include',
        }).then(handleResponse)
            .then(json => json as Promise<IClient[]>)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_SIGNATURE_RECIPIENTS,
                    id: signingInfoId,
                    recipients: data
                })
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: '',
                    statusType: StatusType.Error, statusCode:error?.status
                });
                logger.trackError(`requestRecipients failed for signingInfoId ${signingInfoId} with error ${error.message}`);

            })

        addTask(fetchTask);
    },
    requestSigningInfo: (signingInfoId: number, urlPath: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_SIGNING_INFO, id: signingInfoId })
        const fetchTask = fetch(`${urlPath}?signingInfoId=${signingInfoId}`, {
            method: 'GET', 
            credentials: 'include',
        }).then(handleResponse)
            .then(json => json as Promise<ISigningInfo>)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_SIGNING_INFO,
                    id: signingInfoId,
                    signingInfo: data
                });
                if(callback){
                    callback();
                }
            }).catch(error => {
                dispatch({ type: actionTypes.ERROR_SIGNING_INFO, id: signingInfoId });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: '',
                    statusType: StatusType.Error, statusCode:error?.status
                });
                logger.trackError(`requestSigningInfo failed for signingInfoId ${signingInfoId} with error ${error.message}`);

            })

        addTask(fetchTask);
    },
    updateRecipients: (signingInfoId: number, recipients: IClient[], urlPath: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        const fetchTask = fetch(urlPath, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(recipients),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        }).then(handleResponse)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_SIGNATURE_RECIPIENTS,
                    id: signingInfoId,
                    recipients: recipients
                });
                recipients.every(r => r.authenticationType.toString() !== AuthenticationType.None.toString()) &&
                    recipients.forEach((recipient) => {
                        let param = getAuthenticationParam(recipient);
                        dispatch({
                            type: actionTypes.RECEIVE_CLIENT_AUTHENTICATION_PARAM,
                            param: param ? param : "",
                            email: recipient.emailAddress,
                            authenticationType: recipient.authenticationType,
                            question: recipient.question
                        });
                    })
                if (callback) {
                    callback();
                }

                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ClientInfoConstants.clientDetailUpdateConfirmation,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ClientInfoConstants.clientDetailUpdateError,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                logger.trackError(`updateRecipients failed for signingInfoId ${signingInfoId} with error ${error.message}`);
            })
        addTask(fetchTask);
    },
    requestSigningInfoDocument: (signingInfoId: number, urlPath: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_SIGNING_INFO, id: signingInfoId })
        const fetchTask = fetch(`${urlPath}?signingInfoId=${signingInfoId}`, {
            method: 'GET', 
            credentials: 'include',
        }).then(handleResponse)
            .then(json => json as Promise<ISendForSignatureModel>)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_SIGNING_INFO_DOCUMENTS,
                    id: signingInfoId,
                    signingInfo: data.signingInfo,
                    signatureFlowSettings: data.signatureFlowSettings
                });
                if(callback){
                    callback();
                }
            }).catch(error => {
                dispatch({ type: actionTypes.ERROR_SIGNING_INFO, id: signingInfoId });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: '',
                    statusType: StatusType.Error, statusCode:error?.status
                });
                logger.trackError(`requestSigningInfo failed for signingInfoId ${signingInfoId} with error ${error.message}`);

            })

        addTask(fetchTask);
    },
    updateDocumentClientInfo: (signingInfoId: number, signingInfo:ISigningInfo, urlPath: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(urlPath, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify({
                signingInfoId: signingInfoId,
                clientId: signingInfo.clientId,
                locationId: signingInfo.locationId,
                recipientName: signingInfo.recipientName
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        }).then(handleResponse)
            .then(data => {
                if(callback){
                    callback();
                }
            }).catch(error => {
                if(callback){
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: 'Failed to update client details',
                    statusType: StatusType.Error, statusCode:error?.status
                });
                logger.trackError(`updateDocumentClientInfo failed for signingInfoId ${signingInfo} with error ${error.message}`);
            })
        addTask(fetchTask);
    },
}

const unloadedState: ISigningInfo = Object.assign({}, initialSigningInfo);

export const reducer: Reducer<ISigningInfoDictionary> = (state = {}, incomingAction) => {
    const action = incomingAction as KnownAction;
    let currentState: ISigningInfoDictionary = { ...state };
    switch (action.type) {
        case actionTypes.REQUEST_SIGNING_INFO:
            currentState[action.id] = {
                signingInfo: currentState[action.id] ? currentState[action.id].signingInfo : unloadedState,
                isLoading: true,
                error: false,
                message: ''
            };
            return currentState;

        case actionTypes.RECEIVE_SIGNATURE_RECIPIENTS:
            let signingInfo = currentState[action.id] ? currentState[action.id].signingInfo : unloadedState;
            signingInfo.recipients = action.recipients;
            currentState[action.id] = {
                signingInfo: signingInfo,
                isLoading: false,
                error: false,
                message: ''
            };
            return currentState;

        case actionTypes.RECEIVE_SIGNING_INFO:
            currentState[action.id] = {
                signingInfo: action.signingInfo,
                isLoading: false,
                error: false,
                message: ''
            };
            return currentState;

        case actionTypes.ERROR_SIGNING_INFO:
            currentState[action.id] = {
                signingInfo: currentState[action.id] ? currentState[action.id].signingInfo : unloadedState,
                isLoading: false,
                error: false,
                message: ''
            };
            return currentState;
        case actionTypes.RECEIVE_SIGNING_INFO_DOCUMENTS:
            currentState[action.id] = {
                signingInfo: action.signingInfo,
                isLoading: false,
                error: false,
                message: '',
                signatureFlowSettings:action.signatureFlowSettings
            };
            return currentState;
    }
    return state;
}
