import { LogoutCause } from 'src/SignatureFlow/models/user/UserSettingsModels';
import * as fetchIntercept from 'fetch-intercept';
import { VenusNotifier } from '../../SignatureFlow/components/helper/VenusNotifier';
import * as Loader from '../../SignatureFlow/components/helper/Loader';
import history from '../../routes/history'
import store from '../../SignatureFlow/store'
import { userAutoLogout } from 'src/SignatureFlow/store/auth/actions';
import { RESET_USER_STATUS_CACHE_API } from 'src/SignatureFlow/components/helper/Constants';

export const unregister = fetchIntercept.register({
    request: function (url, config) {
        let configDefaults;
        if(url !== RESET_USER_STATUS_CACHE_API){
            const user = store.getState().auth.user;
            configDefaults = {
                ...config,
                headers: {
                    ...(config.credentials === 'include' && {
                        'X-Requested-With': 'XMLHttpRequest',
                        ...(user.access_token && { authorization: `Bearer ${user.access_token}` }),
                    }),
                },
            }
        }
        else{
            configDefaults = {
                ...config
            }
        }    
        const headerEntries = config?.headers?.entries?.() //temporary fix for bug: 67699
        if (headerEntries) {
            for (var pair of headerEntries) {
                configDefaults.headers[pair[0]] = pair[1]
            }
        }

        const correctedUrl = url.replace(/([^:]\/)\/+/g, "$1"); //code added for removing extra slash from url before api
        return [correctedUrl, configDefaults]
    },
    response: function (response: any) {
        if (response.status == 401) {
            response
                .clone()
                .json()
                .then(response => {
                    Loader.HideLoader()
                    VenusNotifier.Info(response.message, '')
                    window.location.reload()
                })
            return response;
        } else if (response.status == 512) {
            if (history.location.pathname !== '/restricted-access') {
                response
                .clone()
                .json()
                .then(data => {
                    history.push(
                        {
                            pathname: '/restricted-access', 
                            state: {errorMessage: data}
                        })
                });
            }

            return response    
        }       
        else if(response.status == 419){
            const user = store.getState().auth;
            if(!user?.userPrivilegesChanged){ 
                response.clone().json().then(response => {
                    Loader.HideLoader();
                    store.dispatch(userAutoLogout(response.logoutCause as LogoutCause));
                })
            }
            return Promise.reject({status:response.status});
        } 
        else {
            return response
        }
    },
})
