import React from 'react';
import { userManager } from './userService';

const SilentSigninOidc = () => {
    React.useEffect(() => {
        userManager.signinSilentCallback().then((user) => { });
    }, [])
    return <></>;
}

export default SilentSigninOidc