import * as React from 'react';
import { Col, FormGroup, FormControl, FormLabel,Button,Modal } from 'react-bootstrap';
import { IUserModel } from '../../Core/ViewModels/User/UserViewModel';
import * as Helper from '../components/helper/HelperFunctions';
import { VenusNotifier } from '../components/helper/VenusNotifier';
import { GripVertical } from './svg/CustomSvgIcons';
import { isEqual, uniq } from 'lodash';

interface IDocumentAccessListProps {
    allowedUsers: number[];
    usersList: IUserModel[];
    modifyAllowedUsers: (usersAllowedAccess: number[]) => void;
    showPopup: boolean;
    onHide: () => void;
    modify: boolean;
    isCleanUp: boolean;
    modifyDocumentAccessCleanUp: (isCleanUp: boolean) => void;
}

interface IDocumentAccessListState {
    allowedUsers: IUserModel[];
    allUsers: IUserModel[];
    leftSelect: IUserModel[];
    lastLeft: number;
    rightSelect: IUserModel[];
    lastRight: number;
    isModifyAccessLoading: boolean;
}

const everyOne = { userId: -1, firstName: 'Everyone' } as IUserModel;

export class DocumentAccessList extends React.Component<IDocumentAccessListProps, IDocumentAccessListState>{
    constructor(props: IDocumentAccessListProps) {
        super(props);
        this.state = {
            allowedUsers: [],
            allUsers: [],
            leftSelect: [],
            lastLeft: 0,
            rightSelect: [],
            lastRight: 0,
            isModifyAccessLoading: false
        };
    }

    //These are Ids of current user and delegated user list component
    private currentUsersId : string  =  "current-users";
    private selectedUsersId : string  =  "selected-users";

    componentDidMount() {
        this.setDocumentAccessUsers(this.props.allowedUsers)
    }

    UNSAFE_componentWillReceiveProps(nextProps: IDocumentAccessListProps) {
        const allowedUsersId = this.props.allowedUsers.filter(u => u != Helper.NO_INDEX)
        
        if(!isEqual(nextProps.allowedUsers, allowedUsersId)){
            let allowedUsers = nextProps.isCleanUp ?  
                                uniq([...nextProps.allowedUsers, ...allowedUsersId]) : 
                                uniq([...nextProps.allowedUsers]);
            this.setDocumentAccessUsers(allowedUsers);
        }

        if(nextProps.isCleanUp){
            this.props.modifyDocumentAccessCleanUp(false);
        }

    }

    private setDocumentAccessUsers = (allowedUsers: number[]) => {
        let allUsers: IUserModel[] = [...this.props.usersList];
        let allowedUsersTemp: IUserModel[] = [];

        if (this.props.usersList && this.props.usersList.length > 0 && allowedUsers[0] !== -1) {
            allowedUsersTemp = allowedUsers.map((id, i) => {
                return this.props.usersList.find((u) => u?.userId === id);
            }) as IUserModel[];

        }
        if (allowedUsers.length > 0 && allowedUsers[0] !== -1) {
            allUsers = this.filterLeft(allUsers, allowedUsersTemp);
        } else {
            allowedUsersTemp.push(everyOne);
        }

        this.setState({
            allowedUsers: allowedUsersTemp,
            allUsers: this.filterLeft(this.props.usersList, allowedUsersTemp)
        });
    }

    private onSelectFromLeft = (selected: IUserModel, event: any) => {
        if(Helper.isWithinElement(event, this.currentUsersId) && this.props.modify) {
           let leftSelect = Helper.handleSelect(selected, event, this.state.leftSelect, this.state.allUsers, this.state.lastLeft);
           this.setState({ leftSelect: leftSelect, lastLeft: selected.userId });
        }
    }

    private onSelectFromRight = (selected: IUserModel, event: any) => {
        if(Helper.isWithinElement(event, this.selectedUsersId) && this.props.modify) {
           let rightSelect = Helper.handleSelect(selected, event, this.state.rightSelect, this.state.allowedUsers, this.state.lastRight);
           this.setState({ rightSelect: rightSelect, lastRight: selected.userId });
        }
    }

    private onDoubleClickLeft = (selected: IUserModel, callback: any) => {
        let leftSelect = Helper.handleSelect(selected, event, this.state.leftSelect, this.state.allUsers, this.state.lastLeft);
        if(this.props.modify){
            this.setState({ leftSelect: leftSelect, lastLeft: selected.userId });
            if (callback) callback();
        }
    }

    private onDoubleClickRight = (selected: IUserModel, callback: any) => {
        let rightSelect = Helper.handleSelect(selected, event, this.state.rightSelect, this.state.allowedUsers, this.state.lastRight);
        if(this.props.modify){
            this.setState({ rightSelect: rightSelect, lastRight: selected.userId });
            if (callback) callback();
        }

    }

    private moveRight = (event: any) => {
        if (this.state.leftSelect.length > 0) {
            let right = [...this.state.allowedUsers];
            this.state.leftSelect.map((u, i) => {
                right.push(u);
            });
            right = this.handleEveryOne(right);
            this.setState({
                allUsers: this.filterLeft(this.props.usersList, right),
                allowedUsers: right,
                leftSelect: [],
                rightSelect: [],
                lastLeft: 0,
                lastRight: 0
            });

        } else {
            VenusNotifier.Warning("Please select user(s) to add!", "Select Users");
        }
        if (event) {
            event.preventDefault()
        }
    }

    private moveLeft = (event: any) => {
        if (this.state.rightSelect.length > 0) {

            let right = [...this.state.allowedUsers];
            this.state.rightSelect.map((u, i) => {
                Helper.removeItem(u, right);
            });
            right = this.handleEveryOne(right);
            this.setState({
                allUsers: this.filterLeft(this.props.usersList, right),
                allowedUsers: right,
                leftSelect: [],
                rightSelect: [],
                lastLeft: 0,
                lastRight: 0
            });
        } else {
            VenusNotifier.Warning("Please select user(s) to remove!", "Unselect Users");
        }
        if (event) {
            event.preventDefault();
        }
    }

    private DragToRight(){
        let left = [...this.state.allUsers];
        let right = [...this.state.allowedUsers];
        right = [...right,...this.state.leftSelect];
        left = left.filter(u => !right.includes(u));
        right = this.handleEveryOne(right);
        this.setState({
            allUsers: left,
            allowedUsers: right,
            leftSelect: [],
            rightSelect: [],
            lastLeft:0,
            lastRight:0
        });
    }

    private DragToLeft(){
        let left = [...this.state.allUsers];
        let right = [...this.state.allowedUsers];
        left = [...left,...this.state.rightSelect];
        Helper.removeItem(everyOne, left);
        right = right.filter(u => !left.includes(u));
        right = this.handleEveryOne(right);
        this.setState({
            allUsers: left,
            allowedUsers: right,
            leftSelect: [],
            rightSelect: [],
            lastLeft:0,
            lastRight:0
        });
    }

    handleEveryOne = (right: IUserModel[]) => {
        if (right.length > 1) {
            Helper.removeItem(everyOne, right);
        }
        if (right.length === 0) {
            right.push(everyOne);
        }
        return right;
    }

    private filterLeft = (allUsers: IUserModel[], usersToRemove: IUserModel[]) => {
        let left = [...allUsers];
        usersToRemove.map((u, i) => {
            Helper.removeItem(u, left);
        });
        return left;
    }

    private onCancelButtonClick = () => {
        this.props.onHide();
        this.setDocumentAccessUsers(this.props.allowedUsers)
    }

    private onSaveButtonClick = () => {
        this.props.modifyAllowedUsers(this.state.allowedUsers.map(user => user.userId));
        this.props.onHide();
    }

    private onResetButtonClick = () => {
        this.setDocumentAccessUsers(this.props.allowedUsers)
    }

    public render() {              
       return (<Modal className="sender-delegation-modal" centered={true}show={this.props.showPopup} onHide={this.onCancelButtonClick} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>
                    Set Access
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p style={{marginLeft:'14px', marginRight:'14px'}}>Move users to apply signature document access.</p>
            <div className="row">
            <Col sm={12} style={{ display: "flex" }}>
                <Col sm={6}>
                    <FormGroup  style={{ display: "block" }} onDrop={()=>{this.DragToLeft()}}>
                        <FormLabel>AVAILABLE USERS</FormLabel>
                            <FormControl id="current-users" style={{ height: '332px', overflowY: 'auto', padding:'0px' }} className="group_user custom-scroll-bar" as="div" onDragOver={(event:any)=>event.preventDefault()}>
                            <ul style={{marginBottom:'0px'}}>
                                {this.state.allUsers != undefined ? this.state.allUsers.map((user, index) => {
                                    const fullName = Helper.fullName(user);
                                    const oddEven = (index % 2 == 0) ? 'even ' : 'odd ';
                                    const isActiveSelected = this.state.leftSelect.some((j: IUserModel) => j.userId == user.userId) ? "activeSelected" : "";
                                    const cursor = this.props.modify ? "grab" : "not-allowed";
                                    return (
                                        <div draggable={this.props.modify} 
                                            onDragStart={(event:any)=>{this.onSelectFromLeft(user,event)}}>                                                
                                                <li
                                                    className={'sender-delegation-users '+ oddEven + isActiveSelected}
                                                    key={index}
                                                    value={user.userId}
                                                    onDoubleClick={() => { this.onDoubleClickLeft(user, this.moveRight); }}
                                                    onClick={this.onSelectFromLeft.bind(this, user)}
                                                > 
                                                    <div 
                                                        title={fullName}
                                                        className='ellipsis'
                                                        onClick={()=>this.onSelectFromLeft}
                                                        style={{paddingTop:'4px',paddingBottom:'4px',cursor:cursor}} >
                                                        <span style={{paddingLeft:'9px'}}><GripVertical /></span>
                                                        <span style={{paddingLeft:'15px'}}>{fullName}</span>
                                                    </div>
                                                </li>
                                        </div>
                                    )}) : ""}
                            </ul>
                            </FormControl>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup style={{ display: "block" }} onDrop={()=>{ this.DragToRight()}}>
                        <FormLabel>USER(S) WITH ACCESS RIGHTS</FormLabel>
                        <FormControl id="selected-users" style={{ height: '332px', overflowY: 'auto', padding:'0px' }} className="group_user custom-scroll-bar" as="div"  onDragOver={(event:any)=>event.preventDefault()}>
                        <ul style={{marginBottom:'0px'}}>
                            {this.state.allowedUsers.map((user, index) => {
                                const fullName = Helper.fullName(user);
                                const oddEven = (index % 2 == 0) ? 'even ' : 'odd ';
                                const isActiveSelected = this.state.rightSelect.some((j: IUserModel) => j.userId == user.userId) ? "activeSelected" : "";
                                const cursor = this.props.modify ? "grab" : "not-allowed";
                                return (
                                    <div draggable={this.props.modify} 
                                        onDragStart={(event:any)=>{this.onSelectFromRight(user,event)}}>
                                            <li
                                                className={'sender-delegation-users '+ oddEven + isActiveSelected}
                                                key={index}
                                                value={user.userId}
                                                onDoubleClick={() => { this.onDoubleClickRight(user, this.moveLeft); }}
                                                onClick={this.onSelectFromRight.bind(this, user)}
                                                >
                                                <div 
                                                    title={fullName}
                                                    className='ellipsis'
                                                    onClick={() => this.onSelectFromRight} 
                                                    style={{paddingTop:'4px',paddingBottom:'4px',cursor:cursor}} >
                                                    <span style={{paddingLeft:'9px'}}><GripVertical /></span>
                                                    <span style={{paddingLeft:'15px'}}>{fullName}</span>
                                                </div>
                                            </li>
                                    </div>);
                                })
                            }
                        </ul>
                        </FormControl>
                    </FormGroup>
                </Col>
            </Col>
        </div>
        </Modal.Body>
        <Modal.Footer>         
            <span data-test-auto="f9073aa3-fdfd-477c-8ceb-7cdc692c8487" style={{marginRight:'21px'}} onClick={this.onResetButtonClick}>
                <a style={{textDecorationLine: 'underline',color: '#0973BA', cursor:'pointer'}}>
                    Reset
                </a>
            </span>
            <Button
                data-test-auto="e093d289-d0af-41d7-86e9-d2e19b3521f0"
                variant='default'
                className='btn-white modal-footer-button-cancel'
                onClick={this.onCancelButtonClick} >
                    Cancel
            </Button>
            <Button
                variant='info'
                className=' modal-footer-button-save'
                onClick={this.onSaveButtonClick}
                data-test-auto="005b2589-257f-4d28-8d07-218a90556892">
                    Save
            </Button>
        </Modal.Footer>
    </Modal>)
    }
}