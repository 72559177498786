import React, { useEffect } from 'react'
import { ILogger } from 'src/Logger/Logger';
import { useSelector, useDispatch } from 'react-redux';
import LoadingView from '../SignatureFlow/components/LoadingView';
import { TelemetryLogger } from '../Logger/Telemetry/TelemetryLogger';

import { ApplicationState } from '../SignatureFlow/store/types'

import { actionCreators } from '../SignatureFlow/store/PostAuth/PostAuthStore'

export let logger: ILogger;
const LoggedIn: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const user = useSelector((state: ApplicationState) => state.auth.user)
    const isLoadingUser = useSelector((state: ApplicationState) => state.auth.isLoadingUser)
    const ispostAuthStoreLoaded = useSelector((state: ApplicationState) => state.postAuthDataStore.postAuthStoreLoaded)

    const dispatch = useDispatch();
    
    useEffect(() => {
        if (user) {
            dispatch(actionCreators.requestPostAuthData());
        }
    }, [user, dispatch]);
    
   
    logger = user ? TelemetryLogger.getInstance() : null;

    return (user != null && !isLoadingUser && ispostAuthStoreLoaded) ?
        <>
        {children}
        </> :
        <LoadingView />  
}

LoggedIn.displayName = 'LoggedIn'

export default LoggedIn
