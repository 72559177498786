import React, { useEffect } from 'react'
import { signoutRedirectCallback, removeUser } from './userService'
import LoadingView from '../SignatureFlow/components/LoadingView'
import history from '../routes/history'

function SignoutOidc() {
    useEffect(() => {
        async function signoutAsync() {
            try{
                await signoutRedirectCallback();
            }
            catch{
                await removeUser();
            }
            history.push('/login')
        }
        signoutAsync()
    }, [])

    return <LoadingView />
}

export default SignoutOidc
