import 'isomorphic-fetch';
import * as React from 'react';
import { IAuthState } from '../../../store/auth/reducer';
import { API_BASE_URL } from '../../../../utils/AppConstants';
import Notification from '../../helper/NotificationContainer';
import { IEExplorerBlocker } from '../IEExplorerBlocker';
import { SessionTimeout } from '@sssuite-component-ui/session-timeout';
import { handleResponse } from '../../../store/Library';
import LastLoginModal from '../LastLoginModal';
import SecurityModal from '../SecurityModal';
import { TYPES } from '../../../../Startup/types';
import { container } from '../../../../Startup/inversify.config';
import { ISessionLocalStore } from '../../../../Core/Utilities/SessionStore';
import SignalRWebSocket from '../../helper/SignalRwebSocketContainer';
import UserChangedWarning from '../UserChangedContainer';
import { logger } from '../../../../routes/LoggedIn';
import { validateError } from '../../helper/Validations';
import store  from '../../../store';
import { getAccessToken, signoutRedirect } from "../../../../oidcClient/userService";
import SSSuiteLayoutContainer from '../SSSuiteLayout/SSSuiteLayoutContainer';
import { UserAutoLogoutModal } from '../UserAutoLogoutModal';
import { actionCreators as PostAuthStore } from '../../../store/PostAuth/PostAuthStore';
import { createForethoughtCookie } from '../../helper/HelperFunctions';
import { injectPendoScript } from '../../CustomHooks/Pendo';

const SessionStore = container.get<ISessionLocalStore>(TYPES.ISessionStore);

declare global {
    interface Window {
        Variables: any;
        Forethought: any;
        pendo: any;
    }
}

const WarningVisibleMinutes = 1;

export interface AppLayoutProps {
    children?: React.ReactNode;
    authState : IAuthState;
    loggedOutState: boolean;
} 

interface ILayoutState {
    isVisible: boolean;
    showTimeout: boolean;
    signaturesIdentifierTab: any;
    sessionExpiredModal: boolean;
    sessionIdleMinutes: number;
};

export class AppLayout extends React.Component<AppLayoutProps & typeof PostAuthStore, ILayoutState> {
    private sessionRenew: any;
    private sessionExpired: any;

    constructor(props: AppLayoutProps & typeof PostAuthStore) {
        super(props);
        this.state = {
            isVisible: true,
            showTimeout: false,
            signaturesIdentifierTab: '',
            sessionExpiredModal: false,
            sessionIdleMinutes: 0
        };
    }


    componentDidMount() {
        // update every 20 minutes
        this.sessionRenew = setInterval(() => {
            this.renewSession();
        }, 1000 * 60 * (20));

        this.setIntervalSessionExpiredCheck();
        this.addWalkMeScript();
        this.setSessionIdleTime();
        injectPendoScript(this.props);
    }

    componentWillUnmount() {
        clearInterval(this.sessionRenew);
        clearInterval(this.sessionExpired);
    }

    private setIntervalSessionExpiredCheck = () => {

        this.sessionExpired = setInterval(() => {
            if (!getAccessToken()) {
                SessionStore.remove("isVisited");
                this.setState({ sessionExpiredModal: true });
            }
        }, 1000);

    }

    addWalkMeScript = () => {
        fetch(API_BASE_URL + `api/Common/GetWalkMeScript`, {
            method: 'GET',
            credentials: 'include'
        }).then(handleResponse)
            .then(function (response: string) {
                if(!(window as any)._walkmeConfig?.smartLoad){
                    if (response && response.length > 0) {
                        let scriptElement = document.createElement("script");
                        scriptElement.type = "text/javascript";
                        scriptElement.async = true;
                        scriptElement.text = response;
                        document.head.appendChild(scriptElement);
                        (window as any).loadWalkMe && (window as any).loadWalkMe();
                    }
                }
            })
            .catch(function (error: any) {
                console.log(error);
            });
    }

    public render() {
        const authUser = store.getState().auth;
        if (navigator.userAgent.indexOf("Trident") > -1) {
            return <IEExplorerBlocker />
        }
        else {
            return (<div>

                <Notification />

                 <SSSuiteLayoutContainer >
                    <div id="content-wrapper" className="px-3 container-fluid layout-container">{this.props.children}</div>
                </SSSuiteLayoutContainer> 

                <UserAutoLogoutModal logoutCause={authUser.userAutoLogoutCause} openModal={authUser.userPrivilegesChanged} />
                
                <SecurityModal openModal={this.state.sessionExpiredModal} />
                
                <SessionTimeout
                    deviceId={this.props.authState.user.profile.device_id}
                    logout={signoutRedirect}
                    sessionIdleMinutes={this.state.sessionIdleMinutes}
                    warningVisibleMinutes={WarningVisibleMinutes}
                    currentTabLoggedOutShow={this.props.loggedOutState}
                    onExternalLogout= {()=>createForethoughtCookie("isForethoughtWidgetVisible", "false")}
                    setCurrentTabLoggedOutShow={() =>{
                        this.props.loggedOut();
                    }}
                />

                <SignalRWebSocket />
                
                <LastLoginModal />
                
                <UserChangedWarning />

            </div>)
        };
    }

    private renewSession = () => {
        fetch(API_BASE_URL +`api/MyAccount/StayAliveAsync`, {
            method: 'GET',
            credentials: 'include'
        }).then(handleResponse)
            .catch(error => {
                logger.trackTrace(`StayAliveAsync failed with error ${validateError(error)}`);
                // This Api returns "302 Found" when users tocken expired in this case user will redirected to login page this is obvious flow.
                // so changing log type from trackError loging to trackTrace loging. 
        });
    }

    private setSessionIdleTime = () => {
        fetch(API_BASE_URL + 'api/Common/SessionTimeOutMinutes', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(data => this.setState({ sessionIdleMinutes: parseInt(data) }))
            .catch(error => this.setState({ sessionIdleMinutes: 30 }));
    }    
}