import { API_BASE_URL } from "src/utils/AppConstants";
import { IClientInfoComparisonResultViewModel, IClientInfoViewModel } from "../models/ClientManagement";
import { handleResponse } from "./Library";
import { addTask } from "domain-task";
import { logger } from "src/routes/LoggedIn";
import { validateError } from "../components/helper/Validations";
import { HeaderResourceId } from '../components/helper/Constants';



export const getClientInfoByClientId = (clientId: string, resourceId: string, callback: (data: IClientInfoViewModel) => void) => {
    const fetchTask = fetch(API_BASE_URL + `api/ClientManagement/GetClientInfoByClientIdAsync?clientId=${encodeURIComponent(clientId)}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json, */*',
            'Content-Type': 'application/json',
            [HeaderResourceId]: resourceId
        }
    })
    .then(handleResponse)
    .then((data: IClientInfoViewModel) => {
        callback(data);
    })
    .catch((error: any) => {
        logger.trackError(`getClientInfoByClientId failed with error ${validateError(error)}`);
    })

    addTask(fetchTask);
}

export const addClientInfo = (clientInfo: IClientInfoViewModel, resourceId: string, callback: () => void) => {
    const fetchTask = fetch(API_BASE_URL + `api/ClientManagement/AddClientInfoAsync`,{
    method: "POST",
    credentials: "include",
    headers: {
        'Accept': "application/json, */*",
        'Content-Type': "application/json",
        [HeaderResourceId]: resourceId
    },
    body: JSON.stringify(clientInfo)
    })
    .then(handleResponse)
    .then((response: any) => {
        callback();
    })
    .catch((error: any) => {
        logger.trackError(`addClientInfo failed with error ${validateError(error)}`);
    })

    addTask(fetchTask);
}

export const editClientInfo = (clientInfo: IClientInfoViewModel, resourceId: string, callback: () => void) => {
    const fetchTask = fetch(API_BASE_URL + `api/ClientManagement/EditClientInfoAsync`,{
    method: "PUT",
    credentials: "include",
    headers: {
        'Accept': "application/json, */*",
        'Content-Type': "application/json",
        [HeaderResourceId]: resourceId
    },
    body: JSON.stringify(clientInfo)
    })
    .then(handleResponse)
    .then((response: any) => {
        callback();
    })
    .catch((error: any) => {
        logger.trackError(`editClientInfo failed with error ${validateError(error)}`);
    })

    addTask(fetchTask);
}

export const CompareClientInfo = (
    clientInfo: IClientInfoViewModel, 
    resourceId: string,
    callback: (data: IClientInfoComparisonResultViewModel, clientInfo: IClientInfoViewModel) => void
    ) => {
    const fetchTask = fetch(API_BASE_URL + `api/ClientManagement/CompareAsync`,{
    method: "POST",
    credentials: "include",
    headers: {
        'Accept': "application/json, */*",
        'Content-Type': "application/json",
        [HeaderResourceId]: resourceId
    },
    body: JSON.stringify(clientInfo)
    })
    .then(handleResponse)
    .then((data: IClientInfoComparisonResultViewModel) => {
        callback(data, clientInfo);
    })
    .catch((error: any) => {
        logger.trackError(`CompareClientInfo failed with error ${validateError(error)}`);
    })

    addTask(fetchTask);
}
