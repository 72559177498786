let moment = require('moment');
import * as React from 'react';
import { Alert } from 'react-bootstrap';
import {
    BootstrapTable, TableHeaderColumn, TextFilter, CustomFilter, SelectFilter,
    CustomSelectProps} from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { Link } from 'react-router-dom';
import { CheckBoxComponent, CheckBoxSize } from "../../../components/common/CheckBoxComponent";
import { DaysRangeOptionsList, SearchDelayTimeConstant } from '../../helper/Constants';
import * as Helper from '../../../components/helper/HelperFunctions';
import { CustomMultiSelect } from "../../../components/common/MultipleSelectComponent";
import { SignatureFlowReportSignatureStatus } from '../../../models/SignatureFlowReportState';
import { ISignatureFlowReportTableModel } from '../../../models/SignatureFlowReportTableState';
import { getSignatureStatusClassName, sortRenderer } from '../../../components/helper/HelperFunctions';
import CustomSingleSelect from '../../common/SingleSelectComponent';
import { InfoIconFill } from '../../svg/CustomSvgIcons';

export interface TableProps {
    isLoading: boolean,
    onSortChange: any,
    onSearchChange: any,
    onFilterChange: any,
    pageNo: number,
    totalRows: number;
    pageSize: number,
    onRowSelect: (row: any, isSelected: any, e: any) => void,
    onSelectAll: (isSelected: any, rows: any, e: any) => void,
    selectedRows: number[],
    onPageReload: () => void,
    onSignersDetailOpen: (rowIndex: number) => void,
    onDeleteDocumentsOpen: (rowIndex: number) => void
    onCancelDocumentOpen: (rowIndex: number) => void,
    onRetoreDocumentsOpen: (rowIndex: number) => void;
    signatureReportsData: ISignatureFlowReportTableModel,
    onRecipientDetailsOpen: (rowIndex: number) => void,
    onDocumentDetailsOpen: (rowIndex: number) => void
    showBulkSelectionMessage: boolean;
    onBulkSelectionChange: (isEnabled: boolean) => void;
    officeLocation: any[];
}

const SignatureStatusList = {
    "None": "None",
    "Processing": "Processing",
    "OutForSignature": "Out For Signature",
    "ESigned": "E-Signed",
    "Declined": "Declined",
    "Cancelled": "Canceled",
    "PartiallySigned": "Partially Signed",
    "SignatureStampingFailed": "Signature Stamping Failed",
    "DeliveryFailed":"Delivery Failed"
};

export class SignatureRecycleReportTable extends React.Component<TableProps, {}>{
    private filterChanged: boolean = false;
    public refClearSearchBtn: any;
    private customMultiSelect: any;
    private customExpirationDateFilter: any;
    public isAppiedFilter: boolean = false;
    private taxYearList: any[] = [];
    private customDeletionDateSingleSelect: any;
    private customTaxYearMultiSelect: any;
    private customOfficeLocationMultiSelect: any;

    constructor(props: TableProps) {
        super(props);
        this.defaultType = this.defaultType.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.taxYearList = Helper.getTaxYearsForSignReportsFilter();
    }

    private onFilterChange(dataField: any) {
        if (!this.filterChanged && !this.isAppiedFilter) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField);
            this.filterChanged = false;
        }
    }

    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"signatureflow-reports-checkbox-" + props.rowIndex}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            className={"custom-curve-check-box SignatureRecyclecheckbox"}
            text={""} />);
    }

    private setNoContent() {
        if (this.props.isLoading) {
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return "No reports found"
        }
    }

    private defaultType(cell: any, row: any) {
        return <div title={cell} className="ellipsis">{cell}</div>
    }

    //===================================Signature Status Column Header====================================================//
    customSignatureStatusToolTip(cell: any, row: any) {
        let status: any = row.signatureStatus;
        let signatureType: "None" | "Processing" | "OutForSignature" | "ESigned" | "Declined" | "Cancelled" | "PartiallySigned" | "SignatureStampingFailed" | "DeliveryFailed"
            = (typeof status == "number") ? SignatureFlowReportSignatureStatus[row.signatureStatus] : row.signatureStatus;
        return `${SignatureStatusList[signatureType]}`;
    }

    private signatureStatusDataFormatType = (cell: any, row: any) => {

        let status: any = row.signatureStatus;
        let signatureType: "None" | "Processing" | "OutForSignature" | "ESigned" | "Declined" | "Cancelled" | "PartiallySigned" | "SignatureStampingFailed" | "DeliveryFailed"
            = (typeof status == "number") ? SignatureFlowReportSignatureStatus[status] : status;
        const signatureStatusClassName = getSignatureStatusClassName(SignatureStatusList[signatureType])
        if (signatureType == undefined) {
            <Link to={"#"}>{SignatureStatusList["None"].toUpperCase()}</Link>;
        }
        else if (SignatureStatusList[signatureType] === SignatureStatusList.Cancelled || SignatureStatusList[signatureType] === SignatureStatusList.Declined) {
            return <div className={'signature-status '+signatureStatusClassName}>
                        <span className='report-signature-status'
                        onClick={() => this.props.onCancelDocumentOpen(row.rowIndex)}>{SignatureStatusList[signatureType]}</span>
                    </div> ;
        }
        else {
            return <div className={'signature-status '+signatureStatusClassName}>
                        <span className='report-signature-status' onClick={() => this.props.onSignersDetailOpen(row.rowIndex)}>{SignatureStatusList[signatureType]}</span>
                    </div>
        }
    }

    //==================================Signature Status Column Header Ends Here====================================================//

    private CreateSignerDataType = (cell: any, row: any) => {
        return (
            <div style={{ display: "inline-flex" }}>

                <Link to={"#"} onClick={() => this.props.onRecipientDetailsOpen(row.rowIndex)} style={{ display: "contents" }}>
                    <div style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "135px"
                    }} title={(row.signers)}>
                        {(row.signers)}
                    </div>
                    <div>
                        {" (" + row.signedCount + " of " + row.signersCount + " )"}
                    </div>
                </Link>

            </div>
        );
    }

    private CreateDocumentTypeDataFormatType = (cell: any, row: any) => {
        if (row.documentCount > 1) {
            return (<Link to={'#'} className="ellipsis" onClick={() => this.props.onDocumentDetailsOpen(row.rowIndex)}>{"Multiple Documents"}</Link>)
        }
        return <span className='ellipsis'>{`${row.documentType}`}</span>
    }

    private CreateDocumentTypeToolTip = (cell: any, row: any) => {
        if (row.documentCount == 1) {
            return `${row.documentType}`
        }
    }

    private getMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }
    
    private getDeletionDateSingleSelectDropDown = (filterHandler: any, customFilterParameters: any)  => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomSingleSelect onRef={(ref: any) => (this.customDeletionDateSingleSelect = ref)} filterHandler={filterHandler} options={options} placeholder={placeholder} />
        );
    } 

    private getYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customTaxYearMultiSelect = ref)} filterHandler={filterHandler} options={options} enableAllFilter={enableAllFilter} placeholder={placeholder} isSignatureReports="True" />
        );
    }

    private getMultiOfficeLocationDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect
                onRef={(ref: any) => (this.customOfficeLocationMultiSelect = ref)}
                filterHandler={filterHandler}
                options={options}
                placeholder={placeholder}
            />
        );
    }

    private GetSortedColumns = (columns: any) => {
        let columnHeader: any = columns;

        const indexOfColumnIndex = columnHeader.findIndex((item:any) => item.key === 'index');
        columnHeader = Helper.moveItemAtEnd(indexOfColumnIndex, columnHeader);

        return columnHeader;
    }

    private showBulkAlertMessage = () => {
        if (this.props.showBulkSelectionMessage && this.props.totalRows > this.props.pageSize) {
            let documentsInPage = this.props.signatureReportsData.documents.length;
            return (<div className="col-sm-9 col-lg-9 font16" style={{ position: "relative", display: "inline-block", zIndex: 10, paddingLeft: '0px'}} >
                        <div className='custom-alert-warning'>         
                            <InfoIconFill marginLeft='5px' marginBottom='2px'/>  You have selected all  {documentsInPage} records displayed. To select the additional {this.props.totalRows - documentsInPage} records, please
                            <Link to={"#"} onClick={() => this.props.onBulkSelectionChange(true)}> click here</Link>
                        </div>
                    </div>)
        }
    }

    private createCustomToolBar = () => {
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 marR15">
                {
                    this.showBulkAlertMessage()
                }
            </div >);
    }

    public render() {

        let selected: number[] = [];
        let unselectable: number[] = [];
        if (this.props.selectedRows.length > 0) {
            for (var i in this.props.selectedRows) {
                let rowIndex = this.props.selectedRows[i];
                selected.push(((this.props.pageNo - 1) * this.props.pageSize) + rowIndex);
            }
        }
        let data: any = [];
        if (this.props.signatureReportsData.documents) {
            data = this.props.signatureReportsData.documents.map((model, index) => {
                return {
                    signingId: model.signingId,
                    deliveredBy: model.isExpired ? "System" : model.senderName,
                    loggedUser: model.loggedUser,
                    documentName: model.documentName == null ? " " : model.documentName,
                    documentType: model.documentType == null ? " " : model.documentType,
                    documentStatusChangedOn: model.documentStatusChangedOn == null ? "" : moment.utc(model.documentStatusChangedOn).local().format('MM/DD/YYYY'),
                    signers: model.signersEmailAddress,
                    title: model.title,
                    signatureStatus: model.signatureStatus,
                    downloadStatus: model.downloadCount,
                    documentCount: model.documentCount,
                    signedCount: model.signedCount,
                    signersCount: model.signersCount,
                    expiration: model.expiration == null ? "" : moment(model.expiration).local().format('MM/DD/YYYY'),
                    button: 'Actions',
                    index: ((this.props.pageNo - 1) * this.props.pageSize) + index,
                    rowIndex: index,
                    taxYear: (model.taxYear == null || model.taxYear == 0) ? "" : model.taxYear,
                    recipientName: model.recipientName == null ? "" : model.recipientName,
                    clientId: model.clientId == null ? "" : model.clientId,
                    officeLocation: model.officeLocation == null ? "" : model.officeLocation
                }
            });
        }

        const options = {
            onSortChange: this.props.onSortChange,            
            sizePerPage: this.props.pageSize,
            onSearchChange: this.props.onSearchChange,
            page: this.props.pageNo,
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
            toolBar: this.createCustomToolBar,
            clearSearch: true            
        };
        let selectRowProp: any = {
            mode: 'checkbox',
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            selected: selected,
            className: 'row-selected',
            customComponent: this.createCustomCheckbox
        };

        const SignatureStatusOptionsList = [
            { label: 'Canceled', value: 5 },
            { label: 'Declined', value: 4 },
            { label: 'Delivery Failed', value: 8},
            { label: 'E-Signed', value: 3 },
            { label: 'Out For Signature', value: 2 },
            { label: 'Partially Signed', value: 6 },
            { label: 'Processing', value: 1 },
            { label: 'Signature Stamping Failed', value: 7 }
        ];



        const Columns = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultType,
                className: 'text-field-header header-text',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Client Name',
                key: 'recipientName',
                isKey: false,
                dataFormat: this.defaultType,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                className: 'text-field-header header-text',
                width: 'auto',
                filter: {
                    type: 'TextFilter', placeholder: 'Client Name', style: { font: 'bold' }, delay : SearchDelayTimeConstant
                } as TextFilter
            },
            {
                header: 'Client ID',
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultType,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                className: 'text-field-header header-text',
                width: 'auto',
                filter: {
                    type: 'TextFilter', placeholder: 'Client ID', style: { font: 'bold' }, delay : SearchDelayTimeConstant
                } as TextFilter
            },
            {
                header: 'Office Location',
                key: 'officeLocation',
                isKey: false,
                dataFormat: this.defaultType,
                className: 'text-field-header header-text',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'CustomFilter', getElement: this.getMultiOfficeLocationDropDown, 
                        customFilterParameters: { options: this.props.officeLocation, enableAllFilter: true, placeholder: 'Office Location' } as any } as CustomFilter
            },
            {
                header: 'Subject',
                key: 'title',
                isKey: false,
                dataFormat: this.defaultType,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                className: 'text-field-header header-text',
                width: 'auto',
                filter: {
                    type: 'TextFilter', placeholder: 'Subject', style: { font: 'bold' }, delay : SearchDelayTimeConstant
                } as TextFilter
            },
            {
                header: 'Document Type',
                key: 'documentType',
                isKey: false,
                dataFormat: this.CreateDocumentTypeDataFormatType,
                dataSort: true,
                isHidden: false,
                toolTip: this.CreateDocumentTypeToolTip,
                className: 'text-field-header header-text',
                width: 'auto',
                filter: {
                    type: 'TextFilter', placeholder: 'Document Type', style: { font: 'bold' }, delay : SearchDelayTimeConstant
                } as TextFilter
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataFormat: this.defaultType,
                className: 'text-field-header header-text',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'CustomFilter', getElement: this.getYearMultiSelectDropDown, customFilterParameters: { options: this.taxYearList, enableAllFilter: true, placeholder: 'Tax Year' } as any } as CustomFilter
            },
            {
                header: 'Recipients',
                key: 'signers',
                isKey: false,
                dataFormat: this.CreateSignerDataType,
                className: 'text-field-header header-text',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: '200px',
                filter: {
                    type: 'TextFilter', placeholder: 'Recipients', style: { font: 'bold' }, delay : SearchDelayTimeConstant
                } as TextFilter
            },
            {
                header: 'Status',
                key: 'signatureStatus',
                isKey: false,
                dataSort: true,
                dataFormat: this.signatureStatusDataFormatType,
                width: '200px',
                className: 'text-field-header header-text',
                toolTip: this.customSignatureStatusToolTip,
                isHidden: false,
                filter: {
                    type: 'CustomFilter', getElement: this.getMultiSelectDropDown, customFilterParameters: { options: SignatureStatusOptionsList, enableAllFilter: false, placeholder: 'Status' } as any
                } as CustomFilter
            },
            {
                header: 'Deleted By',
                key: 'deliveredBy',
                isKey: false,
                dataFormat: this.defaultType,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                className: 'text-field-header header-text',
                width: 'auto',
                filter: {
                    type: 'TextFilter', placeholder: 'Deleted By', style: { font: 'bold' }, delay : SearchDelayTimeConstant
                } as TextFilter
            },
            {
                header: 'Deletion Date',
                key: 'documentStatusChangedOn',
                isKey: false,
                dataFormat: this.defaultType,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                className: 'text-field-header header-text',
                width: 'auto',
                filter: { type: 'CustomFilter', getElement: this.getDeletionDateSingleSelectDropDown, customFilterParameters: { options: DaysRangeOptionsList, placeholder: 'Deletion Date' } as any } as CustomFilter
            }

        ];

        const SortedColumns: any[] = this.GetSortedColumns(Columns);

        return <div className="pos-relative">            
            <div>
                {SortedColumns.length > 0 &&
                    <BootstrapTable
                        containerClass='report-table-container clearfix'
                        ref='signatureReportTable'
                        data={data}
                        remote={true}
                        fetchInfo={{ dataTotalSize: this.props.totalRows }}
                        options={options}
                        tableHeaderClass='report-header-border table-header-font'
                        trStyle={{borderBottom:'1px solid #A6A9AC', height: '32px'}}
                        striped
                        hover={true}
                        bordered={false}
                        pagination={false}
                        selectRow={selectRowProp}>
                        {SortedColumns.map((value, index) => {
                            return <TableHeaderColumn
                                key={index}
                                ref={value.key}
                                isKey={value.isKey}
                                dataField={value.key}
                                hidden={value.isHidden}
                                width={value.width}
                                dataFormat={value.dataFormat}
                                className={value.className}
                                columnTitle={value.toolTip}
                                filter={value.filter}
                                dataSort={value.dataSort}
                                caretRender={sortRenderer}
                            ><span title={value.header} className="table-text-sort">{value.header}</span>
                            </TableHeaderColumn>;
                        })}
                    </BootstrapTable>
                }
            </div>

        </div>
    }
}

export default SignatureRecycleReportTable;
