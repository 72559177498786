import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/types';
import * as SignatureFlowSettingsStore from '../../../store/SignatureFlowSettingsStore';
import * as SignatureFlowAuthenticationQuestionsStore from '../../../store/settings/AuthenticationQuestions/AuthenticationQuestionsStore';
import * as UsersStore from '../../../store/SFUserStore';
import { GeneralSettings } from './GeneralSettings';

export default connect(
    (state: ApplicationState) => ({
        signatureFlowSettingsData: state.signatureFlowSettingsData,
        userList: state.sfUserList,
        authenticationQuestionsData: state.AuthenticationQuestionsData
    }),
    {
        ...SignatureFlowSettingsStore.actionCreators,
        ...UsersStore.actionCreators,
        ...SignatureFlowAuthenticationQuestionsStore.actionCreators
    }
)(GeneralSettings as any);