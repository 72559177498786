import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { SortDirections } from "../../../components/common/Filters";
import { RouteComponentProps } from 'react-router';
import * as Helper from '../../../components/helper/HelperFunctions';
import * as LockedReportStore from '../../../store/ReportsStore/LockedReport/LockedReportStore';
import * as SignatureFlowReportPopupModelStore from '../../../store/ReportsCommon/SignatureFlowReportPopupModelStore';
import * as SignatureFlowReportFilterStore from '../../../store/ReportsCommon/SignatureFlowReportFilterStore';
import Pagination from '../../common/Pagination';
import { initialSFFilter } from '../../../models/SignatureFlowSettings';
import * as SignatureFlowReportState from '../../../models/SignatureFlowReportTableState';
import SigantureLockedReportTable from './SignatureLockedReportTable';
import { SignatureLockedReportsHeader } from './SignatureLockedReportHeader';
import { GetInitialLockedReportPageState, ISignatureFlowLockedReportPageState } from '../../../models/SignatureFlowLockedReportTableState';
import { Common, SizePerPageList } from '../../helper/Constants';
import { ISignatureFlowReportDocument, ISignatureFlowReportFilter } from '../../../models/SignatureFlowReportState';
import SignatureFlowReportDocumentModal from '../ActionButtonModals/SignatureFlowReportDocumentModal';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { SignatureFlowReportFilterConstants } from '../../helper/Constants';
import { HideLoader, ShowLoader } from '../../helper/Loader';
import { logger } from 'src/routes/LoggedIn';
import { getAdjustedReportsPageNumber } from '../../../components/helper/HelperFunctions';
import * as bootbox from 'bootbox';
import { ScreenIds } from '../../helper/RBACConstants';

type SignatureLockedReportProps =
    {
        LockedReportDocuments: SignatureFlowReportState.ISignatureFlowReportState,
        signatureFlowReportDetails: SignatureFlowReportPopupModelStore.ISignatureFlowReportPopupDictionary,
        signatureFlowReportFilter: SignatureFlowReportFilterStore.IFilterState,
        pageTitle: string,
    }
    & typeof LockedReportStore.actionCreators
    & typeof SignatureFlowReportFilterStore.actionCreators
    & typeof SignatureFlowReportPopupModelStore.actionCreators
    & RouteComponentProps<{ page: string }>;

const pageSize: number = 10;
const NO_INDEX = -1;

export const SignatureLockedReport:React.FC <SignatureLockedReportProps> = (props) => {
    const SignatureLockedReportTableRef: any = useRef();
    const [lockedReportPageState, setLockedReportPageState] = useState<ISignatureFlowLockedReportPageState>(GetInitialLockedReportPageState(pageSize));
    const [defaultFilter, setDefaultFilter] = useState<ISignatureFlowReportFilter>(initialSFFilter);

    useEffect(() => {
        props.requestSignatureLockedDocuments(buildQuery(lockedReportPageState.page, lockedReportPageState.pageSize), true);

        props.requestSignatureLockedReportFilter();
    },[])

    useEffect(()=>{
        if(lockedReportPageState.stateCallback !== null){
            lockedReportPageState.stateCallback();
            setLockedReportPageState({...lockedReportPageState,
                stateCallback: null
            })
        }

        if(props.LockedReportDocuments.totalRowCount !== lockedReportPageState.totalRecordsCount) {
            if(!props.LockedReportDocuments.isLoading) {
                setLockedReportPageState({...lockedReportPageState,
                    totalRecordsCount: props.LockedReportDocuments.totalRowCount
                });
            }
        }
        
    },[lockedReportPageState]);

    useEffect(() => {
        setDefaultFilter(props.signatureFlowReportFilter.lockedReportFilter.find(x => x.isDefault == 1));

        if(props.LockedReportDocuments.totalRowCount !== lockedReportPageState.totalRecordsCount) {
            if(!props.LockedReportDocuments.isLoading) {
                setLockedReportPageState({...lockedReportPageState,
                    totalRecordsCount: props.LockedReportDocuments.totalRowCount
                });
            }
        }
    },[props])

    const buildQuery = (page: number, pageSize: number, updateFilterState?:ISignatureFlowLockedReportPageState) => {
        return '?pageNo=' + page
            + '&pageSize=' + pageSize
            + '&sortBy=' + (updateFilterState ? updateFilterState.sortName : lockedReportPageState.sortName)
            + '&sortOrder=' + (updateFilterState ? updateFilterState.sortOrder : lockedReportPageState.sortOrder)
            + '&searchString=' + (updateFilterState ? updateFilterState.searchString : lockedReportPageState.searchString)
            + '&filterDocumentType=' + (updateFilterState ? updateFilterState.filterDocumentType : lockedReportPageState.filterDocumentType)
            + '&filterTaxYear=' + (updateFilterState ? updateFilterState.filterTaxYear : lockedReportPageState.filterTaxYear)
            + '&filterRecipientName=' + encodeURIComponent(updateFilterState ? updateFilterState.filterRecipientName : lockedReportPageState.filterRecipientName)
            + '&filterClientId=' + encodeURIComponent(updateFilterState ? updateFilterState.filterClientId : lockedReportPageState.filterClientId)
    }

    const onRowSelect = (row: any, isSelected: any, e: any) => {
        let newList = [...lockedReportPageState.selectedRows];
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A' && e.target.tagName !== 'DIV') {
            if (isSelected) {
                newList.push(row.rowIndex);
            } else {
                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
            }
            setLockedReportPageState({...lockedReportPageState,selectedRows: newList });
        }
    }

    const onSelectAll = (isSelected: boolean, rows: any[], e: any) => {
        let selectedRows: number[] = [];
        if (isSelected) {
            rows.forEach((value: any, index: number) => {
                selectedRows.push(value.rowIndex);
            });
        }
        setLockedReportPageState({...lockedReportPageState, selectedRows: selectedRows });
    }

    const onSearchChange = (searchString: string) => {
        let temp: ISignatureFlowReportFilter = { ...lockedReportPageState.filter };
        temp.searchText = searchString;
        let queryString = buildQuery(1, lockedReportPageState.pageSize);
        setLockedReportPageState({...lockedReportPageState,
            searchString: searchString,
            filter: temp,
            selectedRows:[],
            deSelectedRows:[],
            stateCallback: () => props.requestSignatureLockedDocuments(queryString, false)
        });
    };

    const isFilterChanged = (newState:ISignatureFlowLockedReportPageState, filterStatus: any, filterTaxYear: any): boolean => {
        return (
            newState.filterDocumentType != lockedReportPageState.filterDocumentType ||
            filterTaxYear != lockedReportPageState.filterTaxYear ||
            newState.filterRecipientName != lockedReportPageState.filterRecipientName ||
            newState.filterClientId != lockedReportPageState.filterClientId);
    }

    const  onFilterChange = (dataField: any) => {
        let newState: ISignatureFlowLockedReportPageState = {
            filterDocumentType: "",
            filterRecipientName: "",
            filterClientId: "",
        } as ISignatureFlowLockedReportPageState;
        let filterStatus: any;
        let filterTaxYear: any;
        var dict: { [columnName: string]: string; } = {};
        for (let field of Object.keys(dataField)) {
            dict[field.valueOf().toString()] = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            switch (field) {
                case 'taxYear':
                    let tempYear = dataField[field].value ? dataField[field].value : dataField[field];
                    tempYear = ( tempYear && (tempYear !== "-1")) ? tempYear : "";
                    newState.filterTaxYear = tempYear;
                    break;
                case 'documentType':
                    newState.filterDocumentType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'recipientName':
                    newState.filterRecipientName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'clientId':
                    newState.filterClientId = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
            }
        }
        
        let tempfilter: ISignatureFlowReportFilter = { ...lockedReportPageState.filter };
        tempfilter.fields = dict;
        let stateCallBack : any = null;
        if (SignatureLockedReportTableRef && SignatureLockedReportTableRef.current && !SignatureLockedReportTableRef.current.isAppliedFilter && !props.LockedReportDocuments.isLoading) {
            let query = buildQuery(1, lockedReportPageState.pageSize, {...lockedReportPageState, ...newState, ...tempfilter});
            stateCallBack = () => {
                props.requestSignatureLockedDocuments(query, true);
            }
        }
        if (isFilterChanged(newState, filterStatus, filterTaxYear)) {
            setLockedReportPageState({...lockedReportPageState,
                ...newState,
                filter: tempfilter,
                page:1,
                selectedRows:[],
                deSelectedRows:[],
                stateCallback: stateCallBack
            })
        }
    }

    const clearSelection = () => {
        setLockedReportPageState({...lockedReportPageState,
            selectedRows: [],
            deSelectedRows: []
        });
    }

    const getStoreItem = (index: number) => {
        let id = props.LockedReportDocuments.signatureFlowReportTableModel.documents[index].signingId;
        return props.signatureFlowReportDetails[id].signatureReport;

    }

    const onDocumentDetailsOpen = (rowIndex: number = Helper.NO_INDEX) => {
        setLockedReportPageState({...lockedReportPageState,
            documentDetailsState: {
                show: true,
                model: getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });

    }


    const onUnlockDocumentsOpen = (rowIndex: number = NO_INDEX) => {
        if(rowIndex==NO_INDEX && lockedReportPageState.selectedRows.length==1){
            rowIndex=lockedReportPageState.selectedRows[0]
        }
        let ids = onPopupOpen(rowIndex);
        if (ids.length == 0) {
            VenusNotifier.Warning("Please select document(s) to unlock!", null);
            return;
        }
        const assignToUser = rowIndex != NO_INDEX ? (props.LockedReportDocuments.signatureFlowReportTableModel.documents[rowIndex].assignedTo) : null;
        const message = `Before unlocking, kindly make sure that ${(assignToUser) ? (assignToUser + ' is'): 'all users are'} not presently working on these selected document(s).`; 
        bootbox.confirm({
            title:'Important!',
            message: message,
            buttons: {
                cancel: {
                    label: 'Cancel',
                    className:'btn-white modal-footer-button-cancel'
                },
                confirm: {
                    label: 'Unlock Document(s)',
                    className:'btn-info modal-footer-button-save'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    ShowLoader(Common.OverlayMessages.Unlocking);
                    onUnlockSelectedDocuments(ids);
                    logger.trackTrace("onUnlockSelectedDocuments with ids" + JSON.stringify(ids));
                }

            }
        }).find(".modal-dialog")
        .addClass("modal-dialog-centered");
    }

    const onUnlockSelectedDocuments = (ids: number[]) => {
        props.unlockSigningDocuments(ids, true, (isSuccess: boolean) => {
            if(isSuccess){
                const adjustedPageNumber = getAdjustedReportsPageNumber(props.LockedReportDocuments.signatureFlowReportTableModel.count, ids.length, lockedReportPageState.page, lockedReportPageState.pageSize)
                setLockedReportPageState({
                    ...lockedReportPageState,
                    page: adjustedPageNumber
                })
                props.requestSignatureLockedDocuments(buildQuery(adjustedPageNumber, lockedReportPageState.pageSize), true, () => {
                    clearFilters(adjustedPageNumber);
                })
            }
            HideLoader();
        });
    }
    const clearFilters = (page: number) => {
        setLockedReportPageState({ 
            ...lockedReportPageState,
            page: page,
            selectedRows: [],
            stateCallback: () => onPageReload(page)
        });
        SignatureLockedReportTableRef.current && 
        SignatureLockedReportTableRef.current.filtersRef?.current && 
        SignatureLockedReportTableRef.current.filtersRef.current.setState({ activeFilter: '', appliedFilters: [] });
    }
    const onPopupOpen = (rowIndex: number) => {
        if (rowIndex !== NO_INDEX) {
            return [props.LockedReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId];
        }
        if (lockedReportPageState.selectedRows.length == 0) {
            return [];
        }
        return selectedDocumentIds();
    }
    const selectedDocumentIds = () => {
        let ids: number[] = [];
        lockedReportPageState.selectedRows.map((row, i) => {
            let selectedDocument = props.LockedReportDocuments.signatureFlowReportTableModel.documents[row];
            if (selectedDocument) {
                ids.push(selectedDocument.signingId);
            }
        });
        return ids;
    }

    const onDocumentDetailsCancel = () => {
        setLockedReportPageState({...lockedReportPageState,
            documentDetailsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    const onSortChange = (sortName: string, sortOrder: string, column: number) => {
        clearSelection();
        let newState = {...lockedReportPageState};
        newState.filter.sort.column = sortName;
        newState.filter.sort.direction = sortOrder == "asc" ? SortDirections.Ascending : SortDirections.Descending;
        newState.sortName = sortName;
        newState.sortOrder = sortOrder == "asc" ? "asc" : "desc";
        newState.page = 1;
        newState.selectedRows = [];
        newState.deSelectedRows = [];
        
        let queryString = buildQuery(1, lockedReportPageState.pageSize, newState);
        setLockedReportPageState({...newState,
            stateCallback: () => props.requestSignatureLockedDocuments(queryString, false)
        })
    }

    const onPageChange = (page: number, pageSize: number) => {
        let queryString = buildQuery(page, pageSize);
        setLockedReportPageState({...lockedReportPageState,
            page: page,
            pageSize:pageSize,
            selectedRows: [],
            stateCallback:() => props.requestSignatureLockedDocuments(queryString, false)
        });
    }

    const loadSignatureDraftReports = () => {
        const queryString = buildQuery(lockedReportPageState.page, lockedReportPageState.pageSize);
        props.requestSignatureLockedDocuments(queryString, false);
    }

    const onSaveFilterShow = () => {
        setLockedReportPageState({...lockedReportPageState,
            saveFilterShow: true
        });
    }

    const onSaveFilterHide = () => {
        setLockedReportPageState({...lockedReportPageState,
            saveFilterShow: false
        });
    }

    const onFilterNameChange = (event: any) => {
        let temp: ISignatureFlowReportFilter = { ...lockedReportPageState.filter };
        temp.name = event.target.value;
        setLockedReportPageState({...lockedReportPageState,
            filter: temp
        });
    }

    const onFilterSave = (onApplyFilter: (filter: ISignatureFlowReportFilter) => void) => {
        if (lockedReportPageState.filter.name.trim() != '') {
            let filter = props.signatureFlowReportFilter.lockedReportFilter.find(x => x.name.toLocaleLowerCase() == lockedReportPageState.filter.name.toLocaleLowerCase());
            if (filter) {
                VenusNotifier.Warning(SignatureFlowReportFilterConstants.OtherMessage.FilterNameAlreadyExists, null);
            }
            else {
                props.saveSignatureLockedReportFilter(lockedReportPageState.filter.name, lockedReportPageState.filter, () => {
                    onApplyFilter(lockedReportPageState.filter);
                    onSaveFilterHide();
                });
            }
        }
        else {
            VenusNotifier.Warning(SignatureFlowReportFilterConstants.OtherMessage.PleaseEnterFilterName, null);
        }
    }

    const onSetDefaultFilter = (name: string) => {
        let oldDefaultFilter = props.signatureFlowReportFilter.lockedReportFilter.find(x => x.isDefault == 1); 
        let filter: ISignatureFlowReportFilter[] = [];
        if (oldDefaultFilter != null && oldDefaultFilter != undefined && oldDefaultFilter.name != name) {
            oldDefaultFilter.isDefault = 0;
            props.updateSignatureLockedReportFilter(oldDefaultFilter.name, oldDefaultFilter, undefined, false);
        }

        filter = props.signatureFlowReportFilter.lockedReportFilter.filter(x => x.name == name);
        if (filter) {
            filter[0].isDefault = 1;
            setDefaultFilter(filter[0]);
            props.updateSignatureLockedReportFilter(name, filter[0], undefined, true);

        }
    }

    const onRemoveDefaultFilter = (name: string) => {
        let filter = props.signatureFlowReportFilter.lockedReportFilter.find(x => x.name == name);
        if (filter) {
            filter.isDefault = 0;
            props.updateSignatureLockedReportFilter(name, filter, undefined, true);
        }
        onPageReload();
    }

    const onPageReload = (page?:number) => {
        const query = buildQuery(page ? page : lockedReportPageState.page, lockedReportPageState.pageSize);
        props.requestSignatureLockedDocuments(query, true);
        setLockedReportPageState({...lockedReportPageState,
                                selectedRows: []});
    }

    const onBulkSelectionChange = (isEnabled: boolean) => {
        let showBulkSelectionMessage = false;

        setLockedReportPageState({...lockedReportPageState,
            isBulkSelectionEnabled: isEnabled,
            showBulkSelectionMessage: showBulkSelectionMessage
        });
    }

    const onFilterUpdate = (name: string) => {
        let filter = props.signatureFlowReportFilter.lockedReportFilter.find(x => x.name == name);
        if (!filter) {
            VenusNotifier.Warning(SignatureFlowReportFilterConstants.OtherMessage.FilterDoesNotExists, null);
        }
        else {
            filter.fields = lockedReportPageState.filter.fields;
            filter.name = name;
            filter.searchText = lockedReportPageState.filter.searchText;
            filter.sort = lockedReportPageState.filter.sort;
            filter.isDefault = lockedReportPageState.filter.isDefault;
            props.updateSignatureLockedReportFilter(name, filter, undefined, true);
            onSaveFilterHide();
        }
    }

    const onFilterDelete = (filterName: string) => {
        props.removeSignatureLockedReportFilter(filterName);
    }

    const getDefaultFilter = () => {
        let defaultFilterToApply = !props.LockedReportDocuments.isLoading && 
                                    !props.signatureFlowReportFilter.isLoading && defaultFilter ? defaultFilter.name : undefined;
        return defaultFilterToApply;
    }

    return (<div className='user-assignment-content signatureflow-report'>
            <div>
                <SignatureLockedReportsHeader
                    screenId={ScreenIds.Locked}
                    pageTitle={props.pageTitle}
                    selectedDocumentCount={lockedReportPageState.selectedRows.length}
                    onUnlockDocuments={onUnlockDocumentsOpen}
                    />
                <br/>
                
                <SigantureLockedReportTable
                ref={SignatureLockedReportTableRef}
                screenId={ScreenIds.Locked}
                isLoading={props.LockedReportDocuments.isLoading}
                onSortChange={onSortChange}
                onSearchChange={onSearchChange}
                onFilterChange={onFilterChange}
                pageNo={lockedReportPageState.page}
                totalRows={props.LockedReportDocuments.totalRowCount}
                pageSize={lockedReportPageState.pageSize} 
                onRowSelect={onRowSelect} 
                onSelectAll={onSelectAll} 
                selectedRows={lockedReportPageState.selectedRows} 
                signatureReportsData={props.LockedReportDocuments.signatureFlowReportTableModel}
                onDocumentDetailsOpen={onDocumentDetailsOpen}
                onPageReload={onPageReload}
                defaultFilter={getDefaultFilter()}
                saveFilterShow={lockedReportPageState.saveFilterShow}
                onSaveFilterShow={onSaveFilterShow}
                onSaveFilterHide={onSaveFilterHide}
                onFilterSave={onFilterSave}
                onFilterNameChange={onFilterNameChange}
                onSetDefaultFilter={onSetDefaultFilter}
                onRemoveDefaultFilter={onRemoveDefaultFilter}
                filterList={props.signatureFlowReportFilter.lockedReportFilter}
                currentFilter={lockedReportPageState.filter}
                onFilterUpdate={onFilterUpdate}
                onFilterDelete={onFilterDelete}
                loadGrid={loadSignatureDraftReports}
                showBulkSelectionMessage={lockedReportPageState.showBulkSelectionMessage}
                onBulkSelectionChange={onBulkSelectionChange}
                onUnlockSelectedDouments={onUnlockDocumentsOpen}
                />
                <br />
                    <div style={{height:'35px'}}>
                        <Pagination
                            totalCount={lockedReportPageState.totalRecordsCount}
                            pageIndex={lockedReportPageState.page}
                            pageSize={lockedReportPageState.pageSize}
                            defaultPageSize={pageSize}
                            goToPage = {onPageChange}
                            sizePerPageList={SizePerPageList}
                            showSizePerPageList={true}
                            showGoToPages={true}
                            showpageCount={false}
                            ShowListAndGoto={true}
                        />
                    </div>
            </div>

            <SignatureFlowReportDocumentModal
                    show={lockedReportPageState.documentDetailsState.show}
                    signatureReport={lockedReportPageState.documentDetailsState.model as ISignatureFlowReportDocument}
                    onCancel={onDocumentDetailsCancel}
                />
        </div>)
}