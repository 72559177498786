import * as React from 'react';
import Select from 'react-select';
import { Modal, Button, FormControl, Col, Row } from 'react-bootstrap';
import { IAuthenticationQuestionDictionary } from '../../store/settings/AuthenticationQuestions/AuthenticationQuestionsStore';
import { IClient } from '../../models/SigningInfo';
import { IAuthenticationQuestion, initialQuestion, QuestionType } from '../../models/SignatureFlowSettings';
import { VenusNotifier } from '../helper/VenusNotifier';
import { DropdownArrow } from '../svg/CustomSvgIcons';


export interface IQuestionAnswerProps {
    showState: boolean;
    questions: IAuthenticationQuestionDictionary;
    onHide: () => void;
    allowUsersToAdd: boolean;
    recipient: IClient;
    onSave: (recipient: IClient) => void;
    getAuthenticationAnswer: (recipient: IClient, isUpdateRecipient: boolean) => void;
}

export interface IQuestionAnswerState {
    answer: string;
    questions: IAuthenticationQuestion[]
    selectedQuestion: IAuthenticationQuestion;
}


export class QuestionAnswerPopup extends React.Component<IQuestionAnswerProps, IQuestionAnswerState> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedQuestion: initialQuestion,
            answer: '',
            questions: []
        }
    }
    

    UNSAFE_componentWillReceiveProps(nextProps: IQuestionAnswerProps) {
        
        let selectedQuestion = initialQuestion;
        let isCustomQuestion = true;
        let questions : IAuthenticationQuestion[] =[];
        if (nextProps.questions) {
            for (const key in nextProps.questions) {
                if (nextProps.questions.hasOwnProperty(key)) {
                     if (nextProps.questions[key].authenticationQuestion.text === nextProps.recipient.question) {
                         selectedQuestion = nextProps.questions[key].authenticationQuestion;
                         isCustomQuestion = false;
                     }
                    questions.push(nextProps.questions[key].authenticationQuestion);
                }
            }
        }
        if (this.props.allowUsersToAdd) {
            let question: IAuthenticationQuestion = {
                id: questions.length + 1,
                title: 'Custom',
                text: (isCustomQuestion && nextProps.recipient.question) ? nextProps.recipient.question :'',
                questionType: QuestionType.Custom
            }
            questions.push(question);
            if (isCustomQuestion) {
                selectedQuestion = question;
            }
        }
        let answer = nextProps.recipient.answer ? nextProps.recipient.answer : '';
        this.setState({ selectedQuestion: selectedQuestion, questions: questions, answer: answer });
    }


    private onCancelButtonClick = () => {
        this.clearState();
        this.props.onHide()
    }

    onAddButtonClick = () => {
        if (this.state.selectedQuestion.id === 0) {
            VenusNotifier.Warning("Please select a question", "Warning");
            return false;
        }
        if (this.state.selectedQuestion.text === '') {
            VenusNotifier.Warning("Please enter a text", "Warning");
            return false;
        }
        if (this.state.answer.trim() == '') {
            VenusNotifier.Warning("Please enter an answer", "Warning");
            return false;
        }
        let updatedRecipient = this.props.recipient;
        updatedRecipient.question = this.state.selectedQuestion.text;
        updatedRecipient.answer = this.state.answer;
        this.clearState();
        this.props.onSave(updatedRecipient);
    }

    clearState = () => {
        let selectedQuestion: IAuthenticationQuestion = {
            id: 0,
            questionType: QuestionType.None,
            text: '',
            title: ''
        }
        this.setState({ selectedQuestion: selectedQuestion, answer : '' });
    }

    getQuestionOptions = (): { value: string, label: string }[] => {
        let options: { value: string, label: string }[] = [];
        this.state.questions.forEach((question: any, index) => {
            options.push({ value: index.toString(), label: question.title });
        })
       
        return options;
    }

    handleQuestionChange = (event: any) => {
        const questionId = event ? event.value : 0;
        const selectedQuestion = (this.state.questions[questionId] as IAuthenticationQuestion);
        this.setState({
            selectedQuestion: selectedQuestion, answer: ''
        });
        if (selectedQuestion.title !== 'Custom') {
            let recipient = this.props.recipient;
            recipient.answer = '';
            recipient.question = selectedQuestion.text;
            this.props.getAuthenticationAnswer(recipient, false);
        }
    }

    handleAnswerChange = (event: any) => {
        this.setState({ answer : event ? event.target.value :'' });
    }

    isPredefinedQuestion = (): boolean => {
        if (this.props.questions.hasOwnProperty(this.state.selectedQuestion.id) &&
            this.props.questions[this.state.selectedQuestion.id].authenticationQuestion.text === this.state.selectedQuestion.text) {
            return true;
        }
        return false;
    }

    handleQuestionTextChange = (event: any) => {
        this.state.selectedQuestion.text = event.target.value;
        this.setState({ selectedQuestion: this.state.selectedQuestion });
    }

    public render() {
        const questionOptions = this.getQuestionOptions();
        const isPredefinedQuestion = this.isPredefinedQuestion();
        const question = questionOptions.find(m => m.label == this.state.selectedQuestion.title);
    
        return <div>
            <Modal className='qa-popup' centered={true} show={this.props.showState} backdrop="static" onHide={this.onCancelButtonClick}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Q&A Authentication Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="form-group">
                        <Col md={12}>
                            <Select
                                options={questionOptions}
                                onChange={this.handleQuestionChange}
                                value={question ? question.value : 0}
                                clearable={false}
                                placeholder="Select Question"
                                className='font14'                                
                                arrowRenderer={() =><span data-test-auto={"396d00a4-fb70-46e4-9122-f82bf967dab2"}><DropdownArrow/></span>}
                                data-test-auto="97E6D8B4-4167-41C2-9F85-EC613F03AF59"
                            />
                        </Col>
                        <Col md={12}>
                            <FormControl
                                data-test-auto="B0B177B5-E011-4BC5-94D9-28A265EF479A"
                                type="text"
                                placeholder="Answer"
                                disabled={false}
                                className='font14'
                                onChange={this.handleAnswerChange}
                                value={this.state.answer}/>
                        </Col>
                        <Col md={12}>
                            <textarea style={isPredefinedQuestion ? { backgroundColor: '#f1f1f1', resize: "none" } : { backgroundColor: '#fff', resize: "none"}}
                                data-test-auto="0853F052-EE10-4DBE-B461-D7507BF9DF4C"
                                className="form-control font14"
                                placeholder="Please enter question text"
                                rows={6}
                                value={this.state.selectedQuestion.text}
                                onChange={this.handleQuestionTextChange}
                                disabled={isPredefinedQuestion}
                                maxLength={500}
                            />
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        data-test-auto="90A9BBDE-5FC8-405A-A681-90E4E47F5631"
                        variant='default'
                        className='btn-white modal-footer-button-cancel'
                        onClick={this.onCancelButtonClick}>
                        Cancel
                        </Button>
                    <Button
                        variant='info'
                        className=' modal-footer-button-save'
                        onClick={this.onAddButtonClick}
                        data-test-auto="58CCAEDA-F7CC-41AE-AFC1-4688CD67A0A9">
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            </div>
    }
}