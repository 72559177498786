import { createUserManager, createUserManagerWithRedirect } from './oidchelpers'
import store from '../SignatureFlow/store'
import { createForethoughtCookie, deleteCookie } from '../SignatureFlow/components/helper/HelperFunctions'

export var userManager = createUserManager()

export const signinRedirect = () => userManager.signinRedirect()

export const signinRedirectWithCallbackArgs = (args: string) => {
    if (args) {
        const redirectUri = userManager.settings.redirect_uri + "?callback_args=" + encodeURIComponent(args)
        userManager = createUserManagerWithRedirect(redirectUri);
    }
    userManager.signinRedirect()
}

export const signinRedirectCallback = () => userManager.signinRedirectCallback()

export const signoutRedirect = async () => {
    deleteCookie('userAutoLogout');
    createForethoughtCookie("isForethoughtWidgetVisible", "false");
    userManager.signoutRedirect();
}

export const signoutRedirectCallback = () => userManager.signoutRedirectCallback()

export const removeUser = () => userManager.removeUser()

export async function getAccessToken() {
    const user = store.getState().auth.user
    return user?.access_token
}
