import { Action, Reducer } from 'redux';
import { actionTypes } from '../ActionTypes';

export enum StatusType {
    None,
    Error,
    Success,
    Warning,
}

export interface NotificationState {
    message: string,
    type: StatusType,
    statusCode:number
}

export interface NotificationAction {
    type: actionTypes.NOTIFICATION;
    statusMessage: string;
    statusType: StatusType;
    statusCode?:number;
}

type KnownAction = NotificationAction;

const unloadedState: NotificationState = {} as NotificationState;
export const reducer: Reducer<NotificationState> = (state: NotificationState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.NOTIFICATION:
            let notification: NotificationState = { ...state };
            notification.message = action.statusMessage;
            notification.type = action.statusType;
            notification.statusCode = action.statusCode;
            return notification;
    }
    return state || unloadedState;
}
