import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
    ISignatureControl, SignatureControlType, initialSignatureControl
} from '../../models/SignatureData';
import { CheckBoxComponent } from './CheckBoxComponent';
import { InfoIcon, ArrowDownSvg, ArrowUpSvg } from '../svg/CustomSvgIcons';
import { VenusNotifier } from '../helper/VenusNotifier';

interface ISignerTooltipProps {
    control: ISignatureControl;
    showState: boolean;
    onHide: () => void;
    submitButtonClick: (control: ISignatureControl) => void;
    content?: {    
        header: string;
        title: string;
        tooltipContent: string;
    };
}


const SignerTooltipPopup: React.FC<ISignerTooltipProps> = (props) => {
    const [control, setControl] = useState<ISignatureControl>(props.control);
    const [radiosCount, setRadiosCount] = useState<number>(0);

    useEffect(() => {
        setRadiosCount(Number(props.control.tooltip));
    }, [props]);

    useEffect(() => {
        control.type === SignatureControlType.Radio && onInputNumberChange(radiosCount);
    }, [radiosCount]);

    const onCancelButtonClick = () => {
        props.onHide();
        setControl(initialSignatureControl);
    }

    const onSaveButtonClick = () => {
        const { tooltip, type } = control;
        const tooltipNumber = Number(tooltip); 
        if (type && type === SignatureControlType.Radio) {
            if (tooltipNumber >= 2 && tooltipNumber <= 5 && tooltipNumber % 1 === 0) {
                props.submitButtonClick(control);
                setControl(initialSignatureControl);
            } else {
                VenusNotifier.Error("Please select a number between 2 and 5", null);
            }
        } else {
            props.submitButtonClick(control);
            setControl(initialSignatureControl);
        }
    }

    const onToolTipChange = (event: any) => {
        const tempControl = { ...control };
        tempControl.tooltip = event.target.value;
        setControl(tempControl);
    }

    const onRequiredChange = (event: any) => {
        const tempControl = { ...control };
        tempControl.required = event.target.checked;
        setControl(tempControl);
    }

    const onInputNumberChange = (number: any) => {
        const tempControl = { ...control };
        tempControl.tooltip = number;
        setControl(tempControl);
    }

    const incrementRadioCount = () => {
        setRadiosCount((radiosCount) => Math.min(5, radiosCount + 1))
    }

    const decrementRadioCount = () => {
        setRadiosCount((radiosCount) => Math.max(2, radiosCount - 1))
    }

    const getInput = () => {
        if (control.type === SignatureControlType.Radio) {
            return (
                <div className='radio-control-input'>
                    <input
                        data-test-auto="F64CD7BB-408E-4194-ABC4-C2A55B4EF4DA"
                        value={radiosCount}
                        onKeyDown={(e) => e.preventDefault()}
                    />
                    <span className='arrow arrow-up' onClick={incrementRadioCount}><ArrowUpSvg style={{display: 'block', marginTop:'25%'}}/></span>
                    <span className='arrow arrow-down' onClick={decrementRadioCount}><ArrowDownSvg style={{display: 'block'}}/></span>
                </div>
            );
        } else {
            return (
                <textarea
                    data-test-auto="F64CD7BB-408E-4194-ABC4-C2A55B4EF4DA"
                    className="form-control borderRadius3 fontSize14"
                    placeholder="Tooltip"
                    rows={control.tooltip ? (control.tooltip.length > 80 ? 2 : 1) : 1}
                    value={control.tooltip ? control.tooltip : ""}
                    onChange={onToolTipChange}
                    data-lpignore="true"
                />
            );
        }
    }

    useEffect(() => {
        setControl(props.control);
    }, [props.control]);

    return (
        <Modal className='tool-tip-popup' centered={true} show={props.showState} onHide={onCancelButtonClick} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    { props.content && props.content.header ? props.content.header : 'Tool Tip'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <span>{ props.content && props.content.title ? props.content.title : 'Add Text'}</span>
                        { control.type !== SignatureControlType.Radio &&
                            <span title={ props.content && props.content.tooltipContent ? props.content.tooltipContent : "This text will help the signer to enter the necessary details"}>
                                <InfoIcon style={{fill:'#6B7075', marginLeft:'7px'}}/>
                            </span>
                        }
                    </div>
                    <div className="form-group">
                        { getInput() }
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <div className='required-checkbox font14'>
                        <CheckBoxComponent
                            id={"isToolTipRequired"}
                            text={"Required"}
                            checked={control.required}
                            onChange={onRequiredChange}
                        />
                    </div>
                    <Button
                        data-test-auto="7A73152B-7425-4B5A-852F-7126A10F200D"
                        variant='default'
                        className='btn-white modal-footer-button-cancel'
                        onClick={onCancelButtonClick} >
                        Cancel
                    </Button>
                    <Button
                        variant='info'
                        className=' modal-footer-button-save'
                        onClick={onSaveButtonClick}
                        data-test-auto="58D8BFE5-4906-4314-BBF3-C9E173A2A4B2">
                        { control.type === SignatureControlType.Radio ? 'Add' : 'Save' }
                    </Button>
                </Modal.Footer>
            </Modal>
    );
}

export default SignerTooltipPopup;
