import * as React from 'react';
import { ISigner } from '../SignatureControlPanel';
import {
    ISignatureControl, SignatureControlType, SignatureControlRole, SignatureGroupType, ChoosableControlData
} from './../../models/SignatureData';
import { getControlText, getControlIcon } from './../helper/SignatureHelper';
import { GripVertical , RadioButtonSvg} from '../svg/CustomSvgIcons';

export interface SignatureControlProps {
    control: ISignatureControl,
    selectedSigner: ISigner,
    focusedGroup: SignatureGroupType,
    onControlRemove(control: ISignatureControl): void;
    style: React.CSSProperties;
    onToolTipEdit?: (control: ISignatureControl) => void;
}

const SignatureControl: React.FC<SignatureControlProps> = (props) => {

    const getSelectedSignStyle = (): string => {
        if (props.focusedGroup && props.control.role) {
            if (props.focusedGroup == SignatureGroupType.Sender &&
                props.selectedSigner.value == props.control.signer &&
                props.selectedSigner.signatureRole == SignatureControlRole.SFSender) {
                return "sf-sender-signer-sign-highlight";
            }
            if (props.focusedGroup == SignatureGroupType.Receiver &&
                props.selectedSigner.value == props.control.signer &&
                props.selectedSigner.signatureRole == SignatureControlRole.SFReceipient) {
                return "sf-receipient-signer-sign-highlight";
            }
        }
        return "";
    }

    const onToolTipEdit = (event: any) => {
        if (props.onToolTipEdit) {
            props.onToolTipEdit(props.control);
        }
    }

    const getControlActions = (control: ISignatureControl) => {
        switch(control.type) {
            case SignatureControlType.Text:
                return <span onClick={onToolTipEdit} className="textfield-control fa fa-info-circle" style={{ cursor: 'pointer' }}></span>;
            case SignatureControlType.Signature:
                return <>
                        { control.required && <span style={{top: '1px',marginLeft: '1%'}} className="fas fa-asterisk compulsory-star"></span> }
                        <span 
                            onClick={onToolTipEdit} 
                            className={"textfield-control fa fa-info-circle" + (control.required ? " padL05" : "") } 
                            style={{ cursor: 'pointer' }}>
                        </span>
                    </>;
            case SignatureControlType.CheckBox:
                return <span onClick={onToolTipEdit} 
                    className="textfield-control fa fa-info-circle" 
                    style={{ 
                        cursor: 'pointer', 
                        position: "absolute", 
                        right:'-10px',
                        bottom:'-12px',
                        margin:'0px', 
                        float:'right', 
                        lineHeight:'1', 
                        backgroundColor:'white', 
                        borderRadius:'50%' 
                    }}>
                </span>;
            case SignatureControlType.Radio:
                return <span onClick={onToolTipEdit} className="tooltip-edit textfield-control fa fa-info-circle">
                </span>;
            default:
                return "";
        }
    }

    const createChoosableControls = (control: ISignatureControl, items: ChoosableControlData[]) => {
        let choosableControls: JSX.Element[] = [];
        let icon: string = getControlIcon(control.type);

        if(items) {
            items.forEach((item: ChoosableControlData) => {
                choosableControls.push(
                    <div
                        id={item.id}
                        key={"key_" + item.id}
                        data-id={item.id}
                        data-controlguid={control.controlGuid}
                        className={"radio-btn-container choosable-control-item"}
                        title=''
                        style={{
                            top: item.top + 'px',
                            left: item.left + 'px'
                        }}
                    >
                        <span><RadioButtonSvg /></span>
                    </div>
                )
            });
        }
        return choosableControls;
    }

    return (
        <>
        { props.control.type === SignatureControlType.Radio ?
            <div 
                id={'choosable_control_' + props.control.controlGuid} 
                data-id={props.control.controlGuid}
                data-signerstyle={getSelectedSignStyle()}
                className={'choosable-signature-control'}
                style={{
                    height: "100%",
                    width: "100%"
                }}
                title=""
            >
                {createChoosableControls(props.control, props.control.items)}
                {getControlActions(props.control)}
                <div className='draggable-btn-control'><GripVertical /></div>

                <span
                    onClick={() => { props.onControlRemove(props.control) }}
                    aria-disabled={false}
                    className="remove-handle glyphicon glyphicon-remove"
                    style={{ cursor: 'pointer', float: "right", lineHeight: 1, margin: 0, position:'absolute' }}>
                </span>
            </div>
        :
            
            <div className={"draggable-signature " + getSelectedSignStyle()} style={props.style}>
                <span ><GripVertical /></span>
                {getControlText(props.control.type)}
                {getControlActions(props.control)}
                <span
                    onClick={() => { props.onControlRemove(props.control) }}
                    aria-disabled={false}
                    className="remove-handle glyphicon glyphicon-remove"
                    style={{ cursor: 'pointer', float: "right", lineHeight: 1, margin: 0, position:'absolute' }}>
                </span>
            </div>  
        }
        
        </>
    ); 
}

export default SignatureControl;