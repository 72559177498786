import * as React from 'react';
import { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import * as SignatureFlowSettingsStore from '../../../store/SignatureFlowSettingsStore';
import * as SignatureFlowAuthenticationQuestionsStore from '../../../store/settings/AuthenticationQuestions/AuthenticationQuestionsStore';
import * as UsersStore from '../../../store/SFUserStore';
import { SignatureFlowDelegation } from '../SignatureFlowDelegation';
import { DocumentType } from '../DocumentType';
import { IDocumentType } from '../../../models/SignatureFlowSettings';
import { AutomatedReminderSettings } from '../AutomatedReminderSettings';
import { ResourceIdConstants, ScreenIds } from '../../helper/RBACConstants';
import { GreaterThanSvg } from '../../svg/CustomSvgIcons';

type ISignatureFlowSettingsProps =
    {
        signatureFlowSettingsData: SignatureFlowSettingsStore.ISignatureFlowSettingsData,
        userList: UsersStore.ISFUserData,
        authenticationQuestionsData: SignatureFlowAuthenticationQuestionsStore.IAuthenticationQuestionsData
    }
    & typeof SignatureFlowSettingsStore.actionCreators
    & typeof UsersStore.actionCreators
    & typeof SignatureFlowAuthenticationQuestionsStore.actionCreators
    & RouteComponentProps<{}>;

export interface ISignatureFlowSettingsState {
    showDocumentTypePopUp: boolean
}

export const GeneralSettings:React.FC<ISignatureFlowSettingsProps> = (props) => {
    const [generalSettingsState, setGeneralSettingsState] = useState<ISignatureFlowSettingsState>({showDocumentTypePopUp: false})

    React.useEffect(() => {
        props.requestCompanySignatureSettings();
        props.requestAllSFUsers(true);
        props.requestDocumentTypes(`${ScreenIds.Settings}${ResourceIdConstants.GeneralSettings}`);
        props.requestAuthenticationQuestions();
    }, []);

    const saveDocumentType = (id: number, value: string, expiration: number, isActive: boolean) => {
        let documentType: IDocumentType = {
            id: id,
            description: value,
            expiration: expiration,
            isActive: isActive
        }
        props.saveDocumentType(documentType, () => {
            setGeneralSettingsState({ 
                ...generalSettingsState,
                showDocumentTypePopUp: false });
        });
    }

    const updateDocumentType = (id: number, value: string, expiration: number, isActive: boolean) => {
        let documentType: IDocumentType = {
            id: id,
            description: value,
            expiration: expiration,
            isActive: isActive
        }
        props.updateDocumentType(documentType);
    }

    return <div className='font14 settingsPage'>
             <div className="row" style={{ marginLeft: 0, marginBottom:'20px' }}>
                <span className='fontWeight700 text-primary'>Settings</span> 
                <span className='padL08'><GreaterThanSvg /></span>
                <span className='padL08'>General</span>
            </div>
            <div className="row" style={{ marginLeft: 0, marginBottom:'25px' }}>
                    <div className="col-sm-10 padL00 float-left">
                        <h2 style={{fontSize: '20px'}}  className='fontWeight700'>General</h2>
                    </div>
                </div>

            <AutomatedReminderSettings
                resourceId={`${ScreenIds.Settings}${ResourceIdConstants.GeneralSettings}`}
                signatureFlowSettings={props.signatureFlowSettingsData.signatureFlowSettings}
                updateCompanySettings={props.updateCompanySettings}
            />    

            <hr />

            <DocumentType
                resourceId={`${ScreenIds.Settings}${ResourceIdConstants.GeneralSettings}`}
                documentTypes={props.signatureFlowSettingsData.documentTypes ? props.signatureFlowSettingsData.documentTypes : []}
                defaultMessage={props.signatureFlowSettingsData.signatureFlowSettings.messageSettings ? props.signatureFlowSettingsData.signatureFlowSettings.messageSettings.welcomeMessage : 0}
                onSaveDocumentType={saveDocumentType}
                onUpdateDocumentType={updateDocumentType}
                showDocumentTypePopUp={generalSettingsState.showDocumentTypePopUp}
            />

            <hr/>

            <SignatureFlowDelegation
                resourceId={`${ScreenIds.Settings}${ResourceIdConstants.GeneralSettings}`}
                signatureFlowSettings={props.signatureFlowSettingsData.signatureFlowSettings}
                userList={props.userList.users}
                updateCompanySettings={props.updateCompanySettings}
            />
        </div>
}
