import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/types';
import * as SignatureFlowSettingsStore from '../../../store/SignatureFlowSettingsStore';
import * as SignatureFlowSavedMessageStore from '../../../store/settings/SavedMessage/SavedMessageStore';
import {SavedMessage} from './SavedMessage';

export default connect(
    (state: ApplicationState) => ({
        signatureFlowSettingsData: state.signatureFlowSettingsData,
        SavedMessageData: state.SavedMessageData,
    }),
    {
        ...SignatureFlowSettingsStore.actionCreators,
        ...SignatureFlowSavedMessageStore.actionCreators
    }
)(SavedMessage as any);