import * as React from 'react';
import { Overlay, Popover, Button } from 'react-bootstrap';
import { BackArrow, ForwardArrow } from '../svg/CustomSvgIcons';

export interface ISFProps {
    steps: Array<any>;
    stepsInfo: any;
    onCancel: () => void;
    onSaveAndClose: () => void;
    onPrevious: (stepNo: number) => void;
    onNext: (stepNo: number) => void;
    goTo: (currentStep: number, destinationStep: number) => void;
    currentStep: number;
    onFinish: () => void;
    isCustomScrollEnabled?: boolean;
    isLastStepValid?: boolean;
    showClosePopover?: boolean;
    setShowClosePopover?: (show: boolean) => void;
    closePopoverMessage?: string;
    onClosePopoverYesClick?: () => void;
    isOutForSignature: boolean;
    isSaveInProgress:boolean;
}

export class SignatureFlow extends React.Component<{} & ISFProps, any>{
    private closePopoverRef: any;

    private createSteps = () => {
        let _self = this;
        let listItemWidth: string = (this.props.steps && this.props.steps.length > 0 ? (100 / this.props.steps.length) : 100) + '%';
        var listItems = this.props.steps.map(function (item: any, index: number) {
            let temp: any = item.id < _self.props.currentStep ? "sfstep-completed" : "" + ' ' + (item.id == _self.props.currentStep ? "sfstep-active" : "");
            return (
                <li key={index} className={temp} onClick={() => { _self.handleStepChange(item) }} style={{ width: listItemWidth }}>
                    {item.text}
                </li>
            )
        });
        return listItems;
    }

    handleStepChange = (e: any) => {
        this.props.goTo(this.props.currentStep, e.id);
    }
    onSFCancel = () => {
        if(this.props.isSaveInProgress || this.props.isOutForSignature) return;
        this.props.onCancel();
    }
    onPrevious = () => {
        this.props.onPrevious(this.props.currentStep);
    }
    onNext = () => {
        this.props.onNext(this.props.currentStep);
    }
    onFinishSendForSignature = () => {
        this.props.onFinish();
    }

    handleClosePopoverYesBtnClick = (e: any) => {
        if (this.props.onClosePopoverYesClick) {
            this.props.onClosePopoverYesClick();
        }
    }


    public render() {

        const popover = (
            <Popover id="popover-basic" title="Confirmation">
                <div dangerouslySetInnerHTML={{ __html: this.props.closePopoverMessage ? this.props.closePopoverMessage : '' }}></div>
                <div className="popover-footer">
                    <button
                        className="btn btn-only btn-success"
                        onClick={this.handleClosePopoverYesBtnClick}
                        title="Yes"
                        style={{ marginLeft: "5px" }}>
                        Yes
                </button>
                    <button
                        onClick={() => { this.props.setShowClosePopover && this.props.setShowClosePopover(false); }}
                        title="No"
                        className="btn btn-only btn-danger"
                        style={{ marginLeft: "5px" }}>
                        No
                </button>
                </div>
            </Popover >
        );

        return (<div className="container" style={{ maxWidth: "100%", paddingLeft: "0px", paddingRight: "0px" }}>
            <div className="card" style={{
                borderColor: "transparent", backgroundColor: "transparent", position: "relative", marginBottom: "0px"
            }}>
                <div className="card-header sf-panel-header" style={{ backgroundColor: "white", position: "sticky", top: 0, width: "100%" }}>
                    <div className="sf-container">
                        <ul className="sf-progressbar">
                            {this.createSteps()}
                        </ul>
                    </div>
                </div>
                <div className={"card-body " + (this.props.isCustomScrollEnabled ? "custom-sfPanel-body" : "sfPanel-body")}>
                    <div style={{ height: "100%" }}>
                        {
                            this.props.stepsInfo()
                        }
                    </div>
                </div>
                <div id="signature" className="card-footer" style={{
                    backgroundColor: "white", textAlign: "right", margin: "-1px"
                }}>
                    {
                        this.props.currentStep != 1 ?
                            <Button variant='default' className="footer-back-btn width122" disabled={this.props.isSaveInProgress || this.props.isOutForSignature}
                            onClick={() => { this.onPrevious() }}>
                                <span className='padR05'><BackArrow marginBottom={'2px'} fill='#0973BA'/></span>
                                Back
                            </Button> : ""
                            
                    }
                    { this.props.currentStep !=1 ?
                       <Button disabled={this.props.isSaveInProgress || this.props.isOutForSignature}
                        variant='link' 
                        className='footer-exit-without-saving-btn' 
                        onClick={this.onSFCancel} >
                            Exit without Saving
                       </Button>     
                     : <Button variant='default' className="footer-cancel-btn width122"  onClick={this.onSFCancel}>
                         Cancel
                        </Button> 
                    }

                    { this.props.currentStep !=1 ?
                       <Button disabled={this.props.isSaveInProgress || this.props.isOutForSignature} variant='default' className="footer-save-and-close-btn width122" onClick={() => { this.props.onSaveAndClose() }}>
                       Save & Close
                        </Button> : "" 
                    }
                   
                    
                    {
                        this.props.currentStep == this.props.steps.length ?
                            <Button disabled={(this.props.isLastStepValid == undefined ? false : this.props.isLastStepValid)
                                || this.props.isOutForSignature || this.props.isSaveInProgress} variant="success"
                                className="finish width122" onClick={this.onFinishSendForSignature} ref={(reference: any) => { this.closePopoverRef = reference }}>
                                    Submit
                                </Button> :
                            <Button disabled={this.props.isSaveInProgress} variant="info" className='footer-next-btn width122' onClick={() => { this.onNext() }}>
                                Next
                                <span className='padL05'><ForwardArrow marginBottom={'2px'} marginRight={'3px'} fill='#FFFFFF'/></span>
                            </Button>
                    }

                    <Overlay rootClose={true} placement="top"
                        onHide={() => { this.props.setShowClosePopover && this.props.setShowClosePopover(false) }}
                        target={this.closePopoverRef} show={this.props.showClosePopover}>
                        {popover}
                    </Overlay>
                </div>
            </div>
        </div>
        )
    }
}