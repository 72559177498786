import * as React from 'react';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Toaster } from '../SignatureFlow/components/helper/VenusNotifier';
import { FeatureDisabled } from '../SignatureFlow/components/navigation/FeatureDisabled';
import AppLayoutContainer  from '../SignatureFlow/components/navigation/AppLayout/AppLayoutContainer';
import SendForSignature from '../SignatureFlow/components/SendForSignatureContainer';
import SignatureFlowReportContainer from '../SignatureFlow/components/Report/SignatureFlowReportContainer';
import SignatureArchiveReportsContainer from '../SignatureFlow/components/Report/ArchiveReports/SignatureArchiveReportsContainer';
import SignatureRecycleReportsContainer from '../SignatureFlow/components/Report/RecycledReports/SignatureRecycleReportsContainer';
import GeneralSettingsContainer from '../SignatureFlow/components/settings/General Settings/GeneralSettingsContainer';
import SignatureSavedMessageContainer from '../SignatureFlow/components/settings/saved Message/SavedMessageContainer';
import SignatureClientInstructionsContainer from '../SignatureFlow/components/settings/ClientInstructions/ClientInstructionsContainer';
import SecuritySettingsContainer from '../SignatureFlow/components/settings/Security Settings/SecuritySettingsContainer';
import RBACRoute from '../SignatureFlow/routing/RBACRoute';

import LoggedIn from "./LoggedIn";
import LoggedOut from "./LoggedOut";
import SigninOidc from "../oidcClient/SigninOidc";
import SignoutOidc from "../oidcClient/SignoutOidc";
import Login from "../SignatureFlow/components/Login";
import PreLogin from "../SignatureFlow/components/PreLogin";
import SilentSigninOidc from "../oidcClient/silentSigninOidc";
import SignatureDraftReportContainer from 'src/SignatureFlow/components/Report/DraftReport/SignatureDraftReportContainer';
import SignatureLockedReportContainer from 'src/SignatureFlow/components/Report/LockedReports/SignatureLockedReportContainer';

export const Routes = () => {
return <>
    <LoggedIn>
            <AppLayoutContainer>
                <Switch>
                    <Route exact path='/FeatureDisabled' component={FeatureDisabled} />                

                    <RBACRoute path='/SendForSignature' component={SendForSignature} /> 

                    <RBACRoute path='/SignatureDraftReport' component={SignatureDraftReportContainer} />

                    <RBACRoute path='/SignatureFlowReport' component={SignatureFlowReportContainer} />                

                    <RBACRoute path='/SignatureArchivedReports' component={SignatureArchiveReportsContainer} />

                    <RBACRoute path='/SignatureLockedReport' component={SignatureLockedReportContainer} />

                    <RBACRoute path='/SignatureFlowSettings' component={GeneralSettingsContainer} />

                    <RBACRoute path='/SignatureClientInstruction' component={SignatureClientInstructionsContainer} />
                    
                    <RBACRoute path='/SavedMessages' component={SignatureSavedMessageContainer} />
                    
                    <RBACRoute path='/Security' component={SecuritySettingsContainer} />

                    <RBACRoute path='/SignatureRecycleBin' component={SignatureRecycleReportsContainer} />
                </Switch>
            </AppLayoutContainer>
            <Toaster />
    </LoggedIn>

    <LoggedOut>
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/signin-oidc" component={SigninOidc} />
                <Route path="/silent-signin-oidc" component={SilentSigninOidc} />
                <Route path="/signout-callback-oidc" component={SignoutOidc} />
                <Route path="*" component={PreLogin} />
            </Switch>
    </LoggedOut>
 </>
}
