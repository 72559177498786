import * as React from 'react';
import * as Bs from 'react-bootstrap';
import { Modal, Alert } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { ISignatureFlowReportDocument, SignatureFlowReportSignatureStatus } from '../../../models/SignatureFlowReportState';
import { OverlayLoader } from '../../../components/helper/OverlayLoader';
import { SignatureFlowReportConstants } from '../../helper/Constants';
import { VenusNotifier } from '../../../components/helper/VenusNotifier';

interface ICancelDocumentProps {
    show: boolean;
    showLoader: boolean;
    onSaveCancelDocumentDescription?: (id: number, message: string, callBack: () => void, sendNotification: boolean) => void;
    selectedDocument: ISignatureFlowReportDocument;
    onCancel: () => void;

}
interface ICancelDocumentState {
    saving: boolean;
    description: string;
    char_Left: number;
    btnType: string;
    hideAlert: boolean;
    sendNotificaton: boolean;
}

const MAX_CHAR = 200;

export class SignatureFlowReportCancelDocument extends React.Component<ICancelDocumentProps, ICancelDocumentState> {
    constructor(props: ICancelDocumentProps) {
        super(props);
        this.state = {
            description: "",
            saving: false,
            char_Left: MAX_CHAR,
            btnType: "",
            hideAlert: true,
            sendNotificaton: false
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: ICancelDocumentProps) {
        if (nextProps.selectedDocument && nextProps.selectedDocument.cancelledDocumentDescription && !this.state.saving) {
            this.setState({
                description: nextProps.selectedDocument.cancelledDocumentDescription,
                char_Left: MAX_CHAR - nextProps.selectedDocument.cancelledDocumentDescription.length,
                btnType: "Update",
                sendNotificaton: false
            })
        }
        else {
            this.setState({ btnType: "Cancel Document", sendNotificaton: true, char_Left: MAX_CHAR })
        }
    }

    private onChange(event: any) {
        let input = event.target.value;
        if (MAX_CHAR - input.length >= 0) {
            this.setState({
                description: event.target.value,
                char_Left: MAX_CHAR - input.length,
                hideAlert: true,
            });
        }
        else {
            this.setState({ hideAlert: false });
        }
    }

    private onSubmit() {
        if (this.state.description.trim().length === 0) {
            return VenusNotifier.Warning(SignatureFlowReportConstants.CancelDocument.EmptyDescriptionWarningMsg, null);
        }
        let id: number = 0;
        if (this.props.selectedDocument) {
            id = this.props.selectedDocument.signingId;
        }
        this.props.onSaveCancelDocumentDescription && this.props.onSaveCancelDocumentDescription(id, this.state.description, this.callBack, this.state.sendNotificaton);
        this.setState({ saving: true, description: "" , hideAlert: true});
    }

    private callBack = () => {
        this.setState({ saving: false });
        this.props.onCancel();
    }

    private onCancel() {
        this.setState({
            description: "",
            hideAlert: true
        });
        this.props.onCancel();
    }

    private getTitle = (): string => {
        let title: string;
        switch (this.props.selectedDocument.signatureStatus) {
            case SignatureFlowReportSignatureStatus.Cancelled:
                title = SignatureFlowReportConstants.CancelDocument.CanceledTitle;
                break;
            case SignatureFlowReportSignatureStatus.Declined:
                title = SignatureFlowReportConstants.CancelDocument.DeclinedTitle;
                break;
            default:
                title = SignatureFlowReportConstants.CancelDocument.CancelTitle;
                break;
        }
        return title;
    }

    public render() {
        return <Modal centered={true} show={this.props.show} onHide={this.onCancel} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{this.props.selectedDocument && this.getTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.props.showLoader ?
                    <OverlayLoader
                        show={this.props.show}
                        text={"Loading, please wait..."} />
                    :
                    <LoadingOverlay style={{ minHeight: 100 }}>
                        <div>
                            <div><Alert hidden={this.state.hideAlert} variant="warning" >
                                <i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}>
                                </i>Maximum characters reached</Alert>
                            </div>
                            <div style={{ textAlign: "justify",fontSize: "13px" }}>
                                {this.props.selectedDocument && this.props.selectedDocument.signatureStatus != SignatureFlowReportSignatureStatus.Cancelled &&
                                    this.props.selectedDocument.signatureStatus != SignatureFlowReportSignatureStatus.Declined ?
                                    SignatureFlowReportConstants.CancelDocument.Description : ''}
                            </div>
                            <div style={{ padding: "10px 10px 10px 10px" }}>
                                <div style={{ display: "inline" }}>
                                    <span className="fas fa-asterisk compulsory-star-only "></span>
                                    <span><b> {this.props.selectedDocument && this.props.selectedDocument.signatureStatus != SignatureFlowReportSignatureStatus.Declined ?
                                        SignatureFlowReportConstants.CancelDocument.CanceledReasonTilte : SignatureFlowReportConstants.CancelDocument.DeclinedReasonTitle} </b></span>
                                </div>
                                <textarea value={this.state.description} onChange={this.onChange} style={{ resize: "none" }}
                                    className="form-control cancelDocumentTextArea"
                                    disabled={this.props.selectedDocument && this.props.selectedDocument.signatureStatus == SignatureFlowReportSignatureStatus.Declined ? true : false}
                                    placeholder={SignatureFlowReportConstants.CancelDocument.EmptyDescriptionWarningMsg}
                                />
                                {this.props.selectedDocument && this.props.selectedDocument.signatureStatus != SignatureFlowReportSignatureStatus.Declined ?
                                    this.state.char_Left + " characters remaining." : ''}
                            </div>
                        </div>
                        <Loader loading={this.state.saving} text="Saving your changes. Please wait..." />
                    </LoadingOverlay>
                }
            </Modal.Body>
            <Modal.Footer>
                {this.props.selectedDocument && this.props.selectedDocument.signatureStatus != SignatureFlowReportSignatureStatus.Declined ?
                    this.state.btnType == 'Cancel Document' ?
                        <Bs.Button
                            variant='danger'
                            className='btn-danger'
                            onClick={this.onSubmit} >
                                {this.state.btnType}
                        </Bs.Button>
                        :
                        <Bs.Button
                            variant='info'
                            className=' modal-footer-button-save'
                            onClick={this.onSubmit} >
                                {this.state.btnType}
                        </Bs.Button>
                    :
                    <Bs.Button
                        variant="default"
                        className='btn-white modal-footer-button-cancel'
                        onClick={this.onCancel}>
                        Cancel
                </Bs.Button>
                }
            </Modal.Footer>
        </Modal>
    }
}