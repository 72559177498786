import * as React from 'react';
import * as bootbox from 'bootbox';
import { RouteComponentProps } from 'react-router';
import { SortDirections, ReportFilterType } from '../common/Filters';
import {
    ISignatureFlowReportFilter, SignatureFlowReportSignatureStatus,
    IMailData, ISignerModel, ISignatureFlowReportDocument, IDocumentAccess, IDownloadableDocuments,
    ISignatureFlowReportsDocumentInfo,
    ClientTypes
} from '../../models/SignatureFlowReportState';
import { SignatureFlowReportTable } from './SignatureFlowReportTable';
import * as UserStore from '../../store/SFUserStore';
import * as DeliveredReportStore from '../../store/ReportsStore/DeliveredReport/DeliveredReportsStore';
import * as UserProfileStore from '../../store/userManagement/UserProfileStore';
import * as UserSettingsStore from '../../store/userManagement/UserSettingStore';
import {  IUserProfile } from '../../components/navigation/profile/ProfileObjects';
import * as SignatureFlowReportState from '../../models/SignatureFlowReportTableState';
import * as SignatureFlowReportCustomColumnStore from '../../store/ReportsCommon/SignatureFlowReportCustomColumnStore';
import * as SignatureFlowReportFilterStore from '../../store/ReportsCommon/SignatureFlowReportFilterStore';
import * as SignatureFlowReportPopupModelStore from '../../store/ReportsCommon/SignatureFlowReportPopupModelStore';
import * as SignatureFlowSettingsStore from '../../store/SignatureFlowSettingsStore';
import * as SignatureFlowAuthenticationQuestionsStore from '../../store/settings/AuthenticationQuestions/AuthenticationQuestionsStore';
import { VenusNotifier } from '../../components/helper/VenusNotifier';
import * as Helper from '../../components/helper/HelperFunctions';
import { SignatureFlowReportsHeader } from './SignatureFlowReportHeader';
import { INameValue } from '../../components/helper/Helper';
import { SignatureFlowESignDetailsModal } from './ActionButtonModals/SignatureFlowESignDetailsModal';
import { SignatureFlowReportDocumentModal } from './ActionButtonModals/SignatureFlowReportDocumentModal';
import { SignatureFlowReportSetAccessModal } from './ActionButtonModals/SignatureFlowReportSetAccessModal';
import { SignatureFlowReportDocumentNoteModal } from './ActionButtonModals/SignatureFlowReportDocumentNoteModal';
import { SignatureFlowReportCancelDocument } from './ActionButtonModals/SignatureFlowReportCancelDocumentModal';
import { ResendAccessLinkModal } from './ActionButtonModals/SignatureFlowReportResendAccessLinkModal';
import { SignatureFlowReportAccessCodeModal } from './ActionButtonModals/SignatureFlowReportAccessCodeModal';
import { SignatureFlowReportClientTrackingModal } from './ActionButtonModals/SignatureFlowReportClientTrackingModal';
import { SignatureFlowReportDownloadDocuments } from './ActionButtonModals/SignatureFlowReportDownloadDocumentsModal';
import { SignedDocumentDownloadHistory } from './ActionButtonModals/SignatureFlowReportSignedDocumentDownloadHistory';
import { SignatureFlowReportRecipientModal } from './ActionButtonModals/SignatureFlowReportRecipientDetailsModal';
import { ReportProblemModal } from '../common/ReportProblemModal';
import * as CompanyStore from '../../store/Company/CompanyStore';
import * as SignatureStore from '../../store/SignatureStore';
import * as ContactStore from '../../store/ContactsStore';
import * as SFStore from '../../store/SFStore';
import * as SigningInfoSettingsStore from '../../store/SigningInfoSettingsStore';
import { ReportProblemTypes, IReportProblemDetails } from '../common/ReportProblemModel';
import { SignatureFlowReportFilterConstants, Common, SignatureFlowReportConstants, DeleteSignatureConstants, SizePerPageList } from '../helper/Constants';
import { ShowLoader, HideLoader } from '../../components/helper/Loader';
import { logger } from '../../../routes/LoggedIn';
import * as BulkOperationsStore from '../../store/SFBulkOperationsStore';
import { SFBulkOperationQuery } from '../../models/SFBulkOperationQuery';
import { ISigningInfoDictionary } from '../../store/SignatureStore';
import { ISendForSignatureData } from '../../store/SFStore';
import { EditClientInfoModal } from './ActionButtonModals/EditClientInfoModal';
import { IClient, initialLocationMismatchModalState, initialSigningInfo, ISigningInfo } from '../../models/SigningInfo';
import { IContactsState } from '../../store/ContactsStore';
import { getAdjustedReportsPageNumber, getCurrentTaxYear } from '../../components/helper/HelperFunctions';
import { SendReminderModal } from './ActionButtonModals/SendReminderModal';
import { initialReminderSettings } from '../../models/SigningInfoSettings';
import { ISigningInfoSettingDictionary } from '../../store/SigningInfoSettingsStore';
import { IReminderSettings } from '../../models/SignatureFlowSettings';
import { ResourceIdConstants, ScreenIds } from '../helper/RBACConstants';
import { CommonPaths, SignatureReportPaths } from '../helper/UrlPaths';
import Pagination from '../common/Pagination';
import { DefaultDownloadInfoType } from '../../models/user/UserSettingsModels';
import { DownloadTypeOption } from './ActionButtonModals/BulkDownloadOptions';
import * as OfficeLocationStore from '../../store/MultiOffice/OfficeLocationStore';
import { ChangeOfficeLocationModal } from './ActionButtonModals/ChangeOfficeLocationModal';
import LocationMismatchModal from '../LocationMismatch/LocationMismatchModal';
import { addClientInfo, CompareClientInfo, editClientInfo, getClientInfoByClientId } from 'src/SignatureFlow/store/ClientManagementAPI';
import { ClientInfoComparisionCode, IClientInfoComparisonResultViewModel, IClientInfoViewModel, initialClientInfoComparisonResultViewModel, initialClientInfoMismatchModalState, initialClientInfoViewModel, setNewClientInfoComparisonResult } from 'src/SignatureFlow/models/ClientManagement';
import { isEmpty, trim } from 'lodash';
import ClientInfoMismatchModal from '../ClientManagement/ClientInfoMismatchModal';
var htmlencode = require('htmlencode');


// At runtime, Redux will merge together...
type SignatureFlowReportProps =
    {
        DeliveredReportDocuments: SignatureFlowReportState.ISignatureFlowReportState,     // ... state we've requested from the Redux store
        userStore: UserStore.ISFUserData,
        signatureFlowReportDetails: SignatureFlowReportPopupModelStore.ISignatureFlowReportPopupDictionary,
        signatureFlowReportFilter: SignatureFlowReportFilterStore.IFilterState,
        reportCustomColumns: SignatureFlowReportCustomColumnStore.ISignatureFlowReportCustomColumnState,
        profile: IUserProfile,
        settings : UserSettingsStore.UserSettings,
        companyData: CompanyStore.ICompanyData,
        signatureFlowSettingsData: SignatureFlowSettingsStore.ISignatureFlowSettingsData,
        pageTitle: string,
        signingInfoData: ISigningInfoDictionary,
        contactsStore: IContactsState,
        sfStore: ISendForSignatureData,
        userOfficeLocationState: OfficeLocationStore.IUserOfficeLocationState,
        officeLocationState: OfficeLocationStore.IOfficeLocationState,
        signingInfoSettingsData: ISigningInfoSettingDictionary,
        authenticationQuestionsData: SignatureFlowAuthenticationQuestionsStore.IAuthenticationQuestionsData
    }
    & typeof DeliveredReportStore.actionCreators   // ... plus action creators we've requested
    & typeof SignatureFlowReportCustomColumnStore.actionCreators
    & typeof UserStore.actionCreators
    & typeof SignatureFlowReportFilterStore.actionCreators
    & typeof SignatureFlowReportPopupModelStore.actionCreators
    & typeof UserProfileStore.actionCreators
    & typeof UserSettingsStore.actionCreators
    & typeof SignatureFlowSettingsStore.actionCreators
    & typeof BulkOperationsStore.actionCreators
    & typeof SignatureStore.actionCreators
    & typeof SFStore.actionCreators
    & typeof ContactStore.actionCreators
    & typeof SigningInfoSettingsStore.actionCreators
    & typeof SignatureFlowAuthenticationQuestionsStore.actionCreators
    & typeof OfficeLocationStore.actionCreators
    & RouteComponentProps<{ page: string }>;        // ... plus incoming routing parameters

let moment = require('moment');
const pageSize: number = 10;
const NO_INDEX = -1;

const warningMsg: INameValue = {
    cancelDocumentConfirmation: 'Are you sure you want to cancel this document?',
    deletDocumentConfirmation: 'You are about to delete this document. Do you want to proceed?',
}

export class SignatureFlowReport extends React.Component<SignatureFlowReportProps, SignatureFlowReportState.ISignatureFlowReportPageState> {
    constructor(props: SignatureFlowReportProps) {
        super(props);
        this.state = {
            page: 1,
            pageSize: pageSize,
            filter: ({
                name: '',
                searchText: '',
                sort: {
                    column: "",
                    direction: SortDirections.None
                },
                fields: {},
                isDefault: 0,
                filterType: ReportFilterType.None,
                isDefaultFilter: false,
                isMasterFilter: false,
            }),
            selectedRows: [],
            searchString: "",
            sortName: "",
            sortOrder: "desc",
            saveFilterShow: false,
            filterSenderName: "",
            filterDocumentName: "",
            filterDocumentType: "",
            filterDocumentNote: "",
            filterDeliveredOn: undefined,
            filterArchivedOn: undefined,
            filterLastReminderOn:undefined,
            filterSigners: "",
            filterSignatureStatus: "",
            filterExpiration: undefined,
            filterTitle: "",
            filterDownloadStatus: "",
            filterLoggedUser: "",
            filterTaxYear: getCurrentTaxYear(),
            filterRecipientName: "",
            filterClientId: "",
            filterOfficeLocation: "",
            selectedColumnValue: 0,
            documentNoteMessage: "",
            showLoader: false,
            signatureStatusList: [],
            selectedStatus: 0,
            setAccessHeaderTextDisplay: false,
            zipFileDownloadName: '',
            downloadableDocuments: [],
            customColumns:[],
            tempSigninfo : initialSigningInfo,

            //SendRemider states
            sendReminderState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            },

            //Resend Access Link states
            resendAccessLinkState:
            {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Client Tracking states
            clientTrackingState:
            {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Report a Problem states
            reportProblem:
            {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Access Code states
            accessCodeState:
            {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Download Return State
            downloadReturnState:
            {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            },

            //Signer Details State
            signerDetailsState:
            {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Change Office Location State
            changeOfficeLocationState:
            {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            },

            //Document Details State
            documentDetailsState:
            {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Client View State
            clientViewState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Set Access State
            setAccessState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            },

            // Document Note State
            documentNoteState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Delete Action states
            deleteReturnsState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Archive Action states
            archiveReturnsState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            },

            //Cancel Document State
            cancelDocumentState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Download History State
            downloadHistoryState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Recipient Details State
            recipientDetailsState:
            {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Edit ClientInfo State
            editClientInfoState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Download Type Option State 
            DownloadTypeOptionState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            },
            isBulkSelectionEnabled: false,
            showBulkSelectionMessage: false,
            deSelectedRows: [],
            totalRecordsCount:0,

            locationMismatchModalState: initialLocationMismatchModalState,
            clientInfo: initialClientInfoViewModel,
            clientInfoComparisonResult: initialClientInfoComparisonResultViewModel,
            clientInfoMismatchModalState: initialClientInfoMismatchModalState,
            compareClientInfoProgress: false,

            selectedDownloadType: DefaultDownloadInfoType.PromptMeEveryTime,
            defaultDownloadType: DefaultDownloadInfoType.PromptMeEveryTime,
            selectedDefaultDownloadType : false
        };

        this.onPageReload = this.onPageReload.bind(this);

        //Grid Operation Handlers
        this.onRowSelect = this.onRowSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onExportToExcel = this.onExportToExcel.bind(this);

        //filter operation handlers
        this.onFilterChange = this.onFilterChange.bind(this);
        this.applyDefaultFilter = this.applyDefaultFilter.bind(this);
        this.onFilterSave = this.onFilterSave.bind(this);
        this.onFilterUpdate = this.onFilterUpdate.bind(this);
        this.onFilterDelete = this.onFilterDelete.bind(this);
        this.onFilterNameChange = this.onFilterNameChange.bind(this);


        this.onSetAccessOpen = this.onSetAccessOpen.bind(this);
        this.onSetAccessSave = this.onSetAccessSave.bind(this);
        this.onSetAccessCancel = this.onSetAccessCancel.bind(this);

        this.onSendReminder = this.onSendReminder.bind(this);

        this.onResendAccessLinkOpen = this.onResendAccessLinkOpen.bind(this);
        this.onResendAccessLink = this.onResendAccessLink.bind(this);
        this.onResendAccessLinkCancel = this.onResendAccessLinkCancel.bind(this);

        this.onClientTrackingOpen = this.onClientTrackingOpen.bind(this);
        this.onClientTrackingCancel = this.onClientTrackingCancel.bind(this);


        this.onViewAccessCodeOpen = this.onViewAccessCodeOpen.bind(this);
        this.onViewAccessCodeCancel = this.onViewAccessCodeCancel.bind(this);
        this.onGenerateOTP = this.onGenerateOTP.bind(this);

        this.onReportProblemOpen = this.onReportProblemOpen.bind(this);
        this.onReportProblemCancel = this.onReportProblemCancel.bind(this);
        this.onReportProblemSave = this.onReportProblemSave.bind(this);

        this.onSignersDetailOpen = this.onSignersDetailOpen.bind(this);
        this.onSignersDetailCancel = this.onSignersDetailCancel.bind(this);

        this.onDocumentDetailsOpen = this.onDocumentDetailsOpen.bind(this);
        this.onDocumentDetailsCancel = this.onDocumentDetailsCancel.bind(this);

        this.onDocumentNoteOpen = this.onDocumentNoteOpen.bind(this);
        this.onDocumentNoteChange = this.onDocumentNoteChange.bind(this);
        this.onUpdateDocumentNote = this.onUpdateDocumentNote.bind(this);
        this.deleteDocumentNoteMessage = this.deleteDocumentNoteMessage.bind(this);
        this.onDocumentNoteCancel = this.onDocumentNoteCancel.bind(this);

        this.onDownloadReturnOpen = this.onDownloadReturnOpen.bind(this);
        this.onDownloadIndividualOpen = this.onDownloadIndividualOpen.bind(this);
        this.onDownloadReturnCancel = this.onDownloadReturnCancel.bind(this);
        this.onDownloadReturnAll = this.onDownloadReturnAll.bind(this);
        this.onDownloadSubmit = this.onDownloadSubmit.bind(this);
        this.onDownloadIndividualFile = this.onDownloadIndividualFile.bind(this);
        this.onDownloadOptionChange = this.onDownloadOptionChange.bind(this);
        this.onDownloadTypeCancel = this.onDownloadTypeCancel.bind(this);

        this.onArchiveReturnsOpen = this.onArchiveReturnsOpen.bind(this);
        this.onDeleteDocumentsOpen = this.onDeleteDocumentsOpen.bind(this);

        this.onArchiveSelectedReturns = this.onArchiveSelectedReturns.bind(this);
        this.onDeleteSelectedReturns = this.onDeleteSelectedReturns.bind(this);

        this.onCancelDocumentConfirmation = this.onCancelDocumentConfirmation.bind(this);
        this.onCancelDocumentOpen = this.onCancelDocumentOpen.bind(this);
        this.onSaveCancelDocumentDescription = this.onSaveCancelDocumentDescription.bind(this);
        this.onCancelDocumentCancel = this.onCancelDocumentCancel.bind(this);

        this.onDeleteDocumentConfirmation = this.onDeleteDocumentConfirmation.bind(this);

        this.onDownloadHistoryOpen = this.onDownloadHistoryOpen.bind(this);
        this.onDownloadHistoryCancel = this.onDownloadHistoryCancel.bind(this);

        //Support Methods
        this.selectedDocumentIds = this.selectedDocumentIds.bind(this);
        this.selectedDocuments = this.selectedDocuments.bind(this);
        this.getStoreItem = this.getStoreItem.bind(this);
        this.buildQuery = this.buildQuery.bind(this);
        this.buildQueryForExportToExcel = this.buildQueryForExportToExcel.bind(this);

        this.hasAllSignersSigned = this.hasAllSignersSigned.bind(this);
        this.updateSignersEmail = this.updateSignersEmail.bind(this);

        this.onChangeOfficeLocationOpen = this.onChangeOfficeLocationOpen.bind(this);
    }

    UNSAFE_componentWillMount() {
        logger.trackPageView("Signature Report Page");
        if(!this.props.DeliveredReportDocuments.isLoading){
            this.props.requestSignatureDeliveredReportDocuments(this.buildQuery(this.state.page, pageSize), true,
                this.callBack);
        }
        this.props.requestCompanySignatureSettings();
        this.props.requestSignatureFlowReportFilter();
        this.props.requestAllSFUsers(true);
        this.props.requestAuthenticationQuestions();
    }

    UNSAFE_componentWillReceiveProps(nextProps: SignatureFlowReportProps) {
        if (this.state.isBulkSelectionEnabled) {
            let selectedRows: number[] = [];
            nextProps.DeliveredReportDocuments.signatureFlowReportTableModel.documents.forEach((value, index) => {
                this.state.deSelectedRows.find(m => m === value.signingId) === undefined && selectedRows.push(index);
            });    
            this.setState({ selectedRows: selectedRows });
        }
        if(nextProps.DeliveredReportDocuments.totalRowCount !== this.state.totalRecordsCount){
            if(!nextProps.DeliveredReportDocuments.isLoading){
                this.setState({
                    totalRecordsCount: nextProps.DeliveredReportDocuments.totalRowCount,
                })
            }
        }
        if(nextProps.reportCustomColumns.signatureCustomColumn.length > 0){
            this.setState({
                customColumns:nextProps.reportCustomColumns.signatureCustomColumn
            })
        }

        nextProps.settings && nextProps.settings.signatureSettings && nextProps.settings.signatureSettings.defaultSettings &&
        nextProps.settings.signatureSettings.defaultSettings.defaultDownloadInfo && 
        this.setState({ 
            defaultDownloadType: nextProps.settings.signatureSettings.defaultSettings.defaultDownloadInfo.downloadType
        });
    }

    buildQuery(page: number, pageSize: number) {
        return '?pageNo=' + page
            + '&pageSize=' + pageSize
            + '&sortBy=' + this.state.sortName
            + '&sortOrder=' + this.state.sortOrder
            + '&searchString=' + this.state.searchString
            + '&filterSenderName=' + encodeURIComponent(this.state.filterSenderName)
            + '&filterDocumentName=' + encodeURIComponent(this.state.filterDocumentName)
            + '&filterDocumentType=' + this.state.filterDocumentType
            + '&filterDocumentNote=' + encodeURIComponent(this.state.filterDocumentNote)
            + '&filterDownloadStatus=' + this.state.filterDownloadStatus
            + '&filterSignatureStatus=' + (this.state.filterSignatureStatus === "-1" ? "" : this.state.filterSignatureStatus)
            + '&filterDeliveredOn=' + (this.state.filterDeliveredOn || "")
            + '&filterExpiration=' + (this.state.filterExpiration || "")
            + '&filterSigners=' + this.state.filterSigners
            + '&filterTitle=' + encodeURIComponent(this.state.filterTitle)
            + '&filterLoggedUser=' + this.state.filterLoggedUser
            + '&filterTaxYear=' + (this.state.filterTaxYear === "-1" ? "" : this.state.filterTaxYear)
            + '&filterRecipientName=' + encodeURIComponent(this.state.filterRecipientName)
            + '&filterClientId=' + encodeURIComponent(this.state.filterClientId)
            + '&filterLastReminderOn=' + (this.state.filterLastReminderOn || "")
            + '&filterOfficeLocation=' + (this.state.filterOfficeLocation === "-1" ? "" : this.state.filterOfficeLocation)
    }

    buildQueryForExportToExcel(page: number, pageSize: number) {

        return '?pageNo=' + page
            + '&pageSize=' + pageSize
            + '&sortBy=' + this.state.sortName
            + '&sortOrder=' + this.state.sortOrder
            + '&searchString=' + this.state.searchString
            + '&filterSenderName=' + encodeURIComponent(this.state.filterSenderName)
            + '&filterDocumentName=' + encodeURIComponent(this.state.filterDocumentName)
            + '&filterDocumentType=' + this.state.filterDocumentType
            + '&filterDocumentNote=' + encodeURIComponent(this.state.filterDocumentNote)
            + '&filterDownloadStatus=' + this.state.filterDownloadStatus
            + '&filterSignatureStatus=' + (this.state.filterSignatureStatus === "-1" ? "" : this.state.filterSignatureStatus)
            + '&filterDeliveredOn=' + (this.state.filterDeliveredOn || "")
            + '&filterExpiration=' + (this.state.filterExpiration || "")
            + '&filterSigners=' + this.state.filterSigners
            + '&filterTitle=' + encodeURIComponent(this.state.filterTitle)
            + '&filterLoggedUser=' + this.state.filterLoggedUser
            + '&filterTaxYear=' + (this.state.filterTaxYear === "-1" ? "" : this.state.filterTaxYear)
            + '&filterRecipientName=' + encodeURIComponent(this.state.filterRecipientName)
            + '&filterClientId=' + encodeURIComponent(this.state.filterClientId)
            + '&filterLastReminderOn=' + (this.state.filterLastReminderOn || "")
            + '&filterOfficeLocation=' + (this.state.filterOfficeLocation === "-1" ? "" : this.state.filterOfficeLocation)
    }

    loadSignatureFlowReports() {
        let queryString = this.buildQuery(this.state.page, this.state.pageSize);
        this.props.requestSignatureDeliveredReportDocuments(queryString, false);
    }

    fetchSignatureFlowReports(page: number, sizePerPage: number) {
        let queryString = this.buildQuery(page, sizePerPage);
        this.setState({ page: page }, () => { this.props.requestSignatureDeliveredReportDocuments(queryString, false) });
    }

    callBack = () => {
        this.props.requestSignatureArchiveReportCustomColumn();
        this.props.requestSignatureFlowReportCustomColumn();
    }
    //======================Grid Operation Starts here===============================

    private onRowSelect(row: any, isSelected: any, e: any) {
        let newList = [...this.state.selectedRows];
        let deSelectedRows = this.state.deSelectedRows;
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
            if (isSelected) {
                newList.push(row.rowIndex);
                if (this.state.isBulkSelectionEnabled && deSelectedRows && deSelectedRows.length > 0) {
                    index = deSelectedRows.indexOf(row.signingId);
                    deSelectedRows.splice(index, 1);
                }
            } else {
                if(newList && newList.length > 0){
                    var index = newList.indexOf(row.rowIndex);
                    if (index > -1) {
                        newList.splice(index, 1);
                    }
                }
                if (this.state.isBulkSelectionEnabled) {
                    deSelectedRows.push(row.signingId);

                    if (deSelectedRows.length >= this.props.DeliveredReportDocuments.totalRowCount) {
                        deSelectedRows = [];
                        this.setState({deSelectedRows: [], isBulkSelectionEnabled: false});
                    }
                }
            }
            this.state.isBulkSelectionEnabled ?
                this.setState({ deSelectedRows: deSelectedRows, selectedRows: newList }) :
                this.setState({ selectedRows: newList });
        }
        this.forceUpdate();
    }

    private onSelectAll(isSelected: boolean, rows: any[], e: any) {
        let selectedRows: number[] = [];
        let showBulkSelectionMessage = false;

        if (isSelected) {
            rows.forEach((value: any, index: number) => {
                selectedRows.push(value.rowIndex);
            });
            showBulkSelectionMessage = true;
        }
        this.setState({
            selectedRows: selectedRows,
            deSelectedRows: [],
            showBulkSelectionMessage: showBulkSelectionMessage,
            isBulkSelectionEnabled: false
        });
    }

    private onPageChange(page: number, pageSize: number) {
        if (this.state.isBulkSelectionEnabled) {
            this.setState({
                page: page,
                pageSize:pageSize
            }, this.loadSignatureFlowReports);
        }
        else {
            this.setState({
                page: page,
                pageSize:pageSize,
                selectedRows: [],
                showBulkSelectionMessage: false,
            }, this.loadSignatureFlowReports);
        }
    }

    private onSortChange(sortName: string, sortOrder: string, column: number) {
        this.clearSelection();
        let temp: ISignatureFlowReportFilter = { ...this.state.filter };
        temp.sort.column = sortName;
        temp.sort.direction = sortOrder == "asc" ? SortDirections.Ascending : SortDirections.Descending;
        this.setState({
            filter: temp,
            sortName: sortName,
            sortOrder: sortOrder == "asc" ? "asc" : "desc"
        }, () => this.fetchSignatureFlowReports(1, this.state.pageSize));
    }

    private onSearchChange(searchString: string) {
        let temp: ISignatureFlowReportFilter = { ...this.state.filter };
        temp.searchText = searchString;
        this.setState({
            searchString: searchString,
            filter: temp

        }, () => this.fetchSignatureFlowReports(1, this.state.pageSize));
    }

    private onPageReload() {
        let query = this.buildQuery(this.state.page, this.state.pageSize);
        this.props.requestSignatureDeliveredReportDocuments(query, true);
        this.setState({ selectedRows: [], showBulkSelectionMessage: false, isBulkSelectionEnabled: false, deSelectedRows: [] });
    }

    private onBulkSelectionChange = (isEnabled: boolean) => {
        let showBulkSelectionMessage = false;

        this.setState({
            isBulkSelectionEnabled: isEnabled,
            showBulkSelectionMessage: showBulkSelectionMessage
        });
    }

    //======================Grid Operation Ends here=================================
    private selectedDocumentIds() {
        let ids: number[] = [];
        this.state.selectedRows.map((row, i) => {
            let selectedDocument = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[row];
            if (selectedDocument) {
                ids.push(selectedDocument.signingId);
            }
        });
        return ids;
    }

    private selectedDocuments() {
        let docs: any[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[row];
            if (selectedDocument) {
                docs.push(selectedDocument);
            }
        }
        return docs;
    }

    private onPopupOpen(rowIndex: number) {
        let ids = [rowIndex];
        if (rowIndex !== NO_INDEX) {
            return [this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId];
        }
        if (this.state.selectedRows.length == 0) {

            return [];
        }
        return this.selectedDocumentIds();
    }

    private getStoreItem(index: number) {
        let id = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[index].signingId;
        return this.props.signatureFlowReportDetails[id].signatureReport;

    }

    private selectedDocumentsSignersInfo() {
        let sendReminderData: IMailData[] = [];

        this.state.selectedRows.map((row, i) => {
            let selectedDocument = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[row];
            if (selectedDocument) {
                let mailData: IMailData = {
                    signers: [], documents: [], deliveredUser: { email: '', firstName: '', lastName: '', userId: 0 }, title: ''
                };
                let nextSigners = selectedDocument.signerInfo.filter(x => x.isSigned == 0 && 
                    (x.order > 0 || (x.order == 0 && 
                                        (x.signerType == ClientTypes.Sender || x.signerType == ClientTypes.Delegatee) 
                                            && x.clientId != 0))).sort((a: ISignerModel, b: ISignerModel) => {
                    if (a.order < b.order) return -1;
                    else return 1;
                });
                if (nextSigners.length == 0) {
                    return;
                }
                if (selectedDocument.signatureStatus == SignatureFlowReportSignatureStatus.Cancelled ||
                    selectedDocument.signatureStatus == SignatureFlowReportSignatureStatus.Declined ||
                    selectedDocument.signatureStatus == SignatureFlowReportSignatureStatus.ESigned ||
                    selectedDocument.signatureStatus == SignatureFlowReportSignatureStatus.SignatureStampingFailed ||
                    selectedDocument.signatureStatus == SignatureFlowReportSignatureStatus.DeliveryFailed) {
                    return;
                }
                let currentOrder = nextSigners[0].order;
                nextSigners.filter(x=> x.order == currentOrder).forEach((signer)=>{
                    mailData.signers.push(this.checkIfSignerHasDelegatee(signer, selectedDocument));
                });
                selectedDocument.documentInfo.forEach((document, index) => {
                    mailData.documents.push(document);
                });
                mailData.deliveredUser = selectedDocument.deliveredUserDetails;
                mailData.title = selectedDocument.title;
                sendReminderData.push(mailData);
            }
        });
        return sendReminderData;
    }

    private checkIfSignerHasDelegatee = (signer: ISignerModel, document: ISignatureFlowReportDocument): ISignerModel => {
        if (signer.delegateeId && signer.delegateeId > 0) {
            const delegatee = document.signerInfo.filter(x => x.clientId == signer.delegateeId)[0];
            return delegatee;
        }
        return signer;
    }

    private onExportToExcel(onExportToExcelComplete: () => void) {
        let queryString = this.buildQueryForExportToExcel(this.state.page, this.state.pageSize);
        this.props.exportSigantureDeliveredReportsAsExcel(queryString, onExportToExcelComplete);
    }

    private hasAllSignersSigned(selectedReportSigners: ISignerModel[]) {
        let isSigned: boolean = selectedReportSigners.filter(signer => signer.order >= 0 && signer.signerType != ClientTypes.CC).every(signer => signer.isSigned == 1);
        return isSigned;
    }

    //======================Filter Operation Starts here=================================

    private applyDefaultFilter(filter: ISignatureFlowReportFilter) {
        this.setState({ filter: filter },
            () => this.fetchSignatureFlowReports(1, this.state.pageSize))
    }

    private onFilterChange(dataField: any) {
        let newState: any = {
            filterSenderName: "",
            filterDeliveredOn: undefined,
            filterArchivedOn: undefined,
            filterExpiration: undefined,
            filterSigners: "",
            filterDownloadStatus: "",
            filterSignatureStatus: "",
            filterDocumentName: "",
            filterDocumentType: "",
            filterDocumentNote: "",
            filterTitle: "",
            filterLoggedUser: "",
            filterRecipientName: "",
            filterClientId: "",
            filterLastReminderOn:undefined,
            filterOfficeLocation:""          
        } as any;
        let filterStatus: any;
        let filterTaxYear: any;
        var dict: { [columnName: string]: string; } = {};
        for (let field of Object.keys(dataField)) {
            dict[field.valueOf().toString()] = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            switch (field) {
                case 'deliveredBy':
                    newState.filterSenderName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'signatureStatus':
                    let tempField = dataField[field];
                    if (tempField.value !== undefined) {
                        newState.filterSignatureStatus = tempField.value;
                    } else {
                        newState.filterSignatureStatus = tempField;
                    }
                    break;
                case 'officeLocation':
                    let tempLocation = dataField[field];
                    if (tempLocation.value !== undefined) {
                        newState.filterOfficeLocation = tempLocation.value;
                    } else {
                        newState.filterOfficeLocation = tempLocation;
                    }
                    break;
                case 'deliveredOn':
                    newState.filterDeliveredOn = Helper.GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
                    break;
                case 'signers':
                    newState.filterSigners = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'documentNote':
                    newState.filterDocumentNote = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'expiration':
                    newState.filterExpiration = moment(dataField[field].value ? dataField[field].value : dataField[field]).format('MM/DD/YYYY');
                    break;
                case 'downloadStatus':
                    newState.filterDownloadStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'documentName':
                    newState.filterDocumentName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'documentType':
                    newState.filterDocumentType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'title':
                    newState.filterTitle = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'loggedUser':
                    newState.filterLoggedUser = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'taxYear':
                    let tempYear = dataField[field];
                    if (tempYear.value !== undefined) {
                        newState.filterTaxYear = tempYear.value;
                    } else {
                        newState.filterTaxYear = tempYear;
                    }
                    break;
                case 'recipientName':
                    newState.filterRecipientName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'clientId':
                    newState.filterClientId = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'lastReminderOn':
                    newState.filterLastReminderOn = moment(dataField[field].value ? dataField[field].value : dataField[field]).format('MM/DD/YYYY');
            }
        }
        let tempfilter: ISignatureFlowReportFilter = { ...this.state.filter };
        tempfilter.fields = dict;
        if (this.isFilterChanged(newState, filterStatus, filterTaxYear)) {
            this.clearSelection();
            this.setState({ ...newState, filter: tempfilter, page: 1 },
                () => {
                    let signatureFlowReportTable: any = this.refs.SignatureFlowReportTable;
                    if (!signatureFlowReportTable.isAppliedFilter && !this.props.DeliveredReportDocuments.isLoading)
                        this.props.requestSignatureDeliveredReportDocuments(this.buildQuery(1, this.state.pageSize), true)
                })
        }
    }
    private clearSelection = () => {
        this.setState({
            selectedRows: [],
            deSelectedRows: [],
            isBulkSelectionEnabled: false,
            showBulkSelectionMessage: false
        });
    }
    private isFilterChanged(newState: SignatureFlowReportState.ISignatureFlowReportPageState, filterStatus: any, filterTaxYear: any): boolean {
        return (
            newState.filterSenderName !== this.state.filterSenderName ||
            newState.filterSignatureStatus !== this.state.filterSignatureStatus ||
            newState.filterDownloadStatus !== this.state.filterDownloadStatus ||
            newState.filterDeliveredOn !== this.state.filterDeliveredOn ||
            newState.filterExpiration !== this.state.filterExpiration ||
            newState.filterDocumentName !== this.state.filterDocumentName ||
            newState.filterDocumentType !== this.state.filterDocumentType ||
            newState.filterSigners !== this.state.filterSigners ||
            newState.filterDocumentNote != this.state.filterDocumentNote ||
            newState.filterTitle != this.state.filterTitle ||
            newState.filterLoggedUser != this.state.filterLoggedUser ||
            filterTaxYear != this.state.filterTaxYear ||
            newState.filterRecipientName != this.state.filterRecipientName ||
            newState.filterClientId != this.state.filterClientId) ||
            newState.filterLastReminderOn != this.state.filterLastReminderOn ||
            newState.filterOfficeLocation != this.state.filterOfficeLocation;
    }

    onFilterSave(onApplyFilter: (filter: ISignatureFlowReportFilter) => void) {
        if (this.state.filter.name.trim() != '') {
            let filter = this.props.signatureFlowReportFilter.signatureReportFilter.find(x => x.name.toLocaleLowerCase() == this.state.filter.name.toLocaleLowerCase());
            if (filter) {
                VenusNotifier.Warning(SignatureFlowReportFilterConstants.OtherMessage.FilterNameAlreadyExists, null);
            }
            else {
                this.props.saveSignatureFlowReportFilter(this.state.filter.name, this.state.filter, () => {
                    onApplyFilter(this.state.filter);
                    this.onSaveFilterHide();
                });
            }
        }
        else {
            VenusNotifier.Warning(SignatureFlowReportFilterConstants.OtherMessage.PleaseEnterFilterName, null);
        }
    }

    private onFilterNameChange(event: any) {
        let temp: ISignatureFlowReportFilter = { ...this.state.filter };
        temp.name = event.target.value;
        this.setState({
            filter: temp
        });
    }


    clearFilters = () => {
        this.setState({ selectedRows: [] }, this.onPageReload);
        let signatureFlowReportTable: any = this.refs.SignatureFlowReportTable;
        signatureFlowReportTable.refs.signatureReportTable && 
            signatureFlowReportTable.refs.signatureReportTable.toolbar.refs.Filters && 
               signatureFlowReportTable.refs.signatureReportTable.toolbar.refs.Filters.setState({ activeFilter: '', appliedFilters: [] });
    }

    onFilterUpdate(name: string) {
        let filter = this.props.signatureFlowReportFilter.signatureReportFilter.find(x => x.name == name);
        if (!filter) {
            VenusNotifier.Warning(SignatureFlowReportFilterConstants.OtherMessage.FilterDoesNotExists, null);
        }
        else {
            filter.fields = this.state.filter.fields;
            filter.name = name;
            filter.searchText = this.state.filter.searchText;
            filter.sort = this.state.filter.sort;
            filter.isDefault = this.state.filter.isDefault;
            this.props.updateSignatureFlowReportFilter(name, filter, undefined, true);
            this.onSaveFilterHide();
        }
    }

    private onSetDefaultFilter = (name: string) => {
        let oldDefaultFilter = this.props.signatureFlowReportFilter.signatureReportFilter.find(x => x.isDefault == 1);
        let filter: ISignatureFlowReportFilter[] = [];
        if (oldDefaultFilter != null && oldDefaultFilter != undefined && oldDefaultFilter.name != name) {
            oldDefaultFilter.isDefault = 0;
            this.props.updateSignatureFlowReportFilter(oldDefaultFilter.name, oldDefaultFilter, undefined, false);
        }

        filter = this.props.signatureFlowReportFilter.signatureReportFilter.filter(x => x.name == name);
        if (filter) {
            filter[0].isDefault = 1;
            this.props.updateSignatureFlowReportFilter(name, filter[0], undefined, true);

        }
    }

    private onRemoveDefaultFilter = (name: string) => {
        let filter = this.props.signatureFlowReportFilter.signatureReportFilter.find(x => x.name == name);
        if (filter) {
            filter.isDefault = 0;
            this.props.updateSignatureFlowReportFilter(name, filter, undefined, true);
        }
        let signatureFlowReportTable: any = this.refs.SignatureFlowReportTable;
        if(signatureFlowReportTable && signatureFlowReportTable.refs && signatureFlowReportTable.refs.Filters)
        signatureFlowReportTable.refs.Filters.setState({ activeFilter: '', appliedFilters: [] });
        this.onPageReload;

    }

    private onSaveFilterShow = () => {
        this.setState({
            saveFilterShow: true
        });
    }

    private onSaveFilterHide = () => {
        this.setState({
            saveFilterShow: false
        });
    }

    private onFilterDelete(filterName: string) {
        this.props.removeSignatureFlowReportFilter(filterName);
    }

    //======================Filter Operation Ends here=================================


    //======================Signer Details State=======================================

    private onSignersDetailOpen(rowIndex: number = NO_INDEX) {
        this.setState({
            signerDetailsState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });

    }

    private onSignersDetailCancel() {
        this.setState({
            signerDetailsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        })
    }

    //======================Signer Details Ends Here======================================= 


    //======================Document Details State=======================================

    private onDocumentDetailsOpen(rowIndex: number = NO_INDEX) {
        this.setState({
            documentDetailsState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });

    }

    private onDocumentDetailsCancel() {
        this.setState({
            documentDetailsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        })
    }

    //======================Document Details Ends Here=======================================


    //======================SetAccess State========================================

    private onSetAccessOpen(rowIndex: number = NO_INDEX, headerTextShow: boolean) {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning("Please select document(s) to set access for other users!", null);
            return;
        }
        this.props.requestAllSFUsers(false);
        this.props.requestSignatureReportAccess(ids, SignatureReportPaths.SetAccess);
        let selectedReports = ids.map((id, i) => {
            return this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents.filter(x => x.signingId == id)[0];
        });
        this.setState({
            setAccessState: {
                show: true,
                selected: selectedReports,
                saveCheckIds: undefined,
                remove: false
            },
            setAccessHeaderTextDisplay: headerTextShow
        });

        logger.trackTrace("onSetAccessOpen with ids" + JSON.stringify(ids));
    }

    private onSetAccessSave(userIds: number[]) {
        let ids = this.selectedDocumentIds();
        if (ids.length == 0 && this.state.setAccessState.selected) {
            this.state.setAccessState.selected.map((reports, i) => {
                ids.push(reports.signingId)
            })
        }
        let accessMaps: IDocumentAccess[] = [];
        ids.forEach((id, i) => {
            accessMaps.push({
                signingId: id,
                userIds: userIds
            } as IDocumentAccess);
        });
        this.props.setAccessToSignatureReports(accessMaps, SignatureReportPaths.SetAccess);

        logger.trackTrace("onSetAccessSave with accessMaps" + JSON.stringify(accessMaps));
        this.setState({
            setAccessState: {
                show: false,
                selected: undefined,
                saveCheckIds: ids,
                remove: false
            },
            selectedRows: []
        }, this.onPageReload);
    }

    private onSetAccessCancel() {
        this.setState({
            setAccessState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false
            }
        });
    }

    //====================SetAccess Ends Here=======================================

    //====================Resend Access Link Starts Here===============================

    private onResendAccessLinkOpen(rowIndex: number = NO_INDEX) {
        this.setState({
            resendAccessLinkState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });
    }

    private onResendAccessLink(signer: ISignerModel) {
        const index = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents.findIndex(x => x.signingId == signer.signingId);
        const id = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[index].signingId;
        const document = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[index];
        let mailData: IMailData = {
            signers: [], documents: [], deliveredUser: { email: '', firstName: '', lastName: '', userId: 0 }, title: ''
        };
        mailData.signers.push(this.checkIfSignerHasDelegatee(signer, document));
        this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[index].documentInfo.map((document, index) => {
            mailData.documents.push(document)
        });
        mailData.deliveredUser = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[index].deliveredUserDetails;
        mailData.title = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[index].title;
        this.props.resendAccessLink(mailData, SignatureReportPaths.ResendAccesslink);
        this.setState({
            resendAccessLinkState: {
                show: false,
                model: undefined,
                saveCheckId: id
            }
        });

        logger.trackTrace("onResendAccessLink with signer" + JSON.stringify(signer));
    }

    private onResendAccessLinkCancel() {
        this.setState({
            resendAccessLinkState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }
    //====================Resend Access Link Ends Here===============================

    private onArchiveReturnsOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0 && !this.state.isBulkSelectionEnabled) {
            VenusNotifier.Warning("Please select document(s) to archive!", null);
            return;
        }

        let obj = this;
        bootbox.confirm({
            title: 'Archive Document(s)',
            message: 'Are you sure you want to archive these selected document(s)?',
            buttons: {
                cancel: {
                    label: 'Cancel',
                    className:'btn-white modal-footer-button-cancel'
                },
                confirm: {
                    label: 'Yes',
                    className:'btn-info modal-footer-button-save'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    ShowLoader(Common.OverlayMessages.Archiving);
                    this.state.isBulkSelectionEnabled ? this.onArchiveReturnsBulk() : this.onArchiveSelectedReturns(ids);
                }
            }
        }).find(".modal-dialog")
        .addClass("modal-dialog-centered");
    }


    private onDeleteDocumentsOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0 && !this.state.isBulkSelectionEnabled) {
            VenusNotifier.Warning("Please select document(s) to delete!", null);
            return;
        }


        let obj = this;
        bootbox.confirm({
            title:'Delete Document(s)',
            message: DeleteSignatureConstants.ConfirmDeletion,
            buttons: {
                cancel: {
                    label: 'Cancel',
                    className:'btn-white modal-footer-button-cancel'
                },
                confirm: {
                    label: 'Confirm',
                    className:'btn-info modal-footer-button-save'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    ShowLoader(Common.OverlayMessages.Deleting);
                    obj.state.isBulkSelectionEnabled ? obj.onBulkDeleteReturns() : obj.onDeleteSelectedReturns(ids);
                    logger.trackTrace("onDeleteSelectedReturns with ids" + JSON.stringify(ids));
                }

            }
        }).find(".modal-dialog")
        .addClass("modal-dialog-centered");
    }

    private onBulkDeleteReturns = () => {
        let query = this.getBulkOperationQuery();
        this.props.deleteBulkDocuments(query, () => {
            this.setState({
                deleteReturnsState: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                },
                page: 1
            });
            HideLoader();
            this.clearSelection();            
        });
    }

    private onRetoreDocumentsOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0 && !this.state.isBulkSelectionEnabled) {
            VenusNotifier.Warning("Please select document(s) to delete!", null);
            return;
        }


        let obj = this;
        bootbox.confirm({
            message: warningMsg.deletDocumentConfirmation,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'popup-btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.onDeleteSelectedReturns(ids)
                }
            }
        });


    }


    private onArchiveSelectedReturns(ids: number[]) {
        this.props.archiveSignatureDocument(ids,
            () => {
                this.setState({
                    page: getAdjustedReportsPageNumber(this.props.DeliveredReportDocuments.signatureFlowReportTableModel.count, ids.length, this.state.page, this.state.pageSize)
                }, () => {
                    this.clearFilters();
                })
                HideLoader();                      
            });
        this.setState({
            archiveReturnsState: {
                show: false,
                selected: undefined,
                saveCheckIds: ids,
                remove: false
            }
        });
    }

    private onArchiveReturnsBulk = () => {
        let query = this.getBulkOperationQuery();

        this.props.archiveBulkDocuments(query, () => {
            this.setState({
                archiveReturnsState: {
                    show: false,
                    selected: undefined,
                    saveCheckIds: undefined,
                    remove: false
                },
                page: 1
            });
            HideLoader();
            this.clearSelection();
        })
    }

    private onDeleteSelectedReturns(ids: number[]) {
        this.props.deleteSignatureDocument(ids, SignatureReportPaths.Delete, () => {
            this.setState({
                page: getAdjustedReportsPageNumber(this.props.DeliveredReportDocuments.signatureFlowReportTableModel.count, ids.length, this.state.page, this.state.pageSize)
            },() => {
                this.clearFilters();
            })
            HideLoader();
        });
        this.setState({
            deleteReturnsState: {
                show: false,
                model: undefined,
                saveCheckId: ids[0]
            }
        });
    }

    getBulkOperationQuery = (): SFBulkOperationQuery => {
        let query: SFBulkOperationQuery = {
            clientName: this.state.filterRecipientName,
            clientID: this.state.filterClientId,
            title: this.state.filterTitle,
            deliveredBy: this.state.filterSenderName,
            documentName: this.state.filterDocumentName,
            documentType: this.state.filterDocumentType,
            taxYear: (this.state.filterTaxYear === "-1" ? "" : this.state.filterTaxYear),
            deliveredOn: this.state.filterDeliveredOn,
            documentNote: this.state.filterDocumentNote,
            signer: this.state.filterSigners,
            signatureStatus: (this.state.filterSignatureStatus === "-1" ? "" : this.state.filterSignatureStatus),
            expireOn: this.state.filterExpiration,
            loggedInUser: this.state.filterLoggedUser,
            downloadStatus: this.state.filterDownloadStatus,
            archivedOn: this.state.filterArchivedOn,
            lastReminderOn: this.state.filterLastReminderOn,
            unSelectedIds: this.state.deSelectedRows,
            totalRecords: this.props.DeliveredReportDocuments.totalRowCount,
            officeLocation: (this.state.filterOfficeLocation === "-1" ? "" : this.state.filterOfficeLocation),
        }
        return query;
    }

    getBulkOperationQueryForSendReminder = (): SFBulkOperationQuery => {
        let query: SFBulkOperationQuery = {
            clientName: this.state.filterRecipientName,
            clientID: this.state.filterClientId,
            title: this.state.filterTitle,
            deliveredBy: this.state.filterSenderName,
            documentName: this.state.filterDocumentName,
            documentType: this.state.filterDocumentType,
            taxYear: (this.state.filterTaxYear === "-1" ? "" : this.state.filterTaxYear),
            deliveredOn: this.state.filterDeliveredOn,
            documentNote: this.state.filterDocumentNote,
            signer: this.state.filterSigners,
            signatureStatus: this.filterSignatureStatusForBulkReminder(),
            expireOn: this.state.filterExpiration,
            loggedInUser: this.state.filterLoggedUser,
            downloadStatus: this.state.filterDownloadStatus,
            archivedOn: this.state.filterArchivedOn,
            lastReminderOn: this.state.filterLastReminderOn,
            unSelectedIds: this.state.deSelectedRows,
            totalRecords: this.props.DeliveredReportDocuments.totalRowCount,
            officeLocation: (this.state.filterOfficeLocation === "-1" ? "" : this.state.filterOfficeLocation),
        }
        return query;
    }

    filterSignatureStatusForBulkReminder = () => {
        const validStatusForReminders = [SignatureFlowReportSignatureStatus.Processing.toString(),
             SignatureFlowReportSignatureStatus.OutForSignature.toString(),
             SignatureFlowReportSignatureStatus.PartiallySigned.toString()];    //Array containing signature status that is valid for reminder operations. 

        let signatureStatus = this.state.filterSignatureStatus.split(",")
        if (this.state.filterSignatureStatus === "-1" || this.state.filterSignatureStatus === "") {
            return validStatusForReminders.join();
        }
        else {
            signatureStatus = signatureStatus.filter(i => validStatusForReminders.includes(i));
        }
        return signatureStatus.join();
    }

    //====================Download Returns Starts Here===============================

    private getDisplayNameForDloadSignatureDocs(report: ISignatureFlowReportDocument,
        document: ISignatureFlowReportsDocumentInfo, haveAllSignersSigned: boolean) {
        return Helper.getSignerDetail(report) + " " + Helper.addSignedToDocName(document.documentName, haveAllSignersSigned, report.signatureStatus);
    }

    private validateSignedDocumentsDload(selectedReports: ISignatureFlowReportDocument[]) {
        for (let i = 0; i < selectedReports.length; i++) {
            if (!this.hasAllSignersSigned(selectedReports[i].signerInfo))
                return false;
        };
        return true;
    }

    private getDownloadSizeInBytes(size: string){
        var sizeArray = size.split(' ');
        var intSize = parseInt(sizeArray[0]);
        if(sizeArray[1] == 'KB'){
            return intSize*1024;
        }
        else if(sizeArray[1] == 'MB'){
            return intSize*1024*1024;
        }
        else if(sizeArray[1] == 'GB'){
            return intSize*1024*1024*1024;
        }
    }

    private onDownloadIndividualOpen(rowIndex: number = NO_INDEX){
        this.onDownloadReturnOpen(rowIndex, true);
    }

    private onDownloadReturnOpen(rowIndex: number = NO_INDEX, isIndividual: boolean = false) {
        
        let ids = this.onPopupOpen(rowIndex);
        let downloadableDocuments: IDownloadableDocuments[] = [];
        let isAllSignersSigned: boolean = false;
        let zipFileName = Helper.getZipFileNameForMultipleDload();
        let zipSize = 0;
        let selectedReports = ids.map((id, i) => {
            return this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents.filter(x => x.signingId == id)[0];
        });
        if (rowIndex == NO_INDEX) {
            if (!this.validateSignedDocumentsDload(selectedReports) || 
                        (this.state.isBulkSelectionEnabled && this.state.filterSignatureStatus != "3")) {
                VenusNotifier.Error(SignatureFlowReportConstants.DownloadDocument.DocumentNotSignedError, null);
                return;
            }
        }
        if(this.state.isBulkSelectionEnabled && !isIndividual){
            var query = this.getBulkOperationQuery();
            this.props.downloadLaterBulk(query, zipFileName,
                (() => {
                            this.setState({ showLoader: false });
                            this.onDownloadTypeCancel();
                        }
                ));
            
        }
        else{
            selectedReports.map((report, index) => {
                let signingGuid = report.signingInfoGuid;
                let id = report.signingId;
                isAllSignersSigned = this.hasAllSignersSigned(report.signerInfo);
                report.documentInfo.map((document, index) => {
                    let downloadableFileData: IDownloadableDocuments = {
                        signingId: 0, documentGuid: '', documentId: 0, fileName: '', size:0,
                        signingGuid: '', isSigningCompleted: false, fileDownloadName: ''
                    };
                    downloadableFileData.signingGuid = signingGuid;
                    downloadableFileData.signingId = id;
                    downloadableFileData.documentGuid = document.documentGuid;
                    downloadableFileData.fileName = document.documentName;
                    downloadableFileData.documentId = document.documentId;
                    downloadableFileData.isSigningCompleted = isAllSignersSigned;
                    downloadableFileData.fileDownloadName = this.getDisplayNameForDloadSignatureDocs(report, document, isAllSignersSigned);
                    if(document.size!=null){
                        let size = this.getDownloadSizeInBytes(document.size);
                        downloadableFileData.size = size;
                        zipSize+=size;
                    }
                    downloadableDocuments.push(downloadableFileData);
                });

                if (isAllSignersSigned && report.isMigratedSigningIdForAudit) {
                    let auditFileData: IDownloadableDocuments = {
                        signingId: 0, documentGuid: '', documentId: 0, size:0,
                        fileName: '', signingGuid: '', isSigningCompleted: true, fileDownloadName: ''
                    };
                    auditFileData.signingGuid = signingGuid;
                    auditFileData.signingId = id;
                    auditFileData.fileName = "Summary";
                    auditFileData.isSigningCompleted = true;
                    auditFileData.fileDownloadName = "Summary";
                    downloadableDocuments.push(auditFileData);
                }
            });
            this.setState({downloadableDocuments: downloadableDocuments, zipFileDownloadName: zipFileName});
            zipSize = zipSize/1024/1024; // Converting Bytes to MB
            if(zipSize>5120){
                VenusNotifier.Error(SignatureFlowReportConstants.DownloadDocument.DownloadLimitError, null);
            }
            else if(zipSize<200){
                if(selectedReports.length==1){
                    if(selectedReports[0].signatureStatus === SignatureFlowReportSignatureStatus.DeliveryFailed){
                        return;
                    }
                    zipFileName = Helper.getZipFileNameForSingleDload(selectedReports[0].title, isAllSignersSigned);
                    this.setState({
                        downloadReturnState: {
                            show: true,
                            selected: downloadableDocuments,
                            saveCheckIds: undefined,
                            remove: false
                        },
                        zipFileDownloadName: zipFileName
                    });
                }
                else if(this.state.defaultDownloadType == DefaultDownloadInfoType.DownloadNow){
                    VenusNotifier.Info(SignatureFlowReportConstants.DownloadDocument.BulkDownloadNotification, null);
                    this.onDownloadNow(downloadableDocuments, zipFileName);
                }
                else if(this.state.defaultDownloadType == DefaultDownloadInfoType.PromptMeEveryTime){
                    this.setState({
                        DownloadTypeOptionState: {
                        show: true,
                        selected: undefined,
                        saveCheckIds: undefined,
                        remove: false
                        }
                    });
                }
                else{
                    this.onDownloadLater(downloadableDocuments, zipFileName);
                }
            }
            else if (zipSize>200){
                this.onDownloadLater(downloadableDocuments, zipFileName);
            }
        }

    }

    private onDownloadLater(downloadableDocuments: IDownloadableDocuments[], zipName: string){
        this.setState({ showLoader: true });
        this.props.downloadLater(downloadableDocuments, zipName,
            () => {
            this.setState({ showLoader: false });
            this.onDownloadTypeCancel();
        });
    }

    private onDownloadNow(downloadableDocuments: IDownloadableDocuments[], zipName: string){
        this.setState({ showLoader: true });
        this.props.downloadAllDocuments(downloadableDocuments, zipName, SignatureReportPaths.DownloadAll,
            () => {
            this.setState({ showLoader: false });
            this.onDownloadTypeCancel();
        });
    }

    private onDownloadReturnAll() {
        this.onDownloadNow(this.state.downloadableDocuments, this.state.zipFileDownloadName);
    }

    private onDownloadSubmit() {
        if(this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadNow) {
            this.onDownloadNow(this.state.downloadableDocuments, this.state.zipFileDownloadName);
        } else if(this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadToMyDownloads){
            this.onDownloadLater(this.state.downloadableDocuments, this.state.zipFileDownloadName);
        }
        
    }

    private onDownloadIndividualFile(documentGuid: string, fileName: string, signingGuid: string, signingId: number,
        hasAllSignersSigned: boolean, fileDownloadName: string) {
        this.setState({ showLoader: true });
        this.props.downloadIndividualFile(documentGuid, fileName, signingGuid, signingId, hasAllSignersSigned, fileDownloadName, SignatureReportPaths.Download,
            () => {
            this.setState({ showLoader: false });
        });
    }

    private onDownloadReturnCancel() {
        this.setState({
            downloadReturnState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            }
        });
    }

    private onDownloadTypeCancel() {
        this.setState({
            selectedDownloadType : undefined
        })
        this.setState({
            DownloadTypeOptionState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false
            }
        });
        }

    private onDownloadOptionChange(value: any) {
        this.setState({ selectedDownloadType: value.target.value });
    }

    //====================Download Returns End Here===============================

    //====================SendReminder Starts Here===============================

    onSendReminder(rowIndex: number = NO_INDEX) {
        let selectedIds : number [] = [];
        if (rowIndex > NO_INDEX && // from individual action
            this.props.DeliveredReportDocuments &&
            this.props.DeliveredReportDocuments.signatureFlowReportTableModel &&
            this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[rowIndex]) {
            selectedIds.push(this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId);
        }
        else if(this.state.isBulkSelectionEnabled){
            this.setState({
                sendReminderState: {
                    show: true,
                    selected: [],
                    saveCheckIds: undefined,
                    remove: false
            }});
            return;
        }
        else {
            if (this.state.selectedRows.length <= 0) {
                VenusNotifier.Warning("Please select one or more documents to send reminders.", null);
                return;
            }
            selectedIds = this.selectedDocumentIds(); // from header level
        }
        let selectedReports = selectedIds.map((id, i) => {
            return this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents.filter(x => x.signingId == id)[0];
        });
        if (selectedIds.length > 0) {
            if (selectedIds.length === 1) {
                this.props.getReminderSettings(selectedIds[0], `${ScreenIds.SignatureReport}${ResourceIdConstants.SendReminder}`);
            }
            this.setState({
                sendReminderState: {
                    show: true,
                    selected: selectedReports,
                    saveCheckIds: undefined,
                    remove: false
                }
            });
        }
    }


    sendReminderNow = (selectedSigningId: number) => {
        let rowIndex = NO_INDEX;
        if (selectedSigningId > 0) {
            rowIndex = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents.findIndex(x => x.signingId === selectedSigningId);
            this.sendRemindersForSelectedReturns(rowIndex);
        }
        else { //multi select or no selection
            if (this.state.selectedRows.length <= 0 && !this.state.isBulkSelectionEnabled) {
                VenusNotifier.Warning("Please select one or more documents to send reminders.", null);
                return;
            }
            if (this.state.selectedRows.length > 1 || this.state.isBulkSelectionEnabled) {
                if (this.state.isBulkSelectionEnabled) {
                    this.sendBulkReminders();
                }
                else {
                    this.sendRemindersForSelectedReturns(rowIndex);
                }
            }
            else {
                this.sendRemindersForSelectedReturns(this.state.selectedRows[0]);
            }
        }
    }

    sendRemindersForSelectedReturns = (rowIndex: number) => {
        let sendReminder: IMailData[] = [];
        let selectedDocuments: ISignatureFlowReportDocument[] = [];
        let index: number = 0;
        if (rowIndex == NO_INDEX) {
            selectedDocuments = this.selectedDocuments();
            selectedDocuments = selectedDocuments.filter((x: ISignatureFlowReportDocument) =>
                x.signatureStatus == SignatureFlowReportSignatureStatus.OutForSignature ||
                x.signatureStatus == SignatureFlowReportSignatureStatus.Processing ||
                x.signatureStatus == SignatureFlowReportSignatureStatus.PartiallySigned);

            if (selectedDocuments.length == 0) {
                VenusNotifier.Error("Error : We cannot send reminder email notification for the selected document(s).", null);
                this.onCancelSendReminder();
                return;
            }
            for (let i = 0; i < selectedDocuments.length; i++) {
                if (!this.props.signatureFlowReportDetails[selectedDocuments[i].signingId].isSignersUpdated) {
                    this.props.requestSignatureReportSigners(selectedDocuments[i].signingId, `${ScreenIds.SignatureReport}${ResourceIdConstants.SendReminder}`, (error?: boolean) => {
                        if(!error){
                            this.sendRemindersForSelectedReturns(rowIndex)
                        }
                        else {
                            this.onCancelSendReminder();
                        }
                    });
                    return;
                }
            }
            sendReminder = this.selectedDocumentsSignersInfo();
        }
        else {
            selectedDocuments.push(this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[rowIndex]);
            for (let i = 0; i < selectedDocuments.length; i++) {
                if (!this.props.signatureFlowReportDetails[selectedDocuments[i].signingId].isSignersUpdated) {
                    this.props.requestSignatureReportSigners(
                        this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId, `${ScreenIds.SignatureReport}${ResourceIdConstants.SendReminder}`,
                        (error?: boolean) => {
                            if(!error){
                                this.sendRemindersForSelectedReturns(rowIndex)
                            }
                            else {
                                this.onCancelSendReminder();
                            }
                        });
                    return;
                }
            }
            HideLoader();
            let temp = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[rowIndex];
            let mailData: IMailData = {
                signers: [], documents: [], deliveredUser: { email: '', firstName: '', lastName: '', userId: 0 }, title: ''
            };
            let nextSigners = temp.signerInfo.filter(x => x.isSigned == 0 && 
                (x.order > 0 || (x.order == 0 && 
                                    (x.signerType == ClientTypes.Sender || x.signerType == ClientTypes.Delegatee) 
                                        && x.clientId != 0))).sort((a: ISignerModel, b: ISignerModel) => {
                if (a.order < b.order) return -1;
                else return 1;
            });
            if (nextSigners.length == 0) {
                VenusNotifier.Error("Error : We cannot send reminder email notification for this document.", null);
                return;
            }
            
            let currentOrder = nextSigners[0].order;
            nextSigners.filter(x=> x.order == currentOrder).forEach((signer)=>{
                mailData.signers.push(this.checkIfSignerHasDelegatee(signer, temp));
            });

            temp.documentInfo.forEach((document, index) => {
                mailData.documents.push(document);
            });
            mailData.deliveredUser = temp.deliveredUserDetails;
            mailData.title = temp.title;
            sendReminder.push(mailData);
        }
        
        if (selectedDocuments.length > 0) {
            let i: number = 0;
            for (i = 0; i < selectedDocuments.length; i++) {
                let isValid: boolean = true;
                let signatureStatus: number = selectedDocuments[i].signatureStatus;
                if (signatureStatus == SignatureFlowReportSignatureStatus.Cancelled ||
                    signatureStatus == SignatureFlowReportSignatureStatus.Declined ||
                    signatureStatus == SignatureFlowReportSignatureStatus.ESigned ||
                    signatureStatus == SignatureFlowReportSignatureStatus.SignatureStampingFailed ||
                    signatureStatus == SignatureFlowReportSignatureStatus.DeliveryFailed) {
                    isValid = false;
                    index = sendReminder.findIndex(x => x.documents[0].signingId == selectedDocuments[i].signingId);
                    if (index != -1) {
                        sendReminder.splice(index, 1);
                    }
                }

                if (!isValid) {
                    VenusNotifier.Error("Error : We cannot send reminder email notification for this document.", null);
                }
            }
            sendReminder.length > 0 &&
                this.props.sendReminder(sendReminder, SignatureReportPaths.SendReminder, this.onFinishSendReminder);
        }
    }

    sendBulkReminders = () => {
        let query = this.getBulkOperationQueryForSendReminder();
        this.props.sendBulkReminders(query, () => {
            this.setState({
                isBulkSelectionEnabled: false,
                selectedRows: [],
                deSelectedRows: [],
                sendReminderState: {
                    show: false,
                    selected: undefined,
                    saveCheckIds: undefined,
                    remove: false
                }
            });
            HideLoader();
        })
    }

    onCancelSendReminder = () => {
        this.setState({
            sendReminderState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            },
        });
    }

    onFinishSendReminder = () => {
        this.onCancelSendReminder();
        this.onPageReload();
    }

    updateSigningReminderSettings = (
        signingInfoIds: number[],
        newSettings: IReminderSettings, callback?: () => void) => {

        if (signingInfoIds.length === 1) { // single selection
            this.props.updateSigningReminderSettings(signingInfoIds, newSettings, SignatureReportPaths.UpdateReminderSettings, callback);
        }
        else if (this.state.isBulkSelectionEnabled) {
            this.updateBulkReminderSettings(newSettings, callback);
        }
        else { // multi selection
            this.props.updateSigningReminderSettings(signingInfoIds, newSettings, SignatureReportPaths.UpdateReminderSettings, callback);
        }
    }

    getReminderSettings = (): IReminderSettings => {
        if (this.state.isBulkSelectionEnabled) {
            if (this.state.sendReminderState.selected && this.state.sendReminderState.selected.length === 1) {
                return this.props.signingInfoSettingsData[this.state.sendReminderState.selected[0].signingId].signingInfoSettings.reminderSettings;
            }
            return this.props.signatureFlowSettingsData.signatureFlowSettings.reminderSettings;
        }
        if (this.state.sendReminderState.selected && this.state.sendReminderState.selected.length > 0) {
            if (this.state.sendReminderState.selected.length > 1) {
                return this.props.signatureFlowSettingsData.signatureFlowSettings.reminderSettings;
            }
            else {
                return this.props.signingInfoSettingsData[this.state.sendReminderState.selected[0].signingId].signingInfoSettings.reminderSettings;
            }
        }
        return initialReminderSettings;
    }

    isSendReminderModalLoading = () => {
        if (this.state.isBulkSelectionEnabled) {
            if (this.state.sendReminderState.selected && this.state.sendReminderState.selected.length === 1) {
                return this.props.signingInfoSettingsData[this.state.sendReminderState.selected[0].signingId].isLoading
            }
            return false;
        }

        if (this.state.selectedRows.length > 1 &&
            this.props.signingInfoSettingsData) {
            return this.props.signingInfoSettingsData.isLoading;
        }
        if (this.props.signingInfoSettingsData && this.state.sendReminderState.selected) {
            return this.props.signingInfoSettingsData[this.state.sendReminderState.selected[0].signingId].isLoading ||
                this.props.signingInfoSettingsData.isLoading;
        }
        return false;
    }

    updateBulkReminderSettings = (newSettings: IReminderSettings, callback?: () => void) => {
        let query = this.getBulkOperationQueryForSendReminder();
        this.props.updateBulkReminderSettings(newSettings, query, callback);
    }

    resetSelectedRowsAfterBulkOperation = () => {
        this.setState({
            isBulkSelectionEnabled: false,
            selectedRows: [],
            deSelectedRows: [],
            sendReminderState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            },
        });
    }

    //====================SendReminder Ends Here=================================

    //====================View Access Code Starts Here===============================

    private onViewAccessCodeOpen(rowIndex: number) {

        let id = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId;
        this.props.requestSignatureReportAccessCode(id, SignatureReportPaths.GetOtp);

        this.setState({
            accessCodeState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });
    }

    private onGenerateOTP(signingId: number, clientType: number, clientGUID: string, callback: () => void) {
        this.props.generateOTP(signingId, clientType, clientGUID, SignatureReportPaths.GenerateOtp, callback);
        this.setState({
            accessCodeState: {
                show: true,
                model: this.state.accessCodeState.model,
                saveCheckId: undefined
            }
        });
    }

    private onViewAccessCodeCancel() {
        this.setState({
            accessCodeState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }
    //=====================View Access Code Ends Here================================

    //====================Client Tracking Starts Here===============================

    private onClientTrackingOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            this.props.requestSignatureReportClientTracking(id, SignatureReportPaths.ClientTracking);
        });

        this.setState({
            clientTrackingState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });

    }

    private onClientTrackingCancel() {
        this.setState({
            clientTrackingState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }
    //=====================Client Tracking Ends Here=========================================
    
    //==================== Report a problem start ===============================

    private onReportProblemOpen(rowIndex: number = NO_INDEX) {

        this.setState({
            reportProblem: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });

    }

    private onReportProblemCancel() {
        this.setState({
            reportProblem: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    private onReportProblemSave(id: number, problemDetails: IReportProblemDetails, callback?: () => void) {
        this.props.reportTaxDocumentProblem(problemDetails, SignatureReportPaths.ReportedProblem, callback);
        this.setState({
            reportProblem: {
                show: false,
                model: undefined,
                saveCheckId: id
            }
        });

        logger.trackTrace("onReportProblemSave with params" + JSON.stringify({id: id, problemDetails: problemDetails}));

    }
    //====================Report a problem Ends Here===============================


    //=====================Cancel Document=======================================================================

    private onCancelDocumentConfirmation(rowIndex: number) {
        let obj = this;
        let signatureStatus: SignatureFlowReportSignatureStatus = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signatureStatus;
        if (signatureStatus == SignatureFlowReportSignatureStatus.ESigned || signatureStatus == SignatureFlowReportSignatureStatus.Declined
            || signatureStatus == SignatureFlowReportSignatureStatus.Cancelled || signatureStatus == SignatureFlowReportSignatureStatus.SignatureStampingFailed 
            || signatureStatus == SignatureFlowReportSignatureStatus.DeliveryFailed) {
            VenusNotifier.Warning("You cannot cancel this document.", null);
            return;
        }
        bootbox.confirm({
            title:'Document Cancellation',
            message: warningMsg.cancelDocumentConfirmation,
            buttons: {
                cancel: {
                    label: 'Dismiss',
                    className:'btn-white modal-footer-button-cancel'
                },
                confirm: {
                    label: 'Yes, Cancel It',
                    className:'btn-danger'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    obj.onCancelDocumentOpen(rowIndex);
                }
            }
        }).find(".modal-dialog")
        .addClass("modal-dialog-centered");
    }

    private onCancelDocumentOpen(rowIndex: number) {
        let id = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId;
        let signatureStatus = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signatureStatus;

        this.setState({
            showLoader: true,
            cancelDocumentState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });
        const path = signatureStatus === SignatureFlowReportSignatureStatus.Declined ?
            CommonPaths.DeclinedDescription : SignatureReportPaths.CancelDescription;
        this.props.requestCancelledDescription(id, signatureStatus, path,
            () => {
            this.setState({ showLoader: false });
        });
        logger.trackTrace("onCancelDocument with id" + JSON.stringify(id));
    }

    private onSaveCancelDocumentDescription(id: number, message: string, callBack: () => void, sendNotification: boolean) {
        if (message.length == 0) {
            return VenusNotifier.Warning("Please enter the reason for cancelling this document", null);
        }
        let index = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents.findIndex(x => x.signingId == id);
        let signatureReport = { ...this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[index] };
        signatureReport.cancelledDocumentDescription = message
        signatureReport.signatureStatus = SignatureFlowReportSignatureStatus.Cancelled;
        this.props.cancelDocument(signatureReport, SignatureReportPaths.UpdateStatusAsCancel, callBack);
        if (sendNotification) {
            let mailData: IMailData = {
                signers: [], documents: [], deliveredUser: { email: '', firstName: '', lastName: '', userId: 0 }, title: ''
            };

            signatureReport.signerInfo.map((signer, index) => {
                mailData.signers.push(signer);
            })
            signatureReport.documentInfo.map((document, index) => {
                mailData.documents.push(document)
            });
            mailData.deliveredUser = signatureReport.deliveredUserDetails;
            mailData.title = signatureReport.title;

            this.props.sendcanceledDocumentNotification(mailData, message, SignatureReportPaths.CancelNotification);
        }
        logger.trackPageView("Cancel a document");
    }

    private onCancelDocumentCancel() {
        this.setState({
            cancelDocumentState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }


    //======================Cancel Document Ends Here============================================================


    //================== Document Note =============================================

    private onDocumentNoteOpen(rowIndex: number) {
        this.setState({
            documentNoteState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        }, () => {
            this.setState({
                documentNoteMessage: this.state.documentNoteState.model != undefined
                    ? this.state.documentNoteState.model.documentNote : ""
            })
        });
    }

    private onDocumentNoteChange(event: any) {
        let message = event.target.getContent();
        this.setState({ documentNoteMessage: message });
    }

    private onUpdateDocumentNote() {
        let temp: any;
        if (this.state.documentNoteState.model != undefined) {
            temp = this.state.documentNoteState.model;

            if (this.state.documentNoteMessage &&
                this.state.documentNoteMessage.replace(/\&nbsp;/g, '').replace(/<\/?p[^>]*>/g, '').trim() != "") {

                const showSaveMessage = temp.documentNote == "" ? true : false;
                temp.documentNote = htmlencode.htmlEncode(this.state.documentNoteMessage);

                this.props.updateDeliveredDocumentNote(this.state.documentNoteState.model, SignatureReportPaths.DocumentNote,
                    undefined, showSaveMessage);

                this.setState({
                    documentNoteState: {
                        model: temp,
                        show: false,
                        saveCheckId: undefined
                    },
                    documentNoteMessage: ''
                });
            }
            else {
                VenusNotifier.Warning(SignatureFlowReportFilterConstants.ValidationMessage.EmptyDocumentNote, null);
            }
        }

    }

    private deleteDocumentNoteMessage() {
        let temp: any;
        if (this.state.documentNoteState.model != undefined) {
            temp = this.state.documentNoteState.model;
            temp.documentNote = "";
            this.props.updateDeliveredDocumentNote(this.state.documentNoteState.model, SignatureReportPaths.DocumentNote, true);

            this.setState({
                documentNoteState: {
                    model: temp,
                    show: false,
                    saveCheckId: undefined
                },
                documentNoteMessage: ''
            });
        }
    }

    private onDocumentNoteCancel() {
        this.setState({
            documentNoteState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            },
            documentNoteMessage: ''
        });
    }

    //================== Document Note End ==========================================


    //================== Delete Document Starts Here ==========================================
    private onDeleteDocumentConfirmation(rowIndex: number) {
        let obj = this;
        bootbox.confirm({
            title:'Delete Document(s)',
            message: DeleteSignatureConstants.ConfirmDeletion,
            buttons: {
                cancel: {
                    label: 'Cancel',
                    className:'btn-white modal-footer-button-cancel'
                },
                confirm: {
                    label: 'Confirm',
                    className:'btn btn-info modal-footer-button-save'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    let id = obj.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId;
                    obj.props.deleteDocument(id, SignatureReportPaths.Delete, () => {
                        this.setState({
                            page: getAdjustedReportsPageNumber(this.props.DeliveredReportDocuments.signatureFlowReportTableModel.count, 1, this.state.page, this.state.pageSize)
                        })
                        obj.props.requestSignatureDeliveredReportDocuments(this.buildQuery(this.state.page, this.state.pageSize), true)
                    });

                    logger.trackTrace("deleteDocument with id" + id);
                }
            }
        }).find(".modal-dialog")
        .addClass("modal-dialog-centered");
    }


    //================== Delete Document End ==================================================

    //====================Download History Starts Here=============================

    private onDownloadHistoryOpen(rowIndex: number = NO_INDEX) {
        let id = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId;
        this.props.requestDownloadHistory(id);

        this.setState({
            downloadHistoryState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });
    }

    private onDownloadHistoryCancel() {
        this.setState({
            downloadHistoryState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    //====================Download History Ends Here===============================

    //====================Recipient Details Starts Here===============================

    private onRecipientDetailsOpen = (rowIndex: number) => {
        this.setState({
            recipientDetailsState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });
    }

    private onRecipientDetailsCancel = () => {
        this.setState({
            recipientDetailsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    //====================Recipient Details Ends Here===============================

    //====================Edit Signers Email starts here=======================
    private updateSignersEmail = (signatureData: ISignatureFlowReportDocument, signers: ISignerModel[]) => {

        this.props.updateDeliveredSignerEmail(
            signatureData,
            signers,
            `${ScreenIds.SignatureReport}${ResourceIdConstants.EditClientDetails}`);
        this.onRecipientDetailsCancel();
    }

    //====================Edit Signers Email ends here=======================


    // =================Edit Client Details starts here=======================
    private onEditClientDetailsOpen = (rowIndex: number = NO_INDEX) => {
        this.setState({
            editClientInfoState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined
            }
        });
        let id = this.props.DeliveredReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId;
        this.props.requestSigningInfo(id, SignatureReportPaths.SigningInfo);
        if (this.props.contactsStore.contacts.length === 0) {
            this.props.getContacts();
        }
    }

    private onEditClientDetailsCancel = (rowIndex: number = NO_INDEX) =>{
        this.setState({
            locationMismatchModalState: {...initialLocationMismatchModalState},
            clientInfo: {...initialClientInfoViewModel},
            clientInfoComparisonResult: {...initialClientInfoComparisonResultViewModel},
            clientInfoMismatchModalState: {...initialClientInfoMismatchModalState},
            compareClientInfoProgress: false,
            tempSigninfo: initialSigningInfo,
            editClientInfoState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }


    updateRecipients = (signingInfoId: number, recipients: IClient[], callback?: () => void) => {
        this.props.updateRecipients(signingInfoId, recipients, SignatureReportPaths.UpdateAuthentication, callback);
    }

    // =================Edit Client Details ends here======================= 


    //====================Change Office Location  Starts Here===============================

    private onChangeOfficeLocationOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Error("Please select documents to change office location.", null);
            return;
        }
        this.setState({
            changeOfficeLocationState: {
                show: true,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false
            }
        });
    }

    private onChangeOfficeLocationContinue = (locationId: number, contactPersonId: number, callBack: () => void) => {
        let ids = this.selectedDocumentIds();
        let query = this.getBulkOperationQuery();

        if (this.state.isBulkSelectionEnabled) {
            this.props.changeBulkOfficeLocation( query,
                locationId,
                contactPersonId,
               (status)=>{ this.onChangeOfficeLocationCallBack(status,callBack)})
           
        } else {
            this.props.changeOfficeLocation(
                ids,
                locationId,
                contactPersonId,
                SignatureReportPaths.ChangeOfficeLocation,
               (status)=>{ this.onChangeOfficeLocationCallBack(status,callBack)}
            );  
        }
    };

    onChangeOfficeLocationCallBack = (status:string, callBack: () => void) => {
        this.setState({
            changeOfficeLocationState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false
            },
            selectedRows: [],
            deSelectedRows: [],
            isBulkSelectionEnabled: false,
            showBulkSelectionMessage: false,
        });

        if(status === "success"){
            this.onPageReload();
        }
        
        callBack();
    };

    private onChangeOfficeLocationCancel = () => {
        this.setState({
            changeOfficeLocationState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false
            }
        });
    };

    getUpdatedClientInfo = (create?: boolean) => {
        const locationName = this.props.officeLocationState.locationDropdown.find(c => c.value == this.state.tempSigninfo.locationId)?.name;
        let _clientInfo = {...this.state.clientInfo};
        _clientInfo.name = this.state.tempSigninfo.recipientName;
        _clientInfo.clientId = trim(this.state.tempSigninfo.clientId);
        _clientInfo.locationId = this.state.tempSigninfo.locationId;
        _clientInfo.locationName = locationName;
        if(create) {
            _clientInfo = Helper.cleanClientManagementModal(_clientInfo);
        }
        return _clientInfo;
    }

    prepareLocationMismatchCallback = (callBack : () => void) => {
        getClientInfoByClientId(this.state.tempSigninfo.clientId, `${ScreenIds.SignatureReport}${ResourceIdConstants.EditClientDetails}`, (data: IClientInfoViewModel) => {
            if(data && !isEmpty(data) && !isEmpty(data.name) && trim(data.name).length > 0){
                this.setState({
                    clientInfo: data                    
                }, () => {
                    callBack();
                });
            }
            else{
                this.onCompareClientInfo()
            }
            
        })
    }

    compareClientInfoCallback = (compareClientInfoData: IClientInfoComparisonResultViewModel, clientInfo: IClientInfoViewModel) => {
        if(compareClientInfoData.comparisonStatus == ClientInfoComparisionCode.NoDataMismatch){
            this.props.updateDocumentClientInfo(this.state.editClientInfoState.model.signingId, this.state.tempSigninfo,SignatureReportPaths.UpdateDocumentSigningInfo ,this.state.clientInfoMismatchModalState.clientInfoMismatchCallback);
        }
        else if(compareClientInfoData.comparisonStatus == ClientInfoComparisionCode.NewClient){
            this.newClientCallback(clientInfo);
        }
        else {
            this.clientDataMisMatchCallback(compareClientInfoData, clientInfo);
        }
    }

    setClientInfoComparisonResult = (clientInfoComparisonResult: IClientInfoComparisonResultViewModel) => {
        this.setState({ clientInfoComparisonResult }, () => {
            this.setState({ 
                editClientInfoState: {
                    ...this.state.editClientInfoState,
                    show: false
                },
                clientInfoMismatchModalState: { 
                    ...this.state.clientInfoMismatchModalState,
                    showModal: true, 
                    isLoading: false,
                    clientBasicInfoId: 0,
                } 
            });
        });
    }

    newClientCallback = (clientInfo: IClientInfoViewModel) => {
        this.setClientInfoComparisonResult(setNewClientInfoComparisonResult(clientInfo));
    }

    onCompareClientInfo = async () => {
        this.setState({compareClientInfoProgress: true}, async () => {
            await CompareClientInfo(this.getUpdatedClientInfo(), `${ScreenIds.SignatureReport}${ResourceIdConstants.EditClientDetails}`, this.compareClientInfoCallback)
        })
    }

    clientDataMisMatchCallback = (compareClientInfoData:IClientInfoComparisonResultViewModel, clientInfo: IClientInfoViewModel) => {
        getClientInfoByClientId(clientInfo.clientId, `${ScreenIds.SignatureReport}${ResourceIdConstants.EditClientDetails}`, (data: IClientInfoViewModel) => {
            this.setState({clientInfoComparisonResult: compareClientInfoData,
                clientInfo: data,
                editClientInfoState: {
                    ...this.state.editClientInfoState,
                    show: false
                },
                clientInfoMismatchModalState: { 
                    ...this.state.clientInfoMismatchModalState,
                    showModal: true, 
                    isLoading: false,
                    clientBasicInfoId: data.clientBasicInfoId
                }
            })
        })
    }

    private checkClientManagement = (signingInfo : ISigningInfo, callBack : () => void) => {
        if(this.isHaveOfficeLocationAccess(signingInfo)){
            this.setState({ tempSigninfo: {...signingInfo},clientInfoMismatchModalState: {...this.state.clientInfoMismatchModalState,isLoading: true,
                clientInfoMismatchCallback : callBack} },() => {
                if(Helper.isNullOrEmpty(signingInfo?.clientId)){
                    this.props.updateDocumentClientInfo(this.state.editClientInfoState.model.signingId, this.state.tempSigninfo,SignatureReportPaths.UpdateDocumentSigningInfo ,callBack);
                }
                else{
                    this.prepareLocationMismatchCallback(async () => { await this.onCompareClientInfo(); });
                }
            });
        }
        else{
            this.setState({
                locationMismatchModalState: {...this.state.locationMismatchModalState, isModalOpen: true, locationMissmatchCallback : 
                    () => this.props.updateDocumentClientInfo(this.state.editClientInfoState.model.signingId, this.state.tempSigninfo,SignatureReportPaths.UpdateDocumentSigningInfo ,callBack)
                },
                tempSigninfo : {...signingInfo}        
            });
        }
    }

    private locationMismatchModalClose = () => {
        this.setState({
            locationMismatchModalState: {...initialLocationMismatchModalState},
            tempSigninfo: initialSigningInfo
        });
    };

    private locationMismatchModalContinue = () => {
        if(Helper.isNullOrEmpty(this.state.tempSigninfo?.clientId)){
            this.setState( {clientInfoMismatchModalState: {...this.state.clientInfoMismatchModalState,isLoading: true}},()=>{
                this.state.locationMismatchModalState.locationMissmatchCallback();
                this.locationMismatchModalClose();
            });
            return;
        }
        this.setState({ clientInfoMismatchModalState: {...this.state.clientInfoMismatchModalState, isLoading: true,
            clientInfoMismatchCallback : this.state.locationMismatchModalState.locationMissmatchCallback}, 
            locationMismatchModalState: {...this.state.locationMismatchModalState, isModalOpen: false}},
            () => {
                this.prepareLocationMismatchCallback(async () => {
                    await this.onCompareClientInfo();
                });
            }
        );
    };

    private clientInfoMismatchModalClose = (canUpdate?:boolean) => {
        if(canUpdate){
            this.props.updateDocumentClientInfo(this.state.editClientInfoState.model.signingId, this.state.tempSigninfo,SignatureReportPaths.UpdateDocumentSigningInfo ,this.state.clientInfoMismatchModalState.clientInfoMismatchCallback);
        }
        else{
            this.setState({
                clientInfoMismatchModalState: {...initialClientInfoMismatchModalState},
                tempSigninfo: {...initialSigningInfo},
                editClientInfoState: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            });
        }
    }

    isHaveOfficeLocationAccess = (signingInfo : ISigningInfo) => {
        return this.props.userOfficeLocationState.userLocation.find(i => i.locationId == signingInfo?.locationId) != undefined;
    }

    onAddClientInfo = () => {
        this.setState({clientInfoMismatchModalState : {...this.state.clientInfoMismatchModalState, isLoading: true}}, () => {
            addClientInfo(this.getUpdatedClientInfo(true), `${ScreenIds.SignatureReport}${ResourceIdConstants.EditClientDetails}`, () => {
                this.props.updateDocumentClientInfo(this.state.editClientInfoState.model.signingId, this.state.tempSigninfo,SignatureReportPaths.UpdateDocumentSigningInfo ,this.state.clientInfoMismatchModalState.clientInfoMismatchCallback);
            });
        });
    }

    onEditClientInfo = () => {
        this.setState({clientInfoMismatchModalState : {...this.state.clientInfoMismatchModalState, isLoading: true}}, () => {
            editClientInfo(this.getUpdatedClientInfo(), `${ScreenIds.SignatureReport}${ResourceIdConstants.EditClientDetails}`, () => {
                this.props.updateDocumentClientInfo(this.state.editClientInfoState.model.signingId, this.state.tempSigninfo,SignatureReportPaths.UpdateDocumentSigningInfo ,this.state.clientInfoMismatchModalState.clientInfoMismatchCallback);
            })
        });
    }
    //====================Change Office Location Ends Here===============================


    public render() {
        let documentNoteMessage = this.state.documentNoteState.model;
        const btnType = (documentNoteMessage != undefined && documentNoteMessage.documentNote) ? 'Update' : 'Save';
        let _defaultFilter = this.props.signatureFlowReportFilter.signatureReportFilter.find(x => x.isDefault == 1);
        let defaultFilter = !this.props.DeliveredReportDocuments.isLoading && 
                                    !this.props.signatureFlowReportFilter.isLoading && _defaultFilter ? _defaultFilter.name : undefined;

        return <div className='user-assignment-content signatureflow-report'>            
            {

                <div>
                    <SignatureFlowReportsHeader
                        screenId={ScreenIds.SignatureReport}
                        pageTitle={this.props.pageTitle}
                        onSetAccessOpen={this.onSetAccessOpen}
                        onDownloadDocuments={this.onDownloadReturnOpen}
                        onArchiveDocuments={this.onArchiveReturnsOpen}
                        onDeleteDocuments={this.onDeleteDocumentsOpen}
                        onSendReminder={this.onSendReminder}
                        onRetoreDocumentsOpen={this.onRetoreDocumentsOpen}
                        selectedDocumentCount={this.selectedDocumentIds().length}
                        isBulkSelectionEnabled={this.state.isBulkSelectionEnabled}
                        onChangeOfficeLocationOpen={this.onChangeOfficeLocationOpen}
                    />
                    <br />

                    <SignatureFlowReportTable
                        screenId={ScreenIds.SignatureReport}
                        ref='SignatureFlowReportTable'
                        pageNo={this.state.page}
                        totalRows={this.props.DeliveredReportDocuments.totalRowCount}
                        pageSize={this.state.pageSize}
                        onSortChange={this.onSortChange}
                        onSearchChange={this.onSearchChange}
                        onFilterChange={this.onFilterChange}
                        onExportToExcel={this.onExportToExcel}
                        onFilterNameChange={this.onFilterNameChange}
                        onFilterSave={this.onFilterSave}
                        onFilterUpdate={this.onFilterUpdate}
                        onFilterDelete={this.onFilterDelete}
                        filterList={this.props.signatureFlowReportFilter.signatureReportFilter}
                        currentFilter={this.state.filter}
                        onSetDefaultFilter={this.onSetDefaultFilter}
                        onRemoveDefaultFilter={this.onRemoveDefaultFilter}
                        saveFilterShow={this.state.saveFilterShow}
                        onSaveFilterHide={this.onSaveFilterHide}
                        onSaveFilterShow={this.onSaveFilterShow}
                        isLoading={this.props.DeliveredReportDocuments.isLoading}
                        onRowSelect={this.onRowSelect}
                        onSelectAll={this.onSelectAll}
                        selectedRows={this.state.selectedRows}
                        defaultFilter={defaultFilter}
                        loadGrid={this.loadSignatureFlowReports}
                        onSignersDetailOpen={this.onSignersDetailOpen}
                        onResendAccessLinkOpen={this.onResendAccessLinkOpen}
                        onDocumentDetailsOpen={this.onDocumentDetailsOpen}
                        onDownloadReturnOpen={this.onDownloadIndividualOpen}
                        onArchiveReturnsOpen={this.onArchiveReturnsOpen}
                        onDeleteDocumentsOpen={this.onDeleteDocumentsOpen}
                        onRetoreDocumentsOpen={this.onRetoreDocumentsOpen}
                        onSetAccessOpen={this.onSetAccessOpen}
                        onClientTrackingOpen={this.onClientTrackingOpen}
                        onReportProblemOpen={this.onReportProblemOpen}
                        onViewAccessCodeOpen={this.onViewAccessCodeOpen}
                        onDocumentNoteOpen={this.onDocumentNoteOpen}
                        onCancelDocumentOpen={this.onCancelDocumentOpen}
                        onCancelDocumentConfirmation={this.onCancelDocumentConfirmation}
                        onDeleteDocumentConfirmation={this.onDeleteDocumentConfirmation}
                        onSendReminder={this.onSendReminder}
                        onDownloadHistoryOpen={this.onDownloadHistoryOpen}
                        signatureReportsData={this.props.DeliveredReportDocuments.signatureFlowReportTableModel}
                        reportCustomColumns={this.state.customColumns}
                        saveReportCustomColumns={this.props.saveSignatureFlowReportCustomColumn}
                        userBasicProfile={this.props.profile}
                        onRecipientDetailsOpen={this.onRecipientDetailsOpen}
                        onPageReload={this.onPageReload}
                        showBulkSelectionMessage={this.state.showBulkSelectionMessage}
                        onBulkSelectionChange={this.onBulkSelectionChange}
                        onEditClientDetailsOpen={this.onEditClientDetailsOpen}
                        officeLocation={Helper.setUserOfficeLocation(this.props.userOfficeLocationState.userLocation)}
                    />
                    <br />

                        <div style={{height:'35px'}}>
                            <Pagination
                                totalCount={this.state.totalRecordsCount}
                                pageIndex={this.state.page}
                                pageSize={this.state.pageSize}
                                defaultPageSize={pageSize}
                                goToPage = {this.onPageChange}
                                sizePerPageList={SizePerPageList}
                                showSizePerPageList={true}
                                showGoToPages={true}
                                showpageCount={false}
                                ShowListAndGoto={true}
                            />
                       </div>
                    </div>
            }         
            <SignatureFlowESignDetailsModal
                show={this.state.signerDetailsState.show}
                signatureReport={this.state.signerDetailsState.model as ISignatureFlowReportDocument}
                onCancel={this.onSignersDetailCancel}
                pageTitle={this.props.pageTitle}
            />

            <SignatureFlowReportRecipientModal
                show={this.state.recipientDetailsState.show}
                signatureReport={this.state.recipientDetailsState.model as ISignatureFlowReportDocument}
                onCancel={this.onRecipientDetailsCancel}
                updateSigners={this.updateSignersEmail}
            />

            <SignatureFlowReportDocumentModal
                show={this.state.documentDetailsState.show}
                signatureReport={this.state.documentDetailsState.model as ISignatureFlowReportDocument}
                onCancel={this.onDocumentDetailsCancel}
            />

            <SignatureFlowReportSetAccessModal
                showEditAccess={this.state.setAccessState.show}
                onCancel={this.onSetAccessCancel}
                selectedReports={this.state.setAccessState.selected as ISignatureFlowReportDocument[]}
                count={this.state.selectedRows.length}
                users={this.props.userStore.users}
                onApplyAccess={this.onSetAccessSave}
                setAccessHeaderTextDisplay={this.state.setAccessHeaderTextDisplay}
            />

            <SignatureFlowReportDocumentNoteModal
                showState={this.state.documentNoteState.show}
                onHide={this.onDocumentNoteCancel}
                changeStateTinymceBody={this.onDocumentNoteChange}
                signatureReport={this.state.documentNoteState.model as ISignatureFlowReportDocument}
                updateButtonClick={this.onUpdateDocumentNote}
                deleteDocumentNote={this.deleteDocumentNoteMessage}
                buttonText={btnType}
            />

            <ResendAccessLinkModal
                show={this.state.resendAccessLinkState.show}
                model={this.state.resendAccessLinkState.model as ISignatureFlowReportDocument}
                onResendAccessLink={this.onResendAccessLink}
                onCancel={this.onResendAccessLinkCancel}
            />

            <SignatureFlowReportCancelDocument
                show={this.state.cancelDocumentState.show}
                showLoader={this.state.showLoader}
                selectedDocument={this.state.cancelDocumentState.model as ISignatureFlowReportDocument}
                onSaveCancelDocumentDescription={this.onSaveCancelDocumentDescription}
                onCancel={this.onCancelDocumentCancel}
            />

            <SignatureFlowReportAccessCodeModal
                show={this.state.accessCodeState.show}
                selectedDocument={this.state.accessCodeState.model as ISignatureFlowReportDocument}
                onGenerateOTP={this.onGenerateOTP}
                onCancel={this.onViewAccessCodeCancel}
            />

            <SignatureFlowReportClientTrackingModal
                show={this.state.clientTrackingState.show}
                onCancel={this.onClientTrackingCancel}
                selectedDocument={this.state.clientTrackingState.model as ISignatureFlowReportDocument}
            />

            <ReportProblemModal
                onCancel={this.onReportProblemCancel}
                sfModel={this.state.reportProblem.model}
                onReportProblemSave={this.onReportProblemSave}
                show={this.state.reportProblem.show}
                loggedInCPA={this.props.profile}
                companyName={this.props.companyData.companyProfile.companyInfo.companyName}
                reportProblemType={ReportProblemTypes.SignatureSpecific}
            />

            <SignatureFlowReportDownloadDocuments
                show={this.state.downloadReturnState.show}
                onCancel={this.onDownloadReturnCancel}
                downloadableDocuments={this.state.downloadReturnState.selected as IDownloadableDocuments[]}
                downloadIndividual={this.onDownloadIndividualFile}
                downloadAll={this.onDownloadReturnAll}
                showLoader={this.state.showLoader}
            />
            <DownloadTypeOption
                show={this.state.DownloadTypeOptionState.show}
                onCancel={this.onDownloadTypeCancel}
                onDownloadOptionChange={this.onDownloadOptionChange}
                selectedDownloadType={this.state.selectedDownloadType}
                onDownloadOptionSave={this.onDownloadSubmit}
                showLoader={this.state.showLoader}
            />

            <SignedDocumentDownloadHistory
                users={this.props.userStore.users}
                show={this.state.downloadHistoryState.show}
                onCancel={this.onDownloadHistoryCancel}
                selectedDocument={this.state.downloadHistoryState.model as ISignatureFlowReportDocument}
            />

            <EditClientInfoModal
                isLoading={this.state.editClientInfoState.model ? this.props.signingInfoData[this.state.editClientInfoState.model.signingId].isLoading || this.state.clientInfoMismatchModalState.isLoading : true}
                contacts={this.props.contactsStore.contacts}
                refreshPage={this.onPageReload}
                onCancel={this.onEditClientDetailsCancel}
                show={this.state.editClientInfoState.show}
                clientAuthenticationData={this.props.sfStore}
                updateRecipients={this.updateRecipients}
                getAuthenticationParameter={this.props.getAuthenticationParameter}
                questions={this.props.authenticationQuestionsData.authenticationQuestions}
                companySettings={this.props.signatureFlowSettingsData.signatureFlowSettings}
                selectedDocument={this.state.editClientInfoState.model as ISignatureFlowReportDocument}
                officeLocationList={Helper.prepareDropDownData(this.props.officeLocationState.locationDropdown)}
                checkClientManagement = {this.checkClientManagement}
                signingInfo={this.state.editClientInfoState.model ? this.props.signingInfoData[this.state.editClientInfoState.model.signingId].signingInfo : initialSigningInfo}
            />

            <SendReminderModal
                show={this.state.sendReminderState.show}
                selectedReports={this.state.sendReminderState.selected as ISignatureFlowReportDocument[]}
                onCancel={this.onCancelSendReminder}
                reminderSettings={this.getReminderSettings()}
                sendReminderNow={this.sendReminderNow}
                isLoading={this.isSendReminderModalLoading()}
                updateSigningReminderSettings={this.updateSigningReminderSettings}
                signingInfoId={this.state.sendReminderState.selected ? this.state.sendReminderState.selected[0] : 0}
                filterSignatureStatus={this.state.filterSignatureStatus ? this.state.filterSignatureStatus : "" }
                isBulkSelectionEnabled={this.state.isBulkSelectionEnabled}
                resetSelectedRowsAfterBulkOperation={this.resetSelectedRowsAfterBulkOperation}
            />

            <ChangeOfficeLocationModal
                show={this.state.changeOfficeLocationState.show}
                onCancel={this.onChangeOfficeLocationCancel}
                officeLocationList={Helper.prepareDropDownData(this.props.officeLocationState.locationDropdown)}
                onChangeOfficeLocationContinue={this.onChangeOfficeLocationContinue}
            /> 

            <LocationMismatchModal
                show={this.state.locationMismatchModalState.isModalOpen}
                onCancel={this.locationMismatchModalClose}
                onContinue={this.locationMismatchModalContinue}
                locationName={this.state.tempSigninfo.locationId == 0 ? "" : this.props.officeLocationState.locationDropdown.find((x) => x.value == this.state.tempSigninfo.locationId)?.name}
            />

            <ClientInfoMismatchModal 
                show={this.state.clientInfoMismatchModalState.showModal}
                clientInfo={this.state.clientInfo}
                clientInfoComparisonResult={this.state.clientInfoComparisonResult}
                clientInfoMismatchModalClose={this.clientInfoMismatchModalClose}
                isLoading={this.state.clientInfoMismatchModalState.isLoading}
                onAddClientInfo={this.onAddClientInfo}
                onUpdateClientInfo={this.onEditClientInfo}
            />
        </div>
    }
}
