import * as React from 'react';
import * as Bs from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { OverlayLoader } from '../../../components/helper/OverlayLoader';
import { ISignatureFlowReportDocument } from '../../../models/SignatureFlowReportState';
import { GetFormatedDateTime } from '../../../components/helper/HelperFunctions';

let moment = require('moment');

interface IDocumentModalProps {
    show: boolean;
    onCancel: () => void;
    signatureReport: ISignatureFlowReportDocument;
}
interface IColumnValues {
    documentId:number,
    documentName: string,
    documentType: string,
    expiresOn:Date
}

export class SignatureFlowReportDocumentModal extends React.Component<IDocumentModalProps, {}>{
    constructor(props: IDocumentModalProps) {
        super(props);
        this.defaultType = this.defaultType.bind(this);
    }

    defaultType(cell: any, row: any) {
        return <div title={cell} className="ellipsis">{cell}</div>;
    }
    signerDateType(cell: any, row: any) {
        if (cell === "N/A")
            return "N/A";
        else
            return cell;
    }

    public render() {

        const documentDetailsColumn = [
            {
                header: 'Document Name',
                key: 'documentName',
                isKey: false,
                dataFormat: this.defaultType,
                width: '40%',
                toolTip: true,
                className:"table-column-header"
            },
            {
                header: 'Document Type',
                key: 'documentType',
                isKey: true,
                dataFormat: this.defaultType,
                width: '30%',
                toolTip: true,
                className:"table-column-header"
            },
            {
                header: 'Expiring Date',
                key: 'expiresOn',
                isKey: false,
                dataFormat: this.signerDateType,
                width: '30%',
                toolTip: true,
                className:"table-column-header"
            }
        ];

        let documentDetails: IColumnValues[] = [];

        if (this.props.signatureReport && this.props.signatureReport.documentInfo) {
            this.props.signatureReport.documentInfo.map((value, index) => {
                documentDetails.push({
                    documentId: value.documentId,
                    documentName: value.documentName,
                    documentType: value.documentType,
                    expiresOn: value.expiresOn !== undefined ? moment(value.expiresOn).format('MM/DD/YYYY') : "N/A" 
                });
            });
        }

        let modalBody = this.props.signatureReport && this.props.signatureReport.documentInfo ?
            (
                <BootstrapTable data={documentDetails}
                    containerClass='report-table-container'
                    striped
                    bordered={false}
                    tableHeaderClass='report-header-border'>
                    {documentDetailsColumn.map((value, index) => {
                        return <TableHeaderColumn key={index} isKey={value.isKey} dataField={value.key} className={value.className ? value.className : ''} dataFormat={value.dataFormat} width={value.width} columnTitle={value.toolTip}>{value.header}</TableHeaderColumn>;
                    })}
                </BootstrapTable>) :
            (<OverlayLoader
                show={this.props.show}
                text={"Loading, please wait..."} />);

        return <Bs.Modal className="signed-Details-modal" centered={true} show={this.props.show} onHide={this.props.onCancel} backdrop="static">
            <Bs.Modal.Header closeButton onClick={this.props.onCancel} >
                <Bs.Modal.Title>
                    Document Details
                   </Bs.Modal.Title>
            </Bs.Modal.Header>
            <Bs.Form>
                <Bs.Modal.Body>
                    {modalBody}
                </Bs.Modal.Body>
            </Bs.Form>
            <Bs.Modal.Footer>
                <Bs.Button
                    variant="default"
                    className='btn-white modal-footer-button-cancel'
                    onClick={this.props.onCancel}>
                    Cancel
                </Bs.Button>
            </Bs.Modal.Footer>
        </Bs.Modal>
    }
}

export default SignatureFlowReportDocumentModal;