import { addTask } from "domain-task";
import { Action, Reducer } from "redux";
import { API_BASE_URL } from "src/utils/AppConstants";
import { AppThunkAction } from "..";

export interface PostAuthStoreState {
    isCompanySfSubscriptionEnabled: boolean;
    isCompanySignaturesProductEnabled:boolean;
    postAuthStoreLoaded: boolean;
    postAuthApiLoading: boolean;
    loggedOut: boolean;
}

const initalPostAuthStoreState: PostAuthStoreState = {
    isCompanySfSubscriptionEnabled: true,
    isCompanySignaturesProductEnabled:true,
    postAuthStoreLoaded: false,
    postAuthApiLoading: false,
    loggedOut: false
}

type KnownAction = ApiLoaderAction | RecieveDataAction | LoggedOutAction | PostAuthStoreLoader;

export interface ApiLoaderAction {
    type: "PostAuthStoreApiLoader";
    postAuthApiLoading: boolean;
}

export interface RecieveDataAction {
    type: "PostAuthStoreReceiveData";
    iscompanyProuctStatus: any;
}

export interface LoggedOutAction {
    type: "LoggedOut";
    loggedOut: boolean;
}

export interface PostAuthStoreLoader {
    type: "PostAuthStoreLoader";
}   

let authDataExists = false;

export const actionCreators = {

    requestPostAuthData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (authDataExists) {
            return;
        }
        
        dispatch({ type: "PostAuthStoreApiLoader", postAuthApiLoading: true });

        const fetchTask = fetch(`${API_BASE_URL}api/Company/CompanyProductStatus/GetCompanyProductStatus`, { credentials: 'include' })
            .then((resp) => resp.json())
            .then(data => {
                authDataExists = true;
                dispatch({ type: "PostAuthStoreReceiveData", iscompanyProuctStatus: data })
                dispatch({ type: "PostAuthStoreApiLoader", postAuthApiLoading: false });
            }).catch((error) => {
                //Temporary fix for the issue where the API giving 403 error
                dispatch({ type: "PostAuthStoreReceiveData", iscompanyProuctStatus: {isCompanySignaturesSubscriptionEnabled : true, isCompanySignaturesProductEnabled : false} })
                dispatch({ type: "PostAuthStoreApiLoader", postAuthApiLoading: false });
            }).finally(() => {
                dispatch({ type: "PostAuthStoreLoader" });
              });

        addTask(fetchTask);
    },

    loggedOut: () : AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "LoggedOut", loggedOut: true });
    }
}

export const reducer: Reducer<PostAuthStoreState> = (
    state: PostAuthStoreState = initalPostAuthStoreState,
    incomingAction: Action) => {

    const action = incomingAction as KnownAction;

    switch (action.type) {

        case "PostAuthStoreApiLoader":
            return { ...state, postAuthApiLoading: action.postAuthApiLoading }

        case "PostAuthStoreReceiveData":
            return { ...state,                 
                isCompanySfSubscriptionEnabled: action.iscompanyProuctStatus.isCompanySignaturesSubscriptionEnabled,
                isCompanySignaturesProductEnabled:action.iscompanyProuctStatus.isCompanySignaturesProductEnabled
            }
        case "PostAuthStoreLoader":
            return { ...state, postAuthStoreLoaded: true }    
        case "LoggedOut":
            return { ...state, loggedOut: action.loggedOut }

        default: return state || initalPostAuthStoreState;
    }
}