import { ApplicationState } from "../../store/types";
import { connect } from 'react-redux';
import { Notification} from './Notification'

export default connect(
    (state: ApplicationState) => ({
        notification: state.notification,
    }),
    {

    }
    
)(Notification);

