
import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../../../store';
import { API_BASE_URL } from '../../../../utils/AppConstants';
import { ISignatureFlowSettings } from '../../../models/SignatureFlowSettings';
import { handleResponse } from '../../Library';
import { NotificationAction, StatusType } from '../../../store/common/NotificationStore';
import * as Constants from '../../../components/helper/Constants';
import { actionTypes } from '../../../store/ActionTypes';
import { RequestSignatureFlowClientInstructions, ReceiveSignatureClientInstructions, 
          ReceiveSignatureFlowClientInstruction, DeleteSignatureFlowClientInstruction, 
          ResetSignatureFlowClientInstructionsData, ClientInstructionsActionTypes,
          ClientInstructionType} from './ClientInstructionsActionTypes';
          
import { validateError } from '../../../components/helper/Validations';
import { IClientInstrucionsDictionary, IInstruction } from './ClientInstructionsActionTypes';
import * as SignatureFlowSettingsStore from '../../SignatureFlowSettingsStore';


import { logger } from '../../../../routes/LoggedIn';

type DispatchAction = RequestSignatureFlowClientInstructions |
    ReceiveSignatureClientInstructions |
    ReceiveSignatureFlowClientInstruction |
    DeleteSignatureFlowClientInstruction |
    ResetSignatureFlowClientInstructionsData |
    NotificationAction;

export const actionCreators = {

    requestCLientInstructions: (resourceId: string): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/ClientInstructions/GetClientInstructions', {
            method: 'GET',
            credentials: 'include',
            headers: { [Constants.HeaderResourceId]: resourceId }
        })
            .then(handleResponse)
            .then(json => json as Promise<IInstruction[]>)
            .then(data => {
                dispatch({ type: ClientInstructionsActionTypes.RECEIVE_SIGNATURE_FLOW_CLIENT_INSTRUCTIONS, clientInstructions: data });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanySettingsError,
                    statusType: StatusType.Error, statusCode:error?.status
                })
            });
        addTask(fetchTask);

        dispatch({ type: ClientInstructionsActionTypes.REQUEST_SIGNATURE_FLOW_CLIENT_INSTRUCTIONS });
    },

    saveInstructionDetail: (instruction: IInstruction, signatureFlowSettings: ISignatureFlowSettings, isSetDefault: boolean): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/ClientInstructions/', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(instruction),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((newId) => {
                let instructionDetail = Object.assign({}, instruction);
                instructionDetail.id = newId 
                if (isSetDefault) { 
                    const Keyvalue= Object.keys(ClientInstructionType).find(key => ClientInstructionType[key] === instruction.instructionType) ;
                    signatureFlowSettings.clientInstructionSettings['default'+ Keyvalue] = newId;
                    let action: any = SignatureFlowSettingsStore.actionCreators.updateCompanySettings(signatureFlowSettings);
                    dispatch(action);
                    dispatch({
                        type: ClientInstructionsActionTypes.RECEIVE_SIGNATURE_FLOW_CLIENT_INSTRUCTION, instruction: instructionDetail, instructionId: newId
                    });
                }
                else {
                    dispatch({
                        type: ClientInstructionsActionTypes.RECEIVE_SIGNATURE_FLOW_CLIENT_INSTRUCTION, instruction: instructionDetail, instructionId: newId
                    });
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionsConstants.SaveInstructionSuccess, statusType: StatusType.Success
                });
            }).catch(error => {
                const errorMessage = validateError(error);
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: errorMessage, statusType: StatusType.Error, statusCode:error?.status
                });
                logger.trackError(`saveInstructionDetail failed for the request having parameters ${JSON.stringify({ message: instruction, signatureFlowSettings: signatureFlowSettings })} with error ${errorMessage}`)
            });
        addTask(fetchTask);
    },

    updateInstructionDetail: (instruction: IInstruction): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/ClientInstructions/', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(instruction),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: ClientInstructionsActionTypes.RECEIVE_SIGNATURE_FLOW_CLIENT_INSTRUCTION, instruction: instruction, instructionId: instruction.id
                });
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionsConstants.UpdateInstructionSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                const errorMessage = validateError(error);
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: errorMessage, statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`updateInstructionDetail failed for the request having parameters ${JSON.stringify(instruction)} with error ${errorMessage}`)
            });
        addTask(fetchTask);
    },

    deleteInstructionDetail: (instruction: IInstruction): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/ClientInstructions/', {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(instruction),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: ClientInstructionsActionTypes.DELETE_SIGNATURE_FLOW_CLIENT_INSTRUCTION, instruction: instruction
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ClientInstructionsConstants.DeleteInstructionSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                const errorMessage = validateError(error);
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`deleteInstructionDetail failed for the request having parameters ${JSON.stringify(instruction)} with error ${errorMessage}`)
            });
        addTask(fetchTask);
    },

    resetSignatureFlowClientInstructionData: (): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        dispatch({ type: ClientInstructionsActionTypes.RESET_SIGNATURE_FLOW_CLIENT_INSTRUCTIONS_DATA })
    },
};

export interface IClientInstrutionsData {
    clientInstructions: IClientInstrucionsDictionary;
    isLoading: boolean;
}

const ClientInstructionsDic: IClientInstrucionsDictionary = [];

const unloadedState: IClientInstrutionsData = {
    clientInstructions: { ...ClientInstructionsDic },
    isLoading: false,
} as IClientInstrutionsData;

export const reducer: Reducer<IClientInstrutionsData> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case ClientInstructionsActionTypes.REQUEST_SIGNATURE_FLOW_CLIENT_INSTRUCTIONS:
            var received = { ...state };
            received.isLoading = true;
            return received;
        case ClientInstructionsActionTypes.RECEIVE_SIGNATURE_FLOW_CLIENT_INSTRUCTION:
            var received = { ...state };
            if (!received.clientInstructions || !Array.isArray(received.clientInstructions)) {
                received.clientInstructions = [];
            }
            received.clientInstructions[action.instructionId] = { InstructionDetails: action.instruction };
            received.isLoading = false;
            return received;
        case ClientInstructionsActionTypes.RECEIVE_SIGNATURE_FLOW_CLIENT_INSTRUCTIONS:
            var received = { ...state };
            if (!received.clientInstructions || !Array.isArray(received.clientInstructions)) {
                received.clientInstructions = [];
            }
            action.clientInstructions.forEach((item, i) => {
                received.clientInstructions[item.id] = { InstructionDetails: item };
            });
            received.isLoading = false;
            return received;
        case ClientInstructionsActionTypes.DELETE_SIGNATURE_FLOW_CLIENT_INSTRUCTION:
            var received = { ...state }
            if (received.clientInstructions[action.instruction.id]) {
                delete received.clientInstructions[action.instruction.id];
                received.isLoading = false;
            }
            return received;
        
        case ClientInstructionsActionTypes.RESET_SIGNATURE_FLOW_CLIENT_INSTRUCTIONS_DATA:
            return { ...unloadedState } as IClientInstrutionsData;
    }

    return state;
};
