import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { API_BASE_URL } from '../../../utils/AppConstants';
import { AppThunkAction } from '..';
import { ISignatureFlowReportFilter } from '../../models/SignatureFlowReportState';
import { actionTypes } from '../ActionTypes';
import { handleResponse } from '../Library';
import * as Notification from '../common/NotificationStore';
import { SignatureFlowReportFilterConstants } from '../../components/helper/Constants';

import { logger } from '../../../routes/LoggedIn';
import { ReportTypes } from 'src/SignatureFlow/models/CustomColumn';

export interface IFilterState {
    signatureReportFilter: ISignatureFlowReportFilter[],
    archivedReportsFilter: ISignatureFlowReportFilter[],
    draftReportFilter: ISignatureFlowReportFilter[],
    lockedReportFilter: ISignatureFlowReportFilter[],
    isLoading: boolean
}

interface ResetSignatureFlowReportFilterAction {
    type: actionTypes.RESET_SIGNATURE_FLOW_REPORT_FILTER;
}

interface RequestSignatureFlowReportFilterAction {
    type: actionTypes.REQUEST_SIGNATURE_FLOW_REPORT_FILTER;
} 

interface ReceiveSignatureFlowReportFilterAction {
    type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_FILTER;
    filters: ISignatureFlowReportFilter[];
    report: ReportTypes
}

interface SaveSignatureFlowReportFilterAction {
    type: actionTypes.SAVE_SIGNATURE_FLOW_REPORT_FILTER;
    filter: ISignatureFlowReportFilter;
    report: ReportTypes;
}

interface UpdateSignatureFlowReportFilterAction {
    type: actionTypes.UPDATE_SIGNATURE_FLOW_REPORT_FILTER;
    filter: ISignatureFlowReportFilter;
    report: ReportTypes;
}

interface DeleteSignatureFlowReportFilterAction {
    type: actionTypes.DELETE_SIGNATURE_FLOW_REPORT_FILTER;
    name: string;
    report: ReportTypes;
}

type KnownAction = ResetSignatureFlowReportFilterAction | ReceiveSignatureFlowReportFilterAction | SaveSignatureFlowReportFilterAction |
    UpdateSignatureFlowReportFilterAction | DeleteSignatureFlowReportFilterAction | Notification.NotificationAction | RequestSignatureFlowReportFilterAction;

type DispatchActions = ResetSignatureFlowReportFilterAction | ReceiveSignatureFlowReportFilterAction | UpdateSignatureFlowReportFilterAction
    | SaveSignatureFlowReportFilterAction | DeleteSignatureFlowReportFilterAction | RequestSignatureFlowReportFilterAction;

export const actionCreators = {

    requestSignatureFlowReportFilter: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({type: actionTypes.REQUEST_SIGNATURE_FLOW_REPORT_FILTER})
        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReportFilter/', {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<ISignatureFlowReportFilter[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_FILTER, filters: data, report: ReportTypes.Signature });
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: Notification.StatusType.Error,
                    statusCode:error?.status,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.GetAllErrorMessage
                });
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.RESET_SIGNATURE_FLOW_REPORT_FILTER });
    },

    saveSignatureFlowReportFilter: (name: string, filter: ISignatureFlowReportFilter, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let options: any = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(filter)
        };

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReportFilter/' + name, options)
            .then(handleResponse)
            .then(() => {
                filter.name = name;
                dispatch({ type: actionTypes.SAVE_SIGNATURE_FLOW_REPORT_FILTER, filter: filter, report: ReportTypes.Signature });
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Success, statusMessage: SignatureFlowReportFilterConstants.StatusMessage.SavedSuccessMessage });
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: Notification.StatusType.Error,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.SavedErrorMessage,
                    statusCode:error?.status
                });

                logger.trackError(`saveSignatureFlowReportFilter failed for the request having parameters ${JSON.stringify(filter)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    updateSignatureFlowReportFilter: (name: string, filter: ISignatureFlowReportFilter, callback?: (name: string) => void, showLoader?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(filter)
        };

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReportFilter/' + name, options)
            .then(handleResponse)
            .then(() => {
                filter.name = name;
                dispatch({ type: actionTypes.UPDATE_SIGNATURE_FLOW_REPORT_FILTER, filter: filter, report: ReportTypes.Signature });
                if (showLoader) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: Notification.StatusType.Success,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.UpdateSuccessMessage
                    });
                }
                if (callback) {
                    callback(name);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Error, statusMessage: SignatureFlowReportFilterConstants.StatusMessage.UpdateErrorMessage, statusCode:error?.status });

                logger.trackError(`updateSignatureFlowReportFilter failed for the request having parameters ${JSON.stringify(filter)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    removeSignatureFlowReportFilter: (name: string, callback?: (name: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReportFilter/' + name, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.DELETE_SIGNATURE_FLOW_REPORT_FILTER, name: name, report: ReportTypes.Signature });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: Notification.StatusType.Success,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.DeleteSuccessMessage
                });
                if (callback) {
                    callback(name);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Error, statusMessage: SignatureFlowReportFilterConstants.StatusMessage.DeleteErrorMessage, statusCode:error?.status });

                logger.trackError(`removeSignatureFlowReportFilter failed for the request having parameters ${name} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    requestSignatureArchiveReportFilter: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({type: actionTypes.REQUEST_SIGNATURE_FLOW_REPORT_FILTER})
        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureArchiveReportFilter/', {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<ISignatureFlowReportFilter[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_FILTER, filters: data, report: ReportTypes.Archived });
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: Notification.StatusType.Error,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.GetAllErrorMessage, statusCode:error?.status
                });
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.RESET_SIGNATURE_FLOW_REPORT_FILTER });
    },

    saveSignatureArchiveReportFilter: (name: string, filter: ISignatureFlowReportFilter, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let options: any = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(filter)
        };

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureArchiveReportFilter/' + name, options)
            .then(handleResponse)
            .then(() => {
                filter.name = name;
                dispatch({ type: actionTypes.SAVE_SIGNATURE_FLOW_REPORT_FILTER, filter: filter, report: ReportTypes.Archived });
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Success, statusMessage: SignatureFlowReportFilterConstants.StatusMessage.SavedSuccessMessage });
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: Notification.StatusType.Error,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.SavedErrorMessage, statusCode:error?.status
                });

                logger.trackError(`saveSignatureArchiveReportFilter failed for the request having parameters ${JSON.stringify(filter)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    updateSignatureArchiveReportFilter: (name: string, filter: ISignatureFlowReportFilter, callback?: (name: string) => void, showLoader?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(filter)
        };

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureArchiveReportFilter/' + name, options)
            .then(handleResponse)
            .then(() => {
                filter.name = name;
                dispatch({ type: actionTypes.UPDATE_SIGNATURE_FLOW_REPORT_FILTER, filter: filter, report: ReportTypes.Archived });
                if (showLoader) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusType: Notification.StatusType.Success,
                        statusMessage: SignatureFlowReportFilterConstants.StatusMessage.UpdateSuccessMessage
                    });
                }
                if (callback) {
                    callback(name);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Error, statusMessage: SignatureFlowReportFilterConstants.StatusMessage.UpdateErrorMessage, statusCode:error?.status });
                logger.trackError(`updateSignatureArchiveReportFilter failed for the request having parameters ${JSON.stringify(filter)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    removeSignatureArchiveReportFilter: (name: string, callback?: (name: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureArchiveReportFilter/' + name, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.DELETE_SIGNATURE_FLOW_REPORT_FILTER, name: name, report: ReportTypes.Archived });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: Notification.StatusType.Success,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.DeleteSuccessMessage
                });
                if (callback) {
                    callback(name);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Error, statusMessage: SignatureFlowReportFilterConstants.StatusMessage.DeleteErrorMessage, statusCode:error?.status });

                logger.trackError(`removeSignatureArchiveReportFilter failed for the request having parameters ${name} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    requestSignatureDraftReportFilter: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({type: actionTypes.REQUEST_SIGNATURE_FLOW_REPORT_FILTER})
        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureDraftReportFilter/', {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<ISignatureFlowReportFilter[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_FILTER, filters: data, report: ReportTypes.Draft });
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: Notification.StatusType.Error,
                    statusCode:error?.status,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.GetAllErrorMessage
                });
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.RESET_SIGNATURE_FLOW_REPORT_FILTER });
    },

    saveSignatureDraftReportFilter: (name: string, filter: ISignatureFlowReportFilter, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let options: any = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(filter)
        };

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureDraftReportFilter/' + name, options)
            .then(handleResponse)
            .then(() => {
                filter.name = name;
                dispatch({ type: actionTypes.SAVE_SIGNATURE_FLOW_REPORT_FILTER, filter: filter, report: ReportTypes.Draft });
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Success, statusMessage: SignatureFlowReportFilterConstants.StatusMessage.SavedSuccessMessage });
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: Notification.StatusType.Error,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.SavedErrorMessage,
                    statusCode:error?.status
                });

                logger.trackError(`saveSignatureDraftReportFilter failed for the request having parameters ${JSON.stringify(filter)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    updateSignatureDraftReportFilter: (name: string, filter: ISignatureFlowReportFilter, callback?: (name: string) => void, showLoader?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(filter)
        };

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureDraftReportFilter/' + name, options)
            .then(handleResponse)
            .then(() => {
                filter.name = name;
                dispatch({ type: actionTypes.UPDATE_SIGNATURE_FLOW_REPORT_FILTER, filter: filter, report: ReportTypes.Draft });
                if (showLoader) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: Notification.StatusType.Success,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.UpdateSuccessMessage
                    });
                }
                if (callback) {
                    callback(name);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Error, statusMessage: SignatureFlowReportFilterConstants.StatusMessage.UpdateErrorMessage, statusCode:error?.status });

                logger.trackError(`updateSignatureDraftReportFilter failed for the request having parameters ${JSON.stringify(filter)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    removeSignatureDraftReportFilter: (name: string, callback?: (name: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureDraftReportFilter/' + name, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.DELETE_SIGNATURE_FLOW_REPORT_FILTER, name: name, report: ReportTypes.Draft });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: Notification.StatusType.Success,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.DeleteSuccessMessage
                });
                if (callback) {
                    callback(name);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Error, statusMessage: SignatureFlowReportFilterConstants.StatusMessage.DeleteErrorMessage, statusCode:error?.status });

                logger.trackError(`removeSignatureDraftReportFilter failed for the request having parameters ${name} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    requestSignatureLockedReportFilter: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({type: actionTypes.REQUEST_SIGNATURE_FLOW_REPORT_FILTER})
        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureLockedReportFilter/', {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<ISignatureFlowReportFilter[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_FILTER, filters: data, report: ReportTypes.Locked });
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: Notification.StatusType.Error,
                    statusCode:error?.status,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.GetAllErrorMessage
                });
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.RESET_SIGNATURE_FLOW_REPORT_FILTER });
    },

    saveSignatureLockedReportFilter: (name: string, filter: ISignatureFlowReportFilter, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let options: any = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(filter)
        };

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureLockedReportFilter/' + name, options)
            .then(handleResponse)
            .then(() => {
                filter.name = name;
                dispatch({ type: actionTypes.SAVE_SIGNATURE_FLOW_REPORT_FILTER, filter: filter, report: ReportTypes.Locked });
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Success, statusMessage: SignatureFlowReportFilterConstants.StatusMessage.SavedSuccessMessage });
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: Notification.StatusType.Error,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.SavedErrorMessage,
                    statusCode:error?.status
                });

                logger.trackError(`saveSignatureLockedReportFilter failed for the request having parameters ${JSON.stringify(filter)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    updateSignatureLockedReportFilter: (name: string, filter: ISignatureFlowReportFilter, callback?: (name: string) => void, showLoader?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(filter)
        };

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureLockedReportFilter/' + name, options)
            .then(handleResponse)
            .then(() => {
                filter.name = name;
                dispatch({ type: actionTypes.UPDATE_SIGNATURE_FLOW_REPORT_FILTER, filter: filter, report: ReportTypes.Locked });
                if (showLoader) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: Notification.StatusType.Success,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.UpdateSuccessMessage
                    });
                }
                if (callback) {
                    callback(name);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Error, statusMessage: SignatureFlowReportFilterConstants.StatusMessage.UpdateErrorMessage, statusCode:error?.status });

                logger.trackError(`updateSignatureLockedReportFilter failed for the request having parameters ${JSON.stringify(filter)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    removeSignatureLockedReportFilter: (name: string, callback?: (name: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureLockedReportFilter/' + name, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.DELETE_SIGNATURE_FLOW_REPORT_FILTER, name: name, report: ReportTypes.Locked });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: Notification.StatusType.Success,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.DeleteSuccessMessage
                });
                if (callback) {
                    callback(name);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Error, statusMessage: SignatureFlowReportFilterConstants.StatusMessage.DeleteErrorMessage, statusCode:error?.status });

                logger.trackError(`removeSignatureLockedReportFilter failed for the request having parameters ${name} with error ${error.message}`)
            });
        addTask(fetchTask);
    }
};

const unloadstate: IFilterState = { 
    signatureReportFilter: [],
    archivedReportsFilter: [],
    draftReportFilter: [],
    lockedReportFilter: [],
    isLoading: false 
};

export const reducer: Reducer<IFilterState> = (state = unloadstate, incomingAction) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.REQUEST_SIGNATURE_FLOW_REPORT_FILTER:
            return{...unloadstate, isLoading: true};

        case actionTypes.RESET_SIGNATURE_FLOW_REPORT_FILTER:
            return {
                ...state,
                signatureReportFilter: [],
                archivedReportsFilter: [],
                draftReportFilter: [],
                lockedReportFilter: [],
            };

        case actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_FILTER:
            var newState = { ...state };
            switch(action.report){
                case ReportTypes.Signature:
                    newState.signatureReportFilter = action.filters;
                    break;
                case ReportTypes.Archived:
                    newState.archivedReportsFilter = action.filters;
                    break;
                case ReportTypes.Draft:
                    newState.draftReportFilter = action.filters;
                    break;
                case ReportTypes.Locked:
                    newState.lockedReportFilter = action.filters;
                    break;
            }
            return {...newState, isLoading: false};

        case actionTypes.SAVE_SIGNATURE_FLOW_REPORT_FILTER:
            var newState = { ...state };
            switch(action.report){
                case ReportTypes.Signature:
                    newState.signatureReportFilter = [...newState.signatureReportFilter, action.filter];
                    break;
                case ReportTypes.Archived:
                    newState.archivedReportsFilter = [...newState.archivedReportsFilter, action.filter];
                    break;
                case ReportTypes.Draft:
                    newState.draftReportFilter = [...newState.draftReportFilter, action.filter];
                    break;
                case ReportTypes.Locked:
                    newState.lockedReportFilter = [...newState.lockedReportFilter, action.filter];
                    break;
            }
            return {...newState, isLoading: false};

        case actionTypes.UPDATE_SIGNATURE_FLOW_REPORT_FILTER:
            var newState = { ...state };
            switch(action.report){
                case ReportTypes.Signature:
                    newState.signatureReportFilter = newState.signatureReportFilter.map((item) => {
                        return item.name == action.filter.name ? action.filter : item;
                    });
                    break;
                case ReportTypes.Archived:
                    newState.archivedReportsFilter = newState.archivedReportsFilter.map((item) => {
                        return item.name == action.filter.name ? action.filter : item;
                    });
                    break;
                case ReportTypes.Draft:
                    newState.draftReportFilter = newState.draftReportFilter.map((item) => {
                        return item.name == action.filter.name ? action.filter : item;
                    });
                    break;
                case ReportTypes.Locked:
                    newState.lockedReportFilter = newState.lockedReportFilter.map((item) => {
                        return item.name == action.filter.name ? action.filter : item;
                    });
                    break;
            }
            return {...newState, isLoading: false};

        case actionTypes.DELETE_SIGNATURE_FLOW_REPORT_FILTER:
            var newState = { ...state };
            switch(action.report){
                case ReportTypes.Signature:
                    newState.signatureReportFilter = newState.signatureReportFilter.filter((item) => item.name !== action.name);
                    break;
                case ReportTypes.Archived:
                    newState.archivedReportsFilter = newState.archivedReportsFilter.filter((item) => item.name !== action.name);
                    break;
                case ReportTypes.Draft:
                    newState.draftReportFilter = newState.draftReportFilter.filter((item) => item.name !== action.name);
                    break;
                case ReportTypes.Locked:
                    newState.lockedReportFilter = newState.lockedReportFilter.filter((item) => item.name !== action.name);
                    break;
            }
            return {...newState, isLoading: false};

        default:
            return state;
    }
};