import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { API_BASE_URL } from '../../utils/AppConstants';
import { AppThunkAction } from '../store';
import { actionTypes } from '../store/ActionTypes';
import { handleResponse } from './Library';
import { IReminderSettings } from '../models/SignatureFlowSettings';
import { initialSigningInfoSettings, ISigningInfoSettings } from '../models/SigningInfoSettings';
import { StatusType, NotificationAction } from './common/NotificationStore';
import { HeaderResourceId } from '../components/helper/Constants';


export interface LoadingSigningInfoSettingsAction {
    type: actionTypes.LOADING_SIGNING_INFO_SETTINGS;
    isLoading: boolean;
    message?: string;
}

export interface RequestSigningInfoSettingsAction {
    type: actionTypes.REQUEST_SIGNING_INFO_SETTINGS;
    id: number;
    message: string;
}

export interface ReceiveSigningInfoSettingsAction {
    type: actionTypes.RECEIVE_SIGNING_INFO_SETTINGS;
    settings?: ISigningInfoSettings;
}

export interface ReceiveReminderSettingsAction {
    type: actionTypes.RECEIVE_SIGNING_INFO_REMINDER_SETTINGS;
    id: number;
    settings: IReminderSettings;
}

export interface ReceiveMultipleReminderSettingsAction {
    type: actionTypes.RECEIVE_MULTIPLE_SIGNING_INFO_REMINDER_SETTINGS;
    ids: number[];
    settings: IReminderSettings;
}


export interface ErrorSigningInfoSettingsAction {
    type: actionTypes.ERROR_SIGNING_INFO_SETTINGS;
    id: number;
    reason: string;
}
export interface ResetSigningInfoSettingsAction {
    type: actionTypes.RESET_SIGNING_INFO_SETTINGS;
}

type DispatchAction = LoadingSigningInfoSettingsAction | RequestSigningInfoSettingsAction | ReceiveSigningInfoSettingsAction
    | ReceiveReminderSettingsAction | ReceiveMultipleReminderSettingsAction | ErrorSigningInfoSettingsAction | ResetSigningInfoSettingsAction;
type KnownAction = DispatchAction | NotificationAction;

export interface ISigningInfoSettingDictionary {
    [index: number]: {
        signingInfoSettings: ISigningInfoSettings,
        isLoading: boolean,
        error: boolean,
        message: string
    };

    isLoading: boolean;
    message: string;
}

export const actionCreators = {

    getSigningInfoSettings: (signingInfoId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SigningInfoSettings?signingInfoId=' + signingInfoId,
            {
                method: 'GET',
                credentials: 'include'
            }
        )
            .then(handleResponse)
            .then(json => json as Promise<ISigningInfoSettings>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_SIGNING_INFO_SETTINGS, settings: data });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: 'Error occured while fetching document settings',
                    statusType: StatusType.Error, statusCode:error?.status
                });

                dispatch({
                    type: actionTypes.ERROR_SIGNING_INFO_SETTINGS,
                    id: signingInfoId,
                    reason: error.message,
                });
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_SIGNING_INFO_SETTINGS, id: signingInfoId, message: '' });
    },

    getReminderSettings: (signingInfoId: number, resourceId : string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SigningInfoSettings/GetReminderSettings?signingInfoId=' + signingInfoId,
            {
                method: 'GET',
                credentials: 'include'
                , headers: { [HeaderResourceId]: resourceId }
            }
        )
            .then(handleResponse)
            .then(json => json as Promise<IReminderSettings>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_SIGNING_INFO_REMINDER_SETTINGS, id: signingInfoId, settings: data });
                if (callback) callback();
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.ERROR_SIGNING_INFO_SETTINGS,
                    id: signingInfoId,
                    reason: error.message,
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: StatusType.Error, statusCode:error?.status,
                    statusMessage: 'Error occured while fetching reminder settings'
                });
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_SIGNING_INFO_SETTINGS, id: signingInfoId, message: '' });
    },

    updateSigningReminderSettings: (signingInfoIds: number[], newSettings: IReminderSettings, urlPath: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const viewModel = {
            signingInfoIds: signingInfoIds,
            newSettings: newSettings
        };
        const fetchTask = fetch(urlPath,
            {
                method: 'PUT',
                credentials: 'include',
                body: JSON.stringify(viewModel),
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                }
            }
        )
            .then(handleResponse)
            .then(json => json as Promise<IReminderSettings>)
            .then(data => {
                if (callback) callback();
                dispatch({ type: actionTypes.RECEIVE_MULTIPLE_SIGNING_INFO_REMINDER_SETTINGS, ids: signingInfoIds, settings: newSettings });
            })
            .catch(error => {
                dispatch({ type: actionTypes.RECEIVE_SIGNING_INFO_SETTINGS });

                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: StatusType.Error, statusCode:error?.status,
                    statusMessage: 'Error occured while scheduling automated reminder'
                });
                if (callback) callback();
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.LOADING_SIGNING_INFO_SETTINGS, isLoading: true });
    },

    isSigningReminderEnabled: (signingInfoIds: number[], callback: (ids: number[], result: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SigningInfoSettings/IsSigningReminderEnabled',
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                },
                body: JSON.stringify(signingInfoIds)
            }
        )
            .then(handleResponse)
            .then(json => json as Promise<boolean>)
            .then(data => {
                dispatch({ type: actionTypes.LOADING_SIGNING_INFO_SETTINGS, isLoading: false });
                if (callback) callback(signingInfoIds, data);
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: StatusType.Error, statusCode:error?.status,
                    statusMessage: 'Error occured while verifying reminder settings'
                });
                dispatch({ type: actionTypes.LOADING_SIGNING_INFO_SETTINGS, isLoading: false });
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.LOADING_SIGNING_INFO_SETTINGS, isLoading: true });
    }
}

const unloadedState: ISigningInfoSettingDictionary = { isLoading: false, message:'' };
   

export const reducer: Reducer<ISigningInfoSettingDictionary> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type)
    {
        case actionTypes.LOADING_SIGNING_INFO_SETTINGS:
            return { ...state, isLoading: action.isLoading, message: action.message ? action.message : '' };
            
        case actionTypes.REQUEST_SIGNING_INFO_SETTINGS:
            let recieved: ISigningInfoSettingDictionary = { ...state };
            recieved[action.id] = {
                signingInfoSettings: { ...initialSigningInfoSettings },
                error: false,
                isLoading: true,
                message: ''
            };
            return recieved;
        case actionTypes.RECEIVE_SIGNING_INFO_SETTINGS:
            let recieveState: ISigningInfoSettingDictionary = { ...state, isLoading: false };
            if (action.settings !== null && action.settings !== undefined) {
                recieveState[action.settings.signingInfoId] = {
                    signingInfoSettings: action.settings,
                    error: false,
                    isLoading: false,
                    message: ''
                };
            }
            return recieveState;
        case actionTypes.RECEIVE_SIGNING_INFO_REMINDER_SETTINGS:
            let recieveReminderState: ISigningInfoSettingDictionary = { ...state, isLoading: false };
            let settings = recieveReminderState[action.id] ? recieveReminderState[action.id].signingInfoSettings :
                                                            { signingInfoId: action.id, reminderSettings: action.settings };
            settings.reminderSettings = action.settings;
            recieveReminderState[action.id] = {
                signingInfoSettings: settings,
                error: false,
                isLoading: false,
                message: ''
            };
            return recieveReminderState;

        case actionTypes.RECEIVE_MULTIPLE_SIGNING_INFO_REMINDER_SETTINGS:
            let recieveRemindersState: ISigningInfoSettingDictionary = { ...state, isLoading: false };
            action.ids.forEach((id, i) => {
                let currentSetting = recieveRemindersState[id] ? recieveRemindersState[id].signingInfoSettings :
                                                               { signingInfoId: id, reminderSettings: action.settings };
                currentSetting.reminderSettings = action.settings;
                recieveRemindersState[id] = {
                    signingInfoSettings: currentSetting,
                    error: false,
                    isLoading: false,
                    message: ''
                };
            })
            return recieveRemindersState;
        case actionTypes.ERROR_SIGNING_INFO_SETTINGS:
            let errorState = { ...state, isLoading: false };
            let currentSettings = errorState[action.id];
            errorState[action.id] = {
                ...currentSettings,
                error: true,
                isLoading: false
            }
            break;
        case actionTypes.RESET_SIGNING_INFO_SETTINGS:
            return unloadedState;
    }
     return state;
}
