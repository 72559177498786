import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../../../store';
import { API_BASE_URL } from '../../../../utils/AppConstants';
import { ISignatureFlowSettings, IMessage } from '../../../models/SignatureFlowSettings';
import { handleResponse } from '../../Library';
import { NotificationAction, StatusType } from '../../../store/common/NotificationStore';
import * as Constants from '../../../components/helper/Constants';
import { actionTypes } from '../../../store/ActionTypes';
import { RequestSignatureFlowSavedMessages, ReceiveSignatureFlowSavedMessages,ResetSignatureFlowSavedMessageData, ReceiveSignatureFlowSavedMessage, DeleteSignatureFlowSavedMessage,SavedMessageActionTypes } from './SavedMessageActionTypes';
import { validateError } from '../../../components/helper/Validations';
import * as SignatureFlowSettingsStore from '../../SignatureFlowSettingsStore';

import { logger } from '../../../../routes/LoggedIn';

export interface ISavedMessageData {
    savedMessages: ISavedMessageDictionary;
    isLoading: boolean;
}

export interface ISavedMessageDictionary {
    [index: number]: SavedMessageState;
}


export interface SavedMessageState {
    messageDetails: IMessage;
}

type DispatchAction = RequestSignatureFlowSavedMessages |
    ReceiveSignatureFlowSavedMessages |
    ReceiveSignatureFlowSavedMessage |
    DeleteSignatureFlowSavedMessage |
    ResetSignatureFlowSavedMessageData |
    NotificationAction;

export const actionCreators = {

    requestSavedMessages: (resourceId: string): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SignatureFlowSavedMessages/GetSavedMessages', {
            method: 'GET',
            credentials: 'include',
            headers: { [Constants.HeaderResourceId]: resourceId }
        })
            .then(handleResponse)
            .then(json => json as Promise<IMessage[]>)
            .then(data => {
                dispatch({
                    type: SavedMessageActionTypes.RECEIVE_SIGNATURE_FLOW_SAVED_MESSAGES, savedMessages: data
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanySettingsError,
                    statusType: StatusType.Error, statusCode:error?.status
                })
            });
        addTask(fetchTask);

        dispatch({ type: SavedMessageActionTypes.REQUEST_SIGNATURE_FLOW_SAVED_MESSAGES });
    },

    saveMessageDetail: (message: IMessage, signatureFlowSettings: ISignatureFlowSettings, isSetDefault: boolean): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SignatureFlowSavedMessages/', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((newId) => {
                let messageDetail = Object.assign({}, message);
                messageDetail.id = newId
                if (isSetDefault) {
                    signatureFlowSettings.messageSettings.welcomeMessage = newId;
                    let action: any = SignatureFlowSettingsStore.actionCreators.updateCompanySettings(signatureFlowSettings);
                    dispatch(action);
                    dispatch({
                        type: SavedMessageActionTypes.RECEIVE_SIGNATURE_FLOW_SAVED_MESSAGE, savedMessage: messageDetail, messageId: newId
                    });
                }
                else {
                    dispatch({
                        type: SavedMessageActionTypes.RECEIVE_SIGNATURE_FLOW_SAVED_MESSAGE, savedMessage: messageDetail, messageId: newId
                    });
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.SavedMessageConstants.SaveMessageSuccess, statusType: StatusType.Success
                });
            }).catch(error => {
                const errorMessage = validateError(error);
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: errorMessage, statusType: StatusType.Error, statusCode:error?.status
                });
                logger.trackError(`saveMessageDetail failed for the request having parameters ${JSON.stringify({ message: message, signatureFlowSettings: signatureFlowSettings })} with error ${errorMessage}`)
            });
        addTask(fetchTask);
    },

    updateMessageDetail: (message: IMessage): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SignatureFlowSavedMessages/', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: SavedMessageActionTypes.RECEIVE_SIGNATURE_FLOW_SAVED_MESSAGE, savedMessage: message, messageId: message.id
                });
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.SavedMessageConstants.UpdateMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                const errorMessage = validateError(error);
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: errorMessage, statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`updateMessageDetail failed for the request having parameters ${JSON.stringify(message)} with error ${errorMessage}`)
            });
        addTask(fetchTask);
    },

    deleteMessageDetail: (message: IMessage): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SignatureFlowSavedMessages/', {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: SavedMessageActionTypes.DELETE_SIGNATURE_FLOW_SAVED_MESSAGE, message: message
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SavedMessageConstants.DeleteMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                const errorMessage = validateError(error);
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`deleteMessageDetail failed for the request having parameters ${JSON.stringify(message)} with error ${errorMessage}`)
            });
        addTask(fetchTask);
    },

    resetSignatureFlowSettings: (): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        dispatch({ type: SavedMessageActionTypes.RESET_SIGNATURE_FLOW_SAVED_MESSAGE_DATA })
    },
};

const savedMessageDic: ISavedMessageDictionary = [];

const unloadedState: ISavedMessageData = {
    savedMessages: { ...savedMessageDic },
    isLoading: false,
} as ISavedMessageData;

export const reducer: Reducer<ISavedMessageData> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case SavedMessageActionTypes.REQUEST_SIGNATURE_FLOW_SAVED_MESSAGES:
            var received = { ...state };
            received.isLoading = true;
            return received;
        case SavedMessageActionTypes.RECEIVE_SIGNATURE_FLOW_SAVED_MESSAGE:
            var received = { ...state };
            if (!received.savedMessages || !Array.isArray(received.savedMessages)) {
                received.savedMessages = [];
            }
            received.savedMessages[action.messageId] = { messageDetails: action.savedMessage };
            received.isLoading = false;
            return received;
        case SavedMessageActionTypes.RECEIVE_SIGNATURE_FLOW_SAVED_MESSAGES:
            var received = { ...state };
            if (!received.savedMessages || !Array.isArray(received.savedMessages)) {
                received.savedMessages = [];
            }
            action.savedMessages.forEach((item, i) => {
                received.savedMessages[item.id] = { messageDetails: item };
            });
            received.isLoading = false;
            return received;
        case SavedMessageActionTypes.DELETE_SIGNATURE_FLOW_SAVED_MESSAGE:
            var received = { ...state }
            if (received.savedMessages[action.message.id]) {
                delete received.savedMessages[action.message.id];
                received.isLoading = false;
            }
            return received;
        
        case SavedMessageActionTypes.RESET_SIGNATURE_FLOW_SAVED_MESSAGE_DATA:
            return { ...unloadedState } as ISavedMessageData;
    }

    return state;
};
