import * as React from 'react';
import { AccessOption, ISignatureFlowSettings } from '../../../models/SignatureFlowSettings';
import { RadioButtonComponent } from '../../common/RadioButtonComponent';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import * as Constants from '../../../components/helper/Constants';

export interface DefaultAccessOptionsModalProps {
    resourceId: string;
    signatureFlowSettings: ISignatureFlowSettings;
    updateCompanySettings: (signatureFlowSettings: ISignatureFlowSettings, settingsType?: Constants.typeOfSettings) => void;
}

export const DefaultAccessOptionsModal: React.FC<DefaultAccessOptionsModalProps> = props => {
    
    const accessOption = props.signatureFlowSettings.accessOptionsSettings.accessOption;

    const handleOptionEveryonChange = () => {
        let signatureFlowSettings = {...props.signatureFlowSettings};
        signatureFlowSettings.accessOptionsSettings.accessOption = AccessOption.Everyone;
        props.updateCompanySettings(signatureFlowSettings, Constants.typeOfSettings.AccessOptions);
    }

    const handleOptionIndividualChange = () => {
        let signatureFlowSettings = {...props.signatureFlowSettings};
        signatureFlowSettings.accessOptionsSettings.accessOption = AccessOption.IndividualUser;
        props.updateCompanySettings(signatureFlowSettings, Constants.typeOfSettings.AccessOptions);
    }

    const updateAdditionalAccessChange = (event: any) => {
        let signatureFlowSettings = {...props.signatureFlowSettings};
        signatureFlowSettings.accessOptionsSettings.additionalAccess = event.target.checked;
        props.updateCompanySettings(signatureFlowSettings, Constants.typeOfSettings.AccessOptions);
    }
    
        return  <div className="new-main-content default-access-options" data-resource-id={props.resourceId}>
                    <div className="row" style={{ position: "relative" }}>
                        <div className="col-sm-8 col-lg-11" style={{ marginLeft: 17 }}>
                            <div className="row">
                                <div className="col-sm-10 padL00 float-left">
                                <h4 className='sigflow-settings-heading'>Default Access Options</h4>
                                </div>
                            </div>
                            <div className="row" style={{ alignItems: "center" }}>
                                <RadioButtonComponent
                                    id={"access-everyone"}
                                    text={"Everyone"}
                                    style={{'marginTop':'19px'}}
                                    checked={accessOption === AccessOption.Everyone}
                                    onChange={handleOptionEveryonChange}
                                    value={AccessOption.Everyone}
                                    dataTestAuto={"fd1605d6-b1b1-408e-a557-7b2d8fca341c"}
                                />
                            </div>
                            <div className="row" style={{ alignItems: "center" }}>
                                <RadioButtonComponent
                                    id={"access-individual"}
                                    text={"Individual User"}
                                    style={{'marginTop':'5px'}}
                                    checked={accessOption === AccessOption.IndividualUser}
                                    onChange={handleOptionIndividualChange}
                                    value={AccessOption.IndividualUser}
                                    dataTestAuto={"9af1f9ad-9993-4a04-ae9a-c7fac423ee5c"}
                                />
                            </div>
                            <div className={"row " + (accessOption !== AccessOption.IndividualUser ? "allow-modify-disable" : "")} 
                                style={{ alignItems: "center", paddingLeft: '24px', marginBottom:'17px', marginTop:'1px' }}>
                                <CheckBoxComponent
                                    id="allow-modify"
                                    className='allow-modify custom-curve-check-box'
                                    text="Allow user to set additional access to other users before delivery"
                                    checked={props.signatureFlowSettings.accessOptionsSettings.additionalAccess}
                                    onChange={updateAdditionalAccessChange}
                                    disabled = {accessOption !== AccessOption.IndividualUser}
                                    datatestAuto="4bd557b0-763b-4bf6-ada0-f171254aba8a"
                                />
                            </div>
                        </div>
                    </div>
                </div>
}