import { getCurrentTaxYear } from "../components/helper/HelperFunctions";
import { ISignatureFlowReportFilter } from "./SignatureFlowReportState";
import { ReportFilterType, SortDirections } from "../components/common/Filters";
import { IPopupStateSingle } from "./SignatureFlowReportTableState";

export interface ISignatureFlowLockedReportPageState {
    page: number;
    pageSize: number;
    filter: ISignatureFlowReportFilter;
    selectedRows: number[];
    searchString: string;
    sortName: string;
    sortOrder: string;
    totalRecordsCount: number;
    filterDocumentType: string;
    filterTaxYear: any;
    filterRecipientName: string;
    filterClientId: string;
    showLoader: boolean;
    showMainLoader: boolean;    
    deSelectedRows: number[];
    isBulkSelectionEnabled: boolean;
    showBulkSelectionMessage: boolean;
    saveFilterShow: boolean,
    
    stateCallback: () => void | undefined
    
    //pop-ups
    documentDetailsState: IPopupStateSingle;
}

export const GetInitialLockedReportPageState = (pageSize: number):ISignatureFlowLockedReportPageState  => {
    return {
        page: 1,
        pageSize: pageSize,       
        filter: ({
            name: '',
            searchText: '',
            sort: {
                column: "",
                direction: SortDirections.None
            },
            fields: {},
            isDefault: 0,
            filterType: ReportFilterType.None,
            isDefaultFilter: false,
            isMasterFilter: false,
        }),
        selectedRows: [],
        searchString: "",
        sortName: "",
        sortOrder: "desc",
        saveFilterShow: false,
        filterDocumentType: "",
        filterTaxYear: getCurrentTaxYear(),
        filterRecipientName: "",        
        filterClientId: "",
        showMainLoader: false,
        showLoader: false,
        stateCallback: null,
        deSelectedRows: [],
        totalRecordsCount: 0,
        isBulkSelectionEnabled: false,
        showBulkSelectionMessage: false,

        //Document Details State
        documentDetailsState:
        {
            model: undefined,
            show: false,
            saveCheckId: undefined
        },
    };
}
