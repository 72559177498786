import { Action, Reducer } from 'redux';
import { addTask } from 'domain-task';
import { API_BASE_URL } from '../../utils/AppConstants';
import { actionTypes } from '../store/ActionTypes';
import { AppThunkAction } from '../store';
import { NotificationAction, StatusType } from '../store/common/NotificationStore';
import { IContact } from '../models/SigningInfo';
import { handleResponse } from './Library';
import * as Constants from '../components/helper/Constants';

export interface IContactsState {
    contacts: IContact[];
}

export interface ReceiveContactsAction {
    type: actionTypes.RECEIVE_CONTACTS;
    contacts: IContact[]
}

type DispatchAction = ReceiveContactsAction

type KnownAction = DispatchAction | NotificationAction

export const actionCreators = {
    getContacts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SignatureFlowSettings/GetContacts', {
            credentials: 'include',
            method: 'GET',
        }).then(handleResponse)
            .then(json => json as Promise<IContact[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_CONTACTS, contacts: data })
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SignatureFlowConstants.ContactsFetchError,
                    statusType: StatusType.Error,
                    statusCode:error?.status
                });
            });
        addTask(fetchTask);
    }
}

const unloadedState: IContactsState = {
    contacts: []
}

export const reducer: Reducer<IContactsState> = (prevState: IContactsState = unloadedState,
    incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const newState = { ...prevState }
    switch (action.type) {
        case actionTypes.RECEIVE_CONTACTS:
            newState.contacts = action.contacts
            break;
    }
    return newState;
}
