import * as React from 'react';
import { useEffect,useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ClientInstructionsConstants } from '../../helper/Constants'
import {initialInstruction, IInstruction, IClientInstrucionsDictionary, ClientInstructionType} from '../../../store/settings/ClientInstructions/ClientInstructionsActionTypes';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { WarningIcon } from '../../svg/CustomSvgIcons';
import VariableList from './VariableList';

interface IAddInstructionProps {
    showState: boolean;
    onHide: () => void;
    submitButtonClick: (instruction: IInstruction, isSetDefault: boolean) => void;
    buttonText: string;
    selectedInstruction: IInstruction;
    title: string;
    defaultInstructionId: number;
    clientInstructions: IClientInstrucionsDictionary;
    instructionType: ClientInstructionType;
}

export const ClientInstructionsPopUp:React.FC <IAddInstructionProps> = (props) => {

    const [instruction,setInstruction] = useState<IInstruction>(initialInstruction);
    const [alert, setAlert] = useState("warning");
    const [errorMessage, setErrorMessage] = useState("");
    const [isSetDefault , setIsSetDefault] = useState(false);
    const [showAlert, setShowAlert] = useState(false);


    useEffect(() => {
        setInstruction(Object.assign({}, props.selectedInstruction));
        setAlert("warning");
        setErrorMessage("");
        setIsSetDefault(props.defaultInstructionId != 0 && props.defaultInstructionId == props.selectedInstruction.id);
        setShowAlert(false);
    }, [props]);

    const onCancelButtonClick = () => {
        props.onHide();

        setInstruction(initialInstruction);
        setAlert("warning");
        setErrorMessage("");
        setIsSetDefault(false);
        setShowAlert(false);
    }

    const onAddButtonClick = () => {
        if (instruction.name.trim() == "") {
            VenusNotifier.Error(ClientInstructionsConstants.ValidateName,"");
            return false;
        }
        else if (instruction.body.trim() == "") {
            VenusNotifier.Error(ClientInstructionsConstants.ValidateBody,"");
            return false;
        }
        if (checkDuplicateExistance(instruction) > 0) {
            VenusNotifier.Error(ClientInstructionsConstants.SaveInstructionNameError,"");
            return false;
        }
        let instructionValue = Object.assign({}, instruction);
        instructionValue.instructionType = props.instructionType;
        props.submitButtonClick(instructionValue, isSetDefault);
        setInstruction(initialInstruction);        
    }

    const checkDuplicateExistance = (instructionDetails: IInstruction) => {
        let count: number = 0;
        Object.keys(props.clientInstructions).map((key, index) => {
            if (props.clientInstructions[parseInt(key)].InstructionDetails.name.trim().toLowerCase() == instructionDetails.name.trim().toLowerCase()
                && props.clientInstructions[parseInt(key)].InstructionDetails.id != instructionDetails.id)
                count++;
        });
        return count;
    }

    const onNameChange = (event: any) => {
        let message_value = Object.assign({}, instruction);
        message_value.name = event.target.value;
        setInstruction(message_value);
    }

    const onTextChange = (event: any) => {
        let message_value = Object.assign({}, instruction);
        message_value.body = event.target.value;
        setInstruction(message_value);
    }

    const onSetDefaultClick = (isTrue: boolean) => {
        if (isTrue) {
            setAlert("warning");
            setErrorMessage(ClientInstructionsConstants.SetDefaultInstructionWarning);
            setShowAlert(true);
        }
        else {
            setAlert("warning");
            setErrorMessage(ClientInstructionsConstants.RemoveDefaultInstructionWarning);
            setShowAlert(true);            
        }
        setIsSetDefault(isTrue);
    }

    return (
        <div style={{ position: 'relative' }}>
            <Modal show={props.showState} onHide={onCancelButtonClick} className=' sigflow-settings-popup' centered={true} backdrop="static">
                <Modal.Header  className='blueModalHeader' closeButton>
                    <Modal.Title>
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='fontSize14-all'>
                    <div className="col-sm-9 col-lg-9 font16 custom-alert-warning-container"  hidden={!showAlert}>
                        <div className='custom-alert-warning'> 
                            <span style={{fontSize:'16px'}}>
                                <span style={{paddingLeft:'7px', paddingRight:'8px'}}>
                                    <WarningIcon fill='#0973BA' marginBottom={'4px'} />
                                </span>
                            {errorMessage}
                            </span>       
                        </div>  
                    </div>
                    <div className="form-group dispFR">
                        <label>Name</label>
                        <input type="text"
                            data-test-auto="59cb698e-3196-424a-9109-087a96dac821"
                            className="form-control"
                            placeholder="Instruction name"
                            maxLength={200}
                            value={instruction.name}
                            onChange={onNameChange}
                            data-lpignore="true">
                        </input>
                    </div>
                    <div className="form-group dispFR">
                        <label>Body</label>
                        <textarea
                            data-test-auto="27e8f02f-dba7-4d83-9f63-227eb5ee8941"
                            className="form-control"
                            placeholder="Please enter instruction text"
                            rows={5}
                            value={instruction.body}
                            onChange={onTextChange}
                        />
                    </div>
                    <VariableList/>
                </Modal.Body>
                <Modal.Footer  className='justify-content-unset padL16'>
                    <div className={"custom-curve-check-box"} >
                        <div className="form-check">
                            <input
                                id = 'client-instruction-default'
                                data-test-auto="5a1aeaec-24db-4a57-aada-bdb569a39792"
                                className="form-check-input magic-checkbox" 
                                type="checkbox" 
                                value="" 
                                checked={isSetDefault}
                                disabled={props.defaultInstructionId == props.selectedInstruction.id}
                                onChange={(event) => onSetDefaultClick(event.target.checked)}>
                            </input>
                            <label htmlFor={'client-instruction-default'} 
                                    className={"form-check-label "+ ( isSetDefault ? "" : "")}>
                                <span className='fontSize14'>Set as Default</span>
                            </label>
                        </div>
                    </div>
                    <div className='modal-submission col-sm text-right'>
                        <Button
                            data-test-auto="e9915f25-13c9-432a-8c57-5aea746c9004"
                            variant='default'
                            className='btn-white modal-footer-button-cancel'
                            onClick={onCancelButtonClick} >
                                Cancel
                        </Button>
                        <Button
                            variant='info'
                            className='modal-footer-button-save'
                            onClick={onAddButtonClick}
                            data-test-auto="fc372995-1e83-4499-b0bb-c354c47f90c3">
                                {props.buttonText}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
};