import * as React from 'react';
import { useSelector } from 'react-redux'
import { ApplicationState } from "../../store/types";
import { FeatureDisabledSubscriptionSvg, FeatureDisabledSvg } from '../svg/CustomSvgIcons';


export const FeatureDisabled: React.FC = () => {
    const isCompanySfSubscriptionEnabled = useSelector((state: ApplicationState) => state.postAuthDataStore.isCompanySfSubscriptionEnabled)

        return <div className='featureDisabled-body' >
                <div>
                    {isCompanySfSubscriptionEnabled ?<FeatureDisabledSubscriptionSvg/> : <FeatureDisabledSvg/>}
                </div>
                <div>
                    <h2>Feature Disabled</h2>
                        {
                            isCompanySfSubscriptionEnabled ?
                                <div className='featureDisabled-body-text' >{`We are sorry, but this feature is currently not available for your use.\n   Please contact your System Administrator for more information.`}</div> :

                                <div className='featureDisabled-body-text' >{`   This product is not supported on your current subscription tier,\nplease contact your Customer Success Representative for access.`}</div>
                        }
                </div>   
             </div>
}