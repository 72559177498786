import React, {useState, useEffect, useRef} from 'react';
import { Modal, Col, Button, FormControl, Row } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { ISignatureFlowReportDocument } from '../../models/SignatureFlowReportState';
import { IReportProblemDetails, ReportProblemProperty, ReportProblemTypes,
    initialState, ProductTypes, ReportedStep,SFSignatureStatus} from './ReportProblemModel';
import { IUserProfile } from '../navigation/profile/ProfileObjects';
import { ReportProblemConstants, UploadtaxReturnConstants, ClientInfoValidation, ValidationContants } from '../helper/Constants';
import { VenusNotifier } from '../helper/VenusNotifier';
import { isValidEmailAddress } from '../helper/Validations';
import { PhoneNumberComponent } from './PhoneNumberComponent';
import { CloseXButton } from '../svg/CustomSvgIcons';
import { getCurrentTaxYear } from "../../components/helper/HelperFunctions";

interface IReportProbemProps {
    show: boolean;
    onCancel: () => void;
    sfModel?: ISignatureFlowReportDocument;
    onReportProblemSave: (id: number, problemDetails: IReportProblemDetails, callback?: () => void) => void;
    loggedInCPA: IUserProfile;
    companyName: string;
    reportProblemType: ReportProblemTypes;
    problemStep?: ReportedStep;

    // Refactor if we are hiding more fields
    hideStatus?: boolean;
    hideSentBy?: boolean;
    fromHeader? : boolean;
}

interface IReportProblemstate {
    saving: boolean;
    showLoader: boolean;
    problemState: IReportProblemDetails;
}

export const ReportProblemModal = (props: IReportProbemProps) => {
    const [state, setState] = useState<IReportProblemstate>({ 
        saving : false,
        showLoader: false,
        problemState: initialState
    });

    const callBack = useRef<() => void>(null);
    let isDataLoaded = ( props.fromHeader || (props.reportProblemType == ReportProblemTypes.SignatureSpecific && (props.sfModel ? true : false)));
    let loadingMessage: string = "";

    useEffect(() => {
        let reportProblem = { ...state.problemState };
        reportProblem.cpaPhoneNumber = !props.loggedInCPA || !props.loggedInCPA.mobileNumber ? "" : props.loggedInCPA.mobileNumber;
        reportProblem.cpaEmail = !props.loggedInCPA ? "" : props.loggedInCPA.emailAddress;
        reportProblem.productType = props.fromHeader ? ProductTypes[3] : ProductTypes[1];
        reportProblem.taxYear = getCurrentTaxYear();

        if (props.sfModel && props.reportProblemType == ReportProblemTypes.SignatureSpecific) {
            reportProblem.productType = ProductTypes[3];
            reportProblem.signingId = props.sfModel.signingId;
            reportProblem.taxYear = props.sfModel.taxYear;
            reportProblem.status = SFSignatureStatus[props.sfModel.signatureStatus];
            reportProblem.sentBy = props.sfModel.senderName;
            reportProblem.clientName = props.sfModel.recipientName;
            reportProblem.documentName = props.sfModel.documentName;
            reportProblem.documentType = props.sfModel.documentType;
            reportProblem.clientId = props.sfModel.clientId;
        }

        if (props.hideStatus)
            reportProblem.status = "";
        if (props.hideSentBy)
            reportProblem.sentBy = "";

        isDataLoaded = ( props.fromHeader || (props.reportProblemType == ReportProblemTypes.SignatureSpecific && (props.sfModel ? true : false)));
        loadingMessage = !isDataLoaded ? UploadtaxReturnConstants.OverlayMessage.ApplicationLoading : "";

        setState({
            saving: false,
            showLoader: (!props.fromHeader && !props.sfModel) ? true : false,
            problemState: reportProblem
        });
    }, [props]);

    useEffect(() => {
        callBack.current && callBack.current();
    }, [callBack.current]);

    const handleFieldChange = (item: ReportProblemProperty, value: any) => {
        let reportProblem = { ...state.problemState };
        switch (item) {
            case ReportProblemProperty.productType:
                reportProblem.productType = value;

                setState({...state,
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.clientId:
                reportProblem.clientId = value;
                setState({...state,
                    problemState: reportProblem
                });
                break;
           
            case ReportProblemProperty.taxYear:
                reportProblem.taxYear = value;
                setState({...state,
                    problemState: reportProblem
                });
                break;
          
            case ReportProblemProperty.cpaEmail:

                reportProblem.cpaEmail = value;
                setState({...state,
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.cpaPhoneNumber:
                reportProblem.cpaPhoneNumber = value;
                setState({...state,
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.status:
                reportProblem.status = value;
                setState({...state,
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.description:
                reportProblem.description = value;
                setState({...state,
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.clientName:
                reportProblem.clientName = value;
                setState({...state,
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.documentType:
                reportProblem.documentType = value;
                setState({...state,
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.documentName:
                reportProblem.documentName = value;
                setState({...state,
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.sentBy:
                reportProblem.sentBy = value;
                setState({...state,
                    problemState: reportProblem
                });
                break;
        }
    }

    const handleClose = () => {
        setState({...state,
            problemState: initialState
        });

        props.onCancel();
    }

    const onSubmit = () => {

        if (state.problemState.description.trim() === '') {
            VenusNotifier.Warning("Please enter problem description to report.", "Report a Problem");
            return;
        }
        else if (!isValidEmailAddress(state.problemState.cpaEmail)) {
            VenusNotifier.Warning(ClientInfoValidation.inCorrectEmail, "");
            return;
        }
        else if (state.problemState.cpaPhoneNumber.trim() != "") {
            if (state.problemState.cpaPhoneNumber.length < 10 || /^[0-9]{1,10}$/.test(state.problemState.cpaPhoneNumber) == false) {
                VenusNotifier.Warning(ValidationContants.PhoneNumberLengthWarning, "");
                return;
            }
        }

        if (!state.saving) {
            setState({ ...state,
                saving: true, 
                showLoader: true 
            });

            callBack.current = () => {
                props.onReportProblemSave(
                    state.problemState.signingId, state.problemState,
                    () => { setState({ ...state, problemState: initialState }); }
                )
            };
        }

    }

    return (
        <div>
            <Modal centered={true} className="report-problem-modal" show={props.show} onHide={handleClose} backdrop="static">
                <LoadingOverlay>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Report a Problem
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={6}>
                                <span className="section-header">Your Information</span>
                                <Row style={{paddingTop:'7px'}} className='pad-b'>
                                    <Col sm={5}>
                                    <span className='section-field'>Product Type</span>
                                    </Col>
                                    <Col sm={7}>
                                    <div className='section-value ellipsis' title={state.problemState.productType} data-test-auto="aa849eb1-f194-4aba-84f7-dffb3d0cf0fc">
                                        {state.problemState.productType}
                                    </div>
                                    </Col>
                                </Row>
                                <Row className='pad-b'>
                                    <Col sm={5}>
                                        <span className='section-field'>CPA Firm</span>
                                    </Col>
                                    <Col sm={7}>
                                        <div className='section-value ellipsis' title={props.companyName} data-test-auto="b9853ae9-5551-42a1-acd7-8873c06bbf76">
                                            {props.companyName}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='pad-b'>
                                    <Col sm={5}>
                                        <span className='section-field'>Logged-In User</span>
                                    </Col>
                                    <Col sm={7}>
                                        <div className='section-value ellipsis' 
                                            title= {props.loggedInCPA != undefined ?
                                                props.loggedInCPA.firstName + " " + props.loggedInCPA.lastName : ""}
                                            data-test-auto="070ab2c8-8913-4cbc-ab8f-104d89bb00e5">
                                            {props.loggedInCPA != undefined ?
                                            props.loggedInCPA.firstName + " " + props.loggedInCPA.lastName : ""}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='pad-b'>
                                    <Col sm={4} style={{paddingTop:'7px'}}>
                                        <span className='section-field'>Phone</span>
                                    </Col>
                                    <Col sm={8} className='section-value editable-section-value'>
                                        <Row >
                                            <Col style={{paddingLeft: '18px', fontSize:'16px', maxWidth:'87%'}}>
                                                <PhoneNumberComponent
                                                    phoneNumber={state.problemState.cpaPhoneNumber}
                                                    handleChangePhoneNumber={(value: string) => { handleFieldChange(ReportProblemProperty.cpaPhoneNumber, value) }}
                                                    disabled={false}
                                                    autoFocus={true}
                                                    datatestAuto="280e7282-48d5-4ff5-a4c7-7601fef18751"
                                            />
                                            <span onClick={() => { handleFieldChange(ReportProblemProperty.cpaPhoneNumber, "") }} > 
                                                  <CloseXButton fill='#05386B'/>
                                            </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='pad-b'>
                                    <Col sm={4} style={{paddingTop:'7px'}}>
                                        <span className='section-field'>Email</span>
                                    </Col>
                                    <Col sm={8} className='section-value editable-section-value'>
                                        <Row >
                                            <Col style={{paddingLeft: '18px', fontSize:'16px', maxWidth:'87%'}}>
                                                <FormControl
                                                 type="text"
                                                 placeholder="Enter Logged-In CPA Email"
                                                 value={state.problemState.cpaEmail}
                                                 title={state.problemState.cpaEmail}
                                                 onChange={(event: any) => { handleFieldChange(ReportProblemProperty.cpaEmail, event.target.value) }}
                                                 autoFocus={true}
                                                 data-test-auto="b749096c-96bf-4b66-b833-40b56117475f"
                                                />
                                                <span onClick={() => { handleFieldChange(ReportProblemProperty.cpaEmail, "") }} > 
                                                  <CloseXButton fill='#05386B'/>
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='pad-b'>
                                    <Col sm={5}>
                                    <span className='section-field'>Tax Year</span>
                                    </Col>
                                    <Col sm={7}>
                                        <div className='section-value ellipsis' title={state.problemState.taxYear.toString()} data-test-auto="e6679547-95b9-4cc7-8d95-b1787fdc1692">
                                            {state.problemState.taxYear}
                                        </div>
                                    </Col>
                                </Row>
                                { !props.fromHeader &&
                                <Row className='pad-b'>
                                    <Col sm={5}>
                                        <span className='section-field'>Document Type</span>
                                    </Col>
                                    <Col sm={7}>
                                        <div className='section-value ellipsis' title={state.problemState.documentType} data-test-auto="65b25081-b447-4e7d-aa7c-291b3ac9801c">
                                            {state.problemState.documentType}
                                        </div>
                                    </Col>
                                </Row>
                                }
                                { !props.fromHeader &&
                                <Row className='pad-b'>
                                    <Col sm={5}>
                                        <span className='section-field'>Client Name</span>
                                    </Col>
                                    <Col sm={7}>
                                        <div className='section-value ellipsis' title={state.problemState.clientName} data-test-auto="76157598-e61f-4514-bc43-4b7e917764cb">
                                            {state.problemState.clientName}
                                        </div>
                                    </Col>
                                </Row>
                                }
                                { !props.hideStatus &&
                                <Row className='pad-b'>
                                    <Col sm={5}>
                                        <span className='section-field'>Status</span>
                                    </Col>
                                    <Col sm={7}>
                                        <div className='section-value ellipsis' title={state.problemState.status} data-test-auto="911e3fcc-81c3-4e87-9a05-6dcbeb9e898f">
                                            {state.problemState.status}
                                        </div>
                                    </Col>
                                </Row>
                                }
                                { !props.hideSentBy &&
                                <Row className='pad-b'>
                                    <Col sm={5}>
                                        <span className='section-field'>Sent By</span>
                                    </Col>
                                    <Col sm={7}>
                                        <div className={'section-value ellipsis ' + (!props.sfModel?.isValidSender ? 'invalid-sender'  : '')} 
                                            title= {state.problemState.sentBy}
                                            data-test-auto="893b554f-9ef2-42a7-8b9e-a2043ca388b1">
                                            {state.problemState.sentBy}
                                        </div>
                                    </Col>
                                </Row>
                                }   
                                { !props.fromHeader &&
                                <Row className='pad-b'>
                                    <Col sm={5}>
                                        <span className='section-field'>Document Name</span>
                                    </Col>
                                    <Col sm={7}>
                                        <div className='section-value ellipsis' title={state.problemState.documentName} data-test-auto="7a21732c-a406-42eb-bf15-d7e5f51951f0">
                                            {state.problemState.documentName}
                                        </div>
                                    </Col>
                                </Row>
                                }

                            </Col>
                            <Col sm={6}>
                                <span className="section-header">Description</span>
                               
                                    <FormControl
                                        value={state.problemState.description}
                                        id="report-problem-text"
                                        style={{ height: '91%', marginTop:'7px' }}
                                        as="textarea"
                                        placeholder='Please describe your problem…'
                                        onChange={(event: any) => { handleFieldChange(ReportProblemProperty.description, event.target.value) }} 
                                        data-test-auto="2a433b5c-0464-4460-8c09-a051f0eace98"/>
                                    </Col>                          
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='default'
                            data-test-auto='1137347C-947C-4D8A-ABA7-0DE9D70DB526'
                            onClick={handleClose}
                            className='btn-white modal-footer-button-cancel'>
                            Cancel
                        </Button>
                        <Button
                            data-test-auto='E0F1216B-0B82-456E-8EEA-57302D25A818'
                            className=' modal-footer-button-save'
                            onClick={onSubmit}
                            variant="info">
                            Submit
                        </Button>
                    </Modal.Footer>
                    <Loader loading={state.showLoader
                        || (props.reportProblemType == ReportProblemTypes.ReturnSpecific && !isDataLoaded)
                        || (props.reportProblemType == ReportProblemTypes.SignatureSpecific && !isDataLoaded)}
                        text={!isDataLoaded ?
                            loadingMessage : ReportProblemConstants.OverlayMessage.SubmittingIssue} />
                </LoadingOverlay>
            </Modal>
        </div>
    );
}

