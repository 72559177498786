import React, { FC, useState, useEffect } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import { daysDropdown, filterUnSignedDocs, isReminderAllowedFilter } from '../../helper/HelperFunctions';
import { IReminderOptions, IReminderSettings } from '../../../models/SignatureFlowSettings';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { ISignatureFlowReportDocument } from '../../../models/SignatureFlowReportState';
import { SendReminderConstants } from '../../helper/Constants';
import { DropdownArrow, WarningIcon } from '../../svg/CustomSvgIcons';



interface ISendReminderModalProps {
    show: boolean;
    onCancel: () => void;
    signingInfoId: number;
    selectedReports: ISignatureFlowReportDocument[];
    reminderSettings: IReminderSettings;
    updateSigningReminderSettings: (signingInfoIds: number[], newSettings: IReminderSettings, callback?: () => void) => void;
    sendReminderNow: (rowIndex: number) => void;
    isLoading: boolean;
    filterSignatureStatus: string;
    isBulkSelectionEnabled: boolean;
    resetSelectedRowsAfterBulkOperation?: () => void;
}

const tabKeyNames = {
    sendReminderNow: 'sendReminderNow',
    automatedReminder: 'automatedReminder'
}

const initialReminderState: IReminderOptions = {
    enabled: false, reminderDays: 7
}


export const SendReminderModal: FC<ISendReminderModalProps> = props => {

    const [tabKey, setTabKey] = useState(tabKeyNames.sendReminderNow);

    const [reminderOptions, setReminderOptions] = useState(initialReminderState);
 
    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        setReminderOptions(Object.assign({}, props.reminderSettings.signingReminder));
    }, [props.reminderSettings])

    useEffect(() => {
        if(!props.show){
            setInProgress(false);
        }
    },[props.show])

    const handleToggleSwitchChange = (event: any) => {
        setReminderOptions({ ...reminderOptions, enabled: event.target.checked });
    }

    const handleSigningReminderDayChange = (event: any) => {
        setReminderOptions({ ...reminderOptions, reminderDays: event.value });
    }

    const onSave = () => {
        const reminderSettings = { ...props.reminderSettings, signingReminder: reminderOptions };
        const unSignedDocs = filterUnSignedDocs(props.selectedReports);
        const selectedIds = unSignedDocs.map((doc, i) => {
            return doc.signingId;
        })
        setInProgress(true);
        props.updateSigningReminderSettings(selectedIds, reminderSettings, onOperationComplete);
    }

    const onSendReminderNow = () => {
        setInProgress(true);
        if (props.selectedReports && props.selectedReports.length === 1) {
            props.sendReminderNow(props.selectedReports[0].signingId);
        }
        else {
            props.sendReminderNow(0); //multi select
        }
    }

    const onCancel = () => {
        setTabKey(tabKeyNames.sendReminderNow);
        props.onCancel();
    }

    const onOperationComplete = () => {
        setTabKey(tabKeyNames.sendReminderNow);
        if(props.isBulkSelectionEnabled && props.resetSelectedRowsAfterBulkOperation){
            props.resetSelectedRowsAfterBulkOperation();
        }
        else {
            props.onCancel();
        }
    }

    const isDisableButton = () => {
        if(inProgress){
            return true;
        }
        if (props.isBulkSelectionEnabled) {
            return !isReminderAllowedFilter(props.filterSignatureStatus);
        }

        if (filterUnSignedDocs(props.selectedReports).length === 0 ||
            (props.filterSignatureStatus && props.filterSignatureStatus.length !== 0 &&
            !props.filterSignatureStatus.includes('2') &&
            !props.filterSignatureStatus.includes('6') &&
            !props.filterSignatureStatus.includes('1') &&
            !props.filterSignatureStatus.includes('8') &&
                 props.filterSignatureStatus !== '-1')) {
            return true;
        }
        return false;
    }

    const isDisableSaveButton = () => {
        if (isDisableButton()) {
            return true;
        }
        else if (props.selectedReports.length === 1) {
            return props.reminderSettings.signingReminder.enabled === reminderOptions.enabled &&
            props.reminderSettings.signingReminder.reminderDays === reminderOptions.reminderDays;
        }
        return false;
    }

    const getButtonTitle = () => {
        let title = '';
        if (isDisableButton()) {
            title = tabKey === tabKeyNames.sendReminderNow ?
                                SendReminderConstants.CannotSendReminder  :
                                SendReminderConstants.CannotUpdateReminder;
        }
        return title;
    }

    return (<div>
        <Modal centered={true} show={props.show} className="send-reminder-modal" onHide={onCancel} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    Set Reminder
                </Modal.Title>
            </Modal.Header>

            <Modal.Body >
                <LoadingOverlay style={{ height: '50%' }}>
                    <Loader loading={props.isLoading} />
                <Tabs
                    fill
                    id="send-reminder-tabs"
                    activeKey={tabKey}
                    onSelect={(k) => setTabKey(k ? k : '')}
                    className="mb-3">

                    <Tab eventKey={tabKeyNames.sendReminderNow}
                        data-test-auto="114163B2-F101-4DEB-BAC1-19DA2CC61BD1"
                        title={<>Send Reminder Now </>}>
                            <Card className="no-border">
                                <Card.Body>
                                <div className='custom-alert-warning div-flex'> 
                                    <span style={{margin: '0 7px 10px 11px'}}><WarningIcon fill='#0973BA'/></span>
                                    <span style={{marginLeft:'5px'}}><strong>Please Note! </strong> {SendReminderConstants.SigningReminderInfo}</span>
                                </div>
                                {props.selectedReports && props.selectedReports.length === 1 && !isDisableButton() &&
                                    props.reminderSettings.signingReminder.enabled &&
                                    <p className="text-danger" style={{margin: '15px 0 0 10px'}}> For selected document, Automatic Reminder is enabled!</p>
                                }
                            </Card.Body>
                        </Card>
                    </Tab>
                    <Tab eventKey={tabKeyNames.automatedReminder}
                        data-test-auto="9ACAFED8-3B60-4FC3-906C-EEE223173B62"
                        title={<> Schedule Automated Reminder </>} >
                            <Card className="no-border">
                            <Card.Body>
                                    <div className='custom-alert-warning div-flex'> 
                                        <span style={{margin: '0 7px 10px 11px'}}><WarningIcon fill='#0973BA'/></span>
                                        <span style={{marginLeft:'5px'}}><strong>Please Note! </strong> {SendReminderConstants.SigningReminderInfo}</span>
                                    </div>
                                    <br/>
                                    <Row className="automated-reminder-row margin-top-10">
                                        <Col sm={5} className={isDisableButton() ? "disabled-opacity" : ""} style={{paddingLeft: '21px',maxWidth: '70px'}} title={getButtonTitle()}>
                                                <label className="toggleSwitch col-sm-4">
                                            <input type="checkbox"
                                                checked={reminderOptions.enabled}
                                                onChange={handleToggleSwitchChange}
                                                disabled={isDisableButton()}
                                            ></input>
                                            <span className="columnFilterSlider round"></span>
                                        </label>
                                        </Col>
                                        <Col sm={3.5} className="text-left" style={{paddingTop: '12px'}}>
                                            <div> Signing Reminder </div>
                                        </Col>
                                    </Row>
                                    <Row className="automated-reminder-row marT30">
                                        <Col sm={3.5} style={{ paddingRight: "8px", paddingLeft:"17px" }}>Send Signing Reminder every</Col>
                                        <Col sm={3.5} className="select-reminder-control">
                                            <Select
                                                name="signing-reminder-select"
                                                autosize={false}
                                                searchable={false}
                                                arrowRenderer={()=><span><DropdownArrow/></span>}
                                                value={reminderOptions.reminderDays}
                                                onChange={handleSigningReminderDayChange}
                                                options={daysDropdown()}
                                                data-test-auto="52A69016-2B50-4B88-84B4-3E82ED02586A"
                                                clearable={false}
                                                disabled={!reminderOptions.enabled || isDisableButton()}
                                                menuStyle={{ position: 'fixed', width: '100px', zIndex: 9999, backgroundColor:'#fff' }}
                                            />
                                        </Col>
                                        <Col sm={5.5} style={{ paddingLeft: "5px" }}>since last reminder/initial email, until E-Signed.</Col>
                                    </Row>
                                    
                            </Card.Body>
                        </Card>
                    </Tab>
                    </Tabs>
                </LoadingOverlay>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant='default'
                    className='btn-white modal-footer-button-cancel'
                    onClick={onCancel}
                    data-test-auto="2CAC66F0-F438-427C-AA72-F83E8C990899">
                    Cancel
                </Button>
                    {tabKey === tabKeyNames.sendReminderNow ?
                        <Button
                            variant='info'
                            className=' modal-footer-button-save'
                            title={getButtonTitle()}
                            disabled={isDisableButton()}
                            onClick={onSendReminderNow}
                            data-test-auto="0A59AB22-5D60-4DDC-9117-44D24CCAE061">
                            Send
                        </Button>
                        :
                        <Button
                            variant='info'
                            className=' modal-footer-button-save'
                            onClick={onSave}
                            disabled={isDisableSaveButton()}
                            data-test-auto="0A59AB22-5D60-4DDC-9117-44D24CCAE061">
                            Save
                        </Button>}               
            </Modal.Footer>
        </Modal>
    </div>);
}
