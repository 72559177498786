import React, { useEffect, useState } from 'react';
import "../../../assets/custom/ClientManagement.css";
import { Button, Modal } from 'react-bootstrap';
import { ClientInfoComparisionCode, IClientInfoComparisonResultViewModel, IClientInfoMismatchViewModel, IClientInfoViewModel, clientRows, columnNames, defaultOrder } from 'src/SignatureFlow/models/ClientManagement';
import { ClientManagementMismatchModalHeader, ClientManagementMismatchModalTitle } from '../helper/Constants';

export interface IClientInfoMismatchModalProps {
    show: boolean;
    clientInfo: IClientInfoViewModel;
    clientInfoComparisonResult: IClientInfoComparisonResultViewModel;
    clientInfoMismatchModalClose: (canUpdate?:boolean) => void;
    onAddClientInfo:() => void;
    onUpdateClientInfo:() => void;
    isLoading: boolean;
}

interface IClientRow {
    [key: string]: any;
}

const ClientInfoMismatchModal:React.FC<IClientInfoMismatchModalProps> = (props) => {

    const [columns, setColumns] = useState<string[]>([]);
    const [rows, setRows] = useState<string[]>([]);

    useEffect(() => {
        setRowsAndColumns();
    }, [props.clientInfoComparisonResult]);

    const getKeyByValue = (clientRow: IClientRow, value: any): string => {
        const keyValues = Object.entries(clientRow).find(([key, val]) => val === value);
        let key: string = "";

        if (keyValues) {
            key = keyValues[0];
        }
        return key;
    }

    const checkNullValues = (data: IClientInfoMismatchViewModel, row: string) => {
        return (
            (data[row].hadDifference && (data[row].taxReturnValue || data[row].clientManagementValue)) ||
            [getKeyByValue(clientRows, clientRows.clientId), 
            getKeyByValue(clientRows, clientRows.name),
            getKeyByValue(clientRows, clientRows.locationName)].includes(row)
            );
        };
    
        const setRowsAndColumns = () => {
            const { clientInfoComparisonResult } = props;
            if (clientInfoComparisonResult?.clientInfoResultData) {
                const rows = sortRows(
                    Object.keys(clientInfoComparisonResult.clientInfoResultData),
                    clientInfoComparisonResult?.comparisonStatus
                );
    
                const columns: string[] = [];
                for (let i = 0; i < rows.length; i++) {
                    if (clientInfoComparisonResult.clientInfoResultData[rows[i]]?.taxReturnValue) {
                        columns.push(columnNames.taxReturn);
                        break;
                    }
                }
                for (let i = 0; i < rows.length; i++) {
                    if (clientInfoComparisonResult.clientInfoResultData[rows[i]]?.clientManagementValue) {
                        columns.push(columnNames.clientManagement);
                        break;
                    }
                }

                setRows(rows);
                setColumns(columns);
            }
        };
    
        const sortRows = (rows: string[], mismatchType: ClientInfoComparisionCode) => {
            const order = defaultOrder.filter((x) => rows.includes(x));
            return order;
        };

    const getMismatchModalTitle = (): string => {
        if (props.clientInfoComparisonResult.comparisonStatus === ClientInfoComparisionCode.NewClient) {
            return ClientManagementMismatchModalTitle.NewClient;
        } else {
            return ClientManagementMismatchModalTitle.ReviewAndConfirm;
        }
    };

    const getMismatchModalHeader = (): string => {
        const comparisonStatus = props.clientInfoComparisonResult.comparisonStatus;

        if (comparisonStatus === ClientInfoComparisionCode.NewClient) {
            return ClientManagementMismatchModalHeader.NewClient;
        } else if (comparisonStatus === ClientInfoComparisionCode.DataMismatch) {
            return ClientManagementMismatchModalHeader.DataMismatch;
        }
        else {
            return ClientManagementMismatchModalHeader.NoDataMismatch;
        }
    }

    const getPrimaryButtonText = () => {
        if (props.clientInfoComparisonResult.comparisonStatus === ClientInfoComparisionCode.NewClient) {
            return 'Yes, Add It';
        } else {
            return 'Yes, Update';
        }
    }

    const getSecondaryButtonText = () => {
        if (props.clientInfoComparisonResult.comparisonStatus === ClientInfoComparisionCode.NewClient) {
            return 'No, Do Not Add It';
        } else {
            return 'No, Do Not Update';
        }
    }

    const onAddOrUpdateClientInfo = () => {
        if (props.clientInfoComparisonResult.comparisonStatus === ClientInfoComparisionCode.NewClient) {
            props.onAddClientInfo();
        }
        else{
            props.onUpdateClientInfo();
        }
    }

    const getDifferenceClass = (data: IClientInfoMismatchViewModel, row: string) => {
        if (data[row].hadDifference) {
            if (columns.includes(columnNames.clientManagement)) {
                return 'client-data-difference';
            }
        }
        return '';
    }

    const isNewClient = () => {
        return props.clientInfoComparisonResult.comparisonStatus === ClientInfoComparisionCode.NewClient;
    }
    
    return (<div>
                <Modal 
                centered={true} 
                show={props.show} 
                className={"mismatch-modal "+ (isNewClient() ? "new-client" : "")}
                onHide={props.clientInfoMismatchModalClose} 
                backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {getMismatchModalTitle()}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mulishFont">{getMismatchModalHeader()}</div>
                        <div>
                            <table className="table table-striped clients-list-table mulishFont">
                                <thead className="table-header">
                                    <tr>
                                        <th className="width-auto">Client Information</th>
                                        {columns.length > 0 &&
                                            columns.map((column: string, index: number) => (
                                                <th key={index} className="width-auto">
                                                    {column}
                                                </th>
                                            ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows.length > 0 &&
                                        rows.map((row: string, index: number) => {
                                            const data = props.clientInfoComparisonResult.clientInfoResultData;
                                            const taxReturnValue = data[row].taxReturnValue;
                                            const clientManagementValue = data[row].clientManagementValue;
                                            
                                            return checkNullValues(data, row) ? (
                                                <tr style={{ fontWeight: `${index === 0 ? '600' : '400'}` }} key={index} className="client-row">
                                                    <td title={clientRows[row]}>{clientRows[row]}</td>

                                                    {columns.includes(columnNames.taxReturn) ? (
                                                        <td
                                                            title={taxReturnValue}
                                                            className={getDifferenceClass(data, row)}
                                                        >
                                                            {taxReturnValue}
                                                        </td>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {columns.includes(columnNames.clientManagement) ? (
                                                        <td
                                                            title={clientManagementValue}
                                                            className={getDifferenceClass(data, row)}
                                                        >
                                                            {clientManagementValue}
                                                        </td>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </tr>
                                            ) : (
                                                <React.Fragment key={index}></React.Fragment>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="white-background">
                        <Button
                            disabled={props.isLoading}
                            className="decline-button"
                            variant={isNewClient() ? "secondary" : "primary"}
                            onClick={()=>{props.clientInfoMismatchModalClose(true)}}
                        >
                            {getSecondaryButtonText()}
                        </Button>
                        <Button
                            disabled={props.isLoading}
                            className="confirm-button"
                            variant={!isNewClient() ? "secondary" : "primary"}
                            onClick={()=>{onAddOrUpdateClientInfo()}}
                        >
                            {getPrimaryButtonText()}
                        </Button>
                    </Modal.Footer>
                </Modal>
        
        </div>);
}

export default ClientInfoMismatchModal;
