import { IUserGroup } from '../User/UserViewModel'

export interface ICompanySettings {
    isDefault: boolean;
    deliverySettingsModel: IDeliverySettingsModel;
    displaySettingsModel: IDisplaySettingsModel;
    notificationSettingsModel: INotificationSettingsModel;
    signatureSettingsModel: ISignatureSettingsModel;
    userPrivilegeSettingsModel: IUserPrivilegeSettingsModel;
    defaultMessageSettings: IDefaultMessage;
    reportingChannelSettings: IReportingChannelSettings[];
    retentionSettingsModel: IRetentionSettings;
    k1Settings: IK1Settings;
    taxpayerPaymentSettings: ITaxpayerPaymentSettings;
    organizerSettings: IOrganizerSettings;
}
export interface IDefaultMessage {
    initialEmail: number;
    voucherReminders: number;
    downloadTaxDocuments: number;
    downloadSignedForms: number;
    k1Distribution: number;
    welcomeMessage: number;
    k1WelcomeMessage: number;
    downloadInstruction: number;
    paymentInstruction: number;
    savedMessage: number;
    signingReminder: number;
    k1PartnerInfoUpdate: number;
    k1ClientInfoUpdate: number;
    k1PartnerWelcomeMessage: number;
    k1ClientWelcomeMessage: number;
    k1PartnerUpdatePageMessage: number;
    attachmentInstruction: number;
    invoicePaymentSuccessMail: number;
}
export interface IDeliverySettingsModel {
    transmittalsDownloadOptions: TransmittalDownloadOption;
    vouchersDownloadOptions: VoucherDownloadOption;
    taxpayerAuthentication: TaxpayerAuthenticationType;
    isDirectDeliveryToTaxCaddy: boolean;
    isByPassManualVerification: boolean;
    isFooterEnabled: boolean;
    isPrintToPDFEnabled: boolean;
}
export interface IDisplaySettingsModel {
    onlinePaymentUrl: string;
    isEnableInvoice: boolean;
}
export interface INotificationSettingsModel {
    paymentVoucherNotificationSettings: IPaymentVoucherNotificationSettings;
    signingReminderNotificationSettings: ISigningReminderNotificationSettings;
}

export interface ISignatureSettingsModel {
    signatureFormSelectionType: SignatureFormSelectionType,
    knowledgeBasedAuthentication: boolean,
    enableSignerDeligation: boolean,
    useSignatureStamp: boolean,
    enableEsignatureForBusinessReturns: boolean,
    manualAddressId: number,
    isDoNotDisplayDatesEnabled: boolean;
}
export interface IPaymentVoucherNotificationSettings {
    enablePaymentVoucherReminder: boolean,
    noOfDaysForVoucherReminder: number
}

export interface ISigningReminderNotificationSettings {
    enableSigningReminder: boolean,
    noOfDaysForSigningReminder: number
}
export interface IK1InfoSettings {
    noOfDays: number,
    k1MailCycle: number
}

export interface IUserPrivilegeSettingsModel {
    allowAnyUsertoSendDelivary: boolean,
    allowUserToReplaceInvoice: boolean,
    allowUserToSendwithoutInvoice: boolean,
    allowUserToReplaceDistributedInstructionsDocument: boolean,
    enableIPFiltering: boolean,
    allowUserToDistributeK1: boolean
}
export interface IReportingChannelSettings {
    reportingEngine: string;
    enabled: boolean;
    settings: IReportingEngineSettings;
}
export interface IReportingEngineSettings {

}

export interface ISignatureFlowSettings {
    enabled: boolean;
}

export interface IOrganizerSettings {
    enabled: boolean;
    enabledSourceDocuments: boolean;
}

export interface IPrintToPdfSettings {
    enabled: boolean;
}

export interface ITaxCaddySettings extends IReportingEngineSettings {
    isSkipManualVerification: boolean;
    firmIds: string[];
}
export interface IPasswordPolicySettings {
    passwordPolicy: PasswordPolicyType;
    passwordSettings: IPasswordSettings;
    modifiedDate: Date;
}

export interface IK1Settings {
    k1ClientInfoSettings: IK1InfoSettings;
    k1PartnerInfoSettings: IK1InfoSettings;
}
export interface IPasswordSettings {
    passwordOptions: PasswordOptions
    length: number;
    passwordAge: number;
}
export interface IRetentionSettings {
    retentionPeriod: number;
    attestRetentionPeriod: number;
    allowOverride: boolean
}

export interface ITaxpayerPaymentSettings {
    companyConnectedAccountId: string;
    applicationFeesAmount: number;
    enableStripe: boolean;
    priortoReview: boolean;
    allowUserToSendReturnWithoutPayment: boolean;
}

export enum AuthenticationProvider {
    None,
    AzureAD,
    SAML,
    Google
}

export enum TransmittalDownloadOption {
    None = "None",
    IncludeTransmittalInTaxReturnPDF = "IncludeTransmittalInTaxReturnPDF",
    CreateSeparateTransmittalPDF = "CreateSeparateTransmittalPDF"
}
export enum VoucherDownloadOption {
    None = "None",
    SeparateByQuarterlyDueDates = "SeparateByQuarterlyDueDates",
    GroupInSinglePDF = "GroupInSinglePDF"
}
export enum TaxpayerAuthenticationType {
    None = "None",
    Last4ofSSN = "Last4ofSSN",
    First4ofSSN = "First4ofSSN"
}
export enum SignatureFormSelectionType {
    None = "None",
    ESign = "ESign",
    ESignWhenAllIncludedReturnsAllowed = "ESignWhenAllIncludedReturnsAllowed",
    ManualSign = "ManualSign"
}

export enum ReportingEngineType {
    TaxCaddy = "TaxCaddy"
}

export enum PasswordPolicyType {
    NoPolicy = "NoPolicy",
    SSRDefault = "SSRDefault",
    FirmDefinedPolicy = "FirmDefinedPolicy"
}

export enum PasswordOptions {
    None = 0,
    UpperCase = 1,
    LowerCase = 2,
    Numbers = 4,
    SpecialCharacters = 8
}

export enum IconColor {
    Green = "#5CB85C",
    Yellow = "#FFD700"
}

export enum BrandingColors {
    LayoutBackgroundColor = 0,
    LayoutForegroundColor = 1,
    CoverPageBackGroundColor = 2,
    CoverPageForeGroundColor = 3
}

export const initialCompanySettings: ICompanySettings = {
    isDefault: true,
    deliverySettingsModel: {
        transmittalsDownloadOptions: TransmittalDownloadOption.None,
        vouchersDownloadOptions: VoucherDownloadOption.None,
        taxpayerAuthentication: TaxpayerAuthenticationType.None,
        isDirectDeliveryToTaxCaddy: false,
        isByPassManualVerification: false,
        isFooterEnabled: false,
        isPrintToPDFEnabled: false
    },
    displaySettingsModel: {
        isEnableInvoice: true,
        onlinePaymentUrl: "",
    },
    notificationSettingsModel: {
        paymentVoucherNotificationSettings: {
            enablePaymentVoucherReminder: true,
            noOfDaysForVoucherReminder: 1
        },
        signingReminderNotificationSettings: {
            enableSigningReminder: true,
            noOfDaysForSigningReminder: 1
        }
    },
    signatureSettingsModel: {
        signatureFormSelectionType: SignatureFormSelectionType.None,
        knowledgeBasedAuthentication: true,
        enableSignerDeligation: true,
        enableEsignatureForBusinessReturns: true,
        useSignatureStamp: false,
        manualAddressId: 0,
        isDoNotDisplayDatesEnabled: true
    },
    userPrivilegeSettingsModel: {
        allowAnyUsertoSendDelivary: true,
        allowUserToReplaceInvoice: true,
        allowUserToSendwithoutInvoice: true,
        allowUserToReplaceDistributedInstructionsDocument: false,
        enableIPFiltering: false,
        allowUserToDistributeK1: false
    },
    defaultMessageSettings: {
        initialEmail: 0,
        voucherReminders: 0,
        downloadTaxDocuments: 0,
        downloadSignedForms: 0,
        k1Distribution: 0,
        welcomeMessage: 0,
        k1WelcomeMessage: 0,
        downloadInstruction: 0,
        paymentInstruction: 0,
        savedMessage: 0,
        signingReminder: 0,
        k1PartnerInfoUpdate: 0,
        k1ClientInfoUpdate: 0,
        k1PartnerWelcomeMessage: 0,
        k1ClientWelcomeMessage: 0,
        k1PartnerUpdatePageMessage: 0,
        attachmentInstruction: 0,
        invoicePaymentSuccessMail: 0
    },
    reportingChannelSettings: [{
        reportingEngine: ReportingEngineType.TaxCaddy,
        enabled: false,
        settings: {
            isSkipManualVerification: false,
            firmIds: []
        }
    }],
    retentionSettingsModel: {
        retentionPeriod: 2520,
        attestRetentionPeriod: 2520,
        allowOverride: false
    },
    k1Settings: {
        k1PartnerInfoSettings:
        {
            noOfDays: 15,
            k1MailCycle: 1
        },
        k1ClientInfoSettings:
        {
            noOfDays: 15,
            k1MailCycle: 1
        }
    },
    taxpayerPaymentSettings: {
        companyConnectedAccountId: "",
        allowUserToSendReturnWithoutPayment: false,
        applicationFeesAmount: 0,
        enableStripe: false,
        priortoReview: false
    },
    organizerSettings: {
        enabled: false,
        enabledSourceDocuments: false
    }
}

export const initialPasswordPolicySettings: IPasswordPolicySettings = {
        passwordPolicy: PasswordPolicyType.NoPolicy,
        modifiedDate: new Date,
        passwordSettings: {
            passwordOptions: PasswordOptions.None,
            length: 8,
            passwordAge: 0
        }
    }


export interface ISuiteMFASetting {
    isMfaEnabled: boolean;
    trustedDeviceExpiryDays: number;
    isAllowUsertoRememberTrustedDevice: boolean;
    skipMfaForIP: boolean;
    iSTPMfaEnabled: boolean;
}

export const initialMFASetting: ISuiteMFASetting = {
    isAllowUsertoRememberTrustedDevice: false,
    isMfaEnabled: false,
    iSTPMfaEnabled: false,
    skipMfaForIP: false,
    trustedDeviceExpiryDays: 0
}