import { fetch, addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '..';
import { API_BASE_URL } from '../../../utils/AppConstants';
import { actionTypes } from '../ActionTypes';
import { ICompanySettings, initialCompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import {
    ICompanyLogoSetting, IState, ICompany,
    initialCompanyLogoSettings, initialCompanyModel,
} from '../../../Core/ViewModels/Company/CompanyViewModel';
import { handleResponse } from '../Library';
import { NotificationAction, StatusType } from '../common/NotificationStore';
import * as Constants from '../../components/helper/Constants';
const isEqual = require("react-fast-compare");


export interface ICompanyData { //TODO: should be a dictionary so that we can pick a user's details with 'userId' from this.
    //DataPart
    companySettings: ICompanySettings | undefined;
    companyProfile: ICompany;
    companyLogoSetting: ICompanyLogoSetting;
    logoUploadLink: string;
    //Meta infomation
    isLoading: boolean;
    error: boolean;
    message: string;
}

export interface RequestCompanySettingsAction {
    type: actionTypes.REQUEST_COMPANY_SETTINGS;
    message: string;
}

export interface ReceiveCompanySettingsAction {
    type: actionTypes.RECEIVE_COMPANY_SETTINGS;
    settings: ICompanySettings;
}

export interface ErrorCompanySettingsAction {
    type: actionTypes.ERROR_COMPANY_SETTINGS;
    reason: string;
}

export interface ReceiveCompanyLogoAction {
    type: actionTypes.RECEIVE_COMPANY_LOGO;
    logoPath: string;
    isSsrLogo: boolean;
}

export interface ReceiveCompanyProfileAction {
    type: actionTypes.RECEIVE_COMPANY_PROFILE;
    companyProfile: ICompany;
}

type DispatchAction = RequestCompanySettingsAction |
    ReceiveCompanySettingsAction |
    ErrorCompanySettingsAction |
    ReceiveCompanyLogoAction |
    ReceiveCompanyProfileAction |
    NotificationAction;


type KnownAction = DispatchAction;

export const actionCreators = {
    requestCompanySettings: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().companyData;
        if (reload || !state.companySettings || isEqual(state.companySettings, initialCompanySettings)) {
            const fetchTask = fetch(API_BASE_URL + 'api/Company/CompanySettings/GetCompanySettings', {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json as Promise<ICompanySettings>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_COMPANY_SETTINGS, settings: data });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanySettingsError,
                        statusType: StatusType.Error, statusCode:error?.status
                    })
                    dispatch({
                        type: actionTypes.ERROR_COMPANY_SETTINGS,
                        reason: error.message,
                    });

                });
            addTask(fetchTask);
            dispatch({
                type: actionTypes.REQUEST_COMPANY_SETTINGS,
                message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading
            });
        }
    },

    requestCompanyLogo: (reload: boolean = false, isLogoUpdate: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (reload) {
            const fetchTask = fetch(API_BASE_URL + '/api/download/GetCompanyLogoLinkAsync?isSSRlogo=' + isLogoUpdate, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json)
                .then(data => {
                    var isSsrLogo = data.isSsrlogo == "True" ? true : false;
                    dispatch({ type: actionTypes.RECEIVE_COMPANY_LOGO, logoPath: data.logoPath, isSsrLogo: isSsrLogo });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanyLogoError,
                        statusType: StatusType.Error, statusCode:error?.status
                    })
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_COMPANY_SETTINGS, message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading });
        }
    },

    requestCompanyProfile: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().companyData;
        if (reload || !state.companyProfile || state.companyProfile.companyInfo.companyName.length === 0) {
            const fetchTask = fetch(API_BASE_URL + 'api/Company/GetCompanyProfile', {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json as Promise<IState>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_COMPANY_PROFILE, companyProfile: data.company });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanyProfileError,
                        statusType: StatusType.Error, statusCode:error?.status
                    })
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_COMPANY_SETTINGS, message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading });
        }
    }
};

const unloadedState: ICompanyData = {
    companySettings: initialCompanySettings,
    companyLogoSetting: initialCompanyLogoSettings,
    companyProfile: initialCompanyModel,
    logoUploadLink: ''
} as ICompanyData;

export const arrayToHash = (array: any[], id: string = 'userId') =>
    array.reduce((obj, item) => (obj[item[id]] = item, obj), {})

export const reducer: Reducer<ICompanyData> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_COMPANY_SETTINGS:
            var received = { ...state };
            var unLoadedState = { ...unloadedState } as ICompanyData;
                received.companySettings = state.companySettings ? { ...state.companySettings } : state.companySettings,
                received.isLoading = true;
            received.error = false;
            received.message = action.message;
            received.companyLogoSetting = state.companyLogoSetting;
            return received;

        case actionTypes.RECEIVE_COMPANY_SETTINGS:
            var received = { ...state };
            received.companySettings = action.settings;
            received.companySettings.isDefault = false;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;
        case actionTypes.ERROR_COMPANY_SETTINGS:
            return {
                companySettings: Object.assign({}, state.companySettings),//{...state.companySettings}
                companyLogoSetting: state.companyLogoSetting,
                companyProfile: state.companyProfile,
                logoUploadLink: state.logoUploadLink,
                isLoading: false,
                error: true,
                message: action.reason
            } as ICompanyData;

        case actionTypes.RECEIVE_COMPANY_LOGO:
            var received = { ...state };
            received.companyLogoSetting.logoPath = action.logoPath;
            received.companyLogoSetting.isSsrLogo = action.isSsrLogo;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.RECEIVE_COMPANY_PROFILE:
            var received = { ...state };
            received.companyProfile = action.companyProfile;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;
    }

    return state;
};
