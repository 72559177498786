import * as React from 'react';
import { BookmarksPane } from '../components/common/BookmarkPane';
import { EsignHelpTextConstant, SignatureControlConstants } from './helper/Constants';
import { ClientType, IClient, ISigningInfo, SenderSignOption } from '../models/SigningInfo';
import {
    ISigner, SignatureControlPanel, InputType,
    SFPdfPageSignatureControls
} from './SignatureControlPanel';
import {
    ISignatureData,
    ISignatureControl, SignatureControlRole, SignatureControlType, SignatureGroupType,
    initialSignatureControl,
    ISignatureControlsHistory,
    ControlHistoryType,
    ChoosableControlData
} from './../models/SignatureData';
import { IDocumentData } from './../models/DocumentInfo';
import { IPdfDocumentFacade } from '../../Core/Utilities/PdfDocumentFacade';
import * as Helper from '../components/helper/HelperFunctions';
import SignerTooltipPopup from './common/SignerTooltipPopup';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { getControlText, getControlIcon } from './../components/helper/SignatureHelper';
import { Guid } from '../../Core/Utilities/Guid';
import SignatureControl from '../components/common/SignatureControl';

import { PdfViewer } from 'awesome-pdf-viewer';
import { ControlBase } from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { PlaceholderControl } from 'awesome-pdf-viewer/dist/Controls/PlaceholderControl/PlaceholderControl';
import ControlLayer from 'awesome-pdf-viewer/dist/Layers/ControlLayer';
import { Header } from 'awesome-pdf-viewer/dist/layout/Header';
import { PageProperties } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { LeftPanel } from 'awesome-pdf-viewer/dist/layout/LeftPanel';
import { RightPanel } from 'awesome-pdf-viewer/dist/layout/RightPanel';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import { CustomOptions } from 'awesome-pdf-viewer/dist/toolbar/CustomOptions';
import { Pagination } from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import { Toolbar } from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import { PageMode, PdfSource} from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import {PageSize} from "awesome-pdf-viewer/dist/layout/LayoutBase";
import { CONTROL_ID_PREFIX, CONTROL_KEY_PREFIX, StepsHelper } from './helper/steps/StepsHelper';
import { loadPdf } from '../components/helper/HelperFunctions';
import { logger } from '../../routes/LoggedIn';
import { validateError } from './helper/Validations';
const Draggabilly: any = require('draggabilly/draggabilly');

export const PdfProperties = {
    PageHeight: 792,
    PageWidth: 612,
    DefaultScale: 1
}
export const INVALID_PAGE = -100;

const signersControlBoxItems = [
    {
        caption: 'Signature',
        icon: 'fas fa-signature',
        controlType: SignatureControlType.Signature,
        helpText: EsignHelpTextConstant.ClientSignature
    },
    {
        caption: 'Initials',
        icon: 'fas fa-font',
        controlType: SignatureControlType.Initial,
        helpText: EsignHelpTextConstant.Initial
    },
    {
        caption: 'Date Signed',
        icon: 'fas fa-calendar-alt',
        controlType: SignatureControlType.Date,
        helpText: EsignHelpTextConstant.SignatureDate
    },
    {
        caption: 'Print Name',
        icon: 'fas fa-address-card',
        controlType: SignatureControlType.PrintName,
        helpText: EsignHelpTextConstant.PrintName
    },
    {
        caption: 'Company Field',
        icon: 'fas fa-building',
        controlType: SignatureControlType.Company,
        helpText: EsignHelpTextConstant.Company
    },
    {
        caption: 'Title',
        icon: 'fas fa-address-card',
        controlType: SignatureControlType.Title,
        helpText: EsignHelpTextConstant.Title
    },
    {
        caption: 'Text',
        icon: 'fas fa-text-width',
        controlType: SignatureControlType.Text,
        helpText: EsignHelpTextConstant.Text
    },
    {
        caption: 'Check Box',
        icon: 'fas fa-check-square',
        controlType: SignatureControlType.CheckBox,
        helpText: EsignHelpTextConstant.CheckBox
    },
    {
       caption: 'Radio Button',
       icon: 'fas fa-check-circle',
       controlType: SignatureControlType.Radio,
       helpText: EsignHelpTextConstant.Radio
    }
];
const senderControlBoxItems = [{
    caption: 'Signature',
    icon: 'fas fa-signature',
    controlType: SignatureControlType.Signature,
    helpText: EsignHelpTextConstant.ClientSignature
}];
export interface SignatureProps {
    signingInfo: ISigningInfo;
    currentStep: number;
    documentData: IDocumentData[];
    loadRecognizedDataForUnloadedDocuments: (documents: IDocumentData[]) => void;
    updateSFData: (signingInfo: ISigningInfo) => void;
    updateDocumentData: (documentData: IDocumentData) => void;
    loadRecognizedDataForSelectedDocument: (documentGuid: string) => void;
    loadRecognizedDataForSelectedDocumentIfRecipientsUpdated: (documentGuid: string) => void;
    isRecipientsUpdated: boolean;
}
export interface PdfDocumentState {
    currentPage: number;
    zoomEnabled: boolean;
}
export interface SignaturePropsState extends PdfDocumentState {
    signers: ISigner[],
    selectedSigner: ISigner,
    sender: ISigner,
    selectedESignDocument: IDocumentData,
    isBusy: boolean,
    focusedGroup: SignatureGroupType,
    toolTipControl: ISignatureControl,
    showToolTipPopup: boolean,
    undoDisable: boolean,
    scale: number,
    pdfData: any
}
export class SFStep3 extends React.Component<SignatureProps, SignaturePropsState>{
    private _viewPanel: any;
    private _controlLayer: any;
    private _controlList: any[] = [];
    private _toolbar: any;
    //private _bookmarkPanel: any;
    private _controlDisplayPanel: any;
    constructor(props: SignatureProps) {
        super(props);
        this.state = {
            signers: [],
            selectedSigner: {
                value: "",
                label: "",
                signatureRole: SignatureControlRole.None
            },
            sender: {
                value: "",
                label: "",
                signatureRole: SignatureControlRole.SFSender
            },
            currentPage: INVALID_PAGE,
            selectedESignDocument: {} as IDocumentData,
            isBusy: false,
            zoomEnabled: false,
            focusedGroup: SignatureGroupType.None,
            toolTipControl: initialSignatureControl,
            showToolTipPopup: false,
            scale: PdfProperties.DefaultScale,
            pdfData: '',
            undoDisable: true,
        }

    }

    UNSAFE_componentWillMount() {
        //this.setState({ isBusy: true });
        // receipent signers
        var tempSFSigners: ISigner[] = [];
        for (let i = 0; i < this.props.signingInfo.recipients.length; i++) {
            if (this.props.signingInfo.recipients[i].clientType.toString() === ClientType.Signer.toString()) {
                tempSFSigners.push({
                    value: this.props.signingInfo.recipients[i].clientGuid.toString(),
                    label: this.props.signingInfo.recipients[i].emailAddress,
                    signatureRole: SignatureControlRole.SFReceipient
                });
            }
        }
        // sender
        var tempSender: ISigner = this.state.sender;
        tempSender.value = this.props.signingInfo.clientSender.clientGuid.toString();
        tempSender.label = this.props.signingInfo.sender.email.concat(' (You)');
        tempSender.signatureRole = SignatureControlRole.SFSender;


        if (this.props.signingInfo.senderSignOption === SenderSignOption.SIGNS_FIRST) {
            tempSFSigners.unshift(tempSender);
        }
        else if (this.props.signingInfo.senderSignOption === SenderSignOption.SIGNS_LAST) {
            tempSFSigners.push(tempSender);
        }

        let history: boolean = this.props.signingInfo.documentInfo[0]?.signatureData[0] ?
            (this.props.signingInfo.documentInfo[0]?.signatureData[0]?.signatureControlsHistory && this.props.signingInfo.documentInfo[0]?.signatureData[0]?.signatureControlsHistory?.length > 0) : false;

        this.setState({
            selectedSigner: tempSFSigners[0],
            signers: tempSFSigners,
            sender: tempSender,
            focusedGroup: this.props.signingInfo.senderSignOption === SenderSignOption.SIGNS_FIRST ? SignatureGroupType.Sender
                : SignatureGroupType.Receiver,
            selectedESignDocument: this.props.documentData[0],
            currentPage: 1,
            undoDisable: !history
        });
    }

    setReferences = () => {
        this._toolbar && this._toolbar.setViewerReference(this._viewPanel);
        this._viewPanel && this._viewPanel.setToolbarReference(this._toolbar);
        this._viewPanel && this._viewPanel.setControlsReference(this._controlList);
        this._viewPanel && this._viewPanel.setControlLayerReference(this._controlLayer);
        this._viewPanel && this._controlLayer.setViewerReference(this._viewPanel);
        this._controlLayer && this._controlLayer.setControlsReference(this._controlList);
        this._controlLayer && this._controlLayer.setcontrolDisplayPanelReference(this._controlDisplayPanel);
        this._controlDisplayPanel && this._controlDisplayPanel.setControlsReference(this._controlList);
        this._controlDisplayPanel && this._controlDisplayPanel.setViewerReference(this._viewPanel);
    }

    componentDidUpdate() {
        this.setReferences();
    }

    UNSAFE_componentWillReceiveProps(nextProps: SignatureProps) {
        if (this.state.selectedESignDocument &&
            nextProps.documentData.filter(x => x.documentGuid == this.state.selectedESignDocument.documentGuid)[0].sasUrl == "") {
            nextProps.loadRecognizedDataForSelectedDocument(this.state.selectedESignDocument.documentGuid);
        }

    }

    componentDidMount() {
        this.setReferences();
        if (this.props.isRecipientsUpdated || !this.state.selectedESignDocument.isPDFloaded) {
            this.props.loadRecognizedDataForSelectedDocumentIfRecipientsUpdated(this.state.selectedESignDocument.documentGuid);
        }
        if (this.state.selectedESignDocument && !this.state.selectedESignDocument.isPDFloaded) {
            var selected: IDocumentData = { ...this.state.selectedESignDocument }
            if (this.props.documentData[0].sasUrl && this.props.documentData[0].sasUrl != "") {
                loadPdf(this.props.documentData[0].sasUrl).then((data: IPdfDocumentFacade) => {
                    selected.pdf = data;
                    selected.isPDFloaded = true;
                    this.props.updateDocumentData(selected);
                    this.setState({ selectedESignDocument: selected, isBusy: false });
                }).catch (err => {
                    logger.trackError(`Error occurred at loadPDF - SFtep3:  ${validateError(err)}`);
                });
            }
        }
    }

    signerChange = (selectedOption: any) => {
        if (selectedOption) {
            const selectedValue = this.state.signers.find(s => s.value == selectedOption);
            if (selectedValue) {
                const signatureControlRole = selectedValue.signatureRole ? selectedValue.signatureRole : SignatureControlRole.None;
                const focusedGroup = this.getFocusedGroup(signatureControlRole);

                this.setState({
                    selectedSigner: selectedValue,
                    focusedGroup: focusedGroup
                });
            }
            else {
                this.setState({
                    selectedSigner: {
                        value: selectedOption.value,
                        label: selectedOption.label,
                        signatureRole: SignatureControlRole.None,
                        disabled: selectedOption.disabled
                    },
                    focusedGroup: SignatureGroupType.None
                });
            }

        }
    }

    dragStartSignatureControl = (event: any, controlType: SignatureControlType, signatureRole: SignatureControlRole, signer: string) => {
        this.registerDropEvents();
        event.dataTransfer.setData('controlType', controlType.toString());
        event.dataTransfer.setData('signatureRole', signatureRole.toString());
        event.dataTransfer.setData('signer', signer);

        var element = document.createElement("div");
        element.id = "dragging-element";
        element.style.position = "absolute";
        element.style.top = "-1000px";

        let controlText = getControlText(controlType);
        let icon = getControlIcon(controlType);
        element.innerHTML = '<p style="border-style: solid; border-width: 1px;"><i class="'
            + icon + '" style="padding: 5px; background: rgb(240, 240, 241);"></i><label style="font-size: '
            + (12 * this.state.scale).toString() + 'px; font-weight: normal; padding: 0px 5px 0px 5px;">'
            + controlText + '</label></p>';

        document.body.appendChild(element);
        event.dataTransfer.setDragImage(element, 0, 0);
    }

    private dragEndSignatureControl = (event: any) => {
        let element = document.getElementById("dragging-element");
        if (element) {
            element.remove();
        }
    }

    registerDropEvents = () => {
        const _this = this;
        const elements = document.getElementsByClassName("page");
        for (var i = 0; i <= elements.length - 1; i++) {
            const tmpElement: any = elements[i];
            tmpElement.ondrop = function (ev: any) {
                ev.preventDefault();
                const tmpPageElement = ev.target.offsetParent;
                if (tmpPageElement.attributes["data-page-number"]) {
                    _this.dropSignatureControl(ev);
                }
            };
            tmpElement.ondragover = function (ev: any) {
                ev.preventDefault();
            };
        }
    }

    private dropSignatureControl = (event: any) => {
        let controlType = event.dataTransfer.getData('controlType');
        let signatureRole = event.dataTransfer.getData('signatureRole');
        const signer = event.dataTransfer.getData('signer');
        if (controlType && signatureRole) {
            controlType = parseInt(controlType);
            signatureRole = parseInt(signatureRole);
            if (!Number.isNaN(controlType) && !Number.isNaN(signatureRole)) {
                this.addSignatureControl(controlType, signatureRole, signer, event.offsetX, event.offsetY);
            }
        }
    }

    private addControlItems = (tooltip: string): ChoosableControlData[] => {
        let items: ChoosableControlData[] = [];
        let controlLeft = 20;
        let tooltipNumber = Number(tooltip);
        const name = Guid.newGuid().toString();
        for (let i = 1; i <= tooltipNumber; i++) {
            items.push({
                id: Guid.newGuid().toString(),
                name: name,
                top: (controlLeft * i),
                left: 35,
                width: 0,
                height: 0,
                value: { checked: false, value: '' }
            });
        }
        return items;
    }
    
    private addSignatureControl = (type: SignatureControlType, role: SignatureControlRole, signer: string, clientX: number, clientY: number, isHist?: boolean, histGuid?: string) => {
        const { currentPage } = this.state;
        const heightIncrement = 20;
        this._toolbar && this._toolbar.handleZoomChange(1);
        let control: ISignatureControl = {} as ISignatureControl;
        control.controlGuid = Guid.newGuid().toString();
        control.signer = signer;

        const pageSize: PageSize = this.getCurrentPageSize();

        control.top = ControlBase.getBackendControlTopPosition(pageSize.height, clientY);
        control.left = ControlBase.getBackendControlLeftPosition(clientX);

        control.type = type;
        control.role = role;
        control.required = true;

        if (control.type === SignatureControlType.Radio) {
            control.tooltip = SignatureControlConstants.DefaultNumberOfRadioButtons;
            control.height = (SignatureControlConstants.ControlHeight_RadioButton + Number(control.tooltip) * heightIncrement) * this.state.scale;
            control.width = SignatureControlConstants.ControlWidth_RadioButton  * this.state.scale;
            control.items = this.addControlItems(control.tooltip);
        }


        let temp_sfData: ISigningInfo = Object.assign({}, this.props.signingInfo);
        const documentInfoIndex = temp_sfData.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);

        if (documentInfoIndex != Helper.NO_INDEX) {
            let documentInfo = temp_sfData.documentInfo[documentInfoIndex]
            let signatureControls: ISignatureControl[];
            let signatureControlsHistory: ISignatureControlsHistory[];
            const signatureDataIndex = documentInfo.signatureData.findIndex(x => x.pageNo === this.state.currentPage);

            if (!documentInfo.signatureData || !Array.isArray(documentInfo.signatureData)) {
                signatureControls = [];
                signatureControlsHistory = [];
                let signatureData = { pageNo: this.state.currentPage, signatureControls: signatureControls, signatureControlsHistory: signatureControlsHistory } as ISignatureData;
                documentInfo.signatureData = [signatureData];
            }
            else {

                if (signatureDataIndex == Helper.NO_INDEX) {
                    signatureControls = [];
                    signatureControlsHistory = [];
                    let signatureData = { pageNo: this.state.currentPage, signatureControls: signatureControls, signatureControlsHistory: signatureControlsHistory } as ISignatureData;
                    documentInfo.signatureData.push(signatureData);
                }
                else {
                    signatureControls = documentInfo.signatureData[signatureDataIndex].signatureControls;
                    signatureControlsHistory = documentInfo.signatureData[signatureDataIndex].signatureControlsHistory;
                }
            }

            //add Signature history object if it does not exist
            if (!signatureControlsHistory) {
                signatureControlsHistory = [];
                documentInfo.signatureData[signatureDataIndex].signatureControlsHistory = signatureControlsHistory;
            }

            //check if data is from history table if yes then add its possition
            if (!isHist) {
                signatureControlsHistory.push({ signatureControl: control, controlHistoryType: ControlHistoryType.Added });
                this.state.undoDisable ? this.setState({ undoDisable: false }) : "";

            }
            else {
                control.controlGuid = histGuid ? histGuid : control.controlGuid;
                control.left = clientX;
                control.top = clientY;
            }
            signatureControls.push(control);


            this._toolbar.forceUpdate();

            this.props.updateSFData(temp_sfData);

            if ([SignatureControlType.Text, SignatureControlType.CheckBox, SignatureControlType.Radio, SignatureControlType.Signature].includes(control.type)) {
                this.setState({
                    toolTipControl: control,
                    showToolTipPopup: true
                });
            }

        }

    }

    private checkShouldRenderRecipientControl = (clients : IClient[], signerGuid : string) => {
        const client = clients.find(i=> i.clientGuid.toString() == signerGuid);
        return client?.clientType != ClientType.CC;
    }

    onControlLoad = (control: any, pageElement: any) => {
        if (control.draggable === false) {
            this.applyControlStyles(control);
            this.makeDraggableControl(control, pageElement, this.handleControlDragEnd);
            this.registerResizeEvent(control, this.handleResize);
        }
    }

    private makeDraggableControl = (control: any, pageElement: any, dragEndCallBack: any) => {
        const draggie = new Draggabilly('#' + control.id, {
            containment: pageElement,
            handle: '.draggable-btn-control'
        });

        draggie.off('dragEnd', '#' + control.id);
        draggie.on('dragEnd', function (event: any, pointer: any) {
            event = event;
            pointer = pointer;
            const controlElement = document.getElementById(control.id);
            controlElement &&
                dragEndCallBack(control.id.replace(SignatureControlConstants.ControlIdPrefix, ''),
                    controlElement.offsetTop, controlElement.offsetLeft)
        });
        this.setDraggableControlItems(this.handleControlItemsDragEnd);
    }

    setDraggableControlItems = (dragEndCallBack: any, handle?: string): any => {

        const elements: any = document.querySelectorAll('.choosable-control-item');
        for (let i = 0; i <= elements.length - 1; i++) {

            const element: any = elements[i];
            const draggable = element.attributes["data-draggable"]?.value;

            if (!draggable) {
                element.setAttribute("data-draggable", "true");
                const draggie = new Draggabilly(element, {
                    containment: element.parentElement,
                    handle: handle
                });

                draggie.off('dragEnd', element);
                draggie.on('dragEnd', function (event: any, pointer: any) {
                    event = event;
                    pointer = pointer;

                    if (element) {
                        const id: string = element.attributes["data-id"].value;
                        const controlGuid = element.attributes["data-controlguid"].value;

                        dragEndCallBack(id,
                            controlGuid.replace(SignatureControlConstants.ControlIdPrefix, ''),
                            element.offsetTop, element.offsetLeft)
                    }
                });
            }
        };
    }

    private handleControlItemsDragEnd = (id: string, controlGuid: string, top: number, left: number) => {
        let temp_sfData: ISigningInfo = Object.assign({}, this.props.signingInfo);
        const documentInfoIndex = temp_sfData.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);

        if(documentInfoIndex != Helper.NO_INDEX) {
            let documentInfo = temp_sfData.documentInfo[documentInfoIndex]
            const signatureDataIndex = documentInfo.signatureData.findIndex(x => x.pageNo === this.state.currentPage);
            if(signatureDataIndex != Helper.NO_INDEX) {
                let signatureControls = documentInfo.signatureData[signatureDataIndex].signatureControls;
                const index = signatureControls.findIndex(x => x.controlGuid === controlGuid);
                if (index != Helper.NO_INDEX) {
                    let control = signatureControls[index];
                    if (control) {
                        let itemIndex = control.items.findIndex(x => x.id == id);
                        if (itemIndex != Helper.NO_INDEX) {
                            control.items[itemIndex].left = left;
                            control.items[itemIndex].top = top;
                            this.onUpdateControlData(control);
                        }
                    }
                }
            }
        }
        
    }

    applyControlStyles = (control: any) => {
        const controlElement = document.getElementById(control.id);
        if (controlElement) {
            controlElement.removeAttribute('class');
            controlElement.classList.add('choosable-control-group');
            controlElement.classList.add('resizable-both');
            const selectedSignerStyle = controlElement.children[0]?.getAttribute('data-signerstyle');
            controlElement.classList.add(selectedSignerStyle ? selectedSignerStyle : 'choosable-signature-control-border');
        }
    }

    private handleControlDragEnd = (controlGuid: string, top: number, left: number) => {

        let temp_sfData: ISigningInfo = Object.assign({}, this.props.signingInfo);
        const documentInfoIndex = temp_sfData.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);

        if(documentInfoIndex != Helper.NO_INDEX) {
            let documentInfo = temp_sfData.documentInfo[documentInfoIndex]
            const signatureDataIndex = documentInfo.signatureData.findIndex(x => x.pageNo === this.state.currentPage);
            if(signatureDataIndex != Helper.NO_INDEX) {
                let signatureControls = documentInfo.signatureData[signatureDataIndex].signatureControls;
                const index = signatureControls.findIndex(x => x.controlGuid === controlGuid);
                if(index != Helper.NO_INDEX) {
                    const controltop = top / this.state.scale;
                    const controlLeft = left / this.state.scale;
                    const pageSize: PageSize = this.getCurrentPageSize();
                    signatureControls[index].left = ControlBase.getBackendControlLeftPosition(controlLeft);
                    signatureControls[index].top = ControlBase.getBackendControlTopPosition(pageSize.height, controltop);
                    let control = signatureControls[index];
                    this.onUpdateControlData(control);
                }
            }
        }
    }

    private onUpdateControlData = (control: ISignatureControl) => {

        let temp_sfData: ISigningInfo = this.props.signingInfo;
        const documentInfoIndex = temp_sfData.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);

        if(documentInfoIndex != Helper.NO_INDEX) {
            let documentInfo = temp_sfData.documentInfo[documentInfoIndex]
            const signatureDataIndex = documentInfo.signatureData.findIndex(x => x.pageNo === this.state.currentPage);
            if(signatureDataIndex != Helper.NO_INDEX) {
                let draggedControl = documentInfo.signatureData[signatureDataIndex].signatureControls.filter(c => c.controlGuid == control.controlGuid)[0];
                draggedControl.left = control.left;
                draggedControl.top = control.top;
            }
        }
    }

    public handleResize = (controlGuid: string, height: number, width: number) => {
        let temp_sfData: ISigningInfo = this.props.signingInfo;
        const documentInfoIndex = temp_sfData.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);

        if(documentInfoIndex != Helper.NO_INDEX) {
            let documentInfo = temp_sfData.documentInfo[documentInfoIndex]
            const signatureDataIndex = documentInfo.signatureData.findIndex(x => x.pageNo === this.state.currentPage);
            if(signatureDataIndex != Helper.NO_INDEX) {
                let signatureControls = documentInfo.signatureData[signatureDataIndex].signatureControls;
                const index = signatureControls.findIndex(x => x.controlGuid === controlGuid);
                if (index != Helper.NO_INDEX) {
                    let control = signatureControls[index];
                    control.height = height;
                    control.width = width;
                }
            }
        }
    }

    registerResizeEvent = (control: any, onResize: any): any => {
        const element = document.getElementById(control.id);
        if (element) {
            element.onmouseup = function (event: any) {
                event = event;
                onResize(control.id.replace(SignatureControlConstants.ControlIdPrefix, ''),
                    element.offsetHeight, element.offsetWidth);
            };
        }
    }

    private renderSignatureControl = () => {
        const { currentPage, scale } = this.state;
        const controlCollection: any[] = [];
        this._controlList = [];
        const controls: any = this.getSignatureControls();

        if (this._viewPanel) {

            if (controls && controls.signatureControls.length > 0) {
                let tempControls: any = JSON.parse(JSON.stringify(controls));

                tempControls.signatureControls.map((control: ISignatureControl, index: any) => {
                    const renderRecipientControl = this.checkShouldRenderRecipientControl(this.props.signingInfo.recipients, control.signer);
                    const pageSize: PageSize = this.getCurrentPageSize();

                    const pdfViewerTop = ControlBase.getPdfViewerControlTopPosition(pageSize.height, control.top);
                    const pdfViewerLeft = ControlBase.getPdfViewerControlLeftPosition(control.left);
                    switch (control.role) {
                        case SignatureControlRole.SFSender:
                            controlCollection.push(<PlaceholderControl
                                height={control.height ? control.height : (SignatureControlConstants.ControlHeight * this.state.scale)}
                                id={CONTROL_ID_PREFIX + control.controlGuid}
                                key={CONTROL_KEY_PREFIX + control.controlGuid}
                                ref={(ref) => {
                                    this._controlList.push(ref)
                                }}
                                page={currentPage}
                                width={control.width ? control.width : (SignatureControlConstants.ControlWidth * this.state.scale)}
                                top={pdfViewerTop}
                                left={pdfViewerLeft}
                                name={this.state.selectedESignDocument.fileName}
                                helptext={control.type === SignatureControlType.Radio ? '' : control.tooltip }
                                disabled={false}
                                isRequired={control.required}
                                draggable={control.type === SignatureControlType.Radio ? false : true}
                                data={{
                                    type: control.type,
                                    role: control.role,
                                    signer: control.signer,
                                    items: control.items,
                                    tooltip: control.tooltip
                                }}
                                onDragStop={(top: number, left: number) => this.updateForm(top, left, control.controlGuid)}
                                onLoad={this.onControlLoad}>
                                <SignatureControl key={control.controlGuid}
                                    control={control}
                                    onControlRemove={this.onControlRemove}
                                    selectedSigner={tempControls ? tempControls.sender : {} as ISigner}
                                    style={this.getSignatureControlStyle(control)}
                                    focusedGroup={this.state.focusedGroup}
                                    onToolTipEdit={this.onToolTipEdit}
                                />
                            </PlaceholderControl>);
                            break;
                        case SignatureControlRole.SFReceipient:
                            controlCollection.push(<PlaceholderControl
                                height={control.height ? control.height : (SignatureControlConstants.ControlHeight * this.state.scale)}
                                id={CONTROL_ID_PREFIX + control.controlGuid}
                                key={CONTROL_KEY_PREFIX + control.controlGuid}
                                ref={(ref) => {
                                    this._controlList.push(ref)
                                }}
                                page={currentPage}
                                width={control.width ? control.width : (SignatureControlConstants.ControlWidth * this.state.scale)}
                                top={pdfViewerTop}
                                left={pdfViewerLeft}
                                name={this.state.selectedESignDocument.fileName}
                                helptext={control.type === SignatureControlType.Radio ? '' : control.tooltip }
                                disabled={false}
                                isRequired={control.required}
                                draggable={control.type === SignatureControlType.Radio ? false : true}
                                data={{
                                    type: control.type,
                                    role: control.role,
                                    signer: control.signer,
                                    items: control.items,
                                    tooltip: control.tooltip
                                }}
                                onDragStop={(top: number, left: number) => this.updateForm(top, left, control.controlGuid)}
                                onLoad={this.onControlLoad}>
                                {renderRecipientControl ? <SignatureControl key={control.controlGuid}
                                    control={control}
                                    onControlRemove={this.onControlRemove}
                                    selectedSigner={tempControls ? tempControls.signer : {} as ISigner}
                                    style={this.getSignatureControlStyle(control)}
                                    focusedGroup={this.state.focusedGroup}
                                    onToolTipEdit={this.onToolTipEdit}
                                /> : null}
                            </PlaceholderControl>);
                            break;
                    }
                });
            }

        }
        return controlCollection;
    }

    private getSignatureControlStyle = (control: ISignatureControl): React.CSSProperties => {


        let controlHeight = 30;
        let fontSize = 16;
        let style: React.CSSProperties = {};

        style.height = (controlHeight * this.state.scale).toString() + "px";
        style.width = control.type == SignatureControlType.CheckBox ? (42 * this.state.scale).toString() + "px" : (150 * this.state.scale).toString() + "px";
        style.zIndex = 5;
        style.display = "block";
        style.fontSize = (fontSize * this.state.scale).toString() + "px";
        return style;
    }


    private updateForm = (controlTop?: number, controlLeft?: number, controlGuid?: string) => {

        const { signingInfo } = this.props;
        const { selectedESignDocument } = this.state;
        const pageSize: PageSize = this.getCurrentPageSize();


        const currentDocument = signingInfo.documentInfo.find(x => x.documentGuid == selectedESignDocument.documentGuid);
        let signatureData: ISignatureData | undefined = currentDocument?.signatureData.find(x => x.pageNo == this.state.currentPage);

        if (signatureData) {
            if (controlGuid && controlLeft != null && controlTop != null) {
                let controlCheck = this._controlList.filter(i => i?.props?.id.replace(CONTROL_ID_PREFIX, "") == controlGuid)[0];
                controlCheck.state.left = controlLeft;
                controlCheck.state.top = controlTop;
            }
            StepsHelper.updateFormControls(this._controlList, signatureData, pageSize);
        }
    }

    private getCurrentPageSize = (): PageSize => {
        const { currentPage } = this.state;
        const pageSize: PageSize = this._viewPanel.getPageSize(currentPage, 1);
        return pageSize;
    }


    onDocumentClick = (docuementKey: string) => {


        const esignDocument = this.props.documentData.find(x => x.documentGuid === docuementKey) as IDocumentData;
        this.setState({
            selectedESignDocument: esignDocument,
            currentPage: 1
        }, () => {
            if (this.state.selectedESignDocument && !this.state.selectedESignDocument.isPDFloaded) {
                this.props.loadRecognizedDataForSelectedDocument(this.state.selectedESignDocument.documentGuid);
                var selected: IDocumentData = { ...this.state.selectedESignDocument };
                if (this.state.selectedESignDocument.sasUrl && this.state.selectedESignDocument.sasUrl != "") {
                    if (!this.state.selectedESignDocument.isPDFloaded && !this.state.selectedESignDocument.pdf) {
                        loadPdf(this.state.selectedESignDocument.sasUrl).then((data: IPdfDocumentFacade) => {
                            selected.pdf = data;
                            selected.isPDFloaded = true;
                            this.props.updateDocumentData(selected);
                        }).catch(err => {
                            logger.trackError(`Error occurred at loadPDF - SFtep3:  ${validateError(err)}`);
                        })
                    }
                    this.setState({ selectedESignDocument: selected, isBusy: false }, () => { this.forceUpdate(); });
                }
            }
            else {
                if (this.props.isRecipientsUpdated) {
                    this.props.loadRecognizedDataForSelectedDocumentIfRecipientsUpdated(this.state.selectedESignDocument.documentGuid);
                }
            }
            this.onPageChange({ page: 1, scale: this.state.scale });
        });


    }
    onBookmarkPageClick = (pageNo: number, docuementKey?: string) => {
        const index = this.props.documentData.findIndex(x => x.documentGuid === docuementKey);

        //Temp Solution for pdf viewer page change not triggering for first page of documents
        if (this.state.currentPage === pageNo && this.state.selectedESignDocument.documentGuid !== docuementKey && pageNo === 1) {
            this.setState({ selectedESignDocument: { ...this.props.documentData[index] }, currentPage: pageNo, scale: this.state.scale });
        } else {
            this.setState({ selectedESignDocument: { ...this.props.documentData[index] }, currentPage: pageNo, scale: this.state.scale });
        }
        this.onPageChange({ page: pageNo, scale: this.state.scale });
    }

    createUndoButton = () => {

        return this.state.undoDisable ? <div className="icon-bar d-none d-sm-block"><a className="icon disabled" title='' style={{ pointerEvents: 'none' }}><i className='fas fa-reply undo'></i></a> </div>
            : <div className="icon-bar d-none d-sm-block"><a className="icon" onClick={this.onControlUndo} title='Signature control position cannot be undone using Undo button.' ><i className='fas fa-reply undo'></i></a></div>;
    }

    private getSignatureControls = (): SFPdfPageSignatureControls => {
        return {
            signatureControls: this.filterSignatureControls(),
            signer: this.state.selectedSigner,
            focusedGroup: this.state.focusedGroup,
            sender: this.state.sender
        } as SFPdfPageSignatureControls;
    }
    private filterSignatureControls = (): ISignatureControl[] => {
        let signControls: ISignatureControl[] = [];
        const index = this.props.signingInfo.documentInfo.findIndex(x => x.documentGuid == this.state.selectedESignDocument.documentGuid);
        if (index != Helper.NO_INDEX) {

            const signatureData = this.props.signingInfo.documentInfo[index].signatureData;
            if (signatureData && signatureData.length > 0) {
                let index2 = signatureData.findIndex(x => x.pageNo == this.state.currentPage);

                if (index2 != Helper.NO_INDEX) {
                    signatureData[index2].signatureControls.map(function (value, index) {
                        signControls.push({
                            controlGuid: value.controlGuid,
                            signer: value.signer,
                            role: value.role,
                            type: value.type,
                            top: value.top,
                            left: value.left,
                            height: value.height,
                            width: value.width,
                            tooltip: value.tooltip,
                            required: value.required,
                            items: value.items
                        });
                    });
                }
            }
        }

        return signControls;
    }

    private onControlRemove = (control: ISignatureControl, isHist?: boolean) => {
        let temp_signingInfo: ISigningInfo = Object.assign({}, this.props.signingInfo);
        const documentInfoIndex = temp_signingInfo.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);
        if (documentInfoIndex !== Helper.NO_INDEX) {
            const signatureData = temp_signingInfo.documentInfo[documentInfoIndex].signatureData;
            const signatureDataIndex = signatureData.findIndex(x => x.pageNo === this.state.currentPage);
            if (signatureDataIndex !== Helper.NO_INDEX) {

                signatureData[signatureDataIndex].signatureControls = signatureData[signatureDataIndex].signatureControls.filter(x => x.controlGuid != control.controlGuid);

                //Add the Controls to be deleted to history
                if (!isHist) {

                    //add Signature history object if it does not exist
                    if (!signatureData[signatureDataIndex].signatureControlsHistory) {
                        let signatureControlsHistory: ISignatureControlsHistory[] = [];
                        signatureData[signatureDataIndex].signatureControlsHistory = signatureControlsHistory;
                    }

                    signatureData[signatureDataIndex].signatureControlsHistory.push({ signatureControl: control, controlHistoryType: ControlHistoryType.Removed });
                    this.state.undoDisable ? this.setState({ undoDisable: false }) : "";
                    this.props.updateSFData(temp_signingInfo);
                }
            }
            this._toolbar.forceUpdate();

            this._controlLayer.removeControl("control_" + control.controlGuid);
        }
    }

    private onControlUpdate = (oldControl: ISignatureControl, newControl: ISignatureControl) => {
        let temp_signingInfo: ISigningInfo = Object.assign({}, this.props.signingInfo);
        const documentInfoIndex = temp_signingInfo.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);
        if (documentInfoIndex !== Helper.NO_INDEX) {
            const signatureData = temp_signingInfo.documentInfo[documentInfoIndex].signatureData;
            const signatureDataIndex = signatureData.findIndex(x => x.pageNo === this.state.currentPage);
            
            if (signatureDataIndex !== Helper.NO_INDEX) {
                const updatedSignatureControls = signatureData[signatureDataIndex].signatureControls.map((x) => {
                    if (x.controlGuid === oldControl.controlGuid) {
                        return {
                            ...x,
                            top: newControl.top,
                            left: newControl.left,
                        };
                    }
                    return x;
                });
                signatureData[signatureDataIndex].signatureControls = updatedSignatureControls;
                this.props.updateSFData(temp_signingInfo);
            }
            this._toolbar.forceUpdate();
        }
    }

    //Add to history

    //Reset
    private onPageReset = (documentGuid: string) => {

        let temp_signingInfo: ISigningInfo = Object.assign({}, this.props.signingInfo);
        let _self = this;
        const documentData = this.props.documentData.filter(i => i.documentGuid === documentGuid)
        const documentInfoIndex = temp_signingInfo.documentInfo.findIndex(x => x.documentGuid === documentGuid);
        if (documentInfoIndex !== Helper.NO_INDEX) {

            if(documentData && !documentData[0].isPDFloaded){
                this.props.loadRecognizedDataForUnloadedDocuments(documentData);
            }

            if (this.state.selectedESignDocument.documentGuid === documentGuid) {

                //Remove all controls
                document.querySelectorAll(".placeholder-control").forEach((element) => {
                    _self._controlLayer.removeControl(element.id);
                })
                document.querySelectorAll(".choosable-control-group").forEach((element) => {
                    _self._controlLayer.removeControl(element.id);
                })

                this.state.undoDisable ? "" : this.setState({ undoDisable: true });
                this._toolbar.forceUpdate();
            }
            temp_signingInfo.documentInfo[documentInfoIndex].signatureData = [];
            this.props.updateSFData(temp_signingInfo);
        }
    }
    //Undo
    private onControlUndo = () => {
        let temp_signingInfo: ISigningInfo = Object.assign({}, this.props.signingInfo);
        const documentInfoIndex = temp_signingInfo.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);
        let signaturehistorys = temp_signingInfo.documentInfo[documentInfoIndex].signatureData.find(x => x.pageNo === this.state.currentPage)?.signatureControlsHistory
        let signaturehistory = signaturehistorys?.pop();


        //Switch to Add,Remove and update position
        switch (signaturehistory?.controlHistoryType) {
            case ControlHistoryType.Added:
                this.onControlRemove(signaturehistory.signatureControl, true);
                break;

            case ControlHistoryType.Updated:
                this.onControlRemove(signaturehistory.signatureControl, true);
                this.addSignatureControl(signaturehistory.signatureControl.type, signaturehistory.signatureControl.role, signaturehistory.signatureControl.signer, signaturehistory.signatureControl.left, signaturehistory.signatureControl.top, true, signaturehistory.signatureControl.controlGuid);
                break;

            case ControlHistoryType.Removed:
                this.addSignatureControl(signaturehistory.signatureControl.type, signaturehistory.signatureControl.role, signaturehistory.signatureControl.signer, signaturehistory.signatureControl.left, signaturehistory.signatureControl.top, true, signaturehistory.signatureControl.controlGuid);
                break;

            default:
        }

        //Disable undo button
        if (!signaturehistorys || signaturehistorys.length === 0) {
            this.state.undoDisable ? "" : this.setState({ undoDisable: true });
            this._toolbar.forceUpdate();
        }
        this._controlLayer.forceUpdate();


        this.props.updateSFData(temp_signingInfo);
        //call onControlUndoAdd



    }

    //Add to control history





    private onToolTipEdit = (control: ISignatureControl) => {


        //Temp solution pdf viewer controls props are not getting updated when parent state is updated need to check later
        let tempControl = this.props.signingInfo.documentInfo.find(x => x.documentGuid === this.state.selectedESignDocument.documentGuid)?.signatureData.
            find(y => y.pageNo === this.state.currentPage)?.signatureControls.find(z => z.controlGuid === control.controlGuid);

        this.setState({
            toolTipControl: tempControl ? tempControl : control,
            showToolTipPopup: true
        });
    }

    private onCancelToolTipPopup = () => {
        this.setState({
            toolTipControl: initialSignatureControl,
            showToolTipPopup: false
        });
    }

    private onSaveToolTip = (control: ISignatureControl) => {

        let temp_signingInfo: ISigningInfo = Object.assign({}, this.props.signingInfo);
        const documentInfoIndex = temp_signingInfo.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);
        if (documentInfoIndex !== Helper.NO_INDEX) {
            const signatureData = temp_signingInfo.documentInfo[documentInfoIndex].signatureData;
            const signatureDataIndex = signatureData.findIndex(x => x.pageNo === this.state.currentPage);
            if (signatureDataIndex !== Helper.NO_INDEX) {
                let signatureControls = signatureData[signatureDataIndex].signatureControls;

                for (let i = 0; i < signatureControls.length; i++) {

                    let signControl = signatureControls[i];

                    if (signControl.controlGuid === control.controlGuid) {

                        signControl.tooltip = control.tooltip;
                        signControl.required = control.required;

                        if(signControl.type === SignatureControlType.Radio && signControl.items.length != Number(control.tooltip)){
                            signControl.height = (SignatureControlConstants.ControlHeight_RadioButton + Number(control.tooltip) * 20) * this.state.scale;
                            signControl.items = this.addControlItems(control.tooltip);
                        }
                        break;
                    }
                }
                this.props.updateSFData(temp_signingInfo);
            }
        }

        this.setState({
            toolTipControl: initialSignatureControl,
            showToolTipPopup: false
        });
    }

    private getFocusedGroup = (signatureControlRole: SignatureControlRole): SignatureGroupType => {
        let signatureGroupType = SignatureGroupType.None;
        if (signatureControlRole === SignatureControlRole.SFReceipient) {
            signatureGroupType = SignatureGroupType.Receiver;
        }
        else if (signatureControlRole === SignatureControlRole.SFSender) {
            signatureGroupType = SignatureGroupType.Sender;
        }
        return signatureGroupType;
    }

    onPageChange = (pageProps: PageProperties) => {

        this.setState({ currentPage: pageProps.page, scale: pageProps.scale ? pageProps.scale : this.state.scale }, () => {
            this._viewPanel.gotoPage(pageProps.page);
            const documentInfoIndex = this.props.signingInfo.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);
            let signData = this.props.signingInfo.documentInfo[documentInfoIndex].signatureData.find(x => x.pageNo === pageProps.page);
            // if signature history is missing add it
            if (signData && !signData.signatureControlsHistory) {
                let signatureData: ISignatureControlsHistory[] = [];
                signData.signatureControlsHistory = signatureData;
            }
            //Manage undo button on page change
            if (!signData || signData.signatureControlsHistory?.length === 0) {
                this.state.undoDisable ? "" : this.setState({ undoDisable: true });
            }
            else {
                this.state.undoDisable ? this.setState({ undoDisable: false }) : "";
            }
            if (this.props.signingInfo.documentInfo[documentInfoIndex].signatureData.length === 0) {
                //Delete elements of cleared document
                document.querySelectorAll(".placeholder-control").forEach((element) => {
                    this._controlLayer.removeControl(element.id);
                })
                document.querySelectorAll(".choosable-control-group").forEach((element) => {
                    this._controlLayer.removeControl(element.id);
                })
            }
            this._toolbar.forceUpdate();
            this.updateForm();
        });


        //this.setState({ currentPage: pageProps.page, scale: pageProps.scale ? pageProps.scale : this.state.scale }, () => {

        //    this._toolbar && this._toolbar.handleZoomChange(pageProps.scale ? pageProps.scale : this.state.scale );

        //});
    }

    private getTooltipContent = (controlType: number): any => {
        const { Radio } = SignatureControlType;
        if (controlType === Radio) {
            return { header: "Radio Properties", title: "How many radio button(s) would you like to add?", tooltipContent: "" }
        } else {
            return { header: "Tool Tip", title: "Add Text", tooltipContent: "This text will help the signer to enter the necessary details" };
        }
    }

    public render() {
        return (this.state.isBusy ? (<LoadingOverlay style={{ height: '100%' }}>
            <Loader loading={this.state.isBusy} />
        </LoadingOverlay>) :
            <PdfViewer>
                <Header>
                    <Toolbar
                        ref={(ref: any) => this._toolbar = ref}
                        customToolbarOptions={this.createUndoButton()}
                        customToolbarPosition={'left'}
                        hideReadOnly={true}
                        hideRightPanel={false}
                    >
                        <Pagination />
                        <Zoom>
                        </Zoom>
                        <CustomOptions position="left" />

                    </Toolbar>
                </Header>
                <div className="main" style={{
                    height: 'calc(100vh - 305px)',
                    fontSize: '12px'
                }}>
                    <LeftPanel>
                        {
                            <BookmarksPane sectionTitle={"Document(s)"} documentData={this.props.documentData ? this.props.documentData : []}
                                onDocumentSelect={this.onDocumentClick} onPageClick={this.onBookmarkPageClick}
                                isDocumentsLoading={this.props.signingInfo.documentInfo.length == this.props.documentData.length ? false : true}
                                isSelectedDocumentBookmarksLoaded={this.state.selectedESignDocument?.isPDFloaded} reset={this.onPageReset} />
                        }
                    </LeftPanel>
                    {
                        <ControlLayer enableControlsLazyLoad={false} ref={(ref: any) => { this._controlLayer = ref; }} useDefaultNavigationStartControl={false} >
                            {
                                this.renderSignatureControl()
                            }
                        </ControlLayer>
                    }
                    <ViewPanel onDocumentLoad={() => {
                        this.setState({
                            scale: 1
                        })
                    }}
                        ref={(ref: any) => this._viewPanel = ref}

                        onPageChanged={this.onPageChange}
                        onScaleChanged={(scale: number) => {
                            this.setState({
                                scale: scale
                            })
                        }}
                        pageMode={PageMode.SinglePage}
                        defaultPage={this.state.currentPage}
                        url={this.state.selectedESignDocument.sasUrl}
                        disableTextLayer={true}
                        pdfSource={PdfSource.createFromUrl(this.state.selectedESignDocument.sasUrl)}
                    >
                    </ViewPanel>

                    <SignerTooltipPopup
                        control={this.state.toolTipControl}
                        showState={this.state.showToolTipPopup}
                        onHide={this.onCancelToolTipPopup}
                        submitButtonClick={this.onSaveToolTip}
                        content={this.getTooltipContent(this.state.toolTipControl.type)}
                    />

                    <RightPanel>
                        {
                            <SignatureControlPanel
                                title="Recipient(s)"
                                selectedSigner={this.state.selectedSigner}
                                signers={this.state.signers}
                                onSelectBoxChange={this.signerChange}
                                controlBoxItems={signersControlBoxItems}
                                dragStart={this.dragStartSignatureControl}
                                inputType={InputType.Dropdown}
                                dragEnd={this.dragEndSignatureControl}
                            />
                        }
                    </RightPanel>

                </div>
            </PdfViewer>
        );
    }
}