import { addTask } from 'domain-task';
import { API_BASE_URL } from '../../utils/AppConstants';
import { Reducer } from 'redux';
import * as Constants from '../components/helper/Constants';
import { AppThunkAction } from '../store';
import { actionTypes } from '../store/ActionTypes';
import { NotificationAction, StatusType } from '../store/common/NotificationStore';
import { handleResponse } from './Library';
import { AuthenticationType, ISigningInfo } from '../models/SigningInfo';
import { getAuthenticationParam } from '../components/helper/HelperFunctions';
import { ISignatureFlowSettings } from '../models/SignatureFlowSettings';
import { logger } from '../../routes/LoggedIn';
import { ResetSignatureFlowReportDocumentPagesAction } from './ReportsCommon/KnownTypes';

export const callbackType = {
    success: "success",
    error: "error",
    documentProcessedError: "document already processed"
}

export interface IClientAuthenticationDictionary {
    [email: string]: IClientAuthenticationInfo;
}

export interface IClientAuthenticationInfo {
    email: string;
    countryCode?: string;
    mobileNumber?: string;
    questionAnswer?: IQuestionAnswerDic;
    first4SSN?: string;
    last4SSN?: string;

    isLoading: boolean;
}

export interface IQuestionAnswerDic {
    [email: string]: string;
}

export interface ISendForSignatureData {
    clientAuthenticationInfo: IClientAuthenticationDictionary;

    //Meta infomation
    isLoading: boolean;
    error: boolean;
    message: string;
}

export interface ISendForSignatureViewModel {
    signingInfo: ISigningInfo;
    signatureFlowSettings: ISignatureFlowSettings;
}


export interface ReceiveClientAuthenticationParam {
    type: actionTypes.RECEIVE_CLIENT_AUTHENTICATION_PARAM;
    param: string;
    email: string;
    question?: string;
    authenticationType: AuthenticationType;
}

export interface RequestClientAuthenticationParam {
    type: actionTypes.REQUEST_CLIENT_AUTHENTICATION_PARAM;
    email: string;
}

type KnownAction = RequestClientAuthenticationParam |
    ReceiveClientAuthenticationParam |
    NotificationAction |
    ResetSignatureFlowReportDocumentPagesAction;

export const actionCreators = {
    sendForSignature: (signingInfo: ISigningInfo, signatureFlowSettings: ISignatureFlowSettings, callback: (type: string, data?: any) => void):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            const sendForSignature: ISendForSignatureViewModel = {
                signatureFlowSettings: signatureFlowSettings,
                signingInfo: signingInfo
            };
            const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SendForSignature', {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(sendForSignature),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'traditional': 'true',
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                }
            }).then(handleResponse)
                .then(() => {
                    signingInfo.recipients.every(r => r.authenticationType.toString() !== AuthenticationType.None.toString()) &&
                        signingInfo.recipients.forEach((recipient) => {
                            let param = getAuthenticationParam(recipient);
                            dispatch({
                                type: actionTypes.RECEIVE_CLIENT_AUTHENTICATION_PARAM,
                                param: param ? param : "",
                                email: recipient.emailAddress,
                                authenticationType: recipient.authenticationType,
                                question: recipient.question
                            });
                    })
                    dispatch({ type: actionTypes.RESET_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES });
                    callback(callbackType.success);
                })
                .catch(error => {
                    if(error && error.statusText && error.status == 400){
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: error.statusText,
                            statusType: StatusType.Error
                        });
                        callback(callbackType.documentProcessedError)
                    }else{
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.SignatureFlowConstants.SendForSignatureFailed,
                        statusType: StatusType.Error, statusCode:error?.status
                    });
                    }   
                    callback(callbackType.error);

                    logger.trackError(`sendForSignature failed for the request having signingInfo ${JSON.stringify(signingInfo)} with error ${JSON.stringify(error)}`)
                });
            addTask(fetchTask);
        },

    getAuthenticationParameter: (email: string, authenticationType: AuthenticationType, callback: () => void, question?: string):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SendForSignature/GetAuthenticationParameterAsync?email='
                + email + '&authenticationType=' + authenticationType + '&question=' + question , {
                method: 'GET',
                credentials: 'include',
            }).then(handleResponse)
                .then(json => json as Promise<string>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_CLIENT_AUTHENTICATION_PARAM,
                        param: data,
                        email: email,
                        authenticationType: authenticationType,
                        question: question
                    });

                    callback();

                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.RECEIVE_CLIENT_AUTHENTICATION_PARAM,
                        param: "",
                        email: email,
                        authenticationType: authenticationType
                    });

                    logger.trackError(`getAuthenticationParameter failed for the request having parameters ${JSON.stringify({ email: email, authenticationType: authenticationType })} with error ${error.message}`)
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_CLIENT_AUTHENTICATION_PARAM, email: email });
        },
    deleteDocumentsfromBlob : (documentGuids: string[], signingId: number, callback: () => void) : AppThunkAction<KnownAction> => (dispatch, getState) => {
        const payload = {
            documentGuids: documentGuids,
            signingId: signingId
        };
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SignatureFlowReportPopup/DeleteInProcessDocuments', {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'traditional': 'true',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        }).then(handleResponse)
            .then(json => json as Promise<string>)
            .then(data => {                
                if(callback){
                    callback();
                }
            })
            .catch(error => {
                logger.trackError(`deleteDocumentfromBlob failed for the request with error ${error.message}`)
            });
        addTask(fetchTask);
    },
    saveSigningInfoDocument : (signingInfo: ISigningInfo, signatureFlowSettings: ISignatureFlowSettings, callback: (type: string, data?: any) => void) : AppThunkAction<KnownAction> => (dispatch, getState) => {
        const sendForSignature: ISendForSignatureViewModel = {
            signatureFlowSettings: signatureFlowSettings,
            signingInfo: signingInfo
        };
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SendForSignature/SaveSigningInfoDocument', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(sendForSignature),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'traditional': 'true',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        }).then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.RESET_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES });
                callback(callbackType.success);
            })
            .catch(error => {
                if(error && error.statusText && error.status == 400){
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.statusText,
                        statusType: StatusType.Error
                    });
                }else{
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SignatureFlowConstants.SaveSendForSignatureFailed,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                }   
                callback(callbackType.error);

                logger.trackError(`saveSigningInfoDocument failed for the request having signingInfo ${JSON.stringify(signingInfo)} with error ${JSON.stringify(error)}`)
            });
        addTask(fetchTask);
    },
}

const clientAuthenticationDic: IClientAuthenticationDictionary = {};

const unloadedState: ISendForSignatureData = {
    clientAuthenticationInfo: { ...clientAuthenticationDic },

    //meta
    error: false,
    isLoading: false,
    message: ""

} as ISendForSignatureData;

export const reducer: Reducer<ISendForSignatureData> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_CLIENT_AUTHENTICATION_PARAM:
            let newState = { ...state };
            newState.isLoading = true;
            if (!newState.clientAuthenticationInfo || !Array.isArray(newState.clientAuthenticationInfo)) {
                newState.clientAuthenticationInfo = {};
                if (!newState.clientAuthenticationInfo[action.email]) {
                    newState.clientAuthenticationInfo[action.email] = { email: action.email, isLoading: true };
                }
            }
            return newState;
        case actionTypes.RECEIVE_CLIENT_AUTHENTICATION_PARAM:
            var received = { ...state };
            received.isLoading = false;
            if (!received.clientAuthenticationInfo || !Array.isArray(received.clientAuthenticationInfo)) {
                received.clientAuthenticationInfo = {};
            }
            if (action.param !== undefined && action.param !== "") {
                if (!received.clientAuthenticationInfo[action.email]) {
                    received.clientAuthenticationInfo[action.email] = { email: action.email, isLoading: false };
                }
                let authInfo = received.clientAuthenticationInfo[action.email];
                switch (action.authenticationType.toString()) {
                    case AuthenticationType.AccessCodeSMS.toString():
                        let number = (action.param as string).split('-');
                        authInfo.countryCode = number[0];
                        authInfo.mobileNumber = number[1];
                        break;
                    case AuthenticationType.First4SSN.toString():
                        authInfo.first4SSN = action.param;
                        break;
                    case AuthenticationType.Last4SSN.toString():
                        authInfo.last4SSN = action.param;
                        break;
                    case AuthenticationType.QuestionAnswer.toString():
                        if (!authInfo.questionAnswer) {
                            authInfo.questionAnswer = {};
                        }
                        if (action.question && authInfo.questionAnswer) {
                            authInfo.questionAnswer[action.question] = action.param;
                        }
                        break;
                }
                received.clientAuthenticationInfo[action.email] = authInfo;
            }
            return received;
    }
    return state;
}


 
