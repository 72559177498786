import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/types';
import * as SSSuiteStore from 'src/SignatureFlow/store/common/SSSuiteStore'; 
import SSSuiteLayoutWrapper from './SSSuiteLayoutWrapper';
import * as OneTimePasswordStore from '../../../store/common/OneTimePasswordStore';
import * as CompanyStore from '../../../store/Company/CompanyStore';
import * as UserLoginHistory from '../../../store/userManagement/UserLoginHistoryStore';
import * as MyDownloadsStore from '../../../store/MyDownloadsStore';
import * as UserProfileStore from '../../../store/userManagement/UserProfileStore';
import * as UserSettingsStore from '../../../store/userManagement/UserSettingStore';
import * as SFUserStore from '../../../store/SFUserStore';
import * as SSSuiteCompanySettingsStore from '../../../store/Company/SSSuiteCompanySettingsStore'
import * as SignatureFlowReportCustomColumnStore from '../../../store/ReportsCommon/SignatureFlowReportCustomColumnStore';
import * as OfficeLocationStore from '../../../store/MultiOffice/OfficeLocationStore';
import * as SignatureFlowSettingsStore from '../../../store/SignatureFlowSettingsStore';
import * as mfaSettingStore from '../../../store/Company/SuiteMFASettingStore';

export default connect(
    (state: ApplicationState) => ({
        companyData: state.companyData,
        SSSuiteCompanySettings: state.SSSuiteCompanySettingsStore,
        rbacStore: state.SSSuiteStore,
        profile: state.userProfile,
        settings: state.userSettings,
        userLoginHistory: state.userLoginHistory,
        oneTimePasswordData: state.oneTimePasswordStore,
        sfUserState: state.sfUserList,
        PostAuthStoreState: state.postAuthDataStore,
        authState:state.auth,
        reportCustomColumns: state.signatureFlowReportCustomColumns,
        myDownloads: state.myDownloads,
        officeLocationState: state.officeLocation,
        userOfficeLocationState: state.userOfficeLocation,
        mfaSettings: state.mfaSettings
    }),
    {
        ...SSSuiteStore.actionCreators,
        ...UserProfileStore.actionCreators,
        ...UserSettingsStore.actionCreators,
        ...CompanyStore.actionCreators,
        ...SSSuiteCompanySettingsStore.actionCreators,
        ...UserLoginHistory.actionCreators,
        ...OneTimePasswordStore.actionCreators,
        ...SFUserStore.actionCreators,
        ...SignatureFlowReportCustomColumnStore.actionCreators,
        ...MyDownloadsStore.actionCreators,
        ...OfficeLocationStore.actionCreators,
        ...SignatureFlowSettingsStore.actionCreators,
        ...mfaSettingStore.actionCreators
    }
)(SSSuiteLayoutWrapper);