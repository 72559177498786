import * as React from 'react';
import { Card, Accordion  } from 'react-bootstrap';
import { IDocumentData } from './../../models/DocumentInfo';
import { Accordian } from './Accordian';
import { PageThumbView } from './PageThumbView';
import { IPageItem } from '../helper/PdfHelper';
import { IPdfDocumentFacade } from '../../../Core/Utilities/PdfDocumentFacade';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

export interface IBookMarksPaneProps {
    sectionTitle?: any;
    documentData: IDocumentData[],
    onDocumentSelect?: (documentGuid: string) => void;
    onPageClick?: (pageNo: number, documentGuid?: string) => void;
    isDocumentsLoading?: boolean;
    reset: (documentGuid: string) => void;
    isSelectedDocumentBookmarksLoaded?: boolean;
}

export class BookmarksPane extends React.Component<IBookMarksPaneProps, {}> {
    private _viewPanel: any;
    private _bookmarkPanel: any;
    constructor(props: IBookMarksPaneProps) {
        super(props);
    }

    private setPageItems = (details: number) => {
        let pagetItems: IPageItem[] = [];
        Array.from({ length: details }, (i, index) => index + 1)
            .map((pageNo) => {
                let pageItem: IPageItem = {} as IPageItem;
                pageItem.pageNo = pageNo;
                pagetItems.push(pageItem);
            });
        return pagetItems;
    }

    public render() {
        let _self = this;
        let eSignDocuments = _self.props.documentData ? _self.props.documentData : [];
        return <div className="bookmarksPane" style={{ display: "initial" }}>
            <Accordion defaultActiveKey="0">
                <Card className="border-radius-0 marB05" style={{ clear: "both" }}>
                    <Accordion.Toggle eventKey="0">
                        <Card.Header style={{ padding: '10px' }}>
                            <Card.Title>
                                {this.props.sectionTitle}
                            </Card.Title>
                        </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: '0px' }}>
                            <LoadingOverlay style={{ height: '100%' }}>
                                {
                                    eSignDocuments && eSignDocuments.map(function (value, index) {
                                        return <Accordian key={value.documentGuid} uniqueId={value.documentGuid} reset={_self.props.reset} header={value.fileName as string}
                                            onHeaderClick={() => {
                                                _self.props.onDocumentSelect && _self.props.onDocumentSelect(value.documentGuid ? value.documentGuid : "")
                                            }}
                                            content={
                                                <PageThumbView
                                                    pages={_self.setPageItems(value.pdf ? value.pdf.getLastPage() : 0)}
                                                    onPageSelect={(pageNo) => {
                                                        _self.props.onPageClick && _self.props.onPageClick(pageNo, value.documentGuid)
                                                    }}
                                                    pdfDocument={value.pdf as IPdfDocumentFacade} />
                                            }
                                            loading={_self.props.isSelectedDocumentBookmarksLoaded ? false : true} />
                                    })
                                }
                                <Loader loading={_self.props.isDocumentsLoading ? true : false} />
                            </LoadingOverlay>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    }
}