import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { signoutRedirect } from 'src/oidcClient/userService';
import { LogoutCause } from 'src/SignatureFlow/models/user/UserSettingsModels';
import { clearAllTemporaryFilter } from '../common/Filters';
import { UserAutoLogoutConstants, UserAutoLogoutMessageConstants } from '../helper/Constants';
import { SessionStore, deleteCookie, getCookie, setCookie } from '../helper/HelperFunctions';

interface UserAutoLogoutModalProps {
    openModal: boolean;
    logoutCause:LogoutCause;
}
export const UserAutoLogoutModal: React.FC<UserAutoLogoutModalProps> = props => {
    
    const [showAutoLogoutModal, setShowAutoLogoutModal] = useState(false);
    let closeModalClicked = false;
    useEffect(() => {
        if (props.openModal && props.logoutCause != LogoutCause.None) {
            setShowAutoLogoutModal(props.openModal);
            setCookie("UserPermissionChanged", "true", 1);
        }
        else 
        { 
            LogOutUserOnReloadIfPermissionsChanged() 
        }
    }, [props.openModal, props.logoutCause]);

    const LogOutUserOnReloadIfPermissionsChanged = () => {
        if (getCookie("UserPermissionChanged")) {
            onCloseModal();
        }
    }

    const onCloseModal = (): void => {
        if(!closeModalClicked){
            clearAllTemporaryFilter();        
            SessionStore.remove("isVisited");
            deleteCookie("UserPermissionChanged");
            signoutRedirect();   
            closeModalClicked = true; 
        }
    }

    return (
        <div className="margin-tbl-20">
            <Modal show={showAutoLogoutModal} onHide={onCloseModal} className="user-autologout-modal">
                <Modal.Header>
                    <Modal.Title className='custom-modal-header'>
                    <h3 className='user-autologout-modal-title'>{UserAutoLogoutConstants.PermissionChanges}</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col lg={12}>
                                <Row className="user-autologout-modal-content">
                                {UserAutoLogoutMessageConstants.PermissionChanges}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="user-autologout-modal-footer-btn"
                        variant="custom" 
                        onClick={onCloseModal}>
                        Login
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
