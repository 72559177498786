import * as React from 'react';
import { Modal, Alert, Button } from 'react-bootstrap';
import { IAuthenticationQuestion, initialQuestion } from '../../models/SignatureFlowSettings';
import * as SignatureFlowSettingsStore from '../../store/SignatureFlowSettingsStore';
import { SecuritySettingConstants } from '../helper/Constants';
import { WarningIcon } from '../svg/CustomSvgIcons';
import { VenusNotifier } from '../helper/VenusNotifier';

interface IAddQuestionProps {
    showState: boolean;
    onHide: () => void;
    submitButtonClick: (question: IAuthenticationQuestion, isSetDefault: boolean) => void;
    buttonText: string;
    selectedQuestion: IAuthenticationQuestion;
    title: string;
    defaultQuestionId: number;
    isUpdate: boolean;
    questions: SignatureFlowSettingsStore.IAuthenticationQuestionDictionary;
}

interface IAddQuestionPopup {
    question: IAuthenticationQuestion;
    alert: string;
    errorMessage: string;
    isSetDefault: boolean;
    showAlert: boolean;
}

export class AddQuestionPopup extends React.Component<IAddQuestionProps, IAddQuestionPopup>{
    state = {
        question: initialQuestion,
        alert: "warning",
        errorMessage: "",
        isSetDefault: false,
        showAlert: false
    }

    UNSAFE_componentWillReceiveProps(nextProps: IAddQuestionProps) {
        this.setState({
            question: Object.assign({}, nextProps.selectedQuestion),
            alert: "warning",
            errorMessage: "",
            isSetDefault: nextProps.defaultQuestionId != 0 && nextProps.defaultQuestionId == nextProps.selectedQuestion.id,
            showAlert: false
        })
    }

    private onCancelButtonClick = () => {
        this.props.onHide()
        this.setState({
            question: initialQuestion,
            alert: "warning",
            errorMessage: "",
            isSetDefault: false,
            showAlert: false
        })
    }

    private onAddButtonClick = () => {
        if (this.state.question.title.trim() == "") {
            VenusNotifier.Error(SecuritySettingConstants.ValidateTitle,"");
            return false;
        }
        else if (this.state.question.text.trim() == "") {
            VenusNotifier.Error(SecuritySettingConstants.ValidateText,"");
            return false;
        }
        if (this.checkDuplicateExistance(this.state.question) > 0) {
            VenusNotifier.Error(SecuritySettingConstants.SaveQuestionTitleError,"");
            return false;
        }
        let question = this.state.question;
        question.text = question.text.trim();
        this.props.submitButtonClick(question, this.state.isSetDefault);
        this.setState({ question: initialQuestion });
    }

    private checkDuplicateExistance = (question: IAuthenticationQuestion) => {
        let count: number = 0;
        Object.keys(this.props.questions).map((key, index) => {
            if (this.props.questions[parseInt(key)].authenticationQuestion.title.trim().toLowerCase() == question.title.trim().toLowerCase()
                && this.props.questions[parseInt(key)].authenticationQuestion.id != question.id)
                count++;
        });
        return count;
    }

    private onTitleChange = (event: any) => {
        let question = Object.assign({}, this.state.question);
        question.title = event.target.value;
        this.setState({ question: question });
    }

    
    private onTextChange = (event: any) => {
        let question = Object.assign({}, this.state.question);
        question.text = event.target.value;
        this.setState({ question: question });
    }

    private onSetDefaultClick = (isTrue: boolean) => {
        if (isTrue) {
            this.setState({ alert: "warning", errorMessage: SecuritySettingConstants.SetDefaultQuestionWarning, showAlert: true })
        }
        else {
            this.setState({ alert: "warning", errorMessage: SecuritySettingConstants.RemoveDefaultQuestionWarning, showAlert: true })
        }
        this.setState({
            isSetDefault: isTrue,
        })
    }

    public render() {
        return (
            <div style={{ position: 'relative' }}>
                <Modal show={this.props.showState} onHide={this.onCancelButtonClick} className='add-security-question sigflow-settings-popup' centered={true} backdrop="static">
                    <Modal.Header className='blueModalHeader' closeButton>
                        <Modal.Title>
                            {this.props.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='fontSize14-all'>
                        <div className="col-sm-9 col-lg-9 font16 custom-alert-warning-container"  hidden={!this.state.showAlert}>
                            <div className='custom-alert-warning'> 
                                <span style={{fontSize:'16px'}}>
                                    <span style={{paddingLeft:'7px', paddingRight:'8px'}}>
                                        <WarningIcon fill='#0973BA' marginBottom={'4px'} />
                                    </span>
                                {this.state.errorMessage}
                                </span>       
                            </div>
                        </div>
                        <div className="form-group dispFR">
                            <label>Title</label>
                            <input type="text"
                                data-test-auto="340F17FB-91CD-40C2-B84C-6D2E21990D75"
                                className="form-control"
                                placeholder="Question Title"
                                maxLength={200}
                                value={this.state.question.title}
                                onChange={this.onTitleChange}
                                data-lpignore="true">
                            </input>
                        </div>
                        <div className="form-group dispFR">
                            <label>Question</label>
                            <textarea
                                data-test-auto="0853F052-EE10-4DBE-B461-D7507BF9DF4C"
                                className="form-control"
                                placeholder="Please enter question text"
                                rows={5}
                                value={this.state.question.text}
                                onChange={this.onTextChange}                                
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-unset padL16'>
                        <div className={"custom-curve-check-box"} >
                            <div className="form-check">
                                <input
                                    id={'add-security-question-default'}
                                    className="form-check-input magic-checkbox" 
                                    type="checkbox" 
                                    value="" 
                                    checked={this.state.isSetDefault}
                                    disabled={this.props.defaultQuestionId == this.props.selectedQuestion.id}
                                    onChange={(event) => this.onSetDefaultClick(event.target.checked)}>
                                </input>
                                <label htmlFor={'add-security-question-default'} 
                                        className={"form-check-label "+ ( this.state.isSetDefault ? "" : "")}>
                                    <span className='fontSize14'>Set as Default</span>
                                </label>
                            </div>
                        </div>
                        <div className='modal-submission col-sm text-right'>
                            <Button
                                data-test-auto="90A9BBDE-5FC8-405A-A681-90E4E47F5631"
                                variant='default'
                                className='btn-white modal-footer-button-cancel'
                                onClick={this.onCancelButtonClick} >
                                Cancel
                            </Button>
                            <Button
                                variant='info'
                                className='modal-footer-button-save'
                                onClick={this.onAddButtonClick}
                                data-test-auto="58CCAEDA-F7CC-41AE-AFC1-4688CD67A0A9">
                                {this.props.buttonText}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}