export enum MailEvent {
    None,
    Click = 1,
    Delivered = 2,
    Opened = 3,
    Processed = 4,
    Dropped = 5,
    Bounce = 6,
    Deferred = 7,
    SpamReport = 8,
    ScheduleFailed = 9
}