import {  addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../..';
import { actionTypes } from '../../ActionTypes';
import { API_BASE_URL } from '../../../../utils/AppConstants';
import { StatusType, NotificationAction } from '../../common/NotificationStore';
import { handleResponse } from '../../Library';
import {
    RequestDraftReportDocuments, ReceiveDraftReportDocuments,ResetSignatureFlowReportDocumentPagesAction,RequestSignatureFlowReportDocumentPagesAction,
    ReceiveSignatureFlowReportDocumentPagesAction,ReceiveSignatureFlowReportDocumentPopupModal,ResetDraftReportDocuments,RecieveDraftDocumentsLockedState
} from '../../ReportsCommon/KnownTypes';
import { ISignatureFlowReportTableModel, ISignatureFlowReportState } from '../../../models/SignatureFlowReportTableState';
import { ResetSigningInfoSettingsAction } from '../../SigningInfoSettingsStore';
import { validateError } from '../../../components/helper/Validations';

import { logger } from '../../../../routes/LoggedIn';
import { DraftReportPaths } from 'src/SignatureFlow/components/helper/UrlPaths';

type KnownAction =
    RequestDraftReportDocuments |
    ReceiveDraftReportDocuments |
    ResetDraftReportDocuments |
    RequestSignatureFlowReportDocumentPagesAction |
    ReceiveSignatureFlowReportDocumentPagesAction |
    ReceiveSignatureFlowReportDocumentPopupModal |
    ResetSignatureFlowReportDocumentPagesAction |
    NotificationAction |
    ResetSigningInfoSettingsAction |
    RecieveDraftDocumentsLockedState ;

type dispatchAction =
    RequestDraftReportDocuments |
    ReceiveDraftReportDocuments |
    ResetDraftReportDocuments |
    RecieveDraftDocumentsLockedState ;

 
export interface ISignatureDraftReportState extends ISignatureFlowReportState {
    isDocumentsLocked :boolean;
}

export const actionCreators = {

    requestSignatureDraftDocuments: (query: string, reload: boolean = false, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        if (reload || query !== state.DraftReportDocuments.query) {
            let page = state.signatureFlowReportsDocumentPages[query];
            if (!reload && page) {
                dispatch({ type: actionTypes.REQUEST_DRAFT_REPORT_DOCUMENTS, query: query });
                dispatch({ type: actionTypes.RECEIVE_DRAFT_REPORT_DOCUMENTS, query: query, table: page.signatureFlowReportTableModel });
                return;
            }
            const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReports/GetSignatureDraftReports' + query, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<ISignatureFlowReportTableModel>)
                .then(data => {
                    if (callback) {
                        callback();
                    }
                    let updatedState = getState();
                    if(updatedState.DraftReportDocuments.query == query){ //This condition is added whatever the latest query (call) is, it should be updated in the store.
                        dispatch({ type: actionTypes.RECEIVE_DRAFT_REPORT_DOCUMENTS, query: query, table: data });
                        dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES, query: query, table: data, totalRowCount: data.count });
                        dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_POPUP_MODAL, signatureFlowReportDetails: data.documents });
                    }
                })
                .catch((error) => {
                    const message = validateError(error);
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: message, statusType: StatusType.Error, statusCode:error?.status });

                    logger.trackError(`requestSignatureDraftDocuments failed for the request having query ${query} with error ${message}`)
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_DRAFT_REPORT_DOCUMENTS, query: query });
            dispatch({ type: actionTypes.REQUEST_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES, query: query });
        }
    },
    hasAnyDraftDocumentsLocked:(signingIds: number[], callback?: () => void):AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const queryString = signingIds.map(id => `signingIds=${id}`).join('&');
        const url = `${DraftReportPaths.IsDocumentsLocked}?${queryString}`;
        await fetch(url, {
            method: 'GET',
            credentials: 'include'
        })
        .then(handleResponse)
        .then(response => response as Promise<boolean>)
        .then(data => {
            dispatch({type: actionTypes.RECEIVE_DRAFT_DOCUMENTS_LOCKED_STATE, hasLockedDocuments:data });
        })
        .catch(error => {
            dispatch({
                type: actionTypes.NOTIFICATION,
                statusMessage: error,
                statusType: StatusType.Error, statusCode:error?.status
            });
        })
    }
}

const unloadedState: ISignatureDraftReportState = {
    query: '',
    signatureFlowReportTableModel: {
        documents: [],
        count: 0
    } as ISignatureFlowReportTableModel,
    totalRowCount: 0,
    isLoading: false,
    isDocumentsLocked:false
} as ISignatureDraftReportState

export const reducer: Reducer<ISignatureDraftReportState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as dispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_DRAFT_REPORT_DOCUMENTS:
            return {
                ...unloadedState,
                query: action.query,
                isLoading: true
            } as ISignatureDraftReportState;

        case actionTypes.RECEIVE_DRAFT_REPORT_DOCUMENTS:
            return {
                query: action.query,
                signatureFlowReportTableModel: action.table,
                totalRowCount: action.table.count,
                isLoading: false,
                isDocumentsLocked:false
            } as ISignatureDraftReportState;

        case actionTypes.RESET_DRAFT_REPORT_DOCUMENTS:
            return clearSignatureDraftReports(state);


        case actionTypes.RECEIVE_DRAFT_DOCUMENTS_LOCKED_STATE:
        {
            let initialReceive: ISignatureDraftReportState = {...state};
            initialReceive.isDocumentsLocked = action.hasLockedDocuments;
            return initialReceive;
        }
        default:  
            const exhaustiveCheck: never = action;
    }
    return state;
};

function clearSignatureDraftReports(state: ISignatureDraftReportState): ISignatureDraftReportState {
    return {
        ...unloadedState,
        query: state.query,
        isLoading: false,
        isDocumentsLocked:false
    } as ISignatureDraftReportState;
}


