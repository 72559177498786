import * as React from 'react';
import { Link } from 'react-router-dom';
import { Modal, Alert, Button } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { FaLink } from 'react-icons/fa';
import { ClientTypes, ISignatureFlowReportDocument, ISignerModel, SignatureFlowReportSignatureStatus } from '../../../models/SignatureFlowReportState';
import { VenusNotifier } from '../../../components/helper/VenusNotifier';
import { ResendMail } from '../../svg/CustomSvgIcons';

interface IResendAccessLinkProps {
    show: boolean;
    onCancel: () => void;
    model: ISignatureFlowReportDocument;
    onResendAccessLink: (model: ISignerModel) => void;
}
interface IResendAccessLinkState {
    message: string
}

const msg = {
    saving: 'Sending Access Link...',
    loading: 'Loading, please wait...'
}

export class ResendAccessLinkModal extends React.Component<IResendAccessLinkProps, IResendAccessLinkState> {
    constructor(props: IResendAccessLinkProps) {
        super(props);
        this.state = {
            message: msg.loading
        }
        this.onResendAccessLink = this.onResendAccessLink.bind(this);
    }
    UNSAFE_componentWillReceiveProps(nextProps: IResendAccessLinkProps) {
        if (!nextProps.show) {
            this.setState({ message: msg.loading });
        }
    }

    private onResendAccessLink(signerObj: any) {
        const signatureStatus: any = SignatureFlowReportSignatureStatus[this.props.model.signatureStatus];

        let model = this.props.model.signerInfo.find(x => x.clientGuid == signerObj.clientGuid && x.signerType == signerObj.signerType);
        if (model && model.isSigned) {
            this.setState({ message: msg.saving },
                () => {
                    if (model) { this.props.onResendAccessLink(model); }
                }
            );
        }
        else {
            VenusNotifier.Warning("Resend Access Link is only available for recipients that have already signed their documents. If the intended recipient is grayed out and has not yet signed, please use Send Reminder", null)
        }
    }

    public render() {
        const { model } = this.props
        const signedDetails = this.props.model !== undefined && this.props.model.signerInfo && this.props.model.signerInfo.length > 0;

        return <Modal centered={true} show={this.props.show} onHide={this.props.onCancel} className='resend-access-link-modal' backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    Resend Access Link
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{overflow: 'auto', maxHeight: '233px'}}>
                <LoadingOverlay style={{ minHeight: 100 }}>
                    {signedDetails ?
                        <ul style={{ padding: 10 }}>
                            {this.props.model.signerInfo.filter(x => x.order >= 0 && x.signerType != ClientTypes.CC).map((i, index) =>
                                <React.Fragment key={index}>
                                <li key={index}
                                    style={{ width: '-webkit-fill-available', listStyle:'none' }}>
                                        <div className='resend-access-link-email'><span >{i.signerEmail}</span></div>
                                    <ResendMail />
                                    <Link to={"#"} 
                                        onClick={() => this.onResendAccessLink(i)}
                                        className={"resend-access-link " + (i.isSigned == 1 ? "" : "resend-access-link-disabled")}                                        
                                        style={{}}>Resend Link</Link>
                                </li><br />
                                </React.Fragment>
                            )}
                        </ul> : (model !== undefined) &&
                        <Alert variant='warning' >
                            <i className='fas fa-exclamation-triangle' style={{ marginRight: '5px', color: '#666' }}>
                            </i>No details to show!
                                </Alert>}
                    <Loader loading={(model === undefined) || (model.signerInfo === undefined)} />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.props.onCancel}
                    variant="default"
                    className='btn-white modal-footer-button-cancel'
                    data-test-auto="BC3C6FF2-75C2-45AD-8552-8915E4C8EDF0">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    }
}
