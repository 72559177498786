import * as React from 'react';
import * as Bs from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { IPasswordUpdateModel, IUserProfile } from './ProfileObjects';
import { VenusNotifier } from '../../helper/VenusNotifier';
import * as Validation from '../../helper/Validations';
import * as bootbox from 'bootbox';
import * as Loader from '../../helper/Loader';
import PasswordMask from '../../common/PasswordMask';
import { ICompanySettings, IPasswordPolicySettings, PasswordOptions } from '../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { ValidationContants, ChangePasswordConstants, MyAccountConstants } from '../../helper/Constants';
import { WithLogout, WithLogoutProps } from 'src/oidcClient/withLogout';

let existingEmailAddress = "";
let initialState = {} as MyPasswordState;

interface IMyPasswordProps extends WithLogoutProps {
    profile: IUserProfile;
    show: boolean;
    onCancel(): void;
    onComplete(): void;
    updatePassword: (passwordUpdateModel: IPasswordUpdateModel, onResponse: (response: any, error: any) => void) => void;
    companySettings: ICompanySettings;
    passwordPolicySettings: IPasswordPolicySettings;
}

interface MyPasswordState extends IUserProfile {
    alert: string;
    message: string;
    showAlert: boolean;
    saving: boolean;
}

class ChangePassword extends React.Component<IMyPasswordProps, MyPasswordState> {
    constructor(props: IMyPasswordProps) {
        super(props);
        this.state = { ...props.profile, alert: 'warning', message: '', showAlert: false, saving: false };

        //Bindings
        this.submitChanges = this.submitChanges.bind(this);
        this.onChangeOldPassword = this.onChangeOldPassword.bind(this);
        this.onChangepassword = this.onChangepassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
        this.saveMyPassword = this.saveMyPassword.bind(this);
        this.onHide = this.onHide.bind(this);
        this.passwordoptions = this.passwordoptions.bind(this);
        this.getPasswordOptionLabel = this.getPasswordOptionLabel.bind(this);
    }

    UNSAFE_componentWillMount() {
        if (this.props.profile) {
            this.setState({ ...this.props.profile });
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps: IMyPasswordProps) {
        if (nextProps.profile) {
            this.setState({ ...nextProps.profile });
            existingEmailAddress = nextProps.profile.emailAddress;
        }
    }

    onChangeOldPassword(event: any) {
        this.setState({ oldPassword: event.target.value })
    }
    onChangepassword(event: any) {
        this.setState({ password: event.target.value })
    }
    onChangeConfirmPassword(event: any) {
        this.setState({ confirmPassword: event.target.value })
    }

    onHide() {
        this.setState({ alert: '', message: '', showAlert: false, saving: false });
        this.props.onCancel();
    }

    submitChanges() {
        if (!this.state.oldPassword || this.state.oldPassword.trim() == "") {
            this.setState({
                message: ValidationContants.OldPasswordWarning,
                alert: "warning",
                showAlert: true
            });
            return false;
        }


        if (!this.state.password || this.state.password.trim() == "") {
            this.setState({
                message: ValidationContants.NewPasswordWarning,
                alert: "warning",
                showAlert: true
            });
            return false;
        }

        if (!this.state.confirmPassword || this.state.confirmPassword.trim() == "") {
            this.setState({
                message: ValidationContants.ConfirmPasswordWarning,
                alert: "warning",
                showAlert: true
            });
            return false;
        }

        const validationResult = Validation.validatePasswordBasedOnPolicy(this.state.password, this.props.passwordPolicySettings.passwordSettings);
        if (validationResult.isError) {
            this.setState({
                message: validationResult.errorDescription,
                alert: "warning",
                showAlert: true
            });
            return false;
        }

        if (this.state.password.trim() == this.state.confirmPassword.trim()) {
            let stateThis = this;
            bootbox.confirm({
                message:ChangePasswordConstants.PasswordChangeLogoutWarning,
                buttons: {
                    cancel: {
                        label: 'Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: 'Confirm',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        stateThis.saveMyPassword();
                        return true;
                    }
                }
                   
            });
        }
        else {
            this.setState({
                message: ValidationContants.PasswordNotMatchedWarning,
                alert: "warning",
                showAlert: true

            });
            return false;
        }
    }

    saveMyPassword() {
        const state = this;
        this.setState({ alert: '', message: '', showAlert: false, saving: true });
        Loader.ShowLoader();//remove after the impl of content loader
        var passwordUpdateModel: IPasswordUpdateModel = {
            password: this.state.oldPassword,
            newPassword: this.state.password
            }
        this.props.updatePassword(passwordUpdateModel, (response: any, error: any) => {
            state.setState({ saving: false });
            Loader.HideLoader();//remove after the impl of content loader
            if (error) {
                const errorMessage = error !== "" ? error : ChangePasswordConstants.PasswordUpdateFail;
                VenusNotifier.Error(errorMessage, "");
            } else {
                initialState = state.state;
                if (response == true) {
                    VenusNotifier.Success(ChangePasswordConstants.PasswordUpdateSuccess, "");
                    state.setState({ alert: '', message: '', showAlert: false, password: "", confirmPassword: "", oldPassword: "" });
                    state.props.onComplete();
                    state.props.onLogout();
                } else {
                    state.setState({
                        message: ChangePasswordConstants.PasswordUpdateFail,
                        alert: "warning",
                        showAlert: true
                    });
                }
            }
        });
    }

    private getPasswordOptionLabel(option: any): string {
        switch (option) {
            case PasswordOptions.None:
                return ""
            case PasswordOptions.UpperCase:
                return "Upper Case Letter"
            case PasswordOptions.LowerCase:
                return "Lower Case Letter"
            case PasswordOptions.Numbers:
                return "Numbers"
            case PasswordOptions.SpecialCharacters:
                return "Special Character"
            default:
                return option;
        }
    }

    passwordoptions() {
        const items: any[] = [];
        const selectedOptions = Object.keys(PasswordOptions)
            .filter(key => !isNaN(Number(key)))
            .map(key => Number(key))
            .filter(option => option > 0 && (option & this.props.passwordPolicySettings.passwordSettings.passwordOptions) === option);

        selectedOptions.forEach((option, index) => {

            const label = this.getPasswordOptionLabel(option);
            items.push(
                <div>
                      <ul><li> {label}</li></ul >
                </div>
            );
        });

        return (
            <div>
              {items} 
            </div>
        );
    }

    public render() {
        return <Modal className="overlayPopup change-password-modal" show={this.props.show} onHide={this.onHide}>
            <Modal.Header closeButton data-test-auto="7A232988-989E-4DEB-9B10-6EF9BDFBAA37">
                <Modal.Title>
                    <i className='fas fa-user-shield padR05'></i>
                    Change Password
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: '14px' }}>
                {this.state.showAlert ?
                    <Bs.Alert variant={this.state.alert} ><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}>
                    </i>{this.state.message}</Bs.Alert> : " "}
                <div className="row marB10">
                    <div className="col-sm-3 col-md-3 col-xl-3">
                        <div className="marB10 padT07">Old Password:</div>
                    </div>
                    <div className="col-sm-9 col-md-9 col-xl-9">
                        <div className="marB10">
                            <PasswordMask
                                onChange={(e) => this.onChangeOldPassword(e)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row  marB10">
                    <div className="col-sm-3 col-md-3 col-xl-3">
                        <div className="marB10 padT07">New Password:</div>
                    </div>
                    <div className="col-sm-9 col-md-9 col-xl-9">
                        <div className="marB10">
                            <PasswordMask
                                onChange={(e) => this.onChangepassword(e)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row  marB10">
                    <div className="col-sm-3 col-md-3 col-xl-3">
                        <div className="marB10 padT07">Confirm Password:</div>
                    </div>
                    <div className="col-sm-9 col-md-9 col-xl-9">
                        <div className="marB10">
                            <PasswordMask
                                onChange={(e) => this.onChangeConfirmPassword(e)}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <hr />
            <div style={{ padding: "10px", fontSize: '14px'}}>  
              <div className="row marL0 marR0" >
                  <div className="col-sm-5 col-md-5 col-xl-5">       
                    <div className="password-req-lable ">Password Requirements:</div>
                  </div>
              </div>       
              <div className="line-space">
                    1. Minimum Number of characters: <b> {this.props.passwordPolicySettings.passwordSettings.length}</b>           
                </div>
                {this.props.passwordPolicySettings.passwordSettings.passwordOptions != PasswordOptions.None ?
                    <div>
                        <div className="line-space">
                            2. Required character types
                        </div>
                        <div className="line-space" style={{ paddingLeft: "45px" }}>
                            <b> Your password must include one of each of </b>
                        </div>
                        <div  style={{ paddingLeft: "45px" }}>
                            <b> the below required character types </b>
                        </div>
                        <div className="line-space" style={{ paddingLeft: "90px" }}>
                            {this.passwordoptions()}
                        </div>
                        <br />                         
                        {(this.props.passwordPolicySettings.passwordSettings.passwordOptions & PasswordOptions.SpecialCharacters) === PasswordOptions.SpecialCharacters ?                                                
                            <div className="tooltip list-special-char">
                                (View list of available special characters)
                                 <span className="tooltiptext"> ~ ! @ # $ % ^ & * _  </span>
                            </div> 
                            : ""}                                                  
                    </div> : ""}  
            </div>
            <Modal.Footer style={{ display: 'flex'}}>
                <Bs.Button
                    variant='default'
                    onClick={this.onHide}
                    data-test-auto="4A0C3490-369E-49E9-A36A-3139973D5A81">
                        Cancel
                </Bs.Button>
                <Bs.Button
                    variant='info'
                    onClick={this.submitChanges}
                    data-test-auto="3CDE45EA-64A3-49F4-B6F4-A122088BA80B">
                        Save
                </Bs.Button>
            </Modal.Footer>
        </Modal>
    }
}
export default WithLogout(ChangePassword);
