import { injectable } from "inversify";
import * as bootbox from 'bootbox';
 
export interface IDialogBox {
    alert(message: string, callback?: () => void): void;
    confirm(title: string, message: string, cancel: string, confirm: string, callback?: (result: boolean) => void): void;
    confirmCustom(title: string, message: string, rightButtonText: string, leftButtonText: string, callback?: (result: boolean) => void): void;
}
 
@injectable()
export class DialogBox implements IDialogBox {
 
  
    public alert(message: string, callback?: () => void): void {
 
        //bootbox.alert(message,
        //    function () {
        //    callback && callback();
        //});

        bootbox.alert({
            message: message,
            buttons: {
                ok: {
                    label: 'OK',
                    className: 'btn-info'
                }
            },
            callback() {
                callback && callback();
            }
        });
    }
 
    public confirm(title: string, message: string, cancel: string, confirm: string, callback?: (result: boolean) => void): void {
 
        bootbox.confirm({
            title: '<i className="text-secondary"></i>' + title,
            message: message,
            buttons: {
                cancel: {
                    label: cancel,
                    className: 'btn-white btn-default rounded'
                },
                confirm: {
                    label: confirm,
                    className: 'btn-primary rounded'
                }
            },
            callback: (result: boolean) => {
                if (callback) {
                    callback(result);
                }
            }
        });
    }
 
    public confirmCustom(title: string, message: string, rightButtonText: string, leftButtonText: string, callback?: (result: boolean) => void): void {
 
        bootbox.confirm({
            title: '<i className="text-secondary"></i>' + title,
            message: message,
            buttons: {
                cancel: {
                    label: leftButtonText,
                    className: 'btn-white btn-default rounded'
                },
                confirm: {
                    label: rightButtonText,
                    className: 'btn-primary rounded'
                }
            },
            callback: (result: boolean) => {
                if (callback) {
                    callback(result);
                }
            }
        });
    }
}