import * as React from 'react';
import { ISignatureFlowReportDocument } from 'src/SignatureFlow/models/SignatureFlowReportState';
import * as Bs from 'react-bootstrap';
import 'isomorphic-fetch';
import { ActionDropDownButton } from '../svg/CustomSvgIcons';
import { Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { ResourceIdConstants } from '../helper/RBACConstants';

interface ActionProps {
    screenId: string;
    isArchive: boolean;
    title: string;
    rowIndex: number;
    signatureReportsData: ISignatureFlowReportDocument[];
    onDeleteDocument: (rowIndex: number) => void,
    onSetAccessOpen: (rowIndex: number, headerTextShow: boolean) => void;
    onReportProblemOpen: (rowIndex: number) => void;
    onResume:(rowIndex:number) => void;
}



const SignatureFlowDraftReportAction :React.FC<ActionProps> = (props) => {

    const createSecondaryActions = () => {
        const { screenId } = props;
        return (<Bs.Dropdown className='btn-group-vertical' title={"More.."}>
            <Bs.Dropdown.Toggle 
            variant='none' 
            className='btn-sm draft-action-button'>
                <ActionDropDownButton />
            </Bs.Dropdown.Toggle>
  
            <Bs.Dropdown.Menu 
            className='draft-dropdown-menu'
            popperConfig={{strategy: "fixed"}}
            alignRight>
                <Bs.Dropdown.Item >
                    <Button
                    variant="none"
                    data-resource-id={`${screenId}${ResourceIdConstants.SetAccess}`}
                    title={"Set Access"}
                    type="button"
                    className="action-dropdown-btn"
                    onClick={(e) => props.onSetAccessOpen(props.rowIndex, false)} >
                        <span className='filter-dropdown-text'>Set Access</span>
                    </Button>
                </Bs.Dropdown.Item>
                <Bs.Dropdown.Item >
                    <Button variant="none"
                    data-resource-id={`${screenId}${ResourceIdConstants.ReportAProblem}`}
                    type="button" 
                    className="action-dropdown-btn" 
                    onClick={() => props.onReportProblemOpen(props.rowIndex)}>
                        <span className='filter-dropdown-text'>Report a Problem</span>
                    </Button>
                </Bs.Dropdown.Item>
            </Bs.Dropdown.Menu>
          </Bs.Dropdown>)

          
    }
    
   
 
  
    const createPrimaryActions = () => {

        const { screenId } = props;
        return (<div>
            <span
                data-resource-id={`${screenId}${ResourceIdConstants.DraftResume}`}
                title={"Resume"}
                style={{ cursor: 'pointer' }}>
                    <a style={{color:'#05386B'}} onClick={() => props.onResume(props.rowIndex)}>Resume</a>
            </span>
            <span style={{color: '#C7C8C9', paddingLeft:'10px'}}>
                <svg width="2" height="16" viewBox="0 0 2 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="0" height="16" transform="translate(1)" fill="white" fillOpacity="0.01" />
                    <path d="M1 0V16" stroke="#212529" strokeOpacity="0.25" />
                </svg>
            </span>
            <span
                data-resource-id={`${screenId}${ResourceIdConstants.Delete}`}
                onClick={() => {props.onDeleteDocument(props.rowIndex)}}
                title={"Delete"}
                style={{ cursor: 'pointer',  paddingLeft:'10px' }}>
                    <a style={{color:'#05386B'}}>Delete</a>
            </span>
            <span style={{color: '#C7C8C9', paddingLeft:'10px'}}>
                <svg width="2" height="16" viewBox="0 0 2 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="0" height="16" transform="translate(1)" fill="white" fillOpacity="0.01" />
                    <path d="M1 0V16" stroke="#212529" strokeOpacity="0.25" />
                </svg>
            </span>
            
            {createSecondaryActions()}
        </div >);
    }

    return <div className="button-group">{createPrimaryActions()}</div>;
}

export default SignatureFlowDraftReportAction;
