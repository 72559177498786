import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { API_BASE_URL, SSSUITE_API_BASE_URL } from '../../utils/AppConstants';
import { AppThunkAction } from './';
import { IUserLoginHistories, IUserLoginHistory } from '../components/navigation/profile/ProfileObjects';
import { actionTypes } from './ActionTypes';
import { handleResponse } from './Library';
import { StatusType, NotificationAction } from './common/NotificationStore';
import { IDownloadedZipFilesModel } from '../components/navigation/profile/MyDownloads';
import { validateError } from '../components/helper/Validations';
import { SignatureFlowReportConstants } from '../components/helper/Constants';
import { logger } from 'src/routes/LoggedIn';
import { forEach } from 'lodash';

 export interface IMyDownloadsState {
    bulkDownloadsCount: number;
    bulkDownloads: IDownloadedZipFilesModel[];
    isBulkDownloadsLoading: boolean;
}

interface ReceiveMyDownloadsListAction {
    type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST;
    bulkDownloads: IDownloadedZipFilesModel[];
    bulkDownloadsCount: number;
    bulkDownloadsPageNo: number;
}
interface RequestMyDownloadsListAction {
    type: actionTypes.REQUEST_MY_DOWNLOADS_LIST;
}
type KnownAction = RequestMyDownloadsListAction | ReceiveMyDownloadsListAction | NotificationAction;
type DispatchActions = RequestMyDownloadsListAction | ReceiveMyDownloadsListAction;

export const actionCreators = {

    fetchMyDownloads: (pageNo:number = 1, pageSize:number = 10, callBack: ()=> void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/Download/BulkDownloads/?pageNo=' + pageNo + '&pageSize=' + pageSize, {
            method: 'GET',
            credentials: 'include'
        })
            .then((response: any) => {
                var data = handleResponse(response);
                dispatch({ type: actionTypes.REQUEST_MY_DOWNLOADS_LIST })
                return data;
            })
            .then(response => response as Promise<IDownloadedZipFilesModel[]>)
            .then(data => {
                if(callBack){
                    callBack();
                }
                dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, bulkDownloads: data, bulkDownloadsCount: (data.length==0?0:data[0].count), bulkDownloadsPageNo: pageNo });
            })
            .catch(error => {
                if(callBack){
                    callBack();
                }
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error, statusCode:error?.status });
            });
        addTask(fetchTask);
    },
    downloadMyDownloads: (bulkDownloadsId: number, documentGuid: string, filePath: string, fileName: string, callBack: ()=> void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/Download/BulkDownloads/Link?bulkDownloadId='+bulkDownloadsId+'&documentGuid=' + documentGuid+'&fileName='+fileName+'&filePath='+filePath, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(function (documentUrl) {
                if(callBack){
                    callBack();
                }
                window.open(documentUrl, "_blank");
            }).catch(error => {
                if(callBack){
                    callBack();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.DownloadDocument.Error,
                    statusType: StatusType.Error, statusCode:error?.status
                })
                logger.trackError(`downloadMyDownloads failed for the request having documentGuid ${documentGuid} with error ${validateError(error)}`)
            });
        addTask(fetchTask);
    },
    downloadAllMyDownloads: (bulkDownloads: IDownloadedZipFilesModel[], callBack: ()=> void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/Download/BulkDownloads/DownloadAll', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(bulkDownloads)
        })
            .then(handleResponse)
            .then(function (documentUrls) {
                if(callBack){
                    callBack();
                }
                if(documentUrls && documentUrls.length>0){
                    documentUrls.forEach(url=>window.open(url, "_blank"));
                }                
            }).catch(error => {
                if(callBack){
                    callBack();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.DownloadDocument.Error,
                    statusType: StatusType.Error, statusCode:error?.status
                })
                logger.trackError(`downloadAllMyDownloads failed with error ${validateError(error)}`)
            });
        addTask(fetchTask);
    },
    deleteMyDownloads: (bulkDownloadsId: number, documentGuid: string, filePath: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/Download/BulkDownloads?bulkDownloadsId=' + bulkDownloadsId+'&documentGuid='+documentGuid+'&filePath='+filePath, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((data) => {
                if (callback) {
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.MyDownloads.DeleteSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                if (callback) {
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: SignatureFlowReportConstants.MyDownloads.DeleteError,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`deleteMyDownloads failed for the request having bulkDownloadId ${bulkDownloadsId} with error ${validateError(error)}`)
            });
        addTask(fetchTask);
    },
    deleteAllMyDownloads: (callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/Download/BulkDownloads/All', {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((data) => {
                if (callback) {
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SignatureFlowReportConstants.MyDownloads.DeleteAllSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                if (callback) {
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: SignatureFlowReportConstants.MyDownloads.DeleteError,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`deleteAllMyDownloads failed with error ${validateError(error)}`)
            });
        addTask(fetchTask);
    },
}

const initialMyDownloadsState: IMyDownloadsState=
{
    bulkDownloadsCount: 0,
    bulkDownloads: [] as IDownloadedZipFilesModel[],
    isBulkDownloadsLoading: false
}

export const reducer: Reducer<IMyDownloadsState> = (state = initialMyDownloadsState, incomingAction) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.RECEIVE_MY_DOWNLOADS_LIST:
            let myDownloads = { ...state };
            myDownloads.bulkDownloads = [...action.bulkDownloads];
            myDownloads.bulkDownloadsCount = action.bulkDownloadsCount;
            myDownloads.isBulkDownloadsLoading = false;
            return myDownloads;
        case actionTypes.REQUEST_MY_DOWNLOADS_LIST:
            let initialMyDownloadsState = { ...state };
            initialMyDownloadsState.isBulkDownloadsLoading = true;
            return initialMyDownloadsState;
        default:
            const exhaustiveCheck: any = action;
    }
    return state;
};