import * as React from 'react';
import TinyMCE from '../../../Script/react-tinymce';
var htmlencode = require('htmlencode');

interface TinyMCEComponentProps {
    messageBody: string;
    changeStateTinymceBody: (event: any) => void;
    readOnly: number;
}

const TinyMCEComponent: React.FC<TinyMCEComponentProps> = props => {

    return (
        <div>
            <TinyMCE
                tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                data-test-auto="CC40EF97-D565-4D3A-B467-91895733A8CA"
                key={props.messageBody}
                content={htmlencode.htmlDecode(props.messageBody)}
                config={{
                    plugins: 'textcolor autoresize lists',
                    menu: {
                        edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall' },
                        format: { title: 'Format', items: 'bold italic underline | blockformats align ' }
                    },
                    toolbar: 'bold italic forecolor alignleft aligncenter alignright alignjustify bullist numlist indent outdent removeformat ',
                    readonly: props.readOnly,
                    block_formats: 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6'
                }}
                onChange={props.changeStateTinymceBody}
            />
        </div>
    );
}

export default TinyMCEComponent;