import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/types';
import * as RecycleReportStore from '../../../store/ReportsStore/RecycleReport/RecycleReportStore';
import * as SignatureFlowReportFilterStore from '../../../store/ReportsCommon/SignatureFlowReportFilterStore';
import * as Component from '../SignatureRecycleReports';
import * as UserStore from '../../../store/SFUserStore';
import * as SignatureFlowReportCustomColumnStore from '../../../store/ReportsCommon/SignatureFlowReportCustomColumnStore';
import * as SignatureFlowReportPopupModelStore from '../../../store/ReportsCommon/SignatureFlowReportPopupModelStore';
import * as UserProfileStore from '../../../store/userManagement/UserProfileStore';
import * as SignatureFlowSettingsStore from '../../../store/SignatureFlowSettingsStore';
import * as SFBulkOperationsStore from '../../../store/SFBulkOperationsStore';
import * as SigningInfoSettingsStore from '../../../store/SigningInfoSettingsStore';

export default connect(
    (state: ApplicationState) => ({
        RecycleReportDocuments: state.RecycleReportDocuments, 
        reportCustomColumns: state.signatureFlowReportCustomColumns,
        userStore: state.sfUserList,
        signatureFlowReportDetails: state.signatureFlowReport,
        signatureFlowReportFilter: state.signatureFlowReportFilter,
        profile: state.userProfile.user,
        signatureFlowSettingsData: state.signatureFlowSettingsData,
        signingInfoSettingsData: state.signingInfoSettingsStore,
        userOfficeLocationState: state.userOfficeLocation,
        pageTitle: "Recycle Bin"
    }),
    {
        ...RecycleReportStore.actionCreators,
        ...SignatureFlowReportCustomColumnStore.actionCreators,
        ...UserStore.actionCreators,
        ...SignatureFlowReportFilterStore.actionCreators,
        ...SignatureFlowReportPopupModelStore.actionCreators,
        ...UserProfileStore.actionCreators,
        ...SignatureFlowSettingsStore.actionCreators,
        ...SFBulkOperationsStore.actionCreators,
        ...SigningInfoSettingsStore.actionCreators
    }
)(Component.SignatureRecycleReports as any)