import * as React from 'react';
import { Row } from 'react-bootstrap';
import * as DocumentStore from '../store/DocumentStore';
import { AccessOption, IAccessOptionsSettings, IDocumentType } from '../models/SignatureFlowSettings';
import { IDocumentData, DocumentUploadData } from '../models/DocumentInfo';
import { UploadDocumentModal } from '../components/common/UploadDocumentModal';
import { DocumentAccessList } from './DocumentAccessList';
import { IUserModel } from '../../Core/ViewModels/User/UserViewModel';
import { IDocumentAccess } from '../models/SigningInfo';
import OverlayLoader from './helper/OverlayLoader';
import { IDropdown } from "../../Core/ViewModels/User/UserViewModel";

export interface Step1Props {
    getUploadLink: (url: string, callback?: (data?: DocumentStore.IBlobFile) => void) => void;
    deleteUploadedSFDocument: (documentKey: string, callback?: () => void) => void;
    requestDocumentTypes: () => void;
    documentTypes: IDocumentType[];
    accessOptionsSettings: IAccessOptionsSettings;
    updateDocumentUploadData: (documentInfo: DocumentUploadData[]) => void;
    documentUploadData: DocumentUploadData[];
    currentStep: number;
    SendDocumentToExtractSignatureControlsAsync: (documentGuid: string, sourceFileGuid: string, uploadBlobYear: number, callback?: (data?: any) => void) => void;
    addDocumentData: (documentData: IDocumentData) => void;
    deleteDocumentData: (documentGuid: string) => void;
    fileUploadInProgress?: (status: boolean) => void;
    updateTaxYear: (taxYear: any) => void;
    updateClientName: (clientName: string) => void;
    updateClientId: (clientId: string) => void;
    taxYear: number;
    clientName: string;
    clientId: string;
    users: IUserModel[];
    documentAccess:IDocumentAccess;
    hasSetAccessPermission:boolean;
    onRef?: (ref: any) => any
    isDraftDocLoading:boolean;
    getClientInfo: (clientId:string) => void;
    officeLocations: IDropdown[];
    updateOfficeLocation: (officeLocation: number) => void;
    selectedOfficeLocationId: number;
    uploadBlobYear: number;
    isNavigatedFromDraft: boolean;
    setDocumentAccess: (usersAllowedAccess: number[]) => void;
    documentAccessCleanUp: boolean;
    modifyDocumentAccessCleanUp: (isCleanUp: boolean) => void;
}

export interface Step1State {
    showPopup: boolean;
    allUsers: IUserModel[];
}

export class SFStep1 extends React.Component<Step1Props, Step1State>{
    private uploadDocumentModalRef:any;

    constructor(props: Step1Props) {
        super(props);

        this.state = {
            showPopup: false,
            allUsers:props.users
        }
    }
    componentDidMount(): void {
        if(this.props.onRef){
            this.props.onRef(this);
        }
    }

    private modifyAllowedUsers = (usersAllowedAccess: number[]) => {
        this.props.setDocumentAccess(usersAllowedAccess);
    }

    private onPopupHide = () => {
        this.setState({
            showPopup: false
        })
    }

    private onDocumentAccessOpen = () => {
        this.setState({
            showPopup: true
        })
    }

    public showValidationError = (fieldName:string, value:boolean) => {
        this.uploadDocumentModalRef.validationError(fieldName,value);
    }

    public render() {
        const isDocumentAccessModify = !(!this.props.isNavigatedFromDraft && 
                                        this.props.accessOptionsSettings.accessOption === AccessOption.IndividualUser && 
                                        !this.props.accessOptionsSettings.additionalAccess)
        return  (this.props.isDraftDocLoading ?
            <OverlayLoader show={this.props.isDraftDocLoading} text={'Opening document ...'} />
            : 
            <Row style={{ width: "100%", marginLeft: "0" }}>
            <UploadDocumentModal onRef={(ref: any) => (this.uploadDocumentModalRef = ref)} currentStep={this.props.currentStep}
                documentTypes={this.props.documentTypes}
                requestDocumentTypes={this.props.requestDocumentTypes}
                getUploadLink={this.props.getUploadLink}
                documentUploadData={this.props.documentUploadData}
                taxYear={this.props.taxYear}
                clientName={this.props.clientName}
                hasSetAccessPermission={this.props.hasSetAccessPermission}
                clientId={this.props.clientId}
                updateDocumentUploadData={this.props.updateDocumentUploadData}
                deleteUploadedSFDocument={this.props.deleteUploadedSFDocument}
                maxFileLimitUpload={30} 
                SendDocumentToExtractSignatureControlsAsync={this.props.SendDocumentToExtractSignatureControlsAsync}
                addDocumentData={this.props.addDocumentData}
                deleteDocumentData={this.props.deleteDocumentData}
                fileUploadInProgress={this.props.fileUploadInProgress}
                updateTaxYear={this.props.updateTaxYear}
                updateClientName={this.props.updateClientName}
                updateClientId={this.props.updateClientId}
                onDocumentAccessOpen={this.onDocumentAccessOpen}
                isDraftDocLoading={this.props.isDraftDocLoading}
                uploadBlobYear={this.props.uploadBlobYear}
                getClientInfo={this.props.getClientInfo}
                officeLocations={this.props.officeLocations}
                updateOfficeLocation={this.props.updateOfficeLocation}
                selectedOfficeLocationId={this.props.selectedOfficeLocationId}
            />
            <DocumentAccessList
                allowedUsers={this.props.documentAccess?.userIds}
                usersList={this.props.users}
                modifyAllowedUsers={this.modifyAllowedUsers}
                showPopup={this.state.showPopup}
                onHide={this.onPopupHide}
                modify={isDocumentAccessModify}
                isCleanUp={this.props.documentAccessCleanUp}
                modifyDocumentAccessCleanUp={this.props.modifyDocumentAccessCleanUp}
            />
        </Row>
        )
    }
}
