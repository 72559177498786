import { IAuthenticationQuestion } from "src/SignatureFlow/models/SignatureFlowSettings";

export enum AuthenticationQuestionsActionTypes{
    RECEIVE_SIGNATURE_FLOW_AUTHENTICATION_QUESTIONS='RECEIVE_SIGNATURE_FLOW_AUTHENTICATION_QUESTIONS',
    RECEIVE_SIGNATURE_FLOW_AUTHENTICATION_QUESTION='RECEIVE_SIGNATURE_FLOW_AUTHENTICATION_QUESTION',
    DELETE_SIGNATURE_FLOW_AUTHENTICATION_QUESTION='DELETE_SIGNATURE_FLOW_AUTHENTICATION_QUESTION'
}

export interface ReceiveSignatureAuthenticationQuestions {
    type: AuthenticationQuestionsActionTypes.RECEIVE_SIGNATURE_FLOW_AUTHENTICATION_QUESTIONS;
    authenticationQuestions: IAuthenticationQuestion[];
}

export interface ReceiveSignatureAuthenticationQuestion {
    type: AuthenticationQuestionsActionTypes.RECEIVE_SIGNATURE_FLOW_AUTHENTICATION_QUESTION;
    question: IAuthenticationQuestion;
    questionId: number;
}

export interface DeleteSignatureFlowAuthenticationQuestion {
    type: AuthenticationQuestionsActionTypes.DELETE_SIGNATURE_FLOW_AUTHENTICATION_QUESTION;
    question: IAuthenticationQuestion;
}

